<mifosx-web-views-top-bar title="labels.loanRequest"></mifosx-web-views-top-bar>
<div class="container">

  <mat-horizontal-stepper class="mat-elevation-z8" labelPosition="bottom" #loansAccountStepper>
    <ng-template matStepperIcon="number">
      <fa-icon icon="pencil-alt" size="sm"></fa-icon>
    </ng-template>

    <ng-template matStepperIcon="edit">
      <fa-icon icon="pencil-alt" size="sm"></fa-icon>
    </ng-template>

    <ng-template matStepperIcon="done">
      <fa-icon icon="check" size="sm"></fa-icon>
    </ng-template>

    <ng-template matStepperIcon="error">
      <fa-icon icon="exclamation-triangle" size="lg"></fa-icon>
    </ng-template>

    <ng-template matStepperIcon="preview">
      <fa-icon icon="eye" size="sm"></fa-icon>
    </ng-template>

    <mat-step [stepControl]="loansAccountDetailsForm">

      <ng-template matStepLabel>{{'labels.heading.details' | translate }}</ng-template>

      <mifosx-loans-account-details-step [loansAccountTemplate]="loansAccountTemplate"
      [loanProducts]="loanProducts"
      [creditLine]="creditLine"
      [token]="token"
        (loansAccountProductTemplate)="setTemplate($event)">
      </mifosx-loans-account-details-step>
    </mat-step>

    <mat-step state="preview" *ngIf="loansAccountFormValid" completed>

      <ng-template matStepLabel>{{'labels.heading.preview' | translate }}</ng-template>

      <mifosx-loans-account-preview-step [loansAccountTemplate]="loansAccountTemplate"
        [loansAccountProductTemplate]="loansAccountProductTemplate" [nextPeriodDetails]="nextPeriodDetails" [loansAccount]="loansAccount" (submit)="submit()" [submited]="submited">
      </mifosx-loans-account-preview-step>

    </mat-step>

    <mat-step state="success" *ngIf="loansAccountFormValid && success" completed>

      <ng-template matStepLabel>{{'labels.Success' | translate }}</ng-template>

      <div class="success">
        <fa-icon icon="check-circle" class="icon"></fa-icon>
        <h3>{{"creditRequestCreatedSuccessfully" | translate}}</h3>
      </div>

    </mat-step>

  </mat-horizontal-stepper>
  <mifosx-web-views-logo></mifosx-web-views-logo>

</div>
