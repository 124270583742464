<div class="container">

  <mat-card fxLayout="column" fxLayoutGap="3%">

    <mat-form-field fxFlex>
      <mat-label>{{"labels.inputs.meetingdate" | translate}}</mat-label>
      <mat-select [formControl]="meetingDate">
        <mat-option *ngFor="let date of meetingDates" [value]="date">
          {{ date  | dateFormat }}
        </mat-option>
      </mat-select>
      <mat-hint>{{"labels.heading.nextmeetingon" | translate}}: {{ this.centerData.collectionMeetingCalendar.nextTenRecurringDates[0]  | dateFormat }}</mat-hint>
    </mat-form-field>

    <table class="mat-elevation-z1" mat-table [dataSource]="dataSource">

      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef> {{"labels.inputs.name" | translate}} </th>
        <td mat-cell *matCellDef="let member"> {{ member.clientId | find:membersData:'id':'displayName' }} </td>
      </ng-container>

      <ng-container matColumnDef="attendance">
        <th mat-header-cell *matHeaderCellDef> {{"labels.heading.attendance" | translate}} </th>
        <td mat-cell *matCellDef="let member" [ngSwitch]="member.attendanceType">
          <span *ngSwitchCase="1">{{"labels.present" | translate}}</span>
          <span *ngSwitchCase="2">{{"labels.absent" | translate}}</span>
          <span *ngSwitchCase="3">{{"labels.Approved" | translate}}</span>
          <span *ngSwitchCase="4">{{"labels.leave" | translate}}</span>
          <span *ngSwitchCase="5">{{"labels.late" | translate}}</span>
          <button mat-icon-button color="primary"
            (click)="editAttendance(member)">
            <fa-icon icon="pen"></fa-icon>
          </button>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

    </table>

    <mat-card-actions fxLayout="row" fxLayout.xs="column" fxLayoutAlign="center" fxLayoutGap="5px">
      <button type="button" mat-raised-button [routerLink]="['../../']">{{"labels.buttons.cancel" | translate}}</button>
      <button mat-raised-button color="primary" (click)="submit()" *mifosxHasPermission="'CREATE_MEETING'">{{"labels.buttons.confirm" | translate}}</button>
    </mat-card-actions>

  </mat-card>

</div>
