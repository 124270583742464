import { ExportExcelService } from './../../../../core/exportExcel/ExportExcel.service';
import { MatPaginator } from '@angular/material/paginator';
import { FormControl } from '@angular/forms';
/** Angular Imports */
import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SelectionModel } from '@angular/cdk/collections';
import * as _ from 'lodash';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialog } from '@angular/material/dialog';

/** Dialog Imports */
import { ConfirmationDialogComponent } from 'app/shared/confirmation-dialog/confirmation-dialog.component';

/** Custom Services */
import { SettingsService } from 'app/settings/settings.service';
import { Dates } from 'app/core/utils/dates';
import { TasksService } from 'app/tasks/tasks.service';
import * as moment from 'moment';

@Component({
  selector: 'mifosx-reschedule-loan',
  templateUrl: './reschedule-loan.component.html',
  styleUrls: ['../../../operations.module.scss']
})
export class RescheduleLoanComponent {

  /** Loans Data */
  loans: any;
  filteredLoans: any;


  rescheduleReasons = new Set();
  /** Datasource */
  dataSource: MatTableDataSource<any>;
  /** Rows Selection Data */
  selection: SelectionModel<any>;
  /** Batch Requests */
  batchRequests: any[];
  /** Displayed Columns */
  displayedColumns: string[] = ['select', 'client', 'rescheduleRequestNo', 'loanAccountNo', 'rescheduleForm', 'rescheduleReason'];

  maxDate = new Date();
  clientFilter = new FormControl();
  loanAccountFilter = new FormControl();
  rescheduleFilter = new FormControl();
  rescheduleFromDateFilter = new FormControl();
  rescheduleReasonFilter = new FormControl();


  startDateFilter = new FormControl();
  endDateFilter = new FormControl();


  applyFilters = () => {
    this.filteredLoans = this.loans.filter((loan: any) => {
      const client = this.clientFilter.value ? loan?.clientName?.toLowerCase().includes(this.clientFilter.value?.toLowerCase()) : true;
      const loanAccount = this.loanAccountFilter.value ? loan?.loanAccountNumber.includes(this.loanAccountFilter.value) : true;
      const reschedule = this.rescheduleFilter.value ? loan?.id?.toString() === this.rescheduleFilter.value : true ;
      const rescheduleFrom = new Date(loan?.rescheduleFromDate[0],loan?.rescheduleFromDate[1]-1,loan?.rescheduleFromDate[2]);
      const rescheduleFromDate = this.rescheduleFromDateFilter.value ? moment(rescheduleFrom).isSame(this.rescheduleFromDateFilter.value) : true
      const rescheduleReasonCodeValue = this.rescheduleReasonFilter.value ? loan?.rescheduleReasonCodeValue?.name === this.rescheduleReasonFilter.value : true

      const fromDate = this.startDateFilter.value ? moment(rescheduleFrom).isSameOrAfter(this.startDateFilter.value) : true;
      const toDate = this.endDateFilter.value ? moment(rescheduleFrom).isSameOrBefore(this.endDateFilter.value) : true;
      return client && loanAccount && reschedule && rescheduleFromDate && rescheduleReasonCodeValue && fromDate && toDate;
    });
    this.dataSource.data = this.filteredLoans;
  }
  exportexcel(){
    const dateFormat = 'yyyy-MM-dd';
    const data = this.filteredLoans.map((loan: any) => ({
      Client: loan.clientName,
      RescheduleRequest: loan.id,
      LoanAccount: loan.loanAccountNumber,
      RescheduleForm: this.dateUtils.formatDate(loan.rescheduleFromDate,dateFormat),
      RescheduleReason: loan.rescheduleReasonCodeValue.name,
    }))

    const header = ['labels.heading.client','labels.heading.reschulerequest', 'labels.heading.loanaccount', 'labels.rescheduleForm','labels.rescheduleReason'];
    this.exportExcelService.exportExcelFile({data:data,header:header,fileName:'reschedule_loan.xlsx'})
  }
  /**
   * Retrieves the reschedule loan data from `resolve`.
   * @param {ActivatedRoute} route Activated Route.
   * @param {Dialog} dialog MatDialog.
   * @param {Dates} dateUtils Date Utils.
   * @param {router} router Router.
   * @param {SettingsService} settingsService Settings Service.
   * @param {TasksService} tasksService Tasks Service.
   */
  constructor(private route: ActivatedRoute,
    private dialog: MatDialog,
    private dateUtils: Dates,
    private router: Router,
    private settingsService: SettingsService,
    private tasksService: TasksService,
    private exportExcelService: ExportExcelService) {
    this.route.data.subscribe((data: { recheduleLoansData: any }) => {
      this.loans = data.recheduleLoansData;
      this.selection = new SelectionModel(true, []);
      this.filteredLoans = [...this.loans];
      this.dataSource =  new MatTableDataSource(this.filteredLoans);
      this.loans.forEach((loan: any) => {
        if (loan?.rescheduleReasonCodeValue?.name) {
          this.rescheduleReasons.add(loan?.rescheduleReasonCodeValue?.name)
        }
      });
    });
  }


  @ViewChild(MatPaginator) paginator: MatPaginator;

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
  }


  isAllSelected(dataSource2: any) {
    if (dataSource2) {
      const numSelected = this.selection.selected;
      return _.difference(dataSource2, numSelected).length === 0;
    }
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle(dataSource3: any) {
    if (dataSource3) {
      this.isAllSelected(dataSource3) ?
        dataSource3.forEach((row: any) => this.selection.deselect(row)) :
        dataSource3.forEach((row: any) => this.selection.select(row));
    }
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: any): string {
    if (!row) {
      return `${this.isAllSelected(row) ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.position + 1}`;
  }

  approveBulkLoanReschedule() {
    const rescheduleLoanDialogRef = this.dialog.open(ConfirmationDialogComponent, {
      data: { heading: 'labels.heading.rescheduleLoan', dialogContext: 'labels.areYouSureYouWantToRescheduleLoan' }
    });
    rescheduleLoanDialogRef.afterClosed().subscribe((response: { confirm: any }) => {
      if (response.confirm) {
        this.bulkLoanRescheduleApproval();
      }
    });
  }

  bulkLoanRescheduleApproval() {
    const dateFormat = 'yyyy-MM-dd';
    const approvedOnDate = this.dateUtils.formatDate(new Date(), dateFormat);
    const locale = this.settingsService.language.code;
    const formData = {
      dateFormat,
      approvedOnDate,
      locale
    };
    const listSelectedAccounts = this.selection.selected;
    this.batchRequests = [];
    let reqId = 1;
    listSelectedAccounts.forEach((element: any) => {
      const url = 'rescheduleloans/' + element.id + '?command=approve';
      const bodyData = JSON.stringify(formData);
      const batchData = { requestId: reqId++, relativeUrl: url, method: 'POST', body: bodyData };
      this.batchRequests.push(batchData);
    });
    this.tasksService.submitBatchData(this.batchRequests).subscribe((response: any) => {
      console.log(response);
      this.reload();
    });
  }

  applyFilter(filterValue: string = '') {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  /**
   * Refetches data fot the component
   * TODO: Replace by a custom reload component instead of hard-coded back-routing.
   */
  reload() {
    const url: string = this.router.url;
    this.router.navigateByUrl(`/checker-inbox-and-tasks`, { skipLocationChange: true })
      .then(() => this.router.navigate([url]));
  }

}
