<div fxLayout="row" fxLayoutAlign="end" fxLayoutGap="2%" fxLayout.lt-md="column" class="container m-b-20">
  <button mat-raised-button color="primary" [routerLink]="['edit']">
    <fa-icon icon="edit"></fa-icon>&nbsp;&nbsp;
    {{"labels.buttons.edit" | translate}}
  </button>
</div>

<div class="container">

  <mat-card>

    <mat-card-content>

      <div fxLayout="row wrap" fxLayout.lt-md="column">

        <h2 class="mat-h2" fxFlexFill>{{ fixedDepositProductData.name }}</h2>

        <mat-divider [inset]="true"></mat-divider>

        <h3 class="mat-h3" fxFlexFill>{{"labels.buttons.details" | translate}}</h3>

        <mat-divider [inset]="true"></mat-divider>

        <div fxFlexFill>
          <span fxFlex="40%">{{"labels.inputs.product.shortname" | translate}}:</span>
          <span fxFlex="60%">{{ fixedDepositProductData.shortName }}</span>
        </div>

        <div fxFlexFill *ngIf="fixedDepositProductData.description">
          <span fxFlex="40%">{{"labels.heading.description" | translate}}:</span>
          <span fxFlex="60%">{{ fixedDepositProductData.description }}</span>
        </div>

        <h3 class="mat-h3" fxFlexFill>{{"labels.inputs.currency" | translate}}</h3>

        <mat-divider [inset]="true"></mat-divider>

        <div fxFlexFill>
          <span fxFlex="40%">{{"labels.heading.currency" | translate}}:</span>
          <span fxFlex="60%">{{ fixedDepositProductData.currency.name }}</span>
        </div>

        <div fxFlexFill>
          <span fxFlex="40%">{{"labels.inputs.decimalplace" | translate}}:</span>
          <span fxFlex="60%">{{ fixedDepositProductData.currency.decimalPlaces }}</span>
        </div>

        <div fxFlexFill>
          <span fxFlex="40%">{{"labels.heading.currencyinmultiplesof" | translate}}:</span>
          <span fxFlex="60%">{{ fixedDepositProductData.currency.inMultiplesOf | number}}</span>
        </div>

        <h3 class="mat-h3" fxFlexFill>{{"labels.terms" | translate}}</h3>

        <mat-divider [inset]="true"></mat-divider>

        <div fxFlexFill>
          <span fxFlex="40%">{{"labels.inputs.depositamount" | translate}}:</span>
          <span fxFlex="60%">{{ fixedDepositProductData.depositAmount + ' (Min: ' + (fixedDepositProductData.minDepositAmount ? fixedDepositProductData.minDepositAmount : fixedDepositProductData.depositAmount) + ', Max: ' + (fixedDepositProductData.maxDepositAmount ? fixedDepositProductData.maxDepositAmount : fixedDepositProductData.depositAmount) + ')' }}</span>
        </div>

        <div fxFlexFill>
          <span fxFlex="40%">{{"labels.heading.interestcompoundingperiod" | translate}}:</span>
          <span fxFlex="60%">{{ fixedDepositProductData.interestCompoundingPeriodType.value }}</span>
        </div>

        <div fxFlexFill>
          <span fxFlex="40%">{{"labels.heading.interestpostingperiod" | translate}}:</span>
          <span fxFlex="60%">{{ fixedDepositProductData.interestPostingPeriodType.value }}</span>
        </div>

        <div fxFlexFill>
          <span fxFlex="40%">{{"labels.heading.interestcalculatedusing" | translate}}:</span>
          <span fxFlex="60%">{{ fixedDepositProductData.interestCalculationType.value }}</span>
        </div>

        <div fxFlexFill>
          <span fxFlex="40%">{{"labels.inputs.daysinyears" | translate}}</span>
          <span fxFlex="60%">{{ fixedDepositProductData.interestCalculationDaysInYearType.value }}</span>
        </div>

        <h3 class="mat-h3" fxFlexFill>{{"labels.menus.Settings" | translate}}</h3>

        <mat-divider [inset]="true"></mat-divider>

        <div fxFlexFill *ngIf="fixedDepositProductData.lockinPeriodFrequency">
          <span fxFlex="40%">{{"labels.heading.lockinPeriodFrequency" | translate}}:</span>
          <span fxFlex="60%">
            {{ fixedDepositProductData.lockinPeriodFrequency }} {{ fixedDepositProductData.lockinPeriodFrequencyType.value }}</span>
        </div>

        <div fxFlexFill *ngIf="fixedDepositProductData.minDepositTerm">
          <span fxFlex="40%">{{"labels.heading.minimumdepositterm" | translate}}:</span>
          <span fxFlex="60%"
            *ngIf="fixedDepositProductData.minDepositTerm !== undefined">{{ fixedDepositProductData.minDepositTerm }}
            &nbsp;
            {{ fixedDepositProductData.minDepositTermType.value }}</span>
        </div>

        <div fxFlexFill *ngIf="fixedDepositProductData.maxDepositTerm">
          <span fxFlex="40%">{{"labels.inputs.maximumdepositterm" | translate}}:</span>
          <span fxFlex="60%"
            *ngIf="fixedDepositProductData.maxDepositTerm !== undefined">{{ fixedDepositProductData.maxDepositTerm }}
            &nbsp;
            {{ fixedDepositProductData.maxDepositTermType.value }}</span>
        </div>

        <div fxFlexFill *ngIf="fixedDepositProductData.inMultiplesOfDepositTerm">
          <span fxFlex="40%">{{"labels.heading.inmultiplesofdeposit" | translate}}</span>
          <span fxFlex="60%">{{ fixedDepositProductData.inMultiplesOfDepositTerm }}
            -
            {{ fixedDepositProductData.inMultiplesOfDepositTermType.value }}</span>
        </div>

        <div fxFlexFill>
		  <span fxFlex="40%">{{"labels.heading.preclosurepenalapplicable" | translate}}:</span>
		  <span fxFlex="60%">{{ fixedDepositProductData.preClosurePenalApplicable ? 'Yes' : 'No' }}</span>
  		</div>

        <div fxFlexFill *ngIf="fixedDepositProductData.preClosurePenalApplicable">
          <span fxFlex="40%">{{"labels.penalInterest" | translate}} (%):</span>
          <span fxFlex="60%">{{fixedDepositProductData.preClosurePenalInterest}} &nbsp; &nbsp; % &nbsp; &nbsp; On
            &nbsp;&nbsp;
            {{fixedDepositProductData.preClosurePenalInterestOnType.value}}</span>
        </div>

        <div fxFlexFill *ngIf="fixedDepositProductData.minBalanceForInterestCalculation">
          <span fxFlex="40%">{{"labels.inputs.minbalanceforinterest" | translate}}</span>
          <span fxFlex="60%">{{ fixedDepositProductData.minBalanceForInterestCalculation | number }}</span>
        </div>

        <div fxFlexFill>
          <span fxFlex="40%">{{"labels.inputs.withholdtax" | translate}}</span>
          <span fxFlex="60%">{{ fixedDepositProductData.withHoldTax ? 'Yes' : 'No' }}</span>
        </div>

        <div fxFlexFill *ngIf="fixedDepositProductData.withHoldTax">
          <span fxFlex="40%">{{"labels.withholdTaxGroup" | translate}}</span>
          <span fxFlex="60%">{{ fixedDepositProductData.taxGroup.name }}</span>
        </div>

        <div *ngIf="fixedDepositProductData.activeChart" fxFlexFill fxLayout="row wrap" fxLayout.lt-md="column" >

          <h3 class="mat-h3" fxFlexFill>{{"labels.heading.interestchart" | translate}}</h3>

          <mat-divider [inset]="true"></mat-divider>

          <div fxFlexFill  fxLayout="row wrap" fxLayout.lt-md="column">

            <div fxFlexFill *ngIf="fixedDepositProductData.activeChart.name">
              <span fxFlex="40%">{{"labels.heading.name" | translate}}):</span>
              <span fxFlex="60%">{{ fixedDepositProductData.activeChart.name }}</span>
            </div>

            <div fxFlexFill>
              <span fxFlex="40%">{{"labels.heading.interestratechart.valid.from" | translate}}):</span>
              <span fxFlex="60%">{{ fixedDepositProductData.activeChart.fromDate  | dateFormat }}</span>
            </div>

            <div fxFlexFill *ngIf="fixedDepositProductData.activeChart.endDate">
              <span fxFlex="40%">{{"labels.heading.interestratechart.valid.to" | translate}}):</span>
              <span fxFlex="60%">{{ fixedDepositProductData.activeChart.endDate  | dateFormat }}</span>
            </div>

            <div fxFlexFill *ngIf="fixedDepositProductData.activeChart.description">
              <span fxFlex="40%">{{"labels.heading.description" | translate}}:</span>
              <span fxFlex="60%">{{ fixedDepositProductData.activeChart.description }}</span>
            </div>

            <div fxFlexFill>
              <span fxFlex="40%">{{"labels.primaryGroupingAmount" | translate}}):</span>
              <span fxFlex="60%">{{ fixedDepositProductData.activeChart.isPrimaryGroupingByAmount ? 'Yes' : 'No' }}</span>
            </div>

            <table fxFlexFill class="mat-elevation-z1" mat-table [dataSource]="fixedDepositProductData.activeChart.chartSlabs" multiTemplateDataRows>

              <ng-container matColumnDef="period">
                <th mat-header-cell *matHeaderCellDef>{{"labels.Period" | translate}} </th>
                <td mat-cell *matCellDef="let chartSlab">
                  <div *ngIf="chartSlab.fromPeriod !== undefined">
                    {{ chartSlab.fromPeriod }} - {{ chartSlab.toPeriod }} {{ chartSlab.periodType.value }}
                  </div>
                  <div *ngIf="chartSlab.fromPeriod == undefined">
                    &nbsp;
                  </div>
                </td>
              </ng-container>

              <ng-container matColumnDef="amountRange">
                <th mat-header-cell *matHeaderCellDef>{{"labels.heading.interestratedetails.amount.range" | translate}} </th>
                <td mat-cell *matCellDef="let chartSlab">
                  <div *ngIf="chartSlab.amountRangeFrom !== undefined">
                    {{ chartSlab.amountRangeFrom }} - {{ chartSlab.amountRangeTo }}
                  </div>

                  <div *ngIf="chartSlab.amountRangeFrom == undefined">
                    &nbsp;
                  </div>
                </td>
              </ng-container>

              <ng-container matColumnDef="annualInterestRate">
                <th mat-header-cell *matHeaderCellDef>{{"labels.heading.interest" | translate}} </th>
                <td mat-cell *matCellDef="let chartSlab">
                  {{ chartSlab.annualInterestRate }}
                </td>
              </ng-container>

              <ng-container matColumnDef="description">
                <th mat-header-cell *matHeaderCellDef>{{"labels.heading.description" | translate}} </th>
                <td mat-cell *matCellDef="let chartSlab">
                  {{ chartSlab.description }}
                </td>
              </ng-container>

              <ng-container matColumnDef="actions">
                <th mat-header-cell *matHeaderCellDef>{{"labels.inputs.actions" | translate}} </th>
                <td mat-cell *matCellDef="let chartSlab; let chartSlabIndex = dataIndex">
                  <button mat-button color="primary" (click)="expandChartSlabIndex = expandChartSlabIndex === chartSlabIndex ? null : chartSlabIndex">
                    <span *ngIf="expandChartSlabIndex !== chartSlabIndex">
                      <fa-icon icon="eye"></fa-icon>&nbsp;&nbsp;
                      {{"labels.viewIncentives" | translate}}
                    </span>
                    <span *ngIf="expandChartSlabIndex === chartSlabIndex">
                      <fa-icon icon="eye-slash"></fa-icon>&nbsp;&nbsp;
                      {{"labels.hideIncentives" | translate}}
                    </span>
                  </button>
                </td>
              </ng-container>

              <ng-container matColumnDef="incentives">
                <td mat-cell *matCellDef="let chartSlab; let chartSlabIndex = dataIndex" [attr.colspan]="chartSlabsDisplayedColumns.length">
                  <div fxLayout="row wrap" fxFlexFill class="incentives" [@expandChartSlab]="chartSlabIndex === expandChartSlabIndex? 'expanded' : 'collapsed'">
                    <mat-card fxLayout="row wrap" fxFlexFill>

                      <h4 class="mat-h4" fxFlex="23%">
                        {{"labels.incentives" | translate}}
                      </h4>

                      <table fxFlexFill class="mat-elevation-z1" mat-table [dataSource]="chartSlab.incentives">

                        <ng-container matColumnDef="entityType">
                          <th mat-header-cell *matHeaderCellDef>{{"labels.entityType" | translate}} </th>
                          <td mat-cell *matCellDef="let incentive">
                            {{ incentive.entityType | find:fixedDepositProductsTemplate.chartTemplate.entityTypeOptions:'id':'value' }}
                          </td>
                        </ng-container>

                        <ng-container matColumnDef="attributeName">
                          <th mat-header-cell *matHeaderCellDef>{{"labels.attributeName" | translate}} </th>
                          <td mat-cell *matCellDef="let incentive">
                            {{ incentive.attributeName | find:fixedDepositProductsTemplate.chartTemplate.attributeNameOptions:'id':'value' }}
                          </td>
                        </ng-container>

                        <ng-container matColumnDef="conditionType">
                          <th mat-header-cell *matHeaderCellDef>{{"labels.conditionType" | translate}} </th>
                          <td mat-cell *matCellDef="let incentive">
                            {{ incentive.conditionType | find:fixedDepositProductsTemplate.chartTemplate.conditionTypeOptions:'id':'value' }}
                          </td>
                        </ng-container>

                        <ng-container matColumnDef="attributeValue">
                          <th mat-header-cell *matHeaderCellDef>{{"labels.attributeName" | translate}} </th>
                          <td mat-cell *matCellDef="let incentive" [ngSwitch]="incentive.attributeName">
                            <span *ngSwitchCase="2">{{ incentive.attributeValue | find:fixedDepositProductsTemplate.chartTemplate.genderOptions:'id':'name' }}</span>
                            <span *ngSwitchCase="3">{{ incentive.attributeValue }}</span>
                            <span *ngSwitchCase="4">{{ incentive.attributeValue | find:fixedDepositProductsTemplate.chartTemplate.clientTypeOptions:'id':'name' }}</span>
                            <span *ngSwitchCase="5">{{ incentive.attributeValue | find:fixedDepositProductsTemplate.chartTemplate.clientClassificationOptions:'id':'name' }}</span>
                          </td>
                        </ng-container>

                        <ng-container matColumnDef="incentiveType">
                          <th mat-header-cell *matHeaderCellDef>{{"labels.incentiveType" | translate}} </th>
                          <td mat-cell *matCellDef="let incentive">
                            {{ incentive.incentiveType | find:fixedDepositProductsTemplate.chartTemplate.incentiveTypeOptions:'id':'value'}}
                          </td>
                        </ng-container>

                        <ng-container matColumnDef="amount">
                          <th mat-header-cell *matHeaderCellDef>{{"labels.heading.amount" | translate}}</th>
                          <td mat-cell *matCellDef="let incentive">
                            {{ incentive.amount }}
                          </td>
                        </ng-container>

                        <tr mat-header-row *matHeaderRowDef="incentivesDisplayedColumns"></tr>
                        <tr mat-row *matRowDef="let row; columns: incentivesDisplayedColumns;"></tr>

                      </table>

                    </mat-card>
                  </div>
                </td>
              </ng-container>

              <tr mat-header-row *matHeaderRowDef="chartSlabsDisplayedColumns"></tr>
              <tr mat-row *matRowDef="let row; columns: chartSlabsDisplayedColumns"></tr>
              <tr mat-row *matRowDef="let row; columns: chartSlabsIncentivesDisplayedColumns;" class="incentives-row"></tr>

            </table>

          </div>

        </div>

        <div *ngIf="fixedDepositProductData.charges.length" fxFlexFill fxLayout="row wrap" fxLayout.lt-md="column">

          <h3 class="mat-h3" fxFlexFill>{{"labels.anchors.charges" | translate}}</h3>

          <mat-divider fxFlexFill></mat-divider>

          <table fxFlexFill class="mat-elevation-z1" mat-table [dataSource]="fixedDepositProductData.charges">

            <ng-container matColumnDef="name">
              <th mat-header-cell *matHeaderCellDef>{{"labels.inputs.name" | translate}}</th>
              <td mat-cell *matCellDef="let charge">
                {{ charge.name }}
              </td>
            </ng-container>

            <ng-container matColumnDef="type">
              <th mat-header-cell *matHeaderCellDef>{{"labels.inputs.type" | translate}}</th>
              <td mat-cell *matCellDef="let charge">
                {{ charge.chargeCalculationType.value }}
              </td>
            </ng-container>

            <ng-container matColumnDef="amount">
              <th mat-header-cell *matHeaderCellDef>{{"labels.heading.amount" | translate}}</th>
              <td mat-cell *matCellDef="let charge">
                {{ charge.amount | number }}
              </td>
            </ng-container>

            <ng-container matColumnDef="collectedon">
              <th mat-header-cell *matHeaderCellDef>{{"labels.heading.collectedon" | translate}}</th>
              <td mat-cell *matCellDef="let charge">
                {{ charge.chargeTimeType.value }}
              </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="chargesDisplayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: chargesDisplayedColumns;"></tr>

          </table>

        </div>

        <h3 class="mat-h3" fxFlexFill>{{"labels.menus.Accounting" | translate}}</h3>

        <mat-divider [inset]="true"></mat-divider>

        <div fxFlexFill>
          <span fxFlex="40%">{{"labels.inputs.type" | translate}}:</span>
          <span fxFlex="60%">{{ fixedDepositProductData.accountingRule.value }}</span>
        </div>

        <div fxFlexFill *ngIf="fixedDepositProductData.accountingRule.id === 2" fxLayout="row wrap" fxLayout.lt-md="column">

          <h4 fxFlexFill class="mat-h4">{{"labels.assets" | translate}}</h4>

          <div fxFlexFill fxLayout="row wrap" fxLayout.lt-md="column">
            <span fxFlex="40%">{{"labels.inputs.savingreference" | translate}}:</span>
            <span fxFlex="60%">{{ fixedDepositProductData.accountingMappings.savingsReferenceAccount.name }}</span>
          </div>

          <h4 fxFlexFill class="mat-h4">{{"labels.heading.liabilities" | translate}} </h4>

          <div fxFlexFill fxLayout="row wrap" fxLayout.lt-md="column">
            <span fxFlex="40%">{{"labels.inputs.savingcontrol" | translate}}:</span>
            <span fxFlex="60%">{{ fixedDepositProductData.accountingMappings.savingsControlAccount.name }}</span>
            <span fxFlex="40%">{{"labels.inputs.savingstransfersinsuspenpense" | translate}}:</span>
            <span fxFlex="60%">{{ fixedDepositProductData.accountingMappings.transfersInSuspenseAccount.name }}</span>
          </div>

          <h4 fxFlexFill class="mat-h4">{{"labels.heading.expenses" | translate}} </h4>

          <div fxFlexFill fxLayout="row wrap" fxLayout.lt-md="column">
            <span fxFlex="40%">{{"labels.inputs.interestonsavings" | translate}}:</span>
            <span fxFlex="60%">{{ fixedDepositProductData.accountingMappings.interestOnSavingsAccount.name }}</span>
          </div>

          <h4 fxFlexFill class="mat-h4">{{"labels.heading.income" | translate}} </h4>

          <div fxFlexFill fxLayout="row wrap" fxLayout.lt-md="column">
            <span fxFlex="40%">{{"labels.heading.incomefromfees" | translate}}:</span>
            <span fxFlex="60%">{{ fixedDepositProductData.accountingMappings.incomeFromFeeAccount.name }}</span>
            <span fxFlex="40%">{{"labels.heading.incomefrompenalties" | translate}}:</span>
            <span fxFlex="60%">{{ fixedDepositProductData.accountingMappings.incomeFromPenaltyAccount.name }}</span>
          </div>

        <div fxFlexFill *ngIf="fixedDepositProductData.advancedAccountingRules && (fixedDepositProductData.paymentChannelToFundSourceMappings?.length || fixedDepositProductData.feeToIncomeAccountMappings?.length || fixedDepositProductData.penaltyToIncomeAccountMappings?.length)" fxLayout="row wrap" fxLayout.lt-md="column">

          <h3 class="mat-h3" fxFlexFill>{{"labels.heading.advancedaccountingrules" | translate}} </h3>

          <mat-divider [inset]="true"></mat-divider>

          <hr>

          <div *ngIf="fixedDepositProductData.paymentChannelToFundSourceMappings?.length" fxFlexFill fxLayout="row wrap" fxLayout.lt-md="column">
            <h4 class="mat-h4" fxFlexFill>{{"labels.heading.configurefundsourcesforpaymentchannels" | translate}} </h4>

            <table fxFlexFill class="mat-elevation-z1" mat-table
              [dataSource]="fixedDepositProductData.paymentChannelToFundSourceMappings">

              <ng-container matColumnDef="paymentTypeId">
                <th mat-header-cell *matHeaderCellDef>{{"labels.heading.paymenttype" | translate}} </th>
                <td mat-cell *matCellDef="let paymentFundSource">
                  {{ paymentFundSource.paymentType.name }}
                </td>
              </ng-container>

              <ng-container matColumnDef="fundSourceAccountId">
                <th mat-header-cell *matHeaderCellDef>{{"labels.heading.fundsource" | translate}} </th>
                <td mat-cell *matCellDef="let paymentFundSource">
                  {{ paymentFundSource.fundSourceAccount.name }}
                </td>
              </ng-container>

              <tr mat-header-row *matHeaderRowDef="paymentFundSourceDisplayedColumns"></tr>
              <tr mat-row *matRowDef="let row; columns: paymentFundSourceDisplayedColumns;"></tr>

            </table>

          </div>

          <div *ngIf="fixedDepositProductData.feeToIncomeAccountMappings?.length" fxFlexFill fxLayout="row wrap" fxLayout.lt-md="column">

            <h4 class="mat-h4" fxFlexFill>{{"labels.mapFeesSpecificIncomeAccounts" | translate}}:</h4>

            <table fxFlexFill class="mat-elevation-z1" mat-table
              [dataSource]="fixedDepositProductData.feeToIncomeAccountMappings">

              <ng-container matColumnDef="chargeId">
                <th mat-header-cell *matHeaderCellDef>{{"labels.commons.fees" | translate}}:</th>
                <td mat-cell *matCellDef="let feesIncome">
                  {{ feesIncome.charge.name }}
                </td>
              </ng-container>

              <ng-container matColumnDef="incomeAccountId">
                <th mat-header-cell *matHeaderCellDef>{{"labels.heading.incomeaccount" | translate}} </th>
                <td mat-cell *matCellDef="let feesIncome">
                  {{ feesIncome.incomeAccount.name }}
                </td>
              </ng-container>

              <tr mat-header-row *matHeaderRowDef="feesPenaltyIncomeDisplayedColumns"></tr>
              <tr mat-row *matRowDef="let row; columns: feesPenaltyIncomeDisplayedColumns;"></tr>

            </table>

          </div>

          <div *ngIf="fixedDepositProductData.penaltyToIncomeAccountMappings?.length" fxFlexFill fxLayout="row wrap" fxLayout.lt-md="column">

            <h4 class="mat-h4" fxFlexFill>{{"labels.heading.mappenaltiestospecificincomeaccounts" | translate}}:</h4>

            <table fxFlexFill class="mat-elevation-z1" mat-table
              [dataSource]="fixedDepositProductData.penaltyToIncomeAccountMappings">

              <ng-container matColumnDef="chargeId">
                <th mat-header-cell *matHeaderCellDef>{{"labels.commons.penalty" | translate}} </th>
                <td mat-cell *matCellDef="let penaltyIncome">
                  {{ penaltyIncome.charge.name }}
                </td>
              </ng-container>

              <ng-container matColumnDef="incomeAccountId">
                <th mat-header-cell *matHeaderCellDef>{{"labels.heading.incomeaccount" | translate}} </th>
                <td mat-cell *matCellDef="let penaltyIncome">
                  {{ penaltyIncome.incomeAccount.name }}
                </td>
              </ng-container>

              <tr mat-header-row *matHeaderRowDef="feesPenaltyIncomeDisplayedColumns"></tr>
              <tr mat-row *matRowDef="let row; columns: feesPenaltyIncomeDisplayedColumns;"></tr>

              </table>

            </div>

          </div>

	      </div>

      </div>

    </mat-card-content>

  </mat-card>

</div>
