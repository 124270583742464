<div class="container m-b-20" fxLayout="row" fxLayoutAlign="end" fxLayoutGap="20px">

  <button mat-raised-button color="primary" [routerLink]="['edit']" *mifosxHasPermission="'UPDATE_ACCOUNTNUMBERFORMAT'">
    <fa-icon icon="edit"></fa-icon>&nbsp;&nbsp;{{"labels.inputs.edit" | translate }}</button>

  <button mat-raised-button color="warn" (click)="delete()" *mifosxHasPermission="'DELETE_ACCOUNTNUMBERFORMAT'">
    <fa-icon icon="trash"></fa-icon>&nbsp;&nbsp;{{"labels.anchors.delete" | translate }}</button>

</div>

<div class="container">

  <mat-card>

    <mat-card-content>

      <div fxLayout="row wrap" class="content">

        <div fxFlex="50%" class="mat-body-strong">{{"labels.inputs.accounttype" | translate }}</div>

        <div fxFlex="50%">
          {{ accountNumberPreferenceData.accountType.value }}
        </div>

        <div fxFlex="50%" class="mat-body-strong" *ngIf="accountNumberPreferenceData.prefixType">
          {{"labels.prefixType" | translate}}
        </div>

        <div fxFlex="50%" *ngIf="accountNumberPreferenceData.prefixType">
          {{ accountNumberPreferenceData.prefixType.value }}
        </div>

      </div>

    </mat-card-content>

  </mat-card>

</div>
