<mat-card class="container">

  <div fxLayout="row" fxLayoutAlign="start center">
    <div class="search-box">
      <mat-form-field>
        <input matInput placeholder="{{'labels.buttons.search' | translate}}" [formControl]="name">
      </mat-form-field>
      <mat-checkbox #showClosedGroups labelPosition="after"
        (change)="changeShowClosedGroups()">{{'labels.heading.showClosedGroups' | translate}}</mat-checkbox>
    </div>
    <div class="action-button" fxLayoutGap="25px">
      <button mat-raised-button color="primary" [routerLink]="['../', 'organization', 'bulk-import', 'Groups']">
        <fa-icon icon="download"></fa-icon>&nbsp;&nbsp;{{'labels.buttons.importGroup' | translate}}
      </button>
      <button mat-raised-button color="primary" [routerLink]="['create']" *mifosxHasPermission="'CREATE_GROUP'">
        <fa-icon icon="plus"></fa-icon>&nbsp;&nbsp;{{'labels.buttons.creategroup' | translate}}
      </button>
    </div>
  </div>

  <table mat-table [dataSource]="dataSource" matSort>

    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'labels.heading.name' | translate}} </th>
      <td mat-cell *matCellDef="let group"> {{ group.name }} </td>
    </ng-container>

    <ng-container matColumnDef="accountNo">
      <th mat-header-cell *matHeaderCellDef> {{'labels.heading.accnum' | translate}} # </th>
      <td mat-cell *matCellDef="let group"> {{ group.accountNo }} </td>
    </ng-container>

    <ng-container matColumnDef="externalId">
      <th mat-header-cell *matHeaderCellDef> {{'labels.heading.externalid' | translate}} </th>
      <td mat-cell *matCellDef="let group"> {{ group.externalId }} </td>
    </ng-container>

    <ng-container matColumnDef="status">
      <th mat-header-cell *matHeaderCellDef> {{'labels.heading.status' | translate}} </th>
      <td mat-cell *matCellDef="let row">
        <div [ngClass]="row.status.code|statusLookup">
          <!-- <fa-icon icon="stop"></fa-icon> -->
          {{ row.status.value }}
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="officeName">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'labels.heading.office' | translate}} </th>
      <td mat-cell *matCellDef="let group"> {{ group.officeName }} </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;" [routerLink]="[row.id]" class="select-row">
    </tr>

  </table>

  <mat-paginator [length]="dataSource?.records$ | async" [pageSize]="10" [pageSizeOptions]="[10, 25, 50, 100]"
    showFirstLastButtons></mat-paginator>

</mat-card>
