<div class="container">

  <mat-card>

    <div fxLayout="row" fxLayout.lt-md="column">

      <div fxFlex="50%">

        <mat-nav-list>

          <div #datatables>
            <mat-list-item [routerLink]="['data-tables']" *mifosxHasPermission="'READ_DATATABLE'">
              <mat-icon matListIcon>
                <fa-icon icon="table" size="sm"></fa-icon>
              </mat-icon>
              <h4 matLine>{{'labels.anchors.managedatatables'
                | translate}}</h4>
              <p matLine>{{'labels.addnewextrafieldstoanyentityintheformofdatatable' |
                translate}}</p>
            </mat-list-item>
          </div>

          <div #codes>
            <mat-list-item [routerLink]="['codes']" *mifosxHasPermission="'READ_CODE'">
              <mat-icon matListIcon>
                <fa-icon icon="list-ul" size="sm"></fa-icon>
              </mat-icon>
              <h4 matLine>{{'labels.anchors.managecodes'
                | translate}}</h4>
              <p matLine>{{'labels.codesareusedtodefinedropdownvalues' | translate}}</p>
            </mat-list-item>
          </div>

          <div #rolesandpermission>
            <mat-list-item [routerLink]="['roles-and-permissions']" *mifosxHasPermission="'READ_ROLE'">
              <mat-icon matListIcon>
                <fa-icon icon="key" size="sm"></fa-icon>
              </mat-icon>
              <h4 matLine>{{'labels.anchors.manageroleandpermissions'
                | translate}}</h4>
              <p matLine>{{'labels.defineormodifyrolesandassociatedpermissions' | translate}}</p>
            </mat-list-item>
          </div>

          <div #makerCheckerTable>
            <mat-list-item [routerLink]="['configure-mc-tasks']" *mifosxHasPermission="'READ_PERMISSION'">
              <mat-icon matListIcon>
                <fa-icon icon="sitemap" size="sm"></fa-icon>
              </mat-icon>
              <h4 matLine>{{'labels.anchors.configuremakercheckertask'
                | translate}}</h4>
              <p matLine>{{'labels.defineormodifymakercheckertasks' | translate}}</p>
            </mat-list-item>
          </div>

          <mat-list-item [routerLink]="['hooks']" *mifosxHasPermission="'READ_PERMISSION'">
            <mat-icon matListIcon>
              <fa-icon icon="anchor" size="sm"></fa-icon>
            </mat-icon>
            <h4 matLine>{{'labels.anchors.managehooks'
              | translate}}</h4>
            <p matLine>{{'labels.defineormodifyhooks' | translate}}</p>
          </mat-list-item>

          <mat-list-item [routerLink]="['entity-to-entity-mapping']" *mifosxHasPermission="'READ_PERMISSION'">
            <mat-icon matListIcon>
              <fa-icon icon="road" size="sm"></fa-icon>
            </mat-icon>
            <h4 matLine>{{'labels.anchors.entitytoentitymapping'
              | translate}}</h4>
            <p matLine>{{'labels.definemappings' | translate}}</p>
          </mat-list-item>

          <mat-list-item [routerLink]="['surveys']" *mifosxHasPermission="'READ_PERMISSION'">
            <mat-icon matListIcon>
              <fa-icon icon="file-alt" size="sm"></fa-icon>
            </mat-icon>
            <h4 matLine>{{'labels.anchors.managesurveys'
              | translate}}</h4>
            <p matLine>{{'labels.managesurveys' | translate}}</p>
          </mat-list-item>

          <mat-list-item [routerLink]="['external-events']" *mifosxHasPermission="'READ_EXTERNAL_EVENT_CONFIGURATION'">
            <mat-icon matListIcon>
              <fa-icon icon="anchor" size="sm"></fa-icon>
            </mat-icon>
            <h4 matLine>{{'labels.anchors.manageExternalEvents'
              | translate}}</h4>
            <p matLine>{{'labels.manageExternalEvents' | translate}}</p>
          </mat-list-item>

          <mat-list-item [routerLink]="['manage-tenant']">
            <mat-icon matListIcon>
              <fa-icon icon="table" size="sm"></fa-icon>
            </mat-icon>
            <h4 matLine>{{'labels.manageTenant'| translate}}</h4>
            <p matLine>{{'labels.manageTenant' | translate}}</p>
          </mat-list-item>
        </mat-nav-list>

      </div>

      <div fxFlex="50%">

        <mat-nav-list>

          <mat-list-item [routerLink]="['audit-trails']" *mifosxHasPermission="'READ_AUDIT'">
            <mat-icon matListIcon>
              <fa-icon icon="money-check" size="sm"></fa-icon>
            </mat-icon>
            <h4 matLine>{{'labels.anchors.audittrails'
              | translate}}</h4>
            <p matLine>{{'labels.auditlogsofalltheactivities' | translate}}</p>
          </mat-list-item>

          <mat-list-item [routerLink]="['reports']" *mifosxHasPermission="'READ_REPORT'">
            <mat-icon matListIcon>
              <fa-icon icon="file-word" size="sm"></fa-icon>
            </mat-icon>
            <h4 matLine>{{'labels.anchors.managereports'
              | translate}}</h4>
            <p matLine>{{'labels.addnewreportand' | translate}}</p>
          </mat-list-item>

          <div #rolesandpermission>
            <mat-list-item [routerLink]="['fields-permissions']" *mifosxHasPermission="'READ_ROLE'">
              <mat-icon matListIcon>
                <fa-icon icon="briefcase" size="sm"></fa-icon>
              </mat-icon>
              <h4 matLine>{{"labels.managePermissionsFields" | translate}}</h4>
              <p matLine>{{"labels.defineModifyPermissionsFields" | translate}}</p>
            </mat-list-item>
          </div>

          <mat-list-item [routerLink]="['manage-jobs']" *mifosxHasPermission="'READ_SCHEDULER'">
            <mat-icon matListIcon>
              <fa-icon icon="clock" size="sm"></fa-icon>
            </mat-icon>
            <h4 matLine>{{'labels.anchors.schedulerjobs'
              | translate}}</h4>
            <p matLine>{{'labels.scheduleajobmodifyjobs' | translate}}</p>
          </mat-list-item>

          <mat-list-item [routerLink]="['configurations']" *mifosxHasPermission="'READ_CONFIGURATION'">
            <mat-icon matListIcon>
              <fa-icon icon="cogs" size="sm"></fa-icon>
            </mat-icon>
            <h4 matLine>{{'labels.anchors.configuration'
              | translate}}</h4>
            <p matLine>{{'labels.globalconfigurationsand' | translate}}</p>
          </mat-list-item>

          <mat-list-item [routerLink]="['account-number-preferences']" *mifosxHasPermission="'READ_PERMISSION'">
            <mat-icon matListIcon>
              <fa-icon icon="key" size="sm"></fa-icon>
            </mat-icon>
            <h4 matLine>{{'labels.anchors.accountNumberPreferences'
              | translate}}</h4>
            <p matLine>{{'labels.accountNumberPreferences' | translate}}</p>
          </mat-list-item>

          <mat-list-item [routerLink]="['external-services']" *mifosxHasPermission="'READ_CONFIGURATION'">
            <mat-icon matListIcon>
              <fa-icon icon="cog" size="sm"></fa-icon>
            </mat-icon>
            <h4 matLine>{{'labels.anchors.externalServices'
              | translate}}</h4>
            <p matLine>{{'labels.externalServices' | translate}}</p>
          </mat-list-item>

          <mat-list-item *mifosxHasPermission="'READ_CONFIGURATION'">
            <mat-icon matListIcon>
              <fa-icon icon="key" size="sm"></fa-icon>
            </mat-icon>
            <h4 matLine>{{'labels.anchors.twofactorconfig'
              | translate}}</h4>
            <p matLine>{{'labels.twofactorconfig' | translate}}</p>
          </mat-list-item>

        </mat-nav-list>

      </div>

    </div>

  </mat-card>

</div>

<ng-template #templateDatatables let-popover="popover">
  <h2>{{'labels.anchors.managedatatables' | translate}}</h2>
  <p class="mw300">{{'labels.heading.managedatatablesText' | translate: {'name' : appName} }}.</p>
  <div fxLayout="row" fxLayoutAlign="end" fxLayoutGap="2%" fxLayout.lt-md="column">
    <button mat-raised-button color="warn"
      (click)="popover.close();configurationWizardService.closeConfigWizard()">{{'labels.buttons.close' |
      translate}}</button>
    <button mat-raised-button color="primary" (click)="popover.close();previousStepDatatables()">{{'labels.buttons.back'
      | translate}}</button>
    <button mat-raised-button color="primary" (click)="popover.close();nextStepDatatables()">{{'labels.buttons.next' |
      translate}}</button>
  </div>
</ng-template>

<ng-template #templateCodes let-popover="popover">
  <h2>{{'labels.anchors.managecodes' | translate}}</h2>
  <p class="mw300">{{'labels.heading.managecodesText' | translate}}.</p>
  <div fxLayout="row" fxLayoutAlign="end" fxLayoutGap="2%" fxLayout.lt-md="column">
    <button mat-raised-button color="warn"
      (click)="popover.close();configurationWizardService.closeConfigWizard()">{{'labels.buttons.close' |
      translate}}</button>
    <button mat-raised-button color="primary" (click)="popover.close();previousStepCodes()">{{'labels.buttons.back' |
      translate}}</button>
    <button mat-raised-button color="primary" (click)="popover.close();nextStepCodes()">{{'labels.buttons.next' |
      translate}}</button>
  </div>
</ng-template>

<ng-template #templateRolesandPermission let-popover="popover">
  <h2>{{'labels.anchors.manageroleandpermissions' | translate}}</h2>
  <p class="mw300">{{'labels.heading.manageroleandpermissionsText' | translate : {'name' : appName} }}.</p>
  <div fxLayout="row" fxLayoutAlign="end" fxLayoutGap="2%" fxLayout.lt-md="column">
    <button mat-raised-button color="warn"
      (click)="popover.close();configurationWizardService.closeConfigWizard()">{{'labels.buttons.close' |
      translate}}</button>
    <button mat-raised-button color="primary"
      (click)="popover.close();previousStepRolesandPermission()">{{'labels.buttons.back' | translate}}</button>
    <button mat-raised-button color="primary"
      (click)="popover.close();nextStepRolesandPermission()">{{'labels.buttons.next' | translate}}</button>
  </div>
</ng-template>

<ng-template #templateMakerCheckerTable let-popover="popover">
  <h2>{{'labels.anchors.configuremakercheckertask' | translate}}</h2>
  <p class="mw300">{{'labels.heading.configuremakercheckertaskText' | translate}}.</p>
  <div fxLayout="row" fxLayoutAlign="end" fxLayoutGap="2%" fxLayout.lt-md="column">
    <button mat-raised-button color="warn"
      (click)="popover.close();configurationWizardService.closeConfigWizard()">{{'labels.buttons.close' |
      translate}}</button>
    <button mat-raised-button color="primary"
      (click)="popover.close();previousStepMakerCheckerTable()">{{'labels.buttons.back' | translate}}</button>
    <button mat-raised-button color="primary"
      (click)="popover.close();nextStepMakerCheckerTable()">{{'labels.buttons.next' | translate}}</button>
  </div>
</ng-template>

<ng-template #templateConfigurations let-popover="popover">
  <h2>{{'labels.anchors.configuration' | translate}}</h2>
  <p class="mw300">{{'labels.heading.configurationText' | translate}}.
  </p>
  <div fxLayout="row" fxLayoutAlign="end" fxLayoutGap="2%" fxLayout.lt-md="column">
    <button mat-raised-button color="warn"
      (click)="popover.close();configurationWizardService.closeConfigWizard()">{{'labels.buttons.close' |
      translate}}</button>
    <button mat-raised-button color="primary"
      (click)="popover.close();previousStepConfigurations()">{{'labels.buttons.back' | translate}}</button>
    <button mat-raised-button color="primary" (click)="popover.close();nextStepConfigurations()">{{'labels.buttons.next'
      | translate}}</button>
  </div>
</ng-template>

<ng-template #templateSchedulerJobs let-popover="popover">
  <h2>{{'labels.anchors.schedulerjobs' | translate}}</h2>
  <p class="mw300">{{'labels.heading.schedulerjobsText' | translate}}.</p>
  <div fxLayout="row" fxLayoutAlign="end" fxLayoutGap="2%" fxLayout.lt-md="column">
    <button mat-raised-button color="warn"
      (click)="popover.close();configurationWizardService.closeConfigWizard()">{{'labels.buttons.close' |
      translate}}</button>
    <button mat-raised-button color="primary"
      (click)="popover.close();previousStepSchedulerJobs()">{{'labels.buttons.back' | translate}}</button>
    <button mat-raised-button color="primary" (click)="popover.close();nextStepSchedulerJobs()">{{'labels.buttons.next'
      | translate}}</button>
  </div>
</ng-template>

<ng-template #templateManageReports let-popover="popover">
  <h2>{{'labels.anchors.managereports' | translate}}</h2>
  <p class="mw300">{{'labels.heading.managereportsText' | translate}}.</p>
  <div fxLayout="row" fxLayoutAlign="end" fxLayoutGap="2%" fxLayout.lt-md="column">
    <button mat-raised-button color="warn"
      (click)="popover.close();configurationWizardService.closeConfigWizard()">{{'labels.buttons.close' |
      translate}}</button>
    <button mat-raised-button color="primary"
      (click)="popover.close();previousStepManageReports()">{{'labels.buttons.back' | translate}}</button>
    <button mat-raised-button color="primary" (click)="popover.close();nextStepManageReports()">{{'labels.buttons.next'
      | translate}}</button>
  </div>
</ng-template>
