<table mat-table [dataSource]="loans">

  <ng-container matColumnDef="Account No">
    <th mat-header-cell *matHeaderCellDef>{{ 'labels.loanId' | translate }}</th>
    <td mat-cell *matCellDef="let element"> <i class="fa fa-stop"
        [ngClass]="element.inArrears?'status-active-overdue':(element.status.code|statusLookup)"></i>
        {{element.id}}-{{element.productName}}
      </td>
  </ng-container>

  <ng-container matColumnDef="Original Loan">
    <th mat-header-cell *matHeaderCellDef>{{ 'labels.heading.originalLoan' | translate }}</th>
    <td mat-cell *matCellDef="let element" > {{element.originalLoan}} </td>
  </ng-container>

  <ng-container matColumnDef="Amount Paid">
    <th mat-header-cell *matHeaderCellDef>{{ 'labels.heading.amountPaid' | translate }}</th>
    <td mat-cell *matCellDef="let element" > {{element.amountPaid}} </td>
  </ng-container>



  <tr mat-header-row *matHeaderRowDef="openLoansColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: openLoansColumns;" [routerLink]="['..',row.id]" class="select-row"></tr>

</table>
