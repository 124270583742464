<!-- TODO: Refactor Permissions -->
<div class="container">

  <mat-card>

    <div fxLayout="row" fxLayout.lt-md="column">

      <div fxFlex="50%">

        <mat-nav-list>

          <mat-list-item [routerLink]="['Offices']" *mifosxHasPermission="'READ_OFFICE'">
            <mat-icon matListIcon>
              <fa-icon icon="building" size="sm"></fa-icon>
            </mat-icon>
            <h4 matLine>{{'labels.anchors.importoffices' | translate}}</h4>
          </mat-list-item>

          <mat-list-item [routerLink]="['Users']" *mifosxHasPermission="'READ_OFFICE'">
            <mat-icon matListIcon>
              <fa-icon icon="user" size="sm"></fa-icon>
            </mat-icon>
            <h4 matLine>{{'labels.anchors.importusers' | translate}}</h4>
          </mat-list-item>

          <mat-list-item [routerLink]="['Groups']" *mifosxHasPermission="'READ_OFFICE'">
            <mat-icon matListIcon>
              <fa-icon icon="users" size="sm"></fa-icon>
            </mat-icon>
            <h4 matLine>{{'labels.anchors.importgroups' | translate}}</h4>
          </mat-list-item>

          <mat-list-item [routerLink]="['Loan Accounts']" *mifosxHasPermission="'READ_OFFICE'">
            <mat-icon matListIcon>
              <fa-icon icon="money-bill-alt" size="sm"></fa-icon>
            </mat-icon>
            <h4 matLine>{{'labels.anchors.importloanaccounts' | translate}}</h4>
          </mat-list-item>

          <mat-list-item [routerLink]="['Savings Accounts']" *mifosxHasPermission="'READ_OFFICE'">
            <mat-icon matListIcon>
              <fa-icon icon="money-bill-alt" size="sm"></fa-icon>
            </mat-icon>
            <h4 matLine>{{'labels.anchors.importsavingsaccounts' | translate}}</h4>
          </mat-list-item>

          <mat-list-item [routerLink]="['Fixed Deposit Accounts']" *mifosxHasPermission="'READ_OFFICE'">
            <mat-icon matListIcon>
              <fa-icon icon="money-bill-alt" size="sm"></fa-icon>
            </mat-icon>
            <h4 matLine>{{'labels.anchors.importfdaccounts' | translate}}</h4>
          </mat-list-item>

          <mat-list-item [routerLink]="['Chart of Accounts']" *mifosxHasPermission="'READ_OFFICE'">
            <mat-icon matListIcon>
              <fa-icon icon="money-bill-alt" size="sm"></fa-icon>
            </mat-icon>
            <h4 matLine>{{'labels.anchors.importcoa' | translate}}</h4>
          </mat-list-item>

          <mat-list-item [routerLink]="['Share Accounts']" *mifosxHasPermission="'READ_OFFICE'">
            <mat-icon matListIcon>
              <fa-icon icon="money-bill-alt" size="sm"></fa-icon>
            </mat-icon>
            <h4 matLine>{{'labels.anchors.importshareaccounts' | translate}}</h4>
          </mat-list-item>

        </mat-nav-list>

      </div>

      <div fxFlex="50%">

        <mat-nav-list>

          <mat-list-item [routerLink]="['Employees']" *mifosxHasPermission="'READ_OFFICE'">
            <mat-icon matListIcon>
              <fa-icon icon="user" size="sm"></fa-icon>
            </mat-icon>
            <h4 matLine>{{'labels.anchors.employees' | translate}}</h4>
          </mat-list-item>


          <mat-list-item [routerLink]="['Clients']" *mifosxHasPermission="'READ_OFFICE'">
            <mat-icon matListIcon>
              <fa-icon icon="user" size="sm"></fa-icon>
            </mat-icon>
            <h4 matLine>{{'labels.anchors.importclients' | translate}}</h4>
          </mat-list-item>

          <mat-list-item [routerLink]="['Centers']" *mifosxHasPermission="'READ_OFFICE'">
            <mat-icon matListIcon>
              <fa-icon icon="users" size="sm"></fa-icon>
            </mat-icon>
            <h4 matLine>{{'labels.anchors.importcenters' | translate}}</h4>
          </mat-list-item>

          <mat-list-item [routerLink]="['Loan Repayments']" *mifosxHasPermission="'READ_OFFICE'">
            <mat-icon matListIcon>
              <fa-icon icon="chevron-right" size="sm"></fa-icon>
            </mat-icon>
            <h4 matLine>{{'labels.anchors.importloanrepayments' | translate}}</h4>
          </mat-list-item>

          <mat-list-item [routerLink]="['Savings Transactions']" *mifosxHasPermission="'READ_OFFICE'">
            <mat-icon matListIcon>
              <fa-icon icon="chevron-right" size="sm"></fa-icon>
            </mat-icon>
            <h4 matLine>{{'labels.anchors.importsavingstransactions' | translate}}</h4>
          </mat-list-item>

          <mat-list-item [routerLink]="['Fixed Deposit Transactions']" *mifosxHasPermission="'READ_OFFICE'">
            <mat-icon matListIcon>
              <fa-icon icon="chevron-right" size="sm"></fa-icon>
            </mat-icon>
            <h4 matLine>{{'labels.anchors.importfdtransactions' | translate}}</h4>
          </mat-list-item>

          <mat-list-item [routerLink]="['Recurring Deposit Transactions']" *mifosxHasPermission="'READ_OFFICE'">
            <mat-icon matListIcon>
              <fa-icon icon="chevron-right" size="sm"></fa-icon>
            </mat-icon>
            <h4 matLine>{{'labels.anchors.importrdtransactions' | translate}}</h4>
          </mat-list-item>

          <mat-list-item [routerLink]="['Journal Entries']" *mifosxHasPermission="'READ_OFFICE'">
            <mat-icon matListIcon>
              <fa-icon icon="chevron-right" size="sm"></fa-icon>
            </mat-icon>
            <h4 matLine>{{'labels.anchors.importjournalentries' | translate}}</h4>
          </mat-list-item>

          <mat-list-item [routerLink]="['Guarantors']" *mifosxHasPermission="'READ_OFFICE'">
            <mat-icon matListIcon>
              <fa-icon icon="chevron-right" size="sm"></fa-icon>
            </mat-icon>
            <h4 matLine>{{'labels.anchors.importguarantor' | translate}}</h4>
          </mat-list-item>

        </mat-nav-list>

      </div>

    </div>

  </mat-card>

</div>