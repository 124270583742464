<form [formGroup]="shareProductCurrencyForm">

  <div fxLayout="row wrap" fxLayoutGap="2%" fxLayout.lt-md="column">

    <mat-form-field fxFlex="48%">
      <mat-label>{{"labels.inputs.currency" | translate}}</mat-label>
      <mat-select formControlName="currencyCode" required>
        <mat-option *ngFor="let currency of currencyData" [value]="currency.code">
          {{ currency.name }}
        </mat-option>
      </mat-select>
      <mat-error>
        {{"labels.inputs.currency" | translate}} <strong>{{"labels.commons.is required" | translate}}</strong>
      </mat-error>
    </mat-form-field>

    <mat-form-field fxFlex="48%">
      <mat-label>{{"labels.inputs.decimalplace" | translate}}</mat-label>
      <input type="number" matInput formControlName="digitsAfterDecimal" required>
      <mat-error>
        {{"labels.inputs.decimalplace" | translate}} <strong>{{"labels.commons.is required" | translate}}</strong>
      </mat-error>
    </mat-form-field>

    <mat-form-field fxFlex="48%">
      <mat-label>{{"labels.heading.currencyinmultiplesof" | translate}}</mat-label>
      <input type="number" matInput formControlName="inMultiplesOf" required>
      <mat-error>
        {{"labels.heading.currencyinmultiplesof" | translate}} <strong>{{"labels.commons.is required" | translate}}</strong>
      </mat-error>
    </mat-form-field>

  </div>

  <div fxLayout="row" class="margin-t" fxLayout.xs="column" fxLayoutAlign="center" fxLayoutGap="2%">
    <button mat-raised-button matStepperPrevious>
      <fa-icon icon="arrow-left"></fa-icon>&nbsp;&nbsp;
      {{"labels.buttons.previous" | translate}}
    </button>
    <button mat-raised-button matStepperNext>
      {{"labels.buttons.next" | translate}}&nbsp;&nbsp;
      <fa-icon icon="arrow-right"></fa-icon>
    </button>
  </div>

</form>
