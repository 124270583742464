<!-- The viewport is divided into two parts having max-width 70% and 30% -->
<div fxLayout="row">

  <!--
    Content in 70%: (TODO: Allow customization by organization)
      Cover Image
      Organization/Application Name
      Organization/Application Description

    This content will be hidden for smaller screens.
  -->

  <div fxFlex="70%" fxLayout="column" fxLayoutAlign="center center" fxHide.lt-md class="cover">
    <div class="cover-content">
      <h1 class="mat-display-1 cover-title">{{ tenantData?.appName | translate }}</h1>
      <p class="mat-headline cover-description">
        {{ tenantData?.appName }} {{"labels.isDesignedInitiativeGlobalCommunity" | translate: {'name' : tenantData?.appName} }}
      </p>
    </div>
  </div>

  <!--
    Content in 30%: (TODO: Allow customization of Name, Logo and Contact Information by organization)
      Language Selector
      Organization/Application Name with Logo
      Form
      Contact Information/Resources
      Footer
  -->

  <div fxLayout="column" fxFlex.gt-md="1 0 30%" class="login-container">

    <!-- Language Selector to the extreme right -->
    <div fxLayout="row-reverse" fxFlex="1 0 auto">
      <mifosx-language-selector class="p-r-10 p-t-10"></mifosx-language-selector>
      <div fxFlex></div>
      <mifosx-server-selector class="p-l-10 p-t-10" *ngIf="environment.allowServerSwitch"></mifosx-server-selector>
    </div>

    <!-- Logo with Organization Name -->
    <div fxLayout="row" fxLayoutAlign="center center" fxFlex="1 0 auto">
      <img [src]="tenantData.logo ? logoSrc :'assets/images/logo.png'" id="logo" alt="{{ tenantData?.appName }} Logo" class="img-container" loading="eager">
    </div>

    <!--
      Type of Form:
        Login
        Reset Password
        Two Factor Authentication
        Forgot Password (TODO: Decision to be taken on providing this feature.)
    -->
    <div fxLayout="row" fxFlex="3 0 auto" fxLayoutAlign="center start">
      <mifosx-login-form *ngIf="!resetPassword && !twoFactorAuthenticationRequired"></mifosx-login-form>
      <mifosx-reset-password *ngIf="resetPassword"></mifosx-reset-password>
      <mifosx-two-factor-authentication *ngIf="twoFactorAuthenticationRequired"></mifosx-two-factor-authentication>
    </div>

    <!-- Contact Information/Resources (hidden on smaller screens) -->


    <!-- Footer -->
    <mifosx-footer></mifosx-footer>

  </div>

</div>
