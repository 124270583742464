<div class="container">

  <div #filter fxLayout="row" fxLayoutGap="20px">
    <mat-form-field fxFlex>
      <mat-label>&nbsp;&nbsp;{{"labels.buttons.filter" | translate }}</mat-label>
      <input matInput (keyup)="applyFilter($event.target.value)">
    </mat-form-field>
  </div>

  <div #tableRolesandPermissions class="mat-elevation-z8">

    <table mat-table [dataSource]="dataSource" matSort>

      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>{{"labels.inputs.name" | translate }}</th>
        <td mat-cell *matCellDef="let role"> {{ role.name }} </td>
      </ng-container>

      <ng-container matColumnDef="description">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>{{"labels.inputs.description" | translate }}</th>
        <td mat-cell *matCellDef="let role"> {{ role.description }} </td>
      </ng-container>

      <ng-container matColumnDef="disabled">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>{{"labels.inputs.status" | translate }}</th>
        <td mat-cell *matCellDef="let role">
          <div [className]="role.disabled === true ? 'false' : 'true'">
            <fa-icon matTooltip="{{ role.disabled === true ? 'Disabled' : 'Enabled' }}" matTooltipPosition="right" icon="circle" size="lg"></fa-icon>
          </div>
        </td>
      </ng-container>

      <!-- <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef>{{"labels.inputs.actions" | translate}}</th>
        <td mat-cell *matCellDef="let role;" >
          <button *ngIf="role.name !== 'Super user'" mat-icon-button color="primary" (click)="routeEdit($event)" [routerLink]="[role.id, 'edit']">
            <fa-icon icon="edit">{{"labels.inputs.edit" | translate }}</fa-icon>
          </button>
        </td>
      </ng-container> -->

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;" [routerLink]="[row.id]" class="select-row"></tr>

    </table>

    <mat-paginator [pageSizeOptions]="[10, 25, 50, 100]" showFirstLastButtons></mat-paginator>

  </div>

</div>

<ng-template #templateButtonAddRole let-popover="popover">
  <h2>{{"labels.buttons.addrole" | translate}}:</h2>
  <p class="mw300">{{"labels.toCreateNewRole" | translate}}:</p>
  <div fxLayout="row" fxLayoutAlign="end" fxLayoutGap="2%" fxLayout.lt-md="column">
    <button mat-raised-button color="warn" (click)="popover.close();configurationWizardService.closeConfigWizard()">{{"labels.buttons.close" | translate}}</button>
    <button mat-raised-button color="primary" (click)="popover.close();previousStep();">{{"labels.buttons.back" | translate}}</button>
    <button mat-raised-button color="primary" (click)="popover.close();showPopover(templateFilter, filter, 'bottom', true);">{{"labels.buttons.next" | translate}}</button>
  </div>
</ng-template>

<ng-template #templateFilter let-popover="popover">
  <h4>{{"labels.SearchBarFilterroles" | translate}}:</h4>
  <div fxLayout="row" fxLayoutAlign="end" fxLayoutGap="2%" fxLayout.lt-md="column">
    <button mat-raised-button color="warn" (click)="popover.close();configurationWizardService.closeConfigWizard()">{{"labels.buttons.close" | translate}}</button>
    <button mat-raised-button color="primary" (click)="popover.close();showPopover(templateButtonAddRole, buttonAddRole, 'bottom', true)">{{"labels.buttons.back" | translate}}</button>
    <button mat-raised-button color="primary" (click)="popover.close();showPopover(templateTableRolesandPermissions, tableRolesandPermissions, 'top', true)">{{"labels.buttons.next" | translate}}</button>
  </div>
</ng-template>

<ng-template #templateTableRolesandPermissions let-popover="popover">
  <h4 class="mw300">{{"labels.listRolesDefinedWithinOrganization" | translate}}. {{"labels.forMoreDetailsClick" | translate}} <a href="" target="_blank">{{"labels.anchors.manageroleandpermissions" | translate}}:</a></h4>
  <div fxLayout="row" fxLayoutAlign="end" fxLayoutGap="2%" fxLayout.lt-md="column">
    <button mat-raised-button color="warn" (click)="popover.close();configurationWizardService.closeConfigWizard()">{{"labels.buttons.close" | translate}}</button>
    <button mat-raised-button color="primary" (click)="popover.close();showPopover(templateFilter, filter, 'bottom', true);">{{"labels.buttons.back" | translate}}</button>
    <button mat-raised-button color="primary" (click)="popover.close();nextStep()">{{"labels.buttons.next" | translate}}</button>
  </div>
</ng-template>
