<div class="container">

  <mat-card>

    <form [formGroup]="editCenterForm" (ngSubmit)="submit()">

	  <mat-card-content>

	    <div fxLayout="column">

		  <mat-form-field>
		    <mat-label>{{"labels.inputs.name" | translate}}</mat-label>
			<input matInput required formControlName="name">
			<mat-error *ngIf="editCenterForm.controls.name.hasError('required')">
			  {{"labels.centerName" | translate}} <strong>{{"labels.commons.is required" | translate}}</strong>
			</mat-error>
			<mat-error *ngIf="editCenterForm.controls.name.hasError('pattern')">
			  {{"labels.centerName" | translate}} <strong>{{"labels.cannot" | translate}}</strong> {{"labels.anchors.cannotbeginwithspecialcharacter" | translate}}
			</mat-error>
			</mat-form-field>

			<mat-form-field>
			  <mat-label>{{"labels.inputs.staff" | translate}}</mat-label>
			  <mat-select formControlName="staffId">
				<mat-option *ngFor="let staff of staffs" [value]="staff.id">
				  {{ staff.displayName }}
				</mat-option>
			  </mat-select>
			</mat-form-field>

			<mat-form-field>
			  <mat-label>{{"labels.inputs.externalid" | translate}}</mat-label>
			  <input matInput formControlName="externalId">
			</mat-form-field>

			<ng-container *ngIf="centerData.status.value=='Pending'" (click)="activatedOnDatePicker.open()">
			  <mat-form-field>
				<mat-label>{{"labels.heading.activationdate" | translate}}</mat-label>
				<input matInput [min]="minDate" [max]="maxDate" [matDatepicker]="activatedOnDatePicker" required
				  formControlName="activationDate">
				<mat-datepicker-toggle matSuffix [for]="activatedOnDatePicker"></mat-datepicker-toggle>
				<mat-datepicker #activatedOnDatePicker></mat-datepicker>
				<mat-error *ngIf="editCenterForm.controls.activationDate.hasError('required')">
				  {{"labels.inputs.activationdate" | translate}} <strong>{{"labels.commons.is required" | translate}}</strong>
				</mat-error>
			  </mat-form-field>
			</ng-container>

		</div>

	  </mat-card-content>

	  <mat-card-actions fxLayout="row" fxLayout.xs="column" fxLayoutAlign="center" fxLayoutGap="5px">
	    <button type="button" mat-raised-button [routerLink]="['../general']">{{"labels.buttons.cancel" | translate}}</button>
	    <button mat-raised-button color="accent" [disabled]="!editCenterForm.valid">{{"labels.buttons.confirm" | translate}}</button>
	  </mat-card-actions>

	</form>

  </mat-card>

</div>
