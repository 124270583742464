<div class="container">
    <form [formGroup]="simulatorForm">

        <mat-grid-list cols="2" rowHeight="70px">
            <mat-grid-tile>
                <mat-form-field style="width:100%" appearance="fill">
                    <mat-label>{{"labels.inputs.productname" | translate}}</mat-label>
                    <mat-select formControlName="productId" required>
                        <mat-option *ngFor="let product of productData" [value]="product.id">
                            {{ product.name }}
                        </mat-option>
                    </mat-select>
                    <mat-error>
                        {{"labels.inputs.productname" | translate}} <strong>{{"labels.commons.is required" | translate}}</strong>
                    </mat-error>
                </mat-form-field>
            </mat-grid-tile>
            <mat-grid-tile>
                <mat-form-field style="width:100%" appearance="fill">
                    <mat-label>{{"labels.inputs.disbursementon" | translate}}</mat-label>
                    <input matInput [min]="simulatorForm.value.submittedOnDate" [max]="maxDate"
                        [matDatepicker]="disbursementPicker" required formControlName="expectedDisbursementDate">
                    <mat-datepicker-toggle matSuffix [for]="disbursementPicker"></mat-datepicker-toggle>
                    <mat-datepicker #disbursementPicker></mat-datepicker>
                    <mat-error *ngIf="simulatorForm.controls.submittedOnDate?.hasError('required')">
                        {{"labels.inputs.disbursementon" | translate}} <strong>{{"labels.commons.is required" | translate}}</strong>
                    </mat-error>
                </mat-form-field>
            </mat-grid-tile>
            <mat-grid-tile>
                <mat-form-field style="width:100%" appearance="fill">
                    <mat-label>{{"labels.heading.principal" | translate}} {{currencyDisplaySymbol}}</mat-label>
                    <input decimalInput matInput formControlName="principal" required>
                    <mat-error *ngIf="simulatorForm.controls.principal.hasError('required')">
                        {{"labels.heading.principal" | translate}} <strong>{{"labels.commons.is required" | translate}}</strong>
                    </mat-error>
                </mat-form-field>
            </mat-grid-tile>
            <mat-grid-tile>
                <mat-form-field style="width:100%" appearance="fill">
                    <mat-label>{{"labels.inputs.submittedOnDate" | translate}}</mat-label>
                    <input matInput [min]="minDate" [max]="maxDate" [matDatepicker]="submitPicker" required
                        formControlName="submittedOnDate">
                    <mat-datepicker-toggle matSuffix [for]="submitPicker"></mat-datepicker-toggle>
                    <mat-datepicker #submitPicker></mat-datepicker>
                    <mat-error *ngIf="simulatorForm.controls.submittedOnDate?.hasError('required')">
                        {{"labels.inputs.submittedOnDate" | translate}} <strong>{{"labels.commons.is required" | translate}}</strong>
                    </mat-error>
                </mat-form-field>
            </mat-grid-tile>
            <mat-grid-tile>
                <mat-form-field style="width:80%" appearance="fill">
                    <mat-label>{{"labels.inputs.loanterm" | translate}}</mat-label>
                    <input formControlName="loanTermFrequency" matInput required>
                </mat-form-field>
                <mat-form-field style="width:20%" appearance="fill">
                    <mat-label>{{"labels.per" | translate}}</mat-label>
                    <mat-select formControlName="loanTermFrequencyType" required>
                        <mat-option *ngFor="let termFrequencyType of loanaccountinfo?.termFrequencyTypeOptions"
                            [value]="termFrequencyType.id">
                            {{ termFrequencyType.value }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </mat-grid-tile>
            <mat-grid-tile>
                <mat-form-field style="width:100%" appearance="fill">
                    <mat-label>{{"labels.inputs.repaymentstrategy" | translate}}</mat-label>
                    <mat-select formControlName="transactionProcessingStrategyId" required>
                        <mat-option
                            *ngFor="let processingStrategy of loanaccountinfo?.transactionProcessingStrategyOptions"
                            [value]="processingStrategy.id">
                            {{ processingStrategy.name }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </mat-grid-tile>
            <mat-grid-tile>
                <mat-form-field style="width:100%" appearance="fill">
                    <mat-label>{{"labels.inputs.repaidevery" | translate}}</mat-label>
                    <input formControlName="repaymentEvery" matInput required>
                </mat-form-field>
            </mat-grid-tile>
            <mat-grid-tile>
                <mat-form-field style="width:100%" appearance="fill">
                    <mat-label>{{"labels.inputs.amortization" | translate}}</mat-label>
                    <mat-select formControlName="amortizationType" required>
                        <mat-option *ngFor="let amortization of loanaccountinfo?.amortizationTypeOptions"
                            [value]="amortization.id">
                            {{ amortization.value }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </mat-grid-tile>
            <mat-grid-tile>
                <mat-form-field style="width:100%" appearance="fill">
                    <mat-label>{{"labels.heading.numberOfRepayments" | translate}}</mat-label>
                    <input type="number" formControlName="numberOfRepayments" matInput required>
                </mat-form-field>
            </mat-grid-tile>
            <mat-grid-tile>
                <mat-form-field style="width:100%" appearance="fill">
                    <mat-label>{{"labels.heading.interestmethod" | translate}}</mat-label>
                    <mat-select formControlName="interestType" required>
                        <mat-option *ngFor="let interestType of loanaccountinfo?.interestTypeOptions"
                            [value]="interestType.id">
                            {{ interestType.value }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </mat-grid-tile>
            <mat-grid-tile>
                <mat-form-field style="width:100%" appearance="fill">
                    <mat-label>{{"labels.inputs.nominalinterestrate" | translate}}</mat-label>
                    <input formControlName="interestRatePerPeriod" matInput required>
                </mat-form-field>
            </mat-grid-tile>
        </mat-grid-list>
        <div class="actions">
            <button type="button" mat-raised-button [routerLink]="['../']">{{"labels.buttons.cancel" | translate}}</button>
            <button mat-raised-button color="primary" (click)="submit()">{{"labels.buttons.submit" | translate}}</button>
        </div>
    </form>
    <table mat-table [dataSource]="simulationData?.periods" matSort *ngIf="simulationData">
        <ng-container matColumnDef="period">
            <th mat-header-cell *matHeaderCellDef mat-sort-header="period"> </th>
            <td mat-cell *matCellDef="let row"> {{row.period}} </td>
            <td mat-footer-cell *matFooterCellDef> {{"labels.commons.total" | translate}} </td>
        </ng-container>

        <ng-container matColumnDef="dueDate">
            <th mat-header-cell *matHeaderCellDef mat-sort-header="dueDate"> {{'labels.commons.date' | translate}}
            </th>
            <td mat-cell *matCellDef="let row"> {{row.dueDate}} </td>
            <td mat-footer-cell *matFooterCellDef></td>
        </ng-container>

        <ng-container matColumnDef="daysInPeriod">
            <th mat-header-cell *matHeaderCellDef mat-sort-header="daysInPeriod"> {{'labels.commons.days' |
                translate}} </th>
            <td mat-cell *matCellDef="let row"> {{row.daysInPeriod}} </td>
            <td mat-footer-cell *matFooterCellDef> {{simulationData?.loanTermInDays}} </td>
        </ng-container>
        <ng-container matColumnDef="principalDisbursed">
            <th mat-header-cell *matHeaderCellDef mat-sort-header="principalDisbursed">
                {{'labels.commons.disbursement' | translate}} </th>
            <td mat-cell *matCellDef="let row"> {{row.principalDisbursed}} </td>
            <td mat-footer-cell *matFooterCellDef> {{simulationData?.totalPrincipalDisbursed}} </td>
        </ng-container>
        <ng-container matColumnDef="principalDue">
            <th mat-header-cell *matHeaderCellDef mat-sort-header="principalDue"> {{'labels.commons.principaldue' |
                translate}} </th>
            <td mat-cell *matCellDef="let row"> {{row.principalDue}} </td>
            <td mat-footer-cell *matFooterCellDef> {{simulationData?.totalPrincipalExpected}} </td>
        </ng-container>
        <ng-container matColumnDef="principalLoanBalanceOutstanding">
            <th mat-header-cell *matHeaderCellDef mat-sort-header="principalLoanBalanceOutstanding">
                {{'labels.commons.principalbalance' | translate}} </th>
            <td mat-cell *matCellDef="let row"> {{row.principalLoanBalanceOutstanding}} </td>
            <td mat-footer-cell *matFooterCellDef> </td>
        </ng-container>
        <ng-container matColumnDef="interestDue">
            <th mat-header-cell *matHeaderCellDef mat-sort-header="interestDue">{{'labels.commons.interestdue' |
                translate}} </th>
            <td mat-cell *matCellDef="let row"> {{row.interestDue}} </td>
            <td mat-footer-cell *matFooterCellDef> {{simulationData?.totalInterestCharged}} </td>
        </ng-container>
        <ng-container matColumnDef="feeChargesDue">
            <th mat-header-cell *matHeaderCellDef mat-sort-header="feeChargesDue"> {{'labels.commons.fees' |
                translate}} </th>
            <td mat-cell *matCellDef="let row"> {{row.feeChargesDue}} </td>
            <td mat-footer-cell *matFooterCellDef> {{simulationData?.totalFeeChargesCharged}} </td>
        </ng-container>
        <ng-container matColumnDef="penaltyChargesDue">
            <th mat-header-cell *matHeaderCellDef mat-sort-header="penaltyChargesDue">
                {{'labels.commons.penalty' | translate}} </th>
            <td mat-cell *matCellDef="let row"> {{row.penaltyChargesDue}} </td>
            <td mat-footer-cell *matFooterCellDef> {{simulationData?.totalPenaltyChargesCharged}} </td>
        </ng-container>
        <ng-container matColumnDef="totalDueForPeriod">
            <th mat-header-cell *matHeaderCellDef mat-sort-header="totalDueForPeriod">
                {{'labels.commons.due' | translate}} </th>
            <td mat-cell *matCellDef="let row"> {{row.totalDueForPeriod}} </td>
            <td mat-footer-cell *matFooterCellDef> {{simulationData?.totalRepaymentExpected}} </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="select-row"></tr>
        <tr mat-footer-row *matFooterRowDef="displayedColumns"></tr>

    </table>
</div>
