import { TenantService } from './../../core/tenant/tenant.service';
/** Angular Imports */
import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { FormBuilder, Validators } from '@angular/forms';
/** Custom Services */
import { SettingsService } from 'app/settings/settings.service';
import getTenants from './getTenants';
// import getTenants from './getTenants';

/**
 * Server Selector Component
 */
@Component({
  selector: 'mifosx-server-selector',
  templateUrl: './server-selector.component.html',
  styleUrls: ['./server-selector.component.scss']
})

export class ServerSelectorComponent implements OnInit {

  /** Input server. */
  form: any;

  /** Server Settings. */
  servers: any[];

  /** Server Setting */
  serverSelector = new FormControl("");

  /** Server list to show */
  existMoreThanOneServer = false;
  tenantData: any;

  /**
   * @param {SettingsService} settingsService Settings Service
   */
  constructor(private settingsService: SettingsService,
    public dialog: MatDialog,
    private formBuilder: FormBuilder,
    private tenantService: TenantService) {
    this.settingsService.currentTenantData.subscribe(tenant => {
      this.tenantData = tenant;
    });
    this.tenantService.getTenants().subscribe((data: any) => {
      this.servers = data;
      if (this?.tenantData) {
        this.serverSelector.setValue(this.servers.find(tenant => tenant?.id === this?.tenantData.id) || null)
      } else {
        const defaultTenant = this.servers.find(tenant => tenant?.tenantName === "default");
        if (defaultTenant?.lenght > 0) {
          this.settingsService.updateTenantData(defaultTenant);
          this.serverSelector.setValue(defaultTenant || null);
        } else {
          this.settingsService.updateTenantData(this.servers[0]);
          this.serverSelector.setValue(this.servers[0] || null);
        }
      }
    })
  }

  ngOnInit(): void {

    if(this.tenantData){
      this.serverSelector.setValue(this.servers?.find(tenant=>tenant?.tenantName=== this?.tenantData.tenantName)|| null)
    }else{
      this.settingsService.updateTenantData(this.servers.find(tenant=>tenant?.tenantName=== "default"));
      this.serverSelector.setValue(this.servers?.find(tenant=>tenant?.tenantName=== "default")|| null)
    }
  }

  /**
   * Set backend server from the list
   */
  setServer(): void {
    this.settingsService.updateTenantData(this.serverSelector.value);
  }

}
