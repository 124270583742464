<div class="container">

    <mat-card class="tasks-card">

      <mat-card-content>

        <nav mat-tab-nav-bar class="navigation-tabs">
          <a mat-tab-link [routerLink]="['./loan-approval']" routerLinkActive #loanApproval="routerLinkActive"
            [active]="loanApproval.isActive" *mifosxHasPermission="'READ_LOAN'">
            {{"labels.heading.loanapproval" | translate}}
          </a>
          <a mat-tab-link [routerLink]="['./loan-disbursal']" routerLinkActive #loanDisbursal="routerLinkActive"
            [active]="loanDisbursal.isActive" *mifosxHasPermission="'READ_LOAN'">
            {{"labels.heading.loandisbursal" | translate}}
          </a>
          <a mat-tab-link [routerLink]="['./reschedule-loan']" routerLinkActive #rescheduleLoan="routerLinkActive"
            [active]="rescheduleLoan.isActive" *mifosxHasPermission="'RESCHEDULE_LOAN'">
            {{"labels.heading.rescheduleLoan" | translate}}
          </a>
        </nav>

        <router-outlet></router-outlet>

      </mat-card-content>

    </mat-card>

  </div>
