<!-- TODO: Responsiveness/design enhancement and reduction in form loading time -->
<div class="container">

  <mat-card>

    <form [formGroup]="openingBalancesForm" (ngSubmit)="submit()">

      <mat-card-content>

        <div #searchFormRef fxLayout="row wrap" fxLayoutGap="2%" fxLayout.lt-md="column">

          <mat-form-field fxFlex="88%">
            <mat-label>{{"labels.inputs.office" | translate}}</mat-label>
            <mat-select required formControlName="officeId">
              <mat-option *ngFor="let office of officeData" [value]="office.id">
                {{ office.name }}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="openingBalancesForm.controls.officeId.hasError('required')">
              {{"labels.inputs.office" | translate}} <strong>{{"labels.commons.is required" | translate}}</strong>
            </mat-error>
          </mat-form-field>

          <div>
            <button mat-button type="button" color="primary" fxFlex="8%" (click)="retrieveOpeningBalances()">{{"labels.Retrieve" | translate}}</button>
          </div>

        </div>

        <div fxLayout="row wrap" fxLayoutGap="2%" fxLayout.lt-md="column" *ngIf="openingBalancesData">

          <label fxFlex="98%" class="content"><span class="header">{{'labels.openingBalancesContraAccount' | translate}}:</span>&nbsp;&nbsp;{{ openingBalancesData.contraAccount.name + ' (' + openingBalancesData.contraAccount.glCode + ')' }}</label>

          <mat-form-field fxFlex="48%">
            <mat-label>{{"labels.inputs.currency" | translate}}</mat-label>
            <mat-select required formControlName="currencyCode">
              <mat-option *ngFor="let currency of currencyData" [value]="currency.code">
                {{ currency.displayLabel }}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="openingBalancesForm.controls.currencyCode.hasError('required')">
              {{"labels.inputs.currency" | translate}} <strong>{{"labels.commons.is required" | translate}}</strong>
            </mat-error>
          </mat-form-field>

          <mat-form-field fxFlex="48%" (click)="openingBalancesDatePicker.open()">
            <mat-label>{{"labels.openingBalancesDate" | translate}}</mat-label>
            <input matInput [min]="minDate" [max]="maxDate" [matDatepicker]="openingBalancesDatePicker" required formControlName="transactionDate">
            <mat-datepicker-toggle matSuffix [for]="openingBalancesDatePicker"></mat-datepicker-toggle>
            <mat-datepicker #openingBalancesDatePicker></mat-datepicker>
            <mat-error *ngIf="openingBalancesForm.controls.transactionDate.hasError('required')">
              {{"labels.openingBalancesDate" | translate}} <strong>{{"labels.commons.is required" | translate}}</strong>
            </mat-error>
          </mat-form-field>

          <div fxLayout="row wrap" fxLayoutGap="2%" fxLayout.lt-md="column" class="content" fxFlex="100%">

            <div class="header" fxFlex="8%">
              {{"labels.inputs.type" | translate}}
            </div>

            <div class="header" fxFlex="18%">
              {{"labels.inputs.glcode" | translate}}
            </div>

            <div class="header" fxFlex="18%">
              {{"labels.heading.account" | translate}}
            </div>

            <div class="header" fxFlex="23%">
              {{"labels.totalDebit" | translate}}: {{ debitsSum }}
            </div>

            <div class="header" fxFlex="23%">
              {{"labels.totalCredit" | translate}}: {{ creditsSum }}
            </div>

          </div>

          <div fxFlexFill fxLayout="row wrap" fxLayout.lt-md="column" class="content" formArrayName="glAccountEntries"
            *ngFor="let credit of glAccountEntries.controls; let i = index;">

            <div fxFlexFill fxLayout="row wrap" fxLayoutGap="2%" fxLayout.lt-md="column" [formGroupName]="i">

              <div fxFlex="8%" class="p-t-30">
                {{ (openingBalancesData.glAccounts[i].glAccountType.value !== openingBalancesData.glAccounts[i-1]?.glAccountType.value) ? openingBalancesData.glAccounts[i].glAccountType.value : '' }}
              </div>

              <div fxFlex="18%" class="p-t-30">
                {{ openingBalancesData.glAccounts[i].glAccountCode }}
              </div>

              <div fxFlex="18%" class="p-t-30">
                {{ openingBalancesData.glAccounts[i].glAccountName }}
              </div>

              <div fxFlex="23%">
                <mat-form-field>
                  <mat-label>{{"labels.inputs.debit" | translate}}</mat-label>
                  <input matInput decimalInput formControlName="debit">
                </mat-form-field>
              </div>

              <div fxFlex="23%">
                <mat-form-field>
                  <mat-label>{{"labels.heading.credit" | translate}}</mat-label>
                  <input matInput decimalInput formControlName="credit">
                </mat-form-field>
              </div>

            </div>

          </div>

        </div>

      </mat-card-content>

      <mat-card-actions fxLayout="row" fxLayout.xs="column" fxLayoutAlign="center" fxLayoutGap="5px" *ngIf="openingBalancesData">
        <button type="button" mat-raised-button [routerLink]="['../']">{{"labels.buttons.cancel" | translate}}</button>
        <button mat-raised-button color="primary" [disabled]="!openingBalancesForm.valid" *mifosxHasPermission="'DEFINEOPENINGBALANCE_JOURNALENTRY'">{{"labels.buttons.submit" | translate}}</button>
      </mat-card-actions>

    </form>

  </mat-card>

</div>

<ng-template #templateSearchFormRef let-popover="popover">
  <h2>{{"labels.heading.define.openingbalances" | translate}}</h2>
  <p class="mw300">{{"labels.usedMigrateOpeningBalances" | translate}}. {{"labels.toKnowMoreClick" | translate }} <a href="" target="_blank">{{"labels.heading.define.openingbalances" | translate}}</a></p>
  <div fxLayout="row" fxLayoutAlign="end" fxLayoutGap="2%" fxLayout.lt-md="column">
    <button mat-raised-button color="warn" (click)="popover.close();configurationWizardService.closeConfigWizard()">{{"labels.buttons.close" | translate}}</button>
    <button mat-raised-button color="primary" (click)="popover.close();popover.close();previousStep();">{{"labels.buttons.back" | translate}}</button>
    <button mat-raised-button color="primary" (click)="popover.close();popover.close();nextStep();">{{"labels.buttons.next" | translate}}</button>
  </div>
</ng-template>
