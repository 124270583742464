import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'mifosx-web-views-logo',
  templateUrl: './web-views-logo.component.html',
  styleUrls: ['./web-views-logo.component.scss']
})
export class WebViewsLogoComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
