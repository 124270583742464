<mat-card class="container-card">
  <mat-card-content>
    <mat-tab-group #tabGroup mat-align-tabs="center">
      <mat-tab [label]="'labels.anchors.schedulerjobs' | translate">
        <mifosx-manage-scheduler-jobs></mifosx-manage-scheduler-jobs>
      </mat-tab>
      <mat-tab [label]="'labels.workflowJobs' | translate">
        <mifosx-workflow-jobs></mifosx-workflow-jobs>
      </mat-tab>
    </mat-tab-group>

  </mat-card-content>
</mat-card>
