<div class="container">

  <mat-card>

    <form [formGroup]="clientSavingsAccountForm" (ngSubmit)="submit()">

      <mat-card-content>

        <div fxLayout="column">

          <mat-form-field fxFlex="48%">
            <mat-label>{{ 'labels.inputs.defaultSavingsaccount' | translate }}</mat-label>
            <mat-select formControlName="savingsAccountId">
              <mat-option *ngFor="let account of savingsAccounts" [value]="account.id">
                {{ account.accountNo }}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="clientSavingsAccountForm.controls.savingsAccountId.hasError('required')">
              {{ 'labels.inputs.account' | translate }} <strong>{{ 'labels.commons.is required' | translate }}</strong>
            </mat-error>
          </mat-form-field>

        </div>

      </mat-card-content>

      <mat-card-actions fxLayout="row" fxLayout.xs="column" fxLayoutAlign="center" fxLayoutGap="5px">
        <button type="button" mat-raised-button [routerLink]="['../../']">{{ 'labels.buttons.cancel' | translate }}</button>
        <button mat-raised-button color="primary" [disabled]="!clientSavingsAccountForm.valid">{{ 'labels.buttons.confirm' | translate }}</button>
      </mat-card-actions>

    </form>

  </mat-card>

</div>
