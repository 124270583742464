<div class="container">

    <mat-card>

        <form [formGroup]="createSelfServiceForm" (ngSubmit)="submit()">

            <mat-card-content>

                <div div fxLayout="row wrap" fxLayoutGap="2%" fxLayout.lt-md="column">

                    <mat-form-field fxFlex="48%">
                        <mat-label> {{ 'labels.inputs.Username' | translate }} </mat-label>
                        <input matInput required formControlName="username">
                        <mat-error *ngIf="createSelfServiceForm.controls.username.hasError('required')">
                            {{ 'labels.inputs.Username' | translate }} <strong>{{ 'labels.commons.is required' | translate }}</strong>
                        </mat-error>
                    </mat-form-field>

                    <mat-form-field fxFlex="48%">
                        <mat-label> {{ 'labels.inputs.firstname' | translate }} </mat-label>
                        <input matInput required formControlName="firstname">
                        <mat-error *ngIf="createSelfServiceForm.controls.firstname.hasError('required')">
                            {{ 'labels.inputs.firstname' | translate }} <strong>{{ 'labels.commons.is required' | translate }}</strong>
                        </mat-error>
                    </mat-form-field>

                    <mat-form-field fxFlex="48%">
                        <mat-label> {{ 'labels.inputs.lastname' | translate }} </mat-label>
                        <input matInput required formControlName="lastname">
                        <mat-error *ngIf="createSelfServiceForm.controls.lastname.hasError('required')">
                            {{ 'labels.inputs.lastname' | translate }} is <strong>{{ 'labels.commons.is required' | translate }}</strong>
                        </mat-error>
                    </mat-form-field>

                    <mat-form-field fxFlex="48%">
                        <mat-label> {{ 'labels.inputs.email' | translate }} </mat-label>
                        <input matInput required formControlName="email">
                        <mat-error *ngIf="createSelfServiceForm.controls.email.hasError('required')">
                            {{ 'labels.inputs.email' | translate }} <strong>{{ 'labels.commons.is required' | translate }}</strong>
                        </mat-error>
                        <mat-error *ngIf="createSelfServiceForm.controls.email.hasError('email')">
                            <strong>{{ 'errors.inputs.invaliEmail' | translate }}</strong>
                        </mat-error>
                    </mat-form-field>

                    <mat-checkbox labelPosition="before" formControlName="sendPasswordToEmail" fxFlex="48%">
                        {{ 'labels.inputs.autogeneratepassword' | translate }}
                    </mat-checkbox>

                    <div fxFlex="48%" fxLayoutGap="5px" fxLayout="row">

                        <mat-checkbox labelPosition="before" formControlName="passwordNeverExpires">
                            {{ 'labels.inputs.overridePasswordExpirePolicy' | translate }}
                        </mat-checkbox>
    
                        <i class="fa fa-question-circle fa fa-align-left" title="Determines if passwords do not expire for this user even if global configuration for password expiry is enabled"></i>

                    </div>

                    <mat-form-field fxFlex="48%" *ngIf="!hidePasswordField">
                        <mat-label> {{ 'labels.inputs.password' | translate }} </mat-label>
                        <input matInput required formControlName="password" type="password">
                        <mat-error *ngIf="createSelfServiceForm.controls.password.hasError('required')">
                            {{ 'labels.inputs.password' | translate }} <strong>{{ 'labels.commons.is required' | translate }}</strong>
                        </mat-error>
                    </mat-form-field>

                    <mat-form-field fxFlex="48%" *ngIf="!hidePasswordField">
                        <mat-label> {{ 'labels.inputs.repeatpassword' | translate }} </mat-label>
                        <input matInput required formControlName="repeatPassword" type="password">
                        <mat-error *ngIf="createSelfServiceForm.controls.repeatPassword.hasError('required')">
                            {{ 'labels.inputs.repeatpassword' | translate }} <strong>{{ 'labels.commons.is required' | translate }}</strong>
                        </mat-error>
                        <mat-error *ngIf="createSelfServiceForm.controls.repeatPassword.hasError('notequal')">
                            {{ 'errors.inputs.passwordsShouldBeMatched' | translate }}
                        </mat-error>
                    </mat-form-field>

                    <mat-form-field fxFlex="48%">
                        <mat-label> {{ 'labels.inputs.status' | translate }} </mat-label>
                        <input matInput formControlName="status">
                    </mat-form-field>

                </div>
        
                <mat-card-actions fxLayoutGap="5px" fxLayout="row" fxLayout.xs="column" fxLayoutAlign="center">
                  <button type="button" mat-raised-button [routerLink]="['../../']">{{ 'labels.buttons.cancel' | translate }}</button>
                  <button mat-raised-button color="primary" [disabled]="!createSelfServiceForm.valid">{{ 'labels.buttons.activateselfservice' | translate }}</button>
                  <i class="fa fa-question-circle fa-2x" title="Activate Self Service User Help"></i>
                </mat-card-actions>
        
            </mat-card-content>

        </form>

    </mat-card>

</div>
