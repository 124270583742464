<div fxLayout="row" fxLayoutAlign="end" fxLayoutGap="2%" fxLayout.lt-md="column" class="container m-b-20">
    <button mat-raised-button color="primary" [routerLink]="['edit']" >
        <fa-icon icon="edit"></fa-icon>&nbsp;&nbsp;
        {{"labels.buttons.edit" | translate}}
    </button>
    <button mat-raised-button color="warn" (click)="deleteCollateral()" >
        <fa-icon icon="trash"></fa-icon>&nbsp;&nbsp;
        {{"labels.buttons.delete" | translate}}
    </button>
</div>

<div class="container">

    <mat-card>

        <mat-card-content>

            <div fxLayout="row wrap" class="content">

                <div fxFlex="50%" class="mat-body-strong">
                    {{"labels.inputs.collateralName" | translate}}
                </div>

                <div fxFlex="50%">
                    {{ collateralData.name }}
                </div>

                <div fxFlex="50%" class="mat-body-strong">
                    {{"labels.heading.typeQuality" | translate}}
                </div>

                <div fxFlex="50%">
                    {{ collateralData.quality }}
                </div>

                <div fxFlex="50%" class="mat-body-strong">
                    {{"labels.heading.basePrice" | translate}}
                </div>

                <div fxFlex="50%">
                    {{ collateralData.basePrice }}
                </div>

                <div fxFlex="50%" class="mat-body-strong">
                    {{"labels.heading.pctToBase" | translate}}
                </div>

                <div fxFlex="50%">
                    {{ collateralData.pctToBase }}
                </div>

                <div fxFlex="50%" class="mat-body-strong">
                    {{"labels.heading.unitType" | translate}}
                </div>

                <div fxFlex="50%">
                    {{ collateralData.unitType }}
                </div>

                <div fxFlex="50%" class="mat-body-strong">
                    {{"labels.inputs.currency" | translate}}
                </div>

                <div fxFlex="50%">
                    {{ collateralData.currency }}
                </div>

            </div>

        </mat-card-content>

    </mat-card>

</div>
