import { ClientsService } from 'app/clients/clients.service';
import { Dates } from 'app/core/utils/dates';
import { SettingsService } from 'app/settings/settings.service';
import { ActivatedRoute, Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { LoansService } from 'app/loans/loans.service';


@Component({
  selector: 'mifosx-simulator',
  templateUrl: './simulator.component.html',
  styleUrls: ['./simulator.component.scss']
})
export class SimulatorComponent implements OnInit {

  displayedColumns : string[] = ['period','dueDate','daysInPeriod','principalDisbursed','principalDue','principalLoanBalanceOutstanding','interestDue','feeChargesDue','penaltyChargesDue','totalDueForPeriod'];

  loansAccountTermsForm: FormGroup;
  productData: any;
  loanaccountinfo:any;
  loansAccountTemplate: any;
  simulatorForm: FormGroup;
  simulationData : any  = null;
  /** Minimum date allowed. */
  minDate = new Date(2000, 0, 1);
  /** Maximum date allowed. */
  maxDate = new Date(2100, 0, 1);
  currencyDisplaySymbol = '$';


  @Output() loansAccountProductTemplate = new EventEmitter();

  /**
    * Sets loans account create form.
    * @param {FormBuilder} formBuilder Form Builder.
    * @param {route} ActivatedRoute Activated Route.
    * @param {router} Router Router.
    * @param {Dates} dateUtils Date Utils
    * @param {loansService} LoansService Loans Service
    * @param {SettingsService} settingsService Settings Service
    * @param {ClientsService} clientService Client Service
    */
  constructor(private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private dateUtils: Dates,
    private loansService: LoansService,
    private settingsService: SettingsService,
    private clientService: ClientsService
  ) {

    this.createsimulatorForm();

  }
  createsimulatorForm() {
    this.simulatorForm = this.formBuilder.group({
      'productId': ['', Validators.required],
      'submittedOnDate': [new Date(), Validators.required],
      'expectedDisbursementDate': [new Date(), Validators.required],
      'principal': ['', Validators.required],
      'loanTermFrequency': ['', Validators.required],
      'loanTermFrequencyType': ['', Validators.required],
      'numberOfRepayments': ['', Validators.required],
      'repaymentEvery': ['', Validators.required],
      'repaymentFrequencyType': ['', Validators.required],
      'transactionProcessingStrategyId':['',Validators.required],
      'interestRatePerPeriod':['',Validators.required],
      'amortizationType':['',Validators.required],
      'interestType':['',Validators.required],
    });
  }

  ngOnInit() {
    this.route.data.subscribe((data: { loansAccountTemplate: any }) => {
      this.loansAccountTemplate = data.loansAccountTemplate;
      this.productData = this.loansAccountTemplate.productOptions;
      // this.currencyDisplaySymbol = this.loansAccountTemplate.currency.displaySymbol;
    });
    // this.maxDate = this.settingsService.maxFutureDate;
    this.buildDependencies();
    if (this.loansAccountTemplate) {
      this.productData = this.loansAccountTemplate.productOptions;
      if (this.loansAccountTemplate.loanProductId) {
        this.simulatorForm.patchValue({
          'productId': this.loansAccountTemplate.loanProductId,
          'submittedOnDate': this.loansAccountTemplate.timeline.submittedOnDate && new Date(this.loansAccountTemplate.timeline.submittedOnDate),
          'loanOfficerId': this.loansAccountTemplate.loanOfficerId,
          'loanPurposeId': this.loansAccountTemplate.loanPurposeId,
          'fundId': this.loansAccountTemplate.fundId,
          'expectedDisbursementDate': this.loansAccountTemplate.timeline.expectedDisbursementDate && new Date(this.loansAccountTemplate.timeline.expectedDisbursementDate),
          'externalId': this.loansAccountTemplate.externalId,
          'principal': this.loansAccountTemplate.principal,
        });
      }
    }
  }
  buildDependencies() {
    this.simulatorForm.get('productId').valueChanges.subscribe((productId: string) => {
      this.loansService.getLoansAccountTemplateResource(null, null, productId).subscribe((response: any) => {
        this.loanaccountinfo = response;
        this.loansAccountProductTemplate.emit(response);
        this.currencyDisplaySymbol = response.currency.displaySymbol;
        this.simulatorForm.get('principal').setValue(response.principal);
        this.simulatorForm.get('interestRatePerPeriod').setValue(response.interestRatePerPeriod);
        this.simulatorForm.get('loanTermFrequency').setValue(response.termFrequency);
        this.simulatorForm.get('loanTermFrequencyType').setValue(response.termPeriodFrequencyType.id);
        this.simulatorForm.get('numberOfRepayments').setValue(response.numberOfRepayments);
        this.simulatorForm.get('repaymentEvery').setValue(response.repaymentEvery);
        this.simulatorForm.get('repaymentFrequencyType').setValue(response.repaymentFrequencyType.id);
        this.simulatorForm.get('amortizationType').setValue(response.amortizationType);
        this.simulatorForm.get('interestType').setValue(response.interestType);
        this.simulatorForm.get('transactionProcessingStrategyId').setValue(response.transactionProcessingStrategyId);
        this.simulatorForm.get('amortizationType').setValue(response.amortizationType.id);
        this.simulatorForm.get('interestType').setValue(response.interestType.id);
      });
    });
  }

  submit() {
    const values = this.simulatorForm.value;
    const locale = this.settingsService.language.code;
    const dateFormat = 'yyyy-MM-dd';
    const formData = {
      ...values,
      expectedDisbursementDate : this.dateUtils.formatDate(values.expectedDisbursementDate, dateFormat),
      submittedOnDate : this.dateUtils.formatDate(values.submittedOnDate, dateFormat),
      interestCalculationPeriodType: values.loanTermFrequencyType,
      repaymentFrequencyType: values.loanTermFrequencyType,
      locale: locale,
      dateFormat: dateFormat,
      loanType: "individual",
    }
    this.loansService.simulateLoan(formData).subscribe((response: any) => {
      this.simulationData = response;
      console.log("periodes",response.periods)
    });
  }

}
