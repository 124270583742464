<div fxLayout="row" fxLayoutAlign="end" fxLayoutGap="1%" fxLayout.lt-md="column" class="container m-b-20">
  <button mat-raised-button color="success" (click)="approveChecker()">
    <fa-icon icon="check"></fa-icon>&nbsp;&nbsp;{{'labels.buttons.approve' | translate}}
  </button>
  <button mat-raised-button color="warn" (click)="deleteChecker()">
    <fa-icon icon="trash"></fa-icon>&nbsp;&nbsp;{{'labels.buttons.delete' | translate}}
  </button>
  <button mat-raised-button color="reject" (click)="rejectChecker()">
    <fa-icon icon="times"></fa-icon>&nbsp;&nbsp;{{'labels.buttons.reject' | translate}}
  </button>
</div>

<div class="container">

  <mat-card>

    <mat-card-content>

      <div fxLayout="row wrap" fxLayout.lt-md="column">

        <h2 class="mat-h2" fxFlexFill> {{'labels.heading.viewcheckerinbox' | translate}} </h2>

        <mat-divider [inset]="true"></mat-divider>

        <h3 class="mat-h3" fxFlexFill>{{'labels.heading.details' | translate}}</h3>

        <mat-divider [inset]="true"></mat-divider>

        <div fxFlexFill>
          <span fxFlex="40%">{{'labels.heading.id' | translate}}:</span>
          <span fxFlex="60%">{{ checkerInboxDetail.id }}</span>
        </div>

        <div fxFlexFill>
          <span fxFlex="40%">{{ 'labels.heading.status' | translate }}:</span>
          <span fxFlex="60%">{{ checkerInboxDetail.processingResult }}</span>
        </div>

        <div fxFlexFill>
          <span fxFlex="40%">{{ 'labels.heading.user' | translate }}:</span>
          <span fxFlex="60%">{{ checkerInboxDetail.maker }}</span>
        </div>

        <div fxFlexFill>
          <span fxFlex="40%">{{ 'labels.heading.action' | translate }}:</span>
          <span fxFlex="60%">{{ checkerInboxDetail.actionName }}</span>
        </div>

        <div fxFlexFill>
          <span fxFlex="40%">{{ 'labels.heading.entity' | translate }}:</span>
          <span fxFlex="60%">{{ checkerInboxDetail.entityName }}</span>
        </div>

        <div fxFlexFill *ngIf="checkerInboxDetail.resourceId">
          <span fxFlex="40%">{{ 'labels.heading.resourceid' | translate }}:</span>
          <span fxFlex="60%">{{ checkerInboxDetail.resourceId }}</span>
        </div>

        <div fxFlexFill>
          <span fxFlex="40%">{{ 'labels.heading.date' | translate }}:</span>
          <span fxFlex="60%">{{ checkerInboxDetail.madeOnDate | dateFormat}}</span>
        </div>

        <div fxFlexFill *ngIf="checkerInboxDetail.officeName">
          <span fxFlex="40%">{{ 'labels.heading.office' | translate }}:</span>
          <span fxFlex="60%">{{ checkerInboxDetail.officeName }}</span>
        </div>

        <div fxFlexFill *ngIf="checkerInboxDetail.savingsAccountNo">
          <span fxFlex="40%">{{ 'labels.heading.savingsaccountno' | translate }}:</span>
          <span fxFlex="60%">{{ checkerInboxDetail.savingsAccountNo }}</span>
        </div>

        <div fxFlexFill *ngIf="checkerInboxDetail.groupLevelName">
          <span fxFlex="40%"> {{ checkerInboxDetail.groupLevelName }}</span>
          <span fxFlex="60%">{{ checkerInboxDetail.groupName }}</span>
        </div>

        <ng-container *ngIf="displayJSONData">

          <h3 class="mat-h3" fxFlexFill>{{ 'labels.heading.command' | translate }}</h3>

          <mat-divider [inset]="true"></mat-divider>

          <ng-container *ngFor="let json of jsondata | keyvalue">

            <div fxFlexFill>
              <span fxFlex="40%">{{ json.key }}:</span>
              <span fxFlex="60%">{{ json.value }}</span>
            </div>

          </ng-container>

        </ng-container>

      </div>

    </mat-card-content>

  </mat-card>

</div>