<div class="mat-typography">

  <h3>{{'labels.heading.familymembers' | translate}}</h3>

  <div fxLayout="row" fxLayoutAlign="flex-end" class="m-b-20">
    <button mat-raised-button color="primary" (click)="addFamilyMember()">
      <fa-icon icon="plus"></fa-icon>&nbsp;&nbsp;{{'labels.buttons.add' | translate}}
    </button>
  </div>

  <mat-accordion>

    <mat-expansion-panel *ngFor="let member of clientFamilyMembers;index as i" class="family-member">

      <mat-expansion-panel-header>

        <mat-panel-title>
          {{ member.firstName }} {{ member.middleName ? member.middleName + ' ' + member.lastName : member.lastName }}
        </mat-panel-title>

        <mat-panel-description>
          {{member.relationship}}
        </mat-panel-description>

      </mat-expansion-panel-header>

      <mat-divider></mat-divider>

      <div class="family-member-actions" fxLayout="row" fxLayoutAlign="flex-end">
        <button mat-button color="primary">
          <fa-icon icon="edit" (click)="editFamilyMember(member, i)"></fa-icon>
        </button>
        <button mat-button color="warn" (click)="deleteFamilyMember(member.firstName,i)">
          <fa-icon icon="trash"></fa-icon>
        </button>
      </div>

      <p>
        {{ 'labels.inputs.firstname' | translate }} : {{ member.firstName }}<br />
        {{ 'labels.inputs.middlename' | translate }} : {{ member.middleName }}<br />
        {{ 'labels.inputs.lastname' | translate }} : {{ member.lastName }}<br />
        {{ 'labels.inputs.qualification' | translate }} : {{ member.qualification }}<br />
        {{ 'labels.inputs.relationship' | translate }} : {{ member.relationshipId |
        find:clientTemplate.familyMemberOptions.relationshipIdOptions:'id':'name' }}<br />
        {{ 'labels.inputs.age' | translate }} : {{ member.age }}<br />
        {{ 'labels.inputs.isDependent' | translate }} : {{ member.isDependent ? 'Yes' : 'No' }}<br />
        {{ 'labels.inputs.maritalstatus' | translate }} : {{ member.maritalStatusId |
        find:clientTemplate.familyMemberOptions.maritalStatusIdOptions:'id':'name' }}<br />
        {{ 'labels.inputs.gender' | translate }} : {{ member.genderId |
        find:clientTemplate.familyMemberOptions.genderIdOptions:'id':'name' }}<br />
        {{ 'labels.inputs.profession' | translate }} : {{member.professionId |
        find:clientTemplate.familyMemberOptions.professionIdOptions:'id':'name' }}<br />
        {{ 'labels.inputs.dateofbirth' | translate }} : {{member.dateOfBirth | dateFormat}}<br />
      </p>

    </mat-expansion-panel>

  </mat-accordion>

  <div fxLayout="row" class="margin-t" fxLayout.xs="column" fxLayoutAlign="center" fxLayoutGap="2%">
    <button mat-raised-button matStepperPrevious>
      <fa-icon icon="arrow-left"></fa-icon>&nbsp;&nbsp;
      {{'labels.buttons.previous' | translate}}
    </button>
    <button mat-raised-button matStepperNext>
      {{'labels.buttons.next' | translate}}&nbsp;&nbsp;
      <fa-icon icon="arrow-right"></fa-icon>
    </button>
  </div>

</div>