import { ConfirmationDialogComponent } from 'app/shared/confirmation-dialog/confirmation-dialog.component';
import { MatDialog } from '@angular/material/dialog';
/** Angular Imports. */
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Dates } from 'app/core/utils/dates';

/** Custom Services. */
import { OrganizationService } from 'app/organization/organization.service';
import { SettingsService } from 'app/settings/settings.service';

/**
 * Allocate Cash component.
 */
@Component({
  selector: 'mifosx-allocate-cash',
  templateUrl: './allocate-cash.component.html',
  styleUrls: ['./allocate-cash.component.scss']
})
export class AllocateCashComponent implements OnInit {

  /** Minimum Date allowed. */
  minDate = new Date(2000, 0, 1);
  /** Maximum Date allowed. */
  maxDate = new Date();
  /** Cashier data. */
  cashierData: any;
  /** Cashier Form. */
  allocateCashForm: FormGroup;

  /**
   * Get cashier data from `Resolver`.
   * @param {FormBuilder} formBuilder Form Builder.
   * @param {ActivatedRoute} route ActivateRoute.
   * @param {Dates} dateUtils Date Utils.
   * @param {OrganizationService} organizationService Organization Service.
   * @param {SettingsService} settingsService Settings Service.
   * @param {Router} router Router.
   */
  constructor(private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private dateUtils: Dates,
    private organizationService: OrganizationService,
    private settingsService: SettingsService,
    private router: Router,
    public dialog: MatDialog) {
    this.route.data.subscribe((data: { cashierTemplate: any }) => {
      this.cashierData = data.cashierTemplate;
    });
  }

  ngOnInit() {
    this.maxDate = this.settingsService.businessDate;
    this.setCashierForm();
  }

  /**
   * Set Cashier form.
   */
  setCashierForm() {
    this.allocateCashForm = this.formBuilder.group({
      'office': [{ value: this.cashierData.officeName, disabled: true }],
      'tellerName': [{ value: this.cashierData.tellerName, disabled: true }],
      'cashier': [{ value: this.cashierData.cashierName, disabled: true }],
      'assignmentPeriod': [{ value: this.dateUtils.formatDate(this.cashierData.startDate, 'yyyy-MM-dd') + ' - ' + this.dateUtils.formatDate(this.cashierData.endDate, 'yyyy-MM-dd'), disabled: true }],
      'txnDate': [new Date(), Validators.required],
      'currencyCode': ['', Validators.required],
      'txnAmount': ['', Validators.required],
      'txnNote': ['', Validators.required]
    });
  }

  /**
   * Submits Allocate Cash form.
   */
  submit() {
    const unAssignStaffDialogRef = this.dialog.open(ConfirmationDialogComponent, {
      data: { heading: 'labels.buttons.allocatecash', dialogContext: 'allocateCashConfirmation' }
    });
    unAssignStaffDialogRef.afterClosed().subscribe((response: { confirm: any }) => {
      if (response.confirm) {
        const allocateCashFormData = this.allocateCashForm.value;
        const locale = this.settingsService.language.code;
        const dateFormat = 'yyyy-MM-dd';
        const txnDate = this.allocateCashForm.value.txnDate;
        if (allocateCashFormData.closureDate instanceof Date) {
          allocateCashFormData.txnDate = this.dateUtils.formatDate(txnDate, dateFormat);
        }
        const data = {
          ...allocateCashFormData,
          dateFormat,
          locale
        };
        this.organizationService.allocateCash(this.cashierData.tellerId, this.cashierData.cashierId, data).subscribe((response: any) => {
          this.router.navigate(['../'], { relativeTo: this.route });
        });
      }
    });
  }

}
