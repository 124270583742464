import { ActivatedRoute } from '@angular/router';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'mifosx-web-views-loan-charges',
  templateUrl: './web-views-loan-charges.component.html',
  styleUrls: ['./web-views-loan-charges.component.scss']
})
export class WebViewsLoanChargesComponent implements OnInit {


  loanDetailsData: any;
  nextInstallement: string
  constructor(private route: ActivatedRoute) {
    this.route.data.subscribe((data: { loanDetailsData: any }) => {
      this.loanDetailsData = data.loanDetailsData;
      if (this.loanDetailsData?.repaymentSchedule?.periods != null) {
        this.loanDetailsData.repaymentSchedule?.periods.map((period: any) => {


          if (period?.dueDate == this.loanDetailsData.summary?.overdueSinceDate) {
            this.nextInstallement = this.loanDetailsData.summary?.currency?.displaySymbol + " " + period?.totalDueForPeriod
          }
          else if (this.loanDetailsData.summary?.overdueSinceDate == null) {
            this.nextInstallement = "NA";
          }

        })
      }
    })

  }


  ngOnInit(): void {
  }


}
