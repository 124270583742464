/** Angular Imports */
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

/** Routing Imports */
import { Route } from '../core/route/route.service';

/** Translation Imports */
import { extract } from '../core/i18n/i18n.service';

/** Custom Components */
import { CheckerInboxAndTasksComponent } from './checker-inbox-and-tasks/checker-inbox-and-tasks.component';
import { CheckerInboxComponent } from './checker-inbox-and-tasks-tabs/checker-inbox/checker-inbox.component';
import { ClientApprovalComponent } from './checker-inbox-and-tasks-tabs/client-approval/client-approval.component';
import { LoanApprovalComponent } from './checker-inbox-and-tasks-tabs/loan-approval/loan-approval.component';
import { LoanDisbursalComponent } from './checker-inbox-and-tasks-tabs/loan-disbursal/loan-disbursal.component';
import { RescheduleLoanComponent } from './checker-inbox-and-tasks-tabs/reschedule-loan/reschedule-loan.component';
import { ViewCheckerInboxComponent } from './view-checker-inbox/view-checker-inbox.component';

/** Custom Resolvers */
import { GetMakerCheckers } from './common-resolvers/getmakercheckers.resolver';
import { GetGroupedClientsData } from './common-resolvers/getGroupedClientsData.resolver';
import { GetOffices } from './common-resolvers/getOffices.resolver';
import { GetLoans } from './common-resolvers/getLoans.resolver';
import { GetRescheduleLoans } from './common-resolvers/getRescheduleLoans.resolver';
import { MakerCheckerTemplate } from './common-resolvers/makerCheckerTemplate.resolver';
import { GetCheckerInboxDetailResolver } from './common-resolvers/getCheckerInboxDetail.resolver';
import { LoanLockedComponent } from './checker-inbox-and-tasks-tabs/loan-locked/loan-locked.component';
import { LoanLockedResolver } from './checker-inbox-and-tasks-tabs/loan-locked/loan-locked.resolver';
import { GetSavingsApproval } from './common-resolvers/getSavingsApproval.resolver';
import { GetAllSavingsAcativate } from './common-resolvers/getSavingsActivate.resolver';
import { AccountActivationComponent } from './checker-inbox-and-tasks-tabs/account-activation/account-activation.component';
import { AccountApprovalComponent } from './checker-inbox-and-tasks-tabs/account-approval/account-approval.component';
import { ViewMakerCheckerTaskComponent } from './view-maker-checker-task/view-maker-checker-task.component';

/** Tasks Routes */
const routes: Routes = [
  Route.withShell([
    {
      path: 'checker-inbox-and-tasks',
      component: CheckerInboxAndTasksComponent,
      data: { title: extract('labels.anchors.checkerInboxTasks'), breadcrumb: extract('labels.anchors.checkerInboxTasks') },
      children: [
        {
          path: 'checker-inbox',
          component: CheckerInboxComponent,
          data: { title: extract('labels.heading.checkerinbox') },
          resolve: {
            makerCheckerResource: GetMakerCheckers,
            makerCheckerTemplate: MakerCheckerTemplate
          }
        },
        {
          path: 'client-approval',
          component: ClientApprovalComponent,
          data: { title: extract('labels.heading.clientapproval') },
          resolve: {
            groupedClientData: GetGroupedClientsData
          },
        },
        {
          path: 'loan-approval',
          component: LoanApprovalComponent,
          data: { title: extract('labels.heading.loanapproval') },
          resolve: {
            officesData: GetOffices,
            loansData: GetLoans
          },
        },
        {
          path: 'loan-disbursal',
          component: LoanDisbursalComponent,
          data: { title: extract('labels.heading.loandisbursal') },
          resolve: {
            loansData: GetLoans
          }
        }
        ,{
          path: 'account-approval',
          component: AccountApprovalComponent,
          data: { title: extract('labels.heading.accountapproval') },
          resolve: {
            accountData: GetSavingsApproval
          }
        },
        {
          path: 'account-activation',
          component: AccountActivationComponent,
          data: { title: extract('labels.heading.accountactivation') },
          resolve: {
            accountData: GetAllSavingsAcativate
          }
        },
        {
          path: 'loan-locked',
          component: LoanLockedComponent,
          data: { title: extract('labels.heading.loanLocked') },
          resolve: {
            loansData: LoanLockedResolver
          }
        },
        {
          path: 'reschedule-loan',
          component: RescheduleLoanComponent,
          data: { title: extract('labels.heading.rescheduleLoan') },
          resolve: {
            recheduleLoansData: GetRescheduleLoans
          }
        }
      ]
    },
    {
      path: 'checker-inbox-and-tasks/checker-inbox',
      data: { title: extract('labels.anchors.checkerInboxTasks'), breadcrumb: extract('labels.anchors.checkerInboxTasks') },
      children: [
        {
          path: ':id/view',
          component: ViewCheckerInboxComponent,
          data: { title: extract('labels.heading.viewcheckerinbox'), routeParamBreadcrumb: 'id' },
          resolve: {
            checkerInboxDetail: GetCheckerInboxDetailResolver
          }
        }
      ]
    },
    {
      path: 'view-maker-checker-task',
      data: { title: extract('labels.anchors.checkerInboxTasks'), breadcrumb: extract('labels.anchors.checkerInboxTasks') },
      children: [
        {
          path: ':id',
          component: ViewMakerCheckerTaskComponent,
          data: { title: extract('labels.heading.viewcheckerinbox'), routeParamBreadcrumb: 'id' },
          resolve: {
            checkerInboxDetail: GetCheckerInboxDetailResolver
          }
        }
      ]
    },
  ])
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: [
    GetMakerCheckers,
    GetGroupedClientsData,
    GetOffices,
    GetLoans,
    GetRescheduleLoans,
    MakerCheckerTemplate,
    GetCheckerInboxDetailResolver,
    GetSavingsApproval,
    GetAllSavingsAcativate
  ]
})
export class TasksRoutingModule { }
