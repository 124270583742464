import { ErrorHandler, Injectable } from '@angular/core';
import { ProgressBarService } from '../progress-bar/progress-bar.service';

@Injectable()
export class GlobalErrorHandlerService implements ErrorHandler {
  constructor(private progressBarService:ProgressBarService){}

  handleError(error: Error) {
     console.error(error);
     this.progressBarService.stopLoading();
  }
}
