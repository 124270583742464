<div class="container m-b-20" fxLayout="row" fxLayoutAlign="end" fxLayoutGap="20px">
  <div #buttonCreateCharge class="in-block">
    <button mat-raised-button color="primary" [routerLink]="['create']" *mifosxHasPermission="'CREATE_CHARGE'">
      <fa-icon icon="plus"></fa-icon>&nbsp;&nbsp;
      {{"labels.anchors.createcharge" | translate}}
    </button>
  </div>
</div>

<div class="container">

  <div #filter fxLayout="row" fxLayoutGap="20px">
    <mat-form-field fxFlex>
      <mat-label>{{"labels.buttons.filter" | translate}}</mat-label>
      <input matInput (keyup)="applyFilter($event.target.value)">
    </mat-form-field>
  </div>

  <div #chargesTable class="mat-elevation-z8">

    <table mat-table [dataSource]="dataSource" matSort>

      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{"labels.inputs.name" | translate}}</th>
        <td mat-cell *matCellDef="let charge"> {{ charge.name }} </td>
      </ng-container>

      <ng-container matColumnDef="chargeAppliesTo">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{"labels.heading.chargeappliesto" | translate}} </th>
        <td mat-cell *matCellDef="let charge"> {{ charge.chargeAppliesTo.value }} </td>
      </ng-container>

      <ng-container matColumnDef="penalty">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{"labels.inputs.ispenalty" | translate}} </th>
        <td mat-cell *matCellDef="let charge">
            <fa-icon *ngIf="charge.penalty" icon="check-circle" size="lg" class="ispenalty" [matTooltip]="'labels.yes'| translate" matTooltipPosition="right"></fa-icon>
            <fa-icon *ngIf="!charge.penalty" icon="times-circle" size="lg" class="nopenalty" [matTooltip]="'labels.no'| translate" matTooltipPosition="right"></fa-icon>
        </td>
      </ng-container>

      <ng-container matColumnDef="active">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{"labels.menus.status" | translate}} </th>
        <td mat-cell *matCellDef="let charge">
          <div [className]="charge.active === true ? 'true' : 'false'">
            <fa-icon matTooltip="{{ charge.active === true ? 'Active' : 'Inactive' }}" matTooltipPosition="right" icon="circle" size="lg"></fa-icon>
          </div>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;" [routerLink]="[row.id]" class="select-row"></tr>

    </table>

    <mat-paginator [pageSizeOptions]="[10, 25, 50, 100]" showFirstLastButtons></mat-paginator>

  </div>

</div>

<ng-template #templateButtonCreateCharge let-popover="popover">
  <h2>{{"labels.anchors.createcharge" | translate}}</h2>
  <p class="mw300">{{"labels.optionAllowsCreateChargesCupportsLoanCharges" | translate}}</p>
  <div fxLayout="row" fxLayoutAlign="end" fxLayoutGap="2%" fxLayout.lt-md="column">
    <button mat-raised-button color="warn" (click)="popover.close();configurationWizardService.closeConfigWizard()">{{"labels.buttons.close" | translate}}</button>
    <button mat-raised-button color="primary" (click)="popover.close();previousStep()">{{"labels.buttons.back" | translate}}</button>
    <button mat-raised-button color="primary" (click)="popover.close();showPopover(templateFilter,filter, 'bottom', true)">{{"labels.buttons.next" | translate}}</button>
  </div>
</ng-template>

<ng-template #templateFilter let-popover="popover">
  <h4>{{"labels.searchBarFilterCharges" | translate}}</h4>
  <div fxLayout="row" fxLayoutAlign="end" fxLayoutGap="2%" fxLayout.lt-md="column">
    <button mat-raised-button color="warn" (click)="popover.close();configurationWizardService.closeConfigWizard()">{{"labels.buttons.close" | translate}}</button>
    <button mat-raised-button color="primary" (click)="popover.close();showPopover(templateButtonCreateCharge,buttonCreateCharge , 'bottom', true)">{{"labels.buttons.back" | translate}}</button>
    <button mat-raised-button color="primary" (click)="popover.close();showPopover(templateChargesTable,chargesTable, 'top', true)">{{"labels.buttons.next" | translate}}</button>
  </div>
</ng-template>

<ng-template #templateChargesTable let-popover="popover">
  <h4 class="mw300">{{"labels.listChargesOrganizationFormore" | translate}}: <a href="" target="_blank">{{"labels.anchors.charges" | translate}}</a></h4>
  <div fxLayout="row" fxLayoutAlign="end" fxLayoutGap="2%" fxLayout.lt-md="column">
    <button mat-raised-button color="warn" (click)="popover.close();configurationWizardService.closeConfigWizard()">{{"labels.buttons.close" | translate}}</button>
    <button mat-raised-button color="primary" (click)="popover.close();showPopover(templateFilter,filter, 'bottom', true)">{{"labels.buttons.back" | translate}}</button>
    <button mat-raised-button color="primary" (click)="popover.close();nextStep()">{{"labels.buttons.next" | translate}}</button>
  </div>
</ng-template>
