import { ProductsService } from 'app/products/products.service';
import { Router, ActivatedRoute } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AlertService } from 'app/core/alert/alert.service';
import { TranslateService } from '@ngx-translate/core';
import { SettingsService } from 'app/settings/settings.service';


@Component({
  selector: 'mifosx-edit-product-line',
  templateUrl: './edit-product-line.component.html',
  styleUrls: ['./edit-product-line.component.scss']
})
export class EditProductLineComponent implements OnInit {

  parentId: number;
  productLineForm: FormGroup;
  productLinesList: any;
  loanProductsData: any;
  productLineId:any;
  productLine:any;
  parent :any;


  constructor(private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private productsService: ProductsService,
    private alertService: AlertService,
    private translateService: TranslateService,
    private settingsService: SettingsService) {
      this.productLineId = Number(this.route.snapshot.params['id']);

    this.route.parent.data.subscribe((data: { productLine:any }) => {
      this.productLine = data.productLine;
    });
    this.route.data.subscribe((data: { loanProducts: any,productLines :any}) => {
      this.loanProductsData = data.loanProducts;
      this.productLinesList = data.productLines;
    });
  }

  ngOnInit() {
    this.editCLAccountForm();
  }


  editCLAccountForm() {
    const loanProducts = this.productLine.loanProducts.map((item:any)=>item.id)
    this.productLineForm = this.formBuilder.group({
      'limitAmount': [this.productLine.limitAmount, Validators.required],
      'name': [this.productLine.name, Validators.required],
      'loanProducts': [loanProducts, Validators.required],
      'parentId': [this.productLine.parent || undefined],
      'revolving':  [this.productLine?.revolving, ],
      'unique':  [this.productLine?.unique, Validators.required],
      'processingDays':  [this.productLine?.processingDays, Validators.required],
      'validityDays':  [this.productLine?.validityDays, Validators.required],
    });
    this.productLineForm.controls.parentId.disable();
    this.parent = this.productLinesList.find((item:any)=>item.id===this.productLine.parent)
    this.productLineForm.controls.limitAmount.valueChanges.subscribe((parent: any) => {
      this.productLineForm.patchValue({
        'loanProducts': ['', Validators.required],
      })
    })

  }




  submit() {
    const productLineData = {
      ...this.productLineForm.value,
      loanProducts: this.productLineForm.value.loanProducts.map((item:any)=>({"loanProductId":item})),
      locale: this.settingsService.language.code
    }
    this.productsService.editProductLigne(this.productLine.id,productLineData).subscribe((response: any) => {
      this.alertService.alert({ type: 'Success', message: this.translateService.instant("labels.productLineUpdatedSuccessfly") });
      this.router.navigate(["../"],{ relativeTo: this.route })
    });
  }

}
