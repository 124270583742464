<div class="container">

    <mat-card>

      <form [formGroup]="smsConfigurationForm" (ngSubmit)="submit()">

        <mat-card-content>

          <div fxLayout="column">

            <mat-form-field>
              <mat-label>{{"labels.hostName" | translate}}</mat-label>
              <input matInput required formControlName="host_name">
              <mat-error *ngIf="smsConfigurationForm.controls.host_name.hasError('required')">
                {{"labels.hostName" | translate}} <strong>{{"labels.commons.is required" | translate}}</strong>
              </mat-error>
            </mat-form-field>

            <mat-form-field>
              <mat-label>{{"labels.portNumber" | translate}}</mat-label>
              <input matInput required formControlName="port_number">
              <mat-error *ngIf="smsConfigurationForm.controls.port_number.hasError('required')">
                {{"labels.portNumber" | translate}} <strong>{{"labels.commons.is required" | translate}}</strong>
              </mat-error>
            </mat-form-field>

            <mat-form-field>
              <mat-label>{{"labels.endPoint" | translate}}</mat-label>
              <input matInput required formControlName="end_point">
              <mat-error *ngIf="smsConfigurationForm.controls.end_point.hasError('required')">
                {{"labels.endPoint" | translate}} <strong>{{"labels.commons.is required" | translate}}</strong>
              </mat-error>
            </mat-form-field>

            <mat-form-field>
                <mat-label>{{"labels.tenantAppKey" | translate}}</mat-label>
                <input matInput required formControlName="tenant_app_key">
                <mat-error *ngIf="smsConfigurationForm.controls.tenant_app_key.hasError('required')">
                  {{"labels.tenantAppKey" | translate}} <strong>{{"labels.commons.is required" | translate}}</strong>
                </mat-error>
              </mat-form-field>

          </div>

        </mat-card-content>

        <mat-card-actions fxLayout="row" fxLayout.xs="column" fxLayoutAlign="center" fxLayoutGap="5px">
          <button type="button" mat-raised-button [routerLink]="['../']">{{"labels.buttons.cancel" | translate}}</button>
          <button mat-raised-button color="primary" [disabled]="!smsConfigurationForm.valid || smsConfigurationForm.pristine">{{"labels.buttons.submit" | translate}}</button>
        </mat-card-actions>

      </form>

    </mat-card>

  </div>
