<div class="container">

  <mat-card>

    <form [formGroup]="paymentTypeForm" (ngSubmit)="submit()">

      <mat-card-content>

        <div fxLayout="column">

          <mat-form-field>
            <mat-label>{{"labels.heading.paymenttype" | translate}}</mat-label>
            <input required matInput formControlName="name">
            <mat-error *ngIf="paymentTypeForm.controls.name.hasError('required')">
              {{"labels.heading.paymenttype" | translate}} <strong>{{"labels.commons.is required" | translate}}</strong>
            </mat-error>
          </mat-form-field>

          <mat-form-field>
            <mat-label>{{"labels.inputs.description" | translate}}</mat-label>
            <textarea matInput formControlName="description"></textarea>
          </mat-form-field>

          <mat-checkbox labelPosition="before" formControlName="isCashPayment" class="status">
            {{"labels.inputs.iscashpayment" | translate}}
          </mat-checkbox>

          <mat-form-field>
            <mat-label>{{"labels.heading.position" | translate}}</mat-label>
            <input required matInput formControlName="position">
            <mat-error *ngIf="paymentTypeForm.controls.position.hasError('required')">
              {{"labels.heading.position" | translate}} <strong>{{"labels.commons.is required" | translate}}</strong>
            </mat-error>
          </mat-form-field>

        </div>

      </mat-card-content>

      <mat-card-actions fxLayout="row" fxLayout.xs="column" fxLayoutAlign="center" fxLayoutGap="5px">
        <button type="button" mat-raised-button [routerLink]="['../']">{{"labels.buttons.cancel" | translate}}</button>
        <button mat-raised-button color="primary" [disabled]="!paymentTypeForm.valid" *mifosxHasPermission="'CREATE_PAYMENTTYPE'">{{"labels.buttons.submit" | translate}}</button>
      </mat-card-actions>

    </form>

  </mat-card>

</div>
