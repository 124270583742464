/** Angular Imports */
import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

/**
 * Simulator dialog component.
 */
@Component({
  selector: 'mifosx-simulator-dialog',
  templateUrl: './simulator-dialog.component.html',
  styleUrls: ['./simulator-dialog.component.scss']
})
export class SimulatorDialogComponent implements OnInit {

  /**
   * @param {MatDialogRef} dialogRef Component reference to dialog.
   * @param {any} data Provides a deleteContext.
   */
  constructor(public dialogRef: MatDialogRef<SimulatorDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit() {
  }

  close(){
    this.dialogRef.close()
  }

}
