<div class="container">

    <mat-card>

      <div fxLayout="row" fxLayout.lt-md="column">

        <div fxFlex="50%">

          <mat-nav-list>

            <div #simulator>
              <mat-list-item [routerLink]="['simulator']">
                <mat-icon matListIcon>
                  <fa-icon icon="table" size="sm"></fa-icon>
                </mat-icon>
                <h4 matLine>{{"labels.heading.loansimulator" | translate}}</h4>
                <p matLine>{{"labels.heading.loansimulator" | translate}}</p>
              </mat-list-item>
            </div>

            <div #codes>
              <mat-list-item [routerLink]="['viewloans']">
                <mat-icon matListIcon>
                  <fa-icon icon="list-ul" size="sm"></fa-icon>
                </mat-icon>
                <h4 matLine>{{"labels.viewLoans" | translate}}</h4>
                <p matLine>{{"labels.viewLoans" | translate}}</p>
              </mat-list-item>
            </div>

            <div #rolesandpermission>
              <mat-list-item [routerLink]="['repayment']">
                <mat-icon matListIcon>
                  <fa-icon icon="key" size="sm"></fa-icon>
                </mat-icon>
                <h4 matLine>{{"labels.loansRepayment" | translate}}</h4>
                <p matLine>{{"labels.loansRepayment" | translate}}</p>
              </mat-list-item>
            </div>
          </mat-nav-list>

        </div>

        <div fxFlex="50%">

          <mat-nav-list>
            <div #makerCheckerTable>
                <mat-list-item [routerLink]="['loanstracking/loan-approval']">
                  <mat-icon matListIcon>
                    <fa-icon icon="sitemap" size="sm"></fa-icon>
                  </mat-icon>
                  <h4 matLine>{{"labels.anchors.loantracking" | translate}}</h4>
                  <p matLine>{{"labels.anchors.loantracking" | translate}}</p>
                </mat-list-item>
              </div>

              <mat-list-item [routerLink]="['payment-follow/to-pay']">
                <mat-icon matListIcon>
                  <fa-icon icon="anchor" size="sm"></fa-icon>
                </mat-icon>
                <h4 matLine>{{"labels.paymentFollow" | translate}}</h4>
                <p matLine>{{"labels.paymentFollow" | translate}}</p>
              </mat-list-item>

          </mat-nav-list>

        </div>

      </div>

    </mat-card>

  </div>
