<h1 mat-dialog-title>{{ 'labels.heading.uploadclientdocuments' | translate }}</h1>
<div>
    <form [formGroup]="uploadDocumentForm" fxLayout="column">

        <mat-form-field fxFlex>
            <mat-label>{{ 'labels.heading.filename' | translate }}</mat-label>
            <input formControlName="fileName" required matInput />
            <mat-error *ngIf="uploadDocumentForm.controls.fileName.hasError('required')">
                {{ 'labels.heading.filename' | translate }} <strong>{{"labels.commons.is required" | translate}}</strong>
            </mat-error>
        </mat-form-field>

        <mat-form-field fxFlex *ngIf="!documentIdentifier">
            <mat-label>{{ 'labels.inputs.description' | translate }}</mat-label>
            <input formControlName="description" matInput />
        </mat-form-field>

        <mifosx-file-upload flex="60%" (change)="onFileSelect($event)"></mifosx-file-upload>

        <mat-dialog-actions align="end">
            <button mat-raised-button mat-dialog-close>{{"labels.buttons.cancel" | translate}}</button>
            <button mat-raised-button color="primary" [disabled]="!uploadDocumentForm.valid" [mat-dialog-close]="uploadDocumentForm.value">{{ 'labels.buttons.confirm' | translate }}</button>
        </mat-dialog-actions>

    </form>
</div>
