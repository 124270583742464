<div fxLayout="row" fxLayoutAlign="end" fxLayoutGap="2%" fxLayout.lt-md="column" class="container m-b-20">
  <button mat-raised-button color="primary" [routerLink]="['edit']">
    <fa-icon icon="edit"></fa-icon>&nbsp;&nbsp;
    {{"labels.inputs.edit" | translate}}
  </button>
  <button mat-raised-button color="warn" (click)="deleteAccountingClosure()" *mifosxHasPermission="'DELETE_GLCLOSURE'">
    <fa-icon icon="trash"></fa-icon>&nbsp;&nbsp;
    {{"labels.anchors.delete" | translate}}
  </button>
</div>

<div class="container" *ngIf="glAccountClosure">

  <mat-card>

    <mat-card-content>

      <div fxLayout="row wrap" class="content">

        <div fxFlex="50%" class="header">
          {{"labels.anchors.office" | translate}}
        </div>

        <div fxFlex="50%">
          {{ glAccountClosure.officeName }}
        </div>

        <div fxFlex="50%" class="header">
          {{"labels.inputs.closuredate" | translate}}
        </div>

        <div fxFlex="50%">
          {{ glAccountClosure.closingDate }}
        </div>

        <div fxFlex="50%" class="header">
          {{"labels.heading.closedby" | translate}}
        </div>

        <div fxFlex="50%">
          {{ glAccountClosure.createdByUsername }}
        </div>

        <div fxFlex="50%" class="header">
          {{"labels.heading.updatedby" | translate}}
        </div>

        <div fxFlex="50%">
          {{ glAccountClosure.lastUpdatedByUsername }}
        </div>

        <div fxFlex="50%" class="header">
          {{"labels.heading.updatedon" | translate}}
        </div>

        <div fxFlex="50%">
          {{ glAccountClosure.lastUpdatedDate }}
        </div>

        <div fxFlex="50%" class="header">
          {{"labels.heading.closurecreationdate" | translate}}
        </div>

        <div fxFlex="50%">
          {{ glAccountClosure.createdDate }}
        </div>

        <div fxFlex="50%" class="header">
          {{"labels.heading.comments" | translate}}
        </div>

        <div fxFlex="50%">
          {{ glAccountClosure.comments }}
        </div>

      </div>

    </mat-card-content>

  </mat-card>

</div>
