<div class="container input">

  <mat-card *ngIf="!isCollapsed">

    <form [formGroup]="reportForm" (ngSubmit)="run()">

      <mat-card-content fxLayout="column">

        <ng-container  *ngFor="let param of paramData" [ngSwitch]="param.displayType">

          <ng-container *ngIf="reportForm.contains(param.name)">

            <mat-form-field fxFlex *ngSwitchCase="'text'">
              <mat-label>{{param.label}}</mat-label>
              <input matInput required [formControlName]="param.name">
              <mat-error *ngIf="reportForm.controls[param.name].hasError('required')">
                {{param.label}} <strong>{{"labels.commons.is required" | translate}}</strong>
              </mat-error>
            </mat-form-field>

            <mat-form-field fxFlex *ngSwitchCase="'date'" (click)="runReportDatePicker.open()">
              <mat-label>{{param.label}}</mat-label>
              <input matInput [min]="minDate" [max]="maxDate" [matDatepicker]="runReportDatePicker" required
                [formControlName]="param.name">
              <mat-datepicker-toggle matSuffix [for]="runReportDatePicker"></mat-datepicker-toggle>
              <mat-datepicker #runReportDatePicker></mat-datepicker>
              <mat-error *ngIf="reportForm.controls[param.name].hasError('required')">
                {{param.label}} <strong>{{"labels.commons.is required" | translate}}</strong>
              </mat-error>
            </mat-form-field>

            <mat-form-field fxFlex *ngSwitchCase="'select'">
              <mat-label>{{param.label}}</mat-label>
              <mat-select required [formControlName]="param.name">
                <mat-option *ngFor="let option of param.selectOptions" [value]="option">
                  {{option.name}}
                </mat-option>
              </mat-select>
              <mat-error *ngIf="reportForm.controls[param.name].hasError('required')">
                {{param.label}} <strong>{{"labels.commons.is required" | translate}}</strong>
              </mat-error>
            </mat-form-field>

          </ng-container>

        </ng-container>

        <mat-form-field fxFlex>
          <mat-label>{{ 'labels.inputs.decimalplace' | translate }}</mat-label>
          <mat-select [formControl]="decimalChoice">
            <mat-option value="0">0</mat-option>
            <mat-option value="1">1</mat-option>
            <mat-option value="2">2</mat-option>
            <mat-option value="3">3</mat-option>
            <mat-option value="4">4</mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field fxFlex *ngIf="report.type === 'Pentaho' && reportForm.contains('outputType')">
          <mat-label>{{ 'labels.inputs.outputtype' | translate }}</mat-label>
          <mat-select formControlName="outputType" value="HTML">
            <mat-option value="HTML">{{ 'labels.inputs.showreport' | translate }}</mat-option>
            <mat-option value="XLS">{{ 'labels.inputs.exportexcel' | translate }}</mat-option>
            <mat-option value="XLSX">{{ 'labels.inputs.exportexcel2' | translate }}</mat-option>
            <mat-option value="CSV">{{ 'labels.inputs.exportcsv' | translate }}</mat-option>
            <mat-option value="PDF">{{ 'labels.inputs.pdfformat' | translate }}</mat-option>
          </mat-select>
        </mat-form-field>

      </mat-card-content>

      <mat-card-actions fxLayout="row" fxLayout.xs="column" fxLayoutAlign="center" fxLayoutGap="5px">
        <button type="button" mat-raised-button [routerLink]="['/reports']">{{"labels.buttons.cancel" | translate}}</button>
        <button mat-raised-button color="primary" [disabled]="!reportForm.valid" id="runreport">
          <fa-icon icon="cogs"></fa-icon>
          &nbsp; {{ 'labels.anchors.runreport' | translate }}
        </button>
      </mat-card-actions>

    </form>

  </mat-card>

</div>

<div class="container output" *ngIf="isCollapsed">

  <mat-card>

    <div class="m-b-20">
      <button mat-raised-button color="primary" (click)="isCollapsed = false">
        {{ 'labels.buttons.parameters' | translate }}
      </button>
    </div>

    <div>
      <mifosx-table-and-sms *ngIf="!hideTable"[dataObject]="dataObject"></mifosx-table-and-sms>
      <mifosx-chart *ngIf="!hideChart" [dataObject]="dataObject"></mifosx-chart>
      <mifosx-pentaho *ngIf="!hidePentaho" [dataObject]="dataObject"></mifosx-pentaho>
    </div>

  </mat-card>

</div>
