<div fxLayout="row" fxLayoutAlign="end" fxLayoutGap="2%" fxLayout.lt-md="column" class="container m-b-20">
    <span *mifosxHasPermission="'ACTIVATE_HOLIDAY'">
    <button mat-raised-button color="accent" (click)="activateHoliday()" *ngIf="holidayData.status.value !== 'Active'">
      <fa-icon icon="lock-open"></fa-icon>&nbsp;&nbsp;
      {{"labels.anchors.activate" | translate}}
    </button>
  </span>
    <button mat-raised-button color="primary" [routerLink]="['edit']" *mifosxHasPermission="'UPDATE_HOLIDAY'">
    <fa-icon icon="edit"></fa-icon>&nbsp;&nbsp;
    {{"labels.inputs.edit" | translate}}
  </button>
    <button mat-raised-button color="warn" (click)="deleteHoliday()" *mifosxHasPermission="'DELETE_HOLIDAY'">
    <fa-icon icon="trash"></fa-icon>&nbsp;&nbsp;
    {{"labels.anchors.delete" | translate}}
  </button>
</div>

<div class="container">

    <mat-card>

        <mat-card-content>

            <div fxLayout="row wrap" class="content">

                <div fxFlex="50%" class="header">
                    {{"labels.inputs.name" | translate}}
                </div>

                <div fxFlex="50%">
                    {{ holidayData.name }}
                </div>

                <div fxFlex="50%" class="header">
                    {{"labels.inputs.fromdate" | translate}}
                </div>

                <div fxFlex="50%">
                    {{ holidayData.fromDate  | dateFormat }}
                </div>

                <div fxFlex="50%" class="header">
                    {{"labels.inputs.todate" | translate}}
                </div>

                <div fxFlex="50%">
                    {{ holidayData.toDate  | dateFormat }}
                </div>

                <div fxFlex="50%" class="header">
                    {{"labels.heading.repaymentsheduleto" | translate}}
                </div>

                <div fxFlex="50%" *ngIf="holidayData.repaymentsRescheduledTo !== undefined && holidayData.repaymentsRescheduledTo !== null">
                    {{ holidayData.repaymentsRescheduledTo  | dateFormat }}
                </div>

                <div fxFlex="50%" *ngIf="holidayData.repaymentsRescheduledTo === undefined || holidayData.repaymentsRescheduledTo === null">
                    {{"labels.inputs.nextrepaymentdate" | translate}}
                </div>

            </div>

        </mat-card-content>

    </mat-card>

</div>
