<div class="container m-b-20" fxLayout="row" fxLayoutAlign="end" fxLayoutGap="20px">
  <div #createDatatableRef class="in-block">
    <button mat-raised-button color="primary" [routerLink]="['create']" *mifosxHasPermission="'CREATE_DATATABLE'">
      <fa-icon icon="plus"></fa-icon>&nbsp;&nbsp;{{"labels.createDatatable" | translate}}</button>
  </div>
</div>

<div class="container">

  <div #filter fxLayout="row" fxLayoutGap="20px">
    <mat-form-field fxFlex>
      <mat-label>&nbsp;&nbsp;{{"labels.buttons.filter" | translate }}</mat-label>
      <input matInput (keyup)="applyFilter($event.target.value)">
    </mat-form-field>
  </div>

  <div #datatablesList class="mat-elevation-z8">

    <table mat-table [dataSource]="dataSource" matSort>

      <ng-container matColumnDef="registeredTableName">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>{{"labels.inputs.datatablename" | translate}}</th>
        <td mat-cell *matCellDef="let dataTable"> {{ dataTable.registeredTableName }} </td>
      </ng-container>

      <ng-container matColumnDef="applicationTableName">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>{{"labels.heading.associatedwith" | translate}}</th>
        <td mat-cell *matCellDef="let dataTable"> {{ dataTable.applicationTableName }} </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;" [routerLink]="[row.registeredTableName]" class="select-row"></tr>

    </table>

    <mat-paginator [pageSizeOptions]="[10, 25, 50, 100]" showFirstLastButtons></mat-paginator>

  </div>

</div>

<ng-template #templateCreateDatatableRef let-popover="popover">
  <h2>{{"labels.createDatatable" | translate}}</h2>
  <p class="mw300">{{"labels.thisWillLaunchPageThatWillAllowCreateDataTable" | translate}}</p>
  <div fxLayout="row" fxLayoutAlign="end" fxLayoutGap="2%" fxLayout.lt-md="column">
    <button mat-raised-button color="warn" (click)="popover.close();configurationWizardService.closeConfigWizard()">{{"labels.buttons.close" | translate}}</button>
    <button mat-raised-button color="primary" (click)="popover.close();previousStep();">{{"labels.buttons.back" | translate}}</button>
    <button mat-raised-button color="primary" (click)="popover.close();showPopover(templateFilter,filter,'bottom', true);">{{"labels.buttons.next" | translate}}</button>
  </div>
</ng-template>

<ng-template #templateFilter let-popover="popover">
  <h4>{{"labels.searchBarFilterDatatables" | translate}}</h4>
  <div fxLayout="row" fxLayoutAlign="end" fxLayoutGap="2%" fxLayout.lt-md="column">
    <button mat-raised-button color="warn" (click)="popover.close();configurationWizardService.closeConfigWizard()">{{"labels.buttons.close" | translate}}</button>
    <button mat-raised-button color="primary" (click)="popover.close();showPopover(templateCreateDatatableRef,createDatatableRef,'bottom', true);">{{"labels.buttons.back" | translate}}</button>
    <button mat-raised-button color="primary" (click)="popover.close();showPopover(templateDatatablesList,datatablesList,'top', true);">{{"labels.buttons.next" | translate}}</button>
  </div>
</ng-template>

<ng-template #templateDatatablesList let-popover="popover">
  <h4>{{"labels.listAllCurrentlyAvailableDataTables" | translate}}</h4>
  <div fxLayout="row" fxLayoutAlign="end" fxLayoutGap="2%" fxLayout.lt-md="column">
    <button mat-raised-button color="warn" (click)="popover.close();configurationWizardService.closeConfigWizard()">{{"labels.buttons.close" | translate}}</button>
    <button mat-raised-button color="primary" (click)="popover.close();showPopover(templateFilter,filter,'bottom', true);">{{"labels.buttons.back" | translate}}</button>
    <button mat-raised-button color="primary" (click)="popover.close();nextStep();">{{"labels.buttons.next" | translate}}</button>
  </div>
</ng-template>
