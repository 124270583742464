import { LoansComponent } from './loans/loans.component';
import { NgModule } from '@angular/core';
import { OperationsRoutingModule } from 'app/operations/operations-routing.module';

/** Module Imports */
import { DirectivesModule } from '../directives/directives.module';
import { PipesModule } from '../pipes/pipes.module';
import { SharedModule } from '../shared/shared.module';

/** Dialog Component Imports */
import { DragDropModule } from '@angular/cdk/drag-drop';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgxGraphModule } from '@swimlane/ngx-graph';
import { SimulatorComponent } from './loans/simulator/simulator.component';
import { LoanstrackingComponent } from './loans/loanstracking/loanstracking.component';
import { LoanApprovalComponent } from './loans/loanstracking/loan-approval/loan-approval.component';
import { LoanDisbursalComponent } from './loans/loanstracking/loan-disbursal/loan-disbursal.component';
import { RescheduleLoanComponent } from './loans/loanstracking/reschedule-loan/reschedule-loan.component';
import { ViewaccountsComponent } from './accounts/viewaccounts/viewaccounts.component';
import { AccountsComponent } from './accounts/accounts.component';
import { ViewLoansComponent } from './loans/view-loans/view-loans.component';
import { PaymentFollowComponent } from './loans/payment-follow/payment-follow.component';
import { ToPayComponent } from './loans/payment-follow/to-pay/to-pay.component';
import { OverDueComponent } from './loans/payment-follow/over-due/over-due.component';
import { TellersComponent } from './tellers/tellers.component';
import { SavingsTransferComponent } from './transactions/savings-transfer/savings-transfer.component';
import { RepaymentComponent } from './loans/repayment/repayment.component';
import { FollowAccountsComponent } from './accounts/follow-accounts/follow-accounts.component';
import { AccountActivationComponent } from './accounts/follow-accounts-tabs/account-activation/account-activation.component';
import { AccountApprovalComponent } from './accounts/follow-accounts-tabs/account-approval/account-approval.component';

@NgModule({
  imports: [
    OperationsRoutingModule,
    SharedModule,
    PipesModule,
    NgxGraphModule,
    BrowserAnimationsModule,
    DragDropModule,
    DirectivesModule
  ],
  declarations: [
    LoansComponent,
    SimulatorComponent,
    LoanstrackingComponent,
    LoanApprovalComponent,
    LoanDisbursalComponent,
    RescheduleLoanComponent,
    ViewLoansComponent,
    PaymentFollowComponent,
    ToPayComponent,
    OverDueComponent,
    ViewaccountsComponent,
    AccountsComponent,
    ViewLoansComponent,
    TellersComponent,
    SavingsTransferComponent,
    RepaymentComponent,
    FollowAccountsComponent,
    AccountActivationComponent,
    AccountApprovalComponent
  ],
})
export class OperationsModule { }
