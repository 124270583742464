<div class="container">

  <mat-card>

    <div fxLayout="row" fxLayout.lt-md="column">

      <div fxFlex="50%">

        <mat-nav-list>

          <mat-list-item *mifosxHasPermission="'CREATE_JOURNALENTRY'"
            [routerLink]="['journal-entries/frequent-postings']">
            <mat-icon matListIcon>
              <fa-icon icon="sync" size="sm"></fa-icon>
            </mat-icon>
            <h4 matLine>{{'labels.anchors.frequentpostings' | translate}}</h4>
            <p matLine>{{'labels.heading.thesearepredefinedpostings' | translate}}</p>
          </mat-list-item>

          <div #createJournalEntries>
            <mat-list-item *mifosxHasPermission="'CREATE_JOURNALENTRY'" [routerLink]="['journal-entries/create']">
              <mat-icon matListIcon>
                <fa-icon icon="plus" size="sm"></fa-icon>
              </mat-icon>
              <h4 matLine>{{'labels.anchors.addjournalentries' | translate}}</h4>
              <p matLine>{{ 'labels.heading.manualjournalentrytransactionsrecordedinajournal' | translate}}</p>
            </mat-list-item>
          </div>

          <mat-list-item *mifosxHasPermission="'READ_JOURNALENTRY'" [routerLink]="['journal-entries']">
            <mat-icon matListIcon>
              <fa-icon icon="search" size="sm"></fa-icon>
            </mat-icon>
            <h4 matLine>{{'labels.anchors.searchjournalentries' | translate}}</h4>
            <p matLine>{{ 'labels.heading.advancesearchoptionforjournalentries' | translate}}</p>
          </mat-list-item>

          <div #accountsLinked>
            <mat-list-item *mifosxHasPermission="'READ_FINANCIALACTIVITYACCOUNT'"
              [routerLink]="['financial-activity-mappings']">
              <mat-icon matListIcon>
                <fa-icon icon="link" size="sm"></fa-icon>
              </mat-icon>
              <h4 matLine>{{'labels.anchors.financialactivity.accountmappings' | translate}}</h4>
              <p matLine>{{ 'labels.heading.listof.financialactivity.accountmappings' | translate}}</p>
            </mat-list-item>
          </div>

          <div #migrateOpeningBalances>
            <mat-list-item *mifosxHasPermission="'READ_JOURNALENTRY'" [routerLink]="['migrate-opening-balances']">
              <mat-icon matListIcon>
                <fa-icon icon="hand-holding-usd" size="sm"></fa-icon>
              </mat-icon>
              <h4 matLine>{{'labels.anchors.define.openingbalances' | translate}}</h4>
              <p matLine>{{ 'labels.heading.define.openingbalances' | translate}}</p>
            </mat-list-item>
          </div>

        </mat-nav-list>

      </div>

      <div fxFlex="50%">

        <mat-nav-list>

          <div #chartofAccounts>
            <mat-list-item *mifosxHasPermission="'READ_GLACCOUNT'" [routerLink]="['chart-of-accounts']">
              <mat-icon matListIcon>
                <fa-icon icon="sitemap" size="sm"></fa-icon>
              </mat-icon>
              <h4 matLine>{{'labels.anchors.chartofaccounts' | translate}}</h4>
              <p matLine>{{ 'labels.heading.listoftheaccountsusedbytheorganization' | translate}}</p>
            </mat-list-item>
          </div>

          <div #closingEntries>
            <mat-list-item *mifosxHasPermission="'READ_GLCLOSURE'" [routerLink]="['closing-entries']">
              <mat-icon matListIcon>
                <fa-icon icon="archive" size="sm"></fa-icon>
              </mat-icon>
              <h4 matLine>{{'labels.anchors.closingentries' | translate}}</h4>
              <p matLine>{{ 'labels.heading.journalentriesmadeattheendofanaccountingperiod' | translate}}</p>
            </mat-list-item>
          </div>

          <mat-list-item *mifosxHasPermission="'READ_ACCOUNTINGRULE'" [routerLink]="['accounting-rules']">
            <mat-icon matListIcon>
              <fa-icon icon="list" size="sm"></fa-icon>
            </mat-icon>
            <h4 matLine>{{'labels.anchors.accountingrules' | translate}}</h4>
            <p matLine>{{ 'labels.heading.listsallaccountingrules' | translate}}</p>
          </mat-list-item>

          <mat-list-item *mifosxHasPermission="'EXECUTE_PERIODICACCRUALACCOUNTING'"
            [routerLink]="['periodic-accruals']">
            <mat-icon matListIcon>
              <fa-icon icon="calendar" size="sm"></fa-icon>
            </mat-icon>
            <h4 matLine>{{'labels.heading.execute.periodic.accounting' | translate}}</h4>
            <p matLine>{{ 'labels.heading.execute.periodic.accounting' | translate}}</p>
          </mat-list-item>

          <mat-list-item *mifosxHasPermission="'VIEW_PROVISIONING_ENTRIES'" [routerLink]="['provisioning-entries']">
            <mat-icon matListIcon>
              <fa-icon icon="cogs" size="sm"></fa-icon>
            </mat-icon>
            <h4 matLine>{{'labels.heading.provisioningEntries' | translate}}</h4>
            <p matLine>{{ 'labels.anchors.createprovisioningeentries' | translate}}</p>
          </mat-list-item>

        </mat-nav-list>

      </div>

    </div>

  </mat-card>

</div>

<ng-template #templateChartofAccounts let-popover="popover">
  <h2>{{'labels.anchors.chartofaccounts' | translate}}</h2>
  <p class="mw300">{{"labels.chartAccountsGeneralLedgerSetupAllowsYou" | translate : {'name' : appName} }}</p>
  <div fxLayout="row" fxLayoutAlign="end" fxLayoutGap="2%" fxLayout.lt-md="column">
    <button mat-raised-button color="warn"
      (click)="popover.close();configurationWizardService.closeConfigWizard()">{{'labels.buttons.close' |
      translate}}</button>
    <button mat-raised-button color="primary"
      (click)="popover.close();previousStepChartofAccounts()">{{'labels.buttons.back' | translate}}</button>
    <button mat-raised-button color="primary"
      (click)="popover.close();nextStepChartofAccounts()">{{'labels.buttons.next' | translate}}</button>
  </div>
</ng-template>

<ng-template #templateAccountsLinked let-popover="popover">
  <h2>{{'labels.heading.financialactivity.accountmappings' | translate}}</h2>
  <p class="mw400">{{"labels.accountsLinkedFinancialActivities" | translate}}</p>
  <div fxLayout="row" fxLayoutAlign="end" fxLayoutGap="2%" fxLayout.lt-md="column">
    <button mat-raised-button color="warn"
      (click)="popover.close();configurationWizardService.closeConfigWizard()">{{'labels.buttons.close' |
      translate}}</button>
    <button mat-raised-button color="primary"
      (click)="popover.close();previousStepAccountsLinked()">{{'labels.buttons.back' | translate}}</button>
    <button mat-raised-button color="primary" (click)="popover.close();nextStepAccountsLinked()">{{'labels.buttons.next'
      | translate}}</button>
  </div>
</ng-template>

<ng-template #templateMigrateOpeningBalances let-popover="popover">
  <h2>{{'labels.anchors.define.openingbalances' | translate}}</h2>
  <p class="mw400">{{"labels.tMakesEasierOrganizationWantingToMove" | translate: {'name' : appName} }}</p>
  <div fxLayout="row" fxLayoutAlign="end" fxLayoutGap="2%" fxLayout.lt-md="column">
    <button mat-raised-button color="warn"
      (click)="popover.close();configurationWizardService.closeConfigWizard()">{{'labels.buttons.close' |
      translate}}</button>
    <button mat-raised-button color="primary"
      (click)="popover.close();previousStepMigrateOpeningBalances()">{{'labels.buttons.back' | translate}}</button>
    <button mat-raised-button color="primary"
      (click)="popover.close();nextStepMigrateOpeningBalances()">{{'labels.buttons.next' | translate}}</button>
  </div>
</ng-template>

<ng-template #templateClosingEntries let-popover="popover">
  <h2>{{'labels.anchors.closingentries' | translate}}</h2>
  <p class="mw300">{{"labels.inAPPNAMEYouCanCloseAccountingToJournalEntries" | translate: {'name' : appName} }}</p>
  <div fxLayout="row" fxLayoutAlign="end" fxLayoutGap="2%" fxLayout.lt-md="column">
    <button mat-raised-button color="warn"
      (click)="popover.close();configurationWizardService.closeConfigWizard()">{{'labels.buttons.close' |
      translate}}</button>
    <button mat-raised-button color="primary"
      (click)="popover.close();previousStepClosingEntries()">{{'labels.buttons.back' | translate}}</button>
    <button mat-raised-button color="primary" (click)="popover.close();nextStepClosingEntries()">{{'labels.buttons.next'
      | translate}}</button>
  </div>
</ng-template>

<ng-template #templateCreateJournalEntries let-popover="popover">
  <h2>{{'labels.anchors.addjournalentries' | translate}}</h2>
  <p class="mw300">{{"labels.journalEntriesEssentiallyLogs" | translate}}</p>
  <div fxLayout="row" fxLayoutAlign="end" fxLayoutGap="2%" fxLayout.lt-md="column">
    <button mat-raised-button color="warn"
      (click)="popover.close();configurationWizardService.closeConfigWizard()">{{'labels.buttons.close' |
      translate}}</button>
    <button mat-raised-button color="primary"
      (click)="popover.close();previousStepCreateJournalEntries()">{{'labels.buttons.back' | translate}}</button>
    <button mat-raised-button color="primary"
      (click)="popover.close();nextStepCreateJournalEntries()">{{'labels.buttons.next' | translate}}</button>
  </div>
</ng-template>
