<div class="container m-b-20" fxLayout="row" fxLayoutAlign="end" fxLayoutGap="20px">

  <button mat-raised-button color="primary" [routerLink]="['edit']" *mifosxHasPermission="'UPDATE_HOOK'">
    <fa-icon icon="edit"></fa-icon>&nbsp;&nbsp;{{"labels.inputs.edit" | translate }}</button>

  <button mat-raised-button color="warn" (click)="delete()" *mifosxHasPermission="'DELETE_HOOK'">
    <fa-icon icon="trash"></fa-icon>&nbsp;&nbsp;{{"labels.anchors.delete" | translate }}</button>

</div>

<div class="container">

  <mat-card>

    <mat-card-content>

      <div fxLayout="row wrap" class="content">

        <div fxFlex="50%" class="mat-body-strong">
          {{"labels.heading.hookname" | translate}}:
        </div>

        <div fxFlex="50%">
          {{ hookData.displayName }}
        </div>

        <div fxFlex="50%" class="mat-body-strong">
          {{"labels.heading.status" | translate}}:
        </div>

        <div fxFlex="50%">
          {{ hookData.isActive ? 'Active' : 'Inactive' }}
        </div>

        <div fxFlex="50%" class="mat-body-strong">
          {{"labels.heading.activationdate" | translate}}:
        </div>

        <div fxFlex="50%">
          {{ hookData.createdAt  | dateFormat }}
        </div>

        <div fxFlex="50%" class="mat-body-strong">
          {{"labels.heading.updatedon" | translate}}:
        </div>

        <div fxFlex="50%">
          {{ hookData.updatedAt  | dateFormat }}
        </div>

        <div fxFlex="50%" class="mat-body-strong">
          {{"labels.heading.events" | translate}}:
        </div>

        <div fxFlex="50%">

          <mat-label *ngFor="let event of hookData.events">

            {{ event.actionName + '  -  ' + event.entityName }}

            <br>

          </mat-label>

        </div>

        <div fxFlex="50%" class="mat-body-strong" *ngIf="hookData.name === 'Web'">
          {{"labels.contentType" | translate}}:
        </div>

        <div fxFlex="50%" *ngIf="hookData.name === 'Web'">
          {{ hookData.config[0].fieldValue }}
        </div>

        <div fxFlex="50%" class="mat-body-strong">
          {{"labels.payloadURL" | translate}}:
        </div>

        <div fxFlex="50%" *ngIf="hookData.name === 'Web'">
          {{ hookData.config[1].fieldValue }}
        </div>

        <div fxFlex="50%" *ngIf="hookData.name === 'SMS Bridge'">
          {{ hookData.config[0].fieldValue }}
        </div>

        <div fxFlex="50%" class="mat-body-strong" *ngIf="hookData.name === 'SMS Bridge'">
         {{"labels.phoneNumber" | translate}}:
        </div>

        <div fxFlex="50%" *ngIf="hookData.name === 'SMS Bridge'">
          {{ hookData.config[1].fieldValue }}
        </div>

        <div fxFlex="50%" class="mat-body-strong" *ngIf="hookData.name === 'SMS Bridge'">
          {{"labels.inputs.smsprovider" | translate}}:
        </div>

        <div fxFlex="50%" *ngIf="hookData.name === 'SMS Bridge'">
          {{ hookData.config[2].fieldValue }}
        </div>

        <div fxFlex="50%" class="mat-body-strong" *ngIf="hookData.name === 'SMS Bridge'">
         {{"labels.SMSProviderAccountID" | translate}}:
        </div>

        <div fxFlex="50%" *ngIf="hookData.name === 'SMS Bridge'">
          {{ hookData.config[3].fieldValue }}
        </div>

        <div fxFlex="50%" class="mat-body-strong" *ngIf="hookData.name === 'SMS Bridge'">
          {{"labels.SMSProviderToken" | translate}}:
        </div>

        <div fxFlex="50%" *ngIf="hookData.name === 'SMS Bridge'">
          {{ hookData.config[4].fieldValue }}
        </div>

      </div>

    </mat-card-content>

  </mat-card>

</div>
