import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { SystemService } from 'app/system/system.service';

@Component({
  selector: 'mifosx-edit-credit-bureau-configuration',
  templateUrl: './edit-credit-bureau-configuration.component.html',
  styleUrls: ['./edit-credit-bureau-configuration.component.scss']
})
export class EditCreditBureauConfigurationComponent implements OnInit {

  creditBureauForm: FormGroup;
  cbConfigs :any= [];


  /**
   * @param {ActivatedRoute} route ActivatedRoute.
   * @param {Router} router Router.
   * @param {ConfigurationWizardService} configurationWizardService ConfigurationWizard Service.
   * @param {PopoverService} popoverService PopoverService.
   */
  constructor(private route: ActivatedRoute,
    private router: Router,
    private systemService: SystemService,
    private formBuilder: FormBuilder) {
    this.route.data.subscribe((data: { cbConfigs: any}) => {
      this.cbConfigs = data.cbConfigs;
    });
    this.creditBureauForm = this.formBuilder.group({
      'creditBureauConfigurationId': [ '', Validators.required ],
      'value': [ '', Validators.required ],
      'description': [ '', Validators.required ]
    });
  }

  ngOnInit(): void {
  }

  submit(){
    const {creditBureauConfigurationId,...formData} = this.creditBureauForm.value;
    this.systemService.updateCreditBureauConfiguration(formData,creditBureauConfigurationId).subscribe((data:any)=>{
      this.router.navigate(['../../'], {relativeTo: this.route});
    })
  }

}
