<div *ngIf = "!hideOutput">

  <div class="m-b-10" *ngIf = "dataObject.report.type === 'Table'" fxLayoutAlign="end">
    <button mat-raised-button color="primary" (click)="downloadCSV()" >
      {{ 'labels.buttons.exportcsv' | translate }}
    </button>
  </div>

  <div class="table-output">
    <table mat-table [dataSource]="dataSource">
      <div *ngFor= "let column of displayedColumns index as i">
        <ng-container [matColumnDef]="column">
          <th mat-header-cell *matHeaderCellDef>{{column}}</th>
          <td mat-cell *matCellDef="let param" > 
            <span *ngIf="isDecimal(i)">{{toDecimal(param.row[i])}}</span>
            <span *ngIf="!isDecimal(i)">{{param.row[i]}}</span>
          </td>
        </ng-container>
      </div>
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="select-row" ></tr>
    </table>
    <mat-paginator [pageSizeOptions]="[10, 25, 50, 100]" showFirstLastButtons></mat-paginator>
  </div>

</div>
