<div class="container">

  <mat-card>

    <form [formGroup]="accountNumberPreferenceForm" (ngSubmit)="submit()">

      <mat-card-content>

        <div fxLayout="column">

          <mat-form-field>
            <mat-label>{{"labels.inputs.accounttype" | translate }}</mat-label>
            <mat-select required formControlName="accountType">
              <mat-option *ngFor="let accountType of accountNumberPreferencesTemplateData.accountTypeOptions" [value]="accountType.id">
                {{ accountType.value }}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="accountNumberPreferenceForm.controls.accountType.hasError('required')">{{"labels.inputs.accounttype" | translate }} <strong>{{"labels.commons.is required" | translate}}</strong>
            </mat-error>
          </mat-form-field>

          <mat-form-field>
            <mat-label>{{"labels.inputs.prefixField" | translate }}</mat-label>
            <mat-select formControlName="prefixType">
              <mat-option *ngFor="let prefixType of prefixTypeData" [value]="prefixType.id">
                {{ prefixType.value }}
              </mat-option>
            </mat-select>
          </mat-form-field>

        </div>

      </mat-card-content>

      <mat-card-actions fxLayout="row" fxLayout.xs="column" fxLayoutAlign="center" fxLayoutGap="5px">
        <button type="button" mat-raised-button [routerLink]="['../']">{{"labels.buttons.cancel" | translate}}</button>
        <button mat-raised-button color="primary"
          [disabled]="!accountNumberPreferenceForm.valid || accountNumberPreferenceForm.pristine" *mifosxHasPermission="'UPDATE_ACCOUNTNUMBERFORMAT'">{{"labels.buttons.submit" | translate}}</button>
      </mat-card-actions>

    </form>

  </mat-card>

</div>
