<div class="container">

    <mat-card class="tasks-card">

      <mat-card-content>

        <nav mat-tab-nav-bar class="navigation-tabs">
          <a mat-tab-link [routerLink]="['./approval']" routerLinkActive #accountApproval="routerLinkActive"
            [active]="accountApproval.isActive" *mifosxHasPermission="'APPROVE_SAVINGSACCOUNT'">
            {{"labels.heading.accountapproval" | translate}}
          </a>
          <a mat-tab-link [routerLink]="['./activation']" routerLinkActive #accountActivation="routerLinkActive"
            [active]="accountActivation.isActive" *mifosxHasPermission="'ACTIVATE_SAVINGSACCOUNT'">
            {{"labels.heading.accountactivation" | translate}}
          </a>
        </nav>

        <router-outlet></router-outlet>

      </mat-card-content>

    </mat-card>

  </div>
