<h1 mat-dialog-title>Floating Rate Period</h1>

<mat-dialog-content>

  <form [formGroup]="floatingRatePeriodForm">

    <div fxLayout="column">

      <mat-form-field (click)="fromDatePicker.open()">
        <mat-label>{{"labels.inputs.fromdate" | translate }}</mat-label>
        <input required matInput formControlName="fromDate" [min]="minDate" [matDatepicker]="fromDatePicker">
        <mat-datepicker-toggle matSuffix [for]="fromDatePicker"></mat-datepicker-toggle>
        <mat-datepicker #fromDatePicker></mat-datepicker>
        <mat-error *ngIf="floatingRatePeriodForm.controls.fromDate.hasError('required')">
          {{"labels.inputs.fromdate" | translate }} <strong>{{"labels.commons.is required" | translate}}</strong>
        </mat-error>
      </mat-form-field>

      <mat-form-field>
        <mat-label>{{"labels.inputs.interestrate" | translate}}</mat-label>
        <input matInput type="number" required formControlName="interestRate">
        <mat-error *ngIf="floatingRatePeriodForm.controls.interestRate.hasError('required')">
          {{"labels.inputs.interestrate" | translate}} <strong>{{"labels.commons.is required" | translate}}</strong>
        </mat-error>
      </mat-form-field>

      <mat-checkbox class="is-differential" formControlName="isDifferentialToBaseLendingRate" labelPosition="before">
        {{"labels.isDifferential" | translate}}
      </mat-checkbox>

    </div>

  </form>

</mat-dialog-content>

<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>{{"labels.buttons.cancel" | translate}}</button>
  <button mat-raised-button color="primary"
    [disabled]="!floatingRatePeriodForm.valid || floatingRatePeriodForm.pristine" (click)="submit()">{{"labels.buttons.submit" | translate}}</button>
</mat-dialog-actions>
