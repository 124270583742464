<div class="container">

  <mat-card>

    <form [formGroup]="transferClientsForm">

      <mat-card-content>

        <div fxLayout="column">

          <mat-form-field>
            <mat-label>{{ 'labels.heading.selectClientMembersforTransfer' | translate }}</mat-label>
            <mat-select required formControlName="clients" multiple>
              <mat-option *ngFor="let member of clientMembers" [value]="{ id: member.id }">
                {{ member.displayName }}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="transferClientsForm.controls.clients.hasError('required')">
              {{ 'labels.anchors.office' | translate }} <strong>{{"labels.commons.is required" | translate}}</strong>
            </mat-error>
          </mat-form-field>

          <mat-checkbox class="m-t-10 m-b-10" labelPosition="before" formControlName="inheritDestinationGroupLoanOfficer">
            {{ 'labels.inputs.inheritgrouploanofficer' | translate }}?
          </mat-checkbox>

          <mat-form-field>
            <mat-label>{{ 'labels.inputs.destinationgroup' | translate }}</mat-label>
            <input matInput formControlName="destinationGroupId" [matAutocomplete]="groupsAutocomplete">
            <mat-error *ngIf="transferClientsForm.controls.destinationGroupId.hasError('required')">
              {{ 'labels.inputs.destinationgroup' | translate }} <strong>{{"labels.commons.is required" | translate}}</strong>
            </mat-error>
          </mat-form-field>

        </div>

        <mat-autocomplete autoActiveFirstOption #groupsAutocomplete="matAutocomplete" [displayWith]="displayGroup">
          <mat-option *ngFor="let group of groupsData" [value]="group">
            {{ group.name }}
          </mat-option>
        </mat-autocomplete>

        <div class="mat-table" *ngIf="transferClientsForm.get('destinationGroupId').value">
          <div class="mat-header-row">
            <div class="mat-header-cell">{{ 'labels.heading.destinationgroupdetails' | translate }}</div>
          </div>
          <div class="mat-row">
            <div class="mat-cell">{{ 'labels.inputs.name' | translate }}</div>
            <div class="mat-cell">{{transferClientsForm.get('destinationGroupId').value.name}}</div>
          </div>
          <div class="mat-row">
            <div class="mat-cell">{{ 'labels.heading.id' | translate }}</div>
            <div class="mat-cell">{{transferClientsForm.get('destinationGroupId').value.id}}</div>
          </div>
          <div class="mat-row">
            <div class="mat-cell">{{ 'labels.heading.activationdate' | translate }}</div>
            <div class="mat-cell">{{(transferClientsForm.get('destinationGroupId').value.activationDate  | dateFormat) || 'labels.heading.notActivated' | translate }}</div>
          </div>
          <div class="mat-row">
            <div class="mat-cell">{{ 'labels.heading.staff' | translate }}</div>
            <div class="mat-cell">{{transferClientsForm.get('destinationGroupId').value.staffName || 'labels.heading.unassigned' | translate }}</div>
          </div>
          <div class="mat-row">
            <div class="mat-cell">{{ 'labels.heading.externalid' | translate }}</div>
            <div class="mat-cell">{{transferClientsForm.get('destinationGroupId').value.externalId || 'labels.heading.unassigned' | translate }}</div>
          </div>
        </div>

      </mat-card-content>

      <mat-card-actions fxLayout="row" fxLayout.xs="column" fxLayoutAlign="center" fxLayoutGap="5px">
        <button type="button" mat-raised-button [routerLink]="['../../']">{{"labels.buttons.cancel" | translate}}</button>
        <button mat-raised-button color="primary" [disabled]="!transferClientsForm.valid" (click)="submit()"
          *mifosxHasPermission="'TRANSFERCLIENTS_GROUP'">{{"labels.buttons.submit" | translate}}</button>
      </mat-card-actions>

    </form>

  </mat-card>

</div>
