/** Angular Imports */
import { Component, OnInit , TemplateRef, ElementRef , ViewChild, AfterViewInit} from '@angular/core';
import { ActivatedRoute, Router, NavigationEnd, Data } from '@angular/router';

/** rxjs Imports */
import { filter } from 'rxjs/operators';

/** Custom Model */
import { Breadcrumb } from './breadcrumb.model';

/** Custom Services */
import { PopoverService } from '../../../configuration-wizard/popover/popover.service';
import { ConfigurationWizardService } from '../../../configuration-wizard/configuration-wizard.service';
import { extract } from 'app/core/i18n/i18n.service';
import { CreditLineActionsButtons } from 'app/credit-lines/credit-line-actions-buttons';

/**
 * Route data property to generate breadcrumb using a static string.
 *
 * Example- breadcrumb: 'Home'
 */
const routeDataBreadcrumb = 'breadcrumb';
/**
 * Route data property to generate breadcrumb using given route parameter name.
 *
 * Example- routeParamBreadcrumb: 'id'
 */
const routeParamBreadcrumb = 'routeParamBreadcrumb';
/**
 * Route data property to generate breadcrumb using resolved data property name.
 *
 * Use array to specify name for a nested object property.
 *
 * Example- routeResolveBreadcrumb: ['user', 'username']
 */
const routeResolveBreadcrumb = 'routeResolveBreadcrumb';
/**
 * Route data property to specify whether generated breadcrumb should have a link.
 *
 * True by default. Specify false if a link is not required.
 *
 * Example- addBreadcrumbLink: false
 */
const routeAddBreadcrumbLink = 'addBreadcrumbLink';

/**
 * Generate breadcrumbs dynamically via route configuration.
 */
@Component({
  selector: 'mifosx-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss']
})
export class BreadcrumbComponent implements OnInit, AfterViewInit {

  /** Array of breadcrumbs. */
  breadcrumbs: Breadcrumb[];
  childrensRoutes = ['loans-accounts','client-collateral','client-credit-line','fixed-deposits-accounts','savings-accounts','recurringdeposits','shares-accounts','standing-instructions'];
  /* Reference of breadcrumb */
  @ViewChild('breadcrumb') breadcrumb: ElementRef<any>;
  /* Template for popover on breadcrumb */
  @ViewChild('templateBreadcrumb') templateBreadcrumb: TemplateRef<any>;

  actionMapping:any;

  // actions:any = {'Transfer Client': 'transferclient'}

  /**
   * Generates the breadcrumbs.
   * @param {ActivatedRoute} activatedRoute Activated Route.
   * @param {Router} router Router for navigation.
   * @param {ConfigurationWizardService} configurationWizardService ConfigurationWizard Service.
   * @param {PopoverService} popoverService PopoverService.
   */
  constructor(private activatedRoute: ActivatedRoute,
              private router: Router,
              private configurationWizardService: ConfigurationWizardService,
              private popoverService: PopoverService,
              private creditLineActionsButtons: CreditLineActionsButtons) {
                this.actionMapping = creditLineActionsButtons.actionMapping;
    this.generateBreadcrumbs();
  }

  ngOnInit() {
  }

  // breadcrumbLabelExists(key: any) {
  //   // Load the JSON file
  //   const jsonData = require('D:/koby/src/assets/translations/fr-FR.json');

  //   // Check if the key exists in the jsonData object
  //   return Object.prototype.hasOwnProperty.call(jsonData, key);
  // }

  checkUrl(url: string){
    if(url){
    let list = url.split('/');
      return list.length>=2 ? !this.childrensRoutes.includes(list[list.length -2]) : true;
    }
    return true
  }
  /**
   * Generates the array of breadcrumbs for the visited route.
   */
  generateBreadcrumbs() {
    const onNavigationEnd = this.router.events.pipe(filter(event => event instanceof NavigationEnd));

    onNavigationEnd.subscribe(() => {
      this.breadcrumbs = [];
      let currentRoute = this.activatedRoute.root;
      let currentUrl = '';

      while (currentRoute.children.length > 0) {
        const childrenRoutes = currentRoute.children;
        let breadcrumbLabel: any;
        let url: any;

        childrenRoutes.forEach(route => {
          currentRoute = route;
          breadcrumbLabel = false;

          if (route.outlet !== 'primary') {
            return;
          }

          const routeURL = route.snapshot.url.map(segment => segment.path).join('/');
          currentUrl += `/${routeURL}`;

          // if (currentUrl === '/') {
          //   breadcrumbLabel = 'Home';
          // }

          const hasData = (route.routeConfig && route.routeConfig.data);

          if (hasData) {
            if (route.snapshot.data.hasOwnProperty(routeResolveBreadcrumb) && route.snapshot.data[routeResolveBreadcrumb]) {
              breadcrumbLabel = route.snapshot.data;
              route.snapshot.data[routeResolveBreadcrumb].forEach((property: any) => {
                breadcrumbLabel = breadcrumbLabel[property];
              });
            } else if (route.snapshot.data.hasOwnProperty(routeParamBreadcrumb) && route.snapshot.paramMap.get(route.snapshot.data[routeParamBreadcrumb])) {
              breadcrumbLabel = route.snapshot.paramMap.get(route.snapshot.data[routeParamBreadcrumb]);
              const routeData: Data = route.snapshot.data;

              // Client view breadcrumb
              if (routeData.breadcrumb === 'labels.anchors.clients') {
                if (routeData.title==='labels.anchors.clientActions') {
                  if (breadcrumbLabel !== 'Create Credit Line' && breadcrumbLabel !== 'Add Charge') {
                    breadcrumbLabel = 'labels.anchors.' + breadcrumbLabel.toLowerCase().replace(/\s/g, "");
                    // breadcrumbLabel = this.actions[breadcrumbLabel];
                  } else if (breadcrumbLabel === 'Create Credit Line') {
                    breadcrumbLabel = 'labels.createCreditLine';
                  }
                  else if (breadcrumbLabel === 'Add Charge') {
                    breadcrumbLabel = 'labels.buttons.addcharge';
                  }
                }
                else {
                  breadcrumbLabel = routeData.clientViewData.displayName;
                }

                // currentUrl += `/general`;

              //Groups breadcrumb
              } else if (routeData.breadcrumb === 'labels.heading.groups') {
                if (routeData.title==='labels.anchors.groupActions'){
                  if (breadcrumbLabel !== 'Transfer Clients' && breadcrumbLabel !== 'Attendance') {
                    breadcrumbLabel = 'labels.anchors.' + breadcrumbLabel.toLowerCase().replace(/\s/g, "");
                  }
                  else if (breadcrumbLabel === 'Transfer Clients') {
                    breadcrumbLabel = 'labels.buttons.transferclients';
                  }
                  else if (breadcrumbLabel === 'Attendance') {
                    breadcrumbLabel = 'labels.buttons.attendance';
                  }
                }
                else {
                  breadcrumbLabel = routeData.groupViewData.name;
                }


              } else if (routeData.breadcrumb === 'labels.CreditLine' && routeData.routeParamBreadcrumb==="action") {
                breadcrumbLabel = this.actionMapping(breadcrumbLabel);
              } else if (routeData.breadcrumb === 'labels.anchors.centers') {
                breadcrumbLabel = routeData.centerViewData.name;
              } else if (routeData.breadcrumb === 'labels.anchors.loans' /*&& routeData.title==='labels.GSIMAccountView'*/) {
                breadcrumbLabel = routeData.loanDetailsData.loanProductName + '(' + routeData.loanDetailsData.accountNo + ')';

            }

              //Loans Accounts Breadcrumb
              else if (routeData.breadcrumb === 'labels.heading.action' && routeData.routeParamBreadcrumb==="action") {
                  if (breadcrumbLabel !== 'Close (as Rescheduled)' && breadcrumbLabel !== 'Loan Screen Reports') {
                  breadcrumbLabel = 'labels.buttons.' + breadcrumbLabel.toLowerCase().replace(/\s/g, "");
                  } else if ( breadcrumbLabel === 'Close (as Rescheduled)') {
                    breadcrumbLabel = 'labels.buttons.close-rescheduled';
                  }
                  else if ( breadcrumbLabel === 'Loan Screen Reports') {
                    breadcrumbLabel = 'labels.buttons.loanscreenreport';
                  }
              }

              //Savings Accounts / groups Actions / Glim Account  breadcrumb
              else if (routeData.breadcrumb === 'labels.anchors.savings') {
                if (routeData.title==='labels.GSIMAccountView' && routeData.routeParamBreadcrumb==="savingAccountId") {
                  breadcrumbLabel = routeData.gsimData[0].childGSIMAccounts[0].productName + '(' + routeData.gsimData[0].accountNumber + ')';
                }
                else if (routeData.title==='labels.GsimAccountActions' && routeData.routeParamBreadcrumb==="name") {
                    breadcrumbLabel = 'labels.buttons.' + breadcrumbLabel.toLowerCase().replace(/\s/g, "");
                }
                else {
                  breadcrumbLabel = routeData.savingsAccountData.savingsProductName + '(' + routeData.savingsAccountData.accountNo + ')';
                }
              }



              // Loans Account Actions
              else  if (routeData.breadcrumb === 'labels.heading.action' && routeData.routeParamBreadcrumb==="name") {
                if (breadcrumbLabel !== 'Unassign Staff' && breadcrumbLabel !== 'Add Charge'  && breadcrumbLabel !== 'Assign Staff' && breadcrumbLabel !== 'Undo Approval') {
                  const lower = breadcrumbLabel.charAt(0).toLowerCase() + breadcrumbLabel.replace(/\s/g, "").slice(1);
                  breadcrumbLabel = 'labels.buttons.' + lower;
                } else if (breadcrumbLabel === 'Unassign Staff') {
                  breadcrumbLabel = 'labels.buttons.unassignstaff';
                } else if (breadcrumbLabel === 'Add Charge') {
                  breadcrumbLabel = 'labels.buttons.addcharge';
                } else if (breadcrumbLabel === 'Assign Staff') {
                  breadcrumbLabel = 'labels.buttons.assignstaff';
                } else if (breadcrumbLabel === 'Undo Approval') {
                  breadcrumbLabel = 'labels.buttons.undoapproval';
                }

              }


              // Fixed Deposits Breadcrumb
              else if (routeData.breadcrumb === 'labels.fixedDeposits') {
                  if (routeData.title==='Fixed Deposits Account Actions'  && routeData.routeParamBreadcrumb==="name") {
                    if (breadcrumbLabel !== 'Add Charge'){
                      const lower = breadcrumbLabel.charAt(0).toLowerCase() + breadcrumbLabel.replace(/\s/g, "").slice(1);
                      breadcrumbLabel = 'labels.buttons.' + lower;
                    }
                    else {
                      breadcrumbLabel = 'labels.buttons.addcharge';
                    }
                  } else {
                    breadcrumbLabel = routeData.fixedDepositsAccountData.depositProductName + '(' + routeData.fixedDepositsAccountData.accountNo + ')';
                  }
              }


              // Recurring Deposits Breadcrumb
              else if (routeData.breadcrumb === 'labels.recurringDeposits') {
                if (routeData.title==='labels.recurringDepositsAccountActions'  && routeData.routeParamBreadcrumb==="name") {
                  if (breadcrumbLabel !== 'Add Charge'){
                    const lower = breadcrumbLabel.charAt(0).toLowerCase() + breadcrumbLabel.replace(/\s/g, "").slice(1);
                    breadcrumbLabel = 'labels.buttons.' + lower;
                  }
                  else {
                    breadcrumbLabel = 'labels.buttons.addcharge';
                  }
                } else {
                  breadcrumbLabel = routeData.recurringDepositsAccountData.depositProductName + '(' + routeData.recurringDepositsAccountData.accountNo + ')';
                }
            }

            // Shares Account Breadcrumb
            else if (routeData.breadcrumb === 'labels.shares') {
              if (routeData.title==='labels.sharesAccountActions'  && routeData.routeParamBreadcrumb==="name") {
                breadcrumbLabel = 'labels.buttons.' + breadcrumbLabel.toLowerCase().replace(/\s/g, "");
              } else {
                breadcrumbLabel = routeData.sharesAccountData.productName + '(' + routeData.sharesAccountData.accountNo + ')';
              }
          }


          // // Glim Account breadcrumb
          // else if (routeData.breadcrumb === 'labels.anchors.loans') {
          //   if (routeData.title==='labels.GLIMAccountView' && routeData.routeParamBreadcrumb==="loanAccountId") {
          //     breadcrumbLabel = /*routeData.glimData[0].clientName + '(' +*/ routeData.glimData[0].parentAccountNo + ')';
          //   }
          //   else if (routeData.title==='labels.GlimAccountActions' && routeData.routeParamBreadcrumb==="name") {
          //       breadcrumbLabel = 'labels.buttons.' + breadcrumbLabel.toLowerCase().replace(/\s/g, "");
          //   }
          //   else {
          //     breadcrumbLabel = routeData.loanDetailsData.loanProductName + '(' + routeData.loanDetailsData.accountNo + ')';
          //   }
          // }

              else if (routeData.breadcrumb === 'labels.anchors.loanproducts') {
                breadcrumbLabel = routeData.loanProduct.name;
              } else if (routeData.breadcrumb === 'labels.inputs.charges') {
                breadcrumbLabel = routeData.loansAccountCharge.name;
              } else if (routeData.breadcrumb === 'labels.savingProducts') {
                breadcrumbLabel = routeData.savingProduct.name;
              } else if (routeData.breadcrumb === 'labels.anchors.shareproducts') {
                breadcrumbLabel = routeData.shareProduct.name;
              } else if (routeData.breadcrumb === 'labels.anchors.fixeddepositproducts') {
                breadcrumbLabel = routeData.fixedDepositProduct.name;
              } else if (routeData.breadcrumb === 'labels.anchors.recurringdepositproducts') {
                breadcrumbLabel = routeData.recurringDepositProduct.name;
              } else if (routeData.breadcrumb === 'labels.anchors.floatingrates') {
                breadcrumbLabel = routeData.floatingRate.name;
              } else if (routeData.breadcrumb === 'labels.inputs.taxcomponents') {
                breadcrumbLabel = routeData.taxComponent.name;
              } else if (routeData.breadcrumb === 'labels.taxGroups') {
                breadcrumbLabel = routeData.taxGroup.name;
              } else if (routeData.breadcrumb === 'labels.anchors.rates') {
                breadcrumbLabel = routeData.rate.name;
              }
            } else if (route.snapshot.data.hasOwnProperty(routeDataBreadcrumb)) {
              breadcrumbLabel = route.snapshot.data[routeDataBreadcrumb];
            }

            if (route.snapshot.data.hasOwnProperty(routeAddBreadcrumbLink)) {
              url = route.snapshot.data[routeAddBreadcrumbLink];
            } else {
              url = currentUrl;
            }
          }

          const breadcrumb: Breadcrumb = {
            label: breadcrumbLabel,
            url: this.checkUrl(url) ? url : this.breadcrumbs[this.breadcrumbs.length-1].url
          };

          if (breadcrumbLabel && !this.breadcrumbs.some((crumb) => crumb.label === breadcrumbLabel)) {
            this.breadcrumbs.push(breadcrumb);
          }
        });
      }
    });
  }

  /**
   * Popover function
   * @param template TemplateRef<any>.
   * @param target HTMLElement | ElementRef<any>.
   * @param position String.
   * @param backdrop Boolean.
   */
  showPopover(template: TemplateRef<any>, target: HTMLElement | ElementRef<any>, position: string, backdrop: boolean): void {
    setTimeout(() => this.popoverService.open(template, target, position, backdrop, {}), 200);
  }

  /**
   * To show popover.
   */
  ngAfterViewInit() {
    if (this.configurationWizardService.showBreadcrumbs === true) {
    setTimeout(() => {
        this.showPopover(this.templateBreadcrumb, this.breadcrumb.nativeElement, 'bottom', true);
      });
    }
  }

  /**
   * Next Step (Home) Configuration Wizard.
   */
  nextStep() {
    this.configurationWizardService.showBreadcrumbs = false;
    this.configurationWizardService.showHome = true;
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.router.onSameUrlNavigation = 'reload';
    this.router.navigate(['/home']);
  }

  /**
   * Previous Step (SideNavBar) Configuration Wizard.
   */
  previousStep() {
    this.configurationWizardService.showBreadcrumbs = false;
    this.configurationWizardService.showSideNavChartofAccounts = true;
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.router.onSameUrlNavigation = 'reload';
    this.router.navigate(['/home']);
  }

}
