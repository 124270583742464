import { ProductsService } from 'app/products/products.service';
import { Router, ActivatedRoute } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, AbstractControl, ValidationErrors } from '@angular/forms';
import { AlertService } from 'app/core/alert/alert.service';
import { TranslateService } from '@ngx-translate/core';
import { SettingsService } from 'app/settings/settings.service';




@Component({
  selector: 'mifosx-create-product-line',
  templateUrl: './create-product-line.component.html',
  styleUrls: ['./create-product-line.component.scss']
})
export class CreateProductLineComponent implements OnInit {

  parentId: number;
  productLineForm: FormGroup;
  productLines: any =[];
  loanProductsData: any;
  parent :any;

  constructor(private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private productsService: ProductsService,
    private alertService: AlertService,
    private translateService: TranslateService,
    private settingsService: SettingsService) {
    this.route.data.subscribe((data: { loanProducts: any, productLines:any }) => {
      this.loanProductsData = data.loanProducts;
      this.productLines = data.productLines;
      this.parentId = this.productLines.find((item:any)=>!item.product).id
    });
  }

  ngOnInit() {
    this.createCLAccountForm();
  }


  createCLAccountForm() {
    this.productLineForm = this.formBuilder.group({
      'limitAmount': ['', Validators.required],
      'name': ['', Validators.required],
      'loanProducts': ['', Validators.required],
      'parentId': [this.parentId || undefined],
      'revolving':  [false, Validators.required],
      'unique':  [false, Validators.required],
      'processingDays':  [, Validators.required],
      'validityDays':  [, Validators.required],
    });

    this.productLineForm.controls.parentId.valueChanges.subscribe((parent: any) => {
      this.parent = parent;
    })
    this.productLineForm.controls.limitAmount.valueChanges.subscribe((parent: any) => {
      this.productLineForm.patchValue({
        'loanProducts': ['', Validators.required],
      })
    })
  }

  // limitAmountValidator(ctrl: AbstractControl): ValidationErrors | null {
  //   const val = ctrl.value;
  //   if (val) {
  //     const products = this.productLineForm.value.loanProducts;
  //     for(var i=0;i<products.length;i++){
  //       if(val<products[i].minPrincipal){
  //         return {
  //           minPrincipal:products[i].name
  //         }
  //       }
  //     };
  //   }
  //   return null;
  // }




  submit() {
    const productLineData = {
      ...this.productLineForm.value,
      parentId: this.productLineForm.value.parentId.id,
      loanProducts: this.productLineForm.value.loanProducts.map((item:any)=>({"loanProductId":item})),
      locale: this.settingsService.language.code
    }
    console.log(productLineData);
    this.productsService.createProductLine(productLineData).subscribe((response: any) => {
      this.alertService.alert({ type: 'Success', message: this.translateService.instant("labels.productLigneCreatedSuccessfly") });
      this.router.navigate(['../','view',response.productLinetId], {relativeTo: this.route});
    });
  }

}
