<div class="container">

  <mat-card>

    <form [formGroup]="provisioningEntryForm" (ngSubmit)="submit()">

      <mat-card-content>

        <div fxLayout="column">

          <mat-form-field fxFlex (click)="datePicker.open()">
            <mat-label>{{"labels.commons.date" | translate}}</mat-label>
            <input matInput [min]="minDate" [max]="maxDate" [matDatepicker]="datePicker" required formControlName="date">
            <mat-datepicker-toggle matSuffix [for]="datePicker"></mat-datepicker-toggle>
            <mat-datepicker #datePicker></mat-datepicker>
            <mat-error *ngIf="provisioningEntryForm.controls.date.hasError('required')">
              {{"labels.commons.date" | translate}} <strong>{{"labels.commons.is required" | translate}}</strong>
            </mat-error>
          </mat-form-field>

          <div fxFlex>
            <mat-checkbox labelPosition="before" formControlName="createjournalentries">
              {{"labels.createJournalEntries" | translate}}
            </mat-checkbox>
          </div>

        </div>

      </mat-card-content>

      <mat-card-actions fxLayout="row" fxLayout.xs="column" fxLayoutAlign="center" fxLayoutGap="5px">
        <button type="button" mat-raised-button [routerLink]="['../']">{{"labels.buttons.cancel" | translate}}</button>
        <button mat-raised-button color="primary" [disabled]="!provisioningEntryForm.valid" *mifosxHasPermission="'CREATE_PROVISIONING_ENTRIES'">{{"labels.buttons.submit" | translate}}</button>
      </mat-card-actions>

    </form>

  </mat-card>

</div>
