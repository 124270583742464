<div class="container">

  <mat-card>

    <form [formGroup]="roleForm" (ngSubmit)="submit()">

      <mat-card-content>

        <div fxLayout="column">

          <mat-form-field>
            <mat-label>{{"labels.inputs.name" | translate }}</mat-label>
            <input matInput required formControlName="name">
            <mat-error *ngIf="roleForm.controls.name.hasError('required')">{{"labels.roleName" | translate}} <strong>{{"labels.commons.is required" | translate}}</strong>
            </mat-error>
          </mat-form-field>

          <mat-form-field>
            <mat-label>{{"labels.inputs.description" | translate }}</mat-label>
            <textarea matInput required formControlName="description"></textarea>
            <mat-error *ngIf="roleForm.controls.description.hasError('required')">
              {{"labels.roleDescription" | translate}} <strong>{{"labels.commons.is required" | translate}}</strong>
            </mat-error>
          </mat-form-field>


          <mat-form-field>
            <mat-label>{{"labels.Dashboards" | translate}}</mat-label>
            <mat-select formControlName="dashboards" multiple>
              <mat-option *ngFor="let dashboard of dashboards" [value]="dashboard.id">{{dashboard.name}}</mat-option>
            </mat-select>            <mat-error *ngIf="roleForm.controls.dashboards.hasError('required')">
              {{"labels.Dashboards" | translate}}  <strong>{{"labels.commons.is required" | translate}}</strong>
            </mat-error>
          </mat-form-field>

        </div>

        </mat-card-content>

        <mat-card-actions fxLayout="row" fxLayout.xs="column" fxLayoutAlign="center" fxLayoutGap="5px">
          <button type="button" mat-raised-button [routerLink]="['../']">{{"labels.buttons.cancel" | translate}}</button>
          <button mat-raised-button color="primary" [disabled]="!roleForm.valid">{{"labels.buttons.submit" | translate}}</button>
        </mat-card-actions>

      </form>

    </mat-card>

</div>
