<div class="container">

  <mat-card class="tasks-card">

    <mat-card-content>
      <div class="tab-container mat-typography" *ngIf="showData">
        <div class="tab-container mat-typography">
          <div class="export">
            <button mat-raised-button color="success" (click)="advencedSearch()">
              <fa-icon icon="search"></fa-icon>&nbsp;&nbsp;{{"labels.advencedSearch" | translate}}
            </button>
            <button mat-raised-button color="success" (click)="exportexcel()">
              <fa-icon icon="file-excel"></fa-icon>&nbsp;&nbsp;{{'labels.buttons.exportexcel' | translate}}
            </button>
          </div>
          <div *ngIf="showSearch" class="search">
            <div fxLayout="row" fxLayoutAlign="start center">
              <div class="search-box" fxFlex="18%">
                <mat-form-field appearance="fill" fxFlex="100%">
                  <input matInput [formControl]="fromPrincipalFilter" type="number" min="0" (keyup)="applyFilters()"
                    [placeholder]="'labels.inputs.fromBalance'| translate">
                </mat-form-field>
              </div>
              <div class="search-box" fxFlex="18%">
                <mat-form-field appearance="fill" fxFlex="100%">
                  <input matInput [formControl]="toPrincipalFilter" type="number" [min]="fromPrincipalFilter.value"
                    (keyup)="applyFilters()" [placeholder]="'labels.inputs.toBalance'| translate">
                </mat-form-field>
              </div>
            </div>

            <div fxLayout="row" fxLayoutAlign="start center">
              <div class="search-box" fxFlex="18%">
                <mat-form-field fxFlex="100%" appearance="fill">
                  <input matInput [max]="endDateFilter.value" [matDatepicker]="submitPicker"
                    [formControl]="startDateFilter" (dateChange)="applyFilters()" (keyup)="applyFilters()"
                    [placeholder]="'labels.heading.fromdate'| translate">
                  <mat-datepicker-toggle matSuffix [for]="submitPicker"></mat-datepicker-toggle>
                  <mat-datepicker #submitPicker></mat-datepicker>
                </mat-form-field>
              </div>
              <div class="search-box" fxFlex="18%">
                <mat-form-field fxFlex="100%" appearance="fill">
                  <input matInput [min]="startDateFilter.value" [matDatepicker]="submitPicker2"
                    [formControl]="endDateFilter" (dateChange)="applyFilters()" (keyup)="applyFilters()"
                    [placeholder]="'labels.inputs.todate'| translate">
                  <mat-datepicker-toggle matSuffix [for]="submitPicker2"><button>{{"labels.buttons.clear" | translate}}</button></mat-datepicker-toggle>
                  <mat-datepicker #submitPicker2><button>{{"labels.buttons.clear" | translate}}</button></mat-datepicker>
                </mat-form-field>
              </div>
            </div>
          </div>
          <table mat-table [dataSource]="dataSource">
            <ng-container matColumnDef="select">
              <th mat-header-cell *matHeaderCellDef>
                <mat-checkbox (change)="$event ? masterToggle(filteredLoans) : null"
                  [checked]="selection.hasValue() && isAllSelected(filteredLoans)"
                  [indeterminate]="selection.hasValue() && !isAllSelected(filteredLoans)"
                  [aria-label]="checkboxLabel(filteredLoans)">
                </mat-checkbox>
              </th>
              <td mat-cell *matCellDef="let row">
                <mat-checkbox (click)="$event.stopPropagation()" (change)="$event ? selection.toggle(row) : null"
                  [checked]="selection.isSelected(row)" [aria-label]="checkboxLabel(row)">
                </mat-checkbox>
              </td>
            </ng-container>

            <ng-container matColumnDef="status">
              <th mat-header-cell *matHeaderCellDef> {{'labels.inputs.status' | translate}}
                <mat-form-field appearance="fill">
                  <mat-select [formControl]="statusFilter" (selectionChange)="applyFilters()" [placeholder]="'labels.anchors.all'| translate">
                    <mat-option value="">
                      {{'labels.heading.all' | translate}}
                    </mat-option>
                    <mat-option *ngFor="let status of statusList" [value]="status">
                      {{ (status.status | translate) + (status.substatus!=='None' ? (':'+ status.substatus | translate)
                      : '') }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </th>
              <td mat-cell *matCellDef="let account">
                <div [ngClass]="account.status.code | statusLookup">
                  <fa-icon icon="stop"></fa-icon>
                </div>
              </td>
            </ng-container>

            <ng-container matColumnDef="accountNo">
              <th mat-header-cell *matHeaderCellDef> {{'labels.heading.savingaccount' | translate}}
                <mat-form-field appearance="fill">
                  <input matInput [formControl]="accountNoFilter" (keyup)="applyFilters()" [placeholder]="'labels.buttons.search'| translate">
                </mat-form-field>
              </th>
              <td mat-cell *matCellDef="let account" class="view-details"
              [routerLink]="['/clients', account.clientId, 'savings-accounts', account.id]">
                {{ account.accountNo }}
              </td>
            </ng-container>

            <ng-container matColumnDef="clientName">
              <th mat-header-cell *matHeaderCellDef> {{'labels.heading.client' | translate}}
                <mat-form-field appearance="fill">
                  <input matInput [formControl]="clientNameFilter" (keyup)="applyFilters()" [placeholder]="'labels.buttons.search'| translate">
                </mat-form-field>
              </th>
              <td mat-cell *matCellDef="let account" class="view-details"
              [routerLink]="['/clients', account.clientId]">
                {{account.clientName}}
              </td>
            </ng-container>

            <ng-container matColumnDef="savingsProductName">
              <th mat-header-cell *matHeaderCellDef> {{'labels.heading.accounttype' | translate}}
                <mat-form-field appearance="fill">
                  <mat-select [formControl]="savingsProductNameFilter" (selectionChange)="applyFilters()"
                    [placeholder]="'labels.anchors.all'| translate">
                    <mat-option value="">
                      {{'labels.heading.all' | translate}}
                    </mat-option>
                    <mat-option *ngFor="let savingsProductName of savingsproducts" [value]="savingsProductName">
                      {{ savingsProductName }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </th>
              <td mat-cell *matCellDef="let account" class="view-details"
              [routerLink]="['/clients', account.clientId, 'savings-accounts', account.id]">
                {{account.savingsProductName}}
              </td>
            </ng-container>

            <ng-container matColumnDef="accountBalance">
              <th mat-header-cell *matHeaderCellDef> {{'labels.heading.balance' | translate}}
                <mat-form-field appearance="fill">
                  <input matInput [formControl]="accountBalanceFilter" type="number" min="0" (keyup)="applyFilters()"
                    [placeholder]="'labels.buttons.search'| translate">
                </mat-form-field>
              </th>
              <td mat-cell *matCellDef="let account"
              [routerLink]="['/clients', account.clientId, 'savings-accounts', account.id]">
              {{ account.summary?.accountBalance | number}} </td>
            </ng-container>

            <ng-container matColumnDef="availableBalance">
              <th mat-header-cell *matHeaderCellDef> {{'labels.heading.availablebalance' | translate}}
                <mat-form-field appearance="fill">
                  <input matInput [formControl]="availableBalanceFilter" type="number" min="0" (keyup)="applyFilters()"
                    [placeholder]="'labels.buttons.search'| translate">
                </mat-form-field>
              </th>
              <td mat-cell *matCellDef="let account"
              [routerLink]="['/clients', account.clientId, 'savings-accounts', account.id]">
               {{ account.summary?.availableBalance | number}} </td>
            </ng-container>

            <ng-container matColumnDef="lastActive">
              <th mat-header-cell *matHeaderCellDef> {{'labels.heading.lastActive' | translate}}
                <mat-form-field appearance="fill">
                  <input matInput [matDatepicker]="submitPicker" [formControl]="lastActiveFilter" [placeholder]="'labels.buttons.search'| translate"
                    (dateChange)="applyFilters()" (keyup)="applyFilters()">
                  <mat-datepicker-toggle matSuffix [for]="submitPicker"></mat-datepicker-toggle>
                  <mat-datepicker #submitPicker></mat-datepicker>
                </mat-form-field>
              </th>
              <td mat-cell *matCellDef="let account" class="view-details"
              [routerLink]="['/clients', account.clientId, 'savings-accounts', account.id]">
                {{account.lastActiveTransactionDate | dateFormat}}
              </td>
            </ng-container>

            <ng-container matColumnDef="officeName">
              <th mat-header-cell *matHeaderCellDef> {{'labels.heading.officeName' | translate}}
                <mat-form-field appearance="fill">
                  <mat-select [formControl]="officeNameFilter" (selectionChange)="applyFilters()" [placeholder]="'labels.anchors.all'| translate">
                    <mat-option value="">
                      {{'labels.heading.all' | translate}}
                    </mat-option>
                    <mat-option *ngFor="let officeName of agencies" [value]="officeName">
                      {{ officeName }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </th>
              <td mat-cell *matCellDef="let account" class="view-details"
              [routerLink]="['/clients', account.clientId, 'savings-accounts', account.id]">
                {{account.officeName}}
              </td>
            </ng-container>


            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="selection.toggle(row)">
            </tr>
          </table>
          <mat-paginator [pageSizeOptions]="[10, 20, 30]" showFirstLastButtons
            aria-label="Select page of periodic elements">
          </mat-paginator>


        </div>
      </div>
    </mat-card-content>
  </mat-card>
</div>
<div class="alert" *ngIf="!(showData)">
  <div class="message">
    <i class="fa fa-exclamation-circle alert-check"></i>
    {{'labels.heading.noSavingsAccounts' | translate}}.
  </div>
</div>
