<div class="container">

    <mat-card>

      <mat-card-content>

        <form [formGroup]="makeAccountTransferForm">

          <div fxLayout="row wrap" fxLayoutGap="2%" fxLayout.lt-md="column">
            <h3 class="mat-h3" fxFlexFill>{{"labels.transferringFrom" | translate}}</h3>
            <mat-form-field  appearance="fill" fxFlex="98%">
              <mat-label>{{"labels.clientFrom" | translate}}</mat-label>
              <input matInput formControlName="fromClientId" [matAutocomplete]="clientsFromAutocomplete">
              <mat-error *ngIf="makeAccountTransferForm.controls.fromClientId.hasError('required')">
                {{"labels.inputs.client" | translate}} <strong>{{"labels.commons.is required" | translate}}</strong>
              </mat-error>
            </mat-form-field>

            <mat-autocomplete autoActiveFirstOption #clientsFromAutocomplete="matAutocomplete" (optionSelected)="updateMySelection($event)" [displayWith]="displayClient">
              <mat-option *ngFor="let client of clientsFromData" [value]="client">
                {{ client.id }} - {{ client.displayName }}
              </mat-option>
            </mat-autocomplete>

            <mat-form-field  appearance="fill" fxFlex="98%">
              <mat-label>{{"labels.inputs.account" | translate}}</mat-label>
              <mat-select required formControlName="fromAccountId" (selectionChange)="accountChangeEvent()">
                <mat-option *ngFor="let fromAccount of fromAccountData" [value]="fromAccount.id">
                  {{ fromAccount.productName }} - {{ fromAccount.accountNo }}
                </mat-option>
              </mat-select>
              <mat-error *ngIf="makeAccountTransferForm.controls.fromAccountId.hasError('required')">
                {{"labels.inputs.account" | translate}} <strong>{{"labels.commons.is required" | translate}}</strong>
              </mat-error>
            </mat-form-field>

            <mat-divider [inset]="true"></mat-divider>
            <h3 class="mat-h3" fxFlexFill>{{"labels.transferringTo" | translate}}</h3>
            <mat-form-field  appearance="fill" fxFlex="98%">
              <mat-label>{{"labels.inputs.office" | translate}}</mat-label>
              <mat-select required formControlName="toOfficeId" (selectionChange)="changeEvent()">
                <mat-option *ngFor="let toOfficeType of toOfficeTypeData" [value]="toOfficeType.id">
                  {{ toOfficeType.name }}
                </mat-option>
              </mat-select>
              <mat-error *ngIf="makeAccountTransferForm.controls.toOfficeId.hasError('required')">
                {{"labels.inputs.office" | translate}} <strong>{{"labels.commons.is required" | translate}}</strong>
              </mat-error>
            </mat-form-field>

            <mat-form-field  appearance="fill" fxFlex="98%">
              <mat-label>{{"labels.inputs.client" | translate}}</mat-label>
              <input matInput formControlName="toClientId" [matAutocomplete]="clientsAutocomplete">
              <mat-error *ngIf="makeAccountTransferForm.controls.toClientId.hasError('required')">
                {{"labels.inputs.client" | translate}} <strong>{{"labels.commons.is required" | translate}}</strong>
              </mat-error>
            </mat-form-field>

            <mat-autocomplete autoActiveFirstOption #clientsAutocomplete="matAutocomplete" [displayWith]="displayClient">
              <mat-option *ngFor="let client of clientsData" [value]="client">
                {{ client.id }} - {{ client.displayName }}
              </mat-option>
            </mat-autocomplete>

            <mat-form-field  appearance="fill" fxFlex="98%">
              <mat-label>{{"labels.inputs.accounttype" | translate}}</mat-label>
              <mat-select required formControlName="toAccountType" (selectionChange)="changeEvent()">
                <mat-option *ngFor="let toAccountType of toAccountTypeData" [value]="toAccountType.id">
                  {{ toAccountType.value }}
                </mat-option>
              </mat-select>
              <mat-error *ngIf="makeAccountTransferForm.controls.toAccountType.hasError('required')">
                {{"labels.inputs.accounttype" | translate}} <strong>{{"labels.commons.is required" | translate}}</strong>
              </mat-error>
            </mat-form-field>

            <mat-form-field  appearance="fill" fxFlex="98%">
              <mat-label>{{"labels.heading.account" | translate}}</mat-label>
              <mat-select required formControlName="toAccountId" (selectionChange)="changeEvent()">
                <mat-option *ngFor="let toAccount of toAccountData" [value]="toAccount.id">
                  {{ toAccount.productName }} - {{ toAccount.accountNo }}
                </mat-option>
              </mat-select>
              <mat-error *ngIf="makeAccountTransferForm.controls.toAccountId.hasError('required')">
                {{"labels.heading.account" | translate}} <strong>{{"labels.commons.is required" | translate}}</strong>
              </mat-error>
            </mat-form-field>

            <mat-form-field  appearance="fill" fxFlex="98%">
              <mat-label>{{"labels.heading.amount" | translate}}</mat-label>
              <input decimalInput matInput required formControlName="transferAmount">
              <mat-error *ngIf="makeAccountTransferForm.controls.transferAmount.hasError('required')">
                {{"labels.heading.amount" | translate}} <strong>{{"labels.commons.is required" | translate}}</strong>
              </mat-error>
            </mat-form-field>

            <mat-form-field  appearance="fill" fxFlex="98%"  (click)="transferDatePicker.open()">
              <mat-label>{{"labels.inputs.transactiondate" | translate}}</mat-label>
              <input matInput [min]="minDate" [max]="maxDate" [matDatepicker]="transferDatePicker" required
                formControlName="transferDate">
              <mat-datepicker-toggle matSuffix [for]="transferDatePicker"></mat-datepicker-toggle>
              <mat-datepicker #transferDatePicker></mat-datepicker>
              <mat-error *ngIf="makeAccountTransferForm.controls.transferDate.hasError('required')">
                {{"labels.inputs.transactiondate" | translate}} <strong>{{"labels.commons.is required" | translate}}</strong>
              </mat-error>
            </mat-form-field>

            <mat-form-field  appearance="fill" fxFlex="98%">
              <mat-label>{{"labels.heading.description" | translate}}</mat-label>
              <textarea matInput formControlName="transferDescription"></textarea>
              <mat-error *ngIf="makeAccountTransferForm.controls.transferDescription.hasError('required')">
                {{"labels.inputs.transfer.description" | translate}} <strong>{{"labels.commons.is required" | translate}}</strong>
              </mat-error>

            </mat-form-field>

          </div>

        </form>

      </mat-card-content>

      <mat-card-actions fxLayout="row" fxLayout.xs="column" fxLayoutAlign="center" fxLayoutGap="5px">
        <button type="button" mat-raised-button [routerLink]="['../../clients/61']">{{"labels.buttons.cancel" | translate}}</button>
        <button mat-raised-button color="primary" [disabled]="!makeAccountTransferForm.valid" *mifosxHasPermission="'CREATE_ACCOUNTTRANSFER'"
          (click)="submit()">{{"labels.buttons.submit" | translate}}</button>
      </mat-card-actions>

    </mat-card>

  </div>
