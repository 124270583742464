<div fxLayout="row" fxLayoutAlign="end" class="container m-b-20">
  <button mat-raised-button color="primary" [routerLink]="['create']" *mifosxHasPermission="'CREATE_PRODUCTMIX'">
    <fa-icon icon="plus"></fa-icon> {{"labels.buttons.Add" | translate }} </button>
</div>

<div class="container">

   <div fxLayout="row" fxLayoutGap="20px">
    <mat-form-field fxFlex>
      <mat-label>{{"labels.buttons.filter" | translate}}</mat-label>
      <input matInput (keyup)="applyFilter($event.target.value)">
    </mat-form-field>
  </div>

  <div class="mat-elevation-z8">

    <table mat-table [dataSource]="dataSource" matSort>

      <ng-container matColumnDef="productName">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{"labels.inputs.productname" | translate}} </th>
        <td mat-cell *matCellDef="let productMix"> {{ productMix.productName }} </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="select-row" [routerLink]="[row.productId]"></tr>

    </table>

    <mat-paginator [pageSizeOptions]="[10, 25, 50, 100]" showFirstLastButtons></mat-paginator>

  </div>
</div>
