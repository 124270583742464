<div class="container">

  <mat-card>

    <form [formGroup]="taxComponentForm" (ngSubmit)="submit()">

      <mat-card-content>

        <div fxLayout="column">

          <mat-form-field>
            <mat-label>{{"labels.heading.name" | translate}}</mat-label>
            <input matInput required formControlName="name">
            <mat-error *ngIf="taxComponentForm.controls.name.hasError('required')">
            {{"labels.inputs.name" | translate}} <strong>{{"labels.commons.is required" | translate}}</strong>
            </mat-error>
          </mat-form-field>

          <mat-form-field>
            <mat-label> {{"labels.inputs.percentage" | translate}} </mat-label>
            <input matInput required formControlName="percentage">
            <mat-error *ngIf="taxComponentForm.controls.name.hasError('required')"> {{"labels.inputs.percentage" | translate}} <strong>{{"labels.commons.is required" | translate}}</strong>
            </mat-error>
          </mat-form-field>

          <mat-form-field *ngIf="taxComponentData.creditAccountType.value">
            <mat-label> {{"labels.inputs.creditaccounttype" | translate}} </mat-label>
            <input matInput formControlName="creditAccountType">
          </mat-form-field>

          <mat-form-field *ngIf="taxComponentData.creditAccount.name">
            <mat-label> {{"labels.inputs.creditaccount" | translate}} </mat-label>
            <input matInput formControlName="creditAccount">
          </mat-form-field>

          <mat-form-field (click)="startDatePicker.open()">
            <mat-label>{{"labels.inputs.startdate" | translate }}</mat-label>
            <input matInput [min]="minDate" [matDatepicker]="startDatePicker" required formControlName="startDate">
            <mat-datepicker-toggle matSuffix [for]="startDatePicker"></mat-datepicker-toggle>
            <mat-datepicker #startDatePicker></mat-datepicker>
            <mat-error *ngIf="taxComponentForm.controls.startDate.hasError('required')"> {{"labels.inputs.startdate" | translate }} <strong>{{"labels.commons.is required" | translate}}</strong>
            </mat-error>
          </mat-form-field>

        </div>

      </mat-card-content>

      <mat-card-actions fxLayout="row" fxLayout.xs="column" fxLayoutAlign="center" fxLayoutGap="5px">
        <button type="button" mat-raised-button [routerLink]="['../']">{{"labels.buttons.cancel" | translate}}</button>
        <button mat-raised-button color="primary" [disabled]="!taxComponentForm.valid" *mifosxHasPermission="'UPDATE_TAXCOMPONENT'">{{"labels.buttons.submit" | translate}}</button>
      </mat-card-actions>

    </form>

  </mat-card>

</div>
