<div class="container">

  <mat-card>

    <form [formGroup]="creditBureauForm" (ngSubmit)="submit()">

      <mat-card-content>

        <div fxLayout="column">
          <mat-form-field>
            <mat-label>{{ 'labels.heading.product' | translate }}</mat-label>
            <mat-select required formControlName="loanProductId">
              <mat-option *ngFor="let lp of loanProducts" [value]="lp.loanProductId">
                {{ lp.loanProductName }}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="creditBureauForm.controls.loanProductId.hasError('required')">
              {{ 'labels.productLines' | translate }} <strong>{{ 'labels.commons.is required' | translate }}</strong>
            </mat-error>
          </mat-form-field>

          <mat-form-field>
            <mat-label>{{ 'labels.creditbureau' | translate }}</mat-label>
            <mat-select required formControlName="organisationCreditBureauId">
              <mat-option *ngFor="let cb of creditBureauSummary" [value]="cb.organisationCreditBureauId">
                {{ cb.alias }}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="creditBureauForm.controls.organisationCreditBureauId.hasError('required')">
              {{ 'labels.creditbureau' | translate }} <strong>{{ 'labels.commons.is required' | translate }}</strong>
            </mat-error>
          </mat-form-field>

          <mat-checkbox class="is-active" fxFlex="10%" labelPosition="after" formControlName="isCreditcheckMandatory">
            {{"labels.inputs.isCreditCheckMandatory" | translate}}
          </mat-checkbox>

          <mat-checkbox class="is-active" fxFlex="10%" labelPosition="after" formControlName="skipCreditcheckInFailure">
            {{"labels.inputs.skipcreditcheckinfailure" | translate}}
          </mat-checkbox>

          <mat-form-field>
            <mat-label>{{ 'labels.inputs.stale' | translate }}</mat-label>
            <input matInput required formControlName="stalePeriod" type="number">
              <mat-error *ngIf="creditBureauForm.controls.stalePeriod.hasError('required')">
                {{"labels.inputs.alias" | translate}} <strong>{{"labels.commons.is required" | translate}}</strong>
              </mat-error>
          </mat-form-field>
        </div>

      </mat-card-content>

      <mat-card-actions fxLayoutGap="5px" fxLayout="row" fsLayout.xs="column" fxLayoutAlign="center">
        <button type="button" mat-raised-button [routerLink]="['../']">{{ 'labels.buttons.cancel' | translate
          }}</button>
        <button mat-raised-button color="primary" [disabled]="!creditBureauForm.valid">{{ 'labels.buttons.submit' |
          translate }}</button>
      </mat-card-actions>

    </form>

  </mat-card>

</div>
