<div class="tab-container mat-typography">
  <div class="export">
    <button mat-raised-button color="success" (click)="advencedSearch()">
      <fa-icon icon="search"></fa-icon>&nbsp;&nbsp;{{"labels.advencedSearch" | translate }}
    </button>
    <button mat-raised-button color="success" (click)="exportexcel()">
      <fa-icon icon="file-excel"></fa-icon>&nbsp;&nbsp;{{'labels.buttons.exportexcel' | translate}}
    </button>
  </div>
  <div *ngIf="showSearch" class="search">
    <div fxLayout="row" fxLayoutAlign="start center">
      <div class="search-box" fxFlex="18%">
        <mat-form-field appearance="fill" fxFlex="100%">
          <input matInput [formControl]="fromPrincipalFilter" type="number" min="0" (keyup)="applyFilters()"
            [placeholder]="'labels.fromAmount'| translate">
        </mat-form-field>
      </div>
      <div class="search-box" fxFlex="18%">
        <mat-form-field appearance="fill" fxFlex="100%">
          <input matInput [formControl]="toPrincipalFilter" type="number" [min]="fromPrincipalFilter.value"
            (keyup)="applyFilters()" [placeholder]="'labels.toAmount'| translate">
        </mat-form-field>
      </div>
    </div>

    <div fxLayout="row" fxLayoutAlign="start center">
      <div class="search-box" fxFlex="18%">
        <mat-form-field fxFlex="100%" appearance="fill">
          <input matInput [max]="endDateFilter.value" [matDatepicker]="submitPicker" [formControl]="startDateFilter"
            (dateChange)="applyFilters()" (keyup)="applyFilters()" [placeholder]="'labels.fromNextDueDate'| translate">
          <mat-datepicker-toggle matSuffix [for]="submitPicker"></mat-datepicker-toggle>
          <mat-datepicker #submitPicker></mat-datepicker>
        </mat-form-field>
      </div>
      <div class="search-box" fxFlex="18%">
        <mat-form-field fxFlex="100%" appearance="fill">
          <input matInput [min]="startDateFilter.value" [matDatepicker]="submitPicker2" [formControl]="endDateFilter"
            (dateChange)="applyFilters()" (keyup)="applyFilters()" [placeholder]="'labels.toNextDueDate'| translate">
          <mat-datepicker-toggle matSuffix [for]="submitPicker2"><button>{{"labels.buttons.clear" | translate}}</button></mat-datepicker-toggle>
          <mat-datepicker #submitPicker2><button>{{"labels.buttons.clear" | translate}}</button></mat-datepicker>
        </mat-form-field>
      </div>
    </div>
  </div>
  <table mat-table [dataSource]="dataSource">

    <ng-container matColumnDef="status">
      <th mat-header-cell *matHeaderCellDef> {{"labels.menus.status" | translate}}


        <mat-form-field appearance="fill">
          <mat-select disabled [formControl]="statusFilter" (selectionChange)="applyFilters()" [placeholder]="'labels.buttons.search'| translate">
            <mat-option value="">
              {{"labels.menus.All" | translate}}
            </mat-option>
            <mat-option *ngFor="let code of status" [value]="code">
              {{ code | translate}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </th>
      <td mat-cell *matCellDef="let loan" class="view-details">
        <div [ngClass]="loan.inArrears?'status-active-overdue':(loan.status.code|statusLookup)" [matTooltip]="loan.status.code | translate">
          <fa-icon icon="stop"></fa-icon>
        </div>
      </td>

    </ng-container>

    <ng-container matColumnDef="loanAccount">
      <th mat-header-cell *matHeaderCellDef> {{"labels.heading.account" | translate}}
        <mat-form-field appearance="fill">
          <input matInput [formControl]="loanAccountFilter" (keyup)="applyFilters()" [placeholder]="'labels.buttons.search'| translate">
        </mat-form-field>
      </th>
      <td mat-cell *matCellDef="let loan" class="view-details"
        [routerLink]="['/clients', loan.clientId, 'loans-accounts', loan.id]">
        {{loan.accountNo}}
      </td>
    </ng-container>

    <ng-container matColumnDef="clientName">
      <th mat-header-cell *matHeaderCellDef> {{"labels.heading.client" | translate}}

        <mat-form-field appearance="fill">
          <input matInput [formControl]="clientFilter" (keyup)="applyFilters()" [placeholder]="'labels.buttons.search'| translate">
        </mat-form-field>
      </th>
      <td mat-cell *matCellDef="let loan" class="view-details" [routerLink]="['/clients', loan.clientId]">
        {{ loan.clientName }}
      </td>

    </ng-container>


    <ng-container matColumnDef="loanProduct">
      <th mat-header-cell *matHeaderCellDef> {{"labels.heading.product" | translate}}

        <mat-form-field appearance="fill">
          <mat-select [formControl]="productFilter" (selectionChange)="applyFilters()" [placeholder]="'labels.buttons.search'| translate">
            <mat-option value="">
              {{"labels.heading.all" | translate}}
            </mat-option>
            <mat-option *ngFor="let product of products" [value]="product">
              {{ product }}
            </mat-option>
          </mat-select>
        </mat-form-field>

      </th>
      <td mat-cell *matCellDef="let loan" class="view-details"
        [routerLink]="['/clients', loan.clientId, 'loans-accounts', loan.id]">
        {{ loan.loanProductName}}
      </td>
    </ng-container>

    <ng-container matColumnDef="amount">
      <th mat-header-cell *matHeaderCellDef> {{"labels.initialAmount" | translate}}

        <mat-form-field appearance="fill">
          <input matInput [formControl]="principalFilter" type="number" min="0" (keyup)="applyFilters()"
            [placeholder]="'labels.buttons.search'| translate">
        </mat-form-field>

      </th>
      <td mat-cell *matCellDef="let loan"> {{ loan.principal | number}} </td>
    </ng-container>

    <ng-container matColumnDef="principalOutstanding">
      <th mat-header-cell *matHeaderCellDef> {{"labels.heading.outstandingloan" | translate}}
        <mat-form-field appearance="fill">
          <input matInput [formControl]="outstandingFilter" type="number" min="0" (keyup)="applyFilters()"
            [placeholder]="'labels.buttons.search'| translate">
        </mat-form-field>
      </th>
      <td mat-cell *matCellDef="let loan"> {{ loan?.summary?.principalOutstanding }} </td>
    </ng-container>

    <ng-container matColumnDef="paymentNumber">
      <th mat-header-cell *matHeaderCellDef> {{"labels.duePaid" | translate}}
        <mat-form-field appearance="fill">
          <input matInput [formControl]="paymentNumberFilter" type="number" min="0" (keyup)="applyFilters()"
            [placeholder]="'labels.buttons.search'| translate">
        </mat-form-field>
      </th>
      <td mat-cell *matCellDef="let loan"> {{ loan.paymentNumber }} </td>
    </ng-container>

    <ng-container matColumnDef="nextPaymentDate">
      <th mat-header-cell *matHeaderCellDef> {{"labels.nextDue" | translate}}
        <mat-form-field appearance="fill">
          <input matInput [matDatepicker]="submitPicker" [formControl]="nextPaymentDateFilter" [placeholder]="'labels.buttons.search'| translate"
            (dateChange)="applyFilters()" (keyup)="applyFilters()">
          <mat-datepicker-toggle matSuffix [for]="submitPicker"></mat-datepicker-toggle>
          <mat-datepicker #submitPicker></mat-datepicker>
        </mat-form-field>
      </th>
      <td mat-cell *matCellDef="let loan" class="dateCell"> {{ loan?.nextPaymentDetails?.nextPaymentDate | dateFormat }}
      </td>
    </ng-container>

    <ng-container matColumnDef="nextPaymentDetailsAmount">
      <th mat-header-cell *matHeaderCellDef> {{"labels.dueAmount" | translate}}
        <mat-form-field appearance="fill">
          <input matInput [formControl]="nextPaymentAmountFilter" type="number" min="0" (keyup)="applyFilters()"
            [placeholder]="'labels.buttons.search'| translate">
        </mat-form-field>
      </th>
      <td mat-cell *matCellDef="let loan"> {{ loan.nextPaymentDetails.amount }} </td>
    </ng-container>
    <ng-container matColumnDef="nextPaymentDetailsReference">
      <th mat-header-cell *matHeaderCellDef> {{"labels.dueReference" | translate}}
        <mat-form-field appearance="fill">
          <input matInput [formControl]="nextPaymentReferenceFilter" (keyup)="applyFilters()"
            [placeholder]="'labels.buttons.search'| translate">
        </mat-form-field>
      </th>
      <td mat-cell *matCellDef="let loan"> {{ loan.nextPaymentDetails.id  }} </td>
    </ng-container>

    <ng-container matColumnDef="totalOverdue">
      <th mat-header-cell *matHeaderCellDef> {{"labels.amountUnpaid" | translate}}
        <mat-form-field appearance="fill">
          <input matInput [formControl]="totalOverdueFilter" type="number" min="0" (keyup)="applyFilters()"
            [placeholder]="'labels.buttons.search'| translate">
        </mat-form-field>
      </th>
      <td mat-cell *matCellDef="let loan"> {{ loan?.summary?.totalOverdue }} </td>
    </ng-container>

    <ng-container matColumnDef="loanAgency">
      <th mat-header-cell *matHeaderCellDef> {{"labels.agency" | translate}}
        <mat-form-field appearance="fill">
          <mat-select [formControl]="agencyFilter" (selectionChange)="applyFilters()" [placeholder]="'labels.buttons.search'| translate">
            <mat-option value="">
              {{"labels.menus.All" | translate}}
            </mat-option>
            <mat-option *ngFor="let agency of agencies" [value]="agency">
              {{ agency }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </th>
      <td mat-cell *matCellDef="let loan"> {{ loan.agency }} </td>
    </ng-container>


    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="selection.toggle(row)">
    </tr>
  </table>
  <mat-paginator [pageSizeOptions]="[10, 20, 30]" showFirstLastButtons aria-label="Select page of periodic elements">
  </mat-paginator>
</div>
