<button 
  mat-icon-button class="ml-1" [matTooltip]="'labels.menus.Notifications'| translate"
  [matMenuTriggerFor]="notificationsMenu" (menuClosed)="menuClosed()"
  [matBadge]="unreadNotifications.length" [matBadgeHidden]="!unreadNotifications.length" matBadgeColor="warn"  matBadgeSize="medium"
>
  <fa-icon icon="bell" size="lg"></fa-icon>
</button>

<mat-menu class="mifosx-notifications-menu" #notificationsMenu="matMenu" [overlapTrigger]="false">

  <button mat-menu-item class="unread" *ngFor="let notification of unreadNotifications" [routerLink]="[routeMap[notification.objectType], notification.objectId]">
    {{ notification.content }}*<br> 
    <span class="time">[{{ notification.createdAt }}]</span>
  </button>

  <button mat-menu-item *ngFor="let notification of displayedReadNotifications" [routerLink]="[routeMap[notification.objectType], notification.objectId]">
    {{ notification.content }}<br> 
    <span class="time">[{{ notification.createdAt }}]</span>
  </button>

</mat-menu>
