<h1 mat-dialog-title>{{ title }}</h1>

<div mat-dialog-content [formGroup]="depositProductIncentiveForm" fxLayout="column">

  <mat-form-field>
    <mat-label>{{"labels.attribute" | translate}}</mat-label>
    <mat-select formControlName="attributeName" required>
      <mat-option *ngFor="let attributeName of attributeNameData" [value]="attributeName.id">
        {{ attributeName.value }}
      </mat-option>
    </mat-select>
    <mat-error>
      {{"labels.attribute" | translate}} <strong>{{"labels.commons.is required" | translate}}</strong>
    </mat-error>
  </mat-form-field>

  <mat-form-field>
    <mat-label>{{"labels.condition" | translate}}</mat-label>
    <mat-select formControlName="conditionType" required>
      <mat-option *ngFor="let conditionType of conditionTypeData" [value]="conditionType.id">
        {{ conditionType.value }}
      </mat-option>
    </mat-select>
    <mat-error>
      {{"labels.condition" | translate}} <strong>{{"labels.commons.is required" | translate}}</strong>
    </mat-error>
  </mat-form-field>

  <mat-form-field *ngIf="depositProductIncentiveForm.value.attributeName">
    <mat-label>{{"labels.inputs.value" | translate}}</mat-label>
    <mat-select formControlName="attributeValue" *ngIf="depositProductIncentiveForm.value.attributeName !== 3" required>
      <mat-option *ngFor="let attributeValue of attributeValueData" [value]="attributeValue.id">
        {{ attributeValue.name }}
      </mat-option>
    </mat-select>
    <input type="number" matInput formControlName="attributeValue" *ngIf="depositProductIncentiveForm.value.attributeName === 3" required>
    <mat-error>
      {{"labels.inputs.value" | translate}} <strong>{{"labels.commons.is required" | translate}}</strong>
    </mat-error>
  </mat-form-field>

  <mat-form-field>
    <mat-label>{{"labels.inputs.type" | translate}}</mat-label>
    <mat-select formControlName="incentiveType" required>
      <mat-option *ngFor="let incentiveType of incentiveTypeData" [value]="incentiveType.id">
        {{ incentiveType.value }}
      </mat-option>
    </mat-select>
    <mat-error>
      {{"labels.inputs.type" | translate}} <strong>{{"labels.commons.is required" | translate}}</strong>
    </mat-error>
  </mat-form-field>

  <mat-form-field>
    <mat-label>{{"labels.heading.interest" | translate}}</mat-label>
    <input matInput formControlName="amount" decimalInput required>
    <mat-error>
      {{"labels.heading.interest" | translate}} <strong>{{"labels.commons.is required" | translate}}</strong>
    </mat-error>
  </mat-form-field>

</div>

<mat-dialog-actions fxLayout="row" fxLayout.xs="column" fxLayoutAlign="center" fxLayoutGap="2%">
  <button mat-raised-button mat-dialog-close>{{"labels.buttons.cancel" | translate}}</button>
  <button mat-raised-button color="primary" [mat-dialog-close]="{ data: depositProductIncentiveForm }" [disabled]="!depositProductIncentiveForm.valid || depositProductIncentiveForm.pristine">{{ layout.addButtonText }}</button>
</mat-dialog-actions>
