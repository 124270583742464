<!-- Application Shell -->
<mat-sidenav-container id="mifosx-shell-container">


    <!-- Toolbar -->
    <mifosx-toolbar  (collapse)="toggleCollapse($event)"></mifosx-toolbar>
    <!-- Progress Bar -->
    <mat-progress-bar [mode]="progressBarMode"></mat-progress-bar>
    <!-- <div class="container"> -->
    <mifosx-sidenav></mifosx-sidenav>
    <!-- Breadcrumb -->
    <div class="content">
    <mifosx-breadcrumb></mifosx-breadcrumb>
    <!-- Content -->
    <mifosx-content></mifosx-content>
    <!-- Footer -->
    <mifosx-footer></mifosx-footer>
    </div>


</mat-sidenav-container>
