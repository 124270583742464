import { TranslateService } from '@ngx-translate/core';
import { extract } from 'app/core/i18n/i18n.service';
import { DomSanitizer } from '@angular/platform-browser';
import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { AlertService } from './../../core/alert/alert.service';
import { TenantService } from './../../core/tenant/tenant.service';
import { SettingsService } from './../../settings/settings.service';
import { UploadLogoDialogComponent } from './upload-logo-dialog/upload-logo-dialog.component';

@Component({
  selector: 'mifosx-manage-tenant',
  templateUrl: './manage-tenant.component.html',
  styleUrls: ['./manage-tenant.component.scss']
})
export class ManageTenantComponent implements OnInit {
  metabses: any[];
  currentTenant: any;
  tenantLogo: any;


  constructor(private route: ActivatedRoute,
    private tenantService: TenantService,
    private settingsService: SettingsService,
    public dialog: MatDialog,
    private _sanitizer: DomSanitizer,
    private alertService: AlertService,
    private translateService: TranslateService) {
    this.route.data.subscribe((data: { metabaseDBs: any }) => {
      this.metabses = data.metabaseDBs.data;
    });
    this.settingsService.currentTenantData.subscribe((tenant: any) => {
      this.currentTenant = tenant;
      this.tenantLogo = this._sanitizer.bypassSecurityTrustResourceUrl(tenant.logo);
      this.organizationName = new FormControl(this.currentTenant?.appName);
      this.metabaseid = new FormControl(this.currentTenant?.metabaseId);


    })
  }

  ngOnInit(): void {
  }
  organizationName = new FormControl();
  metabaseid = new FormControl();




  onSubmit() {
    var formData = {
      appName:  this.organizationName.value,
      metabaseId: this.metabaseid.value
    }

    this.tenantService.updateTenant(formData, this.currentTenant.tenantName).subscribe((data: any) => {
      console.log("sucess")
      this.alertService.alert({ type: 'Success', message: this.translateService.instant("labels.successfullyUpdated") });
      this.settingsService.updateTenantData({
        ...this.currentTenant,
        ...data.changes
      });
    });

  }

  uploadLogo() {
    const uploadImageDialogRef = this.dialog.open(UploadLogoDialogComponent);
    uploadImageDialogRef.afterClosed().subscribe((image: File) => {
      if (image) {
        console.log(image);
        this.tenantService.uploadTenantLogo(this.currentTenant.id, image)
          .subscribe((data: any) => {
            this.tenantService.getTenants().subscribe((data: any) => {
              const tenant = data.filter((tenant: any) => tenant.id === this.currentTenant.id)[0];
              if (tenant) {
                this.settingsService.updateTenantData(tenant);
                this.alertService.alert({ type: 'Success', message: this.translateService.instant("labels.successfullyUpdated") });
                document.getElementById("iconLogo").setAttribute('href', tenant.logo);
              }
            })
          });
      }
    });
  }

}
