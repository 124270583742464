import { ActivatedRoute } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { ClientsService } from 'app/clients/clients.service';

@Component({
  selector: 'mifosx-web-view-active-loans',
  templateUrl: './web-view-active-loans.component.html',
  styleUrls: ['./web-view-active-loans.component.scss']
})
export class WebViewActiveLoansComponent implements OnInit {

  openLoansColumns: string[] = ['Account No', 'Original Loan', 'Amount Paid', 'Actions'];

  loans: any;
  clientId: string;

  constructor(private clientsService: ClientsService,
    private route: ActivatedRoute,) {
    this.clientId = this.route.parent.snapshot.params['clientId'];

  }

  ngOnInit(): void {
    this.clientsService.getClientAccountData(this.clientId).subscribe((data: any) => {
      if (data?.loanAccounts) {
        this.loans = data?.loanAccounts?.filter((loan: any) => loan.status.value === "Active");
      }
    });
  }

}
