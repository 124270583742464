import { DomSanitizer } from '@angular/platform-browser';
import { SettingsService } from 'app/settings/settings.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'mifosx-error-page',
  templateUrl: './error-page.component.html',
  styleUrls: ['./error-page.component.scss']
})
export class ErrorPageComponent implements OnInit {
  logoSrc:any;

  constructor(private _sanitizer: DomSanitizer,
    private settingsService: SettingsService) {
    this.settingsService.currentTenantData.subscribe(tenant=>{
      if(tenant){
        this.logoSrc =  this._sanitizer.bypassSecurityTrustResourceUrl(tenant.logo);
      document.getElementById("iconLogo").setAttribute('href', tenant.logo);
      }
    })
}

  ngOnInit(): void {
  }

}
