<div class="search-bar">

    <!-- <mat-label>{{"labels.buttons.search" | translate}}</mat-label> -->
    <input [placeholder]="'labels.searchorpress' | translate" type="text" [formControl]="query" (keydown.enter)="search()">

  <!-- <mat-form-field class="resource" appearance="fill"> -->
    <!-- <mat-label>{{"labels.ressource" | translate}}</mat-label> -->
    <!-- <select [formControl]="resource">
      <option *ngFor="let option of resourceOptions" [value]="option.value">
        <span class="option-item">{{ option.name }}</span>
      </option>
    </select> -->
    <div class="dropdown">
      <button #toggleButton (click)="toggleBtn(true)" class="dropbtn">
        <!-- <img src="assets/images/user_placeholder.png"> -->
        {{option.name}} <div class="cert"></div>
      </button>
      <div #menu class="dropdown-content" *ngIf="visibilty">
        <a *ngFor="let option of resourceOptions" (click)="setOption(option)">
          {{ option.name }}
        </a>
        </div>
    </div>




  <!-- </mat-form-field> -->

</div>

<!-- <div class="search-icon">
  <button mat-icon-button (click)="toggleSearchVisibility()" fxHide.lt-sm="true">
    <fa-icon icon="search" size="lg" [matTooltip]="'labels.buttons.search'| translate"></fa-icon>
  </button>
</div> -->
