<div class="container">

  <mat-card>

    <form [formGroup]="rateForm" (ngSubmit)="submit()">

      <mat-card-content>

        <div fxLayout="column">

          <mat-form-field class="read-only">
            <mat-label>{{ 'labels.rateappliesto' | translate }}</mat-label>
            <input matInput required formControlName="productApply" readonly>
            <mat-error *ngIf="rateForm.controls.productApply.hasError('required')">
              {{"labels.rateappliesto" | translate}} <strong>{{"labels.commons.is required" | translate}}</strong>
            </mat-error>
          </mat-form-field>

            <mat-form-field class="read-only">
              <mat-label>{{ 'labels.heading.name' | translate }}</mat-label>
              <input matInput required formControlName="name" readonly>
              <mat-error *ngIf="rateForm.controls.name.hasError('required')">
                {{"labels.heading.name" | translate}} <strong>{{"labels.commons.is required" | translate}}</strong>
              </mat-error>
            </mat-form-field>

            <mat-form-field>
              <mat-label>{{ 'labels.heading.percentage' | translate }}</mat-label>
              <input matInput type="number" min="0" max="100" required formControlName="percentage">
              <mat-error *ngIf="rateForm.controls.percentage.hasError('required')">
                {{"labels.heading.percentage" | translate}} <strong>{{"labels.commons.is required" |
                  translate}}</strong>
              </mat-error>
            </mat-form-field>
            <mat-checkbox labelPosition="after" formControlName="active">
              {{"labels.heading.active" | translate}}
            </mat-checkbox>



        </div>

      </mat-card-content>

      <mat-card-actions fxLayoutGap="5px" fxLayout="row" fsLayout.xs="column" fxLayoutAlign="center">
        <button type="button" mat-raised-button [routerLink]="['../']">{{ 'labels.buttons.cancel' | translate
          }}</button>
        <button mat-raised-button color="primary" [disabled]="!rateForm.valid">{{ 'labels.buttons.submit' |
          translate }}</button>
      </mat-card-actions>

    </form>

  </mat-card>

</div>
