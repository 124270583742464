import { AuthenticationService } from 'app/core/authentication/authentication.service';
/** Angular Imports */
import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';

/** rxjs Imports */
import { Observable } from 'rxjs';

/** Custom Services */

/**
 * Loan accounts template data resolver.
 */
@Injectable()
export class LoansAccountTemplateResolver implements Resolve<Object> {
    /**
     * @param {ProductsService} productsService Products service.
     */
    constructor( private authenticationService: AuthenticationService) { }

    /**
     * Returns the loan account template data.
     * @returns {Observable<any>}
     */
    resolve(route: ActivatedRouteSnapshot): Observable<any> {
        const token = route.queryParams.token;
        const clientId = route.params.clientId;
        return this.authenticationService.getLoansAccountTemplateResource(token,clientId, false);
    }
}
