<div class="container">

  <mat-accordion>

    <mat-expansion-panel>

      <mat-expansion-panel-header>
        <mat-panel-title>
          {{"labels.mainConfiguration" | translate}}
        </mat-panel-title>
      </mat-expansion-panel-header>

      <div fxLayout="column">

        <mat-form-field>
          <mat-label>{{"labels.nameOrganization" | translate}}</mat-label>
          <input matInput>
        </mat-form-field>

        <mat-form-field>
          <mat-label>{{"labels.inputs.description" | translate}}</mat-label>
          <textarea matInput></textarea>
        </mat-form-field>

        <mat-form-field>
          <mat-label>{{"labels.heading.summary" | translate}}</mat-label>
          <textarea matInput></textarea>
        </mat-form-field>

        <mat-form-field>
          <mat-label>{{"labels.defaultLanguage" | translate}}</mat-label>
          <mat-select>
            <mat-option *ngFor="let language of languages" [value]="language">
              {{ language }}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field>
          <mat-label>{{"labels.defaultDateFormat" | translate}}</mat-label>
          <mat-select>
            <mat-option *ngFor="let dateFormat of dateFormats" [value]="dateFormat">
              {{ dateFormat }}
            </mat-option>
          </mat-select>
        </mat-form-field>

      </div>

    </mat-expansion-panel>

    <mat-expansion-panel>

      <mat-expansion-panel-header>
        <mat-panel-title>
          {{"labels.images" | translate}}
        </mat-panel-title>
      </mat-expansion-panel-header>

      <div fxLayout="column">

        <span class="header">{{"labels.favicon" | translate}}</span>
        <mifosx-file-upload></mifosx-file-upload>

        <span class="header">{{"labels.coverImage" | translate}}</span>
        <mifosx-file-upload></mifosx-file-upload>

        <span class="header">{{"labels.logo" | translate}}</span>
        <mifosx-file-upload></mifosx-file-upload>

        <span class="header">{{"labels.logoWithOrganizationName" | translate}}</span>
        <mifosx-file-upload></mifosx-file-upload>

      </div>

    </mat-expansion-panel>

    <mat-expansion-panel>

      <mat-expansion-panel-header>
        <mat-panel-title>
          {{"labels.themeAndFont" | translate}}
        </mat-panel-title>
      </mat-expansion-panel-header>

      <div fxLayout="column">

        <span class="header">{{"labels.mobileBankingApp" | translate}}</span>
        <mat-form-field>
          <mat-label>{{"labels.defaultTheme" | translate}}</mat-label>
          <mat-select>
            <mat-option *ngFor="let theme of mobileAppThemes" [value]="theme">
              {{ theme }}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field>
          <mat-label>{{"labels.defaultFont" | translate}}</mat-label>
          <mat-select>
            <mat-option *ngFor="let font of mobileAppFonts" [value]="font">
              {{ font }}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <span class="header">{{"labels.onlineBankingApp" | translate}}</span>
        <mat-form-field>
          <mat-label>{{"labels.defaultTheme" | translate}}</mat-label>
          <mat-select>
            <mat-option *ngFor="let theme of onlineBankingAppThemes" [value]="theme">
              {{ theme }}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field>
          <mat-label>{{"labels.defaultFont" | translate}}</mat-label>
          <mat-select>
            <mat-option *ngFor="let font of onlineBankingAppFonts" [value]="font">
              {{ font }}
            </mat-option>
          </mat-select>
        </mat-form-field>

      </div>

    </mat-expansion-panel>

    <mat-expansion-panel>

      <mat-expansion-panel-header>
        <mat-panel-title>
          {{"labels.contactInformation" | translate}}
        </mat-panel-title>
      </mat-expansion-panel-header>

      <div fxLayout="column">

        <mat-form-field>
          <mat-label>{{"labels.website" | translate}}</mat-label>
          <input type="url" matInput>
        </mat-form-field>

        <mat-form-field>
          <mat-label>{{"labels.inputs.email" | translate}}</mat-label>
          <input type="email" matInput>
        </mat-form-field>

        <mat-form-field>
          <mat-label>{{"labels.contactNo" | translate}}</mat-label>
          <input type="tel" matInput>
        </mat-form-field>

      </div>

    </mat-expansion-panel>

  </mat-accordion>

</div>
