import { PipesModule } from 'app/pipes/pipes.module';
import { LoansAccountPreviewStepComponent } from './web-views/web-view-create-loan/loans-account-stepper/loans-account-preview-step/loans-account-preview-step.component';
import { WebViewCreateLoanComponent } from './web-views/web-view-create-loan/web-view-create-loan.component';
import { LoansAccountDetailsStepComponent } from './web-views/web-view-create-loan/loans-account-stepper/loans-account-details-step/loans-account-details-step.component';
/** Angular Imports */
import { NgModule } from '@angular/core';

/** Custom Modules */
import { SharedModule } from '../shared/shared.module';
import { LoginRoutingModule } from './login-routing.module';

/** Custom Components */
import { LoginComponent } from './login.component';
import { LoginFormComponent } from './login-form/login-form.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { TwoFactorAuthenticationComponent } from './two-factor-authentication/two-factor-authentication.component';
import { TranslateModule } from '@ngx-translate/core';
import { ErrorPageComponent } from './web-views/error-page/error-page.component';
import { WebViewDashboardLoanComponent } from './web-views/web-view-dashboard-loan/web-view-dashboard-loan.component';
import { WebViewMakeRepaymentComponent } from './web-views/web-view-make-repayment/web-view-make-repayment.component';
import { WebViewLoadingComponent } from './web-views/web-view-loading/web-view-loading.component';
import { WebViewsLoanDetailsComponent } from './web-views/web-views-loan-details/web-views-loan-details.component';
import { WebViewsLoanSummaryComponent } from './web-views/web-views-loan-summary/web-views-loan-summary.component';
import { WebViewsLoanChargesComponent } from './web-views/web-views-loan-charges/web-views-loan-charges.component';
import { WebViewsLoanRepaymentScheduleComponent } from './web-views/web-views-loan-repayment-schedule/web-views-loan-repayment-schedule.component';
import { WebViewsLoanTransactionsComponent } from './web-views/web-views-loan-transactions/web-views-loan-transactions.component';
import { WebViewsTopBarComponent } from './web-views/web-views-top-bar/web-views-top-bar.component';
import { DirectivesModule } from 'app/directives/directives.module';
import { WebViewProductsComponent } from './web-views/web-view-dashboard-loan/web-view-products/web-view-products.component';
import { CoreModule } from 'app/core/core.module';
import { WebViewActiveLoansComponent } from './web-views/web-view-dashboard-loan/web-view-active-loans/web-view-active-loans.component';
import { WebViewHistoryComponent } from './web-views/web-view-dashboard-loan/web-view-history/web-view-history.component';
import { CardComponent } from './web-views/card/card.component';
import { WebViewsLogoComponent } from './web-views/web-views-logo/web-views-logo.component';

/**
 * Login Module
 *
 * All components related to user authentication should be declared here.
 */
@NgModule({
  imports: [
    SharedModule,
    LoginRoutingModule,
    TranslateModule.forRoot(),
    PipesModule,
    DirectivesModule,
    CoreModule
  ],
  declarations: [
    LoginComponent,
    LoginFormComponent,
    ResetPasswordComponent,
    TwoFactorAuthenticationComponent,
    WebViewCreateLoanComponent,
    LoansAccountDetailsStepComponent,
    LoansAccountPreviewStepComponent,
    ErrorPageComponent,
    WebViewDashboardLoanComponent,
    WebViewMakeRepaymentComponent,
    WebViewLoadingComponent,
    WebViewsLoanDetailsComponent,
    WebViewsLoanSummaryComponent,
    WebViewsLoanChargesComponent,
    WebViewsLoanRepaymentScheduleComponent,
    WebViewsLoanTransactionsComponent,
    WebViewsTopBarComponent,
    WebViewProductsComponent,
    WebViewActiveLoansComponent,
    WebViewHistoryComponent,
    CardComponent,
    WebViewsLogoComponent,
  ]
})
export class LoginModule { }
