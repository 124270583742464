<div class="tab-container mat-typography" *ngIf="showData">

  <div fxLayout="row" fxLayoutAlign="start center">
    <div class="search-box" fxFlex="40%">
      <mat-form-field fxFlex="90%">
        <input matInput [placeholder]="'labels.inputs.filterbyname' | translate" 
        [formControl]="clientNameFilter" (keyup)="applyFilter()">
      </mat-form-field>
    </div>
    <div fxFlex="60%">
      <button mat-raised-button color="success" *mifosxHasPermission="'APPROVE_LOAN'" (click)="approveLoan()">
        <fa-icon icon="check"></fa-icon>&nbsp;&nbsp;{{'labels.buttons.approve'| translate}}
      </button>
    </div>
  </div>

  <ng-container *ngFor="let office of filteredOffice">

    <h3 class="mat-h3" *ngIf="office.loans.length > 0" >{{ office.name }}</h3>

    <ng-container *ngIf="office.loans.length > 0">

      <table mat-table [dataSource]="office.loans">

        <ng-container matColumnDef="select">
          <th mat-header-cell *matHeaderCellDef>
            <mat-checkbox (change)="$event ? masterToggle(office.loans) : null"
              [checked]="selection.hasValue() && isAllSelected(office.loans)"
              [indeterminate]="selection.hasValue() && !isAllSelected(office.loans)" [aria-label]="checkboxLabel(office.loans)">
            </mat-checkbox>
          </th>
          <td mat-cell *matCellDef="let row">
            <mat-checkbox (click)="$event.stopPropagation()" (change)="$event ? selection.toggle(row) : null"
              [checked]="selection.isSelected(row)" [aria-label]="checkboxLabel(row)">
            </mat-checkbox>
          </td>
        </ng-container>

        <ng-container matColumnDef="clientName">
          <th mat-header-cell *matHeaderCellDef> {{'labels.heading.client'| translate}} </th>
          <td mat-cell *matCellDef="let loan" class="view-details" [routerLink]="['../../clients', loan.clientId, 'general']">
            {{ loan.clientName }} </td>
        </ng-container>

        <ng-container matColumnDef="loan">
          <th mat-header-cell *matHeaderCellDef> {{'labels.heading.loan'| translate}} </th>
          <td mat-cell *matCellDef="let loan" class="view-details" [routerLink]="['../../clients', loan.clientId, 'loans-accounts', loan.id]">
            {{ loan.loanProductName}}({{loan.accountNo}}) </td>
        </ng-container>

        <ng-container matColumnDef="amount">
          <th mat-header-cell *matHeaderCellDef> {{'labels.heading.amount'| translate}} </th>
          <td mat-cell *matCellDef="let loan"> {{ loan.principal | number}} </td>
        </ng-container>

        <ng-container matColumnDef="loanPurpose">
          <th mat-header-cell *matHeaderCellDef> {{'labels.inputs.loanpurpose'| translate}} </th>
          <td mat-cell *matCellDef="let loan"> {{ loan.loanPurposeName }} </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="selection.toggle(row)">
        </tr>
      </table>

    </ng-container>

  </ng-container>

</div>

<div class="alert" *ngIf="!(showData)">

  <div class="message">
    <i class="fa fa-exclamation-circle alert-check"></i>
    {{'labels.noPendingLoansApproval'| translate}}
  </div>

</div>
