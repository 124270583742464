<h1 mat-dialog-title>{{ 'labels.buttons.viewsig' | translate }}</h1>

<div *ngIf="signatureId" mat-dialog-content>
  <img [src]="signatureImage" alt="Signature">
</div>

<div *ngIf="!signatureId" mat-dialog-content>
  <p>{{ 'labels.anchors.clientSignatureIsNotProvided' | translate }}</p>
</div>

<mat-dialog-actions align="end">
  <button mat-raised-button mat-dialog-close>{{"labels.buttons.close" | translate}}</button>
  <button mat-raised-button *ngIf="signatureId" color="warn" [mat-dialog-close]="{ delete: true }">{{ 'labels.buttons.delete' | translate }}</button>
  <button mat-raised-button color="primary" [mat-dialog-close]="{ upload: true }">{{ signatureId ? 'Reupload' : 'Upload'}}</button>
</mat-dialog-actions>
