<div class="container m-b-20" fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="end" fxLayoutGap="2%">
  <button mat-raised-button color="primary" [routerLink]="['edit']" *mifosxHasPermission="'UPDATE_DELINQUENCY_RANGE'">
    <fa-icon icon="edit"></fa-icon>&nbsp;&nbsp;
    {{"labels.buttons.edit" | translate}}
  </button>
  <button mat-raised-button color="warn" (click)="deleteDelinquencyRange()" *mifosxHasPermission="'DELETE_DELINQUENCY_RANGE'">
    <fa-icon icon="trash"></fa-icon>&nbsp;&nbsp;
    {{"labels.buttons.delete" | translate}}
  </button>
</div>

<div class="container">

  <mat-card>

    <mat-card-content>

      <div fxLayout="row wrap" class="content">

        <div fxFlex="50%" class="mat-body-strong">
          {{"labels.Classification" | translate}}
        </div>

        <div fxFlex="50%">
          {{ delinquencyRangeData.classification }}
        </div>

        <div fxFlex="50%" class="mat-body-strong">
          {{"labels.daysFrom" | translate}}
        </div>

        <div fxFlex="50%">
          {{ delinquencyRangeData.minimumAgeDays }}
        </div>

        <div fxFlex="50%" class="mat-body-strong">
          {{"labels.daysTill" | translate}}
        </div>

        <div fxFlex="50%">
          {{ delinquencyRangeData.maximumAgeDays }}
        </div>

      </div>

    </mat-card-content>

  </mat-card>

</div>
