import { MapCreditBureauToLoanProductComponent } from './external-services/credit-bureau/map-credit-bureau-to-loan-product/map-credit-bureau-to-loan-product.component';
import { ManageTenantResolver } from './manage-tenant/manage-tenant.resolver';
import { ManageTenantComponent } from './manage-tenant/manage-tenant.component';
import { ViewFieldsResolver } from './fields-permissions/view-role-fields-permissions/view-fields.resolver';
import { ViewGetRoleResolver } from './roles-and-permissions/view-role/view-get-role.resolver';
import { ViewRoleFieldsPermissionsComponent } from './fields-permissions/view-role-fields-permissions/view-role-fields-permissions.component';
import { FieldsPermissionsComponent } from './fields-permissions/fields-permissions.component';
import { NgModule } from '@angular/core';

/** Routing Imports */
import { Routes, RouterModule } from '@angular/router';
import { Route } from '../core/route/route.service';
import { extract } from '../core/i18n/i18n.service';

/** Component Imports */
import { SystemComponent } from './system.component';
import { CodesComponent } from './codes/codes.component';
import { CreateCodeComponent } from './codes/create-code/create-code.component';
import { ViewCodeComponent } from './codes/view-code/view-code.component';
import { EditCodeComponent } from './codes/edit-code/edit-code.component';
import { ExternalServicesComponent } from './external-services/external-services.component';
import { ManageDataTablesComponent } from './manage-data-tables/manage-data-tables.component';
import { CreateDataTableComponent } from './manage-data-tables/create-data-table/create-data-table.component';
import { ViewDataTableComponent } from './manage-data-tables/view-data-table/view-data-table.component';
import { EditDataTableComponent } from './manage-data-tables/edit-data-table/edit-data-table.component';
import { ManageHooksComponent } from './manage-hooks/manage-hooks.component';
import { ViewHookComponent } from './manage-hooks/view-hook/view-hook.component';
import { CreateHookComponent } from './manage-hooks/create-hook/create-hook.component';
import { EditHookComponent } from './manage-hooks/edit-hook/edit-hook.component';
import { RolesAndPermissionsComponent } from './roles-and-permissions/roles-and-permissions.component';
import { AddRoleComponent } from './roles-and-permissions/add-role/add-role.component';
import { ManageSurveysComponent } from './manage-surveys/manage-surveys.component';
import { CreateSurveyComponent } from './manage-surveys/create-survey/create-survey.component';
import { ViewSurveyComponent } from './manage-surveys/view-survey/view-survey.component';
import { EditSurveyComponent } from './manage-surveys/edit-survey/edit-survey.component';
import { AmazonS3Component } from './external-services/amazon-s3/amazon-s3.component';
import { EmailComponent } from './external-services/email/email.component';
import { SMSComponent } from './external-services/sms/sms.component';
import { NotificationComponent } from './external-services/notification/notification.component';
import { EditAmazonS3Component } from './external-services/amazon-s3/edit-amazon-s3/edit-amazon-s3.component';
import { EditEmailComponent } from './external-services/email/edit-email/edit-email.component';
import { EditNotificationComponent } from './external-services/notification/edit-notification/edit-notification.component';
import { EditSMSComponent } from './external-services/sms/edit-sms/edit-sms.component';
import { AccountNumberPreferencesComponent } from './account-number-preferences/account-number-preferences.component';
import { CreateAccountNumberPreferenceComponent } from './account-number-preferences/create-account-number-preference/create-account-number-preference.component';
import { ViewAccountNumberPreferenceComponent } from './account-number-preferences/view-account-number-preference/view-account-number-preference.component';
import { EditAccountNumberPreferenceComponent } from './account-number-preferences/edit-account-number-preference/edit-account-number-preference.component';
import { ManageReportsComponent } from './manage-reports/manage-reports.component';
import { EditReportComponent } from './manage-reports/edit-report/edit-report.component';
import { CreateReportComponent } from './manage-reports/create-report/create-report.component';
import { ViewReportComponent } from './manage-reports/view-report/view-report.component';
import { AuditTrailsComponent } from './audit-trails/audit-trails.component';
import { ViewAuditComponent } from './audit-trails/view-audit/view-audit.component';
import { ViewRoleComponent } from './roles-and-permissions/view-role/view-role.component';
import { EditRoleComponent } from './roles-and-permissions/edit-role/edit-role.component';
import { EntityToEntityMappingComponent } from './entity-to-entity-mapping/entity-to-entity-mapping.component';

/** Custom Resolvers */
import { CodesResolver } from './codes/codes.resolver';
import { CodeResolver } from './codes/code.resolver';
import { CodeValuesResolver } from './codes/view-code/code-values.resolver';
import { ManageDataTablesResolver } from './manage-data-tables/manage-data-tables.resolver';
import { DataTableResolver } from './manage-data-tables/data-table.resolver';
import { ManageHooksResolver } from './manage-hooks/manage-hooks.resolver';
import { HookResolver } from './manage-hooks/view-hook/hook.resolver';
import { HooksTemplateResolver } from './manage-hooks/hooks-template.resolver';
import { RolesAndPermissionsResolver } from './roles-and-permissions/roles-and-permissions.resolver';
import { ManageSurveysResolver } from './manage-surveys/manage-surveys.resolver';
import { GlobalConfigurationsResolver } from './configurations/global-configurations-tab/global-configurations.resolver';
import { AmazonS3ConfigurationResolver } from './external-services/amazon-s3/amazon-s3.resolver';
import { EmailConfigurationResolver } from './external-services/email/email.resolver';
import { SMSConfigurationResolver } from './external-services/sms/sms.resolver';
import { NotificationConfigurationResolver } from './external-services/notification/notification.resolver';
import { AccountNumberPreferencesResolver } from './account-number-preferences/account-number-preferences.resolver';
import { AccountNumberPreferencesTemplateResolver } from './account-number-preferences/create-account-number-preference/account-number-preferences-template.resolver';
import { AccountNumberPreferenceResolver } from './account-number-preferences/view-account-number-preference/account-number-preference.resolver';
import { AuditTrailSearchTemplateResolver } from './audit-trails/audit-trail-search-template.resolver';
import { AuditTrailResolver } from './audit-trails/view-audit/audit-trail.resolver';
import { ReportsResolver } from './manage-reports/reports.resolver';
import { ReportResolver } from './manage-reports/report.resolver';
import { SurveyResolver } from './manage-surveys/survey.resolver';
import { ReportTemplateResolver } from './manage-reports/report-template.resolver';
import { ViewSchedulerJobComponent } from './manage-jobs/scheduler-jobs/view-scheduler-job/view-scheduler-job.component';
import { ViewSchedulerJobResolver } from './manage-jobs/scheduler-jobs/view-scheduler-job/view-scheduler-job.resolver';
import { EditSchedulerJobComponent } from './manage-jobs/scheduler-jobs/edit-scheduler-job/edit-scheduler-job.component';
import { ManageSchedulerJobResolver } from './manage-jobs/scheduler-jobs/manage-scheduler-job.resolver';
import { ViewRoleResolver } from './roles-and-permissions/view-role/view-role.resolver';
import { EntityToEntityMappingResolver } from './entity-to-entity-mapping/entity-to-entity-mapping.resolver';
import { ConfigureMakerCheckerTasksComponent } from './configure-maker-checker-tasks/configure-maker-checker-tasks.component';
import { MakerCheckerTasksResolver } from './configure-maker-checker-tasks/configure-maker-checker-tasks.resolver';
import { ViewHistorySchedulerJobComponent } from './manage-jobs/scheduler-jobs/view-history-scheduler-job/view-history-scheduler-job.component';
import { ViewHistorySchedulerJobsResolver } from './manage-jobs/scheduler-jobs/view-history-scheduler-job/view-history-scheduler-job.resolver';
import { ConfigurationsComponent } from './configurations/configurations.component';
import { GlobalConfigurationResolver } from './configurations/global-configurations-tab/global-configuration.resolver';
import { EditConfigurationComponent } from './configurations/global-configurations-tab/edit-configuration/edit-configuration.component';
import { ManageJobsComponent } from './manage-jobs/manage-jobs.component';
import { ManageExternalEventsComponent } from './manage-external-events/manage-external-events.component';
import { ManageExternalEventsResolver } from './manage-external-events/manage-external-events.resolver';
import { CreditBureauComponent } from './external-services/credit-bureau/credit-bureau.component';
import { CreditBureauResolver } from './external-services/credit-bureau/credit-bureau.resolver';
import { CreditBureauMappingsResolver } from './external-services/credit-bureau/credit-bureau-mappings.resolver';
import { AddCreditBureauComponent } from './external-services/credit-bureau/add-credit-bureau/add-credit-bureau.component';
import { CreditBureauTemplateResolver } from './external-services/credit-bureau/add-credit-bureau/credit-bureau-template.resolver';
import { CreditBureauConfigurationComponent } from './external-services/credit-bureau/credit-bureau-configuration/credit-bureau-configuration.component';
import { EditCreditBureauConfigurationComponent } from './external-services/credit-bureau/edit-configuration/edit-credit-bureau-configuration.component';
import { CreditBureauConfigurationResolver } from './external-services/credit-bureau/edit-configuration/credit-bureau-configuration.resolver';
import { MapCreditBureauToLPTemplateResolver } from './external-services/credit-bureau/map-credit-bureau-to-loan-product/map-credit-bureau-to-lp-template.resolver';

const routes: Routes = [
  Route.withShell([
    {
      path: 'system',
      data: { title: extract('labels.menus.System'), breadcrumb: extract('labels.menus.System') },
      children: [
        {
          path: '',
          component: SystemComponent
        },
        {
          path: 'codes',
          data: { title: extract('labels.viewCodes'), breadcrumb: extract("labels.codes") },
          children: [
            {
              path: '',
              component: CodesComponent,
              resolve: {
                codes: CodesResolver
              }
            },
            {
              path: 'create',
              component: CreateCodeComponent,
              data: { title: extract('labels.createCode'), breadcrumb: extract("labels.buttons.create") }
            },
            {
              path: ':id',
              data: { title: extract('labels.viewCodes'), routeParamBreadcrumb: 'id' },
              children: [
                {
                  path: '',
                  component: ViewCodeComponent,
                  resolve: {
                    code: CodeResolver,
                    codeValues: CodeValuesResolver
                  }
                },
                {
                  path: 'edit',
                  component: EditCodeComponent,
                  data: { title: extract('labels.heading.editcode'), breadcrumb: extract("labels.inputs.edit"), routeParamBreadcrumb: false },
                  resolve: {
                    code: CodeResolver
                  }
                }
              ]
            }
          ],
        },
        {
          path: 'external-events',
          data: { title: extract('labels.anchors.manageExternalEvents'), breadcrumb: extract('labels.anchors.manageExternalEvents') },
          children: [
            {
              path: '',
              component: ManageExternalEventsComponent,
              resolve: {
                events: ManageExternalEventsResolver
              }
            },
          ]
        },
        {
          path: 'entity-to-entity-mapping',
          component: EntityToEntityMappingComponent,
          data: { title: extract('labels.anchors.entitytoentitymapping'), breadcrumb: extract('labels.anchors.entitytoentitymapping') },
          resolve: {
            entityMappings: EntityToEntityMappingResolver
          }
        },
        {
          path: 'external-services',
          data: { title: extract('labels.anchors.externalServices'), breadcrumb: extract('labels.anchors.externalServices') },
          children: [
            {
              path: '',
              component: ExternalServicesComponent
            },
            {
              path: 'amazon-s3',
              data: { title: extract('labels.S3AmazonServiceConfiguration'), breadcrumb: 'Amazon S3' },
              children: [
                {
                  path: '',
                  component: AmazonS3Component,
                  resolve: {
                    amazonS3Configuration: AmazonS3ConfigurationResolver
                  }
                },
                {
                  path: 'edit',
                  component: EditAmazonS3Component,
                  data: { title: extract('labels.S3AmazonServiceConfiguration'), breadcrumb: extract("labels.inputs.edit") },
                  resolve: {
                    amazonS3Configuration: AmazonS3ConfigurationResolver
                  }
                }
              ]
            },
            {
              path: 'email',
              data: { title: extract('labels.viewEmailConfiguration'), breadcrumb: extract("labels.inputs.email") },
              children: [
                {
                  path: '',
                  component: EmailComponent,
                  resolve: {
                    emailConfiguration: EmailConfigurationResolver
                  }
                },
                {
                  path: 'edit',
                  component: EditEmailComponent,
                  data: { title: extract('labels.editEmailConfiguration'), breadcrumb: extract("labels.inputs.edit") },
                  resolve: {
                    emailConfiguration: EmailConfigurationResolver
                  }
                }
              ]
            },
            {
              path: 'sms',
              data: { title: extract('labels.viewSMSConfiguration'), breadcrumb: 'SMS' },
              children: [
                {
                  path: '',
                  component: SMSComponent,
                  resolve: {
                    smsConfiguration: SMSConfigurationResolver
                  }
                },
                {
                  path: 'edit',
                  data: { title: extract('labels.editSMSConfiguration'), breadcrumb: extract("labels.inputs.edit") },
                  component: EditSMSComponent,
                  resolve: {
                    smsConfiguration: SMSConfigurationResolver
                  }
                }
              ]
            },
            {
              path: 'notification',
              data: { title: extract('labels.viewNotificationConfiguration'), breadcrumb: extract("labels.notification") },
              children: [
                {
                  path: '',
                  component: NotificationComponent,
                  resolve: {
                    notificationConfiguration: NotificationConfigurationResolver
                  }
                },
                {
                  path: 'edit',
                  component: EditNotificationComponent,
                  data: { title: extract('labels.editNotificationConfiguration'), breadcrumb: extract("labels.inputs.edit") },
                  resolve: {
                    notificationConfiguration: NotificationConfigurationResolver
                  }
                }
              ]
            },
            {
              path: 'creditBureau',
              data: { title: extract('labels.creditbureau'), breadcrumb: extract("labels.viewCreditBureauSummary") },
              children: [
                {
                  path: '',
                  component: CreditBureauComponent,
                  resolve: {
                    creditBureauSummary: CreditBureauResolver,
                    creditBureauMappings: CreditBureauMappingsResolver
                  }
                },
                {
                  path: 'addcb',
                  component: AddCreditBureauComponent,
                  data: { title: extract('labels.addCreditBureau'), breadcrumb: extract("labels.addCreditBureau") },
                  resolve: {
                    creditBureauTemplate: CreditBureauTemplateResolver
                  }
                },
                {
                  path: 'configuration',
                  component: CreditBureauConfigurationComponent,
                  data: { title: extract('labels.anchors.creditbureauconfiguration'), breadcrumb: extract("labels.anchors.creditbureauconfiguration") },
                  resolve: {
                    creditBureauTemplate: CreditBureauTemplateResolver
                  }
                },
                {
                  path: 'editconfiguration/:organisationCreditBureauId',
                  component: EditCreditBureauConfigurationComponent,
                  data: { title: extract('labels.anchors.creditbureauconfiguration'), breadcrumb: extract("labels.anchors.creditbureauconfiguration") },
                  resolve: {
                    cbConfigs: CreditBureauConfigurationResolver
                  }
                },
                {
                  path: 'mapcblp',
                  component: MapCreditBureauToLoanProductComponent,
                  data: { title: extract('labels.anchors.mapcblp'), breadcrumb: extract("labels.anchors.mapcblp") },
                  resolve: {
                    loanProducts: MapCreditBureauToLPTemplateResolver,
                    creditBureauSummary: CreditBureauResolver,
                  }
                }
              ]
            }
          ]
        },
        {
          path: 'data-tables',
          data: { title: extract('labels.anchors.managedatatables'), breadcrumb: extract('labels.anchors.managedatatables') },
          children: [
            {
              path: '',
              component: ManageDataTablesComponent,
              resolve: {
                dataTables: ManageDataTablesResolver
              },
            },
            {
              path: 'create',
              component: CreateDataTableComponent,
              data: { title: extract('labels.anchors.createdatatable'), breadcrumb: extract("labels.buttons.create") },
              resolve: {
                columnCodes: CodesResolver
              }
            },
            {
              path: ':datatableName',
              data: { title: extract('labels.viewDataTable'), routeParamBreadcrumb: 'datatableName' },
              children: [
                {
                  path: '',
                  component: ViewDataTableComponent,
                  resolve: {
                    dataTable: DataTableResolver
                  }
                },
                {
                  path: 'edit',
                  component: EditDataTableComponent,
                  data: { title: extract('labels.heading.editdatatable'), breadcrumb: extract("labels.inputs.edit"), routeParamBreadcrumb: false },
                  resolve: {
                    dataTable: DataTableResolver,
                    columnCodes: CodesResolver
                  }
                }
              ]
            }
          ],
        },
        {
          path: 'hooks',
          data: { title: extract('labels.anchors.managehooks'), breadcrumb: extract("labels.anchors.managehooks") },
          children: [
            {
              path: '',
              component: ManageHooksComponent,
              resolve: {
                hooks: ManageHooksResolver
              }
            },
            {
              path: 'create',
              component: CreateHookComponent,
              data: { title: extract('labels.anchors.createhook'), breadcrumb: extract("labels.buttons.create") },
              resolve: {
                hooksTemplate: HooksTemplateResolver
              }
            },
            {
              path: ':id',
              data: { title: extract('labels.viewHook'), routeParamBreadcrumb: 'id' },
              children: [
                {
                  path: '',
                  component: ViewHookComponent,
                  resolve: {
                    hook: HookResolver
                  }
                },
                {
                  path: 'edit',
                  component: EditHookComponent,
                  data: { title: extract('labels.heading.edithook'), breadcrumb: extract("labels.inputs.edit"), routeParamBreadcrumb: false },
                  resolve: {
                    hooksTemplate: HooksTemplateResolver,
                    hook: HookResolver
                  }
                }
              ]
            }
          ]
        },
        {
          path: 'roles-and-permissions',
          data: { title: extract('labels.rolesAndPermissions'), breadcrumb: extract("labels.rolesAndPermissions") },
          children: [
            {
              path: '',
              component: RolesAndPermissionsComponent,
              resolve: {
                roles: RolesAndPermissionsResolver
              }
            },
            {
              path: 'add',
              component: AddRoleComponent,
              data: { title: extract('labels.buttons.addrole'), breadcrumb: 'Add' }
            },
            {
              path: ':id',
              data: { title: extract('labels.anchors.viewrole'), routeParamBreadcrumb: 'id' },
              runGuardsAndResolvers: 'always',
              children: [
                {
                  path: '',
                  component: ViewRoleComponent,
                  resolve: {
                    roledetails: ViewRoleResolver,
                  }
                },
                {
                  path: 'edit',
                  component: EditRoleComponent,
                  data: { title: extract('labels.anchors.editrole'), breadcrumb: extract("labels.inputs.edit"), routeParamBreadcrumb: false },
                  resolve: {
                    role: ViewRoleResolver,
                  }
                }
              ]
            }
          ]
        },
        {
          path: 'fields-permissions',
          data: { title: extract('labels.fieldsPermissions'), breadcrumb: extract("labels.fieldsPermissions") },
          children: [
            {
              path: '',
              component: FieldsPermissionsComponent,
              resolve: {
                roles: RolesAndPermissionsResolver
              }
            },
            {
              path: ':id',
              data: { title: extract('labels.anchors.viewrole'), routeParamBreadcrumb: 'id' },
              runGuardsAndResolvers: 'always',
              children: [
                {
                  path: '',
                  component: ViewRoleFieldsPermissionsComponent,
                  resolve: {
                    roledetails: ViewFieldsResolver,
                  }
                }
              ]
            }
          ]
        },
        {
          path: 'configure-mc-tasks',
          data: { title: extract('labels.anchors.configuremakercheckertask'), breadcrumb: extract("labels.anchors.configuremakercheckertask") },
          component: ConfigureMakerCheckerTasksComponent,
          resolve: {
            permissions: MakerCheckerTasksResolver
          }
        },
        {
          path: 'surveys',
          data: { title: extract("labels.anchors.managesurveys"), breadcrumb: extract("labels.anchors.managesurveys") },
          children: [
            {
              path: '',
              component: ManageSurveysComponent,
              resolve: {
                surveys: ManageSurveysResolver
              }
            },
            {
              path: 'create',
              component: CreateSurveyComponent,
              data: { title: extract('labels.buttons.createsurvey'), breadcrumb: extract("labels.buttons.create") },
            },
            {
              path: ':id',
              data: { title: extract('labels.viewSurvey'), routeParamBreadcrumb: 'id' },
              children: [
                {
                  path: '',
                  component: ViewSurveyComponent,
                  resolve: {
                    survey: SurveyResolver
                  }
                },
                {
                  path: 'edit',
                  component: EditSurveyComponent,
                  data: { title: extract('labels.editSurvey'), breadcrumb: extract("labels.inputs.edit"), routeParamBreadcrumb: false },
                  resolve: {
                    survey: SurveyResolver
                  }
                }
              ]
            }
          ]
        },
        {
          path: 'manage-jobs',
          data: { title: extract('labels.manageSchedulerAndCOBJobs'), breadcrumb: extract('labels.manageSchedulerAndCOBJobs') },
          children: [
            {
              path: '',
              component: ManageJobsComponent,
              resolve: {},
            },
            {
              path: ':id',
              data: { title: extract('labels.viewSchedulerJob'), routeParamBreadcrumb: 'id' },
              children: [
                {
                  path: '',
                  component: ViewSchedulerJobComponent,
                  resolve: {
                    selectedJob: ViewSchedulerJobResolver
                  }
                },
                {
                  path: 'edit',
                  component: EditSchedulerJobComponent,
                  data: { title: extract('labels.editSchedulerJob'), routeParamBreadcrumb: false, breadcrumb: extract("labels.inputs.edit") },
                  resolve: {
                    jobSelected: ManageSchedulerJobResolver
                  }
                },
                {
                  path: 'viewhistory',
                  component: ViewHistorySchedulerJobComponent,
                  data: { title: extract('labels.anchors.schedularjobhistory'), breadcrumb: extract("labels.anchors.schedularjobhistory") },
                  resolve: {
                    jobsSchedulerHistory: ViewHistorySchedulerJobsResolver
                  },
                }
              ]
            }
          ],
        },
        {
          path: 'configurations',
          data: { title: extract('labels.anchors.configuration'), breadcrumb: extract("labels.anchors.configuration") },
          children: [
            {
              path: '',
              component: ConfigurationsComponent,
              resolve: {}
            },
            {
              path: ':id/edit',
              data: { title: extract('labels.anchors.editconfiguration'), routeParamBreadcrumb: 'id' },
              component: EditConfigurationComponent,
              resolve: {
                configuration: GlobalConfigurationResolver
              }
            }
          ]
        },
        {
          path: 'account-number-preferences',
          data: { title: extract('labels.anchors.accountNumberPreferences'), breadcrumb: extract("labels.anchors.accountNumberPreferences") },
          children: [
            {
              path: '',
              component: AccountNumberPreferencesComponent,
              resolve: {
                accountNumberPreferences: AccountNumberPreferencesResolver
              }
            },
            {
              path: 'create',
              component: CreateAccountNumberPreferenceComponent,
              data: { title: extract('labels.createAccountNumberPreference'), breadcrumb: extract("labels.buttons.create") },
              resolve: {
                accountNumberPreferencesTemplate: AccountNumberPreferencesTemplateResolver
              }
            },
            {
              path: ':id',
              data: { title: extract('labels.viewAccountNumberPreference'), routeParamBreadcrumb: 'id' },
              children: [
                {
                  path: '',
                  component: ViewAccountNumberPreferenceComponent,
                  resolve: {
                    accountNumberPreference: AccountNumberPreferenceResolver
                  }
                },
                {
                  path: 'edit',
                  component: EditAccountNumberPreferenceComponent,
                  data: { title: extract('labels.editAccountNumberPreference'), breadcrumb: extract("labels.inputs.edit"), routeParamBreadcrumb: false },
                  resolve: {
                    accountNumberPreference: AccountNumberPreferenceResolver,
                    accountNumberPreferencesTemplate: AccountNumberPreferencesTemplateResolver
                  }
                }
              ]
            }
          ]
        },
        {
          path: 'reports',
          data: { title: extract('labels.anchors.managereports'), breadcrumb: extract("labels.anchors.managereports") },
          children: [
            {
              path: '',
              component: ManageReportsComponent,
              resolve: {
                reports: ReportsResolver
              }
            },
            {
              path: 'create',
              component: CreateReportComponent,
              data: { title: extract('labels.anchors.createreport'), breadcrumb: extract("labels.buttons.create") },
              resolve: {
                reportTemplate: ReportTemplateResolver
              }
            },
            {
              path: ':id',
              data: { title: extract('labels.heading.viewreport'), routeParamBreadcrumb: 'id' },
              children: [
                {
                  path: '',
                  component: ViewReportComponent,
                  resolve: {
                    report: ReportResolver
                  }
                },
                {
                  path: 'edit',
                  component: EditReportComponent,
                  data: { title: extract('labels.anchors.editreport'), routeParamBreadcrumb: false, breadcrumb: extract("labels.inputs.edit") },
                  resolve: {
                    report: ReportResolver,
                    reportTemplate: ReportTemplateResolver
                  }
                }
              ]
            }
          ]
        },
        {
          path: 'audit-trails',
          data: { title: extract('labels.anchors.audittrails'), breadcrumb: extract('labels.anchors.audittrails') },
          children: [
            {
              path: '',
              component: AuditTrailsComponent,
              resolve: {
                auditTrailSearchTemplate: AuditTrailSearchTemplateResolver
              }
            },
            {
              path: ':id',
              component: ViewAuditComponent,
              data: { title: extract('labels.viewAudit'), routeParamBreadcrumb: 'id' },
              resolve: {
                auditTrail: AuditTrailResolver
              }
            }
          ]
        },
        {
          path: 'manage-tenant',
          data: { title: extract('labels.manageTenant'), breadcrumb: extract('labels.manageTenant') },
          component: ManageTenantComponent,
          resolve: {
            metabaseDBs: ManageTenantResolver
          }
        }
      ]
    }
  ])
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: [
    CodesResolver,
    CodeResolver,
    CodeValuesResolver,
    ManageDataTablesResolver,
    DataTableResolver,
    ManageHooksResolver,
    HookResolver,
    HooksTemplateResolver,
    RolesAndPermissionsResolver,
    ManageSurveysResolver,
    SurveyResolver,
    GlobalConfigurationsResolver,
    GlobalConfigurationResolver,
    AmazonS3ConfigurationResolver,
    EmailConfigurationResolver,
    SMSConfigurationResolver,
    NotificationConfigurationResolver,
    AccountNumberPreferencesResolver,
    AccountNumberPreferencesTemplateResolver,
    AccountNumberPreferenceResolver,
    ReportsResolver,
    ReportResolver,
    ReportTemplateResolver,
    AuditTrailSearchTemplateResolver,
    AuditTrailResolver,
    ViewSchedulerJobResolver,
    ManageSchedulerJobResolver,
    ViewRoleResolver,
    ViewGetRoleResolver,
    EntityToEntityMappingResolver,
    MakerCheckerTasksResolver,
    ViewHistorySchedulerJobsResolver,
    ViewFieldsResolver,
    ManageTenantResolver,
    CreditBureauResolver,
    CreditBureauMappingsResolver,
    CreditBureauTemplateResolver,
    CreditBureauConfigurationResolver,
    MapCreditBureauToLPTemplateResolver
  ]
})
export class SystemRoutingModule { }
