<div class="tab-container mat-typography">
  <div class="profile-overview">
    {{"labels.profileOverview" | translate}}
  </div>
  <mat-card class="client-card">

    <mat-card-header fxLayout="column" class="mat-toolbar mat-primary header">

      <mat-card-title-group class="header-title-group">

        <div class="profile-image-container">

            <img mat-card-md-image class="profile-image"
              [src]="(clientImage)?clientImage:'assets/images/user_placeholder.png'">

          <div class="actions-profile">

            <span (click)="doAction('Upload Image')">
              <button class="client-image-button" mat-icon-button
                [matTooltip]="'labels.anchors.uploadImage' | translate " *mifosxHasPermission="'CREATE_CLIENTIMAGE'">
                <i class="fa fa-upload"></i>
              </button>
              {{"labels.anchors.uploadImage" | translate}}
            </span>
            <span (click)="doAction('Capture Image')">
              <button class="client-image-button" mat-icon-button
                [matTooltip]=" 'labels.anchors.captureImage' | translate " *mifosxHasPermission="'CREATE_CLIENTIMAGE'">
                <i class="fa fa-camera"></i>
              </button>
              {{"labels.anchors.captureImage" | translate}}
            </span>
            <span (click)="doAction('Delete Image')" *ngIf="clientViewData.imagePresent">
              <button class="client-image-button" mat-icon-button
                [matTooltip]=" 'labels.anchors.deleteImage' | translate " *mifosxHasPermission="'DELETE_CLIENTIMAGE'">
                <i class="fa fa-trash"></i>
              </button>
              {{"labels.anchors.deleteImage" | translate}}
            </span>
            <span >
              <mifosx-whatsapp-button text="true" [phoneNumber]="clientViewData.mobileNo"></mifosx-whatsapp-button>
            </span>
          </div>
          <p (click)="doAction('View Signature')" class="signature">{{ 'labels.buttons.viewSignature' | translate }}</p>
        </div>

        <div class="mat-typography client-card-title">


          <!-- <mat-card-subtitle>
            <p>
              Client #:{{clientViewData.accountNo}} | External
              Id: {{clientViewData.externalId}} | Staff:{{clientViewData.staffName || 'Unassigned'}}<br />
              Activation Date :
              {{(clientViewData.activation)?(clientViewData.activationDate | dateFormat) :'Not Activated'}}<br />
              Member Of :
              <span *ngFor="let group of clientViewData.groups">
                <a>{{group.name}}</a>&nbsp;
              </span>
              <span *ngIf="!clientViewData.groups">Unassigned</span>
              <br/>
            </p>
          </mat-card-subtitle> -->


          <table class="box-with-new-status-align-profile" style="margin-top: 6px;">
            <tbody>
              <tr>
                <th class="whitebg" style="font-size: 16px; color: #0a1129; font-weight: 600;" colspan="2">{{
                  'labels.heading.basicDetails' | translate }}</th>
              </tr>
              <tr>
                <th class="table-bold">{{ 'labels.inputs.activationdate' | translate }}
                </th>
                <th class="table-bold">{{ 'labels.heading.memberof' | translate }}
                </th>
                <th class="table-bold">{{ 'labels.inputs.isStaff' | translate }}</th>
                <th class="table-bold">{{ 'labels.inputs.mobilenumber' | translate }}
                </th>
              </tr>
              <tr>
                <td>
                  <span class="padded-td">{{clientViewData.timeline.activatedOnDate | dateFormat}}
                    <span *ngIf="!clientViewData.timeline.activatedOnDate" class="ng-binding ng-hide">
                      {{'labels.notactivated' | translate}}
                    </span>
                  </span>
                </td>
                <td>
                  <span *ngIf="!clientViewData.groups">
                    {{'labels.unassigned' | translate }}
                  </span>

                  <span *ngFor="let group of clientViewData.groups; let last= last">

                    <span class="list-group-item-text" style="color: #9198af;"
                    [routerLink]="['/groups',group.id]">{{group.name}}</span>&nbsp;
                    <span *ngIf="!clientViewData?.groups">
                      {{'label.unassigned' | translate }}
                    </span>
                    <span *ngIf="!last">|</span>
                  </span>
                </td>
                <td><span class="padded-td">{{clientViewData.isStaff}}</span>
                </td>
                <td *ngIf="clientViewData.mobileNo">
                  <span>{{clientViewData.mobileNo}}</span>
                </td>
                <td *ngIf="!clientViewData.mobileNo">-</td>
              </tr>
              <tr>
                <th class="table-bold">{{ 'labels.inputs.gender' | translate }}</th>
                <th class="table-bold">{{ 'labels.inputs.clienttype' | translate }}
                </th>
                <th class="table-bold">{{ 'labels.inputs.clientclassification' | translate }}</th>
                <th class="table-bold">{{ 'labels.inputs.dateofbirth' | translate }}
                </th>
              </tr>
              <tr>
                <td *ngIf="clientViewData.gender?.name" class=""><span
                    class="padded-td">{{clientViewData.gender.name}}</span>
                </td>
                <td *ngIf="!clientViewData.gender?.name">-</td>
                <td *ngIf="clientViewData.clientType.name" class=""><span
                    class="padded-td">{{clientViewData.clientType.name}}</span>
                </td>
                <td *ngIf="!clientViewData.clientType.name">-</td>
                <td *ngIf="clientViewData.clientClassification.name" class=""><span
                    class="padded-td">{{clientViewData.clientClassification.name}}</span>
                </td>
                <td *ngIf="!clientViewData.clientClassification.name">-</td>
                <td *ngIf="clientViewData.dateOfBirth"><span class="padded-td">{{clientViewData.dateOfBirth |
                    dateFormat}}</span>
                </td>
                <td *ngIf="!clientViewData.dateOfBirth">-</td>
              </tr>
              <tr>
                <th class="table-bold">{{ 'labels.heading.closedate' | translate }}
                </th>
              </tr>
              <tr>
                <td *ngIf="clientViewData.status.value==='Closed'"><span
                    class="padded-td">{{clientViewData.timeline.closedOnDate |
                    dateFormat}}</span>
                </td>
                <td *ngIf="clientViewData.status.value!=='Closed'">-</td>
              </tr>


              <tr>
                <th class="whitebg" style="font-size: 16px; color: #0a1129;font-weight: 600;" colspan="2">
                  {{'labels.heading.performancehistory' | translate }}</th>
              </tr>
              <tr>
                <th class="ng-binding">{{ 'labels.heading.numofloancycle' | translate }}</th>
                <th class="ng-binding">{{ 'labels.heading.lastloanamount' | translate }}</th>
                <th class="ng-binding">{{ 'labels.heading.numofactiveloans' | translate }}</th>
                <th class="ng-binding">{{ 'labels.heading.numofactivesavings' | translate }}</th>
              </tr>
              <tr>
                <td><span class="padded-td">{{clientViewData.ClientSummary?.loanCycle}}</span>
                </td>
                <td><span class="padded-td">{{clientViewData.ClientSummary?.lastLoanAmount|number}}</span>
                </td>
                <td><span class="padded-td">{{clientViewData.ClientSummary?.activeLoans}}</span>
                </td>
                <td><span class="padded-td">{{clientViewData.ClientSummary?.activeSavings}}</span>
                </td>
              </tr>
              <tr>
                <th class="ng-binding">{{ 'labels.heading.totalsavings' | translate }}()
        <!--                   Todo: 'currency'
                  ({{clientSummary.currency.code}}) -->
                </th>
              </tr>
              <tr>
                <td><span class="padded-td">{{clientSummary?.totalSavings}}</span></td>
              </tr>
              <!-- ngRepeat: t in totalAllSavingsAccountsBalanceBasedOnCurrency -->
            </tbody>
          </table>



        </div>

      </mat-card-title-group>
    </mat-card-header>
  </mat-card>



  <div class="heading-content">
    <div fxLayout="column" fxFlex="100%">
      <div fxLayout="row" fxLayoutAlign="flex-end" style="margin-right: -15px;">
        <button mat-raised-button class="f-right" color="primary"
          (click)="changeView()">{{'labels.buttons.'+viewButtonTitle | translate }}</button>
      </div>
    </div>
  </div>
  <div [ngStyle]="{'display': graphView ? 'block' : 'none'}" id="metabasedashboard">
    <mifosx-metabasedashboard class="metabase" [dashId]="dashId" [clientId]="clientid"></mifosx-metabasedashboard>
  </div>
  <div [ngStyle]="{'display': graphView ? 'none' : 'block'}" class="tables-container">
    <!-- <h3>{{ 'labels.heading.performancehistory' | translate }}</h3>
    <div fxLayout="row" fxLayoutGap="32px" class="performance-history-container">
      <p>
        {{ 'labels.heading.numofloancycle' | translate }} : {{clientSummary?.loanCycle}} <br />
        {{ 'labels.heading.numofactiveloans' | translate }} : {{clientSummary?.activeLoans}} <br />
        {{ 'labels.heading.lastloanamount' | translate }} : {{clientSummary?.lastLoanAmount}} <br />
      </p>
      <p>
        {{ 'labels.heading.numofactivesavings' | translate }} : {{clientSummary?.activeSavings}} <br />
        {{ 'labels.heading.totalsavings' | translate }} : {{clientSummary?.totalSavings}} <br />
      </p>
    </div> -->

    <div *mifosxHasPermission="'READ_CLIENTCHARGE'">
      <!-- Upcoming Charges -->
      <div class="heading-content">
        <div fxLayout="column" fxFlex="50%">
          <div class="heading-name">
            <h3>{{ 'labels.heading.upcomingcharges' | translate }}</h3>
          </div>
        </div>
        <div fxLayout="column" fxFlex="50%">
          <div fxLayout="row" fxLayoutAlign="flex-end">
            <button mat-raised-button class="f-right" color="primary" [routerLink]="[ 'charges', 'overview']"
              *mifosxHasPermission="'READ_CLIENTCHARGE'">
              {{ 'labels.buttons.chargesoverview' | translate }} </button>
          </div>
        </div>
      </div>

      <table mat-table [dataSource]="upcomingCharges">

        <ng-container matColumnDef="Name">
          <th mat-header-cell *matHeaderCellDef>{{ 'labels.heading.name' | translate }}</th>
          <td mat-cell *matCellDef="let element" > <i class="fa fa-stop"
              [ngClass]="(!(element.isWaived || element.isPaid)) | statusLookup"></i>
            {{element.name}} </td>
        </ng-container>

        <ng-container matColumnDef="Due as of">
          <th mat-header-cell *matHeaderCellDef>{{ 'labels.heading.dueAsOf' | translate }}</th>
          <td mat-cell *matCellDef="let element"> {{element.dueDate | dateFormat}} </td>
        </ng-container>

        <ng-container matColumnDef="Due">
          <th mat-header-cell *matHeaderCellDef>{{ 'labels.heading.due' | translate }}</th>
          <td mat-cell *matCellDef="let element"> {{element.amount}} </td>
        </ng-container>

        <ng-container matColumnDef="Paid">
          <th mat-header-cell *matHeaderCellDef>{{ 'labels.heading.paid' | translate }}</th>
          <td mat-cell *matCellDef="let element"> {{element.amountPaid}} </td>
        </ng-container>

        <ng-container matColumnDef="Waived">
          <th mat-header-cell *matHeaderCellDef>{{ 'labels.heading.waived' | translate }}</th>
          <td mat-cell *matCellDef="let element"> {{element.amountWaived}} </td>
        </ng-container>

        <ng-container matColumnDef="Outstanding" >
          <th mat-header-cell *matHeaderCellDef>{{ 'labels.heading.outstanding' | translate }}</th>
          <td mat-cell *matCellDef="let element"> {{element.amountOutstanding}} </td>
        </ng-container>

        <ng-container matColumnDef="Actions">
          <th mat-header-cell *matHeaderCellDef>{{ 'labels.heading.actions' | translate }}</th>
          <td mat-cell *matCellDef="let element" class="actions-cell">
            <button class="account-action-button" mat-stroked-button color="primary" (click)="routeEdit($event)"
              [routerLink]="['charges', element.id, 'pay']" *mifosxHasPermission="'PAY_CLIENTCHARGE'">
              <i class="fa fa-dollar"></i>&nbsp;{{ 'labels.buttons.payClientCharge' | translate }}
            </button>
            <button class="account-action-button" mat-stroked-button color="primary"
              (click)="routeEdit($event); waiveCharge(element.id,element.clientId)"
              *mifosxHasPermission="'WAIVE_CLIENTCHARGE'">
              <i class="fa fa-flag"></i>&nbsp;{{ 'labels.buttons.waiveClientCharge' | translate }}
            </button>
          </td>

        </ng-container>

        <tr mat-header-row *matHeaderRowDef="upcomingChargesColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: upcomingChargesColumns;" [routerLink]="['charges',row.id]"></tr>

      </table>
    </div>
    <!-- loans accounts overview table -->
    <div class="heading-content">
      <div fxLayout="column" fxFlex="50%">
        <div class="heading-name">
          <h3>{{ 'labels.buttons.loanaccount' | translate }}</h3>
        </div>
      </div>
      <div fxLayout="column" fxFlex="50%">
        <div fxLayout="row" fxLayoutAlign="flex-end">
          <button mat-raised-button class="f-right" color="primary"
            (click)="toggleLoanAccountsOverview()">{{(showClosedLoanAccounts?'labels.buttons.viewActiveAccounts':'labels.buttons.viewClosedAccounts')
            | translate}}</button>
        </div>
      </div>
    </div>

    <table *ngIf="!showClosedLoanAccounts" mat-table [dataSource]="loanAccounts|accountsFilter:'loan'">

      <ng-container matColumnDef="Account No">
        <th mat-header-cell *matHeaderCellDef>{{ 'labels.heading.accnum' | translate }}</th>
        <td mat-cell *matCellDef="let element"> <i class="fa fa-stop"
            [ngClass]="element.inArrears?'status-active-overdue':(element.status.code|statusLookup)"></i>
          {{element.accountNo}}
        </td>
      </ng-container>

      <ng-container matColumnDef="Loan Account">
        <th mat-header-cell *matHeaderCellDef>{{ 'labels.heading.loanaccount' | translate }}</th>
        <td mat-cell *matCellDef="let element"> {{element.productName}} </td>
      </ng-container>

      <ng-container matColumnDef="Original Loan">
        <th mat-header-cell *matHeaderCellDef>{{ 'labels.heading.originalLoan' | translate }}</th>
        <td mat-cell *matCellDef="let element"> {{element.originalLoan}} </td>
      </ng-container>
      <ng-container matColumnDef="Loan Balance">
        <th mat-header-cell *matHeaderCellDef>{{ 'labels.heading.loanBalance' | translate }}</th>
        <td mat-cell *matCellDef="let element"> {{element.loanBalance}} </td>
      </ng-container>

      <ng-container matColumnDef="Amount Paid">
        <th mat-header-cell *matHeaderCellDef>{{ 'labels.heading.amountPaid' | translate }}</th>
        <td mat-cell *matCellDef="let element"> {{element.amountPaid}} </td>
      </ng-container>

      <ng-container matColumnDef="Type">
        <th mat-header-cell *matHeaderCellDef>{{ 'labels.heading.type' | translate }}</th>
        <td mat-cell *matCellDef="let element">
          <i class="fa fa-large" [ngClass]="(element.loanType.value=== 'Individual')?'fa-user':'fa-group'"
            matTooltip=" {{ element.loanType.value }}" matTooltipPosition="above"></i>
        </td>
      </ng-container>

      <ng-container matColumnDef="Actions">
        <th mat-header-cell *matHeaderCellDef>{{ 'labels.heading.actions' | translate }}</th>
        <td mat-cell *matCellDef="let element" class="actions-cell">
          <span *mifosxHasPermission="'REPAYMENT_LOAN'">
            <button *ngIf="element.status.active" class="account-action-button" mat-stroked-button color="primary"
              (click)="routeEdit($event)" [routerLink]="['loans-accounts', element.id, 'actions', 'Make Repayment']">
              <i class="fa fa-dollar"></i>&nbsp;{{ 'labels.buttons.makerepayment' | translate }}
            </button>
            <span *ngIf="element.status.pendingApproval">
              <button class="account-action-button" mat-stroked-button color="primary"
                *mifosxHasPermission="'APPROVE_LOAN'" (click)="routeEdit($event)"
                [routerLink]="['loans-accounts', element.id, 'actions', 'Approve']">
                <i class="fa fa-check"></i>&nbsp;{{ 'labels.buttons.approve' | translate }}
              </button>
            </span>
            <span *ngIf="!element.status.pendingApproval && !element.status.active && !element.status.overpaid">
              <button class="account-action-button" mat-stroked-button color="primary"
                *mifosxHasPermission="'DISBURSE_LOAN'" (click)="routeEdit($event)"
                [routerLink]="['loans-accounts', element.id, 'actions', 'Disburse']">
                <i class="fa fa-flag"></i>&nbsp;{{ 'labels.buttons.disburse' | translate }}
              </button>
            </span>
            <span *ngIf="!element.status.pendingApproval && !element.status.active && element.status.overpaid">
              <button class="account-action-button" mat-stroked-button color="primary"
                *mifosxHasPermission="'DISBURSE_LOAN'" (click)="routeEdit($event); routeTransferFund(element.id)">
                <i class="fa fa-exchange"></i>&nbsp;{{ 'labels.buttons.transferFunds' | translate }}
              </button>
            </span>
          </span>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="openLoansColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: openLoansColumns;" [routerLink]="['loans-accounts', row.id]"
        class="select-row"></tr>

    </table>

    <!-- Closed Loan Accounts -->
    <table *ngIf="showClosedLoanAccounts" mat-table [dataSource]="loanAccounts|accountsFilter:'loan':'closed'">

      <ng-container matColumnDef="Account No">
        <th mat-header-cell *matHeaderCellDef> {{ 'labels.heading.accnum' | translate }} </th>
        <td mat-cell *matCellDef="let element"> <i class="fa fa-stop"
            [ngClass]="element.inArrears?'status-active-overdue':(element.status.code|statusLookup)"></i>
          {{element.accountNo}}
        </td>
      </ng-container>

      <ng-container matColumnDef="Loan Account">
        <th mat-header-cell *matHeaderCellDef> {{ 'labels.heading.loanaccount' | translate }}</th>
        <td mat-cell *matCellDef="let element"> {{element.productName}} </td>
      </ng-container>

      <ng-container matColumnDef="Original Loan">
        <th mat-header-cell *matHeaderCellDef> {{ 'labels.heading.originalLoan' | translate }} </th>
        <td mat-cell *matCellDef="let element"> {{element.lastActiveTransactionDate | dateFormat}} </td>
      </ng-container>

      <ng-container matColumnDef="Loan Balance">
        <th mat-header-cell *matHeaderCellDef> {{ 'labels.heading.loanBalance' | translate }} </th>
        <td mat-cell *matCellDef="let element"> {{element.loanBalance}} </td>
      </ng-container>

      <ng-container matColumnDef="Amount Paid">
        <th mat-header-cell *matHeaderCellDef> {{ 'labels.heading.amountPaid' | translate }} </th>
        <td mat-cell *matCellDef="let element"> {{element.accountBalance}} </td>
      </ng-container>

      <ng-container matColumnDef="Type">
        <th mat-header-cell *matHeaderCellDef> {{ 'labels.heading.type' | translate }} </th>
        <td mat-cell *matCellDef="let element">
          <i class="fa fa-large" [ngClass]="(element.loanType.value === 'Individual')?'fa-user':'fa-group'"
            matTooltip=" {{ element.loanType.value }}" matTooltipPosition="above"></i>
        </td>
      </ng-container>

      <ng-container matColumnDef="Closed Date">
        <th mat-header-cell *matHeaderCellDef> {{ 'labels.heading.closedate' | translate }} </th>
        <td mat-cell *matCellDef="let element">{{element.timeline.closedOnDate | dateFormat}}</td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="closedLoansColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: closedLoansColumns;" [routerLink]="['loans-accounts', row.id]"
        class="select-row"></tr>

    </table>

    <!-- Saving overview Table -->
    <div class="heading-content">
      <div fxLayout="column" fxFlex="50%">
        <div class="heading-name">
          <h3>{{ 'labels.buttons.savingaccounts' | translate }}</h3>
        </div>
      </div>
      <div fxLayout="column" fxFlex="50%">
        <div fxLayout="row" fxLayoutAlign="flex-end">
          <button mat-raised-button class="f-right" color="primary"
            (click)="toggleSavingAccountsOverview()">{{(showClosedSavingAccounts?'labels.buttons.viewActiveAccounts':'labels.buttons.viewClosedAccounts')
            | translate}}</button>
        </div>
      </div>
    </div>

    <table *ngIf="!showClosedSavingAccounts" mat-table
      [dataSource]="savingAccounts|accountsFilter:'saving':'open':'isSavings'">

      <ng-container matColumnDef="Account No">
        <th mat-header-cell *matHeaderCellDef> {{ 'labels.heading.accnum' | translate }} </th>
        <td mat-cell *matCellDef="let element"> <i class="fa fa-stop" [ngClass]="element.status.code|statusLookup"></i>
          {{element.accountNo}} </td>
      </ng-container>

      <ng-container matColumnDef="Saving Account">
        <th mat-header-cell *matHeaderCellDef> {{ 'labels.heading.savingaccount' | translate }} </th>
        <td mat-cell *matCellDef="let element"> {{element.productName}} </td>
      </ng-container>

      <ng-container matColumnDef="Last Active">
        <th mat-header-cell *matHeaderCellDef> {{ 'labels.heading.lastActive' | translate }} </th>
        <td mat-cell *matCellDef="let element"> {{element.lastActiveTransactionDate | dateFormat}} </td>
      </ng-container>

      <ng-container matColumnDef="Balance">
        <th mat-header-cell *matHeaderCellDef> {{ 'labels.heading.balance' | translate }} </th>
        <td mat-cell *matCellDef="let element"> {{element.accountBalance}} </td>
      </ng-container>

      <ng-container matColumnDef="Actions">
        <th mat-header-cell *matHeaderCellDef> {{ 'labels.heading.actions' | translate }} </th>
        <td mat-cell *matCellDef="let element" class="actions-cell">
          <span *mifosxHasPermission="'DEPOSIT_SAVINGSACCOUNT'">
            <button *ngIf="element.status.active" class="account-action-button" mat-stroked-button color="primary"
              (click)="routeEdit($event)" [routerLink]="['savings-accounts', element.id, 'actions', 'Deposit']">
              <i class="fa fa-arrow-up"></i>&nbsp; {{ 'labels.buttons.deposit' | translate }}
            </button>
          </span>
          <span *mifosxHasPermission="'WITHDRAW_SAVINGSACCOUNT'">
            <button *ngIf="element.status.active" class="account-action-button" mat-stroked-button color="primary"
              (click)="routeEdit($event)" [routerLink]="['savings-accounts', element.id, 'actions', 'Withdrawal']">
              <i class="fa fa-arrow-down"></i>&nbsp; {{ 'labels.buttons.withdraw' | translate }}
            </button>
          </span>
          <span *mifosxHasPermission="'APPROVE_SAVINGSACCOUNT'">
            <button *ngIf="element.status.submittedAndPendingApproval" class="account-action-button" mat-stroked-button
              color="primary" (click)="routeEdit($event)"
              [routerLink]="['savings-accounts', element.id, 'actions', 'Approve']">
              <i class="fa fa-check"></i>&nbsp; {{ 'labels.buttons.approve' | translate }}
            </button>
          </span>
          <span *mifosxHasPermission="'APPROVALUNDO_SAVINGSACCOUNT'">
            <button *ngIf="!element.status.submittedAndPendingApproval && !element.status.active"
              class="account-action-button" mat-stroked-button color="primary" (click)="routeEdit($event)"
              [routerLink]="['savings-accounts', element.id, 'actions', 'Undo Approval']">
              <i class="fa fa-undo"></i>&nbsp; {{ 'labels.buttons.undoapproval' | translate }}
            </button>
          </span>
          <span *mifosxHasPermission="'ACTIVATE_SAVINGSACCOUNT'">
            <button *ngIf="!element.status.submittedAndPendingApproval && !element.status.active"
              class="account-action-button" mat-stroked-button color="primary" (click)="routeEdit($event)"
              [routerLink]="['savings-accounts', element.id, 'actions', 'Activate']">
              <i class="fa fa-check-circle"></i>&nbsp; {{ 'labels.buttons.activate' | translate }}
            </button>
          </span>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="openSavingsColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: openSavingsColumns;"
        [routerLink]="[ 'savings-accounts', row.id, 'transactions']"></tr>

    </table>

    <!-- Closed Saving Accounts -->
    <table *ngIf="showClosedSavingAccounts" mat-table
      [dataSource]="savingAccounts|accountsFilter:'saving':'closed':'isSavings'">

      <ng-container matColumnDef="Account No">
        <th mat-header-cell *matHeaderCellDef> {{ 'labels.heading.accnum' | translate }} </th>
        <td mat-cell *matCellDef="let element"> <i class="fa fa-stop" [ngClass]="element.status.code|statusLookup"></i>
          {{element.accountNo}} </td>
      </ng-container>

      <ng-container matColumnDef="Saving Account">
        <th mat-header-cell *matHeaderCellDef> {{ 'labels.heading.savingaccount' | translate }}</th>
        <td mat-cell *matCellDef="let element"> {{element.productName}} </td>
      </ng-container>

      <ng-container matColumnDef="Closed Date">
        <th mat-header-cell *matHeaderCellDef> {{ 'labels.heading.closedate' | translate }}</th>
        <td mat-cell *matCellDef="let element">{{element.timeline.closedOnDate | dateFormat}}</td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="closedSavingsColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: closedSavingsColumns;"
        [routerLink]="[ 'savings-accounts', row.id, 'transactions']"></tr>

    </table>

    <!-- Fixed Deposit Table -->
    <div class="heading-content">
      <div fxLayout="column" fxFlex="50%">
        <div class="heading-name">
          <h3>{{ 'labels.anchors.importfdaccounts' | translate }}</h3>
        </div>
      </div>
      <div fxLayout="column" fxFlex="50%">
        <div fxLayout="row" fxLayoutAlign="flex-end">
          <button mat-raised-button class="f-right" color="primary"
            (click)="toggleFixedAccountsOverview()">{{(showClosedFixedAccounts?'labels.buttons.viewActiveAccounts':'labels.buttons.viewClosedAccounts')
            | translate}}</button>
        </div>
      </div>
    </div>

    <table *ngIf="!showClosedFixedAccounts" mat-table
      [dataSource]="savingAccounts|accountsFilter:'saving':'open':'isFixed'">

      <ng-container matColumnDef="Account No">
        <th mat-header-cell *matHeaderCellDef> {{ 'labels.heading.accnum' | translate }} </th>
        <td mat-cell *matCellDef="let element"> <i class="fa fa-stop" [ngClass]="element.status.code|statusLookup"></i>
          {{element.accountNo}} </td>
      </ng-container>

      <ng-container matColumnDef="Saving Account">
        <th mat-header-cell *matHeaderCellDef> {{ 'labels.heading.savingaccount' | translate }}</th>
        <td mat-cell *matCellDef="let element"> {{element.productName}} </td>
      </ng-container>

      <ng-container matColumnDef="Last Active">
        <th mat-header-cell *matHeaderCellDef> {{ 'labels.heading.lastActive' | translate }}</th>
        <td mat-cell *matCellDef="let element"> {{element.lastActiveTransactionDate | dateFormat}} </td>
      </ng-container>

      <ng-container matColumnDef="Balance">
        <th mat-header-cell *matHeaderCellDef> {{ 'labels.heading.balance' | translate }}</th>
        <td mat-cell *matCellDef="let element"> {{element.accountBalance}} </td>
      </ng-container>

      <ng-container matColumnDef="Actions">
        <th mat-header-cell *matHeaderCellDef> {{ 'labels.heading.actions' | translate }} </th>
        <td mat-cell *matCellDef="let element" class="actions-cell">
          <button *ngIf="element.status.submittedAndPendingApproval" class="account-action-button" mat-stroked-button
            color="primary" (click)="routeEdit($event)"
            [routerLink]="['fixed-deposits-accounts', element.id, 'actions', 'Approve']">
            <i class="fa fa-check"></i>&nbsp;{{ 'labels.buttons.approve' | translate }}
          </button>
          <button *ngIf="!element.status.submittedAndPendingApproval && !element.status.active"
            class="account-action-button" mat-stroked-button color="primary" (click)="routeEdit($event)"
            [routerLink]="['fixed-deposits-accounts', element.id, 'actions', 'Undo Approval']">
            <i class="fa fa-undo"></i>&nbsp; {{ 'labels.buttons.undoapproval' | translate }}
          </button>
          <button *ngIf="!element.status.submittedAndPendingApproval && !element.status.active"
            class="account-action-button" mat-stroked-button color="primary" (click)="routeEdit($event)"
            [routerLink]="['fixed-deposits-accounts', element.id, 'actions', 'Activate']">
            <i class="fa fa-check-circle"></i>&nbsp; {{ 'labels.buttons.activate' | translate }}
          </button>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="openSavingsColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: openSavingsColumns;"
        [routerLink]="[ 'fixed-deposits-accounts', row.id, 'interest-rate-chart']"></tr>

    </table>

    <!-- Closed Fixed Deposit Accounts -->

    <table *ngIf="showClosedFixedAccounts" mat-table
      [dataSource]="savingAccounts|accountsFilter:'saving':'closed':'isFixed'">

      <ng-container matColumnDef="Account No">
        <th mat-header-cell *matHeaderCellDef> {{ 'labels.heading.accnum' | translate }} </th>
        <td mat-cell *matCellDef="let element"> <i class="fa fa-stop" [ngClass]="element.status.code|statusLookup"></i>
          {{element.accountNo}} </td>
      </ng-container>

      <ng-container matColumnDef="Saving Account">
        <th mat-header-cell *matHeaderCellDef> {{ 'labels.heading.savingaccount' | translate }}</th>
        <td mat-cell *matCellDef="let element"> {{element.productName}} </td>
      </ng-container>

      <ng-container matColumnDef="Closed Date">
        <th mat-header-cell *matHeaderCellDef> {{ 'labels.heading.closedate' | translate }}</th>
        <td mat-cell *matCellDef="let element">{{element.timeline.closedOnDate | dateFormat}}</td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="closedSavingsColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: closedSavingsColumns;"
        [routerLink]="[ 'fixed-deposits-account', row.id]"></tr>

    </table>

    <!-- Recurring Deposit Table -->
    <div class="heading-content">
      <div fxLayout="column" fxFlex="50%">
        <div class="heading-name">
          <h3>{{ 'labels.anchors.recurringDepositAccounts' | translate }}</h3>
        </div>
      </div>
      <div fxLayout="column" fxFlex="50%">
        <div fxLayout="row" fxLayoutAlign="flex-end">
          <button mat-raised-button class="f-right" color="primary"
            (click)="toggleRecurringAccountsOverview()">{{(showClosedRecurringAccounts?'labels.buttons.viewActiveAccounts':'labels.buttons.viewClosedAccounts')
            | translate }}</button>
        </div>
      </div>
    </div>

    <table *ngIf="!showClosedRecurringAccounts" mat-table
      [dataSource]="savingAccounts|accountsFilter:'saving':'open':'isRecurring'">

      <ng-container matColumnDef="Account No">
        <th mat-header-cell *matHeaderCellDef> {{ 'labels.heading.accnum' | translate }} </th>
        <td mat-cell *matCellDef="let element"> <i class="fa fa-stop" [ngClass]="element.status.code|statusLookup"></i>
          {{element.accountNo}} </td>
      </ng-container>

      <ng-container matColumnDef="Saving Account">
        <th mat-header-cell *matHeaderCellDef> {{ 'labels.heading.savingaccount' | translate }}</th>
        <td mat-cell *matCellDef="let element"> {{element.productName}} </td>
      </ng-container>

      <ng-container matColumnDef="Last Active">
        <th mat-header-cell *matHeaderCellDef> {{ 'labels.heading.lastActive' | translate }}</th>
        <td mat-cell *matCellDef="let element"> {{element.lastActiveTransactionDate | dateFormat}} </td>
      </ng-container>

      <ng-container matColumnDef="Balance">
        <th mat-header-cell *matHeaderCellDef> {{ 'labels.heading.balance' | translate }}</th>
        <td mat-cell *matCellDef="let element"> {{element.accountBalance}} </td>
      </ng-container>

      <ng-container matColumnDef="Actions">
        <th mat-header-cell *matHeaderCellDef> {{ 'labels.heading.actions' | translate }} </th>
        <td mat-cell *matCellDef="let element" class="actions-cell">
          <ng-container *ngIf="element.status.submittedAndPendingApproval">
            <button class="account-action-button" mat-stroked-button *mifosxHasPermission="'APPROVE_SAVINGSACCOUNT'"
              [routerLink]="['recurringdeposits', element.id, 'actions', 'Approve']" color="primary"
              (click)="routeEdit($event)">
              <i class="fa fa-check"></i>&nbsp;{{ 'labels.buttons.approve' | translate }}
            </button>
          </ng-container>
          <ng-container *ngIf="!element.status.submittedAndPendingApproval && !element.status.active">
            <button class="account-action-button" mat-stroked-button color="primary" (click)="routeEdit($event)"
              *mifosxHasPermission="'APPROVALUNDO_SAVINGSACCOUNT'"
              [routerLink]="['recurringdeposits', element.id, 'actions', 'Undo Approval']">
              <i class="fa fa-undo"></i>&nbsp; {{ 'labels.buttons.undoapproval' | translate }}
            </button>
            <button class="account-action-button" mat-stroked-button color="primary" (click)="routeEdit($event)"
              *mifosxHasPermission="'ACTIVATE_SAVINGSACCOUNT'"
              [routerLink]="['recurringdeposits', element.id, 'actions', 'Activate']">
              <i class="fa fa-check-circle"></i>&nbsp; {{ 'labels.buttons.activate' | translate }}
            </button>
          </ng-container>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="openSavingsColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: openSavingsColumns;"
        [routerLink]="[ 'recurringdeposits', row.id, 'interest-rate-chart']" class="select-row"></tr>

    </table>

    <!-- Closed Recurring Deposit Accounts -->
    <table *ngIf="showClosedRecurringAccounts" mat-table
      [dataSource]="savingAccounts|accountsFilter:'saving':'closed':'isRecurring'">

      <ng-container matColumnDef="Account No">
        <th mat-header-cell *matHeaderCellDef> {{ 'labels.heading.accnum' | translate }} </th>
        <td mat-cell *matCellDef="let element"> <i class="fa fa-stop" [ngClass]="element.status.code|statusLookup"></i>
          {{element.accountNo}} </td>
      </ng-container>

      <ng-container matColumnDef="Saving Account">
        <th mat-header-cell *matHeaderCellDef> {{ 'labels.heading.savingaccount' | translate }}</th>
        <td mat-cell *matCellDef="let element"> {{element.productName}} </td>
      </ng-container>

      <ng-container matColumnDef="Closed Date">
        <th mat-header-cell *matHeaderCellDef> {{ 'labels.heading.closedate' | translate }}</th>
        <td mat-cell *matCellDef="let element">{{element.timeline.closedOnDate | dateFormat}}</td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="closedSavingsColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: closedSavingsColumns;"
        [routerLink]="[ 'recurringdeposits', row.id, 'interest-rate-chart']" class="select-row"></tr>
    </table>

    <!-- Shares overview Table -->
    <div class="heading-content">
      <div fxLayout="column" fxFlex="50%">
        <div class="heading-name">
          <h3>{{ 'labels.heading.sharesAccounts' | translate }}</h3>
        </div>
      </div>
      <div fxLayout="column" fxFlex="50%">
        <div fxLayout="row" fxLayoutAlign="flex-end">
          <button mat-raised-button class="f-right" color="primary"
            (click)="toggleShareAccountsOverview()">{{(showClosedSavingAccounts?'labels.buttons.viewActiveAccounts':'labels.buttons.viewClosedAccounts')
            | translate}}</button>
        </div>
      </div>
    </div>

    <table *ngIf="!showClosedShareAccounts" mat-table [dataSource]="shareAccounts|accountsFilter:'share'">

      <ng-container matColumnDef="Account No">
        <th mat-header-cell *matHeaderCellDef> {{ 'labels.heading.accnum' | translate }} </th>
        <td mat-cell *matCellDef="let element"> <i class="fa fa-stop" [ngClass]="element.status.code|statusLookup"></i>
          {{element.accountNo}} </td>
      </ng-container>

      <ng-container matColumnDef="Share Account">
        <th mat-header-cell *matHeaderCellDef> {{ 'labels.anchors.shareAccount' | translate }}</th>
        <td mat-cell *matCellDef="let element"> {{element.productName}} </td>
      </ng-container>

      <ng-container matColumnDef="Approved Shares">
        <th mat-header-cell *matHeaderCellDef> {{ 'labels.heading.approvedshares' | translate }}</th>
        <td mat-cell *matCellDef="let element"> {{element.totalApprovedShares}} </td>
      </ng-container>

      <ng-container matColumnDef="Pending For Approval Shares">
        <th mat-header-cell *matHeaderCellDef> {{ 'labels.heading.pendingforapproval' | translate }} </th>
        <td mat-cell *matCellDef="let element"> {{element.totalPendingForApprovalShares}} </td>
      </ng-container>

      <ng-container matColumnDef="Actions">
        <th mat-header-cell *matHeaderCellDef> {{ 'labels.heading.actions' | translate }} </th>
        <td mat-cell *matCellDef="let element" class="actions-cell">
          <button *ngIf="element.status.submittedAndPendingApproval" class="account-action-button" mat-stroked-button
            color="primary" (click)="routeEdit($event)"
            [routerLink]="['shares-accounts', element.id, 'actions', 'Approve']">
            <i class="fa fa-check"></i>&nbsp; {{ 'labels.buttons.approve' | translate }}
          </button>
          <button *ngIf="!element.status.submittedAndPendingApproval && !element.status.active"
            class="account-action-button" mat-stroked-button color="primary" (click)="routeEdit($event)"
            [routerLink]="['shares-accounts', element.id, 'actions', 'Undo Approval']">
            <i class="fa fa-undo"></i>&nbsp; {{ 'labels.buttons.undoapproval' | translate }}
          </button>
          <button *ngIf="!element.status.submittedAndPendingApproval && !element.status.active"
            class="account-action-button" mat-stroked-button color="primary" (click)="routeEdit($event)"
            [routerLink]="['shares-accounts', element.id, 'actions', 'Activate']">
            <i class="fa fa-check-circle"></i>&nbsp; {{ 'labels.buttons.activate' | translate }}
          </button>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="openSharesColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: openSharesColumns;"
        [routerLink]="[ 'shares-accounts', row.id, 'transactions']"></tr>

    </table>

    <!-- Closed Share Accounts -->
    <table *ngIf="showClosedShareAccounts" mat-table [dataSource]="shareAccounts|accountsFilter:'share':'closed'">

      <ng-container matColumnDef="Account No">
        <th mat-header-cell *matHeaderCellDef> {{ 'labels.heading.accnum' | translate }} </th>
        <td mat-cell *matCellDef="let element"> <i class="fa fa-stop" [ngClass]="element.status.code|statusLookup"></i>
          {{element.accountNo}} </td>
      </ng-container>

      <ng-container matColumnDef="Share Account">
        <th mat-header-cell *matHeaderCellDef> {{ 'labels.anchors.shareAccount' | translate }}</th>
        <td mat-cell *matCellDef="let element"> {{element.productName}} </td>
      </ng-container>

      <ng-container matColumnDef="Approved Shares">
        <th mat-header-cell *matHeaderCellDef> {{ 'labels.heading.approvedshares' | translate }}</th>
        <td mat-cell *matCellDef="let element"> {{element.totalApprovedShares}} </td>
      </ng-container>

      <ng-container matColumnDef="Pending For Approval Shares">
        <th mat-header-cell *matHeaderCellDef> {{ 'labels.heading.pendingforapproval' | translate }} </th>
        <td mat-cell *matCellDef="let element"> {{element.totalPendingForApprovalShares}} </td>
      </ng-container>

      <ng-container matColumnDef="Closed Date">
        <th mat-header-cell *matHeaderCellDef> {{ 'labels.heading.closedate' | translate }}</th>
        <td mat-cell *matCellDef="let element">{{element.timeline.closedOnDate | dateFormat}}</td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="closedSharesColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: closedSharesColumns;"
        [routerLink]="[ 'shares-accounts', row.id, 'transactions']"></tr>

    </table>
    <div *mifosxHasPermission="'READ_COLLATERAL'">
      <!-- Collateral Data Table -->
      <div class="heading-content">
        <div fxLayout="column" fxFlex="50%">
          <div class="heading-name">
            <h3>{{'labels.heading.collateralData' | translate }}</h3>
          </div>
        </div>
        <div fxLayout="column" fxFlex="50%">
          <div fxLayout="row" fxLayoutAlign="flex-end">
            <button mat-raised-button class="f-right" color="primary" disabled>
              {{'labels.buttons.viewcollaterals' | translate}}
            </button>
          </div>
        </div>
      </div>

      <table mat-table [dataSource]="collaterals" class="exception">

        <ng-container matColumnDef="ID">
          <th mat-header-cell *matHeaderCellDef>{{'labels.heading.id' | translate}}</th>
          <td mat-cell *matCellDef="let element">{{ element.collateralId }}</td>
        </ng-container>

        <ng-container matColumnDef="Name">
          <th mat-header-cell *matHeaderCellDef>{{'labels.heading.name' | translate}}</th>
          <td mat-cell *matCellDef="let element">{{ element.name }}</td>
        </ng-container>

        <ng-container matColumnDef="Quantity">
          <th mat-header-cell *matHeaderCellDef>{{'labels.heading.quantity' | translate}}</th>
          <td mat-cell *matCellDef="let element">{{ element.quantity }}</td>
        </ng-container>

        <ng-container matColumnDef="Total Value">
          <th mat-header-cell *matHeaderCellDef>{{'labels.heading.totalValue' | translate}}</th>
          <td mat-cell *matCellDef="let element">{{ element.basePrice*element.quantity }}</td>
        </ng-container>

        <ng-container matColumnDef="Total Collateral Value">
          <th mat-header-cell *matHeaderCellDef>{{'labels.heading.totalCollateralValue' | translate}}</th>
          <td mat-cell *matCellDef="let element">{{ element.pctToBase*element.basePrice*element.quantity/100 }}</td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="collateralsColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: collateralsColumns;"
          [routerLink]="[ 'client-collateral', row.collateralId]"></tr>

      </table>
    </div>
    <div>
      <!-- Credit Lines Data Table -->
      <div class="heading-content">
        <div fxLayout="column" fxFlex="50%">
          <div class="heading-name">
            <h3>{{'labels.creditLines' | translate }}</h3>
          </div>
        </div>
        <div fxLayout="column" fxFlex="50%">
          <div fxLayout="row" fxLayoutAlign="flex-end">
            <mat-button-toggle-group [formControl]="viewGroup">
              <mat-button-toggle value="listView">
                <fa-icon icon="list"></fa-icon>
              </mat-button-toggle>
              <mat-button-toggle value="treeView">
                <fa-icon icon="sitemap"></fa-icon>
              </mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
      </div>

      <!-- <table mat-table [dataSource]="creditLines">

        <ng-container matColumnDef="id">
          <th mat-header-cell *matHeaderCellDef>{{'labels.heading.id' | translate}}</th>
          <td mat-cell *matCellDef="let element">
            <i class="fa fa-stop" [ngClass]="element.status.value | statusLookup"
              [matTooltip]="element.status.value | translate"></i>
            {{ element?.id }}
          </td>
        </ng-container>

        <ng-container matColumnDef="productLine">
          <th mat-header-cell *matHeaderCellDef>{{'labels.productLine' | translate}}</th>
          <td mat-cell *matCellDef="let element">{{ element?.productLine.name }}</td>
        </ng-container>

        <ng-container matColumnDef="limitAmount">
          <th mat-header-cell *matHeaderCellDef>{{'labels.limitAmount' | translate}}</th>
          <td mat-cell *matCellDef="let element">{{ element.limitAmount }}</td>
        </ng-container>

        <ng-container matColumnDef="remainAmount">
          <th mat-header-cell *matHeaderCellDef>{{'labels.remainAmount' | translate}}</th>
          <td mat-cell *matCellDef="let element">{{ element.remainAmount }}</td>
        </ng-container>

        <ng-container matColumnDef="processingEndDate">
          <th mat-header-cell *matHeaderCellDef>{{'labels.processingEndDate' | translate}}</th>
          <td mat-cell *matCellDef="let element">{{ element?.processingEndDate | dateFormat}}</td>
        </ng-container>

        <ng-container matColumnDef="validityEndDate">
          <th mat-header-cell *matHeaderCellDef>{{'labels.validityEndDate' | translate}}</th>
          <td mat-cell *matCellDef="let element">{{ element?.validityEndDate | dateFormat}}</td>
        </ng-container>

        <ng-container matColumnDef="actions">
          <th mat-header-cell *matHeaderCellDef>{{'labels.inputs.actions' | translate}}</th>
          <td mat-cell *matCellDef="let element">
            <ng-container *ngFor="let action of creditLinebuttons">
              <ng-container *mifosxHasPermission="action.permission">
                <button class="account-action-button" mat-stroked-button color="primary"
                  *ngIf="actionCreditLine(action.action,element.status.code)"
                  [routerLink]="['client-credit-line', element.id, action.action]" (click)="routeEdit($event)">
                  <i [class]="action.icon"></i>&nbsp;{{ action.name | translate }}
                </button>
              </ng-container>
            </ng-container>

          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="creditLinesColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: creditLinesColumns;" [routerLink]="[ 'client-credit-line', row.id]">
        </tr>

      </table> -->





























      <div [hidden]="viewGroup.value !== 'listView'">


        <table mat-table [dataSource]="creditLines">

          <ng-container matColumnDef="id">
            <th mat-header-cell *matHeaderCellDef>{{'labels.heading.id' | translate}}</th>
            <td mat-cell *matCellDef="let element">
              <i class="fa fa-stop" [ngClass]="element.status.value | statusLookup"
                [matTooltip]="element.status.value | translate"></i>
              {{ element?.id }}
            </td>
          </ng-container>

          <ng-container matColumnDef="productLine">
            <th mat-header-cell *matHeaderCellDef>{{'labels.productLine' | translate}}</th>
            <td mat-cell *matCellDef="let element">{{ element?.productLine.name }}</td>
          </ng-container>

          <ng-container matColumnDef="limitAmount">
            <th mat-header-cell *matHeaderCellDef>{{'labels.limitAmount' | translate}}</th>
            <td mat-cell *matCellDef="let element">{{ element.limitAmount }}</td>
          </ng-container>

          <ng-container matColumnDef="remainAmount">
            <th mat-header-cell *matHeaderCellDef>{{'labels.remainAmount' | translate}}</th>
            <td mat-cell *matCellDef="let element">{{ element.remainAmount }}</td>
          </ng-container>

          <ng-container matColumnDef="processingEndDate">
            <th mat-header-cell *matHeaderCellDef>{{'labels.processingEndDate' | translate}}</th>
            <td mat-cell *matCellDef="let element">{{ element?.processingEndDate | dateFormat}}</td>
          </ng-container>

          <ng-container matColumnDef="validityEndDate">
            <th mat-header-cell *matHeaderCellDef>{{'labels.validityEndDate' | translate}}</th>
            <td mat-cell *matCellDef="let element">{{ element?.validityEndDate | dateFormat}}</td>
          </ng-container>

          <ng-container matColumnDef="isUnique">
            <th mat-header-cell *matHeaderCellDef>{{'labels.isUnique' | translate}}</th>
            <td mat-cell *matCellDef="let element">
              {{ element?.productLine?.unique ? ('labels.inputs.true' | translate) :'labels.inputs.false' | translate }}
            </td>
          </ng-container>

          <ng-container matColumnDef="revolving">
            <th mat-header-cell *matHeaderCellDef>{{'labels.revolving' | translate}}</th>
            <td mat-cell *matCellDef="let element">
              {{ productLine?.revolving ? ('labels.inputs.true' | translate) :'labels.inputs.false' | translate }}
            </td>
          </ng-container>

          <ng-container matColumnDef="actions">
            <th mat-header-cell *matHeaderCellDef>{{'labels.inputs.actions' | translate}}</th>
            <td mat-cell *matCellDef="let element" class="actions-cell">
              <ng-container *ngFor="let action of creditLinebuttons">
                <ng-container>
                  <!-- <ng-container *mifosxHasPermission="action.permission"> -->
                  <button class="account-action-button" mat-stroked-button color="primary"
                    *ngIf="actionCreditLine(action.action,element.status.code)"
                    [routerLink]="['client-credit-line', element.id, action.action]" (click)="routeEdit($event)">
                    <i [class]="action.icon"></i>&nbsp;{{ action.name | translate }}
                  </button>
                </ng-container>
              </ng-container>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="creditLinesColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: creditLinesColumns;" [routerLink]="[ 'client-credit-line', row.id]">
          </tr>

        </table>


      </div>


      <!-- Tree View -->

      <div class="tree" [hidden]="viewGroup.value !== 'treeView'">

        <div class="m-b-20" fxLayout="row" fxLayoutAlign="end" fxLayoutGap="20px">
          <button mat-raised-button (click)="nestedTreeControl.expandAll()">
            {{"labels.buttons.expand.all" | translate}}
          </button>
          <button mat-raised-button (click)="nestedTreeControl.collapseAll()">
            {{"labels.buttons.collapse.all" | translate}}
          </button>
        </div>

        <div fxLayout="row" fxLayoutGap="2%" fxLayout.lt-md="column">
          <div fxFlex>

            <mat-tree [dataSource]="nestedTreeDataSource" [treeControl]="nestedTreeControl" class="cl-account-tree">

              <mat-tree-node *matTreeNodeDef="let node" matTreeNodeToggle>
                <li class="mat-tree-node">
                  <button mat-icon-button disabled></button>
                  <span (click)="viewLcChildren(node)" [ngClass]="creditLine?.id===node?.id && 'cl-selected'">
                    {{ node.id }}-{{node.productLine.name}} ({{node.limitAmount}} {{node.currency.code}})
                  </span>
                </li>
              </mat-tree-node>

              <mat-nested-tree-node *matTreeNodeDef="let node; when: hasNestedChild">
                <li>
                  <div class="mat-tree-node">
                    <button mat-icon-button matTreeNodeToggle [attr.aria-label]="'toggle ' + node.id">
                      <fa-icon class="mat-icon-rtl-mirror"
                        icon="{{ nestedTreeControl.isExpanded(node) ? 'chevron-down' : 'chevron-right' }}"></fa-icon>
                    </button>
                    <span (click)="viewLcChildren(node)" [ngClass]="productLine?.id===node?.id && 'cl-selected'">
                      {{ node.id }}-{{node.productLine.name}} ({{node.limitAmount}} {{node.currency.code}})
                    </span>
                  </div>
                  <ul [class.cl-account-tree-invisible]="!nestedTreeControl.isExpanded(node)">
                    <ng-container matTreeNodeOutlet></ng-container>
                  </ul>
                </li>
              </mat-nested-tree-node>

            </mat-tree>

          </div>
          <div fxFlex="40%" *ngIf="creditLine">

            <mat-card>

              <mat-card-content>

                <div fxLayout="row wrap" class="content node-card">

                  <div fxFlex="50%" class="header">
                    {{"labels.heading.id" | translate}}
                  </div>

                  <div fxFlex="50%">
                    {{ creditLine?.id }}
                  </div>

                  <div fxFlex="50%" class="header">
                    {{"labels.productLine" | translate}}
                  </div>

                  <div fxFlex="50%">
                    {{ creditLine?.productLine.name }}
                  </div>

                  <div fxFlex="50%" class="header">
                    {{"labels.limitAmount" | translate}}
                  </div>

                  <div fxFlex="50%">
                    {{ creditLine?.limitAmount }} {{creditLine?.currency.code}}
                  </div>

                  <div fxFlex="50%" class="header">
                    {{"labels.remainAmount" | translate}}
                  </div>

                  <div fxFlex="50%">
                    {{ creditLine?.remainAmount }} {{creditLine?.currency.code}}
                  </div>

                  <div fxFlex="50%" class="header">
                    {{"labels.processingEndDate" | translate}}
                  </div>

                  <div fxFlex="50%">
                    {{ creditLine?.processingEndDate | dateFormat}}
                  </div>

                  <div fxFlex="50%" class="header">
                    {{"labels.validityEndDate" | translate}}
                  </div>

                  <div fxFlex="50%">
                    {{ creditLine?.validityEndDate | dateFormat }}
                  </div>



                  <div fxFlex="50%" class="header">
                    {{"labels.isUnique" | translate}}
                  </div>

                  <div fxFlex="50%">
                    {{ productLine?.unique ? ('labels.inputs.true' | translate) :'labels.inputs.false' | translate }}
                  </div>

                  <div fxFlex="50%" class="header">
                    {{'labels.revolving' | translate}}
                  </div>

                  <div fxFlex="50%">
                    {{ productLine?.revolving ? ('labels.inputs.true' | translate) :'labels.inputs.false' | translate }}
                  </div>


                </div>

              </mat-card-content>

            </mat-card>

          </div>
        </div>

      </div>

    </div>
  </div>
</div>
