import { ConfigurationWizardComponent } from './../../../configuration-wizard/configuration-wizard.component';
import { MatDialog } from '@angular/material/dialog';
import { PopoverService } from './../../../configuration-wizard/popover/popover.service';
import { ConfigurationWizardService } from './../../../configuration-wizard/configuration-wizard.service';
import { Component, OnInit, HostBinding, Input, } from '@angular/core';
import { Router } from '@angular/router';
import { NavigationService } from '../../../navigation/navigation.service';
import { animate, state, style, transition, trigger } from '@angular/animations';

export interface NavItem {
  displayName: string;
  disabled?: boolean;
  iconName: string;
  route?: string;
  wizard?:boolean,
  children?: NavItem[];
  permission?: boolean;
}

@Component({
  selector: 'app-menu-list-item',
  templateUrl: './menu-list-item.component.html',
  styleUrls: ['./menu-list-item.component.scss'],
  animations: [
    trigger('indicatorRotate', [
      state('collapsed', style({ transform: 'rotate(0deg)' })),
      state('expanded', style({ transform: 'rotate(0deg)' })),
      transition('expanded <=> collapsed',
        animate('225ms cubic-bezier(0.4,0.0,0.2,1)')
      ),
    ])
  ]
})
export class MenuListItemComponent implements OnInit {
  expanded: boolean = false;
  @HostBinding('attr.aria-expanded') ariaExpanded = this.expanded;
  @Input() item: NavItem;
  @Input() depth: number;
  @Input() child?: boolean;

  constructor(public navService: NavigationService,
    public dialog: MatDialog,
    private configurationWizardService: ConfigurationWizardService,
    private popoverService: PopoverService,
    public router: Router) {
    if (this.depth === undefined) {
      this.depth = 0;
    }
  }
  openDialog() {
    const configWizardRef = this.dialog.open(ConfigurationWizardComponent, {});
    configWizardRef.afterClosed().subscribe((response: { show: number }) => {
      if (response.show === 1) {
        this.configurationWizardService.showToolbar = true;
        this.router.routeReuseStrategy.shouldReuseRoute = () => false;
        this.router.onSameUrlNavigation = 'reload';
        this.router.navigate(['/home']);
      }
      if (response.show === 2) {
        this.configurationWizardService.showCreateOffice = true;
        this.router.navigate(['/organization']);
      }
      if (response.show === 3) {
        this.configurationWizardService.showDatatables = true;
        this.router.navigate(['/system']);
      }
      if (response.show === 4) {
        this.configurationWizardService.showChartofAccounts = true;
        this.router.navigate(['/accounting']);
      }
      if (response.show === 5) {
        this.configurationWizardService.showCharges = true;
        this.router.navigate(['/products']);
      }
      if (response.show === 6) {
        this.configurationWizardService.showManageFunds = true;
        this.router.navigate(['/organization']);
      }
      if (response.show === 0) {

      }
    });
  }

  ngOnInit(): void {
    this.navService.currentUrl.subscribe((url: string) => {
      if (this.item.route && url) {
        // console.log(`Checking '/${this.item.route}' against '${url}'`);
        this.expanded = url.indexOf(`/${this.item.route}`) === 0;
        this.ariaExpanded = this.expanded;
        // console.log(`${this.item.route} is expanded: ${this.expanded}`);
      }
    });
  }

  onItemSelected(item: NavItem) {
    if(item.wizard){
      this.openDialog();
    }else{
      if (!item.children || !item.children.length) {
      this.router.navigate([item.route]);
      this.navService.closeNav();
    }
    if (item.children && item.children.length) {
      this.expanded = !this.expanded;
    }
  }
  }
}
