import { WhatsappDialogComponent } from './../whatsapp-dialog/whatsapp-dialog.component';
import {Component, Input} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';

@Component({
  selector: 'mifosx-whatsapp-button',
  templateUrl: './whatsapp-button.component.html',
  styleUrls: ['./whatsapp-button.component.scss']
})
export class WhatsappButtonComponent {

  @Input() phoneNumber: string;
  @Input() text: Boolean;

  constructor(public dialog: MatDialog) {}

  openDialog(): void {
    const dialogRef = this.dialog.open(WhatsappDialogComponent, {
      width: '345px',
      data: {phoneNumber: this.phoneNumber},
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed',result);

    });
  }
}
