<div class="container">

  <mat-card>

    <form [formGroup]="financialActivityMappingForm" (ngSubmit)="submit()">

      <mat-card-content>

        <div fxLayout="column">

          <mat-form-field>
            <mat-label>{{"labels.heading.financialactivity" | translate}}</mat-label>
            <mat-select required formControlName="financialActivityId">
              <mat-option *ngFor="let financialActivity of financialActivityData" [value]="financialActivity.id">
                {{ financialActivity.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field>
            <mat-label>{{"labels.inputs.account" | translate}}</mat-label>
            <mat-select required formControlName="glAccountId">
              <mat-option *ngFor="let glAccount of glAccountData" [value]="glAccount.id">
                {{ glAccount.name + ' (' + glAccount.glCode + ')' }}
              </mat-option>
            </mat-select>
          </mat-form-field>

        </div>

      </mat-card-content>

      <mat-card-actions fxLayout="row" fxLayout.xs="column" fxLayoutAlign="center" fxLayoutGap="5px">
        <button type="button" mat-raised-button [routerLink]="['../']">{{"labels.buttons.cancel" | translate}}</button>
        <button mat-raised-button color="primary" [disabled]="financialActivityMappingForm.pristine" *mifosxHasPermission="'UPDATE_FINANCIALACTIVITYACCOUNT'">{{"labels.buttons.submit" | translate}}</button>
      </mat-card-actions>

    </form>

  </mat-card>

</div>
