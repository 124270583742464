<div class="tab-container mat-typography">
  <h3>{{datatableName}}</h3>
  <div fxLayout="row" fxLayoutAlign="flex-end">
    <button mat-raised-button color="primary" (click)="add()" *ngIf="!dataObject.data[0]">
      <fa-icon icon="plus"></fa-icon>&nbsp;&nbsp;{{ 'labels.buttons.add' | translate }}
    </button>
    <button mat-raised-button color="primary" (click)="edit()" *ngIf="dataObject.data[0]">
      <fa-icon icon="edit"></fa-icon>&nbsp;&nbsp;{{ 'labels.buttons.edit' | translate }}
    </button>
    <button class="delete-button" mat-raised-button color="warn" (click)="delete()" *ngIf="dataObject.data[0]">
      <fa-icon icon="trash"></fa-icon>&nbsp;&nbsp;{{ 'labels.buttons.deleteall' | translate }}
    </button>
  </div>
  <ng-container>

    <div *ngFor="let columnHeader of dataObject.columnHeaders;let i = index" class="table-data">

      <div fxFlex="45%" class="mat-body-strong left">
        {{columnHeader.columnName}}
      </div>

      <div fxFlex="45%" [ngSwitch]="columnHeader.columnDisplayType" class="right">
        <span *ngSwitchCase="'DATE'">
          {{dataObject.data[0]?.row[i] | dateFormat}}
        </span>
        <span *ngSwitchCase="'DATETIME'">
          {{dataObject.data[0]?.row[i] | datetimeFormat}}
        </span>
        <span *ngSwitchCase="'INTEGER' || 'DECIMAL'">
          {{dataObject?.data[0]?.row[i] | number}}
        </span>
        <span *ngSwitchDefault>
          {{dataObject.data[0]?.row[i]}}
        </span>
      </div>
    </div>
  </ng-container>

</div>
