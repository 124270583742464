import { WebViewsLoanTransactionsComponent } from './web-views/web-views-loan-transactions/web-views-loan-transactions.component';
import { WebViewsLoanRepaymentScheduleComponent } from './web-views/web-views-loan-repayment-schedule/web-views-loan-repayment-schedule.component';
import { WebViewsLoanChargesComponent } from './web-views/web-views-loan-charges/web-views-loan-charges.component';
import { WebViewsLoanSummaryComponent } from './web-views/web-views-loan-summary/web-views-loan-summary.component';
import { LoanDetailsResolver } from './../loans/common-resolvers/loan-details.resolver';
import { WebViewMakeRepaymentComponent } from './web-views/web-view-make-repayment/web-view-make-repayment.component';
import { ClientAccountsResolver } from './../clients/common-resolvers/client-accounts.resolver';
import { ClientViewResolver } from './../clients/common-resolvers/client-view.resolver';
import { ErrorPageComponent } from './web-views/error-page/error-page.component';
import { WebViewCreateLoanComponent } from './web-views/web-view-create-loan/web-view-create-loan.component';
/** Angular Imports */
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

/** Transalation Imports */
import { extract } from '../core/i18n/i18n.service';

/** Custom Components */
import { LoginComponent } from './login.component';
import { LoansAccountTemplateResolver } from './web-views/web-view-create-loan/loans-account-template.resolver';
import { WebViewDashboardLoanComponent } from './web-views/web-view-dashboard-loan/web-view-dashboard-loan.component';
import { WebViewsLoanDetailsComponent } from './web-views/web-views-loan-details/web-views-loan-details.component';
import { ClientCreditLineResolver } from 'app/clients/common-resolvers/client-credit-line.resolver';
import { WebViewActiveLoansComponent } from './web-views/web-view-dashboard-loan/web-view-active-loans/web-view-active-loans.component';
import { WebViewHistoryComponent } from './web-views/web-view-dashboard-loan/web-view-history/web-view-history.component';
import { WebViewProductsComponent } from './web-views/web-view-dashboard-loan/web-view-products/web-view-products.component';
import { LoanProductsResolver } from 'app/accounting/common-resolvers/loan-products.resolver';

/** Login Routes */
const routes: Routes = [
  {
    path: 'login',
    component: LoginComponent,
    data: { title: extract('Login') }
  },
  {
    path: 'webview/:clientId/loan/create',
    component: WebViewCreateLoanComponent,
    data: { title: extract('labels.heading.loan') },
    resolve: {
      loansAccountTemplate: LoansAccountTemplateResolver,
      clientCreditLine: ClientCreditLineResolver,
      loanProducts: LoanProductsResolver,
    }
  },
  {
    path: 'webview/:clientId/loan',
    component: WebViewDashboardLoanComponent,
    data: { title: extract('labels.heading.loan') },
    children: [
      {
        path: 'dashboard',
        component: WebViewActiveLoansComponent,
      },
      {
        path: 'history',
        component: WebViewHistoryComponent,
        resolve: {
          accounts: ClientAccountsResolver
        }
      },
      {
        path: 'products',
        component: WebViewProductsComponent,
        resolve: {
          loanProducts: LoanProductsResolver,
          creditLine: ClientCreditLineResolver
        }
      },
      {
        path : "",
        redirectTo :"dashboard",
        pathMatch: "prefix"
      }
    ]
  },
  {
    path: 'webview/:clientId/loan/:loanId',
    component: WebViewsLoanDetailsComponent,
    data: { title: extract('labels.heading.loan') },
    resolve: {
      loanDetailsData: LoanDetailsResolver
    },
  },
  {
    path: 'webview/:clientId/loan/:loanId/summary',
    component: WebViewsLoanSummaryComponent,
    data: { title: extract('labels.heading.loan') },
    resolve: {
      loanDetailsData: LoanDetailsResolver
    }
  },
  {
    path: 'webview/:clientId/loan/:loanId/charges',
    component: WebViewsLoanChargesComponent,
    data: { title: extract('labels.heading.loan') },
    resolve: {
      loanDetailsData: LoanDetailsResolver
    }
  },
  {
    path: 'webview/:clientId/loan/:loanId/repayment_schedule',
    component: WebViewsLoanRepaymentScheduleComponent,
    data: { title: extract('labels.heading.loan') },
    resolve: {
      loanDetailsData: LoanDetailsResolver
    }
  },
  {
    path: 'webview/:clientId/loan/:loanId/transactions',
    component: WebViewsLoanTransactionsComponent,
    data: { title: extract('labels.heading.loan') },
    resolve: {
      loanDetailsData: LoanDetailsResolver
    }
  },
  {
    path: 'webview/:clientId/loan/:loanId/make_repayment',
    component: WebViewMakeRepaymentComponent,
    data: { title: extract('labels.heading.loan') },
    resolve: {
      loanDetailsData: LoanDetailsResolver
    }
  },
  {
    path: 'webview/error',
    component: ErrorPageComponent,
    data: { title: extract('labels.inputs.error') },
  }
];

/**
 * Login Routing Module
 *
 * Configures the login routes.
 */
@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: [LoansAccountTemplateResolver,
    LoanDetailsResolver,
    LoanProductsResolver,
    ClientAccountsResolver]
})
export class LoginRoutingModule { }
