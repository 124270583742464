import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { SystemService } from 'app/system/system.service';

@Component({
  selector: 'mifosx-credit-bureau-configuration',
  templateUrl: './credit-bureau-configuration.component.html',
  styleUrls: ['./credit-bureau-configuration.component.scss']
})
export class CreditBureauConfigurationComponent implements OnInit {

  creditBureauForm: FormGroup;
  CreditBureauConfigurations :any= [];


  /**
   * @param {ActivatedRoute} route ActivatedRoute.
   * @param {Router} router Router.
   * @param {ConfigurationWizardService} configurationWizardService ConfigurationWizard Service.
   * @param {PopoverService} popoverService PopoverService.
   */
  constructor(private route: ActivatedRoute,
    private router: Router,
    private systemService: SystemService,
    private formBuilder: FormBuilder) {
    this.route.data.subscribe((data: { creditBureauTemplate: any}) => {
      this.CreditBureauConfigurations = data.creditBureauTemplate;
    });
    this.creditBureauForm = this.formBuilder.group({
      'creditBureauId': [ undefined, Validators.required ]
    });
  }

  ngOnInit(): void {
  }

  submit(){
    const creditBureauId = this.creditBureauForm.value.creditBureauId
      this.router.navigate(['../','editconfiguration',creditBureauId], {relativeTo: this.route});

  }

}
