import { HomeService } from './../../../home/home.service';
import { UploadImageDialogComponent } from './../custom-dialogs/upload-image-dialog/upload-image-dialog.component';
import { CaptureImageDialogComponent } from './../custom-dialogs/capture-image-dialog/capture-image-dialog.component';
import { DeleteSignatureDialogComponent } from './../custom-dialogs/delete-signature-dialog/delete-signature-dialog.component';
import { UploadSignatureDialogComponent } from './../custom-dialogs/upload-signature-dialog/upload-signature-dialog.component';
import { ViewSignatureDialogComponent } from './../custom-dialogs/view-signature-dialog/view-signature-dialog.component';
import { UnassignStaffDialogComponent } from './../custom-dialogs/unassign-staff-dialog/unassign-staff-dialog.component';
import { DeleteDialogComponent } from 'app/shared/delete-dialog/delete-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
/** Angular Imports */
import { Component, ElementRef, OnInit, ViewChild, Output } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

/** Custom Services. */
import { ClientsService } from 'app/clients/clients.service';
import { CreditLineActionsButtons } from 'app/credit-lines/credit-line-actions-buttons';
import { MatTreeNestedDataSource } from '@angular/material/tree';
import { NestedTreeControl } from '@angular/cdk/tree';
import { MatTableDataSource } from '@angular/material/table';
import { FormControl } from '@angular/forms';
import { of } from 'rxjs';



/**
 * General Tab component.
 */
@Component({
  selector: 'mifosx-general-tab',
  templateUrl: './general-tab.component.html',
  styleUrls: ['./general-tab.component.scss']
})
export class GeneralTabComponent implements OnInit {

  /** Open Loan Accounts Columns */
  openLoansColumns: string[] = ['Account No', 'Loan Account', 'Original Loan', 'Loan Balance', 'Amount Paid', 'Type', 'Actions'];
  /** Closed Loan Accounts Columns */
  closedLoansColumns: string[] = ['Account No', 'Loan Account', 'Original Loan', 'Loan Balance', 'Amount Paid', 'Type', 'Closed Date'];
  /** Open Savings Accounts Columns */
  openSavingsColumns: string[] = ['Account No', 'Saving Account', 'Last Active', 'Balance', 'Actions'];
  /** Closed Savings Accounts Columns */
  closedSavingsColumns: string[] = ['Account No', 'Saving Account', 'Closed Date'];
  /** Open Shares Accounts Columns */
  openSharesColumns: string[] = ['Account No', 'Share Account', 'Approved Shares', 'Pending For Approval Shares', 'Actions'];
  /** Closed Shares Accounts Columns */
  closedSharesColumns: string[] = ['Account No', 'Share Account', 'Approved Shares', 'Pending For Approval Shares', 'Closed Date'];
  /** Upcoming Charges Columns */
  upcomingChargesColumns: string[] = ['Name', 'Due as of', 'Due', 'Paid', 'Waived', 'Outstanding', 'Actions'];
  /** Collaterals Column */
  collateralsColumns: string[] = ['ID', 'Name', 'Quantity', 'Total Value', 'Total Collateral Value'];

  creditLinesColumns: string[] = ['id', 'productLine', 'limitAmount', 'remainAmount', 'processingEndDate', 'validityEndDate','isUnique', 'actions'];

  /** Client Account Data */
  clientAccountData: any;
  /** Loan Accounts Data */
  loanAccounts: any;
  /** Savings Accounts Data */
  savingAccounts: any;
  /** Shares Accounts Data */
  shareAccounts: any;
  /** Upcoming Charges Data */
  upcomingCharges: any;
  /** Client Summary Data */
  clientSummary: any;
  /** Collaterals Data */
  collaterals: any;
  /** Credit Lines Data */
  creditLines: any;

  clientViewData: any = {};
  clientDatatables: any;
  clientImage: any;
  clientTemplateData: any;


  /** Show Closed Loan Accounts */
  showClosedLoanAccounts = false;
  /** Show Closed Saving Accounts */
  showClosedSavingAccounts = false;
  /** Show Closed Share Accounts */
  showClosedShareAccounts = false;
  /** Show Closed Reccuring Deposits Accounts */
  showClosedRecurringAccounts = false;
  /** Show Closed Fixed Deposits Accounts */
  showClosedFixedAccounts = false;

  /** Client Id */
  clientid: any;
  dashId: string;

  graphView: boolean = true;
  viewButtonTitle: string = 'tableView';

  creditLinebuttons: any;
  actionCreditLine: any;
  creditLine:any;

  nestedTreeControl: NestedTreeControl<any>;
  nestedTreeDataSource: MatTreeNestedDataSource<any>;
  viewGroup = new FormControl('listView');
  hasNestedChild = (_: number, node: any) => node.children.length;
  private _getChildren = (node: any) => of(node.children);


  /**
   * @param {ActivatedRoute} route Activated Route
   * @param {ClientsService} clientService Clients Service
   * @param {Router} router Router
   */
  constructor(
    private route: ActivatedRoute,
    private clientService: ClientsService,
    private router: Router,
    private clientsService: ClientsService,
    private _sanitizer: DomSanitizer,
    public dialog: MatDialog,
    private homeService: HomeService,
    private creditLineActionsButtons: CreditLineActionsButtons) {
    this.route.data.subscribe((data: { clientAccountsData: any, clientChargesData: any, clientSummary: any, clientCollateralData: any, clientViewData: any, clientCreditLine: any, productLines: any }) => {
      this.clientAccountData = data.clientAccountsData;
      this.savingAccounts = data.clientAccountsData.savingsAccounts;
      this.loanAccounts = data.clientAccountsData.loanAccounts;
      this.shareAccounts = data.clientAccountsData.shareAccounts;
      this.upcomingCharges = data.clientChargesData.pageItems;
      this.collaterals = data.clientCollateralData;
      this.creditLines = data.clientCreditLine.map((item:any)=>({
        ...item,
        status: {
          code: item.status,
          value: this.statusCL(item.status)
        }
      }));
      this.nestedTreeDataSource = new MatTreeNestedDataSource<any>();
      const tree = this.generateLCTree(this.creditLines)
      this.nestedTreeControl = new NestedTreeControl<any>(this._getChildren);
      this.nestedTreeDataSource.data = tree;
      this.nestedTreeControl.expand(this.nestedTreeDataSource.data[0]);
      this.nestedTreeControl.dataNodes = tree;
      this.creditLinebuttons = creditLineActionsButtons.buttons
      this.actionCreditLine = creditLineActionsButtons.actionCreditLine;

      // this.clientSummary = data.clientSummary ? data.clientSummary[0] : [];
      this.clientid = this.route.parent.snapshot.params['clientId'];
      this.clientViewData['ClientSummary'] = data.clientSummary[0];
    });
    this.route.parent.data.subscribe((data: {
      clientViewData: any,
      clientTemplateData: any,
      clientDatatables: any
    }) => {
      this.clientViewData = { ...this.clientViewData, ...data.clientViewData };
      this.clientDatatables = data.clientDatatables;
      this.clientTemplateData = data.clientTemplateData;
    });
  }


  ngOnInit() {

     // Check if the user's last pick exists in localStorage
     const lastPick = localStorage.getItem('lastPick');

     // If the last pick exists, set the graphView accordingly
     if (lastPick) {
       this.graphView = JSON.parse(lastPick);  // true which shows Tables 
     } else {
       // If the last pick doesn't exist, show tables
       this.graphView = false;
     }


    this.clientsService.getClientProfileImage(this.clientid).subscribe(
      (base64Image: any) => {
        this.clientImage = this._sanitizer.bypassSecurityTrustResourceUrl(base64Image);
      }, (error: any) => { }
    );
    this.homeService.getDashboardById('1').subscribe((data: any) => {
      this.dashId = data.uuid;
    })
  }

  generateLCTree(items: any, parentId: any = undefined) {
    const result: any[] = [];
    items.forEach((item: any) => {
      if (item.parentId === parentId) {
        const children = this.generateLCTree(items, item.id)
        if (children.length) {
          item.children = children
        } else {
          item.children = []
        }
        result.push(item)
      }
    });
    return result;
  }

  viewany(cl: any) {

    this.creditLine = cl;
  }

  statusCL(code: number) {
    switch (code) {
      case 100:
        return "creditLineStatusType.applied";
      case 200:
        return "creditLineStatusType.accepted"
      case 300:
        return "creditLineStatusType.confirmed";
      case 400:
        return "creditLineStatusType.to.complete";
      case 500:
        return "creditLineStatusType.redused";
      case 600:
        return "creditLineStatusType.expired";
    }
  }


  /**
 * Performs action button/option action.
 * @param {string} name action name.
 */
  doAction(name: string) {
    switch (name) {
      case 'Assign Staff':
      case 'Close':
      case 'Survey':
      case 'Reject':
      case 'Activate':
      case 'Withdraw':
      case 'Update Default Savings':
      case 'Transfer Client':
      case 'Undo Transfer':
      case 'Accept Transfer':
      case 'Reject Transfer':
      case 'Reactivate':
      case 'Undo Rejection':
      case 'Add Charge':
      case 'Create Collateral':
      case 'Create Self Service User':
      case 'Client Screen Reports':
        this.router.navigate([`actions/${name}`], { relativeTo: this.route });
        break;
      case 'Unassign Staff':
        this.unassignStaff();
        break;
      case 'Delete':
        this.deleteClient();
        break;
      case 'View Signature':
        this.viewSignature();
        break;
      case 'Upload Signature':
        this.uploadSignature();
        break;
      case 'Delete Signature':
        this.deleteSignature();
        break;
      case 'Capture Image':
        this.captureProfileImage();
        break;
      case 'Upload Image':
        this.uploadProfileImage();
        break;
      case 'Delete Image':
        this.deleteProfileImage();
        break;
      case 'Create Standing Instructions':
        const createStandingInstructionsQueryParams: any = { officeId: this.clientViewData.officeId, accountType: 'fromsavings' };
        this.router.navigate(['standing-instructions/create-standing-instructions'], { relativeTo: this.route, queryParams: createStandingInstructionsQueryParams });
        break;
      case 'View Standing Instructions':
        const viewStandingInstructionsQueryParams: any = { officeId: this.clientViewData.officeId, accountType: 'fromsavings' };
        this.router.navigate(['standing-instructions/list-standing-instructions'], { relativeTo: this.route, queryParams: viewStandingInstructionsQueryParams });
        break;
    }
  }

  /**
* Deletes the client
*/
  private deleteClient() {
    const deleteClientDialogRef = this.dialog.open(DeleteDialogComponent, {
      data: { deleteContext: `labels.clientWith`, code: ` id: ${this.clientViewData.id}` }
    });
    deleteClientDialogRef.afterClosed().subscribe((response: any) => {
      if (response.delete) {
        this.clientsService.deleteClient(this.clientViewData.id).subscribe(() => {
          this.router.navigate(['/clients'], { relativeTo: this.route });
        });
      }
    });
  }

  /**
   * Unassign's the client's staff.
   */
  private unassignStaff() {
    const unAssignStaffDialogRef = this.dialog.open(UnassignStaffDialogComponent);
    unAssignStaffDialogRef.afterClosed().subscribe((response: { confirm: any }) => {
      if (response.confirm) {
        this.clientsService.executeClientCommand(this.clientViewData.id, 'unassignStaff', { staffId: this.clientViewData.staffId })
          .subscribe(() => {
            this.reload();
          });
      }
    });
  }
  /**
 * Refetches data for the component
 * TODO: Replace by a custom reload component instead of hard-coded back-routing.
 */
  reload() {
    const url: string = this.router.url;
    this.router.navigateByUrl(`/clients`, { skipLocationChange: true })
      .then(() => this.router.navigate([url]));
  }

  /**
   * Shows client signature in a dialog
   */
  private viewSignature() {
    this.clientsService.getClientDocuments(this.clientViewData.id).subscribe((documents: any) => {
      const viewSignatureDialogRef = this.dialog.open(ViewSignatureDialogComponent, {
        data: {
          documents: documents,
          id: this.clientViewData.id
        }
      });
      viewSignatureDialogRef.afterClosed().subscribe((response: any) => {
        if (response.upload) {
          this.uploadSignature();
        } else if (response.delete) {
          this.deleteSignature();
        }
      });
    });
  }

  /**
   * Uploads client signature
   */
  private uploadSignature() {
    const uploadSignatureDialogRef = this.dialog.open(UploadSignatureDialogComponent);
    uploadSignatureDialogRef.afterClosed().subscribe((signature: File) => {
      if (signature) {
        this.clientsService.uploadClientSignatureImage(this.clientViewData.id, signature)
          .subscribe(() => {
            this.reload();
          });
      }
    });
  }

  /**
   * Deletes client signature
   */
  private deleteSignature() {
    this.clientsService.getClientDocuments(this.clientViewData.id).subscribe((documents: any) => {
      const deleteSignatureDialogRef = this.dialog.open(DeleteSignatureDialogComponent, {
        data: documents
      });
      deleteSignatureDialogRef.afterClosed().subscribe((response: any) => {
        if (response.delete) {
          this.clientsService.deleteClientDocument(this.clientViewData.id, response.id)
            .subscribe(() => {
              this.reload();
            });
        } else if (response.upload) {
          this.uploadSignature();
        }
      });
    });
  }

  /**
   * Captures clients profile image.
   */
  private captureProfileImage() {
    const captureImageDialogRef = this.dialog.open(CaptureImageDialogComponent);
    captureImageDialogRef.afterClosed().subscribe((imageURL: string) => {
      if (imageURL) {
        this.clientsService.uploadCapturedClientProfileImage(this.clientViewData.id, imageURL)
          .subscribe(() => {
            this.reload();
          });
      }
    });
  }

  /**
   * Uploads the clients image.
   */
  private uploadProfileImage() {
    const uploadImageDialogRef = this.dialog.open(UploadImageDialogComponent);
    uploadImageDialogRef.afterClosed().subscribe((image: File) => {
      if (image) {
        this.clientsService.uploadClientProfileImage(this.clientViewData.id, image)
          .subscribe(() => {
            this.reload();
          });
      }
    });
  }

  /**
   * Deletes the client image.
   */
  private deleteProfileImage() {
    const deleteClientImageDialogRef = this.dialog.open(DeleteDialogComponent, {
      data: { deleteContext: `labels.theProfileImageOf`, code: ` ${this.clientViewData.displayName}` }
    });
    deleteClientImageDialogRef.afterClosed().subscribe((response: any) => {
      if (response.delete) {
        this.clientsService.deleteClientProfileImage(this.clientViewData.id)
          .subscribe(() => {
            this.reload();
          });
      }
    });
  }

  viewLcChildren(lc: any) {

    this.creditLine = lc;
}

  /**
   * Toggles Loan Accounts Overview
   */
  toggleLoanAccountsOverview() {
    this.showClosedLoanAccounts = !this.showClosedLoanAccounts;
  }

  /**
   * Toggles Loan Accounts Overview
   */
  toggleSavingAccountsOverview() {
    this.showClosedSavingAccounts = !this.showClosedSavingAccounts;
  }

  /**
   * Toggles Loan Accounts Overview
   */
  toggleShareAccountsOverview() {
    this.showClosedShareAccounts = !this.showClosedShareAccounts;
  }

  /**
   * Toggles Reccuring Accounts Overview
   */
  toggleRecurringAccountsOverview() {
    this.showClosedRecurringAccounts = !this.showClosedRecurringAccounts;
  }

  /**
   * Toggles Fixed Accounts Overview
   */
  toggleFixedAccountsOverview() {
    this.showClosedFixedAccounts = !this.showClosedFixedAccounts;
  }

  /**
   * Waive Charge.
   * @param chargeId Selected Charge Id.
   * @param clientId Selected Client Id.
   */
  waiveCharge(chargeId: string, clientId: string) {
    const charge = { clientId: clientId.toString(), resourceType: chargeId };
    this.clientService.waiveClientCharge(charge).subscribe(() => {
      this.getChargeData(clientId);
    });
  }

  /**
   * Get Charge Data.
   * @param clientId Selected Client Id.
   */
  getChargeData(clientId: string) {
    this.clientService.getClientChargesData(clientId).subscribe((data: any) => {
      this.upcomingCharges = data.pageItems;
    });
  }

  /**
   * Stops the propagation to view pages.
   * @param $event Mouse Event
   */
  routeEdit($event: MouseEvent) {
    $event.stopPropagation();
  }

  /**
   * @param {any} loanId Loan Id
   */
  routeTransferFund(loanId: any) {
    const queryParams: any = { loanId: loanId, accountType: 'fromloans' };
    this.router.navigate(['../', 'loans-accounts', loanId, 'transfer-funds', 'make-account-transfer'], { relativeTo: this.route, queryParams: queryParams });
  }

  changeView() {
    this.graphView = !this.graphView;
    // this.viewButtonTitle = this.graphView ? 'tableView' : 'graphView';

     // Store the updated graphView value in localStorage
     localStorage.setItem('lastPick', JSON.stringify(this.graphView));
  }

  // forwardClick(targetElement: ElementRef){
  //   targetElement.nativeElement.click();
  // }


}
