import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';



@Component({
  selector: 'mifosx-view-product-line',
  templateUrl: './view-product-line.component.html',
  styleUrls: ['./view-product-line.component.scss']
})
export class ViewProductLineComponent implements OnInit {

  productLine :any;
  constructor(private route: ActivatedRoute) {
      const CLRepositoryId = Number(this.route.snapshot.params['id']);

    this.route.parent.data.subscribe((data: { productLine:any, productLines:any}) => {
      this.productLine = {
        ...data.productLine,
        parent : data.productLines.find((item:any)=>item.id===data.productLine?.parent)
      }
    });
  }

  ngOnInit(): void {
  }

}
