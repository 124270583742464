<div class="container m-b-20" fxLayout="row" fxLayoutAlign="end" fxLayoutGap="20px">

  <div #buttonTreeView class="in-block">
    <button mat-raised-button color="primary">
      <fa-icon icon="sitemap"></fa-icon>&nbsp;&nbsp;
      {{ 'labels.buttons.treeview' | translate }}
    </button>
  </div>

  <div #buttonCreateOffice class="in-block">
    <button mat-raised-button color="primary" [routerLink]="['create']" *mifosxHasPermission="'CREATE_OFFICE'">
      <fa-icon icon="plus"></fa-icon>&nbsp;&nbsp;
      {{ 'labels.buttons.createoffice' | translate }}
    </button>
  </div>

  <div #buttonImportOffices class="in-block">
    <button mat-raised-button color="primary" *mifosxHasPermission="'CREATE_OFFICE'"
      [routerLink]="['/organization', 'bulk-import', 'Offices']">
      <fa-icon icon="upload"></fa-icon>&nbsp;&nbsp;
      {{ 'labels.buttons.importoffices' | translate }}
    </button>
  </div>

</div>

<div class="container">

  <div #filter fxLayout="row" fxLayoutGap="20px">
    <mat-form-field fxFlex>
      <mat-label>{{ 'labels.buttons.filter' | translate }}</mat-label>
      <input matInput (keyup)="applyFilter($event.target.value)">
    </mat-form-field>
  </div>

  <div #tableOffices class="mat-elevation-z8">

    <table mat-table [dataSource]="dataSource" matSort>

      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'labels.heading.officeName' | translate }} </th>
        <td mat-cell *matCellDef="let offices"> {{ offices.name }} </td>
      </ng-container>

      <ng-container matColumnDef="externalId">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'labels.heading.externalid' | translate }} </th>
        <td mat-cell *matCellDef="let offices"> {{ offices.externalId }} </td>
      </ng-container>

      <ng-container matColumnDef="parentName">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'labels.heading.parentoffice' | translate }} </th>
        <td mat-cell *matCellDef="let offices"> {{ offices.parentName }} </td>
      </ng-container>

      <ng-container matColumnDef="openingDate">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'labels.heading.openedon' | translate }} </th>
        <td mat-cell *matCellDef="let offices"> {{ offices.openingDate | dateFormat }} </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="select-row"
        [routerLink]="[row.id, 'general']"></tr>

    </table>

    <mat-paginator [pageSizeOptions]="[10, 25, 50, 100]" showFirstLastButtons></mat-paginator>

  </div>

</div>

<ng-template #templateButtonTreeView let-popover="popover">
  <h2>{{ 'labels.buttons.treeview' | translate }}</h2>
  <p class="mw300">{{"labels.thisWillLaunchAlternativeViewWhichAllows" | translate}}.</p>
  <div fxLayout="row" fxLayoutAlign="end" fxLayoutGap="2%" fxLayout.lt-md="column">
    <button mat-raised-button color="warn" (click)="popover.close();configurationWizardService.closeConfigWizard()">{{
      'labels.buttons.close' | translate }}</button>
    <button mat-raised-button color="primary" (click)="popover.close();previousStep();">{{ 'labels.buttons.back' |
      translate }}</button>
    <button mat-raised-button color="primary"
      (click)="popover.close();showPopover(templateButtonCreateOffice,buttonCreateOffice,'bottom', true);">{{
      'labels.buttons.next' | translate }}</button>
  </div>
</ng-template>

<ng-template #templateButtonCreateOffice let-popover="popover">
  <h2>{{ 'labels.buttons.createoffice' | translate }}</h2>
  <p class="mw300">{{"labels.thisOptionAllowsYouCreateNewOffices" | translate}}.</p>
  <div fxLayout="row" fxLayoutAlign="end" fxLayoutGap="2%" fxLayout.lt-md="column">
    <button mat-raised-button color="warn" (click)="popover.close();configurationWizardService.closeConfigWizard()">{{
      'labels.buttons.close' | translate }}</button>
    <button mat-raised-button color="primary"
      (click)="popover.close();showPopover(templateButtonTreeView,buttonTreeView,'bottom', true);">{{
      'labels.buttons.back' | translate }}</button>
    <button mat-raised-button color="primary"
      (click)="popover.close();showPopover(templateButtonImportOffices,buttonImportOffices,'bottom', true);">{{
      'labels.buttons.next' | translate }}</button>
  </div>
</ng-template>

<ng-template #templateButtonImportOffices let-popover="popover">
  <h2>{{ 'labels.buttons.importoffices' | translate }}</h2>
  <p class="mw300">{{"labels.thisWillLaunchBulkImportOfficesPage" | translate}}.</p>
  <div fxLayout="row" fxLayoutAlign="end" fxLayoutGap="2%" fxLayout.lt-md="column">
    <button mat-raised-button color="warn" (click)="popover.close();configurationWizardService.closeConfigWizard()">{{
      'labels.buttons.close' | translate }}</button>
    <button mat-raised-button color="primary"
      (click)="popover.close();showPopover(templateButtonCreateOffice,buttonCreateOffice,'bottom', true);">{{
      'labels.buttons.back' | translate }}</button>
    <button mat-raised-button color="primary"
      (click)="popover.close();showPopover(templateFilter,filter,'bottom', true);">{{ 'labels.buttons.next' | translate
      }}</button>
  </div>
</ng-template>

<ng-template #templateFilter let-popover="popover">
  <h4>{{"labels.searchBarFilterOffices" | translate}}.</h4>
  <div fxLayout="row" fxLayoutAlign="end" fxLayoutGap="2%" fxLayout.lt-md="column">
    <button mat-raised-button color="warn" (click)="popover.close();configurationWizardService.closeConfigWizard()">{{
      'labels.buttons.close' | translate }}</button>
    <button mat-raised-button color="primary"
      (click)="popover.close();showPopover(templateButtonImportOffices,buttonImportOffices,'bottom', true);">{{
      'labels.buttons.back' | translate }}</button>
    <button mat-raised-button color="primary"
      (click)="popover.close();showPopover(templateTableOffices,tableOffices,'top', true);">{{ 'labels.buttons.next' |
      translate }}</button>
  </div>
</ng-template>

<ng-template #templateTableOffices let-popover="popover">
  <h4>{{"labels.listOfficesOrganization" | translate}}.</h4>
  <div fxLayout="row" fxLayoutAlign="end" fxLayoutGap="2%" fxLayout.lt-md="column">
    <button mat-raised-button color="warn" (click)="popover.close();configurationWizardService.closeConfigWizard()">{{
      'labels.buttons.close' | translate }}</button>
    <button mat-raised-button color="primary"
      (click)="popover.close();showPopover(templateFilter,filter,'bottom', true);">{{ 'labels.buttons.back' | translate
      }}</button>
    <button mat-raised-button color="primary" (click)="popover.close();nextStep()">{{ 'labels.buttons.next' | translate
      }}</button>
  </div>
</ng-template>
