import { ExportExcelService } from './../../../../core/exportExcel/ExportExcel.service';
/** Angular Imports */
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SelectionModel } from '@angular/cdk/collections';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialog } from '@angular/material/dialog';

/** Custom Services */
import { TasksService } from '../../../../tasks/tasks.service';
import { SettingsService } from 'app/settings/settings.service';

/** Dialog Components */
import { ConfirmationDialogComponent } from 'app/shared/confirmation-dialog/confirmation-dialog.component';
import { Dates } from 'app/core/utils/dates';
import * as moment from 'moment';

@Component({
  selector: 'mifosx-account-activation',
  templateUrl: './account-activation.component.html',
  styleUrls: ['../../../operations.module.scss']
})
export class AccountActivationComponent {

  /** Loans Data */
  accounts: any;
  accountsFiltred: any;
  /** Batch Requests */
  batchRequests: any[];
  /** Datasource for loans disbursal table */
  dataSource: MatTableDataSource<any>;
  /** Row Selection */
  selection: SelectionModel<any>;
  /** Displayed Columns for loan disbursal data */
  displayedColumns: string[] = ['select', 'client', 'savingsAccountNumber', 'type', 'balance', 'submittedOn', 'office'];

  offices: any;
  products: any;
  filteredAccount: any;

  clientFilter = new FormControl();
  accountNumber = new FormControl();
  balance = new FormControl();
  productFilter = new FormControl();
  officeFilter = new FormControl();
  submittedOnFilter = new FormControl();
  startDateFilter = new FormControl();
  endDateFilter = new FormControl();

  /**
   * Retrieves the loans data from `resolve`.
   * @param {ActivatedRoute} route Activated Route.
   * @param {Dialog} dialog MatDialog.
   * @param {Dates} dateUtils Date Utils.
   * @param {router} router Router.
   * @param {SettingsService} settingsService Settings Service.
   * @param {TasksService} tasksService Tasks Service.
   */
  constructor(private route: ActivatedRoute,
    private dialog: MatDialog,
    private dateUtils: Dates,
    private settingsService: SettingsService,
    private tasksService: TasksService,
    private exportExcelService: ExportExcelService) {
    this.route.data.subscribe((data: { accountData: any }) => {
      this.accounts = data.accountData.pageItems;
      this.accountsFiltred = data.accountData.pageItems;
      this.dataSource = new MatTableDataSource(this.accounts);
      this.selection = new SelectionModel(true, []);
      this.products = this.productList();
      this.offices = this.officeList();
    });
  }

  applyFilters = () => {
    this.accountsFiltred = this.accounts.filter((account: any) => {
      const client = this.clientFilter.value ? account?.clientName?.toLowerCase().includes(this.clientFilter.value?.toLowerCase()) : true;
      const accountNumber = this.accountNumber.value ? account?.accountNo?.toLowerCase().includes(this.accountNumber.value?.toLowerCase()) : true;
      const balance = this.balance.value ? account?.summary?.accountBalance?.toLowerCase().includes(this.balance.value?.toLowerCase()) : true;
      let product= true;
      if(this.productFilter.value!= 'All'){
        product = this.productFilter.value ?  account?.savingsProductName?.toLowerCase()===this.productFilter.value?.toLowerCase() : true;
      }else
        this.productFilter.setValue('');
      let office= true;
      if(this.officeFilter.value!= 'All')
        office = this.officeFilter.value ?  account?.officeName?.toLowerCase()===this.officeFilter.value?.toLowerCase() : true;
      else
        this.officeFilter.setValue('');
      var submitedOn = account.timeline.submittedOnDate;
      const dateSubmittedOn = new Date(submitedOn[0], submitedOn[1] - 1, submitedOn[2]);
      var dateFiltre :Date = this.submittedOnFilter.value;
      const submittedOn =  dateFiltre ? moment(dateSubmittedOn).isSame(dateFiltre) : true;

      dateFiltre = this.startDateFilter.value;
      const startDate =  dateFiltre ? moment(dateSubmittedOn).isAfter(dateFiltre) : true;

      dateFiltre = this.endDateFilter.value;
      const endDate =  dateFiltre ? moment(dateSubmittedOn).isBefore(dateFiltre) : true;

      return  client && accountNumber && balance && product && office && submittedOn && startDate && endDate;
    });
    this.dataSource=this.accountsFiltred;
  }

  exportexcel(): void {
    const dateFormat = 'yyyy-MM-dd';
    const data = this.accountsFiltred.map((account: any) => ({
      Client: account.clientName,
      SavingAccount: account.accountNo,
      Type: account.savingsProductName,
      Balance: account.summary?.accountBalance,
      submittedOn: this.dateUtils.formatDate(account.timeline.submittedOnDate,dateFormat),
      Office: account.officeName,
    }))

    const header = ['labels.heading.client', 'labels.heading.savingaccount', 'labels.heading.accounttype', 'labels.heading.balance', 'labels.inputs.submittedOnDate', 'labels.heading.officeName'];
    this.exportExcelService.exportExcelFile({data:data,header:header,fileName:'view_accounts.xlsx'})
  }


  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.accountsFiltred.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected() ?
      this.selection.clear() :
      this.accountsFiltred.forEach((row: any) => this.selection.select(row));
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: any): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.position + 1}`;
  }

  activateAccount() {
    const activateAccountDialogRef = this.dialog.open(ConfirmationDialogComponent, {
      data: { heading: 'labels.heading.accountactivation', dialogContext: 'labels.areYouSureYouWantToActivateAccount' }
    });
    activateAccountDialogRef.afterClosed().subscribe((response: { confirm: any }) => {
      if (response.confirm) {
        this.bulkAccountActivation();
      }
    });
  }

  bulkAccountActivation() {
    const dateFormat = 'yyyy-MM-dd';
    const activatedOnDate = this.dateUtils.formatDate(new Date(), dateFormat);
    const locale = this.settingsService.language.code;
    const formData = {
      dateFormat,
      activatedOnDate,
      locale
    };
    const selectedAccounts = this.selection.selected.length;
    const listSelectedAccounts = this.selection.selected;
    let approvedAccounts = 0;
    this.batchRequests = [];
    let reqId = 1;
    listSelectedAccounts.forEach((element: any) => {
      const url = 'savingsaccounts/' + element.id + '?command=activate';
      const bodyData = JSON.stringify(formData);
      const batchData = { requestId: reqId++, relativeUrl: url, method: 'POST', body: bodyData };
      this.batchRequests.push(batchData);
    });
    this.tasksService.submitBatchData(this.batchRequests).subscribe((response: any) => {
      console.log(response);
      response.forEach((responseEle: any) => {
        if (responseEle.statusCode = '200') {
          approvedAccounts++;
          responseEle.body = JSON.parse(responseEle.body);
          if (selectedAccounts === approvedAccounts) {
            this.savingsResource();
          }
        }
      });
    });
  }

  savingsResource() {
    this.tasksService.getAllSavingsAcativate().subscribe((response: any) => {
      this.accounts = response.pageItems;
      this.dataSource = new MatTableDataSource(this.accounts);
      this.selection = new SelectionModel(true, []);
    });
  }

  applyFilter(filterValue: string = '') {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  productList(){
    let products= new Set(['All']);
    this.accountsFiltred.map((account : any)=>{return account.savingsProductName}).forEach(products.add, products);
    return products;
  }

  officeList(){
    let offices= new Set(['All']);
    this.accountsFiltred?.map((account : any)=>{
      return account.officeName})?.forEach(offices.add, offices);
    return offices;
  }


}
