<mifosx-web-views-top-bar title="labels.inputs.charges"></mifosx-web-views-top-bar>
<div class="container">
  <div class="content">
    <mat-card *ngFor="let charge of loanDetailsData?.charges" class="unpaid"
      [ngClass]="{'paid': charge?.paid == true || charge?.waived == true}">
      <mat-card-content>
        <h2>{{charge.name}}</h2>
        <p>{{charge.dueDate | dateFormat}}</p>
        <div fxFlexFill>
          <span fxFlex="40%">
            {{"labels.commons.due" | translate}} :
          </span>
          <span fxFlex="60%">
            {{charge?.amount}} {{charge?.currency?.displaySymbol}}
          </span>
        </div>
        <div fxFlexFill>
          <span fxFlex="40%">
            {{"labels.heading.amountPaid" | translate}} :
          </span>
          <span fxFlex="60%">
            {{charge?.amountPaid}} {{charge?.currency?.displaySymbol}}
          </span>
        </div>
        <div fxFlexFill>
          <span fxFlex="40%">
            {{"labels.heading.waived" | translate}} :
          </span>
          <span fxFlex="60%">
            {{charge?.amountWaived}} {{charge?.currency?.displaySymbol}}
          </span>
        </div>
        <div fxFlexFill>
          <span fxFlex="40%">
            {{"labels.heading.outstanding" | translate}} :
          </span>
          <span fxFlex="60%">
            {{charge?.amountOutstanding}} {{charge?.currency?.displaySymbol}}
          </span>
        </div>
      </mat-card-content>
    </mat-card>
  </div>
  <mifosx-web-views-logo></mifosx-web-views-logo>

</div>
