<mat-card class="group-card">

  <mat-card-header fxLayout="column" class="mat-toolbar mat-primary header">
    
    <div class="group-card-title">
      <mat-card-title>
        <h3>
          <i class="fa fa-stop" [ngClass]="groupViewData.status.code|statusLookup"
            [matTooltip]="groupViewData.status.value"></i> <i class="fa fa-users"></i>
            <!-- {{ 'labels.inputs.groupName' | translate }} : -->
            {{groupViewData.name}} <i class="fa fa-barcode"></i> {{ 'labels.inputs.accountnumber' | translate }}: {{groupViewData.id}} |
             <i class="fa fa-building"></i> {{ 'labels.heading.officeName' | translate }}: "{{groupViewData.officeName}}" |
              <i class="fa fa-map-marker"></i> {{ 'labels.centerName' | translate }}: "{{groupViewData.centerName}}"
        </h3>
      </mat-card-title>
      <!-- <mat-card-subtitle>
        <p>
          {{ 'labels.inputs.accountnumber' | translate }} #: {{groupViewData.id}} | {{ 'labels.centerName' | translate }}: {{groupViewData.centerName}} | {{ 'labels.inputs.staff' | translate }}: {{groupViewData.staffName
          || ('labels.heading.unassigned' | translate )}}<br />
          {{ 'labels.heading.officeName' | translate }}: {{groupViewData.officeName}}<br />
           {{ 'labels.heading.activationdate' | translate }} : {{(groupViewData.activationDate)?(groupViewData.activationDate | dateFormat) :('labels.heading.notActivated' | translate)}}<br />
          <span *ngIf="!groupViewData.active">
            {{ 'labels.heading.closuredate' | translate }}: {{groupViewData.timeline.closedOnDate | dateFormat}}
          </span>
        </p>
      </mat-card-subtitle> -->
    </div>

    
    <mat-card-actions class="group-actions" *ngIf="!(groupViewData.status.value==='Closed')">

      <button mat-raised-button [matMenuTriggerFor]="ManageClient">
        {{ 'labels.manage' | translate }}
        &nbsp;&nbsp;<i class="fa fa-caret-down drop-down-icon ng-scope"></i>

      </button>
      <mat-menu #ManageClient="matMenu" yPosition="below">
        <span *ngIf="!(groupViewData.status.value==='Active')">
          <button mat-menu-item *mifosxHasPermission="'UPDATE_GROUP'" (click)="doAction('Activate')">
            {{ 'labels.buttons.activate' | translate }}</button>
        </span>
        <button mat-menu-item *mifosxHasPermission="'UPDATE_GROUP'" (click)="doAction('Edit')">
          {{ 'labels.buttons.edit' | translate }}
        </button>
        <button mat-menu-item *mifosxHasPermission="'CREATE_CLIENT'" (click)="doAction('Manage Members')">
          {{ 'labels.inputs.addclients' | translate }}</button>
        <button mat-menu-item *mifosxHasPermission="'ASSOCIATECLIENTS_GROUP'" (click)="doAction('Transfer Clients')">
          {{"labels.buttons.transferclients" | translate}}</button>
        <button mat-menu-item *mifosxHasPermission="'TRANSFERCLIENTS_GROUP'" (click)="doAction('Manage Members')">
          {{"labels.heading.managemembers" | translate}}</button>
        <span *ngIf="!groupViewData.staffId">
          <button mat-menu-item *mifosxHasPermission="'ASSIGNSTAFF_GROUP'" (click)="doAction('Assign Staff')">
            {{ 'labels.heading.assignstaff' | translate }}
          </button>
        </span>
        <span *ngIf="groupViewData.staffId">
          <button mat-menu-item *mifosxHasPermission="'UNASSIGNSTAFF_GROUP'" (click)="doAction('Unassign Staff')">
            {{ 'labels.heading.unassignstaff' | translate }}
          </button>
        </span>
        <span
          *ngIf="!(groupViewData.centerId || groupViewData.collectionMeetingCalendar) && groupViewData.status.value==='Active'">
          <button mat-menu-item *mifosxHasPermission="'CREATE_MEETING'" (click)="doAction('Attach Meeting')">
            {{ 'labels.heading.attachmeeting' | translate }}
          </button>
        </span>
        <span *ngIf="groupViewData.collectionMeetingCalendar">
          <button mat-menu-item *mifosxHasPermission="'SAVEORUPDATEATTENDANCE_MEETING'"
            (click)="doAction('Attendance')">
            {{ 'labels.buttons.attendance' | translate }}
          </button>
        </span>
        <button mat-menu-item *mifosxHasPermission="'DELETE_GROUP'" (click)="doAction('Delete')">
          {{ 'labels.anchors.delete' | translate }}
        </button>
        <button mat-menu-item *mifosxHasPermission="'CLOSE_GROUP'" (click)="doAction('Close')">
          {{ 'labels.anchors.close' | translate }}
        </button>
      </mat-menu>




      <button mat-raised-button [matMenuTriggerFor]="Serve">
        {{ 'labels.serve' | translate }}
        &nbsp;&nbsp;<i class="fa fa-caret-down drop-down-icon ng-scope"></i>

      </button>
      <mat-menu #Serve="matMenu" yPosition="below">
        <button mat-menu-item *mifosxHasPermission="'CREATE_SAVINGSACCOUNT'"
          [routerLink]="['savings-accounts', 'create']">{{ 'labels.buttons.groupsavingapplication' | translate }}</button>
        <button mat-menu-item [routerLink]="['savings-accounts', 'gsim-account' , 'create']"> {{ 'labels.buttons.gsimapplication' | translate }} </button>
        <button mat-menu-item *mifosxHasPermission="'CREATE_LOAN'" [routerLink]="['loans-accounts', 'create']">{{ 'labels.buttons.grouploanapplication' | translate }}</button>
        <span *ngIf="groupViewData.clientMembers">
          <button mat-menu-item *mifosxHasPermission="'CREATE_LOAN'" [routerLink]="['loans-accounts','jlg-loan','create']">
            {{ 'labels.buttons.bulkjlgloanapplication' | translate }}
          </button>
        </span>
        <button mat-menu-item [routerLink]="['loans-accounts', 'glim-account', 'create']">{{ 'labels.buttons.glimloanapplication' | translate }}</button>


      </mat-menu>

      <!--



        <span *ngIf="!(groupViewData.status.value==='Active')">
          <button mat-raised-button  *mifosxHasPermission="'UPDATE_GROUP'" (click)="doAction('Activate')">
            <i class="fa fa-check-sign"></i>Activate</button>
        </span>
        <button mat-raised-button *mifosxHasPermission="'UPDATE_GROUP'" (click)="doAction('Edit')">
          <i class="fa fa-edit"></i>Edit</button>
        <button mat-raised-button *mifosxHasPermission="'CREATE_CLIENT'">
          <i class="fa fa-plus"></i>{{ 'labels.inputs.addclients' | translate }}</button>
        <button mat-raised-button *mifosxHasPermission="'ASSOCIATECLIENTS_GROUP'" (click)="doAction('Transfer Clients')">
          <i class="fa fa-exchange"></i>Transfer Clients</button>
        <button mat-raised-button *mifosxHasPermission="'TRANSFERCLIENTS_GROUP'" (click)="doAction('Manage Members')">
          <i class="fa fa-user"></i>Manage Members</button>

        <button mat-raised-button *ngIf="groupViewData.active" [matMenuTriggerFor]="AccountApplications">
          <i class="fa fa-file"></i>Applications</button>
        <mat-menu #AccountApplications="matMenu">
          <span *ngIf="groupViewData.clientMembers">
            <button mat-menu-item *mifosxHasPermission="'CREATE_LOAN'">Bulk JLG Loan Application</button></span>
          <button mat-menu-item *mifosxHasPermission="'CREATE_SAVINGSACCOUNT'" [routerLink]="['savings-accounts', 'create']">Group Saving Application</button>
          <button mat-menu-item *mifosxHasPermission="'CREATE_LOAN'" [routerLink]="['loans-accounts', 'create']">Group Loan Application</button>
          <button mat-menu-item  [routerLink]="['loans-accounts', 'glim-account', 'create']">GLIM Loan Application</button>
          <button mat-menu-item  [routerLink]="['savings-accounts', 'gsim-account' , 'create']"> GSIM Application </button>
        </mat-menu>

        <button mat-raised-button [matMenuTriggerFor]="More">{{'labels.buttons.more' | translate}}</button>
        <mat-menu #More="matMenu">
          <span *ngIf="groupViewData.collectionMeetingCalendar">
            <button mat-menu-item *mifosxHasPermission="'SAVEORUPDATEATTENDANCE_MEETING'" (click)="doAction('Attendance')">Attendance</button></span>
          <span *ngIf="!groupViewData.staffId">
            <button mat-menu-item *mifosxHasPermission="'ASSIGNSTAFF_GROUP'" (click)="doAction('Assign Staff')">Assign Staff</button></span>
          <span *ngIf="groupViewData.staffId">
            <button mat-menu-item *mifosxHasPermission="'UNASSIGNSTAFF_GROUP'" (click)="doAction('Unassign Staff')">Unassign Staff</button></span>
          <span *ngIf="!(groupViewData.centerId || groupViewData.collectionMeetingCalendar) && groupViewData.status.value==='Active'">
            <button mat-menu-item *mifosxHasPermission="'CREATE_MEETING'" (click)="doAction('Attach Meeting')">Attach Meeting</button></span>
          <button mat-menu-item *mifosxHasPermission="'CLOSE_GROUP'" (click)="doAction('Close')">{{"labels.buttons.close" | translate}}</button>
          <button mat-menu-item *mifosxHasPermission="'DELETE_GROUP'" (click)="doAction('Delete')">{{ 'labels.buttons.delete' | translate }}</button>
        </mat-menu> -->

    </mat-card-actions>

  </mat-card-header>

  <mat-card-content>

    <nav class="navigation-tabs">
      <a [routerLink]="['.']" routerLinkActive #general="routerLinkActive"
        [ngClass]="general.isActive && this.router.url.split('/').length ===3 ? 'selected_tab': ''">
        {{ 'labels.heading.general' | translate }}
      </a>
      <a [routerLink]="['./notes']" *mifosxHasPermission="'READ_GROUPNOTE'" routerLinkActive #notes="routerLinkActive"
        [ngClass]="notes.isActive ? 'selected_tab': ''">
        {{ 'labels.inputs.notes' | translate }}
      </a>
      <a [routerLink]="['./committee']" routerLinkActive #committee="routerLinkActive"
        [ngClass]="committee.isActive ? 'selected_tab': ''">
        {{ 'labels.heading.committee' | translate }}
      </a>
      <span *ngFor="let groupDatatable of groupDatatables">
        <a *mifosxHasPermission="'READ_' + groupDatatable.registeredTableName"
          [routerLink]="['./datatables',groupDatatable.registeredTableName]" routerLinkActive
          #datatable="routerLinkActive" [ngClass]="datatable.isActive ? 'selected_tab': ''">
          {{groupDatatable.registeredTableName}}
        </a>
      </span>
    </nav>
    <div class="content">

      <router-outlet></router-outlet>
    </div>
  </mat-card-content>

</mat-card>
