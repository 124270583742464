import { AuthenticationService } from 'app/core/authentication/authentication.service';
/** Angular Imports */
import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

/** Custom Services */
import { SettingsService } from 'app/settings/settings.service';

/** Step Components */
import { Dates } from 'app/core/utils/dates';
import { LoansAccountDetailsStepComponent } from './loans-account-stepper/loans-account-details-step/loans-account-details-step.component';
import { MatStepper } from '@angular/material/stepper';
import { LoansService } from 'app/loans/loans.service';
import { DomSanitizer } from '@angular/platform-browser';

/**
 * Create loans account
 */
@Component({
  selector: 'mifosx-web-view-create-loan',
  templateUrl: './web-view-create-loan.component.html',
  styleUrls: ['./web-view-create-loan.component.scss']
})
export class WebViewCreateLoanComponent implements OnInit {

  /** Imports all the step component */
  @ViewChild(LoansAccountDetailsStepComponent, { static: true }) loansAccountDetailsStep: LoansAccountDetailsStepComponent;

  @ViewChild('loansAccountStepper') private stepper: MatStepper;

  /** Loans Account Template */
  loansAccountTemplate: any;
  /** Loans Account Product Template */
  loansAccountProductTemplate: any;
  /** Collateral Options */
  collateralOptions: any;
  /** Multi Disburse Loan */
  multiDisburseLoan: any;
  /** Principal Amount */
  principal: any;
  success = false;
  token: string;
  clientId: any;
  creditLine:any;
  submited = false;
  loanProducts :any[];

  /**
   * Sets loans account create form.
   * @param {route} ActivatedRoute Activated Route.
   * @param {router} Router Router.
   * @param {Dates} dateUtils Date Utils
   * @param {authenticationService} AuthenticationService Loans Service
   * @param {SettingsService} settingsService Settings Service
   */
  constructor(private route: ActivatedRoute,
    private router: Router,
    private dateUtils: Dates,
    private loanService: LoansService,
    private settingsService: SettingsService,
    private authenticationService: AuthenticationService,
  ) {
    this.clientId = this.route.snapshot.params['clientId'];
    this.route.data.subscribe((data: { loansAccountTemplate: any,clientCreditLine: any,loanProducts:any}) => {
      this.loansAccountTemplate = data.loansAccountTemplate;
      this.creditLine = data.clientCreditLine[0];
      if(this.creditLine){
        this.loanProducts = data.loanProducts.filter((product:any)=>product.minPrincipal<=this.creditLine?.remainAmount);
      }
    }, (error: any) => {
      this.router.navigate(['/webview/error']);
    });
  }

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      this.token = params['token'];
    });

  }

  /**
   * Sets loans account product template and collateral template
   * @param {any} $event API response
   */
  setTemplate($event: any) {
    this.loansAccountProductTemplate = $event;
    const entityId = (this.loansAccountTemplate.clientId) ? this.loansAccountTemplate.clientId : this.loansAccountTemplate.group.id;
    const isGroup = (this.loansAccountTemplate.clientId) ? false : true;
    const productId = this.loansAccountProductTemplate.loanProductId;
    this.authenticationService.getLoansAccountTemplateResource(this.token, entityId, isGroup, productId).subscribe((response: any) => {
      this.multiDisburseLoan = response.multiDisburseLoan;
    });
  }

  /** Get Loans Account Details Form Data */
  get loansAccountDetailsForm() {
    return this.loansAccountDetailsStep.loansAccountDetailsForm;
  }

  /** Get Loans Account Details Form Data */
  get nextPeriodDetails() {
      return this.loansAccountDetailsStep?.nextPeriod;
    }

  /** Checks wheter all the forms in different steps are valid or not */
  get loansAccountFormValid() {
    return this.loansAccountDetailsForm.valid;
  }


  /** Retrieves Data of all forms except Currency to submit the data */
  get loansAccount() {
    return {
      ...this.loansAccountDetailsStep.loansAccountDetails,
    };
  }

  /**
   * Submits Data to create loan account
   */
  submit() {
    this.submited = true;
    this.success = true;
    const locale = this.settingsService.language.code;
    const dateFormat = 'yyyy-MM-dd';
    const charges = this.loansAccountProductTemplate?.charges?.map((charge:any)=>({
      chargeId: charge?.chargeId,
      amount: charge?.amount
    }))
    const loansAccountData = {
      ...this.loansAccount,
      submittedOnDate: this.dateUtils.formatDate(this.loansAccount.submittedOnDate, dateFormat),
      expectedDisbursementDate: this.dateUtils.formatDate(this.loansAccount.expectedDisbursementDate, dateFormat),
      dateFormat,
      locale,
      loanType: 'individual',
      clientId: this.clientId,
      transactionProcessingStrategyId: this.loansAccountProductTemplate.transactionProcessingStrategyId,
      interestCalculationPeriodType: this.loansAccountProductTemplate.interestCalculationPeriodType?.id,
      interestRatePerPeriod: this.loansAccountProductTemplate.interestRatePerPeriod,
      amortizationType: this.loansAccountProductTemplate.amortizationType?.id,
      interestType: this.loansAccountProductTemplate.interestType?.id,
      creditLineId: this.creditLine?.id,
      charges:charges
    };


    // delete loansAccountData.isValid;
    // loansAccountData.principal = loansAccountData.principalAmount;
    // delete loansAccountData.principalAmount;

    this.loanService.createWebViewLoansAccount(loansAccountData).subscribe(async (response: any) => {
      // this.approve(response.loanId)
        this.stepper.next()
        // setTimeout(() => console.log("redirect"), 5000);
    });
  }

  // approve(loanId: any) {
  //   const locale = this.settingsService.language.code;
  //   const dateFormat = 'yyyy-MM-dd';
  //   const data = {
  //     approvedOnDate: this.dateUtils.formatDate(new Date(), dateFormat),
  //     approvedLoanAmount: this.loansAccount.principal,
  //     expectedDisbursementDate: this.dateUtils.formatDate(this.loansAccount.expectedDisbursementDate, dateFormat),
  //     dateFormat,
  //     locale
  //   };
  //   this.loanService.loanActionButtons(loanId, 'approve', data).subscribe((response: any) => {
  //     this.disburse(loanId)
  //   });
  // }

  // disburse(loanId: any) {
  //   const locale = this.settingsService.language.code;
  //   const dateFormat = 'yyyy-MM-dd';
  //   const data = {
  //     actualDisbursementDate: this.dateUtils.formatDate(new Date(), dateFormat),
  //     transactionAmount: this.loansAccount.principal,
  //     dateFormat,
  //     locale
  //   };
  //   this.loanService.loanActionButtons(loanId, 'disburse', data)
  //     .subscribe((response: any) => {
  //       this.stepper.next()
  //       // setTimeout(() => console.log("redirect"), 5000);
  //     });
  // }

}
