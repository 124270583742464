<mat-spinner [diameter]="50" *ngIf="isLoading"></mat-spinner>
<ng-container *ngIf="urlSafe">
<iframe
    class="metabase"
    [src]="urlSafe"
    frameborder="0"
    allowtransparency
    style ="left:0;width:100%;height:1200px;"
    (load)="onLoad()"
></iframe>
</ng-container>
