<div class="container">

  <mat-card class="tasks-card">

    <mat-card-content>

      <nav mat-tab-nav-bar class="navigation-tabs">
        <a mat-tab-link [routerLink]="['./checker-inbox']" routerLinkActive #checkerInbox="routerLinkActive"
          [active]="checkerInbox.isActive" *mifosxHasPermission="'ALL_FUNCTIONS_READ'">
          {{'labels.heading.checkerinbox' | translate}}
        </a>
        <a mat-tab-link [routerLink]="['./client-approval']" routerLinkActive #clientApproval="routerLinkActive"
          [active]="clientApproval.isActive" *mifosxHasPermission="'READ_CLIENT'">
          {{'labels.heading.clientapproval' | translate}}
        </a>
        <a mat-tab-link [routerLink]="['./loan-approval']" routerLinkActive #loanApproval="routerLinkActive"
          [active]="loanApproval.isActive" *mifosxHasPermission="'READ_LOAN'">
          {{'labels.heading.loanapproval' | translate}}
        </a>
        <a mat-tab-link [routerLink]="['./loan-disbursal']" routerLinkActive #loanDisbursal="routerLinkActive"
          [active]="loanDisbursal.isActive" *mifosxHasPermission="'READ_LOAN'">
          {{'labels.heading.loandisbursal' | translate}}
        </a>
        <a mat-tab-link [routerLink]="['./reschedule-loan']" routerLinkActive #rescheduleLoan="routerLinkActive"
          [active]="rescheduleLoan.isActive" *mifosxHasPermission="'RESCHEDULE_LOAN'">
          {{'labels.heading.rescheduleLoan' | translate}}
        </a>
        <a mat-tab-link [routerLink]="['./loan-locked']" routerLinkActive #loanLocked="routerLinkActive"
          [active]="loanLocked.isActive">
          {{'labels.heading.loanLocked'| translate}}
        </a>
        <a mat-tab-link [routerLink]="['./account-approval']" routerLinkActive #accountApproval="routerLinkActive"
          [active]="accountApproval.isActive" *mifosxHasPermission="'APPROVE_SAVINGSACCOUNT'">
          {{'labels.heading.accountapproval'| translate}}
        </a>
        <a mat-tab-link [routerLink]="['./account-activation']" routerLinkActive #accountActivation="routerLinkActive"
          [active]="accountActivation.isActive" *mifosxHasPermission="'ACTIVATE_SAVINGSACCOUNT'">
          {{'labels.heading.accountactivation'| translate}}
        </a>
      </nav>

      <router-outlet></router-outlet>

    </mat-card-content>

  </mat-card>

</div>