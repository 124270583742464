<div fxLayout="row wrap" fxLayoutGap="2%" fxLayout.lt-md="column">

  <mat-form-field fxFlex="48%">
    <mat-label>{{"labels.heading.charge" | translate}}</mat-label>
    <mat-select #charge>
      <mat-option *ngFor="let charge of chargeData | chargesFilter:chargesDataSource:currencyCode.value" [value]="charge">
        {{ charge.name }}
      </mat-option>
    </mat-select>
  </mat-form-field>

  <div fxFlex="48%" fxFlexAlign="center">
    <button type="button" mat-raised-button color="primary" (click)="addCharge(charge)" [disabled]="!charge.value">
      <fa-icon icon="plus"></fa-icon>&nbsp;&nbsp;
      {{"labels.buttons.Add" | translate}}
    </button>
  </div>

  <table fxFlex="98%" class="mat-elevation-z1" mat-table [dataSource]="chargesDataSource" [hidden]="chargesDataSource.length === 0">

    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef>{{"labels.inputs.name" | translate}}</th>
      <td mat-cell *matCellDef="let charge">
        {{ charge.name + ', ' + charge.currency.displaySymbol }}
      </td>
    </ng-container>

    <ng-container matColumnDef="chargeCalculationType">
      <th mat-header-cell *matHeaderCellDef>{{"labels.inputs.type" | translate}}</th>
      <td mat-cell *matCellDef="let charge">
        {{ charge.chargeCalculationType.value }}
      </td>
    </ng-container>

    <ng-container matColumnDef="amount">
      <th mat-header-cell *matHeaderCellDef>{{"labels.heading.amount" | translate}}</th>
      <td mat-cell *matCellDef="let charge">
        {{ charge.amount }}
      </td>
    </ng-container>

    <ng-container matColumnDef="chargeTimeType">
      <th mat-header-cell *matHeaderCellDef>{{"labels.heading.collectedon" | translate}}</th>
      <td mat-cell *matCellDef="let charge">
        {{ charge.chargeTimeType.value }}
      </td>
    </ng-container>

    <ng-container matColumnDef="action">
      <th mat-header-cell *matHeaderCellDef>{{"labels.inputs.actions" | translate}} </th>
      <td mat-cell *matCellDef="let charge">
        <button mat-icon-button color="warn" (click)="deleteCharge(charge)">
          <fa-icon icon="trash"></fa-icon>
        </button>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

  </table>

</div>

<div fxLayout="row" class="margin-t" fxLayout.xs="column" fxLayoutAlign="center" fxLayoutGap="2%">
  <button mat-raised-button matStepperPrevious>
    <fa-icon icon="arrow-left"></fa-icon>&nbsp;&nbsp;
    {{"labels.heading.prev" | translate}}
  </button>
  <button mat-raised-button matStepperNext>
    {{"labels.buttons.next" | translate}}&nbsp;&nbsp;
    <fa-icon icon="arrow-right"></fa-icon>
  </button>
</div>
