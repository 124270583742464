<div class="container">

  <mat-card>

    <form [formGroup]="creditBureauForm" (ngSubmit)="submit()">

      <mat-card-content>

        <div fxLayout="column">
          <mat-form-field>
            <mat-label>{{ 'labels.inputs.creditbureau' | translate }}</mat-label>
            <mat-select required formControlName="creditBureauId">
              <mat-option *ngFor="let cbc of CreditBureauConfigurations" [value]="cbc.creditBureauId">
                {{ cbc.creditBureauSummary }}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="creditBureauForm.controls.creditBureauId.hasError('required')">
              {{ 'labels.productLines' | translate }} <strong>{{ 'labels.commons.is required' | translate }}</strong>
            </mat-error>
          </mat-form-field>
        </div>

      </mat-card-content>

      <mat-card-actions fxLayoutGap="5px" fxLayout="row" fsLayout.xs="column" fxLayoutAlign="center">
        <button type="button" mat-raised-button [routerLink]="['../']">{{ 'labels.buttons.cancel' | translate
          }}</button>
        <button mat-raised-button color="primary" [disabled]="!creditBureauForm.valid">{{ 'labels.buttons.submit' |
          translate }}</button>
      </mat-card-actions>

    </form>

  </mat-card>

</div>
