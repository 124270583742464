<div class="container">

  <mat-card>

    <div fxLayout="row" fxLayout.lt-md="column">

      <div fxFlex="50%">

        <mat-nav-list>

          <div #simulator>
            <mat-list-item [routerLink]="['/']">
              <mat-icon matListIcon>
                <fa-icon icon="table" size="sm"></fa-icon>
              </mat-icon>
              <h4 matLine>{{ 'labels.anchors.viewtellers' | translate }}</h4>
              <p matLine>{{ 'labels.anchors.viewtellersDesc' | translate }}</p>
            </mat-list-item>
          </div>

          <div #codes>
            <mat-list-item [routerLink]="['/']">
              <mat-icon matListIcon>
                <fa-icon icon="list-ul" size="sm"></fa-icon>
              </mat-icon>
              <h4 matLine>{{'labels.anchors.tellercontrol' | translate}}</h4>
              <p matLine>{{'labels.anchors.tellercontrolDesc' | translate}}</p>
            </mat-list-item>
          </div>


        </mat-nav-list>

      </div>

      <div fxFlex="50%">

        <mat-nav-list>
          <div #rolesandpermission>
            <mat-list-item [routerLink]="['/']">
              <mat-icon matListIcon>
                <fa-icon icon="key" size="sm"></fa-icon>
              </mat-icon>
              <h4 matLine>{{'labels.anchors.assigncashier' | translate}}</h4>
              <p matLine> {{'labels.anchors.assigncashierDesc' | translate}}</p>
            </mat-list-item>
          </div>

            <mat-list-item [routerLink]="['/']">
              <mat-icon matListIcon>
                <fa-icon icon="anchor" size="sm"></fa-icon>
              </mat-icon>
              <h4 matLine>{{'labels.anchors.tellerstransactions' | translate}} </h4>
              <p matLine>{{'labels.anchors.tellerstransactionsDesc' | translate}}</p>
            </mat-list-item>

        </mat-nav-list>

      </div>

    </div>

  </mat-card>

</div>
