<div class="container">

  <mat-card>

    <form [formGroup]="reactivateClientForm" (ngSubmit)="submit()">

      <mat-card-content>

          <mat-form-field fxFlex (click)="reactivationDatePicker.open()">
            <mat-label>{{ 'labels.inputs.reactivationdate' | translate }}</mat-label>
            <input matInput [min]="minDate" [max]="maxDate" [matDatepicker]="reactivationDatePicker" required formControlName="reactivationDate">
            <mat-datepicker-toggle matSuffix [for]="reactivationDatePicker"></mat-datepicker-toggle>
            <mat-datepicker #reactivationDatePicker></mat-datepicker>
            <mat-error *ngIf="reactivateClientForm.controls.reactivationDate.hasError('required')">
              {{ 'labels.inputs.reactivationdate' | translate }} <strong>{{ 'labels.commons.is required' | translate }}</strong>
            </mat-error>
          </mat-form-field>

      </mat-card-content>

      <mat-card-actions fxLayout="row" fxLayout.xs="column" fxLayoutAlign="center" fxLayoutGap="5px">
        <button type="button" mat-raised-button [routerLink]="['../../']">{{ 'labels.buttons.cancel' | translate }}</button>
        <button mat-raised-button color="primary" [disabled]="!reactivateClientForm.valid">{{ 'labels.buttons.confirm' | translate }}</button>
      </mat-card-actions>

    </form>

  </mat-card>

</div>
