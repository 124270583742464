import { Router } from '@angular/router';
/** Angular Imports */
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';

/** rxjs Imports */
import { EMPTY, Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

/** Environment Configuration */

/** Custom Services */
import { AlertService } from '../alert/alert.service';
import { Logger } from '../logger/logger.service';
import { extract } from '../i18n/i18n.service';
import { environment } from 'environments/environment';

/** Initialize Logger */
const log = new Logger('SuccessHandlerInterceptor');

/**
 * Http Request interceptor to add a default error handler to requests.
 */
@Injectable()
export class SuccessHandlerInterceptor implements HttpInterceptor {

  /**
   * @param {AlertService} alertService Alert Service.
   */
  authenticated = false;
  constructor(private alertService: AlertService,
    private router: Router) {
    this.authenticated = JSON.parse(sessionStorage.getItem("mifosXOAuthTokenDetails"))?.access_token || JSON.parse(localStorage.getItem("mifosXOAuthTokenDetails"))?.access_token
  }

  /**
   * Intercepts a Http request and adds a default error handler.
   */
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if ((request.method == 'POST' || request.method == 'PUT') && !request.url.includes(environment.baseAuthentificationUrl) && !request.urlWithParams.includes("loans?command=calculateLoanSchedule")) {
      return next.handle(request).pipe(tap((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse && event.ok) {
          if (!event.url.includes('batches')) {
            this.alertService.alert({ type: 'success', message: 'labels.successSubmit' });
            this.viewMakerChecker(event)
          } else {
            const failed = event.body.filter((item: any) => item.statusCode !== 200)
            if (failed.length === 0) {
              this.alertService.alert({ type: 'success', message: 'labels.successSubmit' });
            } else {
              let errorMessage = 'labels.somethingWentWrong';
              try {
                const resp = JSON.parse(failed[0].body);
                errorMessage = resp?.defaultUserMessage || resp?.developerMessage || 'labels.somethingWentWrong';
              } catch (e: any) {

              }
              this.alertService.alert({ type: 'error', message: errorMessage });
            }
          }
        }
      }));
    } else {
      return next.handle(request);
    }
  }

  viewMakerChecker(response: HttpResponse<any>) {
    if (response.body && response.body.commandId) {
      //Maker checker is enabled or performing actions of maker checker
      if (response.url.indexOf('makercheckers/') > 0) {
        //return response for maker checker actions(approve or delete)
        return response;
      } else {
        //redirect if maker checker is enabled
        this.router.navigate(['/view-maker-checker-task', response.body.commandId]);
        throw new Error('redirect-to-view-maker-checker-task');
      }
    }
  }

}
