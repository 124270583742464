import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'mifosx-tellers',
  templateUrl: './tellers.component.html',
  styleUrls: ['./tellers.component.scss']
})
export class TellersComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
