<div class="container">

  <mat-card>

    <form [formGroup]="holidayForm" (ngSubmit)="submit()">

      <mat-card-content>

        <div fxLayout="column">

          <mat-form-field>
            <mat-label>{{"labels.inputs.name" | translate}}</mat-label>
            <input matInput required autofocus formControlName="name">
            <mat-error *ngIf="holidayForm.controls.name.hasError('required')">
              {{"labels.inputs.name" | translate}} <strong>{{"labels.commons.is required" | translate}}</strong>
            </mat-error>
          </mat-form-field>

          <mat-form-field *ngIf="!isActiveHoliday" (click)="fromDatePicker.open()">
            <mat-label>{{"labels.inputs.fromdate" | translate}}</mat-label>
            <input matInput [min]="minDate" [max]="maxDate" [matDatepicker]="fromDatePicker" required formControlName="fromDate">
            <mat-datepicker-toggle matSuffix [for]="fromDatePicker"></mat-datepicker-toggle>
            <mat-datepicker #fromDatePicker></mat-datepicker>
            <mat-error *ngIf="holidayForm.controls.fromDate.hasError('required')">
              {{"labels.inputs.fromdate" | translate}} <strong>{{"labels.commons.is required" | translate}}</strong>
            </mat-error>
          </mat-form-field>

          <mat-form-field *ngIf="!isActiveHoliday" (click)="toDatePicker.open()">
            <mat-label>{{"labels.inputs.todate" | translate}}</mat-label>
            <input matInput [min]="minDate" [max]="maxDate" [matDatepicker]="toDatePicker" required formControlName="toDate">
            <mat-datepicker-toggle matSuffix [for]="toDatePicker"></mat-datepicker-toggle>
            <mat-datepicker #toDatePicker></mat-datepicker>
            <mat-error *ngIf="holidayForm.controls.toDate.hasError('required')">
              {{"labels.inputs.todate" | translate}} <strong>{{"labels.commons.is required" | translate}}</strong>
            </mat-error>
          </mat-form-field>

          <mat-form-field *ngIf="!isActiveHoliday">
            <mat-label>{{"labels.repaymentSchedulingType" | translate}}</mat-label>
            <mat-select required formControlName="reschedulingType">
              <mat-option *ngFor="let repayments of holidayData.repaymentSchedulingTypes" [value]="repayments.id">
                {{ repayments.value }}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="holidayForm.controls.reschedulingType.hasError('required')">
              {{"labels.repaymentSchedulingType" | translate}} <strong>{{"labels.commons.is required" | translate}}</strong>
            </mat-error>
          </mat-form-field>

          <mat-form-field *ngIf="!isActiveHoliday && reSchedulingType === 2" (click)="repaymentDatePicker.open()">
            <mat-label>{{"labels.inputs.repaymentsheduleto" | translate}}</mat-label>
            <input matInput [min]="minDate" [max]="maxDate" [matDatepicker]="repaymentDatePicker" required formControlName="repaymentsRescheduledTo">
            <mat-datepicker-toggle matSuffix [for]="repaymentDatePicker"></mat-datepicker-toggle>
            <mat-datepicker #repaymentDatePicker></mat-datepicker>
            <mat-error *ngIf="holidayForm.controls.repaymentsRescheduledTo.hasError('required')">
              {{"labels.inputs.repaymentsheduleto" | translate}} <strong>{{"labels.commons.is required" | translate}}</strong>
            </mat-error>
          </mat-form-field>

          <mat-form-field>
            <mat-label>{{"labels.heading.description" | translate}}</mat-label>
            <input matInput formControlName="description">
          </mat-form-field>

        </div>

      </mat-card-content>

      <mat-card-actions fxLayout="row" fxLayout.xs="column" fxLayoutAlign="center" fxLayoutGap="5px">
        <button type="button" mat-raised-button [routerLink]="['../']">{{"labels.buttons.cancel" | translate}}</button>
        <button mat-raised-button color="primary" [disabled]="!holidayForm.valid" *mifosxHasPermission="'UPDATE_HOLIDAY'">{{"labels.buttons.submit" | translate}}</button>
      </mat-card-actions>

    </form>

  </mat-card>

</div>
