<div class="container">

    <mat-card>

        <form [formGroup]="clientCollateralForm" (ngSubmit)="submit()">

            <mat-card-content>

                <div fxLayout="column">
                    <mat-form-field>
                        <mat-label>{{ 'labels.inputs.collateral' | translate }}</mat-label>
                        <mat-select required formControlName="collateralId">
                            <mat-option *ngFor="let clientCollateral of clientCollateralOptions" [value]="clientCollateral.id">
                                {{ clientCollateral.name }}
                            </mat-option>
                        </mat-select>
                        <mat-error *ngIf="clientCollateralForm.controls.collateralId.hasError('required')">
                            {{ 'labels.inputs.collateral' | translate }} <strong>{{ 'labels.commons.is required' | translate }}</strong>
                        </mat-error>
                    </mat-form-field>
                    
                    <div *ngIf="collateralDetails" fxLayout="column">

                        <mat-form-field>
                            <mat-label>{{ 'labels.inputs.name' | translate }}</mat-label>
                            <input matInput required formControlName="name">
                        </mat-form-field>

                        <mat-form-field>
                            <mat-label>{{ 'labels.heading.typeQuality' | translate }}</mat-label>
                            <input matInput required formControlName="quality">
                        </mat-form-field>

                        <mat-form-field>
                            <mat-label>{{ 'labels.inputs.unittype' | translate }}</mat-label>
                            <input matInput required formControlName="unitType">
                        </mat-form-field>

                        <mat-form-field>
                            <mat-label>{{ 'labels.inputs.baseprice' | translate }}</mat-label>
                            <input matInput required formControlName="basePrice">
                        </mat-form-field>

                        <mat-form-field>
                            <mat-label>{{ 'labels.heading.pctToBase' | translate }}</mat-label>
                            <input matInput required formControlName="pctToBase">
                        </mat-form-field>

                        <mat-form-field>
                            <mat-label>{{ 'labels.heading.quantity' | translate }}</mat-label>
                            <input matInput required formControlName="quantity">
                            <mat-error *ngIf="clientCollateralForm.controls.quantity.hasError('required')">
                                {{ 'labels.heading.quantity' | translate }} <strong>{{ 'labels.commons.is required' | translate }}</strong>
                            </mat-error>
                        </mat-form-field>

                        <mat-form-field>
                            <mat-label>{{ 'labels.inputs.total' | translate }}</mat-label>
                            <input matInput required formControlName="totalValue">
                        </mat-form-field>

                        <mat-form-field>
                            <mat-label>{{ 'labels.heading.totalCollateralValue' | translate }}</mat-label>
                            <input matInput required formControlName="totalCollateralValue">
                        </mat-form-field>

                    </div>

                </div>

            </mat-card-content>

            <mat-card-actions fxLayoutGap="5px" fxLayout="row" fsLayout.xs="column" fxLayoutAlign="center">
                <button type="button" mat-raised-button [routerLink]="['../../']">{{ 'labels.buttons.cancel' | translate }}</button>
                <button mat-raised-button color="primary" [disabled]="!clientCollateralForm.valid">{{ 'labels.buttons.submit' | translate }}</button> 
            </mat-card-actions>

        </form>
    
    </mat-card>

</div>