<mifosx-web-views-top-bar title="labels.heading.summary"></mifosx-web-views-top-bar>
<div class="container">
  <div class="content">
    <mat-card>
      <mat-card-content>
        <div fxFlexFill>
          <span fxFlex="40%">
            A/C :
          </span>
          <span fxFlex="60%">
            {{loanDetailsData?.accountNo}}
          </span>
        </div>
        <div fxFlexFill>
          <span fxFlex="40%">
            {{"labels.inputs.loanproduct" | translate}} :
          </span>
          <span fxFlex="60%">
            {{loanDetailsData?.loanProductName}}
          </span>
        </div>
        <div fxFlexFill>
          <span fxFlex="40%">
            {{"labels.inputs.loanpurpose" | translate}} :
          </span>
          <span fxFlex="60%">
            {{loanDetailsData?.loanPurposeName}}
          </span>
        </div>
      </mat-card-content>
    </mat-card>
    <mat-card>
      <mat-card-content>
        <div fxFlexFill>
          <span fxFlex="40%">
            {{"labels.inputs.principal" | translate}} :
          </span>
          <span fxFlex="60%">
            {{loanDetailsData?.principal}} {{loanDetailsData?.currency?.displaySymbol}}
          </span>
        </div>
        <div fxFlexFill>
          <span fxFlex="40%">
            {{"labels.heading.interest" | translate}} :
          </span>
          <span fxFlex="60%">
            {{loanDetailsData?.summary?.interestCharged}} {{loanDetailsData?.currency?.displaySymbol}}
          </span>
        </div>
        <div fxFlexFill>
          <span fxFlex="40%">
            {{"labels.heading.fees" | translate}} :
          </span>
          <span fxFlex="60%">
            {{loanDetailsData?.summary?.feeChargesCharged}} {{loanDetailsData?.currency?.displaySymbol}}
          </span>
        </div>
        <div fxFlexFill>
          <span fxFlex="40%">
            {{"labels.heading.penalities" | translate}} :
          </span>
          <span fxFlex="60%">
            {{loanDetailsData?.summary?.penaltyChargesCharged}} {{loanDetailsData?.currency?.displaySymbol}}
          </span>
        </div>
      </mat-card-content>
    </mat-card>
    <mat-card>
      <mat-card-content>
        <div fxFlexFill>
          <span fxFlex="40%">
            {{"labels.totalExpectedRepayment" | translate}} :
          </span>
          <span fxFlex="60%">
            {{loanDetailsData?.summary?.totalExpectedRepayment}} {{loanDetailsData?.currency?.displaySymbol}}
          </span>
        </div>
        <div fxFlexFill>
          <span fxFlex="40%">
            {{"labels.totalPaid" | translate}} :
          </span>
          <span fxFlex="60%">
            {{loanDetailsData?.summary?.totalRepayment}} {{loanDetailsData?.currency?.displaySymbol}}
          </span>
        </div>
        <div fxFlexFill>
          <span fxFlex="40%">
            {{"labels.interestwaived" | translate}} :
          </span>
          <span fxFlex="60%">
            {{loanDetailsData?.summary?.interestWaived}} {{loanDetailsData?.currency?.displaySymbol}}
          </span>
        </div>
        <div fxFlexFill>
          <span fxFlex="40%">
            {{"labels.penalitieswaived" | translate}} :
          </span>
          <span fxFlex="60%">
            {{loanDetailsData?.summary?.penaltyChargesWaived}} {{loanDetailsData?.currency?.displaySymbol}}
          </span>
        </div>
        <div fxFlexFill>
          <span fxFlex="40%">
            {{"labels.feewaived" | translate}} :
          </span>
          <span fxFlex="60%">
            {{loanDetailsData?.summary?.feeChargesWaived}} {{loanDetailsData?.currency?.displaySymbol}}
          </span>
        </div>
      </mat-card-content>
    </mat-card>

    <mat-card>
      <mat-card-content>
        <div fxFlexFill>
          <span fxFlex="40%">
            {{"labels.outstandingBalance" | translate}} :
          </span>
          <span fxFlex="60%">
            {{loanDetailsData?.summary?.totalOutstanding}} {{loanDetailsData?.currency?.displaySymbol}}
          </span>
        </div>
        <div fxFlexFill>
          <span fxFlex="40%">
            {{"labels.accountStatus" | translate}} :
          </span>
          <span fxFlex="60%">
            <p *ngIf="loanDetailsData?.status?.active">
              <span class="active"></span> {{"labels.inputs.active" | translate}}
            </p>
            <p *ngIf="!loanDetailsData?.status?.active">
              <span class="inactive"></span> {{"labels.heading.inactive" | translate}}
            </p>
          </span>
        </div>

      </mat-card-content>
    </mat-card>
  </div>
  <mifosx-web-views-logo></mifosx-web-views-logo>
</div>
