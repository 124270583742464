<div class="container">

  <mat-card>

    <form [formGroup]="settleCashForm" (ngSubmit)="submit()">

      <mat-card-content>

        <div fxLayout="row wrap" fxLayoutGap="2%" fxLayout.lt-md="column">

          <mat-form-field fxFlex="48%">
            <mat-label>{{"labels.inputs.office" | translate}}</mat-label>
            <input matInput required formControlName="office">
          </mat-form-field>

          <mat-form-field fxFlex="48%">
            <mat-label>{{"labels.inputs.teller.name" | translate}}</mat-label>
            <input matInput required formControlName="tellerName">
          </mat-form-field>

          <mat-form-field fxFlex="48%">
            <mat-label>{{"labels.heading.cashmgmt.cashierName" | translate}}</mat-label>
            <input matInput required formControlName="cashier">
          </mat-form-field>

          <mat-form-field fxFlex="48%">
            <mat-label>{{"labels.inputs.cashierallocationdetails" | translate}}</mat-label>
            <input matInput formControlName="assignmentPeriod">
          </mat-form-field>

          <mat-form-field fxFlex="48%" (click)="cashDatePicker.open()">
            <mat-label>{{"labels.heading.date" | translate}}</mat-label>
            <input matInput [min]="minDate" [max]="maxDate" [matDatepicker]="cashDatePicker" required formControlName="txnDate">
            <mat-datepicker-toggle matSuffix [for]="cashDatePicker"></mat-datepicker-toggle>
            <mat-datepicker #cashDatePicker></mat-datepicker>
            <mat-error *ngIf="settleCashForm.controls.txnDate.hasError('required')">
              {{"labels.heading.date" | translate}} <strong>{{"labels.commons.is required" | translate}}</strong>
            </mat-error>
          </mat-form-field>

          <mat-form-field fxFlex="48%">
            <mat-label>{{"labels.inputs.currency" | translate}}</mat-label>
            <mat-select required formControlName="currencyCode">
              <mat-option *ngFor="let currency of cashierData.currencyOptions" [value]="currency.code">
                {{ currency.name }}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="settleCashForm.controls.currencyCode.hasError('required')">
              {{"labels.inputs.currency" | translate}} <strong>{{"labels.commons.is required" | translate}}</strong>
            </mat-error>
          </mat-form-field>

          <mat-form-field fxFlex="48%">
            <mat-label>{{"labels.heading.amount" | translate}}</mat-label>
            <input matInput required formControlName="txnAmount">
            <mat-error *ngIf="settleCashForm.controls.txnAmount.hasError('required')">
              {{"labels.heading.amount" | translate}} <strong>{{"labels.commons.is required" | translate}}</strong>
            </mat-error>
          </mat-form-field>

          <mat-form-field fxFlex="48%">
            <mat-label>{{"labels.inputs.teller.cashiertxn.txnNote" | translate}}</mat-label>
            <input matInput required formControlName="txnNote">
            <mat-error *ngIf="settleCashForm.controls.txnNote.hasError('required')">
              {{"labels.inputs.teller.cashiertxn.txnNote" | translate}} <strong>{{"labels.commons.is required" | translate}}</strong>
            </mat-error>
          </mat-form-field>

        </div>

      </mat-card-content>

      <mat-card-actions fxLayout="row" fxLayout.xs="column" fxLayoutAlign="center" fxLayoutGap="5px">
        <button type="button" mat-raised-button [routerLink]="['../']">{{"labels.buttons.cancel" | translate}}</button>
        <button mat-raised-button color="primary" [disabled]="!settleCashForm.valid" *mifosxHasPermission="'SETTLECASHFROMCASHIER_TELLER'">{{"labels.buttons.settlecash" | translate}}</button>
      </mat-card-actions>

    </form>

  </mat-card>

</div>
