import {
  Component, OnInit, Input, TemplateRef, ElementRef, ViewChild,
  AfterViewInit, HostBinding, ViewEncapsulation
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';

/** Custom Components */
import { KeyboardShortcutsDialogComponent } from 'app/shared/keyboard-shortcuts-dialog/keyboard-shortcuts-dialog.component';

/** Custom Services */
import { AuthenticationService } from '../../authentication/authentication.service';
import { PopoverService } from '../../../configuration-wizard/popover/popover.service';
import { ConfigurationWizardService } from '../../../configuration-wizard/configuration-wizard.service';

/** Custom Imports */
import { frequentActivities } from './frequent-activities';
import { NavigationService } from '../../../navigation/navigation.service';
import { ConfigurationWizardComponent } from '../../../configuration-wizard/configuration-wizard.component';
import { HasPermission } from 'app/directives/has-permission/has-permission';

/**
 * Sidenav component.
 */
@Component({
  selector: 'mifosx-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SidenavComponent implements OnInit, AfterViewInit {

  /** True if sidenav is in collapsed state. */
  @Input() sidenavCollapsed: boolean;

  showMenu: any = {};

  /** Username of authenticated user. */
  username: string;
  /** Array of all user activities */
  userActivity: string[];
  /** Mapped Activites */
  mappedActivities: any[] = [];
  /** Collection of possible frequent activities */
  frequentActivities: any[] = frequentActivities;

  /* Refernce of logo */
  @ViewChild('logo') logo: ElementRef<any>;
  /* Template for popover on logo */
  @ViewChild('templateLogo') templateLogo: TemplateRef<any>;
  /* Refernce of chart of accounts */
  @ViewChild('chartOfAccounts') chartOfAccounts: ElementRef<any>;
  /* Template for popover on chart of accounts */
  @ViewChild('templateChartOfAccounts') templateChartOfAccounts: TemplateRef<any>;

  /**
   * @param {Router} router Router for navigation.
   * @param {MatDialog} dialog Mat Dialog
   * @param {AuthenticationService} authenticationService Authentication Service.
   * @param {ConfigurationWizardService} configurationWizardService ConfigurationWizard Service.
   * @param {PopoverService} popoverService PopoverService.
   */
  constructor(public navService: NavigationService, private router: Router,
    public dialog: MatDialog,
    private authenticationService: AuthenticationService,
    private configurationWizardService: ConfigurationWizardService,
    private popoverService: PopoverService,
    private hasPermission :HasPermission) {
    this.userActivity = JSON.parse(localStorage.getItem('mifosXLocation'));
  }

  /**
   * Sets the username of the authenticated user.
   */
  ngOnInit() {
    const credentials = this.authenticationService.getCredentials();
    this.username = credentials.username;
    this.setMappedAcitivites();
  }


  openMenu(element: string) {
    this.showMenu[element] = !this.showMenu[element];
    console.log("click!!");
  }

  /**
   * Logs out the authenticated user and redirects to login page.
   */
  logout() {
    this.authenticationService.logout()
      .subscribe(() => this.router.navigate(['/login'], { replaceUrl: true }));
  }

  /**
   * OpensBank of AfricaJIRA Wiki page.
   */
  help() {
    window.open('', '_blank');
  }


  /**
   * Opens Keyboard shortcuts dialog.
   */
  showKeyboardShortcuts() {
    const dialogRef = this.dialog.open(KeyboardShortcutsDialogComponent);
    dialogRef.afterClosed().subscribe((response: any) => { });
  }

  /**
   * Returns top three frequent activities.
   */
  getFrequentActivities() {
    const frequencyCounts: any = {};
    let index = this.userActivity.length;
    while (index) {
      frequencyCounts[this.userActivity[--index]] = (frequencyCounts[this.userActivity[index]] , 0) + 1;
    }
    const frequencyCountsArray = Object.entries(frequencyCounts);
    const topThreeFrequentActivities =
      frequencyCountsArray
        .sort((a: any, b: any) => b[1] - a[1])
        .map((entry: any[]) => entry[0])
        .filter((activity: string) => !['/', '/login', '/home', '/dashboard'].includes(activity))
        .slice(0, 3);
    return topThreeFrequentActivities;
  }

  /**
   * Maps frequently accessed urls to button objects.
   */
  setMappedAcitivites() {
    const activities: string[] = this.getFrequentActivities();
    activities.forEach((activity: string) => {
      if (activity.includes('/clients')) {
        this.pushActivity('/clients');
      } else if (activity.includes('/groups')) {
        this.pushActivity('/groups');
      } else if (activity.includes('/centers')) {
        this.pushActivity('/centers');
      } else if (activity.includes('/accounting')) {
        this.pushActivity('/accounting');
      } else if (activity.includes('/reports')) {
        this.pushActivity('/reports');
      } else if (activity.includes('/users')) {
        this.pushActivity('/users');
      } else if (activity.includes('/organization')) {
        this.pushActivity('/organization');
      } else if (activity.includes('/system')) {
        this.pushActivity('/system');
      } else if (activity.includes('/products')) {
        this.pushActivity('/products');
      } else if (activity.includes('/templates')) {
        this.pushActivity('/templates');
      } else if (activity.includes('/self-service')) {
        this.pushActivity('/self-service');
      }
    });
    this.mappedActivities.reverse();
  }

  /**
   * Pushes activity to mapped activities
   * @param {string} path Activity Path
   */
  pushActivity(path: string) {
    const activity = this.frequentActivities.find((entry: any) => entry.path === path);
    if (!this.mappedActivities.includes(activity)) {
      this.mappedActivities.push(activity);
    }
  }

  /**
   * Popover function
   * @param template TemplateRef<any>.
   * @param target HTMLElement | ElementRef<any>.
   * @param position String.
   * @param backdrop Boolean.
   */
  showPopover(template: TemplateRef<any>, target: HTMLElement | ElementRef<any>, position: string, backdrop: boolean): void {
    setTimeout(() => this.popoverService.open(template, target, position, backdrop, {}), 200);
  }

  /**
   * To show popovers
   */
  ngAfterViewInit() {
    if (this.configurationWizardService.showSideNav === true) {
      setTimeout(() => {
        this.showPopover(this.templateLogo, this.logo.nativeElement, 'bottom', true);
      });
    }
    if (this.configurationWizardService.showSideNavChartofAccounts === true) {
      setTimeout(() => {
        this.showPopover(this.templateChartOfAccounts, this.chartOfAccounts.nativeElement, 'top', true);
      });
    }
  }

  /**
   * Next Step (Breadcrumbs) Configuration Wizard.
   */
  nextStep() {
    this.configurationWizardService.showSideNav = false;
    this.configurationWizardService.showSideNavChartofAccounts = false;
    this.configurationWizardService.showBreadcrumbs = true;
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.router.onSameUrlNavigation = 'reload';
    this.router.navigate(['/home']);
  }

  /**
   * Previous Step (Toolbar) Configuration Wizard.
   */
  previousStep() {
    this.configurationWizardService.showSideNav = false;
    this.configurationWizardService.showSideNavChartofAccounts = false;
    this.configurationWizardService.showToolbarAdmin = true;
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.router.onSameUrlNavigation = 'reload';
    this.router.navigate(['/home']);
  }


  productsPermission = this.hasPermission.hasOneOfThosePermissions(["READ_LOANPRODUCT" , "READ_SAVINGSPRODUCT" , "READ_SHAREPRODUCT" , "READ_CHARGE" , "READ_RATE" , "READ_COLLATERALS" , "READ_PRODUCTMIX" , "READ_FIXEDDEPOSITPRODUCT" , "READ_RECURRINGDEPOSITPRODUCT" , "READ_TAXGROUP" , "READ_FLOATINGRATE"]);
  roductsPermission = this.hasPermission.hasOneOfThosePermissions(["READ_LOANPRODUCT" , "READ_SAVINGSPRODUCT" , "READ_SHAREPRODUCT" , "READ_CHARGE" , "READ_RATE" , "READ_COLLATERALS" , "READ_PRODUCTMIX" , "READ_FIXEDDEPOSITPRODUCT" , "READ_RECURRINGDEPOSITPRODUCT" , "READ_TAXGROUP" , "READ_FLOATINGRATE"]);
  systemPermission = this.hasPermission.hasOneOfThosePermissions(["READ_DATATABLE" , "READ_CODE" , "READ_ROLE" , "READ_PERMISSION" , "READ_AUDIT" , "READ_REPORT" , "READ_SCHEDULER" , "READ_CONFIGURATION" , "READ_CONFIGURATION"]);
  organizationPermission = this.hasPermission.hasOneOfThosePermissions(["READ_OFFICE" , "READ_HOLIDAY" , "READ_STAFF" , "READ_STANDINGINSTRUCTION" , "READ_PASSWORD_VALIDATION_POLICY" , "VIEW_PROVISIONS" , "READ_ENTITY_DATATABLE_CHECK" , "VIEW_ADHOC" , "READ_CURRENCY" , "READ_FUND" , "BULKREASSIGN_LOAN" , "READ_TELLER" , "READ_WORKINGDAYS" , "READ_PAYMENTTYPE" , "VIEW_SMSCAMPAIGNS" , "VIEW_BULKIMPORT"]);



  navItems: any[] = [
    {
      displayName: 'labels.menus.Dashboard',
      iconName: 'tachometer-alt',
      route: '',
      permission: true
    },
    {
      displayName: 'labels.menus.Checker Inbox and Tasks',
      iconName: 'check',
      route: 'checker-inbox-and-tasks/checker-inbox',
      permission: this.hasPermission.hasOneOfThosePermissions(["ALL_FUNCTIONS_READ" , "READ_CLIENT" , "ACTIVATE_CLIENT" , "READ_LOAN" , "APPROVE_LOAN" , "RESCHEDULE_LOAN" , "APPROVE_LOANRESCHEDULE"])
    },
    {
      displayName: 'labels.menus.Admin',
      iconName: 'shield-alt',
      route: '',
      permission: this.productsPermission || this.systemPermission || this.organizationPermission || this.hasPermission.hasOneOfThosePermissions(["READ_USER" , "READ_TEMPLATE"]),
      children: [
        {
          displayName: 'labels.menus.Users',
          iconName: "users",
          route: 'users',
          permission: this.hasPermission.hasOneOfThosePermissions(["READ_USER"]),
          children: []
        }, {
          displayName: 'labels.menus.Organization',
          iconName: "building",
          route: 'organization',
          children: [],
          permission: this.organizationPermission
        }, {
          displayName: 'labels.menus.System',
          iconName: "sync",
          route: 'system',
          children: [],
          permission: this.systemPermission
        }, {
          displayName: 'labels.menus.Products',
          iconName: "box",
          route: 'products',
          children: [],
          permission: this.productsPermission
        }, {
          displayName: 'labels.menus.Templates',
          iconName: "file",
          route: 'templates',
          children: [],
          permission: this.hasPermission.hasOneOfThosePermissions(["READ_TEMPLATE"])
        },
        {
          displayName: 'labels.menus.Self Service',
          iconName: "money-check",
          route: '',
          permission: true,
          children: [
            {
              displayName: 'labels.menus.userManagement',
              iconName: "users",
              route: 'self-service/users',
              children: [],
              permission: true
            },
            {
              displayName: 'labels.menus.appConfiguration',
              iconName: "sync",
              route: 'self-service/app-configuration',
              children: [],
              permission: true
            },
            {
              displayName: 'labels.menus.taskManagement',
              iconName: "file-alt",
              route: 'self-service/task-management',
              children: [],
              permission: true
            },
          ]
        },
        {
          displayName: 'labels.menus.Configuration Wizard',
          iconName: "info",
          route: '',
          wizard: true,
          children: [],
          permission: true,
        },
      ]
    },
    {
      displayName: 'labels.menus.Clients',
      iconName: 'user',
      route: 'clients',
      permission: this.hasPermission.hasOneOfThosePermissions(["READ_CLIENT" , "CREATE_CLIENT"]),
      children: [
        {
          displayName: 'labels.menus.All',
          iconName: 'list',
          route: 'clients',
          children: [],
          permission: this.hasPermission.hasOneOfThosePermissions(["READ_CLIENT"])
        },
        {
          displayName: 'labels.anchors.createclient',
          iconName: 'plus',
          route: 'clients/create',
          children: [],
          permission: this.hasPermission.hasOneOfThosePermissions(["CREATE_CLIENT"])
        },
      ]
    },
    {
      displayName: 'labels.menus.Groups',
      iconName: 'users',
      route: '',
      permission: this.hasPermission.hasOneOfThosePermissions(["READ_GROUP" , "CREATE_GROUP"]),
      children: [
        {
          displayName: 'labels.menus.All',
          iconName: 'list',
          route: 'groups',
          children: [],
          permission: this.hasPermission.hasOneOfThosePermissions(["READ_GROUP"]),
        },
        {
          displayName: 'labels.anchors.creategroup',
          iconName: 'plus',
          route: 'groups/create',
          children: [],
          permission: this.hasPermission.hasOneOfThosePermissions(["CREATE_GROUP"]),
        },
      ]
    },
    {
      displayName: 'labels.menus.Centers',
      iconName: 'map-marker',
      route: '',
      permission: this.hasPermission.hasOneOfThosePermissions(["READ_CENTERS" , "CREATE_CENTER"]),
      children: [
        {
          displayName: 'labels.menus.All',
          iconName: 'list',
          route: 'centers',
          children: [],
          permission: this.hasPermission.hasOneOfThosePermissions(["READ_CENTERS"])
        },
        {
          displayName: 'labels.anchors.createcenter',
          iconName: 'plus',
          route: 'centers/create',
          children: [],
          permission: this.hasPermission.hasOneOfThosePermissions(["CREATE_CENTER"])
        },
      ]
    },
    {
      displayName: 'Operations',
      iconName: 'sync',
      route: 'dashboard',
      permission: this.hasPermission.hasOneOfThosePermissions(["READ_TELLER" , "READ_ACCOUNTTRANSFERS" , "READ_SAVINGSACCOUNT" , "READ_LOAN"]),
      children: [
        {
          displayName: 'labels.menus.Tellers',
          iconName: 'money-bill-wave-alt',
          route: 'operations/tellers',
          children: [],
          permission: this.hasPermission.hasOneOfThosePermissions(["READ_TELLER"])
        },
        {
          displayName: 'labels.menus.Accounts',
          iconName: 'user',
          route: 'operations/accounts',
          children: [],
          permission: this.hasPermission.hasOneOfThosePermissions(["READ_SAVINGSACCOUNT"])
        },
        {
          displayName: 'labels.menus.Transactions',
          iconName: 'exchange-alt',
          route: 'operations/transfer',
          children: [],
          permission: this.hasPermission.hasOneOfThosePermissions(["READ_ACCOUNTTRANSFERS"])
        },
        {
          displayName: 'labels.menus.Loans',
          iconName: 'wallet',
          route: 'operations/loans',
          children: [],
          permission: this.hasPermission.hasOneOfThosePermissions(["READ_LOAN"])

        }
      ]
    },
    {
      displayName: 'labels.menus.Accounting',
      iconName: 'money-bill-alt',
      route: '',
      permission: this.hasPermission.hasOneOfThosePermissions(["CREATE_JOURNALENTRY" , "CREATE_JOURNALENTRY" , "READ_JOURNALENTRY" , "READ_FINANCIALACTIVITYACCOUNT" , "READ_JOURNALENTRY" , "READ_GLACCOUNT" , "READ_GLCLOSURE" , "READ_ACCOUNTINGRULE" , "EXECUTE_PERIODICACCRUALACCOUNTING" , "VIEW_PROVISIONING_ENTRIES"]),
      children: [
        {
          displayName: 'labels.menus.All',
          iconName: 'list',
          route: 'accounting',
          children: []
        },
        {
          displayName: 'labels.anchors.frequentpostings',
          iconName: 'sync',
          route: 'accounting/journal-entries/frequent-postings',
          children: [],
          permission: this.hasPermission.hasOneOfThosePermissions(["CREATE_JOURNALENTRY"])
        },
        {
          displayName: 'labels.anchors.addjournalentries',
          iconName: 'plus',
          route: 'accounting/journal-entries/create',
          children: [],
          permission: this.hasPermission.hasOneOfThosePermissions(["CREATE_JOURNALENTRY"])
        },
        {
          displayName: 'labels.anchors.searchjournalentries',
          iconName: 'search',
          route: 'accounting/journal-entries',
          children: [],
          permission: this.hasPermission.hasOneOfThosePermissions(["READ_JOURNALENTRY"])
        },
        {
          displayName: 'labels.anchors.financialactivity.accountmappings',
          iconName: 'link',
          route: 'accounting/financial-activity-mappings',
          children: [],
          permission: this.hasPermission.hasOneOfThosePermissions(["READ_FINANCIALACTIVITYACCOUNT"])
        },
        {
          displayName: 'labels.anchors.define.openingbalances',
          iconName: 'hand-holding-usd',
          route: 'accounting/migrate-opening-balances',
          children: [],
          permission: this.hasPermission.hasOneOfThosePermissions(["READ_JOURNALENTRY"])
        },
        {
          displayName: 'labels.anchors.chartofaccounts',
          iconName: 'sitemap',
          route: 'accounting/chart-of-accounts',
          children: [],
          permission: this.hasPermission.hasOneOfThosePermissions(["READ_GLACCOUNT"])
        },
        {
          displayName: 'labels.anchors.closingentries',
          iconName: 'archive',
          route: 'accounting/closing-entries',
          children: [],
          permission: this.hasPermission.hasOneOfThosePermissions(["READ_GLCLOSURE"])
        },
        {
          displayName: 'labels.anchors.accountingrules',
          iconName: 'list',
          route: 'accounting/accounting-rules',
          children: [],
          permission: this.hasPermission.hasOneOfThosePermissions(["READ_ACCOUNTINGRULE"])
        },
        {
          displayName: 'labels.heading.execute.periodic.accounting',
          iconName: 'calendar',
          route: 'accounting/periodic-accruals',
          children: [],
          permission: this.hasPermission.hasOneOfThosePermissions(["EXECUTE_PERIODICACCRUALACCOUNTING"])
        },
        {
          displayName: 'labels.heading.provisioningEntries',
          iconName: 'cogs',
          route: 'accounting/provisioning-entries',
          children: [],
          permission: this.hasPermission.hasOneOfThosePermissions(["VIEW_PROVISIONING_ENTRIES"])
        },
      ]
    },
    {
      displayName: 'labels.menus.Reports',
      iconName: 'chart-bar',
      route: '',
      permission: this.hasPermission.hasOneOfThosePermissions(["READ_REPORT"]),
      children: [
        {
          displayName: 'labels.menus.All',
          iconName: 'list',
          route: 'reports',
          children: [],
          permission: this.hasPermission.hasOneOfThosePermissions(["READ_REPORT"])
        },
        {
          displayName: 'labels.menus.Clients',
          iconName: 'users',
          route: 'reports/Client',
          children: [],
          permission: this.hasPermission.hasOneOfThosePermissions(["READ_REPORT"])
        },
        {
          displayName: 'labels.menus.Loans',
          iconName: 'wallet',
          route: 'reports/Loan',
          children: [],
          permission: this.hasPermission.hasOneOfThosePermissions(["READ_REPORT"])
        },
        {
          displayName: 'labels.menus.Savings',
          iconName: 'hand-holding-usd',
          route: 'reports/Savings',
          children: [],
          permission: this.hasPermission.hasOneOfThosePermissions(["READ_REPORT"])
        },
        {
          displayName: 'labels.menus.Funds',
          iconName: 'exchange-alt',
          route: 'reports/Funds',
          children: [],
          permission: this.hasPermission.hasOneOfThosePermissions(["READ_REPORT"])
        },
        {
          displayName: 'labels.menus.Accounting',
          iconName: 'money-bill-alt',
          route: 'reports/Accounting',
          children: [],
          permission: this.hasPermission.hasOneOfThosePermissions(["READ_REPORT"])
        },
        {
          displayName: 'labels.menus.XBRL',
          iconName: 'file',
          route: 'xbrl',
          children: [],
          permission: this.hasPermission.hasOneOfThosePermissions(["READ_REPORT"])
        }
      ]
    },
    {
      displayName: 'labels.menus.Help',
      iconName: 'question-circle',
      route: '',
      permission: true,
    }
  ];
}
