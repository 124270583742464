import { SystemService } from 'app/system/system.service';
import { Component, OnInit, TemplateRef, ElementRef, ViewChild, AfterViewInit } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';


@Component({
  selector: 'mifosx-credit-bureau',
  templateUrl: './credit-bureau.component.html',
  styleUrls: ['./credit-bureau.component.scss']
})
export class CreditBureauComponent implements OnInit {

  creditBureauSummary: any;
  creditBureauMappings: any;
  creditBureauColumns: string[] = ['creditbureauId', 'alias', 'cbName', 'cbproduct', 'country', 'is_active', 'active/inactive'];
  cbMappingsColumns: string[] = ['id', 'alias', 'cbsummary', 'loanproduct', 'is_creditcheck_mandatory', 'skip_credit_check_in_failure', 'stale_period', 'is_active', 'active/inactive'];
  dataSource: MatTableDataSource<any>;
  dataSourceMapping: MatTableDataSource<any>;

  @ViewChild(MatSort, { static: true }) sort: MatSort;

  /**
   * @param {ActivatedRoute} route ActivatedRoute.
   * @param {Router} router Router.
   * @param {ConfigurationWizardService} configurationWizardService ConfigurationWizard Service.
   * @param {PopoverService} popoverService PopoverService.
   */
  constructor(private route: ActivatedRoute,
    private router: Router,
    private systemService: SystemService) {
    this.route.data.subscribe((data: { creditBureauSummary: any, creditBureauMappings: any }) => {
      this.creditBureauSummary = [];
      let cbIndex = 0;
      this.creditBureauMappings = [];
      let cbMappingsIndex = 0;
      for (var i in data.creditBureauSummary) {
        if (data.creditBureauSummary[i] != null && data.creditBureauSummary[i].organisationCreditBureauId != null) {
          cbIndex++;
          this.creditBureauSummary.push({ ...data.creditBureauSummary[i], index: cbIndex });
        }
      }
      for (var i in data.creditBureauMappings) {
        if (data.creditBureauMappings[i] != null && data.creditBureauMappings[i].creditbureauLoanProductMappingId != null) {
          cbMappingsIndex++;
          this.creditBureauMappings.push({ ...data.creditBureauMappings[i], index: cbMappingsIndex });
        }
      }
    });
  }

  ngOnInit() {
    this.dataSource = new MatTableDataSource(this.creditBureauSummary);
    this.dataSource.sort = this.sort;

    this.dataSourceMapping = new MatTableDataSource(this.creditBureauMappings);
    this.dataSource.sort = this.sort;
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  changeCbStatus(cb: any) {
    var formData = {
      creditBureauId: cb.organisationCreditBureauId,
      isActive: !cb.isActive
    };
    this.systemService.updateCreditBureauSummary(formData).subscribe((data: any) => {
      this.reload();
    })
  }
  changeCbMappingStatus(cb: any) {
    var formData = {
      creditbureauLoanProductMappingId: cb.creditbureauLoanProductMappingId,
      isActive: !cb.isActive
    };
    this.systemService.updateCreditBureauMapping(formData).subscribe((data: any) => {
      this.reload();
    })
  }


  reload() {
    const url: string = this.router.url;
    this.router.navigateByUrl(``, { skipLocationChange: true })
      .then(() => this.router.navigate([url]));
  }

}


