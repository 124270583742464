<div fxLayout="row wrap" fxLayout.lt-md="column">

  <h2 class="mat-h2" fxFlexFill>{{ shareProduct.name }}</h2>

  <mat-divider fxFlexFill></mat-divider>

  <h3 class="mat-h3" fxFlexFill>{{"labels.buttons.details" | translate}}</h3>

  <mat-divider fxFlexFill></mat-divider>

  <div fxFlexFill>
    <span fxFlex="40%">{{"labels.inputs.product.shortname" | translate}}:</span>
    <span fxFlex="60%">{{ shareProduct.shortName }}</span>
  </div>

  <div fxFlexFill *ngIf="shareProduct.description">
    <span fxFlex="40%">{{"labels.heading.description" | translate}}:</span>
    <span fxFlex="60%">{{ shareProduct.description }}</span>
  </div>

  <h3 class="mat-h3" fxFlexFill>{{"labels.inputs.currency" | translate}}</h3>

  <mat-divider fxFlexFill></mat-divider>

  <div fxFlexFill>
    <span fxFlex="40%">{{"labels.heading.currency" | translate}}:</span>
    <span fxFlex="60%">{{ shareProduct.currencyCode | find:shareProductsTemplate.currencyOptions:'code':'name' }}</span>
  </div>

  <div fxFlexFill>
    <span fxFlex="40%">{{"labels.inputs.decimalplace" | translate}}:</span>
    <span fxFlex="60%">{{ shareProduct.digitsAfterDecimal }}</span>
  </div>

  <div fxFlexFill>
    <span fxFlex="40%">{{"labels.heading.currencyinmultiplesof" | translate}}:</span>
    <span fxFlex="60%">{{ shareProduct.inMultiplesOf }}</span>
  </div>

  <h3 class="mat-h3" fxFlexFill>{{"labels.terms" | translate}}</h3>

  <mat-divider fxFlexFill></mat-divider>

  <div fxFlexFill>
    <span fxFlex="40%">{{"labels.inputs.totalnumberofshares" | translate }}</span>
    <span fxFlex="60%">{{ shareProduct.totalShares }}</span>
  </div>

  <div fxFlexFill>
    <span fxFlex="40%">{{"labels.inputs.totalsharestobeissue" | translate }}</span>
    <span fxFlex="60%">{{ shareProduct.sharesIssued }}</span>
  </div>

  <div fxFlexFill>
    <span fxFlex="40%">{{"labels.heading.sharenominalprice" | translate }}</span>
    <span fxFlex="60%">{{ shareProduct.unitPrice }}</span>
  </div>

  <div fxFlexFill>
    <span fxFlex="40%">{{"labels.inputs.capitalvalue" | translate }}:</span>
    <span fxFlex="60%">{{ shareProduct.shareCapital }}</span>
  </div>

  <h3 class="mat-h3" fxFlexFill>{{"labels.menus.Settings" | translate}}</h3>

  <mat-divider fxFlexFill></mat-divider>

  <div fxFlexFill>
    <span fxFlex="40%">{{"labels.inputs.sharesperclient" | translate }}:</span>
    <span fxFlex="60%">{{ shareProduct.nominalShares + ' (Min: ' + (shareProduct.minimumShares ? shareProduct.minimumShares : shareProduct.nominalShares) + ', Max: ' + (shareProduct.maximumShares ? shareProduct.maximumShares : shareProduct.nominalShares) + ')' }}</span>
  </div>

  <div fxFlexFill *ngIf="shareProduct.minimumActivePeriodForDividends">
    <span fxFlex="40%">{{"labels.inputs.minimumactiveperiodt" | translate }}:</span>
    <span fxFlex="60%">{{ shareProduct.minimumActivePeriodForDividends + ' ' + (shareProduct.minimumactiveperiodFrequencyType | find:shareProductsTemplate.minimumActivePeriodFrequencyTypeOptions:'id':'value') }}</span>
  </div>

  <div fxFlexFill *ngIf="shareProduct.lockinPeriodFrequency">
    <span fxFlex="40%">{{"labels.heading.lockinPeriodFrequency" | translate}}:</span>
    <span fxFlex="60%">{{ shareProduct.lockinPeriodFrequency + ' ' + (shareProduct.lockinPeriodFrequencyType | find:shareProductsTemplate.lockinPeriodFrequencyTypeOptions:'id':'value') }}</span>
  </div>

  <div fxFlexFill>
    <span fxFlex="40%">{{"labels.inputs.allowdividendsforinactiveclients" | translate }}:</span>
    <span fxFlex="60%">{{ shareProduct.allowDividendCalculationForInactiveClients ? 'Yes' : 'No' }}</span>
  </div>

  <div fxFlexFill *ngIf="shareProduct.marketPricePeriods.length" fxLayout="row wrap" fxLayout.lt-md="column">

    <h3 class="mat-h3" fxFlexFill>{{"labels.inputs.marketpriceperiods" | translate }}:</h3>

    <mat-divider fxFlexFill></mat-divider>

    <table fxFlexFill class="mat-elevation-z1" mat-table [dataSource]="shareProduct.marketPricePeriods">

      <ng-container matColumnDef="fromDate">
        <th mat-header-cell *matHeaderCellDef>{{"labels.heading.fromdate" | translate }}</th>
        <td mat-cell *matCellDef="let marketPricePeriod">
          {{ marketPricePeriod.fromDate  | dateFormat }}
        </td>
      </ng-container>

      <ng-container matColumnDef="shareValue">
        <th mat-header-cell *matHeaderCellDef>{{"labels.heading.sharenominalprice" | translate }}</th>
        <td mat-cell *matCellDef="let marketPricePeriod">
          {{ marketPricePeriod.shareValue }}
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="marketPriceDisplayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: marketPriceDisplayedColumns;"></tr>

    </table>

  </div>

  <div fxFlexFill *ngIf="shareProduct.chargesSelected.length" fxLayout="row wrap" fxLayout.lt-md="column">

    <h3 class="mat-h3" fxFlexFill>{{"labels.anchors.charges" | translate}}</h3>

    <mat-divider fxFlexFill></mat-divider>

    <table fxFlexFill class="mat-elevation-z1" mat-table [dataSource]="shareProduct.chargesSelected">

      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef>{{"labels.inputs.name" | translate}}</th>
        <td mat-cell *matCellDef="let charge">
          {{ charge.name + ', ' + charge.currency.displaySymbol }}
        </td>
      </ng-container>

      <ng-container matColumnDef="chargeCalculationType">
        <th mat-header-cell *matHeaderCellDef>{{"labels.inputs.type" | translate}}</th>
        <td mat-cell *matCellDef="let charge">
          {{ charge.chargeCalculationType.value }}
        </td>
      </ng-container>

      <ng-container matColumnDef="amount">
        <th mat-header-cell *matHeaderCellDef>{{"labels.heading.amount" | translate}}</th>
        <td mat-cell *matCellDef="let charge">
          {{ charge.amount  }}
        </td>
      </ng-container>

      <ng-container matColumnDef="chargeTimeType">
        <th mat-header-cell *matHeaderCellDef>{{"labels.heading.collectedon" | translate}}</th>
        <td mat-cell *matCellDef="let charge">
          {{ charge.chargeTimeType.value }}
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="chargesDisplayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: chargesDisplayedColumns;"></tr>

    </table>

  </div>

  <h3 class="mat-h3" fxFlexFill>{{"labels.menus.Accounting" | translate}}</h3>

  <mat-divider fxFlexFill></mat-divider>

  <div fxFlexFill>
    <span fxFlex="40%">{{"labels.inputs.type" | translate}}:</span>
    <span fxFlex="60%">{{ accountingRuleData[shareProduct.accountingRule-1] }}</span>
  </div>

  <div fxFlexFill *ngIf="shareProduct.accountingRule === 2" fxLayout="row wrap" fxLayout.lt-md="column">

    <h4 fxFlexFill class="mat-h4">{{"labels.assets" | translate}}</h4>

    <div fxFlexFill>
      <span fxFlex="40%">{{"labels.inputs.sharereference" | translate }}:</span>
      <span fxFlex="60%">{{ shareProduct.shareReferenceId | find:shareProductsTemplate.accountingMappingOptions.assetAccountOptions:'id':'name' }}</span>
    </div>

    <h4 fxFlexFill class="mat-h4">{{"labels.heading.liabilities" | translate}} </h4>

    <div fxFlexFill>
      <span fxFlex="40%">{{"labels.inputs.sharecontrol" | translate }}:</span>
      <span fxFlex="60%">{{ shareProduct.shareSuspenseId | find:shareProductsTemplate.accountingMappingOptions.liabilityAccountOptions:'id':'name' }}</span>
    </div>

    <h4 fxFlexFill class="mat-h4">{{"labels.heading.shareequity" | translate }}</h4>

    <div fxFlexFill>
      <span fxFlex="40%">{{"labels.inputs.equity" | translate }}:</span>
      <span fxFlex="60%">{{ shareProduct.shareEquityId | find:shareProductsTemplate.accountingMappingOptions.equityAccountOptions:'id':'name' }}</span>
    </div>

    <h4 fxFlexFill class="mat-h4">{{"labels.heading.income" | translate}} </h4>

    <div fxFlexFill>
      <span fxFlex="40%">{{"labels.heading.incomefromfees" | translate}}:</span>
      <span fxFlex="60%">{{ shareProduct.incomeFromFeeAccountId | find:shareProductsTemplate.accountingMappingOptions.incomeAccountOptions:'id':'name' }}</span>
    </div>

  </div>

</div>

<div fxLayout="row" class="margin-t" fxLayout.xs="column" fxLayoutAlign="center" fxLayoutGap="2%">
  <button mat-raised-button matStepperPrevious>
    <fa-icon icon="arrow-left"></fa-icon>&nbsp;&nbsp;
    {{"labels.heading.prev" | translate}}
  </button>
  <button mat-raised-button [routerLink]="['../']">
    {{"labels.buttons.cancel" | translate}}
  </button>
  <button mat-raised-button color="primary" (click)="submit.emit()" *mifosxHasPermission="taskPermission">
    {{"labels.buttons.submit" | translate}}
  </button>
</div>
