<div class="container">

  <mat-card>

    <form [formGroup]="tellerForm" (ngSubmit)="submit()">

      <mat-card-content>

        <div fxLayout="column">

          <mat-form-field>
            <mat-label>{{"labels.inputs.teller.name" | translate}}</mat-label>
            <input matInput required formControlName="name">
            <mat-error *ngIf="tellerForm.controls.name.hasError('required')">
              {{"labels.inputs.teller.name" | translate}} <strong>{{"labels.commons.is required" | translate}}</strong>
            </mat-error>
            <mat-error *ngIf="tellerForm.controls.name.hasError('pattern')">
              {{"labels.inputs.teller.name" | translate}} <strong>{{"labels.cannot" | translate}}</strong> {{"labels.anchors.cannotbeginwithspecialcharacter" | translate}}
            </mat-error>
          </mat-form-field>

          <mat-form-field>
            <mat-label>{{"labels.inputs.office" | translate}}</mat-label>
            <mat-select formControlName="officeId">
              <mat-option *ngFor="let office of officeData" [value]="office.id">
                {{ office.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field>
            <mat-label>{{"labels.inputs.description" | translate}}</mat-label>
            <input matInput formControlName="description">
          </mat-form-field>

          <mat-form-field (click)="startDatePicker.open()">
            <mat-label>{{"labels.inputs.start_date" | translate}}</mat-label>
            <input matInput [min]="minDate" [max]="maxDate" [matDatepicker]="startDatePicker" required
              formControlName="startDate">
            <mat-datepicker-toggle matSuffix [for]="startDatePicker"></mat-datepicker-toggle>
            <mat-datepicker #startDatePicker></mat-datepicker>
            <mat-error *ngIf="tellerForm.controls.startDate.hasError('required')">
              {{"labels.inputs.start_date" | translate}} <strong>{{"labels.commons.is required" | translate}}</strong>
            </mat-error>
          </mat-form-field>

          <mat-form-field (click)="endDatePicker.open()">
            <mat-label>{{"labels.inputs.end_date" | translate}}</mat-label>
            <input matInput [min]="tellerForm.value.startDate" [max]="maxDate" [matDatepicker]="endDatePicker" formControlName="endDate">
            <mat-datepicker-toggle matSuffix [for]="endDatePicker"></mat-datepicker-toggle>
            <mat-datepicker #endDatePicker></mat-datepicker>
          </mat-form-field>

          <mat-form-field>
            <mat-label>{{"labels.menus.status" | translate}}</mat-label>
            <mat-select required formControlName="status">
              <mat-option *ngFor="let tellerStatus of tellerStatusesData" [value]="tellerStatus.id">
                {{ tellerStatus.value }}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="tellerForm.controls.status.hasError('required')">
              {{"labels.menus.status" | translate}} <strong>{{"labels.commons.is required" | translate}}</strong>
            </mat-error>
          </mat-form-field>

        </div>

      </mat-card-content>

      <mat-card-actions fxLayout="row" fxLayout.xs="column" fxLayoutAlign="center" fxLayoutGap="5px">
        <button type="button" mat-raised-button [routerLink]="['../']">{{"labels.buttons.cancel" | translate}}</button>
        <button mat-raised-button color="primary" [disabled]="!tellerForm.valid"
          *mifosxHasPermission="'UPDATE_TELLER'">{{"labels.buttons.submit" | translate}}</button>
      </mat-card-actions>

    </form>

  </mat-card>

</div>
