<form [formGroup]="createClientForm">

  <div fxLayout="row wrap" fxLayoutGap="2%" fxLayout.lt-md="column">

    <mat-form-field fxFlex="48%">
      <mat-label>{{'labels.inputs.office' | translate}}</mat-label>
      <mat-select required formControlName="officeId">
        <mat-option *ngFor="let office of officeOptions" [value]="office.id">
          {{ office.name }}
        </mat-option>
      </mat-select>
      <mat-error *ngIf="createClientForm.controls.officeId.hasError('required')">
        {{'labels.inputs.office' | translate}} <strong>{{'labels.commons.is required' | translate}}</strong>
      </mat-error>
    </mat-form-field>

    <mat-form-field fxFlex="48%">
      <mat-label>{{'labels.inputs.legalForm' | translate}}</mat-label>
      <mat-select formControlName="legalFormId">
        <mat-option *ngFor="let legalForm of legalFormOptions" [value]="legalForm.id">
          {{ legalForm.value }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field fxFlex="48%">
      <mat-label>{{'labels.inputs.staff' | translate}}</mat-label>
      <mat-select formControlName="staffId">
        <mat-option *ngFor="let staff of staffOptions" [value]="staff.id">
          {{ staff.displayName }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field *ngIf="createClientForm.contains('fullname')" fxFlex="48%">
      <mat-label>{{ (createClientForm.value.legalFormId === 1 ? 'labels.inputs.name' : 'labels.heading.entityname') | translate }}</mat-label>
      <input matInput required formControlName="fullname">
      <mat-error *ngIf="createClientForm.controls.fullname.hasError('required')">
        {{'labels.inputs.clientName' | translate}} <strong>{{'labels.commons.is required' | translate}}</strong>
      </mat-error>
      <mat-error *ngIf="createClientForm.controls.fullname.hasError('pattern')">
        {{'labels.inputs.clientName' | translate}} <strong>{{'labels.anchors.cannotbeginwithspecialcharacter' |
          translate}}</strong>
      </mat-error>
    </mat-form-field>

    <mat-form-field *ngIf="createClientForm.contains('firstname')" fxFlex="48%">
      <mat-label>{{ 'labels.inputs.firstname' | translate}}</mat-label>
      <input matInput required formControlName="firstname">
      <mat-error *ngIf="createClientForm.controls.firstname.hasError('required')">
        {{ 'labels.inputs.firstname' | translate}} <strong>{{'labels.commons.is required' | translate}}</strong>
      </mat-error>
      <mat-error *ngIf="createClientForm.controls.firstname.hasError('pattern')">
        {{ 'labels.inputs.firstname' | translate}} <strong>{{'labels.anchors.cannotbeginwithspecialcharacter' |
          translate}}</strong>
      </mat-error>
    </mat-form-field>

    <mat-form-field *ngIf="createClientForm.contains('middlename')" fxFlex="48%">
      <mat-label>{{'labels.inputs.middlename' | translate}}</mat-label>
      <input matInput formControlName="middlename">
      <mat-error *ngIf="createClientForm.controls.middlename.hasError('pattern')">
        {{'labels.inputs.middlename' | translate}} <strong>{{'labels.anchors.cannotbeginwithspecialcharacter' |
          translate}}</strong>
      </mat-error>
    </mat-form-field>

    <mat-form-field *ngIf="createClientForm.contains('lastname')" fxFlex="48%">
      <mat-label>{{'labels.inputs.lastname' | translate}}</mat-label>
      <input matInput required formControlName="lastname">
      <mat-error *ngIf="createClientForm.controls.lastname.hasError('required')">
        {{'labels.inputs.lastname' | translate}} <strong>{{'labels.commons.is required' | translate}}</strong>
      </mat-error>
      <mat-error *ngIf="createClientForm.controls.lastname.hasError('pattern')">
        {{'labels.inputs.lastname' | translate}} <strong>{{'labels.anchors.cannotbeginwithspecialcharacter' |
          translate}}</strong>
      </mat-error>
    </mat-form-field>

    <mat-form-field fxFlex="48%" (click)="dateOfBirthDatePicker.open()">
      <mat-label>{{ createClientForm.value.legalFormId === 1 ? ('labels.inputs.dateofbirth' |
        translate ) :('labels.inputs.incorporationdate' | translate) }}</mat-label>
      <input matInput [max]="maxDate" [matDatepicker]="dateOfBirthDatePicker" formControlName="dateOfBirth">
      <mat-datepicker-toggle matSuffix [for]="dateOfBirthDatePicker"></mat-datepicker-toggle>
      <mat-datepicker #dateOfBirthDatePicker></mat-datepicker>
    </mat-form-field>

    <mat-form-field fxFlex="48%">
      <mat-label>{{'labels.inputs.externalid' | translate}}</mat-label>
      <input matInput formControlName="externalId">
    </mat-form-field>

    <div fxFlex="100%" fxLayout="row wrap" fxLayoutGap="2%" fxLayout.lt-md="column"
      formGroupName="clientNonPersonDetails" *ngIf="createClientForm.contains('clientNonPersonDetails')">

      <mat-form-field fxFlex="48%">
        <mat-label>{{'labels.inputs.constitution' | translate}}</mat-label>
        <mat-select formControlName="constitutionId" required>
          <mat-option *ngFor="let constitution of constitutionOptions" [value]="constitution.id">
            {{ constitution.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field fxFlex="48%">
        <mat-label>{{'labels.inputs.mainBusinessLine' | translate}}</mat-label>
        <mat-select formControlName="mainBusinessLineId">
          <mat-option *ngFor="let business of businessLineOptions" [value]="business.id">
            {{ business.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field fxFlex="48%" (click)="incorpValidityTillDateDatePicker.open()">
        <mat-label>{{'labels.inputs.incorpValidityTillDate' |
          translate}}</mat-label>
        <input matInput [min]="minDate" [max]="maxDate" [matDatepicker]="incorpValidityTillDateDatePicker"
          formControlName="incorpValidityTillDate">
        <mat-datepicker-toggle matSuffix [for]="incorpValidityTillDateDatePicker"></mat-datepicker-toggle>
        <mat-datepicker #incorpValidityTillDateDatePicker></mat-datepicker>
      </mat-form-field>

      <mat-form-field fxFlex="48%">
        <mat-label>{{'labels.inputs.incorpNumber' |
          translate}}</mat-label>
        <input matInput formControlName="incorpNumber">
      </mat-form-field>

      <mat-form-field fxFlex="98%">
        <mat-label>{{'labels.inputs.remarks' |
          translate}}</mat-label>
        <textarea matInput formControlName="remarks"></textarea>
      </mat-form-field>

    </div>

    <mat-form-field *ngIf="createClientForm.value.legalFormId === 1" fxFlex="48%">
      <mat-label>{{ 'labels.inputs.gender' | translate }}</mat-label>
      <mat-select formControlName="genderId">
        <mat-option *ngFor="let gender of genderOptions" [value]="gender.id">
          {{ gender.name }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-checkbox *ngIf="createClientForm.value.legalFormId === 1" fxFlex="48%" labelPosition="before"
      formControlName="isStaff" class="margin-v">
      {{ 'labels.inputs.isStaff' | translate }}
    </mat-checkbox>

    <mat-form-field fxFlex="48%">
      <mat-label>{{'labels.inputs.mobilenumber' |
        translate}}</mat-label>
      <input matInput type="number" formControlName="mobileNo">
    </mat-form-field>

    <mat-form-field fxFlex="48%">
      <mat-label>{{'labels.inputs.email' |
        translate}}</mat-label>
      <input matInput formControlName="emailAddress">
      <mat-error *ngIf="createClientForm.controls.emailAddress.errors?.email">
        Email not valid
      </mat-error>
    </mat-form-field>

    <mat-form-field fxFlex="48%">
      <mat-label>{{'labels.inputs.clienttype' | translate}}</mat-label>
      <mat-select formControlName="clientTypeId">
        <mat-option *ngFor="let clientType of clientTypeOptions" [value]="clientType.id">
          {{ clientType.name }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field fxFlex="48%">
      <mat-label>{{'labels.inputs.clientclassification' | translate}}</mat-label>
      <mat-select formControlName="clientClassificationId">
        <mat-option *ngFor="let clientClassification of clientClassificationTypeOptions"
          [value]="clientClassification.id">
          {{ clientClassification.name }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field fxFlex="48%" (click)="submittedOnDatePicker.open()">
      <mat-label>{{'labels.inputs.submittedOnDate' | translate}}</mat-label>
      <input matInput [min]="minDate" [max]="maxDate" [matDatepicker]="submittedOnDatePicker"
        formControlName="submittedOnDate" required>
      <mat-datepicker-toggle matSuffix [for]="submittedOnDatePicker"></mat-datepicker-toggle>
      <mat-datepicker #submittedOnDatePicker></mat-datepicker>
    </mat-form-field>

    <br />

    <mat-checkbox fxFlex="48%" labelPosition="before" formControlName="active" class="margin-v">
      {{'labels.inputs.active' | translate}}?
    </mat-checkbox>

    <mat-form-field *ngIf="createClientForm.contains('activationDate')" fxFlex="48%"
      (click)="activatedOnDatePicker.open()">
      <mat-label>{{'labels.inputs.activationdate' | translate}}</mat-label>
      <input matInput [min]="minDate" [max]="createClientForm.value.submittedOnDate"
        [matDatepicker]="activatedOnDatePicker" required formControlName="activationDate">
      <mat-datepicker-toggle matSuffix [for]="activatedOnDatePicker"></mat-datepicker-toggle>
      <mat-datepicker #activatedOnDatePicker></mat-datepicker>
      <mat-error *ngIf="createClientForm.controls.activationDate.hasError('required')">
        {{'labels.inputs.activationdate' | translate}} <strong>{{'labels.commons.is required' | translate}}</strong>
      </mat-error>
    </mat-form-field>

    <mat-checkbox fxFlex="48%" labelPosition="before" formControlName="addSavings" class="margin-v">
      {{ 'labels.inputs.opensavingsproduct' | translate }}?
    </mat-checkbox>

    <mat-form-field *ngIf="createClientForm.contains('savingsProductId')" fxFlex="48%">
      <mat-label>{{'labels.inputs.savingproduct' | translate}}</mat-label>
      <mat-select required formControlName="savingsProductId">
        <mat-option *ngFor="let product of savingProductOptions" [value]="product.id">
          {{ product.name }}
        </mat-option>
      </mat-select>
      <mat-error *ngIf="createClientForm.controls.savingsProductId.hasError('required')">
        {{'labels.inputs.savingproduct' | translate}} <strong>{{'labels.commons.is required' | translate}}</strong>
      </mat-error>
    </mat-form-field>

  </div>

  <div fxLayout="row" class="margin-t" fxLayout.xs="column" fxLayoutAlign="center" fxLayoutGap="2%">
    <button mat-raised-button matStepperPrevious disabled>
      <fa-icon icon="arrow-left"></fa-icon>&nbsp;&nbsp;
      {{'labels.buttons.previous' | translate}}
    </button>
    <button mat-raised-button matStepperNext>
      {{'labels.buttons.next' | translate}}&nbsp;&nbsp;
      <fa-icon icon="arrow-right"></fa-icon>
    </button>
  </div>

</form>