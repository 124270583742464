<div class="container">

  <div fxLayout="row" fxLayoutGap="20px">
    <mat-form-field fxFlex>
      <mat-label>{{'labels.buttons.filter' | translate}}</mat-label>
      <input matInput (keyup)="applyFilter($event.target.value)">
    </mat-form-field>
  </div>

  <div class="mat-elevation-z8">

    <table mat-table [dataSource]="dataSource" matSort>

      <ng-container matColumnDef="reportName">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'labels.inputs.reportname' | translate}} </th>
        <td mat-cell *matCellDef="let report"> {{ report.reportName }} </td>
      </ng-container>

      <ng-container matColumnDef="reportType">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'labels.inputs.reporttype' | translate}} </th>
        <td mat-cell *matCellDef="let report"> {{ report.reportType }} </td>
      </ng-container>

      <ng-container matColumnDef="reportCategory">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'labels.inputs.reportcategory' | translate}} </th>
        <td mat-cell *matCellDef="let report"> {{ report.reportCategory }} </td>
      </ng-container>


      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="select-row"
        [routerLink]="['/reports','run', row.reportName]" [queryParams]="{ type: row.reportType, id: row.id }"></tr>

    </table>

    <mat-paginator [pageSizeOptions]="[10, 25, 50, 100]" showFirstLastButtons></mat-paginator>

  </div>

</div>