<div class="container m-b-20" fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="end" fxLayoutGap="2%">
  <button mat-raised-button color="primary" [routerLink]="['edit']" *mifosxHasPermission="'UPDATE_TAXCOMPONENT'">
    <fa-icon icon="edit"></fa-icon>&nbsp;&nbsp;
    {{"labels.buttons.edit" | translate}}
  </button>
</div>

<div class="container">

  <mat-card>

    <mat-card-content>

      <div fxLayout="row wrap" class="content">

        <div fxFlex="50%" class="mat-body-strong"> {{"labels.inputs.name" | translate }} </div>

        <div fxFlex="50%">
          {{ taxComponentData.name }}
        </div>

        <div fxFlex="50%" class="mat-body-strong"> {{"labels.inputs.percentage" | translate}} </div>

        <div fxFlex="50%">
          {{ taxComponentData.percentage }}
        </div>

        <div fxFlex="50%" class="mat-body-strong" *ngIf="taxComponentData.debitAccountType"> {{"labels.inputs.debitaccounttype" | translate }} </div>

        <div fxFlex="50%" *ngIf="taxComponentData.debitAccountType">
          {{ taxComponentData.debitAccountType.value }}
        </div>

        <div fxFlex="50%" class="mat-body-strong" *ngIf="taxComponentData.debitAccount"> {{"labels.inputs.debitaccount" | translate}} </div>

        <div fxFlex="50%" *ngIf="taxComponentData.debitAccount">
          {{ taxComponentData.debitAccount.name}}
        </div>

        <div fxFlex="50%" class="mat-body-strong" *ngIf="taxComponentData.creditAccountType"> {{"labels.inputs.creditaccounttype" | translate}} </div>

        <div fxFlex="50%" *ngIf="taxComponentData.creditAccountType">
          {{ taxComponentData.creditAccountType.value }}
        </div>

        <div fxFlex="50%" class="mat-body-strong" *ngIf="taxComponentData.creditAccount"> {{"labels.inputs.creditaccount" | translate}} </div>

        <div fxFlex="50%" *ngIf="taxComponentData.creditAccount">
          {{ taxComponentData.creditAccount.name }}
        </div>

        <div fxFlex="50%" class="mat-body-strong"> {{"labels.inputs.startdate" | translate }} </div>

        <div fxFlex="50%">
          {{ taxComponentData.startDate  | dateFormat }}
        </div>

      </div>

    </mat-card-content>

  </mat-card>

</div>
