<div class="container m-b-20" fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="end" fxLayoutGap="2%">
  <button mat-raised-button color="primary" [routerLink]="['edit']">
    <fa-icon icon="edit"></fa-icon>&nbsp;&nbsp;{{"labels.inputs.edit" | translate }}</button>
  <button mat-raised-button color="primary" [routerLink]="['viewhistory']">
    {{"labels.buttons.viewhistory" | translate}}
  </button>
</div>

<div class="container">

  <mat-card>

    <mat-card-content>

      <div fxLayout="row wrap" class="content">

        <div fxFlex="50%" class="mat-body-strong">{{"labels.inputs.jobname" | translate}}</div>

        <div fxFlex="50%">
          {{ jobData.displayName }}
        </div>

        <div fxFlex="50%" class="mat-body-strong">{{"labels.heading.cronexpression" | translate}}</div>

        <div fxFlex="50%">
          {{ jobData.cronExpression }}
        </div>

        <div fxFlex="50%" class="mat-body-strong">
          {{"labels.heading.isactivejob" | translate}}
        </div>

        <div fxFlex="50%">
          {{ jobData.active }}
        </div>

        <div fxFlex="50%" class="mat-body-strong">
          {{"labels.heading.follow" | translate}}
        </div>

        <div fxFlex="50%">
          <a href="http://www.cronmaker.com/">	{{"labels.anchors.clickheretogeneratecron" | translate}} </a>
        </div>

      </div>

    </mat-card-content>

  </mat-card>

</div>
