<span class="container">

  <mat-card>

    <form [formGroup]="glAccountForm" (ngSubmit)="submit()">

      <mat-card-content>

        <div fxLayout="row wrap" fxLayoutGap="2%" fxLayout.lt-md="column">

          <mat-form-field fxFlex="48%">
            <mat-label>{{"labels.inputs.accounttype" | translate}}</mat-label>
            <mat-select required formControlName="type">
              <mat-option *ngFor="let accountType of accountTypeData" [value]="accountType.id">
                {{ accountType.value }}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="glAccountForm.controls.type.hasError('required')">
              {{"labels.inputs.accounttype" | translate}} <strong>{{"labels.commons.is required" | translate}}</strong>
            </mat-error>
          </mat-form-field>

          <mat-form-field fxFlex="48%">
            <mat-label>{{"labels.inputs.accountname" | translate}}</mat-label>
            <input matInput required formControlName="name">
            <mat-error *ngIf="glAccountForm.controls.name.hasError('required')">
              {{"labels.inputs.accountname" | translate}} <strong>{{"labels.commons.is required" | translate}}</strong>
            </mat-error>
          </mat-form-field>

          <mat-form-field fxFlex="48%">
            <mat-label>{{"labels.inputs.accountusage" | translate}}</mat-label>
            <mat-select required formControlName="usage">
              <mat-option *ngFor="let accountUsage of accountUsageData" [value]="accountUsage.id">
                {{ accountUsage.value }}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="glAccountForm.controls.usage.hasError('required')">
              {{"labels.inputs.accountusage" | translate}} <strong>{{"labels.commons.is required" | translate}}</strong>
            </mat-error>
          </mat-form-field>

          <mat-form-field fxFlex="48%">
            <mat-label>{{"labels.inputs.glcode" | translate}}</mat-label>
            <input matInput required formControlName="glCode">
            <mat-error *ngIf="glAccountForm.controls.glCode.hasError('required')">
              {{"labels.inputs.glcode" | translate}} <strong>{{"labels.commons.is required" | translate}}</strong>
            </mat-error>
          </mat-form-field>

          <mat-form-field fxFlex="48%">
            <mat-label>{{"labels.inputs.parent" | translate}}</mat-label>
            <mat-select formControlName="parentId">
              <mat-option *ngFor="let parent of parentData" [value]="parent.id">
                ({{ parent.glCode }}) {{ parent.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field fxFlex="28%">
            <mat-label>{{"labels.inputs.tag" | translate}}</mat-label>
            <mat-select formControlName="tagId">
              <mat-option *ngFor="let tag of tagData" [value]="tag.id">
                {{ tag.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <div fxFlex="18%" class="manual-entries-allowed-wrapper">
            <mat-checkbox labelPosition="before" required formControlName="manualEntriesAllowed" class="manual-entries-allowed">
              {{"labels.inputs.manualentriesallowed" | translate}}
            </mat-checkbox>
          </div>

          <mat-form-field fxFlex="98%">
            <mat-label>{{"labels.heading.description" | translate}}</mat-label>
            <textarea matInput formControlName="description"></textarea>
          </mat-form-field>

        </div>

      </mat-card-content>

      <mat-card-actions fxLayout="row" fxLayout.xs="column" fxLayoutAlign="center" fxLayoutGap="5px">
        <button type="button" mat-raised-button [routerLink]="['../']">{{"labels.buttons.cancel" | translate}}</button>
        <button mat-raised-button color="primary" [disabled]="glAccountForm.pristine" *mifosxHasPermission="'UPDATE_GLACCOUNT'">{{"labels.buttons.submit" | translate}}</button>
      </mat-card-actions>

    </form>

  </mat-card>

</span>
