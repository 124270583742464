<div class="container m-b-20" fxLayout="row" fxLayoutAlign="end" fxLayoutGap="20px">

  <button mat-raised-button color="primary" [routerLink]="['create']" *mifosxHasPermission="'CREATE_PAYMENTTYPE'">
    <fa-icon icon="plus"></fa-icon>&nbsp;&nbsp;
    {{ 'labels.anchors.createpaymenttype' | translate }}
  </button>

</div>

<div class="container">

  <div fxLayout="row" fxLayoutGap="20px">
    <mat-form-field fxFlex>
      <mat-label>{{ 'labels.buttons.filter' | translate }}</mat-label>
      <input matInput (keyup)="applyFilter($event.target.value)">
    </mat-form-field>
  </div>

  <div class="mat-elevation-z8">

    <table mat-table [dataSource]="dataSource" matSort>

      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'labels.heading.name' | translate }} </th>
        <td mat-cell *matCellDef="let paymentType"> {{ paymentType.name }} </td>
      </ng-container>

      <ng-container matColumnDef="description">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'labels.heading.description' | translate }} </th>
        <td mat-cell *matCellDef="let paymentType"> {{ paymentType.description }} </td>
      </ng-container>

      <ng-container matColumnDef="codeName">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'labels.heading.code' | translate }} </th>
        <td mat-cell *matCellDef="let paymentType"> {{ paymentType.codeName }} </td>
      </ng-container>

      <ng-container matColumnDef="isSystemDefined">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'labels.heading.systemdefined' | translate }}</th>
        <td mat-cell *matCellDef="let paymentType">
          <div [className]="paymentType.isSystemDefined === true ? 'true text-center' : 'false text-center'">
            <fa-icon *ngIf="paymentType.isSystemDefined" icon="check-circle" size="lg" [matTooltip]="'labels.yes'| translate"
              matTooltipPosition="right"></fa-icon>
            <fa-icon *ngIf="!paymentType.isSystemDefined" icon="times-circle" size="lg" [matTooltip]="'labels.no'| translate"
              matTooltipPosition="right"></fa-icon>
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="isCashPayment">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'labels.heading.iscashpayment' | translate }} </th>
        <td mat-cell *matCellDef="let paymentType">
          <div [className]="paymentType.isCashPayment === true ? 'true text-center' : 'false text-center'">
            <fa-icon *ngIf="paymentType.isCashPayment" icon="check-circle" size="lg" [matTooltip]="'labels.yes'| translate"
              matTooltipPosition="right"></fa-icon>
            <fa-icon *ngIf="!paymentType.isCashPayment" icon="times-circle" size="lg" [matTooltip]="'labels.no'| translate"
              matTooltipPosition="right"></fa-icon>
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="position">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'labels.heading.position' | translate }} </th>
        <td mat-cell *matCellDef="let paymentType" class="text-center"> {{ paymentType.position }} </td>
      </ng-container>

      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef> {{ 'labels.heading.actions' | translate }} </th>
        <td mat-cell *matCellDef="let paymentType">
          <button mat-button [routerLink]="[paymentType.id, 'edit']" color="primary"
            *ngIf="!paymentType.isSystemDefined">
            <fa-icon icon="edit"></fa-icon>&nbsp;&nbsp;{{ 'labels.buttons.edit' | translate }}
          </button>
          <button mat-button (click)="deletePaymentType(paymentType.id)" color="warn"
            *ngIf="!paymentType.isSystemDefined">
            <fa-icon icon="trash"></fa-icon>&nbsp;&nbsp;{{ 'labels.buttons.delete' | translate }}
          </button>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

    </table>

    <mat-paginator [pageSizeOptions]="[10, 25, 50, 100]" showFirstLastButtons></mat-paginator>

  </div>

</div>