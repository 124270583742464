<div class="container">

  <mat-card>

    <form [formGroup]="undoClientRejectionForm" (ngSubmit)="submit()">

      <mat-card-content>

          <mat-form-field fxFlex (click)="reopenedDatePicker.open()">
            <mat-label>{{ 'labels.inputs.reopeneddate' | translate }}</mat-label>
            <input matInput [min]="minDate" [max]="maxDate" [matDatepicker]="reopenedDatePicker" required formControlName="reopenedDate">
            <mat-datepicker-toggle matSuffix [for]="reopenedDatePicker"></mat-datepicker-toggle>
            <mat-datepicker #reopenedDatePicker></mat-datepicker>
            <mat-error *ngIf="undoClientRejectionForm.controls.reopenedDate.hasError('required')">
              {{ 'labels.inputs.reopeneddate' | translate }} <strong>{{ 'labels.commons.is required' | translate }}</strong>
            </mat-error>
          </mat-form-field>

      </mat-card-content>

      <mat-card-actions fxLayout="row" fxLayout.xs="column" fxLayoutAlign="center" fxLayoutGap="5px">
        <button type="button" mat-raised-button [routerLink]="['../../']">{{ 'labels.buttons.cancel' | translate }}</button>
        <button mat-raised-button color="accent" [disabled]="!undoClientRejectionForm.valid">{{ 'labels.buttons.confirm' | translate }}</button>
      </mat-card-actions>

    </form>

  </mat-card>

</div>
