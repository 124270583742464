import { LoanstrackingComponent } from './loans/loanstracking/loanstracking.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoansAccountTemplateResolver } from './../loans/common-resolvers/loans-account-template.resolver';

/** Routing Imports */
import { Route } from '../core/route/route.service';

/** Translation Imports */
import { extract } from '../core/i18n/i18n.service';

/** Custom Components */
import { SimulatorComponent } from './loans/simulator/simulator.component';
import { LoansComponent } from './loans/loans.component';
import { LoanApprovalComponent } from './loans/loanstracking/loan-approval/loan-approval.component';
import { GetOffices } from 'app/tasks/common-resolvers/getOffices.resolver';
import { GetLoans } from 'app/tasks/common-resolvers/getLoans.resolver';
import { LoanDisbursalComponent } from './loans/loanstracking/loan-disbursal/loan-disbursal.component';
import { RescheduleLoanComponent } from './loans/loanstracking/reschedule-loan/reschedule-loan.component';
import { GetRescheduleLoans } from 'app/tasks/common-resolvers/getRescheduleLoans.resolver';
import { SavingsTransferComponent } from './transactions/savings-transfer/savings-transfer.component';
import { RepaymentComponent } from './loans/repayment/repayment.component';
import { FollowAccountsComponent } from './accounts/follow-accounts/follow-accounts.component';
import { AccountApprovalComponent } from './accounts/follow-accounts-tabs/account-approval/account-approval.component';
import { AccountActivationComponent } from './accounts/follow-accounts-tabs/account-activation/account-activation.component';
import { GetAllSavingsAcativate } from 'app/tasks/common-resolvers/getSavingsActivate.resolver';
import { GetSavingsApproval } from 'app/tasks/common-resolvers/getSavingsApproval.resolver';
import { AccountsComponent } from './accounts/accounts.component';
import { ViewaccountsComponent } from './accounts/viewaccounts/viewaccounts.component';
import { SavingsAccountsResolver } from 'app/account-transfers/common-resolvers/savings-accounts.resolver';
import { TellersComponent } from './tellers/tellers.component';
import { ViewLoansComponent } from './loans/view-loans/view-loans.component';
import { GetAllLoans } from 'app/tasks/common-resolvers/getAllLoans.resolver';
import { PaymentFollowComponent } from './loans/payment-follow/payment-follow.component';
import { ToPayComponent } from './loans/payment-follow/to-pay/to-pay.component';
import { OverDueComponent } from './loans/payment-follow/over-due/over-due.component';

const routes: Routes = [
  Route.withShell([{
    path: 'operations',
    children: [
      {
        path: 'loans',
        data: { title: extract('labels.anchors.loans'), breadcrumb: extract('labels.anchors.loans'), routeParamBreadcrumb: false },
        children: [
          {
            path: '',
            component: LoansComponent,
          },
          {
            path: 'simulator',
            data: { title: extract('labels.simulator'), breadcrumb: extract('labels.simulator'), routeParamBreadcrumb: false },
            component: SimulatorComponent,
            resolve: {
              loansAccountTemplate: LoansAccountTemplateResolver
            }
          },
          {
            path: 'loanstracking',
            component: LoanstrackingComponent,
            data: { title: extract('labels.anchors.loantracking'), breadcrumb: extract('labels.anchors.loantracking') },
            children: [
              {
                path: 'loan-approval',
                component: LoanApprovalComponent,
                data: { title: extract('labels.heading.loanapproval')},
                resolve: {
                  loansData: GetLoans
                },
              },
              {
                path: 'loan-disbursal',
                component: LoanDisbursalComponent,
                data: { title: extract('labels.heading.loandisbursal') },
                resolve: {
                  loansData: GetLoans
                }
              },
              {
                path: 'reschedule-loan',
                component: RescheduleLoanComponent,
                data: { title: extract('labels.heading.rescheduleLoan') },
                resolve: {
                  recheduleLoansData: GetRescheduleLoans
                }
              }
            ]
          },
          {
            path: 'viewloans',
            component: ViewLoansComponent,
            data: { title: extract('labels.viewLoans'), breadcrumb: extract("labels.viewLoans") },
            resolve: {
              loansData: GetAllLoans
            }
          },
          {
            path: 'payment-follow',
            component: PaymentFollowComponent,
            data: { title: extract('labels.paymentFollow'), breadcrumb: extract('labels.paymentFollow') },
            children: [
              {
                path: 'to-pay',
                component: ToPayComponent,
                data: { title: extract('labels.heading.topay') },
                resolve: {
                  loansData: GetLoans
                }
              },
              {
                path: 'over-due',
                component: OverDueComponent,
                data: { title: extract('labels.heading.overdue') },
                resolve: {
                  loansData: GetLoans
                }
              }
            ]
          },
          {
            path: 'repayment',
            data: { title: extract('labels.loansRepayment'), breadcrumb: extract("labels.loansRepayment"), routeParamBreadcrumb: false },
            component: RepaymentComponent,
          }
        ]
      },
      {
        path: 'transfer',
        data: { title: extract('labels.transfer'), breadcrumb: extract("labels.transfer"), routeParamBreadcrumb: false },
        component: SavingsTransferComponent,
      },
      {
        path: 'accounts',
        data: { title: extract('labels.buttons.savingaccounts'), breadcrumb: extract('labels.buttons.savingaccounts'), routeParamBreadcrumb: false },
        children: [
          {
            path: '',
            component: AccountsComponent,
          },
          {
            path: 'viewaccounts',
            data: {
              title: extract('labels.viewAccounts'),
              breadcrumb: extract('labels.viewAccounts'), routeParamBreadcrumb: false
            },
            component: ViewaccountsComponent,
            resolve: {
              viewaccountsTemplate: SavingsAccountsResolver
            }
          },
          {
            path: 'followaccount',
            data: { title: extract('labels.followAccount'), breadcrumb: extract('labels.followAccount'), routeParamBreadcrumb: false },
            component: FollowAccountsComponent,
            children: [
              {
                path: 'approval',
                data: { title: extract('labels.heading.accountapproval'), routeParamBreadcrumb: false },
                component: AccountApprovalComponent,
                resolve: {
                  accountData: GetSavingsApproval
                }
              },
              {
                path: 'activation',
                data: { title: extract('labels.heading.accountactivation'), routeParamBreadcrumb: false },
                component: AccountActivationComponent,
                resolve: {
                  accountData: GetAllSavingsAcativate
                }
              }
            ]
          }
        ]
      },
      {
        path: 'tellers',
        data: { title: extract('labels.anchors.tellers'), breadcrumb: extract("labels.anchors.tellers"), routeParamBreadcrumb: false },
        children: [
          {
            path: '',
            component: TellersComponent,
          },
        ]
      }
    ]
  }])
]

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: [LoansAccountTemplateResolver, GetAllLoans, GetAllSavingsAcativate, GetSavingsApproval, SavingsAccountsResolver]
})
export class OperationsRoutingModule { }
