import { SearchService } from './../search/search.service';
/** Angular Imports */
import { CollectionViewer, DataSource } from '@angular/cdk/collections';

/** rxjs Imports */
import { Observable, BehaviorSubject } from 'rxjs';

/** Custom Services */
import { ClientsService } from './clients.service';

/**
 * Clients custom data source to implement server side filtering, pagination and sorting.
 */
export class ClientsDataSource implements DataSource<any> {

  /** clients behavior subject to represent loaded journal clients page. */
  private clientsSubject = new BehaviorSubject<any[]>([]);
  /** Records subject to represent total number of filtered clients records. */
  private recordsSubject = new BehaviorSubject<number>(0);
  /** Records observable which can be subscribed to get the value of total number of filtered clients records. */
  public records$ = this.recordsSubject.asObservable();

  /**
   * @param {ClientsService} clientsService Clients Service
   */
  constructor(private clientsService: ClientsService, private searchService: SearchService) { }

  /**
   * Gets clients on the basis of provided parameters and emits the value.
   * @param {any} filterBy Properties by which clients should be filtered.
   * @param {string} orderBy Property by which clients should be sorted.
   * @param {string} sortOrder Sort order: ascending or descending.
   * @param {number} pageIndex Page number.
   * @param {number} limit Number of clients within the page.
   */
  getClients(orderBy: string = '', sortOrder: string = '', pageIndex: number = 0, limit: number = 10, showClosedAccounts: boolean = true) {
    this.clientsSubject.next([]);
    this.clientsService.getClients(orderBy, sortOrder, pageIndex * limit, limit)
      .subscribe((clients: any) => {
        if (showClosedAccounts) {
          clients.pageItems = clients.pageItems;
        } else {
          clients.pageItems = clients.pageItems.filter((client: any) => client.status.value !== 'Closed');
        }
        this.recordsSubject.next(clients.totalFilteredRecords);
        this.clientsSubject.next(clients.pageItems);
      });
  }

  /**
   * @param {CollectionViewer} collectionViewer
   */
  connect(collectionViewer: CollectionViewer): Observable<any[]> {
    return this.clientsSubject.asObservable();
  }

  /**
   * @param {CollectionViewer} collectionViewer
   */
  disconnect(collectionViewer: CollectionViewer): void {
    this.clientsSubject.complete();
    this.recordsSubject.complete();
  }

  /** Filter Active Client Data.
   * @param {string} filterValue Filter Value which clients should be filtered.
   * @param {string} orderBy Property by which clients should be sorted.
   * @param {string} sortOrder Sort order: ascending or descending.
   * @param {number} pageIndex Page number.
   * @param {number} limit Number of clients within the page.
   */
  filterClients(filter: string, orderBy: string = '', sortOrder: string = '', pageIndex: number = 0, limit: number = 10, showClosedAccounts: boolean = true) {
    this.clientsSubject.next([]);
    // this.clientsService.getClients(orderBy, sortOrder, pageIndex * limit, limit)
    //   .subscribe((clients: any) => {
    //     if (showClosedAccounts) {
    //       clients.pageItems = clients.pageItems.filter((client: any) => client.displayName.toLowerCase().includes(filter));
    //     } else {
    //       clients.pageItems = clients.pageItems.filter((client: any) => client.status.value !== 'Closed' && client.displayName.toLowerCase().includes(filter));
    //     }
    //     this.recordsSubject.next(clients.totalFilteredRecords);
    //     this.clientsSubject.next(clients.pageItems);
    //   });
    if (filter) {
      this.searchService.getSearchResults(filter, "clients,clientIdentifiers")
        .subscribe((clients: any) => {
          var arrayLength = clients.length;
          var numberOfClients = 0;
          var actualClients: any[] = []
          for (var i = 0; i < arrayLength; i++) {
            var result = clients[i];
            var client: any = {};
            client.status = {};
            client.subStatus = {};
            client.status.value = result.entityStatus.value;
            client.status.code = result.entityStatus.code;
            if (result.entityType == 'CLIENT') {

              client.displayName = result.entityName;
              client.accountNo = result.entityAccountNo;
              client.id = result.entityId;
              client.externalId = result.entityExternalId;
              client.officeName = result.parentName;
            } else if (result.entityType == 'CLIENTIDENTIFIER') {
              numberOfClients = numberOfClients + 1;
              client.displayName = result.parentName;
              client.id = result.parentId;
              client.externalId = result.parentExternalId;

            }
            actualClients.push(client);
          }
          this.recordsSubject.next(actualClients.length);
          this.clientsSubject.next(actualClients);
        });
    } else {
      this.clientsService.getClients(orderBy, sortOrder, pageIndex * limit, limit)
        .subscribe((clients: any) => {
          if (showClosedAccounts) {
            clients.pageItems = clients.pageItems
          } else {
            clients.pageItems = clients.pageItems.filter((client: any) => client.status.value !== 'Closed');
          }
          this.recordsSubject.next(clients.totalFilteredRecords);
          this.clientsSubject.next(clients.pageItems);
        });
    }
  }

}
