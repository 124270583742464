import { TranslateService } from '@ngx-translate/core';
import { ExportExcelService } from './../../../../core/exportExcel/ExportExcel.service';
import { FormControl } from '@angular/forms';
/** Angular Imports */
import { SelectionModel } from '@angular/cdk/collections';
import { Component, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import * as _ from 'lodash';

/** Dialog Imports */
import { ConfirmationDialogComponent } from 'app/shared/confirmation-dialog/confirmation-dialog.component';

/** Custom Services */

import { MatPaginator } from '@angular/material/paginator';
import { Dates } from 'app/core/utils/dates';
import { SettingsService } from 'app/settings/settings.service';
import { TasksService } from 'app/tasks/tasks.service';
import * as moment from 'moment';

@Component({
  selector: 'mifosx-over-due',
  templateUrl: './over-due.component.html',
  styleUrls: ['../../../operations.module.scss']
})
export class OverDueComponent {

  /** Offices Data */
  offices: any;
  /** Loans Data */
  loans: any;
  filteredLoans: any;
  // dataSource = new MatTableDataSource(this.filteredLoans);

  products = new Set();
  agencies = new Set();
  status = new Set();

  /** Checks whether to show data or not */
  showData = false;
  /** Data source for loans approval table. */
  dataSource: MatTableDataSource<any>;
  /** Row Selection Data */
  selection: SelectionModel<any>;
  /** Map data */
  idToNodeMap = {};
  /** Grouped Office Data */
  officesArray: any[];
  /** List of Requests */
  batchRequests: any[];
  /** Displayed Columns */
  displayedColumns: string[] = ['status', 'loanAccount', 'clientName', 'loanProduct', 'amount', 'principalOutstanding', 'paymentNumber', 'OutstandingPaymentNumber', 'nextPaymentDate', 'nextPaymentDetailsAmount', 'nextPaymentDetailsReference', 'totalOverdue', 'loanAgency'];

  showSearch  = false;
  maxDate = new Date();
  statusFilter = new FormControl();
  loanAccountFilter = new FormControl();
  clientFilter = new FormControl();
  productFilter = new FormControl();
  principalFilter = new FormControl();
  outstandingFilter = new FormControl();
  paymentNumberFilter = new FormControl();
  outstandingPaymentNumberFilter = new FormControl();
  nextPaymentDateFilter = new FormControl();
  totalOverdueFilter = new FormControl();
  agencyFilter = new FormControl();
  fromPrincipalFilter = new FormControl();
  toPrincipalFilter = new FormControl();
  endDateFilter = new FormControl();
  startDateFilter = new FormControl();
  nextPaymentAmountFilter = new FormControl();
  nextPaymentReferenceFilter = new FormControl();

  advencedSearch = ()=>{
    this.showSearch = !this.showSearch;
  }

  applyFilters = () => {
    this.filteredLoans = this.loans.filter((loan: any) => {

      const client = this.clientFilter.value ? loan?.clientName?.toLowerCase().includes(this.clientFilter.value?.toLowerCase()) : true;
      const loanAccount = this.loanAccountFilter.value ? loan?.accountNo.includes(this.loanAccountFilter.value) : true;
      const product = this.productFilter.value ? loan?.loanProductName?.toLowerCase().includes(this.productFilter.value?.toLowerCase()) : true
      const principal = this.principalFilter.value ? loan?.principal === this.principalFilter.value : true
      const agency = this.agencyFilter.value ? loan?.agency?.toLowerCase().includes(this.agencyFilter.value?.toLowerCase()) : true;

      const fromPrincipal = this.fromPrincipalFilter.value || this.fromPrincipalFilter.value === 0 ? loan?.principal >= this.fromPrincipalFilter.value : true;
      const toPrincipal = this.toPrincipalFilter.value || this.toPrincipalFilter.value === 0 ? loan?.principal <= this.toPrincipalFilter.value : true;
      const selectedStatus = this.statusFilter.value ? this.statusFilter.value === loan.status.code : true;
      const outstanding = this.outstandingFilter.value || this.outstandingFilter.value === 0 ? this.outstandingFilter.value === loan?.summary?.principalOutstanding : true;
      const paymentNumber = this.paymentNumberFilter.value || this.paymentNumberFilter.value === 0 ? this.paymentNumberFilter.value === loan?.paymentNumber : true;
      const outstandingPaymentNumber = this.outstandingPaymentNumberFilter.value || this.outstandingPaymentNumberFilter.value === 0 ? this.outstandingPaymentNumberFilter.value === loan?.OutstandingPaymentNumber : true;

      const date = loan?.nextPaymentDetails?.nextPaymentDate;
      const nextPayment = date ? new Date(date[0], date[1] - 1, date[2]) : undefined;

      const nextPaymentAmount = this.nextPaymentAmountFilter.value || this.nextPaymentAmountFilter.value === 0 ? this.nextPaymentAmountFilter.value === loan.nextPaymentDetails.amount : true;
      const nextPaymentReference = this.nextPaymentReferenceFilter.value || this.nextPaymentReferenceFilter.value === 0 ? this.nextPaymentReferenceFilter.value === loan.nextPaymentDetails.id : true;

      const nextPaymentDate = this.nextPaymentDateFilter.value ? moment(nextPayment).isSame(this.nextPaymentDateFilter.value) : true;
      const fromDate = this.startDateFilter.value ? moment(nextPayment).isSameOrAfter(this.startDateFilter.value) : true;
      const toDate = this.endDateFilter.value ? moment(nextPayment).isSameOrBefore(this.endDateFilter.value) : true;

      const totalOverdue = this.totalOverdueFilter.value || this.totalOverdueFilter.value === 0 ? this.totalOverdueFilter.value === loan?.summary?.totalOverdue : true;
      return selectedStatus && client && loanAccount && product && principal && outstanding && outstandingPaymentNumber && paymentNumber && totalOverdue && agency && fromPrincipal && nextPaymentDate && toPrincipal && fromDate && toDate && nextPaymentAmount && nextPaymentReference;
    });
    this.dataSource.data = this.filteredLoans;
  }

  exportexcel(): void {
    const dateFormat = 'yyyy-MM-dd';
    const data = this.filteredLoans.map((loan: any) => ({
      Status: this.translateService.instant(loan.status.code),
      Account: loan.accountNo,
      Client: loan.clientName,
      Product: loan.loanProductName,
      InitialAmount: loan.principal,
      OutstandingAmount: loan?.summary?.principalOutstanding,
      DuePaid: loan.paymentNumber,
      DueUnpaid: loan.OutstandingPaymentNumber,
      NextDue: this.dateUtils.formatDate(loan?.nextPaymentDetails?.nextPaymentDate,dateFormat),
      DueAmount: loan.nextPaymentDetails.amount,
      DueReference: loan.nextPaymentDetails.id,
      AmountUnpaid: loan?.summary?.totalOverdue,
      Agency: loan.agency
    }))

    const header = ['labels.inputs.status', 'labels.inputs.account', 'labels.heading.client', 'labels.heading.product', 'labels.initialAmount', 'labels.heading.outstandingloan', 'labels.duePaid', 'labels.dueUnpaid', 'labels.nextDue', 'labels.dueAmount', 'labels.dueReference', 'labels.amountUnpaid', 'labels.agency'];
    this.exportExcelService.exportExcelFile({data:data,header:header,fileName:'over_due.xlsx'})
  }

  /**
   * Retrieves the offices and loans data from `resolve`.
   * @param {ActivatedRoute} route Activated Route.
   * @param {Dialog} dialog MatDialog.
   * @param {Dates} dateUtils Date Utils.
   * @param {router} router Router.
   * @param {SettingsService} settingsService Settings Service.
   * @param {TasksService} tasksService Tasks Service.
   */
  constructor(private route: ActivatedRoute,
    private dialog: MatDialog,
    private dateUtils: Dates,
    private router: Router,
    private settingsService: SettingsService,
    private tasksService: TasksService,
    private exportExcelService: ExportExcelService,
    private translateService: TranslateService) {
    this.route.data.subscribe((data: { loansData: any }) => {
      this.loans = data.loansData.pageItems
      .filter((item:any) => item.inArrears)
      .map((loan: any) => {
        var nextPaymentDetails = {};
        if (loan.nextPaymentDetails) {
          const nextPayment = loan.nextPaymentDetails.split(";");
          nextPaymentDetails = {
            nextPaymentDate: nextPayment[0].split("-").map((i: string) => parseInt(i)),
            amount: parseInt(nextPayment[2]),
            id: nextPayment[1]
          }
        }
        return { ...loan, nextPaymentDetails: nextPaymentDetails }
      });
      this.filteredLoans = [...this.loans];
      this.dataSource = new MatTableDataSource(this.filteredLoans);
      this.selection = new SelectionModel(true, []);
      if (this.loans.lenght !== 0) {
        this.showData = true
      }
      this.loans.forEach((loan: any) => {
        if (loan?.loanProductName) {
          this.products.add(loan?.loanProductName)
        }
        if (loan?.agency) {
          this.agencies.add(loan?.agency)
        }
        if (loan?.status.code) {
          this.status.add(loan?.status.code)
        }
      });
    });
  }

  @ViewChild(MatPaginator) paginator: MatPaginator;

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
  }


  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected(dataSource2: any) {
    if (dataSource2) {
      const numSelected = this.selection.selected;
      return _.difference(dataSource2, numSelected).length === 0;
    }
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle(dataSource3: any) {
    if (dataSource3) {
      this.isAllSelected(dataSource3) ?
        dataSource3.forEach((row: any) => this.selection.deselect(row)) :
        dataSource3.forEach((row: any) => this.selection.select(row));
    }
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: any): string {
    if (!row) {
      return `${this.isAllSelected(row) ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.position + 1}`;
  }

  approveLoan() {
    const approveLoanDialogRef = this.dialog.open(ConfirmationDialogComponent, {
      data: { heading: 'labels.approveLoan', dialogContext: 'labels.areYouSureYouWantToApproveLoan' }
    });
    approveLoanDialogRef.afterClosed().subscribe((response: { confirm: any }) => {
      if (response.confirm) {
        this.bulkLoanApproval();
      }
    });
  }

  bulkLoanApproval() {
    const dateFormat = 'yyyy-MM-dd';
    const approvedOnDate = this.dateUtils.formatDate(new Date(), dateFormat);
    const locale = this.settingsService.language.code;
    const formData = {
      dateFormat,
      approvedOnDate,
      locale
    };
    const selectedAccounts = this.selection.selected.length;
    const listSelectedAccounts = this.selection.selected;
    let approvedAccounts = 0;
    this.batchRequests = [];
    let reqId = 1;
    listSelectedAccounts.forEach((element: any) => {
      const url = 'loans/' + element.id + '?command=approve';
      const bodyData = JSON.stringify(formData);
      const batchData = { requestId: reqId++, relativeUrl: url, method: 'POST', body: bodyData };
      this.batchRequests.push(batchData);
    });
    this.tasksService.submitBatchData(this.batchRequests).subscribe((response: any) => {
      response.forEach((responseEle: any) => {
        if (responseEle.statusCode = '200') {
          approvedAccounts++;
          responseEle.body = JSON.parse(responseEle.body);
          if (selectedAccounts === approvedAccounts) {
            // this.loanResource();
          }
        }
      });
      this.reload();
    });
  }

  applyFilter(filterValue: string = '') {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }


  reload() {
    const url: string = this.router.url;
    this.router.navigateByUrl(`/checker-inbox-and-tasks`, { skipLocationChange: true })
      .then(() => this.router.navigate([url]));
  }

}
