<div class="container">

  <mat-card>

    <form [formGroup]="officeForm" (ngSubmit)="submit()">

      <mat-card-content>

        <div fxLayout="column">

          <mat-form-field>
            <mat-label>{{"labels.buttons.offices" | translate}}</mat-label>
            <input matInput required formControlName="name">
            <mat-error *ngIf="officeForm.controls.name.hasError('required')">
              {{"labels.buttons.offices" | translate}} <strong>{{"labels.commons.is required" | translate}}</strong>
            </mat-error>
          </mat-form-field>

          <mat-form-field *ngIf="officeForm.contains('parentId')">
            <mat-label>{{"labels.inputs.parentoffice" | translate}}</mat-label>
            <mat-select required formControlName="parentId">
              <mat-option *ngFor="let office of officeData.allowedParents" [value]="office.id">
                {{ office.name }}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="officeForm.controls.parentId.hasError('required')">
              {{"labels.inputs.parentoffice" | translate}} <strong>{{"labels.commons.is required" | translate}}</strong>
            </mat-error>
          </mat-form-field>

          <mat-form-field (click)="openingDatePicker.open()">
            <mat-label>{{"labels.inputs.openedon" | translate}}</mat-label>
            <input matInput [min]="minDate" [max]="maxDate" [matDatepicker]="openingDatePicker" required formControlName="openingDate">
            <mat-datepicker-toggle matSuffix [for]="openingDatePicker"></mat-datepicker-toggle>
            <mat-datepicker #openingDatePicker></mat-datepicker>
            <mat-error *ngIf="officeForm.controls.openingDate.hasError('required')">
              {{"labels.heading.date" | translate}} <strong>{{"labels.commons.is required" | translate}}</strong>
            </mat-error>
          </mat-form-field>

          <mat-form-field>
            <mat-label>{{"labels.inputs.externalid" | translate}}</mat-label>
            <input matInput formControlName="externalId">
          </mat-form-field>

        </div>

      </mat-card-content>

      <mat-card-actions fxLayout="row" fxLayout.xs="column" fxLayoutAlign="center" fxLayoutGap="5px">
        <button type="button" mat-raised-button [routerLink]="['../general']">{{"labels.buttons.cancel" | translate}}</button>
        <button mat-raised-button color="primary" [disabled]="!officeForm.valid" *mifosxHasPermission="'UPDATE_OFFICE'">{{"labels.buttons.submit" | translate}}</button>
      </mat-card-actions>

    </form>

  </mat-card>

</div>
