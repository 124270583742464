<div class="container">

  <mat-card>

    <form [formGroup]="roleForm" (ngSubmit)="submit()">

      <mat-card-content>

          <div fxLayout="column">

            <mat-form-field>
              <mat-label>{{"labels.roleName" | translate}}</mat-label>
              <input matInput disabled formControlName="name">
            </mat-form-field>

            <mat-form-field>
              <mat-label>{{"labels.roleDescription" | translate}}</mat-label>
              <input matInput required formControlName="description">
              <mat-error *ngIf="roleForm.controls.description.hasError('required')">{{"labels.inputs.description" | translate }} <strong>{{"labels.commons.is required" | translate}}</strong>
              </mat-error>
            </mat-form-field>


            <mat-form-field>
              <mat-label>{{"labels.Dashboards" | translate}}</mat-label>
              <mat-select formControlName="dashboards" multiple>
                <mat-option *ngFor="let dashboard of dashboards" [value]="dashboard.id">{{dashboard.name}}</mat-option>
              </mat-select>            <mat-error *ngIf="roleForm.controls.dashboards.hasError('required')">
                {{"labels.Dashboards" | translate}} <strong>{{"labels.commons.is required" | translate}}</strong>
              </mat-error>
            </mat-form-field>


          </div>

      </mat-card-content>

      <mat-card-actions fxLayout="row" fxLayout.xs="column" fxLayoutAlign="center" fxLayoutGap="5px">
        <button type="button" mat-raised-button [routerLink]="['../../']">{{"labels.buttons.cancel" | translate}}</button>
        <button mat-raised-button color="primary" [disabled]="!roleForm.valid || roleForm.pristine">{{"labels.buttons.submit" | translate}}</button>
      </mat-card-actions>

    </form>

  </mat-card>

</div>
