<div fxLayout="row" fxLayoutAlign="end" fxLayoutGap="2%" fxLayout.lt-md="column" class="container m-b-20">
  <span>
    <button mat-raised-button color="primary" [routerLink]="['edit']" *mifosxHasPermission="'UPDATE_PRODUCT_LINE'">
      <fa-icon icon="edit"></fa-icon>&nbsp;&nbsp;
      {{"labels.inputs.edit" | translate}}
    </button>
  </span>
</div>

<div class="container">

  <mat-card>

    <mat-card-content>

      <div fxLayout="row wrap" class="content">

        <div fxFlex="50%" class="header">
          {{"labels.inputs.name" | translate}}
        </div>

        <div fxFlex="50%">
          {{ productLine?.name }}
        </div>

        <div fxFlex="50%" class="header">
          {{"labels.inputs.parent" | translate}}
        </div>

        <div fxFlex="50%">
          {{ productLine?.parent?.name }}
        </div>

        <div fxFlex="50%" class="header" >
          {{"labels.limitAmount" | translate}}
        </div>

        <div fxFlex="50%">
          {{ productLine?.limitAmount }} {{ productLine?.limitAmount && productLine?.currency?.code }}
        </div>

        <div fxFlex="50%" class="header">
          {{"labels.inputs.loanproduct" | translate}}
        </div>

        <div fxFlex="50%" >
          <ul>
            <li *ngFor="let product of productLine?.loanProducts">{{product.name}}</li>
          </ul>
        </div>

        <div fxFlex="50%" class="header">
          {{"labels.validityDays" | translate}}
        </div>

        <div fxFlex="50%">
          {{ productLine?.validityDays }} {{productLine?.validityDays && "labels.heading.days" | translate}}
        </div>
        <div fxFlex="50%" class="header">
          {{"labels.processingDays" | translate}}
        </div>

        <div fxFlex="50%">
          {{ productLine?.processingDays}}  {{productLine?.processingDays && "labels.heading.days" | translate}}
        </div>

        <div fxFlex="50%" class="header">
          {{"labels.revolving" | translate}}
        </div>

        <div fxFlex="50%">
          {{ productLine?.revolving ? ('labels.inputs.true' | translate) :'labels.inputs.false' | translate }}
        </div>

        <div fxFlex="50%" class="header">
          {{"labels.isUnique" | translate}}
        </div>

        <div fxFlex="50%">
          {{ productLine?.unique ? ('labels.inputs.true' | translate) :'labels.inputs.false' | translate }}
        </div>

      <ng-container *ngIf="productLine?.approvalDeadline">
        <div fxFlex="50%" class="header">
          {{"labels.approvalDays" | translate}}
        </div>

        <div fxFlex="50%">
          {{ productLine?.approvalDays}} {{"labels.commons.days" | translate}}
        </div>
      </ng-container>

      </div>

    </mat-card-content>

  </mat-card>

</div>
