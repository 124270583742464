<div class="container m-b-20" fxLayout="row" fxLayoutAlign="end" fxLayoutGap="20px">
  <div #buttonCreateClosure>
    <button mat-raised-button color="primary" [routerLink]="['create']" *mifosxHasPermission="'CREATE_GLCLOSURE'">
      <fa-icon icon="plus"></fa-icon>&nbsp;&nbsp;
      {{'labels.heading.createclosure' | translate}}
    </button>
  </div>
</div>

<div #filter class="container" fxLayout="row">
  <mat-form-field fxFlex>
    <mat-label>{{'labels.heading.officeName' | translate}}</mat-label>
    <input matInput [formControl]="officeName" [matAutocomplete]="officeNameAutocomplete">
  </mat-form-field>
</div>

<!-- Autocomplete data -->
<mat-autocomplete autoActiveFirstOption #officeNameAutocomplete="matAutocomplete">
  <mat-option *ngFor="let office of filteredOfficeData | async" [value]="office.name">
    {{ office.name }}
  </mat-option>
</mat-autocomplete>

<div class="container">

  <div #closuresTable class="mat-elevation-z8">

    <table mat-table [dataSource]="dataSource" matSort>

      <ng-container matColumnDef="officeName">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'labels.heading.office' | translate}} </th>
        <td mat-cell *matCellDef="let glAccountClosure"> {{ glAccountClosure.officeName }} </td>
      </ng-container>

      <ng-container matColumnDef="closingDate">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'labels.heading.closuredate' | translate}} </th>
        <td mat-cell *matCellDef="let glAccountClosure"> {{ glAccountClosure.closingDate }} </td>
      </ng-container>

      <ng-container matColumnDef="comments">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'labels.heading.comments' | translate}} </th>
        <td mat-cell *matCellDef="let glAccountClosure"> {{ glAccountClosure.comments }} </td>
      </ng-container>

      <ng-container matColumnDef="createdByUsername">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'labels.heading.createdby' | translate}} </th>
        <td mat-cell *matCellDef="let glAccountClosure">{{ glAccountClosure.createdByUsername }}</td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;" [routerLink]="['view', row.id]" class="select-row">
      </tr>

    </table>

    <mat-paginator [pageSizeOptions]="[10, 25, 50, 100]" showFirstLastButtons></mat-paginator>

  </div>

</div>

<ng-template #templateButtonCreateClosure let-popover="popover">
  <h2>{{'labels.heading.createclosure' | translate}}</h2>
  <p class="mw300">{{"labels.thisOptionAllowsCreateClosure" | translate}}</p>
  <div fxLayout="row" fxLayoutAlign="end" fxLayoutGap="2%" fxLayout.lt-md="column">
    <button mat-raised-button color="warn"
      (click)="popover.close();configurationWizardService.closeConfigWizard()">{{'labels.heading.close' |
      translate}}</button>
    <button mat-raised-button color="primary" (click)="popover.close();previousStep();">{{'labels.heading.back' |
      translate}}</button>
    <button mat-raised-button color="primary"
      (click)="popover.close();showPopover(templateFilter,filter, 'bottom', true);">{{'labels.heading.next' |
      translate}}</button>
  </div>
</ng-template>

<ng-template #templateFilter let-popover="popover">
  <h4>{{"labels.searchBarFilterClosuresOffice" | translate}}</h4>
  <div fxLayout="row" fxLayoutAlign="end" fxLayoutGap="2%" fxLayout.lt-md="column">
    <button mat-raised-button color="warn"
      (click)="popover.close();configurationWizardService.closeConfigWizard()">{{'labels.heading.close' |
      translate}}</button>
    <button mat-raised-button color="primary"
      (click)="popover.close();showPopover(templateButtonCreateClosure, buttonCreateClosure, 'bottom', true);">{{'labels.heading.back'|translate}}</button>
    <button mat-raised-button color="primary"
      (click)="popover.close();showPopover(templateClosuresTable,closuresTable, 'top', true);">{{'labels.heading.next' |
      translate}}</button>
  </div>
</ng-template>

<ng-template #templateClosuresTable let-popover="popover">
  <h4>{{"labels.listClosuresToKnowMoreClick" | translate}}: <a href="" target="_blank">{{'labels.anchors.closingentries' |
      translate}}</a></h4>
  <div fxLayout="row" fxLayoutAlign="end" fxLayoutGap="2%" fxLayout.lt-md="column">
    <button mat-raised-button color="warn"
      (click)="popover.close();configurationWizardService.closeConfigWizard()">{{'labels.heading.close' |
      translate}}</button>
    <button mat-raised-button color="primary"
      (click)="popover.close();showPopover(templateFilter,filter, 'bottom', true);">{{'labels.heading.back'|translate}}</button>
    <button mat-raised-button color="primary" (click)="popover.close();nextStep()">{{'labels.heading.next'
      |translate}}</button>
  </div>
</ng-template>
