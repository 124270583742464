<div fxLayout="row wrap" fxLayout.lt-md="column">

  <h2 class="mat-h2" fxFlexFill>{{ savingProduct.name }}</h2>

  <mat-divider fxFlexFill></mat-divider>

  <h3 class="mat-h3" fxFlexFill>{{"labels.buttons.details" | translate}}</h3>

  <mat-divider fxFlexFill></mat-divider>

  <div fxFlexFill>
    <span fxFlex="40%">{{"labels.inputs.product.shortname" | translate}}:</span>
    <span fxFlex="60%">{{ savingProduct.shortName }}</span>
  </div>

  <div fxFlexFill *ngIf="savingProduct.description">
    <span fxFlex="40%">{{"labels.heading.description" | translate}}:</span>
    <span fxFlex="60%">{{ savingProduct.description }}</span>
  </div>

  <h3 class="mat-h3" fxFlexFill>{{"labels.inputs.currency" | translate}}</h3>

  <mat-divider fxFlexFill></mat-divider>

  <div fxFlexFill>
    <span fxFlex="40%">{{"labels.heading.currency" | translate}}:</span>
    <span fxFlex="60%">{{ savingProduct.currencyCode | find:savingProductsTemplate.currencyOptions:'code':'name' }}</span>
  </div>

  <div fxFlexFill>
    <span fxFlex="40%">{{"labels.inputs.decimalplace" | translate}}:</span>
    <span fxFlex="60%">{{ savingProduct.digitsAfterDecimal }}</span>
  </div>

  <div fxFlexFill>
    <span fxFlex="40%">{{"labels.heading.currencyinmultiplesof" | translate}}:</span>
    <span fxFlex="60%">{{ savingProduct.inMultiplesOf }}</span>
  </div>

  <h3 class="mat-h3" fxFlexFill>{{"labels.terms" | translate}}</h3>

  <mat-divider fxFlexFill></mat-divider>

  <div fxFlexFill>
    <span fxFlex="40%">{{"labels.heading.nominalannualinterestrate" | translate }}:</span>
    <span fxFlex="60%">{{ savingProduct.nominalAnnualInterestRate }}</span>
  </div>

  <div fxFlexFill>
    <span fxFlex="40%">{{"labels.heading.interestcompoundingperiod" | translate}}:</span>
    <span fxFlex="60%">{{ savingProduct.interestCompoundingPeriodType | find:savingProductsTemplate.interestCompoundingPeriodTypeOptions:'id':'value' }}</span>
  </div>

  <div fxFlexFill>
    <span fxFlex="40%">{{"labels.heading.interestpostingperiod" | translate}}:</span>
    <span fxFlex="60%">{{ savingProduct.interestPostingPeriodType | find:savingProductsTemplate.interestPostingPeriodTypeOptions:'id':'value' }}</span>
  </div>

  <div fxFlexFill>
    <span fxFlex="40%">{{"labels.heading.interestcalculatedusing" | translate}}:</span>
    <span fxFlex="60%">{{ savingProduct.interestCalculationType | find:savingProductsTemplate.interestCalculationTypeOptions:'id':'value' }}</span>
  </div>

  <div fxFlexFill>
    <span fxFlex="40%">{{"labels.heading.daysinyears" | translate}}:</span>
    <span fxFlex="60%">{{ savingProduct.interestCalculationDaysInYearType | find:savingProductsTemplate.interestCalculationDaysInYearTypeOptions:'id':'value' }}</span>
  </div>

  <h3 class="mat-h3" fxFlexFill>{{"labels.menus.Settings" | translate}}</h3>

  <mat-divider fxFlexFill></mat-divider>

  <div fxFlexFill *ngIf="savingProduct.minRequiredOpeningBalance">
    <span fxFlex="40%">{{"labels.heading.minimumopeningbalance" | translate }}:</span>
    <span fxFlex="60%">{{ savingProduct.minRequiredOpeningBalance }}</span>
  </div>

  <div fxFlexFill *ngIf="savingProduct.lockinPeriodFrequency">
    <span fxFlex="40%">{{"labels.heading.lockinPeriodFrequency" | translate}}:</span>
    <span fxFlex="60%">{{ savingProduct.lockinPeriodFrequency + ' ' + (savingProduct.lockinPeriodFrequencyType | find:savingProductsTemplate.lockinPeriodFrequencyTypeOptions:'id':'value') }}</span>
  </div>

  <div fxFlexFill>
    <span fxFlex="40%">{{"labels.inputs.withdrawalfeefortransfers" | translate }}:</span>
    <span fxFlex="60%">{{ savingProduct.withdrawalFeeForTransfers ? 'Yes' : 'No' }}</span>
  </div>

  <div fxFlexFill *ngIf="savingProduct.minBalanceForInterestCalculation">
    <span fxFlex="40%">{{"labels.inputs.minbalanceforinterest" | translate}}</span>
    <span fxFlex="60%">{{ savingProduct.minBalanceForInterestCalculation }}</span>
  </div>

  <div fxFlexFill>
    <span fxFlex="40%">{{"labels.inputs.withdrawalfeefortransfers" | translate }}:</span>
    <span fxFlex="60%">{{ savingProduct.enforceMinRequiredBalance ? 'Yes' : 'No' }}</span>
  </div>

  <div fxFlexFill *ngIf="savingProduct.minRequiredBalance">
    <span fxFlex="40%">{{"labels.inputs.minRequiredBalance" | translate }}:</span>
    <span fxFlex="60%">{{ savingProduct.minRequiredBalance }}</span>
  </div>

  <div fxFlexFill>
    <span fxFlex="40%">{{"labels.withholdTaxApplicable" | translate}}):</span>
    <span fxFlex="60%">{{ savingProduct.withHoldTax ? 'Yes' : 'No' }}</span>
  </div>

  <div fxFlexFill *ngIf="savingProduct.withHoldTax">
    <span fxFlex="40%">{{"labels.inputs.taxgroup" | translate}}):</span>
    <span fxFlex="60%">{{ savingProduct.taxGroupId | find:savingProductsTemplate.taxGroupOptions:'id':'name' }}</span>
  </div>

  <div fxFlexFill>
    <span fxFlex="40%">{{"labels.inputs.allowoverdraft" | translate }}:</span>
    <span fxFlex="60%">{{ savingProduct.allowOverdraft ? 'Yes' : 'No' }}</span>
  </div>

  <div fxFlexFill *ngIf="savingProduct.minOverdraftForInterestCalculation">
    <span fxFlex="40%">{{"labels.minimumOverdraftRequiredInterestCalculation" | translate }}:</span>
    <span fxFlex="60%">{{ savingProduct.minOverdraftForInterestCalculation }}</span>
  </div>

  <div fxFlexFill *ngIf="savingProduct.nominalAnnualInterestRateOverdraft">
    <span fxFlex="40%">{{"labels.inputs.nominalannualinterestrateoverdraft" | translate }}:</span>
    <span fxFlex="60%">{{ savingProduct.nominalAnnualInterestRateOverdraft }}</span>
  </div>

  <div fxFlexFill *ngIf="savingProduct.overdraftLimit">
    <span fxFlex="40%">{{"labels.inputs.overdraftlimit" | translate }}:</span>
    <span fxFlex="60%">{{ savingProduct.overdraftLimit }}</span>
  </div>

  <div fxFlexFill>
    <span fxFlex="40%">{{"labels.inputs.isDormancyTrackingActive" | translate }}:</span>
    <span fxFlex="60%">{{ savingProduct.isDormancyTrackingActive ? 'Yes' : 'No' }}</span>
  </div>

  <div fxFlexFill *ngIf="savingProduct.isDormancyTrackingActive" fxLayout="row wrap" fxLayout.lt-md="column">
    <span fxFlex="40%">{{"labels.inputs.daysToInactive" | translate }}:</span>
    <span fxFlex="60%">{{ savingProduct.daysToInactive }}</span>
    <span fxFlex="40%">{{"labels.inputs.daysToDormancy" | translate }}:</span>
    <span fxFlex="60%">{{ savingProduct.daysToDormancy }}</span>
    <span fxFlex="40%">{{"labels.inputs.daysToEscheat" | translate }}:</span>
    <span fxFlex="60%">{{ savingProduct.daysToEscheat }}</span>
  </div>

  <div fxFlexFill *ngIf="savingProduct.charges.length" fxLayout="row wrap" fxLayout.lt-md="column">

    <h3 class="mat-h3" fxFlexFill>{{"labels.anchors.charges" | translate}}</h3>

    <mat-divider fxFlexFill></mat-divider>

    <table fxFlexFill class="mat-elevation-z1" mat-table [dataSource]="savingProduct.charges">

      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef>{{"labels.inputs.name" | translate}}</th>
        <td mat-cell *matCellDef="let charge">
          {{ charge.name + ', ' + charge.currency.displaySymbol }}
        </td>
      </ng-container>

      <ng-container matColumnDef="chargeCalculationType">
        <th mat-header-cell *matHeaderCellDef>{{"labels.inputs.type" | translate}}</th>
        <td mat-cell *matCellDef="let charge">
          {{ charge.chargeCalculationType.value }}
        </td>
      </ng-container>

      <ng-container matColumnDef="amount">
        <th mat-header-cell *matHeaderCellDef>{{"labels.heading.amount" | translate}}</th>
        <td mat-cell *matCellDef="let charge">
          {{ charge.amount  }}
        </td>
      </ng-container>

      <ng-container matColumnDef="chargeTimeType">
        <th mat-header-cell *matHeaderCellDef>{{"labels.heading.collectedon" | translate}}</th>
        <td mat-cell *matCellDef="let charge">
          {{ charge.chargeTimeType.value }}
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="chargesDisplayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: chargesDisplayedColumns;"></tr>

    </table>

  </div>

  <h3 class="mat-h3" fxFlexFill>{{"labels.menus.Accounting" | translate}}</h3>

  <mat-divider fxFlexFill></mat-divider>

  <div fxFlexFill>
    <span fxFlex="40%">{{"labels.inputs.type" | translate}}:</span>
    <span fxFlex="60%">{{ accountingRuleData[savingProduct.accountingRule-1] }}</span>
  </div>

  <div fxFlexFill *ngIf="savingProduct.accountingRule === 2" fxLayout="row wrap" fxLayout.lt-md="column">

    <h4 fxFlexFill class="mat-h4">{{"labels.assets" | translate}}</h4>

    <div fxFlexFill fxLayout="row wrap" fxLayout.lt-md="column">
      <span fxFlex="40%">{{"labels.inputs.savingreference" | translate}}:</span>
      <span fxFlex="60%">{{ savingProduct.savingsReferenceAccountId | find:savingProductsTemplate.accountingMappingOptions.assetAccountOptions:'id':'name' }}</span>
      <span fxFlex="40%">{{"labels.inputs.overdraftportfoliocontrol" | translate }}:</span>
      <span fxFlex="60%">{{ savingProduct.overdraftPortfolioControlId | find:savingProductsTemplate.accountingMappingOptions.assetAccountOptions:'id':'name' }}</span>
    </div>

    <h4 fxFlexFill class="mat-h4">{{"labels.heading.liabilities" | translate}} </h4>

    <div fxFlexFill fxLayout="row wrap" fxLayout.lt-md="column">
      <span fxFlex="40%">{{"labels.inputs.savingcontrol" | translate}}:</span>
      <span fxFlex="60%">{{ savingProduct.savingsControlAccountId | find:savingProductsTemplate.accountingMappingOptions.liabilityAccountOptions:'id':'name' }}</span>
      <span fxFlex="40%">{{"labels.inputs.savingstransfersinsuspenpense" | translate}}:</span>
      <span fxFlex="60%">{{ savingProduct.transfersInSuspenseAccountId | find:savingProductsTemplate.accountingMappingOptions.liabilityAccountOptions:'id':'name' }}</span>
      <div fxFlexFill *ngIf="savingProduct.isDormancyTrackingActive">
        <span fxFlex="40%">{{"labels.inputs.escheatLiability" | translate }}:</span>
        <span fxFlex="60%">{{ savingProduct.escheatLiabilityId | find:savingProductsTemplate.accountingMappingOptions.liabilityAccountOptions:'id':'name' }}</span>
      </div>
    </div>

    <h4 fxFlexFill class="mat-h4">{{"labels.heading.expenses" | translate}} </h4>

    <div fxFlexFill fxLayout="row wrap" fxLayout.lt-md="column">
      <span fxFlex="40%">{{"labels.inputs.interestonsavings" | translate}}:</span>
      <span fxFlex="60%">{{ savingProduct.interestOnSavingsAccountId | find:savingProductsTemplate.accountingMappingOptions.expenseAccountOptions:'id':'name' }}</span>
      <span fxFlex="40%">{{"labels.buttons.writeoff" | translate }}:</span>
      <span fxFlex="60%">{{ savingProduct.writeOffAccountId | find:savingProductsTemplate.accountingMappingOptions.expenseAccountOptions:'id':'name' }}</span>
    </div>

    <h4 fxFlexFill class="mat-h4">{{"labels.heading.income" | translate}} </h4>

    <div fxFlexFill fxLayout="row wrap" fxLayout.lt-md="column">
      <span fxFlex="40%">{{"labels.heading.incomefromfees" | translate}}:</span>
      <span fxFlex="60%">{{ savingProduct.incomeFromFeeAccountId | find:savingProductsTemplate.accountingMappingOptions.incomeAccountOptions:'id':'name' }}</span>
      <span fxFlex="40%">{{"labels.heading.incomefrompenalties" | translate}}:</span>
      <span fxFlex="60%">{{ savingProduct.incomeFromPenaltyAccountId | find:savingProductsTemplate.accountingMappingOptions.incomeAccountOptions:'id':'name' }}</span>
      <span fxFlex="40%">{{"labels.inputs.interest.on.overdrafts" | translate }}:</span>
      <span fxFlex="60%">{{ savingProduct.incomeFromInterestId | find:savingProductsTemplate.accountingMappingOptions.incomeAccountOptions:'id':'name' }}</span>
    </div>

    <div *ngIf="savingProduct.advancedAccountingRules && (savingProduct.paymentChannelToFundSourceMappings?.length || savingProduct.feeToIncomeAccountMappings?.length || savingProduct.penaltyToIncomeAccountMappings?.length)" fxFlexFill fxLayout="row wrap" fxLayout.lt-md="column">

      <h3 class="mat-h3" fxFlexFill>{{"labels.heading.advancedaccountingrules" | translate}} </h3>

      <mat-divider fxFlexFill></mat-divider>

      <div *ngIf="savingProduct.paymentChannelToFundSourceMappings?.length" fxFlexFill fxLayout="row wrap" fxLayout.lt-md="column">

        <h4 class="mat-h4" fxFlexFill>{{"labels.fundsourcesforpaymentchannels" | translate}}:</h4>

        <table fxFlexFill class="mat-elevation-z1" mat-table [dataSource]="savingProduct.paymentChannelToFundSourceMappings">

          <ng-container matColumnDef="paymentTypeId">
            <th mat-header-cell *matHeaderCellDef>{{"labels.heading.paymenttype" | translate}} </th>
            <td mat-cell *matCellDef="let paymentFundSource">
              {{ paymentFundSource.paymentTypeId | find:savingProductsTemplate.paymentTypeOptions:'id':'name' }}
            </td>
          </ng-container>

          <ng-container matColumnDef="fundSourceAccountId">
            <th mat-header-cell *matHeaderCellDef>{{"labels.heading.fundsource" | translate}} </th>
            <td mat-cell *matCellDef="let paymentFundSource">
              {{ paymentFundSource.fundSourceAccountId | find:savingProductsTemplate.accountingMappingOptions.assetAccountOptions:'id':'name' }}
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="paymentFundSourceDisplayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: paymentFundSourceDisplayedColumns;"></tr>

        </table>

      </div>

      <div *ngIf="savingProduct.feeToIncomeAccountMappings?.length" fxFlexFill fxLayout="row wrap" fxLayout.lt-md="column">

        <h4 class="mat-h4" fxFlexFill>{{"labels.mapFeesSpecificIncomeAccounts" | translate}}:</h4>

        <table fxFlexFill class="mat-elevation-z1" mat-table [dataSource]="savingProduct.feeToIncomeAccountMappings">

          <ng-container matColumnDef="chargeId">
            <th mat-header-cell *matHeaderCellDef>{{"labels.commons.fees" | translate}}:</th>
            <td mat-cell *matCellDef="let feesIncome">
              {{ feesIncome.chargeId | find:savingProductsTemplate.chargeOptions:'id':'name' }}
            </td>
          </ng-container>

          <ng-container matColumnDef="incomeAccountId">
            <th mat-header-cell *matHeaderCellDef>{{"labels.heading.incomeaccount" | translate}} </th>
            <td mat-cell *matCellDef="let feesIncome">
              {{ feesIncome.incomeAccountId | find:savingProductsTemplate.accountingMappingOptions.incomeAccountOptions:'id':'name' }}
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="feesPenaltyIncomeDisplayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: feesPenaltyIncomeDisplayedColumns;"></tr>

        </table>

      </div>

      <div *ngIf="savingProduct.penaltyToIncomeAccountMappings?.length" fxFlexFill fxLayout="row wrap" fxLayout.lt-md="column">

        <h4 class="mat-h4" fxFlexFill>{{"labels.heading.mappenaltiestospecificincomeaccounts" | translate}}:</h4>

        <table fxFlexFill class="mat-elevation-z1" mat-table [dataSource]="savingProduct.penaltyToIncomeAccountMappings">

          <ng-container matColumnDef="chargeId">
            <th mat-header-cell *matHeaderCellDef>{{"labels.commons.penalty" | translate}} </th>
            <td mat-cell *matCellDef="let penaltyIncome">
              {{ penaltyIncome.chargeId  | find:savingProductsTemplate.penaltyOptions:'id':'name' }}
            </td>
          </ng-container>

          <ng-container matColumnDef="incomeAccountId">
            <th mat-header-cell *matHeaderCellDef>{{"labels.heading.incomeaccount" | translate}} </th>
            <td mat-cell *matCellDef="let penaltyIncome">
              {{ penaltyIncome.incomeAccountId | find:savingProductsTemplate.accountingMappingOptions.incomeAccountOptions:'id':'name' }}
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="feesPenaltyIncomeDisplayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: feesPenaltyIncomeDisplayedColumns;"></tr>

        </table>

      </div>

    </div>

  </div>

</div>

<div fxLayout="row" class="margin-t" fxLayout.xs="column" fxLayoutAlign="center" fxLayoutGap="2%">
  <button mat-raised-button matStepperPrevious>
    <fa-icon icon="arrow-left"></fa-icon>&nbsp;&nbsp;
    {{"labels.heading.prev" | translate}}
  </button>
  <button mat-raised-button [routerLink]="['../']">
    {{"labels.buttons.cancel" | translate}}
  </button>
  <button mat-raised-button color="primary" (click)="submit.emit()" *mifosxHasPermission="taskPermission">
    {{"labels.buttons.submit" | translate}}
  </button>
</div>
