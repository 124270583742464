<div class="container">

  <!-- <form [formGroup]="filtersForm">
    <mat-form-field style="width:300px" appearance="fill">
      <mat-label>{{ 'labels.menus.Dashboard' | translate }}</mat-label>
      <mat-select formControlName="dashboard" (selectionChange)="selectDahsboard($event.value)">
        <mat-option *ngFor="let dashboard of dashboards" [value]="dashboard">
          {{ dashboard.name }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </form> -->

<div id="select_bar" fxLayout="column" fxLayoutAlign="space-evenly start">
  <form [formGroup]="filtersForm">
    <label fxLayout="column" fxLayoutAlign="space-evenly start"> Client Trends for {{ filtersForm.get('dashboard').value?.name }}</label>

    <select fxLayout="column" fxLayoutAlign="space-evenly start" formControlName="dashboard" (ngModelChange)="selectDahsboard($event)">
      <option *ngFor="let dashboard of dashboards" [ngValue]="dashboard">
        {{ dashboard.name }}
      </option>
    </select>    
  </form>

</div>

    <div fxLayout="row" fxLayoutGap="40px" style="width: 100%; border: 1px solid #DDDDDD;">
      <mifosx-metabasedashboard [data]="dashData"></mifosx-metabasedashboard>
    </div>
</div>
