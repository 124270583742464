<div class="container">

  <mat-card>

    <div fxLayout="row" fxLayout.lt-md="column">

      <div fxFlex="50%">

        <mat-nav-list>

          <div #loanProducts>
            <mat-list-item [routerLink]="['loan-products']" *mifosxHasPermission="'READ_LOANPRODUCT'">
              <mat-icon matListIcon>
                <fa-icon icon="briefcase" size="sm"></fa-icon>
              </mat-icon>
              <h4 matLine>{{
                'labels.anchors.loanproducts' | translate }}</h4>
              <p matLine>{{'labels.addnewloanproductor' | translate}}</p>
            </mat-list-item>
          </div>

          <div #savingsProducts>
            <mat-list-item [routerLink]="['saving-products']" *mifosxHasPermission="'READ_SAVINGSPRODUCT'">
              <mat-icon matListIcon>
                <fa-icon icon="briefcase" size="sm"></fa-icon>
              </mat-icon>
              <h4 matLine>{{
                'labels.anchors.savingproducts' | translate }}</h4>
              <p matLine>{{'labels.addnewsavingproductor' | translate}}</p>
            </mat-list-item>
          </div>

          <div #shareProducts>
            <mat-list-item [routerLink]="['share-products']" *mifosxHasPermission="'READ_SHAREPRODUCT'">
              <mat-icon matListIcon>
                <fa-icon icon="briefcase" size="sm"></fa-icon>
              </mat-icon>
              <h4 matLine>{{
                'labels.anchors.shareproducts' | translate }}</h4>
              <p matLine>{{'labels.addnewshareproductor' | translate}}</p>
            </mat-list-item>
          </div>

          <div #charges>
            <mat-list-item [routerLink]="['charges']" *mifosxHasPermission="'READ_CHARGE'">
              <mat-icon matListIcon>
                <fa-icon icon="money-bill-alt" size="sm"></fa-icon>
              </mat-icon>
              <h4 matLine>{{
                'labels.anchors.charges' | translate }}</h4>
              <p matLine>{{'labels.definecharges' | translate}}</p>
            </mat-list-item>
          </div>

          <div #charges>
            <mat-list-item [routerLink]="['rates']" *mifosxHasPermission="'READ_RATE'">
              <mat-icon matListIcon>
                <fa-icon icon="money-bill-alt" size="sm"></fa-icon>
              </mat-icon>
              <h4 matLine>{{
                'labels.anchors.rates' | translate }}</h4>
              <p matLine>{{'labels.ratesText' | translate}}</p>
            </mat-list-item>
          </div>

          <mat-list-item [routerLink]="['collaterals']">
            <mat-icon matListIcon>
              <fa-icon icon="money-bill-alt" size="sm"></fa-icon>
            </mat-icon>
            <h4 matLine>{{'labels.heading.collaterals' | translate }}</h4>
            <p matLine>{{'labels.heading.collaterals' | translate}}</p>
          </mat-list-item>

          <mat-list-item [routerLink]="['delinquency-bucket-configurations']"
            *mifosxHasPermission="'READ_DELINQUENCY_BUCKET'">
            <mat-icon matListIcon>
              <fa-icon icon="briefcase" size="sm"></fa-icon>
            </mat-icon>
            <h4 matLine>{{
              'labels.anchors.delinquencyBuckets' | translate }}</h4>
            <p matLine>{{'labels.delinquencyBuckets' | translate}}</p>
          </mat-list-item>

        </mat-nav-list>

      </div>

      <div fxFlex="50%">

        <mat-nav-list>

          <mat-list-item [routerLink]="['products-mix']" *mifosxHasPermission="'READ_PRODUCTMIX'">
            <mat-icon matListIcon>
              <fa-icon icon="random" size="sm"></fa-icon>
            </mat-icon>
            <h4 matLine>{{
              'labels.anchors.productsmix' | translate }}</h4>
            <p matLine>{{'labels.definesrulesfortakingmul' | translate}}</p>
          </mat-list-item>

          <div #fixedDepositProducts>
            <mat-list-item [routerLink]="['fixed-deposit-products']" *mifosxHasPermission="'READ_FIXEDDEPOSITPRODUCT'">
              <mat-icon matListIcon>
                <fa-icon icon="briefcase" size="sm"></fa-icon>
              </mat-icon>
              <h4 matLine>{{
                'labels.anchors.fixeddepositproducts' | translate }}</h4>
              <p matLine>{{'labels.fixeddepositproduct.def' | translate}}</p>
            </mat-list-item>
          </div>

          <div #recurringDepositProducts>
            <mat-list-item [routerLink]="['recurring-deposit-products']"
              *mifosxHasPermission="'READ_RECURRINGDEPOSITPRODUCT'">
              <mat-icon matListIcon>
                <fa-icon icon="briefcase" size="sm"></fa-icon>
              </mat-icon>
              <h4 matLine>{{
                'labels.anchors.recurringdepositproducts' | translate }}</h4>
              <p matLine>{{'labels.recurringdepositproduct.def' | translate}}</p>
            </mat-list-item>
          </div>

          <mat-list-item [routerLink]="['tax-configurations']" *mifosxHasPermission="'READ_TAXGROUP'">
            <mat-icon matListIcon>
              <fa-icon icon="cogs" size="sm"></fa-icon>
            </mat-icon>
            <h4 matLine>{{'labels.anchors.managetaxconfigurations' | translate}}</h4>
            <p matLine>{{'labels.view.taxconfiguration' |
              translate}}</p>
          </mat-list-item>

          <mat-list-item [routerLink]="['floating-rates']" *mifosxHasPermission="'READ_FLOATINGRATE'">
            <mat-icon matListIcon>
              <fa-icon icon="money-bill-alt" size="sm"></fa-icon>
            </mat-icon>
            <h4 matLine>{{
              'labels.anchors.floatingrates' | translate }}</h4>
            <p matLine>{{'labels.definefloatingrates' | translate}}</p>
          </mat-list-item>

          <mat-list-item [routerLink]="['product-lines']">
            <mat-icon matListIcon>
              <fa-icon icon="random" size="sm"></fa-icon>
            </mat-icon>
            <h4 matLine>{{'labels.productLines' | translate }}</h4>
            <p matLine>{{'labels.productLines'| translate}}</p>
          </mat-list-item>

        </mat-nav-list>

      </div>

    </div>

  </mat-card>

</div>

<ng-template #templateCharges let-popover="popover">
  <h2>{{'labels.anchors.charges' | translate }}</h2>
  <p class="mw400">{{'labels.heading.chargessText' | translate }}</p>
  <div fxLayout="row" fxLayoutAlign="end" fxLayoutGap="2%" fxLayout.lt-md="column">
    <button mat-raised-button color="warn"
      (click)="popover.close();configurationWizardService.closeConfigWizard()">{{"labels.buttons.close" | translate}}</button>
    <button mat-raised-button color="primary" (click)="popover.close();previousStepCharges()">{{"labels.buttons.back" | translate}}</button>
    <button mat-raised-button color="primary" (click)="popover.close();nextStepCharges()">{{"labels.buttons.next" | translate}}</button>
  </div>
</ng-template>

<ng-template #templateLoanProducts let-popover="popover">
  <h2>{{'labels.anchors.loanproducts' | translate }}</h2>
  <p class="mw400">{{'labels.heading.loanproductsText' | translate }}.</p>
  <div fxLayout="row" fxLayoutAlign="end" fxLayoutGap="2%" fxLayout.lt-md="column">
    <button mat-raised-button color="warn"
      (click)="popover.close();configurationWizardService.closeConfigWizard()">{{"labels.buttons.close" | translate}}</button>
    <button mat-raised-button color="primary" (click)="popover.close();previousStepLoanProducts()">{{"labels.buttons.back" | translate}}</button>
    <button mat-raised-button color="primary" (click)="popover.close();nextStepLoanProducts()">{{"labels.buttons.next" | translate}}</button>
  </div>
</ng-template>

<ng-template #templateSavingsProducts let-popover="popover">
  <h2>{{'labels.anchors.savingproducts' | translate }}</h2>
  <p class="mw400">{{'labels.heading.savingproductsText' | translate }}.</p>
  <div fxLayout="row" fxLayoutAlign="end" fxLayoutGap="2%" fxLayout.lt-md="column">
    <button mat-raised-button color="warn"
      (click)="popover.close();configurationWizardService.closeConfigWizard()">{{"labels.buttons.close" | translate}}</button>
    <button mat-raised-button color="primary" (click)="popover.close();previousStepSavingsProducts()">{{"labels.buttons.back" | translate}}</button>
    <button mat-raised-button color="primary" (click)="popover.close();nextStepSavingsProducts()">{{"labels.buttons.next" | translate}}</button>
  </div>
</ng-template>

<ng-template #templateShareProducts let-popover="popover">
  <h2>{{'labels.anchors.shareproducts' | translate }}</h2>
  <p class="mw400">{{'labels.heading.shareproductsText' | translate }}.</p>
  <div fxLayout="row" fxLayoutAlign="end" fxLayoutGap="2%" fxLayout.lt-md="column">
    <button mat-raised-button color="warn"
      (click)="popover.close();configurationWizardService.closeConfigWizard()">{{"labels.buttons.close" | translate}}</button>
    <button mat-raised-button color="primary" (click)="popover.close();previousStepShareProducts()">{{"labels.buttons.back" | translate}}</button>
    <button mat-raised-button color="primary" (click)="popover.close();nextStepShareProducts()">{{"labels.buttons.next" | translate}}</button>
  </div>
</ng-template>

<ng-template #templateFixedDepositProducts let-popover="popover">
  <h2>{{'labels.anchors.fixeddepositproducts' | translate }}</h2>
  <p class="mw400">{{'labels.heading.fixeddepositproductsText' | translate }}.</p>
  <div fxLayout="row" fxLayoutAlign="end" fxLayoutGap="2%" fxLayout.lt-md="column">
    <button mat-raised-button color="warn"
      (click)="popover.close();configurationWizardService.closeConfigWizard()">{{"labels.buttons.close" | translate}}</button>
    <button mat-raised-button color="primary" (click)="popover.close();previousStepFixedDepositProducts()">{{"labels.buttons.back" | translate}}</button>
    <button mat-raised-button color="primary" (click)="popover.close();nextStepFixedDepositProducts()">{{"labels.buttons.next" | translate}}</button>
  </div>
</ng-template>

<ng-template #templateRecurringDepositProducts let-popover="popover">
  <h2>{{'labels.anchors.recurringdepositproducts' | translate }}</h2>
  <p class="mw400">{{'labels.heading.recurringdepositproductsText' | translate }}</p>
  <div fxLayout="row" fxLayoutAlign="end" fxLayoutGap="2%" fxLayout.lt-md="column">
    <button mat-raised-button color="warn"
      (click)="popover.close();configurationWizardService.closeConfigWizard()">{{"labels.buttons.close" | translate}}</button>
    <button mat-raised-button color="primary"
      (click)="popover.close();previousStepRecurringDepositProducts()">{{"labels.buttons.back" | translate}}</button>
    <button mat-raised-button color="primary" (click)="popover.close();nextStepRecurringDepositProducts()">{{"labels.buttons.next" | translate}}</button>
  </div>
</ng-template>
