<div fxLayout="row wrap" fxLayout.lt-md="column">

  <h2 class="mat-h2" fxFlexFill>{{ loanProduct.name }}</h2>

  <mat-divider fxFlexFill></mat-divider>

  <h3 class="mat-h3" fxFlexFill>{{"labels.buttons.details" | translate}}</h3>

  <mat-divider fxFlexFill></mat-divider>

  <div fxFlexFill>
    <span fxFlex="40%">{{"labels.inputs.product.shortname" | translate}}:</span>
    <span fxFlex="60%">{{ loanProduct.shortName }}</span>
  </div>

  <div fxFlexFill *ngIf="loanProduct.fundId">
    <span fxFlex="40%">{{"labels.heading.fundname" | translate }}:</span>
    <span fxFlex="60%">{{ loanProduct.fundId | find:loanProductsTemplate.fundOptions:'id':'name' }}</span>
  </div>

  <div fxFlexFill>
    <span fxFlex="40%">{{"labels.heading.includeborrowercycle" | translate }}:</span>
    <span fxFlex="60%">{{ loanProduct.includeInBorrowerCycle ? 'Yes' : 'No' }}</span>
  </div>

  <div fxFlexFill *ngIf="loanProduct.startDate">
    <span fxFlex="40%">{{"labels.inputs.startdate" | translate }}:</span>
    <span fxFlex="60%">{{ loanProduct.startDate }}</span>
  </div>

  <div fxFlexFill *ngIf="loanProduct.closeDate">
    <span fxFlex="40%">{{"labels.inputs.closedate" | translate }}:</span>
    <span fxFlex="60%">{{ loanProduct.closeDate }}</span>
  </div>

  <div fxFlexFill *ngIf="loanProduct.description">
    <span fxFlex="40%">{{"labels.heading.description" | translate}}:</span>
    <span fxFlex="60%">{{ loanProduct.description }}</span>
  </div>

  <h3 class="mat-h3" fxFlexFill>{{"labels.inputs.currency" | translate}}</h3>

  <mat-divider fxFlexFill></mat-divider>

  <div fxFlexFill>
    <span fxFlex="40%">{{"labels.heading.currency" | translate}}:</span>
    <span fxFlex="60%">{{ loanProduct.currencyCode | find:loanProductsTemplate.currencyOptions:'code':'name' }}</span>
  </div>

  <div fxFlexFill>
    <span fxFlex="40%">{{"labels.inputs.decimalplace" | translate}}:</span>
    <span fxFlex="60%">{{ loanProduct.digitsAfterDecimal }}</span>
  </div>

  <div fxFlexFill>
    <span fxFlex="40%">{{"labels.heading.currencyinmultiplesof" | translate}}:</span>
    <span fxFlex="60%">{{ loanProduct.inMultiplesOf }}</span>
  </div>

  <div fxFlexFill>
    <span fxFlex="40%">{{"labels.inputs.installmentinmultiplesof" | translate }}:</span>
    <span fxFlex="60%">{{ loanProduct.installmentAmountInMultiplesOf }}</span>
  </div>

  <h3 class="mat-h3" fxFlexFill>{{"labels.terms" | translate}}</h3>

  <mat-divider fxFlexFill></mat-divider>

  <div fxFlexFill>
    <span fxFlex="40%">{{"labels.inputs.principalf" | translate }}:</span>
    <span fxFlex="60%">{{ loanProduct.principal + ' (Min: ' + (loanProduct.minPrincipal ? loanProduct.minPrincipal : loanProduct.principal) + ', Max: ' + (loanProduct.maxPrincipal ? loanProduct.maxPrincipal : loanProduct.principal) + ')' }}</span>
  </div>

  <div fxFlexFill>
    <span fxFlex="40%">{{"labels.allowApprovedDisbursedAmountsOverApplied" | translate }}:</span>
    <span fxFlex="60%">{{ loanProduct.allowApprovedDisbursedAmountsOverApplied ? 'Yes' : 'No' }}</span>
  </div>

  <div fxFlexFill *ngIf="loanProduct.allowApprovedDisbursedAmountsOverApplied">
    <span fxFlex="40%">{{"labels.overApplied" | translate }}:</span>
    <span fxFlex="60%" *ngIf="loanProduct.overAppliedCalculationType === 'percentage'">{{ loanProduct.overAppliedNumber }} %</span>
    <span fxFlex="60%" *ngIf="loanProduct.overAppliedCalculationType === 'flat'">{{ loanProduct.overAppliedNumber }} {{ loanProduct.currencyCode }}</span>
</div>

  <div fxFlexFill>
    <span fxFlex="40%">{{"labels.inputs.numofrepayments" | translate }}:</span>
    <span fxFlex="60%">{{ loanProduct.numberOfRepayments + ' (Min: ' + (loanProduct.minNumberOfRepayments ? loanProduct.minNumberOfRepayments : loanProduct.numberOfRepayments) + ', Max: ' + (loanProduct.maxNumberOfRepayments ? loanProduct.maxNumberOfRepayments : loanProduct.numberOfRepayments) + ')' }}</span>
  </div>

  <div fxFlexFill>
    <span fxFlex="40%">{{"labels.linedtofloatinginterestrates" | translate }}:</span>
    <span fxFlex="60%">{{ loanProduct.isLinkedToFloatingInterestRates ? 'Yes' : 'No' }}</span>
  </div>

  <div fxFlexFill *ngIf="!loanProduct.isLinkedToFloatingInterestRates">
    <span fxFlex="40%">{{"labels.inputs.nominalinterestrate" | translate }}:</span>
    <span fxFlex="60%">{{ loanProduct.interestRatePerPeriod + ' (Min: ' + (loanProduct.minInterestRatePerPeriod ? loanProduct.minInterestRatePerPeriod : loanProduct.interestRatePerPeriod) + ', Max: ' + (loanProduct.maxInterestRatePerPeriod ? loanProduct.maxInterestRatePerPeriod : loanProduct.interestRatePerPeriod) + ')' + ' ' + (loanProduct.interestRateFrequencyType | find:loanProductsTemplate.interestRateFrequencyTypeOptions:'id':'value') }}</span>
  </div>

  <div fxFlexFill *ngIf="loanProduct.isLinkedToFloatingInterestRates" fxLayout="row wrap" fxLayout.lt-md="column">
    <span fxFlex="40%">{{"labels.heading.floatinglendingrate" | translate }}:</span>
    <span fxFlex="60%">{{ loanProduct.floatingRatesId | find:loanProductsTemplate.floatingRateOptions:'id':'name' }}</span>
    <span fxFlex="40%">{{"labels.heading.floatinginterestratedifferential" | translate }}:</span>
    <span fxFlex="60%">{{ loanProduct.interestRateDifferential }}</span>
    <span fxFlex="40%">{{"labels.heading.isFloatingInterestRateCalculationAllowed" | translate }}:</span>
    <span fxFlex="60%">{{ loanProduct.isFloatingInterestRateCalculationAllowed ? 'Yes' : 'No' }}</span>
    <span fxFlex="40%">{{"labels.inputs.floatinginterestrate" | translate }}:</span>
    <span fxFlex="60%">{{ loanProduct.defaultDifferentialLendingRate + ' (Min: ' + (loanProduct.minDifferentialLendingRate ? loanProduct.minDifferentialLendingRate : loanProduct.defaultDifferentialLendingRate) + ', Max: ' + (loanProduct.maxDifferentialLendingRate ? loanProduct.maxDifferentialLendingRate : loanProduct.defaultDifferentialLendingRate) + ')' }}</span>
  </div>

  <div fxFlexFill>
    <span fxFlex="40%">{{"labels.inputs.useborrowerloancounter" | translate }}:</span>
    <span fxFlex="60%">{{ loanProduct.useBorrowerCycle ? 'Yes' : 'No' }}</span>
  </div>

  <div fxFlexFill *ngIf="loanProduct.principalVariationsForBorrowerCycle?.length">

    <h4 class="mat-h4" fxFlexFill>{{"labels.heading.principalCycleVariations" | translate }}:</h4>

    <table fxFlexFill class="mat-elevation-z1" mat-table [dataSource]="loanProduct.principalVariationsForBorrowerCycle">

      <ng-container matColumnDef="valueConditionType">
        <th mat-header-cell *matHeaderCellDef>{{"labels.condition" | translate }}:</th>
        <td mat-cell *matCellDef="let variation">
          {{ variation.valueConditionType | find:loanProductsTemplate.valueConditionTypeOptions:'id':'value' }}
        </td>
      </ng-container>

      <ng-container matColumnDef="borrowerCycleNumber">
        <th mat-header-cell *matHeaderCellDef>{{"labels.inputs.cyclenumber" | translate }}:</th>
        <td mat-cell *matCellDef="let variation">
          {{ variation.borrowerCycleNumber }}
        </td>
      </ng-container>

      <ng-container matColumnDef="minValue">
        <th mat-header-cell *matHeaderCellDef>{{"labels.inputs.minimum" | translate }}:</th>
        <td mat-cell *matCellDef="let variation">
          {{ variation.minValue  }}
        </td>
      </ng-container>

      <ng-container matColumnDef="defaultValue">
        <th mat-header-cell *matHeaderCellDef>{{"labels.inputs.default" | translate }}:</th>
        <td mat-cell *matCellDef="let variation">
          {{ variation.defaultValue }}
        </td>
      </ng-container>

      <ng-container matColumnDef="maxValue">
        <th mat-header-cell *matHeaderCellDef>{{"labels.inputs.maximum" | translate }}:</th>
        <td mat-cell *matCellDef="let variation">
          {{ variation.maxValue }}
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="variationsDisplayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: variationsDisplayedColumns;"></tr>

    </table>

  </div>

  <div fxFlexFill *ngIf="loanProduct.numberOfRepaymentVariationsForBorrowerCycle?.length">

    <h4 class="mat-h4" fxFlexFill>{{"labels.heading.numerOfRepaymentCycleVariations" | translate }}:</h4>

    <table fxFlexFill class="mat-elevation-z1" mat-table [dataSource]="loanProduct.numberOfRepaymentVariationsForBorrowerCycle">

      <ng-container matColumnDef="valueConditionType">
        <th mat-header-cell *matHeaderCellDef>{{"labels.condition" | translate }}:</th>
        <td mat-cell *matCellDef="let variation">
          {{ variation.valueConditionType | find:loanProductsTemplate.valueConditionTypeOptions:'id':'value' }}
        </td>
      </ng-container>

      <ng-container matColumnDef="borrowerCycleNumber">
        <th mat-header-cell *matHeaderCellDef>{{"labels.inputs.cyclenumber" | translate }}:</th>
        <td mat-cell *matCellDef="let variation">
          {{ variation.borrowerCycleNumber }}
        </td>
      </ng-container>

      <ng-container matColumnDef="minValue">
        <th mat-header-cell *matHeaderCellDef>{{"labels.inputs.minimum" | translate }}:</th>
        <td mat-cell *matCellDef="let variation">
          {{ variation.minValue  }}
        </td>
      </ng-container>

      <ng-container matColumnDef="defaultValue">
        <th mat-header-cell *matHeaderCellDef>{{"labels.inputs.default" | translate }}:</th>
        <td mat-cell *matCellDef="let variation">
          {{ variation.defaultValue }}
        </td>
      </ng-container>

      <ng-container matColumnDef="maxValue">
        <th mat-header-cell *matHeaderCellDef>{{"labels.inputs.maximum" | translate }}:</th>
        <td mat-cell *matCellDef="let variation">
          {{ variation.maxValue }}
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="variationsDisplayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: variationsDisplayedColumns;"></tr>

    </table>

  </div>

  <div fxFlexFill *ngIf="loanProduct.interestRateVariationsForBorrowerCycle?.length">

    <h4 class="mat-h4" fxFlexFill>{{"labels.heading.interestRateCycleVariations" | translate }}:</h4>

    <table fxFlexFill class="mat-elevation-z1" mat-table [dataSource]="loanProduct.interestRateVariationsForBorrowerCycle">

      <ng-container matColumnDef="valueConditionType">
        <th mat-header-cell *matHeaderCellDef>{{"labels.condition" | translate }}:</th>
        <td mat-cell *matCellDef="let variation">
          {{ variation.valueConditionType | find:loanProductsTemplate.valueConditionTypeOptions:'id':'value' }}
        </td>
      </ng-container>

      <ng-container matColumnDef="borrowerCycleNumber">
        <th mat-header-cell *matHeaderCellDef>{{"labels.inputs.cyclenumber" | translate }}:</th>
        <td mat-cell *matCellDef="let variation">
          {{ variation.borrowerCycleNumber }}
        </td>
      </ng-container>

      <ng-container matColumnDef="minValue">
        <th mat-header-cell *matHeaderCellDef>{{"labels.inputs.minimum" | translate }}:</th>
        <td mat-cell *matCellDef="let variation">
          {{ variation.minValue  }}
        </td>
      </ng-container>

      <ng-container matColumnDef="defaultValue">
        <th mat-header-cell *matHeaderCellDef>{{"labels.inputs.default" | translate }}:</th>
        <td mat-cell *matCellDef="let variation">
          {{ variation.defaultValue }}
        </td>
      </ng-container>

      <ng-container matColumnDef="maxValue">
        <th mat-header-cell *matHeaderCellDef>{{"labels.inputs.maximum" | translate }}:</th>
        <td mat-cell *matCellDef="let variation">
          {{ variation.maxValue }}
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="variationsDisplayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: variationsDisplayedColumns;"></tr>

    </table>

  </div>

  <div fxFlexFill>
    <span fxFlex="40%">{{"labels.heading.repayevery" | translate }}:</span>
    <span fxFlex="60%">{{ loanProduct.repaymentEvery + ' ' + (loanProduct.repaymentFrequencyType | find:loanProductsTemplate.repaymentFrequencyTypeOptions:'id':'value') }}</span>
  </div>

  <div fxFlexFill *ngIf="loanProduct.minimumDaysBetweenDisbursalAndFirstRepayment">
    <span fxFlex="40%">{{"labels.inputs.minimum.days.between.disbursal.and.first.repayment" | translate }}:</span>
    <span fxFlex="60%">{{ loanProduct.minimumDaysBetweenDisbursalAndFirstRepayment }}</span>
  </div>

  <h3 class="mat-h3" fxFlexFill>{{"labels.menus.Settings" | translate}}</h3>

  <mat-divider fxFlexFill></mat-divider>

  <div fxFlexFill>
    <span fxFlex="40%">{{"labels.heading.amortization" | translate}}:</span>
    <span fxFlex="60%">{{ loanProduct.amortizationType | find:loanProductsTemplate.amortizationTypeOptions:'id':'value' }}</span>
  </div>

  <div fxFlexFill>
    <span fxFlex="40%">{{"labels.inputs.isequalamortization" | translate}}:</span>
    <span fxFlex="60%">{{ loanProduct.isEqualAmortization ? 'Yes' : 'No' }}</span>
  </div>

  <div fxFlexFill>
    <span fxFlex="40%">{{"labels.heading.interestmethod" | translate }}:</span>
    <span fxFlex="60%">{{ loanProduct.interestType | find:loanProductsTemplate.interestTypeOptions:'id':'value' }}</span>
  </div>

  <div fxFlexFill>
    <span fxFlex="40%">{{"labels.heading.interestcalculationperiod" | translate }}:</span>
    <span fxFlex="60%">{{ loanProduct.interestCalculationPeriodType | find:loanProductsTemplate.interestCalculationPeriodTypeOptions:'id':'value' }}</span>
  </div>

  <div fxFlexFill *ngIf="loanProduct.interestCalculationPeriodType === 1">
    <span fxFlex="40%">{{"labels.heading.allowpartialperiodinterestcalcualtion" | translate }}:</span>
    <span fxFlex="60%">{{ loanProduct.allowPartialPeriodInterestCalcualtion ? 'Yes' : 'No' }}</span>
  </div>

  <div fxFlexFill>
    <span fxFlex="40%">{{"labels.heading.repaymentstrategy" | translate }}:</span>
    <span fxFlex="60%">{{ loanProduct.transactionProcessingStrategyId | find:loanProductsTemplate.transactionProcessingStrategyOptions:'code':'name' }}</span>
  </div>

  <div fxFlexFill *ngIf="loanProduct.graceOnPrincipalPayment">
    <span fxFlex="40%">{{"labels.gracePrincipalPayment" | translate }}:</span>
    <span fxFlex="60%">{{ loanProduct.graceOnPrincipalPayment }}</span>
  </div>

  <div fxFlexFill *ngIf="loanProduct.graceOnInterestPayment">
    <span fxFlex="40%">{{"labels.graceInterestPayment" | translate }}:</span>
    <span fxFlex="60%">{{ loanProduct.graceOnInterestPayment }}</span>
  </div>

  <div fxFlexFill *ngIf="loanProduct.graceOnInterestCharged">
    <span fxFlex="40%">{{"labels.inputs.interestfreeperiod" | translate }}:</span>
    <span fxFlex="60%">{{ loanProduct.graceOnInterestCharged }}</span>
  </div>

  <div fxFlexFill *ngIf="loanProduct.inArrearsTolerance">
    <span fxFlex="40%">{{"labels.heading.arearstolerance" | translate }}:</span>
    <span fxFlex="60%">{{ loanProduct.inArrearsTolerance }}</span>
  </div>

  <div fxFlexFill>
    <span fxFlex="40%">{{"labels.heading.daysinyears" | translate}}:</span>
    <span fxFlex="60%">{{ loanProduct.daysInYearType | find:loanProductsTemplate.daysInYearTypeOptions:'id':'value' }}</span>
  </div>

  <div fxFlexFill>
    <span fxFlex="40%">{{"labels.inputs.daysinmonth" | translate }}:</span>
    <span fxFlex="60%">{{ loanProduct.daysInMonthType | find:loanProductsTemplate.daysInMonthTypeOptions:'id':'value' }}</span>
  </div>

  <div fxFlexFill>
    <span fxFlex="40%">{{"labels.inputs.candefineinstallmentamount" | translate }}:</span>
    <span fxFlex="60%">{{ loanProduct.canDefineInstallmentAmount ? 'Yes' : 'No' }}</span>
  </div>

  <div fxFlexFill *ngIf="loanProduct.graceOnArrearsAgeing">
    <span fxFlex="40%">{{"labels.inputs.no.of.overdue.days.to.move.loan.into.arrears" | translate }}:</span>
    <span fxFlex="60%">{{ loanProduct.graceOnArrearsAgeing }}</span>
  </div>

  <div fxFlexFill *ngIf="loanProduct.overdueDaysForNPA">
    <span fxFlex="40%">{{"labels.inputs.no.of.overdue.days.to.change.loan.into.npa" | translate }}:</span>
    <span fxFlex="60%">{{ loanProduct.overdueDaysForNPA }}</span>
  </div>

  <div fxFlexFill>
    <span fxFlex="40%">{{"labels.anchors.delinquencyBuckets" | translate }}:</span>
    <span fxFlex="60%">{{ loanProduct.delinquencyBucketId ? 'Yes' : 'No' }}</span>
  </div>

  <div fxFlexFill>
    <span fxFlex="40%">{{"labels.inputs.loan.account.moves.out.of.npa.only.on.arrears.completion" | translate }}:</span>
    <span fxFlex="60%">{{ loanProduct.accountMovesOutOfNPAOnlyOnArrearsCompletion ? 'Yes' : 'No' }}</span>
  </div>

  <div fxFlexFill *ngIf="loanProduct.principalThresholdForLastInstallment">
    <span fxFlex="40%">{{"labels.inputs.thresholdforlastinstallment" | translate }}:</span>
    <span fxFlex="60%">{{ loanProduct.principalThresholdForLastInstallment }}</span>
  </div>

  <div fxFlexFill>
    <span fxFlex="40%">{{"labels.inputs.variable.installments.allowed" | translate }}:</span>
    <span fxFlex="60%">{{ loanProduct.allowVariableInstallments ? 'Yes' : 'No' }}</span>
  </div>

  <div fxFlexFill *ngIf="loanProduct.allowVariableInstallments">
    <span fxFlex="40%">{{"labels.inputs.variableinstallments" | translate }}:</span>
    <span fxFlex="60%">{{ '(Min: ' + loanProduct.minimumGap + ', Max: ' + (loanProduct.maximumGap ? loanProduct.maximumGap : loanProduct.minimumGap) + ')' }}</span>
  </div>

  <div fxFlexFill>
    <span fxFlex="40%">{{"labels.inputs.canUseForTopup" | translate }}</span>
    <span fxFlex="60%">{{ loanProduct.canUseForTopup ? 'Yes' : 'No' }}</span>
  </div>

  <h3 class="mat-h3" fxFlexFill>{{"labels.heading.interestRecalculation" | translate }}:</h3>

  <mat-divider fxFlexFill></mat-divider>

  <div fxFlexFill>
    <span fxFlex="40%">{{"labels.inputs.recalculateinterest" | translate }}:</span>
    <span fxFlex="60%">{{ loanProduct.isInterestRecalculationEnabled ? 'Yes' : 'No' }}</span>
  </div>

  <div fxFlexFill *ngIf="loanProduct.isInterestRecalculationEnabled" fxLayout="row wrap" fxLayout.lt-md="column">
    <span fxFlex="40%">{{"labels.inputs.preclose.interest.calculation.strategy" | translate }}:</span>
    <span fxFlex="60%">{{ loanProduct.preClosureInterestCalculationStrategy | find:loanProductsTemplate.preClosureInterestCalculationStrategyOptions:'id':'value' }}</span>
    <span fxFlex="40%">{{"labels.inputs.interest.recalculation.reschdule.strategy" | translate }}:</span>
    <span fxFlex="60%">{{ loanProduct.rescheduleStrategyMethod | find:loanProductsTemplate.rescheduleStrategyTypeOptions:'id':'value' }}</span>
    <span fxFlex="40%">{{"labels.inputs.interest.recalculation.compounding.method" | translate }}:</span>
    <span fxFlex="60%">{{ loanProduct.interestRecalculationCompoundingMethod | find:loanProductsTemplate.interestRecalculationCompoundingTypeOptions:'id':'value' }}</span>
    <div fxFlexFill *ngIf="loanProduct.interestRecalculationCompoundingMethod !== 0" fxLayout="row wrap" fxLayout.lt-md="column">
      <span fxFlex="40%">{{"labels.inputs.frequency.for.compounding" | translate }}:</span>
      <span fxFlex="60%">
        {{ loanProduct.recalculationCompoundingFrequencyType | find:loanProductsTemplate.interestRecalculationFrequencyTypeOptions:'id':'value' }}
        <span *ngIf="loanProduct.recalculationCompoundingFrequencyType === 4">&nbsp;&nbsp;{{ loanProduct.recalculationCompoundingFrequencyNthDayType | find:loanProductsTemplate.interestRecalculationNthDayTypeOptions:'id':'value' }}</span>
        <span *ngIf="(loanProduct.recalculationCompoundingFrequencyType === 4 && loanProduct.recalculationCompoundingFrequencyNthDayType !== -2) || loanProduct.recalculationCompoundingFrequencyType === 3">&nbsp;&nbsp;{{ loanProduct.recalculationCompoundingFrequencyDayOfWeekType | find:loanProductsTemplate.interestRecalculationDayOfWeekTypeOptions:'id':'value' }}</span>
        <span *ngIf="loanProduct.recalculationCompoundingFrequencyType === 4 && loanProduct.recalculationCompoundingFrequencyNthDayType === -2">&nbsp;&nbsp;{{ loanProduct.recalculationCompoundingFrequencyOnDayType }}</span>
      </span>
      <div fxFlexFill *ngIf="loanProduct.recalculationCompoundingFrequencyType !== 1" fxLayout="row wrap" fxLayout.lt-md="column">
        <span fxFlex="40%">{{"labels.inputs.frequenc.interval.for.compounding" | translate }}:</span>
        <span fxFlex="60%">{{ loanProduct.recalculationCompoundingFrequencyInterval }}</span>
      </div>
    </div>
    <div fxFlexFill fxLayout="row wrap" fxLayout.lt-md="column">
      <span fxFlex="40%">{{"labels.inputs.frequency.for.recalculte.outstanding.principal" | translate }}:</span>
      <span fxFlex="60%">
        {{ loanProduct.recalculationRestFrequencyType | find:loanProductsTemplate.interestRecalculationFrequencyTypeOptions:'id':'value' }}
        <span *ngIf="loanProduct.recalculationRestFrequencyType === 4">&nbsp;&nbsp;{{ loanProduct.recalculationRestFrequencyNthDayType | find:loanProductsTemplate.interestRecalculationNthDayTypeOptions:'id':'value' }}</span>
        <span *ngIf="(loanProduct.recalculationRestFrequencyType === 4 && loanProduct.recalculationRestFrequencyNthDayType !== -2) || loanProduct.recalculationRestFrequencyType === 3">&nbsp;&nbsp;{{ loanProduct.recalculationRestFrequencyDayOfWeekType | find:loanProductsTemplate.interestRecalculationDayOfWeekTypeOptions:'id':'value' }}</span>
        <span *ngIf="loanProduct.recalculationRestFrequencyType === 4 && loanProduct.recalculationRestFrequencyNthDayType === -2">&nbsp;&nbsp;{{ loanProduct.recalculationRestFrequencyOnDayType }}</span>
      </span>
    </div>
    <div fxFlexFill *ngIf="loanProduct.recalculationRestFrequencyType !== 1" fxLayout="row wrap" fxLayout.lt-md="column">
      <span fxFlex="40%">{{"labels.inputs.frequenc.interval.for.recalculte.outstanding.principal" | translate }}:</span>
      <span fxFlex="60%">{{ loanProduct.recalculationRestFrequencyInterval }}</span>
      <div fxFlexFill *ngIf="loanProduct.recalculationRestFrequencyDate">
        <span fxFlex="40%">{{"labels.restFrequencyDate" | translate }}:</span>
        <span fxFlex="60%">{{ loanProduct.recalculationRestFrequencyDate }}</span>
      </div>
    </div>
    <div fxFlexFill>
      <span fxFlex="40%">{{"labels.inputs.arrearsbasedonoriginalschedule" | translate }}:</span>
      <span fxFlex="60%">{{ loanProduct.isArrearsBasedOnOriginalSchedule ? 'Yes' : 'No' }}</span>
    </div>
  </div>

  <h3 class="mat-h3" fxFlexFill>{{"labels.heading.gauranteeRequirements" | translate }}:</h3>

  <mat-divider fxFlexFill></mat-divider>

  <div fxFlexFill>
    <span fxFlex="40%">{{"labels.inputs.holdguaranteefunds" | translate }}</span>
    <span fxFlex="60%">{{ loanProduct.holdGuaranteeFunds ? 'Yes' : 'No' }}</span>
  </div>

  <div fxFlexFill *ngIf="loanProduct.holdGuaranteeFunds" fxLayout="row wrap" fxLayout.lt-md="column">
    <span fxFlex="40%">{{"labels.inputs.mandatory.guarantee" | translate }}</span>
    <span fxFlex="60%">{{ loanProduct.mandatoryGuarantee }}</span>
    <div fxFlexFill *ngIf="loanProduct.minimumGuaranteeFromOwnFunds">
      <span fxFlex="40%">{{"labels.inputs.minimum.guarantee.from.own.funds" | translate }}</span>
      <span fxFlex="60%">{{ loanProduct.minimumGuaranteeFromOwnFunds }}</span>
    </div>
    <div fxFlexFill *ngIf="loanProduct.minimumGuaranteeFromGuarantor">
      <span fxFlex="40%">{{"labels.inputs.minimum.guarantee.from.guarantor" | translate }}</span>
      <span fxFlex="60%">{{ loanProduct.minimumGuaranteeFromGuarantor }}</span>
    </div>
  </div>

  <h3 class="mat-h3" fxFlexFill>{{"labels.loanTrancheDetails" | translate }}</h3>

  <mat-divider fxFlexFill></mat-divider>

  <div fxFlexFill>
    <span fxFlex="40%">{{"labels.inputs.multidisburseloan" | translate }}:</span>
    <span fxFlex="60%">{{ loanProduct.multiDisburseLoan ? 'Yes' : 'No' }}</span>
  </div>

  <div fxFlexFill *ngIf="loanProduct.multiDisburseLoan" fxLayout="row wrap" fxLayout.lt-md="column">
    <span fxFlex="40%">{{"labels.inputs.maxtranchecount" | translate }}:</span>
    <span fxFlex="60%">{{ loanProduct.maxTrancheCount }}</span>
    <div fxFlexFill *ngIf="loanProduct.outstandingLoanBalance">
      <span fxFlex="40%">{{"labels.inputs.outstandingloanbalance" | translate }}:</span>
      <span fxFlex="60%">{{ loanProduct.outstandingLoanBalance }}</span>
    </div>
  </div>

  <div fxFlexFill>
    <span fxFlex="40%">{{"labels.disallowExpectedDisbursements" | translate }}:</span>
    <span fxFlex="60%">{{ loanProduct.disallowExpectedDisbursements ? 'Yes' : 'No' }}</span>
  </div>

  <h3 class="mat-h3" fxFlexFill>{{"labels.heading.configurableAttributes" | translate }}:</h3>

  <mat-divider fxFlexFill></mat-divider>

  <div fxFlexFill>
    <span fxFlex="40%">{{"labels.inputs.isConfigLoanAttributes" | translate }}:</span>
    <span fxFlex="60%">{{ loanProduct.allowAttributeConfiguration ? 'Yes' : 'No' }}</span>
  </div>

  <div fxFlexFill *ngIf="loanProduct.allowAttributeConfiguration" fxLayout="row wrap" fxLayout.lt-md="column">
    <span fxFlex="40%">{{"labels.inputs.amortization" | translate }}:</span>
    <span fxFlex="60%">{{ loanProduct.allowAttributeOverrides.amortizationType ? 'Yes' : 'No' }}</span>
    <span fxFlex="40%">{{"labels.heading.interestmethod" | translate }}:</span>
    <span fxFlex="60%">{{ loanProduct.allowAttributeOverrides.interestType ? 'Yes' : 'No' }}</span>
    <span fxFlex="40%">{{"labels.heading.repaymentstrategy" | translate }}:</span>
    <span fxFlex="60%">{{ loanProduct.allowAttributeOverrides.transactionProcessingStrategyId ? 'Yes' : 'No' }}</span>
    <span fxFlex="40%">{{"labels.heading.interestcalculationperiod" | translate }}:</span>
    <span fxFlex="60%">{{ loanProduct.allowAttributeOverrides.interestCalculationPeriodType ? 'Yes' : 'No' }}</span>
    <span fxFlex="40%">{{"labels.heading.arearstolerance" | translate }}:</span>
    <span fxFlex="60%">{{ loanProduct.allowAttributeOverrides.inArrearsTolerance ? 'Yes' : 'No' }}</span>
    <span fxFlex="40%">{{"labels.inputs.repaidevery" | translate }}:</span>
    <span fxFlex="60%">{{ loanProduct.allowAttributeOverrides.repaymentEvery ? 'Yes' : 'No' }}</span>
    <span fxFlex="40%">{{"labels.inputs.grace" | translate }}:</span>
    <span fxFlex="60%">{{ loanProduct.allowAttributeOverrides.graceOnPrincipalAndInterestPayment ? 'Yes' : 'No' }}</span>
    <span fxFlex="40%">{{"labels.inputs.no.of.overdue.days.to.move.loan.into.arrears" | translate }}:</span>
    <span fxFlex="60%">{{ loanProduct.allowAttributeOverrides.graceOnArrearsAgeing ? 'Yes' : 'No' }}</span>
  </div>

  <div fxFlexFill *ngIf="loanProduct.charges.length" fxLayout="row wrap" fxLayout.lt-md="column">

    <h3 class="mat-h3" fxFlexFill>{{"labels.anchors.charges" | translate}}</h3>

    <mat-divider fxFlexFill></mat-divider>

    <table fxFlexFill class="mat-elevation-z1" mat-table [dataSource]="loanProduct.charges | chargesPenaltyFilter:false">

      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef>{{"labels.inputs.name" | translate}}</th>
        <td mat-cell *matCellDef="let charge">
          {{ charge.name + ', ' + charge.currency.displaySymbol }}
        </td>
      </ng-container>

      <ng-container matColumnDef="chargeCalculationType">
        <th mat-header-cell *matHeaderCellDef>{{"labels.inputs.type" | translate}}</th>
        <td mat-cell *matCellDef="let charge">
          {{ charge.chargeCalculationType.value }}
        </td>
      </ng-container>

      <ng-container matColumnDef="amount">
        <th mat-header-cell *matHeaderCellDef>{{"labels.heading.amount" | translate}}</th>
        <td mat-cell *matCellDef="let charge">
          {{ charge.amount  }}
        </td>
      </ng-container>

      <ng-container matColumnDef="chargeTimeType">
        <th mat-header-cell *matHeaderCellDef>{{"labels.heading.collectedon" | translate}}</th>
        <td mat-cell *matCellDef="let charge">
          {{ charge.chargeTimeType.value }}
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="chargesDisplayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: chargesDisplayedColumns;"></tr>

    </table>

    <h3 class="mat-h3" fxFlexFill>{{"labels.overdueCharges" | translate }}</h3>

    <mat-divider fxFlexFill></mat-divider>

    <table fxFlexFill class="mat-elevation-z1" mat-table [dataSource]="loanProduct.charges | chargesPenaltyFilter:true">

      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef>{{"labels.inputs.name" | translate}}</th>
        <td mat-cell *matCellDef="let overdueCharge">
          {{ overdueCharge.name + ', ' + overdueCharge.currency.displaySymbol }}
        </td>
      </ng-container>

      <ng-container matColumnDef="chargeCalculationType">
        <th mat-header-cell *matHeaderCellDef>{{"labels.inputs.type" | translate}}</th>
        <td mat-cell *matCellDef="let overdueCharge">
          {{ overdueCharge.chargeCalculationType.value }}
        </td>
      </ng-container>

      <ng-container matColumnDef="amount">
        <th mat-header-cell *matHeaderCellDef>{{"labels.heading.amount" | translate}}</th>
        <td mat-cell *matCellDef="let overdueCharge">
          {{ overdueCharge.amount  }}
        </td>
      </ng-container>

      <ng-container matColumnDef="chargeTimeType">
        <th mat-header-cell *matHeaderCellDef>{{"labels.heading.collectedon" | translate}}</th>
        <td mat-cell *matCellDef="let overdueCharge">
          {{ overdueCharge.chargeTimeType.value }}
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="chargesDisplayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: chargesDisplayedColumns;"></tr>

    </table>

  </div>

  <h3 class="mat-h3" fxFlexFill>{{"labels.menus.Accounting" | translate}}</h3>

  <mat-divider fxFlexFill></mat-divider>

  <div fxFlexFill>
    <span fxFlex="40%">{{"labels.inputs.type" | translate}}:</span>
    <span fxFlex="60%">{{ accountingRuleData[loanProduct.accountingRule-1] }}</span>
  </div>

  <div fxFlexFill *ngIf="loanProduct.accountingRule >= 2 && loanProduct.accountingRule <= 4" fxLayout="row wrap" fxLayout.lt-md="column">

    <h4 fxFlexFill class="mat-h4">{{"labels.assets" | translate }} / {{"labels.heading.liabilities" | translate }}</h4>

    <div fxFlexFill fxLayout="row wrap" fxLayout.lt-md="column">
      <span fxFlex="40%">{{"labels.heading.fundsource" | translate }} </span>
      <span fxFlex="60%">{{ loanProduct.fundSourceAccountId | find:loanProductsTemplate.accountingMappingOptions.assetAndLiabilityAccountOptions:'id':'name' }}</span>
    </div>

    <h4 fxFlexFill class="mat-h4">{{"labels.assets" | translate}}</h4>

    <div fxFlexFill fxLayout="row wrap" fxLayout.lt-md="column">
      <span fxFlex="40%">{{"labels.heading.loanportfolio" | translate }} </span>
      <span fxFlex="60%">{{ loanProduct.loanPortfolioAccountId | find:loanProductsTemplate.accountingMappingOptions.assetAccountOptions:'id':'name' }}</span>
      <div fxFlexFill *ngIf="loanProduct.accountingRule === 3 || loanProduct.accountingRule === 4" fxLayout="row wrap" fxLayout.lt-md="column">
        <span fxFlex="40%">{{"labels.inputs.receivableinterest" | translate }} </span>
        <span fxFlex="60%">{{ loanProduct.receivableInterestAccountId | find:loanProductsTemplate.accountingMappingOptions.assetAccountOptions:'id':'name' }}</span>
        <span fxFlex="40%">{{"labels.inputs.receivablefees" | translate }} </span>
        <span fxFlex="60%">{{ loanProduct.receivableFeeAccountId | find:loanProductsTemplate.accountingMappingOptions.assetAccountOptions:'id':'name' }}</span>
        <span fxFlex="40%">{{"labels.inputs.receivablepenalties" | translate }} </span>
        <span fxFlex="60%">{{ loanProduct.receivablePenaltyAccountId | find:loanProductsTemplate.accountingMappingOptions.assetAccountOptions:'id':'name' }}</span>
      </div>
      <span fxFlex="40%">{{"labels.inputs.transfersinsuspense" | translate }} </span>
      <span fxFlex="60%">{{ loanProduct.transfersInSuspenseAccountId | find:loanProductsTemplate.accountingMappingOptions.assetAccountOptions:'id':'name' }}</span>
    </div>

    <h4 fxFlexFill class="mat-h4">{{"labels.heading.income" | translate}} </h4>

    <div fxFlexFill fxLayout="row wrap" fxLayout.lt-md="column">
      <span fxFlex="40%">{{"labels.heading.incomefrominterest" | translate }} </span>
      <span fxFlex="60%">{{ loanProduct.interestOnLoanAccountId | find:loanProductsTemplate.accountingMappingOptions.incomeAccountOptions:'id':'name' }}</span>
      <span fxFlex="40%">{{"labels.heading.incomefromfees" | translate}}:</span>
      <span fxFlex="60%">{{ loanProduct.incomeFromFeeAccountId | find:loanProductsTemplate.accountingMappingOptions.incomeAccountOptions:'id':'name' }}</span>
      <span fxFlex="40%">{{"labels.heading.incomefrompenalties" | translate}}:</span>
      <span fxFlex="60%">{{ loanProduct.incomeFromPenaltyAccountId | find:loanProductsTemplate.accountingMappingOptions.incomeAccountOptions:'id':'name' }}</span>
      <span fxFlex="40%">{{"labels.heading.recoverypayments" | translate }} </span>
      <span fxFlex="60%">{{ loanProduct.incomeFromRecoveryAccountId | find:loanProductsTemplate.accountingMappingOptions.incomeAccountOptions:'id':'name' }}</span>
    </div>

    <h4 fxFlexFill class="mat-h4">{{"labels.heading.expenses" | translate}} </h4>

    <div fxFlexFill fxLayout="row wrap" fxLayout.lt-md="column">
      <span fxFlex="40%">{{"labels.heading.loseswrittenoff" | translate }} </span>
      <span fxFlex="60%">{{ loanProduct.writeOffAccountId | find:loanProductsTemplate.accountingMappingOptions.expenseAccountOptions:'id':'name' }}</span>
      <span fxFlex="40%">{{"labels.goodwillCredit" | translate }} </span>
      <span fxFlex="60%">{{ loanProduct.goodwillCreditAccountId | find:loanProductsTemplate.accountingMappingOptions.expenseAccountOptions:'id':'name' }}</span>
    </div>

    <h4 fxFlexFill class="mat-h4">{{"labels.heading.liabilities" | translate}} </h4>

    <div fxFlexFill>
      <span fxFlex="40%">{{"labels.inputs.overpaymentliability" | translate }} </span>
      <span fxFlex="60%">{{ loanProduct.overpaymentLiabilityAccountId | find:loanProductsTemplate.accountingMappingOptions.liabilityAccountOptions:'id':'name' }}</span>
    </div>

    <div *ngIf="loanProduct.advancedAccountingRules && (loanProduct.paymentChannelToFundSourceMappings?.length || loanProduct.feeToIncomeAccountMappings?.length || loanProduct.penaltyToIncomeAccountMappings?.length)" fxFlexFill fxLayout="row wrap" fxLayout.lt-md="column">

      <h3 class="mat-h3" fxFlexFill>{{"labels.heading.advancedaccountingrules" | translate}} </h3>

      <mat-divider fxFlexFill></mat-divider>

      <div *ngIf="loanProduct.paymentChannelToFundSourceMappings?.length" fxFlexFill fxLayout="row wrap" fxLayout.lt-md="column">

        <h4 class="mat-h4" fxFlexFill>{{"labels.fundsourcesforpaymentchannels" | translate}}:</h4>

        <table fxFlexFill class="mat-elevation-z1" mat-table [dataSource]="loanProduct.paymentChannelToFundSourceMappings">

          <ng-container matColumnDef="paymentTypeId">
            <th mat-header-cell *matHeaderCellDef>{{"labels.heading.paymenttype" | translate}} </th>
            <td mat-cell *matCellDef="let paymentFundSource">
              {{ paymentFundSource.paymentTypeId | find:loanProductsTemplate.paymentTypeOptions:'id':'name' }}
            </td>
          </ng-container>

          <ng-container matColumnDef="fundSourceAccountId">
            <th mat-header-cell *matHeaderCellDef>{{"labels.heading.fundsource" | translate}} </th>
            <td mat-cell *matCellDef="let paymentFundSource">
              {{ paymentFundSource.fundSourceAccountId | find:loanProductsTemplate.accountingMappingOptions.assetAccountOptions:'id':'name' }}
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="paymentFundSourceDisplayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: paymentFundSourceDisplayedColumns;"></tr>

        </table>

      </div>

      <div *ngIf="loanProduct.feeToIncomeAccountMappings?.length" fxFlexFill fxLayout="row wrap" fxLayout.lt-md="column">

        <h4 class="mat-h4" fxFlexFill>{{"labels.mapFeesSpecificIncomeAccounts" | translate}}:</h4>

        <table fxFlexFill class="mat-elevation-z1" mat-table [dataSource]="loanProduct.feeToIncomeAccountMappings">

          <ng-container matColumnDef="chargeId">
            <th mat-header-cell *matHeaderCellDef>{{"labels.commons.fees" | translate}}:</th>
            <td mat-cell *matCellDef="let feesIncome">
              {{ feesIncome.chargeId | find:loanProductsTemplate.chargeOptions:'id':'name' }}
            </td>
          </ng-container>

          <ng-container matColumnDef="incomeAccountId">
            <th mat-header-cell *matHeaderCellDef>{{"labels.heading.incomeaccount" | translate}} </th>
            <td mat-cell *matCellDef="let feesIncome">
              {{ feesIncome.incomeAccountId | find:loanProductsTemplate.accountingMappingOptions.incomeAccountOptions.concat(loanProductsTemplate.accountingMappingOptions.liabilityAccountOptions):'id':'name' }}
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="feesPenaltyIncomeDisplayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: feesPenaltyIncomeDisplayedColumns;"></tr>

        </table>

      </div>

      <div *ngIf="loanProduct.penaltyToIncomeAccountMappings?.length" fxFlexFill fxLayout="row wrap" fxLayout.lt-md="column">

        <h4 class="mat-h4" fxFlexFill>{{"labels.heading.mappenaltiestospecificincomeaccounts" | translate}}:</h4>

        <table fxFlexFill class="mat-elevation-z1" mat-table [dataSource]="loanProduct.penaltyToIncomeAccountMappings">

          <ng-container matColumnDef="chargeId">
            <th mat-header-cell *matHeaderCellDef>{{"labels.commons.penalty" | translate}} </th>
            <td mat-cell *matCellDef="let penaltyIncome">
              {{ penaltyIncome.chargeId  | find:loanProductsTemplate.penaltyOptions:'id':'name' }}
            </td>
          </ng-container>

          <ng-container matColumnDef="incomeAccountId">
            <th mat-header-cell *matHeaderCellDef>{{"labels.heading.incomeaccount" | translate}} </th>
            <td mat-cell *matCellDef="let penaltyIncome">
              {{ penaltyIncome.incomeAccountId | find:loanProductsTemplate.accountingMappingOptions.incomeAccountOptions:'id':'name' }}
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="feesPenaltyIncomeDisplayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: feesPenaltyIncomeDisplayedColumns;"></tr>

        </table>

      </div>

    </div>

  </div>

</div>

<div fxLayout="row" class="margin-t" fxLayout.xs="column" fxLayoutAlign="center" fxLayoutGap="2%">
  <button mat-raised-button matStepperPrevious>
    <fa-icon icon="arrow-left"></fa-icon>&nbsp;&nbsp;
    {{"labels.heading.prev" | translate}}
  </button>
  <button mat-raised-button [routerLink]="['../']">
    {{"labels.buttons.cancel" | translate}}
  </button>
  <button mat-raised-button color="primary" (click)="submit.emit()">
    {{"labels.buttons.submit" | translate}}
  </button>
</div>
