<mifosx-web-views-top-bar title="labels.heading.repaymentschedule"></mifosx-web-views-top-bar>
<div class="container">
  <div class="content">
    <mat-card>
      <div fxFlexFill>
        <span fxFlex="50%">
          {{"labels.inputs.accno" | translate}} :
        </span>
        <span fxFlex="50%">
          {{loanDetailsData?.accountNo}}
        </span>
      </div>
      <div fxFlexFill>
        <span fxFlex="50%">
          {{"labels.heading.disbursementdate" | translate}} :
        </span>
        <span fxFlex="50%">
          {{loanDetailsData?.timeline?.expectedDisbursementDate | dateFormat}}
        </span>
      </div>
      <div fxFlexFill>
        <span fxFlex="50%">
          {{"labels.heading.numofrepayments" | translate}} :
        </span>
        <span fxFlex="50%">
          {{loanDetailsData?.numberOfRepayments}}
        </span>
      </div>
    </mat-card>




    <table mat-table [dataSource]="loanDetailsData.repaymentSchedule.periods">

      <ng-container matColumnDef="number">
        <th mat-header-cell class="center" *matHeaderCellDef> # </th>
        <td mat-cell class="right" *matCellDef="let ele"> {{ ele.period }} </td>
        <td mat-footer-cell *matFooterCellDef> &nbsp; </td>
      </ng-container>


      <ng-container matColumnDef="date">
        <th mat-header-cell class="center" *matHeaderCellDef> {{'labels.heading.date' | translate}} </th>
        <td mat-cell class="center" *matCellDef="let ele"> {{ ele.dueDate | dateFormat}} </td>
        <td mat-footer-cell *matFooterCellDef> </td>
      </ng-container>


      <ng-container matColumnDef="principalDue">
        <th mat-header-cell class="r-amount" *matHeaderCellDef> {{'labels.commons.principaldue' | translate}} </th>
        <td mat-cell class="check r-amount" *matCellDef="let ele"> {{ ele.principalOriginalDue | number }}
          {{loanDetailsData?.currency?.displaySymbol}}</td>
        <td mat-footer-cell *matFooterCellDef> </td>

      </ng-container>

      <ng-container matColumnDef="balanceOfLoan">
        <th mat-header-cell class="r-amount" *matHeaderCellDef> {{'labels.heading.balanceofloan' | translate}} </th>
        <td mat-cell class="r-amount" *matCellDef="let ele"> {{ ele.principalLoanBalanceOutstanding | number }}
          {{loanDetailsData?.currency?.displaySymbol}}</td>
        <td mat-footer-cell class="r-amount" *matFooterCellDef> &nbsp; </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      <tr mat-footer-row *matFooterRowDef="displayedColumns"></tr>

    </table>
  </div>
  <mifosx-web-views-logo></mifosx-web-views-logo>
</div>
