<div fxLayout="row" fxLayoutAlign="end" class="container m-b-20">
  <div #buttonDefineMapping>
    <button mat-raised-button color="primary" [routerLink]="['create']" *mifosxHasPermission="'CREATE_FINANCIALACTIVITYACCOUNT'">
      <fa-icon icon="plus"></fa-icon>&nbsp;&nbsp;{{"labels.buttons.define.new.financial.mapping" | translate}}
    </button>
  </div>
</div>

<div #activitiesTable class="mat-elevation-z8 container">

  <table mat-table [dataSource]="dataSource" matSort>

    <ng-container matColumnDef="financialActivity">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> {{"labels.heading.financialactivity" | translate}} </th>
      <td mat-cell *matCellDef="let financialActivityAccount"> {{ financialActivityAccount.financialActivityData.name }} </td>
    </ng-container>

    <ng-container matColumnDef="glAccountName">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> {{"labels.inputs.accountname" | translate}} </th>
      <td mat-cell *matCellDef="let financialActivityAccount"> {{ financialActivityAccount.glAccountData.name }} </td>
    </ng-container>

    <ng-container matColumnDef="glAccountCode">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> {{"labels.accountCode" | translate}} </th>
      <td mat-cell *matCellDef="let financialActivityAccount"> {{ financialActivityAccount.glAccountData.glCode }} </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;" [routerLink]="['view', row.id]" class="select-row"></tr>

  </table>

  <mat-paginator [pageSizeOptions]="[10, 25]" showFirstLastButtons></mat-paginator>

</div>

<ng-template #templateButtonDefineMapping let-popover="popover">
  <h2>{{"labels.buttons.define.new.financial.mapping" | translate}}</h2>
  <p class="mw300">{{"labels.defineMapping" | translate}}.</p>
  <div fxLayout="row" fxLayoutAlign="end" fxLayoutGap="2%" fxLayout.lt-md="column">
    <button mat-raised-button color="warn" (click)="popover.close();configurationWizardService.closeConfigWizard()">{{"labels.buttons.close" | translate}}</button>
    <button mat-raised-button color="primary" (click)="popover.close();previousStep()">{{"labels.buttons.back" | translate}}</button>
    <button mat-raised-button color="primary" (click)="popover.close();showPopover(templateActivitiesTable, activitiesTable, 'top', true)">{{"labels.buttons.next" | translate}}</button>
  </div>
</ng-template>

<ng-template #templateActivitiesTable let-popover="popover">
  <h4 class="mw300">{{"labels.listAccountsLinkedDifferentFinancialActivities" | translate}}. {{"labels.toKnowMoreClick" | translate }} <a href="" target="_blank">Accounts linked to Financial Activities</a> </h4>
  <div fxLayout="row" fxLayoutAlign="end" fxLayoutGap="2%" fxLayout.lt-md="column">
    <button mat-raised-button color="warn" (click)="popover.close();configurationWizardService.closeConfigWizard()">{{"labels.buttons.close" | translate}}</button>
    <button mat-raised-button color="primary" (click)="popover.close();showPopover(templateButtonDefineMapping, buttonDefineMapping , 'bottom', true)">{{"labels.buttons.back" | translate}}</button>
    <button mat-raised-button color="primary" (click)="popover.close();nextStep()">{{"labels.buttons.next" | translate}}</button>
  </div>
</ng-template>
