<div class="tab-container mat-typography" *ngIf="accounts.length > 0">

    <div fxLayout="row" fxLayoutAlign="start center">
      <div class="search-box" fxFlex="18%">
        <mat-form-field fxFlex="100%" appearance="fill">
          <input matInput [max]="endDateFilter.value" [matDatepicker]="submitPicker"
            [formControl]="startDateFilter" (dateChange)="applyFilters()" (keyup)="applyFilters()"
            [placeholder]="'labels.heading.from'| translate">
          <mat-datepicker-toggle matSuffix [for]="submitPicker"></mat-datepicker-toggle>
          <mat-datepicker #submitPicker></mat-datepicker>
        </mat-form-field>
      </div>
      <div class="search-box" fxFlex="18%">
        <mat-form-field fxFlex="100%" appearance="fill">
          <input matInput [min]="startDateFilter.value" [matDatepicker]="submitPicker2"
            [formControl]="endDateFilter" (dateChange)="applyFilters()" (keyup)="applyFilters()"
            [placeholder]="'labels.heading.to'| translate">
          <mat-datepicker-toggle matSuffix [for]="submitPicker2"><button>{{"labels.buttons.clear" | translate}}</button></mat-datepicker-toggle>
          <mat-datepicker #submitPicker2><button>{{"labels.buttons.clear" | translate}}</button></mat-datepicker>
        </mat-form-field>
      </div>
      <div fxFlex="59%">
        <div class="export">
          <button mat-raised-button color="success" *mifosxHasPermission="'APPROVE_SAVINGSACCOUNT'" (click)="approveAccount()">
            <fa-icon icon="check"></fa-icon>&nbsp;&nbsp;{{"labels.anchors.approve" | translate}}
          </button>
          <button mat-raised-button color="success" (click)="exportexcel()">
            <fa-icon icon="file-excel"></fa-icon>&nbsp;&nbsp;{{'labels.buttons.exportexcel' | translate}}
          </button>
        </div>
      </div>
    </div>

    <table mat-table [dataSource]="dataSource">


      <ng-container matColumnDef="select">
        <th mat-header-cell *matHeaderCellDef>
          <mat-checkbox (change)="$event ? masterToggle() : null" [checked]="selection.hasValue() && isAllSelected()"
            [indeterminate]="selection.hasValue() && !isAllSelected()" [aria-label]="checkboxLabel()">
          </mat-checkbox>
        </th>
        <td mat-cell *matCellDef="let row">
          <mat-checkbox (click)="$event.stopPropagation()" (change)="$event ? selection.toggle(row) : null"
            [checked]="selection.isSelected(row)" [aria-label]="checkboxLabel(row)">
          </mat-checkbox>
        </td>
      </ng-container>

      <ng-container matColumnDef="client">
        <th mat-header-cell *matHeaderCellDef> {{"labels.inputs.clientName" | translate}}
          <mat-form-field appearance="fill">
            <input matInput [formControl]="clientFilter" (keyup)="applyFilters()" [placeholder]="'labels.buttons.search'| translate">
          </mat-form-field>
        </th>
        <td mat-cell *matCellDef="let savings" class="view-details" [routerLink]="['/clients', savings.clientId]"> {{savings.clientName}} </td>
      </ng-container>

      <ng-container matColumnDef="savingsAccountNumber">
        <th mat-header-cell *matHeaderCellDef> {{"labels.heading.savingsaccountno" | translate}}
          <mat-form-field appearance="fill">
            <input matInput [formControl]="accountNumber" (keyup)="applyFilters()" [placeholder]="'labels.buttons.search'| translate">
          </mat-form-field>
        </th>
        <td mat-cell *matCellDef="let savings" class="view-details" [routerLink]="['/clients', savings.clientId, 'savings-accounts', savings.id]"> {{savings.accountNo}} </td>
      </ng-container>

      <ng-container matColumnDef="type">
        <th mat-header-cell *matHeaderCellDef> {{"labels.productType" | translate}}
          <mat-form-field appearance="fill">
            <mat-select [formControl]="productFilter" (selectionChange)="applyFilters()" [placeholder]="'labels.buttons.search'| translate">
              <mat-option *ngFor="let product of products" [value]="product">
                {{ product }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </th>
        <td mat-cell *matCellDef="let savings" class="view-details" [routerLink]="['../../clients', savings.clientId, 'general']"> {{savings.savingsProductName}} </td>
      </ng-container>

      <ng-container matColumnDef="balance">
        <th mat-header-cell *matHeaderCellDef> {{"labels.heading.balance" | translate}}
          <mat-form-field appearance="fill">
            <input matInput [formControl]="balance" (keyup)="applyFilters()" [placeholder]="'labels.buttons.search'| translate">
          </mat-form-field>
        </th>
        <td mat-cell *matCellDef="let savings" class="view-details" [routerLink]="['../../clients', savings.clientId, 'savings-accounts', savings.id]"> {{savings.summary.accountBalance}} </td>
      </ng-container>

      <ng-container matColumnDef="submittedOn">
        <th mat-header-cell *matHeaderCellDef> {{"labels.inputs.submittedOnDate" | translate}}
          <mat-form-field style="width: 100%;" appearance="fill">
            <input matInput [max]="maxDate" [matDatepicker]="submitPicker"
            [formControl]="submittedOnFilter" (dateChange)="applyFilters()" (keyup)="applyFilters()">
            <mat-datepicker-toggle matSuffix [for]="submitPicker"></mat-datepicker-toggle>
            <mat-datepicker #submitPicker></mat-datepicker>
          </mat-form-field>
        </th>
        <td mat-cell *matCellDef="let savings" class="view-details" [routerLink]="['../../clients', savings.clientId, 'general']"> {{savings.timeline.submittedOnDate  | dateFormat}}</td>
      </ng-container>

      <ng-container matColumnDef="office">
        <th mat-header-cell *matHeaderCellDef> {{"labels.menus.office" | translate}}
          <mat-form-field appearance="fill">
            <mat-select [formControl]="officeFilter" (selectionChange)="applyFilters()" [placeholder]="'labels.buttons.search'| translate">
              <mat-option *ngFor="let office of offices" [value]="office">
                {{ office }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </th>
        <td mat-cell *matCellDef="let savings" class="view-details" [routerLink]="['../../clients', savings.clientId, 'savings-accounts', savings.id]"> {{savings.officeName}} </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="selection.toggle(row)">
      </tr>
    </table>

  </div>

  <div class="alert" *ngIf="accounts.length === 0">

    <div class="message">
      <i class="fa fa-exclamation-circle alert-check"></i>
      {{"labels.noPendingAccountAvailableApproval" | translate}}.
    </div>

  </div>
