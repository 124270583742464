<mifosx-web-views-top-bar title="labels.buttons.makerepayment"></mifosx-web-views-top-bar>

<div class="container" *ngIf="dataObject">
  <div class="content">

    <form [formGroup]="repaymentLoanForm" (ngSubmit)="submit()">

      <div>

        <div fxLayout="column">

          <div class="input-container">
            <label>{{'labels.inputs.transactionamount' | translate}}</label>
            <input  [min]="0" [max]="totalOutstanding" decimalInput required formControlName="transactionAmount">
            <mat-error *ngIf="repaymentLoanForm.controls.transactionAmount.hasError('required')">{{'labels.inputs.transactionamount' | translate}}<strong>{{ 'labels.commons.is required'| translate }}</strong>
            </mat-error>
            <mat-error *ngIf="repaymentLoanForm.controls.transactionAmount.hasError('max')"> {{ 'labels.inputs.transactionamount'|
              translate }} <strong>{{"labels.mustBeLessThan" | translate}}</strong>  {{totalOutstanding}}
            </mat-error>
            <mat-error *ngIf="repaymentLoanForm.controls.transactionAmount.hasError('min')"> {{ 'labels.inputs.transactionamount'|
              translate }} <strong>{{"labels.mustBeGreaterThan" | translate}}</strong>  0
            </mat-error>
          </div>

          <div class="input-container">
            <label>{{ 'labels.inputs.note'| translate }}</label>
            <textarea  formControlName="note"></textarea>
          </div>

        </div>

        <mat-card-actions fxLayout="row" fxLayoutAlign="center" fxLayoutGap="5px">
          <button type="button" mat-raised-button [routerLink]="['../../']">{{"labels.buttons.cancel" | translate}}</button>
          <button mat-raised-button color="primary" [disabled]="!repaymentLoanForm.valid">{{"labels.buttons.submit" | translate}}</button>
        </mat-card-actions>

      </div>

    </form>

  </div>
  <mifosx-web-views-logo></mifosx-web-views-logo>

</div>
<mifosx-web-view-loading *ngIf="!dataObject"></mifosx-web-view-loading>
