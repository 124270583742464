<div class="container">

  <mat-card>

    <form [formGroup]="transferClientForm" (ngSubmit)="submit()">

      <mat-card-content>

        <div fxLayout="column">

          <mat-form-field>
            <mat-label>{{ 'labels.inputs.office' | translate }}</mat-label>
            <mat-select required formControlName="destinationOfficeId">
              <mat-option *ngFor="let office of officeData" [value]="office.id">
                {{ office.name }}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="transferClientForm.controls.destinationOfficeId.hasError('required')">
              {{ 'labels.inputs.office' | translate }} <strong>{{ 'labels.commons.is required' | translate }}</strong>
            </mat-error>
          </mat-form-field>

          <mat-form-field (click)="transferDatePicker.open()">
            <mat-label>{{ 'labels.inputs.transfer.date' | translate }}</mat-label>
            <input matInput [min]="minDate" [max]="maxDate" [matDatepicker]="transferDatePicker" required formControlName="transferDate">
            <mat-datepicker-toggle matSuffix [for]="transferDatePicker"></mat-datepicker-toggle>
            <mat-datepicker #transferDatePicker></mat-datepicker>
            <mat-error *ngIf="transferClientForm.controls.transferDate.hasError('required')">
              {{ 'labels.inputs.transfer.date' | translate }} <strong>{{ 'labels.commons.is required' | translate }}</strong>
            </mat-error>
          </mat-form-field>

          <mat-form-field>
            <mat-label>{{ 'labels.inputs.note' | translate }}</mat-label>
            <textarea matInput formControlName="note"></textarea>
          </mat-form-field>

        </div>

      </mat-card-content>

      <mat-card-actions fxLayout="row" fxLayout.xs="column" fxLayoutAlign="center" fxLayoutGap="5px">
        <button type="button" mat-raised-button [routerLink]="['../../']">{{ 'labels.buttons.cancel' | translate }}</button>
        <button mat-raised-button color="primary" [disabled]="!transferClientForm.valid">{{ 'labels.buttons.confirm' | translate }}</button>
      </mat-card-actions>

    </form>

  </mat-card>

</div>
