<form [formGroup]="fixedDepositProductSettingsForm">

  <div fxLayout="row wrap" fxLayoutGap="2%" fxLayout.lt-md="column">

    <h4 fxFlex="98%" class="mat-h4">
    {{"labels.inputs.lockinPeriodFrequency" | translate}}
  </h4>

    <mat-form-field fxFlex="48%">
      <mat-label>{{"labels.Frequency" | translate}}</mat-label>
      <input type="number" matInput formControlName="lockinPeriodFrequency">
    </mat-form-field>

    <mat-form-field fxFlex="48%">
      <mat-label>{{"labels.inputs.type" | translate}}</mat-label>
      <mat-select formControlName="lockinPeriodFrequencyType">
        <mat-option *ngFor="let lockinPeriodFrequencyType of lockinPeriodFrequencyTypeData" [value]="lockinPeriodFrequencyType.id">
          {{ lockinPeriodFrequencyType.value }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <h4 fxFlex="98%" class="mat-h4">>{{"labels.inputs.minimumdepositterm" | translate}}<</h4>

    <mat-form-field fxFlex="48%">
      <mat-label>{{"labels.Frequency" | translate}}</mat-label>
      <input type="number" matInput formControlName="minDepositTerm" required>
      <mat-error>
        {{"labels.minimumDepositTermFrequency" | translate}} <strong>{{"labels.commons.is required" | translate}}</strong>
      </mat-error>
    </mat-form-field>

    <mat-form-field fxFlex="48%">
      <mat-label>{{"labels.heading.frequencyType" | translate}}</mat-label>
      <mat-select formControlName="minDepositTermTypeId" required>
        <mat-option *ngFor="let periodFrequencyType of periodFrequencyTypeData" [value]="periodFrequencyType.id">
          {{ periodFrequencyType.value }}
        </mat-option>
      </mat-select>
      <mat-error>
        {{"labels.minimumDepositTermFrequencyType" | translate}} <strong>{{"labels.commons.is required" | translate}}</strong>
      </mat-error>
    </mat-form-field>

    <h4 fxFlex="98%" class="mat-h4">{{"labels.inputs.inmultiplesof" | translate}}</h4>

    <mat-form-field fxFlex="48%">
      <mat-label>{{"labels.Frequency" | translate}}</mat-label>
      <input type="number" matInput formControlName="inMultiplesOfDepositTerm">
    </mat-form-field>

    <mat-form-field fxFlex="48%">
      <mat-label>{{"labels.inputs.type" | translate}}</mat-label>
      <mat-select formControlName="inMultiplesOfDepositTermTypeId">
        <mat-option *ngFor="let periodFrequencyType of periodFrequencyTypeData" [value]="periodFrequencyType.id">
          {{ periodFrequencyType.value }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <h4 fxFlex="98%" class="mat-h4">{{"labels.heading.maximumdepositterm" | translate}}</h4>

    <mat-form-field fxFlex="48%">
      <mat-label>{{"labels.Frequency" | translate}}</mat-label>
      <input type="number" matInput formControlName="maxDepositTerm">
    </mat-form-field>

    <mat-form-field fxFlex="48%">
      <mat-label>{{"labels.inputs.type" | translate}}</mat-label>
      <mat-select formControlName="maxDepositTermTypeId">
        <mat-option *ngFor="let periodFrequencyType of periodFrequencyTypeData" [value]="periodFrequencyType.id">
          {{ periodFrequencyType.value }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-divider fxFlex="98%"></mat-divider>

    <h4 fxFlex="23%" class="mat-h4">{{"labels.prematureclosure" | translate}}</h4>

    <mat-checkbox fxFlex="73%" labelPosition="before" formControlName="preClosurePenalApplicable" class="margin-v">
      {{"labels.heading.preclosurepenalapplicable" | translate}}
    </mat-checkbox>

    <mat-form-field fxFlex="48%">
      <mat-label>{{"labels.penalInterest" | translate}} (%)</mat-label>
      <input type="number" matInput formControlName="preClosurePenalInterest">
    </mat-form-field>

    <mat-form-field fxFlex="48%">
      <mat-label>{{"labels.Period" | translate}}</mat-label>
      <mat-select formControlName="preClosurePenalInterestOnTypeId">
        <mat-option *ngFor="let preClosurePenalInterestOnType of preClosurePenalInterestOnTypeData" [value]="preClosurePenalInterestOnType.id">
          {{ preClosurePenalInterestOnType.value }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-divider fxFlex="98%"></mat-divider>

    <mat-checkbox fxFlex="48%" labelPosition="before" formControlName="withHoldTax" class="margin-v">
      {{"labels.inputs.withholdtax" | translate}}?
    </mat-checkbox>

    <mat-form-field fxFlex="48%" *ngIf="fixedDepositProductSettingsForm.value.withHoldTax">
      <mat-label>{{"labels.inputs.taxgroup" | translate}}</mat-label>
      <mat-select formControlName="taxGroupId" required>
        <mat-option *ngFor="let taxGroup of taxGroupData" [value]="taxGroup.id">
          {{ taxGroup.name }}
        </mat-option>
      </mat-select>
      <mat-error>
        {{"labels.inputs.taxgroup" | translate}} <strong>{{"labels.commons.is required" | translate}}</strong>
      </mat-error>
    </mat-form-field>

  </div>

  <div fxLayout="row" class="margin-t" fxLayout.xs="column" fxLayoutAlign="center" fxLayoutGap="2%">
    <button mat-raised-button matStepperPrevious>
      <fa-icon icon="arrow-left"></fa-icon>&nbsp;&nbsp;
      {{"labels.buttons.previous" | translate}}
    </button>
    <button mat-raised-button matStepperNext>
      {{"labels.buttons.next" | translate}}&nbsp;&nbsp;
      <fa-icon icon="arrow-right"></fa-icon>
    </button>
  </div>

</form>
