<div fxLayout="column">

  <h3 class="mat-h3" fxFlexFill>{{'labels.heading.details' | translate }}</h3>

  <mat-divider fxFlexFill></mat-divider>

  <div fxFlexFill>
    <span fxFlex="50%">{{'labels.heading.product' | translate }}:</span>
    <span fxFlex="50%">{{ +loansAccount.productId | find:loansAccountTemplate.productOptions:'id':'name' }}</span>
  </div>


  <div fxFlexFill *ngIf="loansAccount.loanPurposeId">
    <span fxFlex="50%">{{'labels.inputs.loanpurpose' | translate}}:</span>
    <span
      fxFlex="50%">{{ +loansAccount.loanPurposeId | find:loansAccountProductTemplate.loanPurposeOptions:'id':'name'}}</span>
  </div>


  <!-- <div fxFlexFill>
    <span fxFlex="50%">{{'labels.inputs.submittedon' | translate}}:</span>
    <span fxFlex="50%">{{ loansAccount.submittedOnDate  | dateFormat }}</span>
  </div> -->

  <div fxFlexFill>
    <span fxFlex="50%">{{'labels.inputs.disbursementon' | translate}}:</span>
    <span fxFlex="50%">{{ loansAccount.expectedDisbursementDate  | dateFormat }}</span>
  </div>




  <mat-divider fxFlexFill></mat-divider>

  <div fxFlexFill>
    <span fxFlex="50%">{{'labels.heading.principal' | translate}}:</span>
    <span fxFlex="50%">{{ loansAccount.principal }} {{loansAccountProductTemplate.currency.code}}</span>
  </div>

  <div fxFlexFill>
    <span fxFlex="50%">{{'labels.inputs.loanterm' | translate}}:</span>
    <span fxFlex="50%">{{ loansAccount.loanTermFrequency }}
      {{+loansAccount.repaymentFrequencyType | find: loansAccountProductTemplate.termFrequencyTypeOptions:'id':'value'}}</span>
  </div>

  <div fxFlexFill>
    <span fxFlex="50%">{{'labels.heading.numberOfRepayments' | translate}}:</span>
    <span fxFlex="50%">{{ loansAccount.numberOfRepayments }}</span>
  </div>

  <div fxFlexFill>
    <span fxFlex="50%">{{'labels.inputs.repaidevery' | translate}}:</span>
    <span fxFlex="50%">{{loansAccount.repaymentEvery}}
      {{+loansAccount.repaymentFrequencyType | find: loansAccountProductTemplate.termFrequencyTypeOptions:'id':'value'}}</span>
  </div>

  <mat-divider fxFlexFill></mat-divider>

  <div fxFlexFill>
    <span fxFlex="50%">{{"labels.nextDue" | translate}}</span>
    <span fxFlex="50%">{{nextPeriodDetails?.totalDueForPeriod}} {{loansAccountProductTemplate.currency.code}}</span>
  </div>
  <div fxFlexFill>
    <span fxFlex="50%">{{"labels.heading.lastRepayment" | translate}}:</span>
    <span fxFlex="50%">{{nextPeriodDetails?.lastdueDate | dateFormat}}</span>
  </div>


</div>

<div fxLayout="row" class="margin-t" fxLayoutAlign="center" fxLayoutGap="2%">
  <button mat-raised-button matStepperPrevious>
    <fa-icon class="hidden-mobile" icon="arrow-left"></fa-icon>&nbsp;&nbsp;
    {{'labels.buttons.previous' | translate}}</button>
  <button mat-raised-button [routerLink]="['../']">
    {{ 'labels.buttons.cancel'| translate }}
  </button>
  <button mat-raised-button [disabled]="submited" color="primary" (click)="submit.emit()">{{'labels.buttons.submit' | translate}}</button>
</div>
