import { ConfirmationDialogComponent } from 'app/shared/confirmation-dialog/confirmation-dialog.component';
import { MatDialog } from '@angular/material/dialog';
/** Angular Imports */
import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

/** Custom Services. */
import { ClientsService } from 'app/clients/clients.service';
import { Dates } from 'app/core/utils/dates';
import { SettingsService } from 'app/settings/settings.service';

/**
 * Client Pay Charge component.
 */
@Component({
  selector: 'mifosx-client-pay-charges',
  templateUrl: './client-pay-charges.component.html',
  styleUrls: ['./client-pay-charges.component.scss']
})
export class ClientPayChargesComponent implements OnInit {

  /** Transaction Form. */
  transactionForm: any;
  /** Transaction Data. */
  transactionData: any;
  /** Minimum Date allowed. */
  minDate = new Date(2000, 0, 1);

  /**
   * Retrieves the charge data from `resolve`.
   * @param {ClientService} clientService Products Service.
   * @param {FormBuilder} formBuilder Form Builder.
   * @param {ActivatedRoute} route Activated Route.
   * @param {Router} router Router for navigation.
   * @param {SettingsService} settingsService Setting service
   */
  constructor(
    private clientsService: ClientsService,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private dateUtils: Dates,
    private settingsService: SettingsService,
    public dialog: MatDialog,
  ) {
    this.route.data.subscribe((data: { transactionData: any }) => {
      this.transactionData = data.transactionData;
    });
  }

  ngOnInit() {
    this.setTransactionForm();
  }

  /**
   * Set Transaction Form.
   */
  setTransactionForm() {
    this.transactionForm = this.formBuilder.group({
      'amount': [this.transactionData.amount, Validators.required],
      'transactionDate': [new Date(), Validators.required]
    });
  }

  /**
   * Submits Transaction form.
   */
  submit() {
    const unAssignStaffDialogRef = this.dialog.open(ConfirmationDialogComponent, {
      data: { heading: 'labels.buttons.paycharge', dialogContext: 'payChargeConfirmation' }
    });
    unAssignStaffDialogRef.afterClosed().subscribe((response: { confirm: any }) => {
      if (response.confirm) {
        const transactionFormData = this.transactionForm.value;
        const locale = this.settingsService.language.code;
        const dateFormat = 'yyyy-MM-dd';
        const prevTransactionDate = this.transactionForm.value.transactionDate;
        if (transactionFormData.transactionDate instanceof Date) {
          transactionFormData.transactionDate = this.dateUtils.formatDate(prevTransactionDate, dateFormat);
        }
        const data = {
          ...transactionFormData,
          dateFormat,
          locale
        };
        this.clientsService.payClientCharge(this.transactionData.clientId, this.transactionData.id, data).subscribe(() => {
          this.router.navigate(['../../..', 'general'], { relativeTo: this.route });
        });
      }
    });
  }

}
