<div class="container m-b-20" fxLayout="row" fxLayoutAlign="end" fxLayoutGap="20px">

  <button mat-raised-button color="primary" [routerLink]="['edit']" *mifosxHasPermission="'UPDATE_FLOATINGRATE'">
    <fa-icon icon="edit"></fa-icon>&nbsp;&nbsp;
    {{"labels.buttons.edit" | translate}}
  </button>

</div>

<div class="container">

  <mat-card>

    <mat-card-content>

      <div fxLayout="row wrap" class="content" fxLayout.lt-md="column">

        <div fxFlex="18%" class="mat-body-strong">
          {{"labels.inputs.floatingratename" | translate }}:
        </div>

        <div fxFlex="18%">
          {{ floatingRateData.name }}
        </div>

        <div fxFlex="20%" class="mat-body-strong">
          {{"labels.heading.isbaselendingrate" | translate}}
        </div>

        <div fxFlex="10%">
          {{ floatingRateData.isBaseLendingRate ? 'Yes' : 'No' }}
        </div>

        <div fxFlex="14%" class="mat-body-strong">
          {{"labels.inputs.is_active" | translate}}
        </div>

        <div fxFlex="10%">
          {{ floatingRateData.isActive ? 'Yes' : 'No' }}
        </div>

      </div>

      <br>

      <mat-divider [inset]="true"></mat-divider>

      <br>

      <p class="mat-title">{{"labels.heading.rateperiods" | translate }}</p>

      <div class="mat-elevation-z8">

        <table mat-table [dataSource]="dataSource" matSort>

          <ng-container matColumnDef="fromDate">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>{{"labels.heading.fromdate" | translate }}</th>
            <td mat-cell *matCellDef="let floatingRate"> {{ floatingRate.fromDate  | dateFormat : 'yyyy-MM-dd' }} </td>
          </ng-container>

          <ng-container matColumnDef="interestRate">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>{{"labels.heading.interestrate" | translate }}</th>
            <td mat-cell *matCellDef="let floatingRate"> {{ floatingRate.interestRate }} </td>
          </ng-container>

          <ng-container matColumnDef="isDifferential">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>{{"labels.isDifferential" | translate }}</th>
            <td mat-cell *matCellDef="let floatingRate">
              <fa-icon *ngIf="floatingRate.isDifferentialToBaseLendingRate" icon="check-circle" size="lg"
                class="is-differential" [matTooltip]="'labels.yes'| translate" matTooltipPosition="right"></fa-icon>
              <fa-icon *ngIf="!floatingRate.isDifferentialToBaseLendingRate" icon="times-circle" size="lg"
                class="not-is-differential" [matTooltip]="'labels.no'| translate" matTooltipPosition="right"></fa-icon>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

        </table>

        <mat-paginator [pageSizeOptions]="[10, 25, 50, 100]" showFirstLastButtons></mat-paginator>

      </div>

    </mat-card-content>

  </mat-card>

</div>
