<div class="container">

  <mat-card>

    <form [formGroup]="creditBureauForm" (ngSubmit)="submit()">

      <mat-card-content>

        <div fxLayout="column">
          <mat-form-field>
            <mat-label>{{ 'labels.inputs.configkey' | translate }}</mat-label>
            <mat-select required formControlName="creditBureauConfigurationId">
              <mat-option *ngFor="let cbc of cbConfigs" [value]="cbc.creditBureauConfigurationId">
                {{ cbc.configurationKey }}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="creditBureauForm.controls.creditBureauConfigurationId.hasError('required')">
              {{ 'labels.inputs.configkey'  | translate }} <strong>{{ 'labels.commons.is required' | translate }}</strong>
            </mat-error>
          </mat-form-field>
          <mat-form-field>
            <mat-label>{{ 'labels.heading.value' | translate }}</mat-label>
            <input matInput required formControlName="value">
              <mat-error *ngIf="creditBureauForm.controls.value.hasError('required')">
                {{'labels.heading.value' | translate}} <strong>{{"labels.commons.is required" | translate}}</strong>
              </mat-error>
          </mat-form-field>
          <mat-form-field>
            <mat-label>{{ 'labels.heading.description' | translate }}</mat-label>
            <input matInput required formControlName="description">
            <mat-error *ngIf="creditBureauForm.controls.description.hasError('required')">
              {{'labels.heading.description' | translate}} <strong>{{"labels.commons.is required" | translate}}</strong>
            </mat-error>
          </mat-form-field>
        </div>

      </mat-card-content>

      <mat-card-actions fxLayoutGap="5px" fxLayout="row" fsLayout.xs="column" fxLayoutAlign="center">
        <button type="button" mat-raised-button [routerLink]="['../../']">{{ 'labels.buttons.cancel' | translate
          }}</button>
        <button mat-raised-button color="primary" [disabled]="!creditBureauForm.valid">{{ 'labels.buttons.submit' |
          translate }}</button>
      </mat-card-actions>

    </form>

  </mat-card>

</div>
