<form [formGroup]="smsCampaignDetailsForm">

  <div fxLayout="row wrap" fxLayoutGap="2%" fxLayout.lt-md="column" fxLayoutAlign.gt-sm="start center">

    <mat-form-field fxFlex="48%">
      <mat-label>{{"labels.inputs.campaignName" | translate}}</mat-label>
      <input matInput formControlName="campaignName" required>
      <mat-error>
        {{"labels.inputs.campaignName" | translate}} <strong>{{"labels.commons.is required" | translate}}</strong>
      </mat-error>
    </mat-form-field>

    <mat-form-field fxFlex="48%" *ngIf="!smsCampaignDetailsForm.controls.isNotification.value">
      <mat-label>{{"labels.inputs.smsprovider" | translate}}</mat-label>
      <mat-select formControlName="providerId">
        <mat-option *ngFor="let provider of smsProviders" [value]="provider.id">
          {{ provider.value }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field fxFlex="48%">
      <mat-label>{{"labels.heading.triggertype" | translate}}</mat-label>
      <mat-select formControlName="triggerType" required>
        <mat-option *ngFor="let triggerType of triggerTypes" [value]="triggerType.id">
          {{ triggerType.value }}
        </mat-option>
      </mat-select>
      <mat-error>
      {{"labels.heading.triggertype" | translate}} <strong>{{"labels.commons.is required" | translate}}</strong>
      </mat-error>
    </mat-form-field>

    <mat-checkbox labelPosition="before" formControlName="isNotification" fxFlex="48%">
      {{"labels.inputs.isnotification" | translate}}?
    </mat-checkbox>

    <mat-form-field fxFlex="48%" *ngIf="smsCampaignDetailsForm.controls.recurrenceStartDate" (click)="recurrenceStartDatePicker.open()">
      <mat-label>{{"labels.inputs.scheduledate" | translate}}</mat-label>
      <input matInput [min]="minDate" [max]="maxDate" [matDatepicker]="recurrenceStartDatePicker"
        formControlName="recurrenceStartDate" required>
      <mat-datepicker-toggle matSuffix [for]="recurrenceStartDatePicker"></mat-datepicker-toggle>
      <mat-datepicker #recurrenceStartDatePicker></mat-datepicker>
      <mat-error>
        {{"labels.inputs.scheduledate" | translate}} <strong>{{"labels.commons.is required" | translate}}</strong>
      </mat-error>
    </mat-form-field>

    <mat-form-field fxFlex="48%">
      <mat-label>{{"labels.heading.businessRule" | translate}}</mat-label>
      <mat-select formControlName="runReportId" required>
        <mat-option *ngFor="let rule of businessRules" [value]="rule.reportId">
          {{ rule.reportName }}
        </mat-option>
      </mat-select>
      <mat-error>
        {{"labels.heading.businessRule" | translate}} <strong>{{"labels.commons.is required" | translate}}</strong>
      </mat-error>
    </mat-form-field>

  </div>

  <mifosx-edit-business-rule-parameters [paramData]="paramData" [smsCampaign]="smsCampaign"
    (templateParameters)="passParameters($event)">
  </mifosx-edit-business-rule-parameters>

</form>
