import { filter } from 'rxjs/operators';
import { ActivatedRoute } from '@angular/router';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'mifosx-web-view-products',
  templateUrl: './web-view-products.component.html',
  styleUrls: ['./web-view-products.component.scss']
})
export class WebViewProductsComponent implements OnInit {

  loanProducts: any = [];

  constructor(private route: ActivatedRoute) {
    this.route.data.subscribe((data: { loanProducts: any,creditLine:any }) => {
      const creditLine = data.creditLine[0];
      this.loanProducts = data.loanProducts;
      if(creditLine){
        this.loanProducts = this.loanProducts.filter((product:any)=>product.minPrincipal<=creditLine?.remainAmount);
      }
    });
  }

  ngOnInit(): void {
  }

}
