<div class="container">

  <mat-card class="tasks-card">

    <mat-card-content>

      <nav mat-tab-nav-bar class="navigation-tabs">
        <a mat-tab-link [routerLink]="['./to-pay']" routerLinkActive #loanApproval="routerLinkActive"
          [active]="loanApproval.isActive" *mifosxHasPermission="'READ_LOAN'">
          {{"labels.heading.topay" | translate}}
        </a>
        <a mat-tab-link [routerLink]="['./over-due']" routerLinkActive #loanDisbursal="routerLinkActive"
          [active]="loanDisbursal.isActive" *mifosxHasPermission="'READ_LOAN'">
          {{"Overdue" | translate}}
        </a>
      </nav>

      <router-outlet></router-outlet>

    </mat-card-content>

  </mat-card>

</div>
