/** Angular Imports */
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';

/** Custom Services */
import { ProductsService } from '../../products.service';
import { SettingsService } from 'app/settings/settings.service';
import { Dates } from 'app/core/utils/dates';

/**
 * Edit Rate component.
 */
@Component({
  selector: 'mifosx-edit-rate',
  templateUrl: './edit-rate.component.html',
  styleUrls: ['./edit-rate.component.scss']
})
export class EditRateComponent implements OnInit {

  /** Rate form */
  rateForm: FormGroup;
  //Right now only loan is accepted for a rate.
  rateOptions = [{ id: "m_loan" }];
  // rate data
  rate: any = null;
  rateId :any = null;

  /**
   * Retrieves the rate template data
   * @param {FormBuilder} formBuilder Form Builder
   * @param {ProductsService} productsService Products Service.
   * @param {ActivatedRoute} route Activated Route.
   * @param {Router} router Router for navigation.
   * @param {SettingsService} settingsService Settings Service
   */
  constructor(private formBuilder: FormBuilder,
    private productsService: ProductsService,
    private route: ActivatedRoute,
    private router: Router,
    private settingsService: SettingsService) {
    this.route.data.subscribe((data: { rate: any }) => {
      this.rate = data.rate;
    });
    this.rateId = this.route.snapshot.params['id'];
  }




  /**
   * Edit and sets Rate Form
   */
  ngOnInit(): void {
    this.editRateForm();
  }

  /**
   * Edit the Rate Form
   */
  editRateForm() {
    this.rateForm = this.formBuilder.group({
      'productApply': [this.rate?.productApply, Validators.required],
      'name': [this.rate?.name, Validators.required],
      'percentage': [this.rate?.percentage, Validators.required],
      'active': [this.rate?.active],
    });
  }

  /**
   * Submit a new rate Product form
   */
  submit() {
    const {percentage,active,...form} = this.rateForm.value;
    const locale = this.settingsService.language.code;
    const data = {
      percentage,
      active,
      id: this.rateId,
      locale
    };
    this.productsService.editRate(data,this.rateId).subscribe((response: any) => {
      this.router.navigate(['../'], { relativeTo: this.route });
    });
  }



}
