<div class="container m-b-20" fxLayout="row" fxLayoutAlign="end" fxLayoutGap="20px">

  <div #buttonCreateHoliday class="in-block">
    <button mat-raised-button color="primary" [routerLink]="['create']" *mifosxHasPermission="'CREATE_HOLIDAY'">
      <fa-icon icon="plus"></fa-icon>&nbsp;&nbsp;
      {{'labels.buttons.createholiday' | translate }}
    </button>
  </div>

</div>

<div class="container">

  <div #filterRef fxLayout="row" fxLayoutGap="20px">

    <mat-form-field fxFlex>
      <mat-label>{{'labels.buttons.filter' | translate }}</mat-label>
      <input matInput (keyup)="applyFilter($event.target.value)">
    </mat-form-field>
    <mat-form-field>
      <mat-label> {{'labels.heading.selectoffice' | translate}} </mat-label>
      <mat-select [formControl]="officeSelector">
        <mat-option *ngFor="let office of officeData" [value]="office.id">
          {{ office.name }}
        </mat-option>
      </mat-select>
    </mat-form-field>

  </div>

  <div class="mat-elevation-z8" [hidden]="!officeSelector.value">

    <table mat-table [dataSource]="dataSource" matSort>

      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'labels.heading.holidayname' | translate}} </th>
        <td mat-cell *matCellDef="let holidays"> {{ holidays.name }} </td>
      </ng-container>

      <ng-container matColumnDef="fromDate">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'labels.heading.startdate' | translate}} </th>
        <td mat-cell *matCellDef="let holidays"> {{ holidays.fromDate | dateFormat }} </td>
      </ng-container>

      <ng-container matColumnDef="toDate">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'labels.heading.endDateSelect' | translate}} </th>
        <td mat-cell *matCellDef="let holidays"> {{ holidays.toDate | dateFormat }} </td>
      </ng-container>

      <ng-container matColumnDef="repaymentsRescheduledTo">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'labels.heading.repaymentsheduleto' | translate}} </th>
        <td mat-cell *matCellDef="let holidays"> {{ holidays.reschedulingType === 1 ? 'Next Repayment Date' :
          holidays.repaymentsRescheduledTo | dateFormat }} </td>
      </ng-container>

      <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'labels.heading.status' | translate}} </th>
        <td mat-cell *matCellDef="let holidays"> {{ holidays.status.value }} </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="select-row" [routerLink]="[row.id]"></tr>

    </table>

    <mat-paginator [pageSizeOptions]="[10, 25, 50, 100]" showFirstLastButtons></mat-paginator>

  </div>

</div>

<ng-template #templateButtonCreateHoliday let-popover="popover">
  <h2>{{'labels.buttons.createholiday' | translate }}</h2>
  <p class="mw300">{{'labels.heading.createHolidayText' | translate }}.</p>
  <div fxLayout="row" fxLayoutAlign="end" fxLayoutGap="2%" fxLayout.lt-md="column">
    <button mat-raised-button color="warn"
      (click)="popover.close();configurationWizardService.closeConfigWizard()">{{'labels.buttons.close' | translate
      }}</button>
    <button mat-raised-button color="primary" (click)="popover.close();previousStep();">{{'labels.buttons.back' |
      translate }}</button>
    <button mat-raised-button color="primary"
      (click)="popover.close();showPopover(templateFilterRef,filterRef,'bottom', true);">{{'labels.buttons.next' |
      translate }}</button>
  </div>
</ng-template>

<ng-template #templateFilterRef let-popover="popover">
  <h4>{{'labels.heading.searchHolidaysText' | translate }}.</h4>
  <div fxLayout="row" fxLayoutAlign="end" fxLayoutGap="2%" fxLayout.lt-md="column">
    <button mat-raised-button color="warn"
      (click)="popover.close();configurationWizardService.closeConfigWizard()">{{'labels.buttons.close' | translate
      }}</button>
    <button mat-raised-button color="primary"
      (click)="popover.close();showPopover(templateButtonCreateHoliday,buttonCreateHoliday,'bottom', true);">{{'labels.buttons.back'
      | translate }}</button>
    <button mat-raised-button color="primary" (click)="popover.close();nextStep();">{{'labels.buttons.next' | translate
      }}</button>
  </div>
</ng-template>