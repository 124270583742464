/** Angular Imports */
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';

/** Custom Services */
import { ProductsService } from '../../products.service';
import { SettingsService } from 'app/settings/settings.service';
import { Dates } from 'app/core/utils/dates';

/**
 * Create Rate component.
 */
@Component({
  selector: 'mifosx-create-rate',
  templateUrl: './create-rate.component.html',
  styleUrls: ['./create-rate.component.scss']
})
export class CreateRateComponent implements OnInit {

  /** Rate form */
  rateForm: FormGroup;
  //Right now only loan is accepted for a rate.
  rateOptions = [{id : "m_loan"}];


  /**
   * Retrieves the rate template data
   * @param {FormBuilder} formBuilder Form Builder
   * @param {ProductsService} productsService Products Service.
   * @param {ActivatedRoute} route Activated Route.
   * @param {Router} router Router for navigation.
   * @param {SettingsService} settingsService Settings Service
   */
  constructor(private formBuilder: FormBuilder,
              private productsService: ProductsService,
              private route:  ActivatedRoute,
              private router: Router,
              private settingsService: SettingsService) {
               }

  /**
   * Create and sets Rate Form
   */
  ngOnInit(): void {
    this.createRateForm();
  }

  /**
   * Create the Rate Form
   */
  createRateForm() {
    this.rateForm = this.formBuilder.group({
      'productApply': ['', Validators.required],
      'name': ['', Validators.required],
      'percentage': ['', Validators.required],
      'active': [false],
    });
  }

  /**
   * Submit a new rate Product form
   */
  submit() {
    const rateFormData = this.rateForm.value;
    const locale = this.settingsService.language.code;
    const data = {
      ...rateFormData,
      locale
    };
    this.productsService.createRate(data).subscribe((response: any) => {
      this.router.navigate(['../'], { relativeTo: this.route });
    });
  }



}
