<div class="container input">

  <mat-card *ngIf="!isCollapsed">

    <form [formGroup]="instructionForm" (ngSubmit)="search()">

      <mat-card-content>

        <div fxLayout="column">

          <mat-form-field>
            <mat-label>{{'labels.inputs.clientName' | translate }}</mat-label>
            <input matInput formControlName="clientName">
          </mat-form-field>

          <mat-form-field>
            <mat-label>{{'labels.inputs.clientId' | translate }}</mat-label>
            <input matInput formControlName="clientId">
          </mat-form-field>

          <mat-form-field>
            <mat-label>{{'labels.inputs.transfertype' | translate}}</mat-label>
            <mat-select formControlName="transferType">
              <mat-option *ngFor="let transferType of standingInstructionsTemplate.transferTypeOptions"
                [value]="transferType.id">
                {{ transferType.value }}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field>
            <mat-label>{{'labels.inputs.accounttype' | translate}}</mat-label>
            <mat-select formControlName="fromAccountType">
              <mat-option *ngFor="let accountType of standingInstructionsTemplate.fromAccountTypeOptions"
                [value]="accountType.id">
                {{ accountType.value }}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field *ngIf="instructionForm.contains('fromAccountId')">
            <mat-label>{{ 'labels.inputs.fromAccountId' | translate }}</mat-label>
            <input matInput formControlName="fromAccountId">
          </mat-form-field>

          <mat-form-field (click)="fromDatePicker.open()">
            <mat-label>{{ 'labels.inputs.fromdate' | translate }}</mat-label>
            <input matInput [min]="minDate" [max]="maxDate" formControlName="fromDate" [matDatepicker]="fromDatePicker">
            <mat-datepicker-toggle matSuffix [for]="fromDatePicker"></mat-datepicker-toggle>
            <mat-datepicker #fromDatePicker></mat-datepicker>
          </mat-form-field>

          <mat-form-field (click)="toDatePicker.open()">
            <mat-label>{{ 'labels.inputs.todate' | translate }}</mat-label>
            <input matInput [min]="minDate" [max]="maxDate" formControlName="toDate" [matDatepicker]="toDatePicker">
            <mat-datepicker-toggle matSuffix [for]="toDatePicker"></mat-datepicker-toggle>
            <mat-datepicker #toDatePicker></mat-datepicker>
          </mat-form-field>

        </div>

      </mat-card-content>

      <mat-card-actions fxLayout="row" fxLayout.xs="column" fxLayoutAlign="center" fxLayoutGap="5px">
        <button type="button" mat-raised-button [routerLink]="['../']">{{ 'labels.buttons.cancel' | translate
          }}</button>
        <button mat-raised-button color="primary" *mifosxHasPermission="'READ_STANDINGINSTRUCTION'">
          <fa-icon icon="search"></fa-icon>
          &nbsp; {{ 'labels.buttons.search' | translate }}
        </button>
      </mat-card-actions>

    </form>

  </mat-card>

</div>

<div class="container output" *ngIf="isCollapsed">

  <mat-card>

    <div class="m-b-20">
      <button mat-raised-button color="primary" (click)="isCollapsed = false">
        {{'labels.buttons.parameters' | translate }}
      </button>
    </div>

    <table class="mat-elevation-z1" mat-table [dataSource]="dataSource" matSort>

      <ng-container matColumnDef="fromClient">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'labels.heading.fromClient' | translate}} </th>
        <td mat-cell *matCellDef="let instruction"> {{ instruction.fromClient.displayName }}({{
          instruction.fromClient.id }}) </td>
      </ng-container>

      <ng-container matColumnDef="fromAccount">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'labels.heading.fromaccount' | translate}} </th>
        <td mat-cell *matCellDef="let instruction"> {{ instruction.fromAccount.productName }}({{
          instruction.fromAccount.id }}) </td>
      </ng-container>

      <ng-container matColumnDef="toClient">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'labels.heading.toClient' | translate}} </th>
        <td mat-cell *matCellDef="let instruction"> {{ instruction.toClient.displayName }}({{ instruction.toClient.id
          }}) </td>
      </ng-container>

      <ng-container matColumnDef="toAccount">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'labels.heading.toaccount' | translate}} </th>
        <td mat-cell *matCellDef="let instruction"> {{ instruction.toAccount.productName }}({{ instruction.toAccount.id
          }}) </td>
      </ng-container>

      <ng-container matColumnDef="executionTime">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'labels.heading.executionTime' | translate}} </th>
        <td mat-cell *matCellDef="let instruction"> {{ instruction.executionTime }} </td>
      </ng-container>

      <ng-container matColumnDef="amount">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'labels.heading.amount' | translate}} </th>
        <td mat-cell *matCellDef="let instruction"> {{ instruction.amount | number }} </td>
      </ng-container>

      <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'labels.heading.status' | translate}} </th>
        <td mat-cell *matCellDef="let instruction"> {{ instruction.status }} </td>
      </ng-container>

      <ng-container matColumnDef="errorLog">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'labels.heading.errorlog' | translate}} </th>
        <td mat-cell *matCellDef="let instruction">
          <button *ngIf="instruction.status === 'failed'" class="error-log" mat-raised-button color="warn"
            [matTooltip]="instruction.errorLog">
            <i class="fa fa-exclamation"></i>
          </button>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

    </table>

    <mat-paginator [pageSizeOptions]="[10, 25, 50, 100]" showFirstLastButtons></mat-paginator>

  </mat-card>

</div>
