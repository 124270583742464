<div class="container">

  <mat-card>

    <form [formGroup]="userForm" (ngSubmit)="submit()">

      <mat-card-content>

        <div #userFormRef fxLayout="row wrap" fxLayoutGap="2%" fxLayout.lt-md="column">

          <mat-form-field fxFlex="48%">
            <mat-label>{{'labels.inputs.username'| translate}}</mat-label>
            <input matInput required formControlName="username">
            <mat-error *ngIf="userForm.controls.username.hasError('required')">
              {{'labels.inputs.username'| translate}} <strong>{{"labels.commons.is required" | translate}}</strong>
            </mat-error>
          </mat-form-field>

          <mat-form-field fxFlex="48%">
            <mat-label>{{'labels.heading.email'| translate}}</mat-label>
            <input matInput [required]="userForm.controls.sendPasswordToEmail.value" formControlName="email">
            <mat-error *ngIf="userForm.controls.email.hasError('email')">
              {{'errors.inputs.invaliEmail'| translate}}
            </mat-error>
            <mat-error *ngIf="userForm.controls.email.hasError('required')">
              {{'labels.heading.email'| translate}} <strong>{{"labels.commons.is required" | translate}}</strong>
            </mat-error>
          </mat-form-field>

          <mat-form-field fxFlex="48%">
            <mat-label>{{'labels.inputs.firstname'| translate}}</mat-label>
            <input matInput required formControlName="firstname">
            <mat-error *ngIf="userForm.controls.firstname.hasError('pattern')">
              {{'labels.inputs.firstname'| translate}} <strong>{{'errors.inputs.cannotBeginWwithASpecialCharacterOrNumber'| translate}}</strong>
            </mat-error>
            <mat-error *ngIf="userForm.controls.firstname.hasError('required')">
              {{'labels.inputs.firstname'| translate}} <strong>{{"labels.commons.is required" | translate}}</strong>
            </mat-error>
          </mat-form-field>

          <mat-form-field fxFlex="48%">
            <mat-label>{{'labels.inputs.lastname'| translate}}</mat-label>
            <input matInput required formControlName="lastname">
            <mat-error *ngIf="userForm.controls.lastname.hasError('pattern')">
              {{'labels.inputs.lastname'| translate}} <strong>{{'errors.inputs.cannotBeginWwithASpecialCharacterOrNumber'| translate}}</strong>
            </mat-error>
            <mat-error *ngIf="userForm.controls.lastname.hasError('required')">
              {{'labels.inputs.lastname'| translate}} <strong>{{"labels.commons.is required" | translate}}</strong>
            </mat-error>
          </mat-form-field>

          <div fxFlex="48%" class="password-never-expires-wrapper">
            <mat-checkbox labelPosition="before" formControlName="passwordNeverExpires" >
             {{'labels.inputs.overridepasswordexpiry'| translate}}
            </mat-checkbox>
          </div>

          <div fxFlex="48%" class="send-password-to-email-wrapper">
            <mat-checkbox labelPosition="before" formControlName="sendPasswordToEmail">
              {{'labels.inputs.autogeneratepassword'| translate}}
            </mat-checkbox>
          </div>

          <mat-form-field fxFlex="48%" *ngIf="!userForm.controls.sendPasswordToEmail.value">
            <mat-label>{{'labels.inputs.password'| translate}}</mat-label>
            <input matInput required type="password" formControlName="password">
            <mat-error *ngIf="userForm.controls.password.hasError('required')">
              {{'labels.inputs.password'| translate}} <strong>{{"labels.commons.is required" | translate}}</strong>
            </mat-error>
          </mat-form-field>

          <mat-form-field fxFlex="48%" *ngIf="!userForm.controls.sendPasswordToEmail.value">
            <mat-label>{{'labels.inputs.repeatpassword'| translate}}</mat-label>
            <input matInput required type="password" formControlName="repeatPassword">
            <mat-error *ngIf="userForm.controls.repeatPassword.hasError('required')">
              {{'labels.inputs.password'| translate}} <strong>{{"labels.commons.is required" | translate}}</strong>
            </mat-error>
          </mat-form-field>

          <p fxFlex="98%" class="reset-password-error" *ngIf="userForm.errors?.passwordsDoNotMatch &&
          (!userForm.controls.password.hasError('required') && !userForm.controls.repeatPassword.hasError('required'))">
          {{'labels.inputs.password'| translate}} <strong>{{'labels.doNotMatch'| translate}}</strong>
          </p>

          <mat-form-field fxFlex="48%">
            <mat-label>{{'labels.inputs.office'| translate}}</mat-label>
            <mat-select required formControlName="officeId">
              <mat-option *ngFor="let office of officesData" [value]="office.id">
                {{ office.name }}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="userForm.controls.officeId.hasError('required')">
              {{'labels.inputs.office'| translate}} <strong>{{"labels.commons.is required" | translate}}</strong>
            </mat-error>
          </mat-form-field>

          <mat-form-field fxFlex="48%">
            <mat-label>{{'labels.inputs.staff'| translate}}</mat-label>
            <mat-select formControlName="staffId">
              <mat-option *ngFor="let staff of staffData" [value]="staff.id">
                {{ staff.displayName }}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field fxFlex="48%">
            <mat-label>{{'labels.inputs.roles'| translate}}</mat-label>
            <mat-select required formControlName="roles" multiple>
              <mat-option *ngFor="let role of rolesData" [value]="role.id">
                {{ role.name }}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="userForm.controls.roles.hasError('required')">
              {{'labels.atLeastOneRole'| translate}} <strong>{{'labels.MustBeSelected'| translate}}</strong>
            </mat-error>
          </mat-form-field>

        </div>

      </mat-card-content>

      <mat-card-actions fxLayout="row" fxLayout.xs="column" fxLayoutAlign="center" fxLayoutGap="5px">
        <button type="button" mat-raised-button [routerLink]="['../']">{{"labels.buttons.cancel" | translate}}</button>
        <button mat-raised-button color="primary" [disabled]="!userForm.valid">{{"labels.buttons.submit" | translate}}</button>
      </mat-card-actions>

    </form>

  </mat-card>

</div>

<ng-template #templateUserFormRef let-popover="popover">
  <h2>{{'labels.anchors.createuser'| translate}}</h2>
  <p class="mw400">{{'labels.ClickStartFillingDetails'| translate}} * {{'labels.markFieldsNeccessary'| translate}} {{'labels.forMoreDetailsClick'| translate}} <a href="" target="_blank">{{'labels.anchors.users'| translate}}</a></p>
  <div fxLayout="row" fxLayoutAlign="end" fxLayoutGap="2%" fxLayout.lt-md="column">
    <button mat-raised-button color="warn" (click)="popover.close();configurationWizardService.closeConfigWizard()">{{"labels.buttons.close" | translate}}</button>
    <button mat-raised-button color="primary" (click)="popover.close()">{{'labels.anchors.createuser'| translate}}</button>
    <button mat-raised-button color="primary" (click)="popover.close();previousStep()">{{"labels.buttons.back" | translate}}</button>
    <button mat-raised-button color="primary" (click)="popover.close();nextStep()">{{"labels.buttons.next" | translate}}</button>
  </div>
</ng-template>

