import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { SystemService } from 'app/system/system.service';

@Component({
  selector: 'mifosx-add-credit-bureau',
  templateUrl: './add-credit-bureau.component.html',
  styleUrls: ['./add-credit-bureau.component.scss']
})
export class AddCreditBureauComponent implements OnInit {

  creditBureauForm: FormGroup;
  CreditBureauConfigurations :any= [];


  /**
   * @param {ActivatedRoute} route ActivatedRoute.
   * @param {Router} router Router.
   * @param {ConfigurationWizardService} configurationWizardService ConfigurationWizard Service.
   * @param {PopoverService} popoverService PopoverService.
   */
  constructor(private route: ActivatedRoute,
    private router: Router,
    private systemService: SystemService,
    private formBuilder: FormBuilder) {
    this.route.data.subscribe((data: { creditBureauTemplate: any}) => {
      this.CreditBureauConfigurations = data.creditBureauTemplate;
    });
    this.creditBureauForm = this.formBuilder.group({
      'alias': [ '', Validators.required ],
      'creditBureauId': [ undefined, Validators.required ]
    });
  }

  ngOnInit(): void {
  }

  submit(){
    const {creditBureauId,alias} = this.creditBureauForm.value;
    this.systemService.addCreditBureau({alias:alias},creditBureauId).subscribe((data:any)=>{
      this.router.navigate(['../'], {relativeTo: this.route});
    })
  }

}
