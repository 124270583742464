import { TranslateService } from '@ngx-translate/core';
/** Angular Imports */
import { Component, OnInit } from '@angular/core';

/** Custom Service */
import { SettingsService } from './settings.service';
import { FormControl } from '@angular/forms';

/**
 * Settings component.
 */
@Component({
  selector: 'mifosx-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss']
})
export class SettingsComponent implements OnInit {

  /** Placeholder for languages. update once translations are set up */
  languageSelector = new FormControl();
  /**input from application configuration expansion panel */
  organizationName = new FormControl();
  favicon: File;
  logo: File;
  /** Date formats. */
  dateFormats: string[] = [
    'dd MMMM yyyy',
    'dd/MMMM/yyyy',
    'dd-MMMM-yyyy',
    'dd-MM-yy',
    'MMMM-dd-yyyy',
    'MMMM dd yyyy',
    'MMMM/dd/yyyy',
    'MM-dd-yy',
    'yyyy-MM-dd'
  ];
  /** Placeholder for fonts. */
  fonts: any;

  /** Language Setting */
  language = new FormControl('');
  /** Date Format Setting */
  dateFormat =  new FormControl('');

  /**
   * @param {SettingsService} settingsService Settings Service
   */
  constructor(private translate: TranslateService,
    private settingsService: SettingsService) {
      this.languageSelector.setValue(this.currentLanguage);

    }

  ngOnInit() {
    this.language.patchValue(this.settingsService.language);
    this.dateFormat.patchValue(this.settingsService.dateFormat);
    this.buildDependencies();
  }

  /**
   * Subscribe to value changes.
   */
  buildDependencies() {
    this.language.valueChanges.subscribe((language: any) => {
      this.settingsService.setLanguage(language);
    });
    this.dateFormat.valueChanges.subscribe((dateFormat: string) => {
      this.settingsService.setDateFormat(dateFormat);
    });
  }

  /**
   * Compare function for mat-select.
   * Useful in patching values if value is an object.
   * @param {any} option1 option 1
   * @param {any} option2 option 2.
   */
  compareOptions(option1: any, option2: any) {
    return option1 && option2 && option1.code === option2.code;
  }

    /**
   * Sets a new language to be used by the application.
   * @param {string} language New language.
   */
    setLanguage() {
      this.translate.use(this.languageSelector.value);
      this.settingsService.setLanguage({ name: this.languageSelector.value, code: this.languageSelector.value.substring(0, 2) });
    }

    /**
   * Returns the current language used by the application.
   * @returns {string} Current language.
   */
    get currentLanguage(): string {
      return this.translate.currentLang;
    }

    /**
     * Returns all the languages supported by the application.
     * @return {string[]} Supported languages.
     */
    get languages(): string[] {
      return this.translate.getLangs();
    }

  }
