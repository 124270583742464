<h1 mat-dialog-title>{{ data.context }} {{ 'labels.heading.familyember' | translate }}</h1>

<form [formGroup]="familyMemberForm">

  <div fxLayout="row wrap" fxLayout.lt-md="column" fxLayoutAlign="start center" fxLayoutGap="2%">

    <mat-form-field fxFlex="48%">
      <mat-label>{{ 'labels.inputs.firstname' | translate }}</mat-label>
      <input formControlName="firstName" required matInput />
      <mat-error *ngIf="familyMemberForm.controls.firstName.hasError('required')">
        {{ 'labels.inputs.firstname' | translate }} <strong>{{"labels.commons.is required" | translate}}</strong>
      </mat-error>
    </mat-form-field>

    <mat-form-field fxFlex="48%">
      <mat-label>{{ 'labels.inputs.middlename' | translate }}</mat-label>
      <input formControlName="middleName" matInput />
    </mat-form-field>

    <mat-form-field fxFlex="48%">
      <mat-label>{{ 'labels.inputs.lastname' | translate }}</mat-label>
      <input formControlName="lastName" required matInput />
      <mat-error *ngIf="familyMemberForm.controls.lastName.hasError('required')">
        {{ 'labels.inputs.lastname' | translate }} <strong>{{"labels.commons.is required" | translate}}</strong>
      </mat-error>
    </mat-form-field>

    <mat-form-field fxFlex="48%">
      <mat-label>{{ 'labels.inputs.qualification' | translate }}</mat-label>
      <input formControlName="qualification" matInput />
    </mat-form-field>

    <mat-form-field fxFlex="48%">
      <mat-label>{{ 'labels.inputs.age' | translate }}</mat-label>
      <input type="number" formControlName="age" matInput min="0"/>
      <mat-error *ngIf="familyMemberForm.controls.age.hasError('required')">
        {{ 'labels.inputs.age' | translate }} <strong>{{"labels.commons.is required" | translate}}</strong>
      </mat-error>
      <mat-error *ngIf="familyMemberForm.controls.age.hasError('min')">
        {{ 'errors.inputs.ageCannotBeNegative' | translate }}
      </mat-error>
    </mat-form-field>

    <mat-checkbox formControlName="isDependent" labelPosition="before" fxFlex="48%">{{ 'labels.inputs.isDependent' | translate }}</mat-checkbox>

    <mat-form-field fxFlex="48%">
      <mat-label>{{ 'labels.inputs.relationship' | translate }}</mat-label>
      <mat-select formControlName="relationshipId" required>
        <mat-option *ngFor="let relation of data.options.relationshipIdOptions" [value]="relation.id">
          {{ relation.name  }}
        </mat-option>
      </mat-select>
      <mat-error *ngIf="familyMemberForm.controls.relationshipId.hasError('required')">
        {{ 'labels.inputs.relationship' | translate }} <strong>{{"labels.commons.is required" | translate}}</strong>
      </mat-error>
    </mat-form-field>

    <mat-form-field fxFlex="48%">
      <mat-label>{{ 'labels.inputs.gender' | translate }}</mat-label>
      <mat-select formControlName="genderId">
        <mat-option *ngFor="let gender of data.options.genderIdOptions" [value]="gender.id">
          {{ gender.name }}
        </mat-option>
      </mat-select>
      <mat-error *ngIf="familyMemberForm.controls.genderId.hasError('required')">
        {{ 'labels.inputs.gender' | translate }} <strong>{{"labels.commons.is required" | translate}}</strong>
      </mat-error>
    </mat-form-field>

    <mat-form-field fxFlex="48%">
      <mat-label>{{ 'labels.inputs.profession' | translate }}</mat-label>
      <mat-select formControlName="professionId">
        <mat-option *ngFor="let profession of data.options.professionIdOptions" [value]="profession.id">
          {{ profession.name }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field fxFlex="48%">
      <mat-label>{{ 'labels.inputs.maritalstatus' | translate }}</mat-label>
      <mat-select formControlName="maritalStatusId">
        <mat-option *ngFor="let maritalStatus of data.options.maritalStatusIdOptions"
          [value]="maritalStatus.id">
          {{ maritalStatus.name }}
        </mat-option>
      </mat-select>
    </mat-form-field>


    <mat-form-field fxFlex="98%" (click)="dueDatePicker.open()">
      <mat-label>{{ 'labels.inputs.dateofbirth' | translate }}</mat-label>
      <input formControlName="dateOfBirth" matInput [min]="minDate" [max]="maxDate" [matDatepicker]="dueDatePicker">
      <mat-datepicker-toggle matSuffix [for]="dueDatePicker"></mat-datepicker-toggle>
      <mat-datepicker #dueDatePicker></mat-datepicker>
      <mat-error *ngIf="familyMemberForm.controls.dateOfBirth.hasError('required')">
        {{ 'labels.inputs.dateofbirth' | translate }} <strong>{{"labels.commons.is required" | translate}}</strong>
      </mat-error>
    </mat-form-field>

  </div>

  <mat-dialog-actions align="center">
    <button mat-raised-button mat-dialog-close>{{"labels.buttons.cancel" | translate}}</button>
    <button mat-raised-button color="primary" [disabled]="!familyMemberForm.valid" [mat-dialog-close]="{ member: familyMember }">{{ 'labels.buttons.confirm' | translate }}</button>
  </mat-dialog-actions>

</form>
