<div class="container">

  <mat-card>

    <div fxLayout="row" fxLayout.lt-md="column">

      <div fxFlex="50%">

        <mat-nav-list>

          <mat-list-item [routerLink]="['amazon-s3']">
            <mat-icon matListIcon>
              <fa-icon icon="cloud" size="sm"></fa-icon>
            </mat-icon>
            <h4 matLine>{{"labels.S3AmazonExternalService" | translate}}</h4>
            <p matLine>{{"labels.S3AmazonServiceConfiguration" | translate}}</p>
          </mat-list-item>

          <mat-list-item [routerLink]="['sms']">
            <mat-icon matListIcon>
              <fa-icon icon="comment-alt" size="sm"></fa-icon>
            </mat-icon>
            <h4 matLine>{{"labels.SMSExternalService" | translate}}</h4>
            <p matLine>{{"labels.SMSServiceConfiguration" | translate}}</p>
          </mat-list-item>

          <mat-list-item [routerLink]="['creditBureau']">
            <mat-icon matListIcon>
              <fa-icon icon="university" size="sm"></fa-icon>
            </mat-icon>
            <h4 matLine>{{"labels.creditbureau" | translate}}</h4>
            <p matLine>{{"labels.creditBureauIntegration" | translate}}</p>
          </mat-list-item>

        </mat-nav-list>

      </div>

      <div fxFlex="50%">

        <mat-nav-list>

          <mat-list-item [routerLink]="['email']">
            <mat-icon matListIcon>
              <fa-icon icon="envelope" size="sm"></fa-icon>
            </mat-icon>
            <h4 matLine>{{"labels.emailExternalService" | translate}}</h4>
            <p matLine>{{"labels.emailServiceConfiguration" | translate}}</p>
          </mat-list-item>

          <mat-list-item [routerLink]="['notification']">
            <mat-icon matListIcon>
              <fa-icon icon="bell" size="sm"></fa-icon>
            </mat-icon>
            <h4 matLine>{{"labels.notificationExternalService" | translate}}</h4>
            <p matLine>{{"labels.notificationServiceConfiguration" | translate}}</p>
          </mat-list-item>

        </mat-nav-list>

      </div>

    </div>

  </mat-card>

</div>
