<div class="container">

    <mat-card>

      <form [formGroup]="repaymentLoanForm" (ngSubmit)="submit()">

        <mat-card-content >

          <div fxLayout="column">
            <mat-form-field fxFlex="98%">
              <mat-label>{{"labels.clientFrom" | translate}}</mat-label>
              <input matInput formControlName="fromClientId" [matAutocomplete]="clientsFromAutocomplete">
              <mat-error *ngIf="repaymentLoanForm.controls.fromClientId.hasError('required')">
                {{"labels.inputs.client" | translate}} <strong>{{"labels.commons.is required" | translate}}</strong>
              </mat-error>
            </mat-form-field>

            <mat-autocomplete autoActiveFirstOption #clientsFromAutocomplete="matAutocomplete" (optionSelected)="clientChange($event)" [displayWith]="displayClient">
              <mat-option *ngFor="let client of clientsData" [value]="client">
                {{ client.id }} - {{ client.displayName }}
              </mat-option>
            </mat-autocomplete>

            <mat-form-field fxFlex="98%">
              <mat-label>{{"labels.inputs.account" | translate}}</mat-label>
              <mat-select required formControlName="fromAccountId" (selectionChange)="accountChangeEvent()">
                <mat-option *ngFor="let fromAccount of accountData" [value]="fromAccount.id">
                  {{ fromAccount.productName }} - {{ fromAccount.accountNo }}
                </mat-option>
              </mat-select>
              <mat-error *ngIf="repaymentLoanForm.controls.fromAccountId.hasError('required')">
                {{"labels.heading.account" | translate}} <strong>{{"labels.commons.is required" | translate}}</strong>
              </mat-error>
            </mat-form-field>

            <mat-form-field (click)="transactionDatePicker.open()">
              <mat-label>{{"labels.heading.transactiondate" | translate}}</mat-label>
              <input matInput [min]="minDate" [max]="maxDate" [matDatepicker]="transactionDatePicker" required
                formControlName="transactionDate">
              <mat-datepicker-toggle matSuffix [for]="transactionDatePicker"></mat-datepicker-toggle>
              <mat-datepicker #transactionDatePicker></mat-datepicker>
              <mat-error *ngIf="repaymentLoanForm.controls.transactionDate.hasError('required')">
                {{"labels.heading.transactiondate" | translate}} <strong>{{"labels.commons.is required" | translate}}</strong>
              </mat-error>
            </mat-form-field>

            <mat-form-field>
              <mat-label>{{"labels.inputs.transactionamount" | translate}}</mat-label>
              <input matInput required formControlName="transactionAmount">
              <mat-error *ngIf="repaymentLoanForm.controls.transactionAmount.hasError('required')">
                {{"labels.inputs.transactionamount" | translate}} <strong>{{"labels.commons.is required" | translate}}</strong>
              </mat-error>
            </mat-form-field>

            <mat-form-field>
              <mat-label>{{"labels.heading.paymenttype" | translate}}</mat-label>
              <mat-select formControlName="paymentTypeId">
                <mat-option *ngFor="let paymentType of paymentTypes" [value]="paymentType.id">
                  {{ paymentType.name }}
                </mat-option>
              </mat-select>
            </mat-form-field>

            <div fxFlexFill>
              <span fxFlex="25%">{{"labels.anchors.showpaymentdetails" | translate}}</span>
              <span fxFlex="75%" class="expandcollapsebutton" (click)="addPaymentDetails()">
                <button mat-raised-button color="primary" *ngIf="showPaymentDetails">
                  <i class="fa fa-minus"></i>
                </button>
                <button mat-raised-button color="primary" *ngIf="!showPaymentDetails">
                  <i class="fa fa-plus"></i>
                </button>
              </span>
            </div>

            <ng-container *ngIf="showPaymentDetails">
              <mat-form-field>
                <mat-label>{{"labels.inputs.accnum" | translate}}</mat-label>
                <input matInput formControlName="accountNumber">
              </mat-form-field>

              <mat-form-field>
                <mat-label>{{"labels.inputs.checknumber" | translate}}</mat-label>
                <input matInput formControlName="checkNumber">
              </mat-form-field>

              <mat-form-field>
                <mat-label>{{"labels.inputs.routingcode" | translate}}</mat-label>
                <input matInput formControlName="routingCode">
              </mat-form-field>

              <mat-form-field>
                <mat-label>{{"labels.inputs.receiptnumber" | translate}}</mat-label>
                <input matInput formControlName="receiptNumber">
              </mat-form-field>

              <mat-form-field>
                <mat-label>{{"labels.inputs.banknumber" | translate}}</mat-label>
                <input matInput formControlName="bankNumber">
              </mat-form-field>
            </ng-container>

            <mat-form-field>
              <mat-label>{{"labels.inputs.note" | translate}}</mat-label>
              <textarea matInput formControlName="note"></textarea>
            </mat-form-field>

          </div>

          <mat-card-actions fxLayout="row" fxLayout.xs="column" fxLayoutAlign="center" fxLayoutGap="5px">
            <button type="button" mat-raised-button [routerLink]="['..']">{{"labels.buttons.cancel" | translate}}</button>
            <button mat-raised-button color="primary" [disabled]="!repaymentLoanForm.valid"
              *mifosxHasPermission="'REPAYMENT_LOAN'">{{"labels.buttons.submit" | translate}}</button>
          </mat-card-actions>

        </mat-card-content>

      </form>

    </mat-card>

  </div>
