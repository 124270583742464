import { AfterViewInit, OnInit, Component, Input, Inject, SimpleChanges } from '@angular/core'
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { DOCUMENT } from '@angular/common'
import * as jose from 'jose'
import { environment } from 'environments/environment';

interface DashboardParam {
  "name": string,
  "slug": string,
  "id": string,
  "type": string,
  "sectionId": string
}

interface DashboardData {
  name: string,
  uuid: string,
  id: number,
  parameters: DashboardParam[]
}

@Component({
  selector: 'mifosx-metabasedashboard',
  templateUrl: './metabasedashboard.component.html',
  styleUrls: ['./metabasedashboard.component.scss']
})
export class MetabaseDashboardComponent implements OnInit {

  @Input() dashId: any;
  @Input() clientId: any;
  @Input() data: DashboardData;

  urlSafe: SafeResourceUrl;
  url: string;
  isLoading: boolean = false;

  constructor(public sanitizer: DomSanitizer, @Inject(DOCUMENT) private _document: Document) {
  }

  ngOnChanges(changes: SimpleChanges) {
    if((this.dashId && this.clientId) || this.data){
    this.setURL();
    }
  }

  setURL() {
    this.url = this.dashId ? environment.metabaseURL + this.dashId : environment.metabaseURL + this.data?.uuid;
    let params: string[] = [];
    if (this.dashId) {
      params = ['client_id=' + this.clientId];
    } else {
      this.data?.parameters?.forEach((item: DashboardParam) => {
        params.push(`${item.slug}=`);
      })
    }

    if (params.length != 0) {
      this.url = this.url + '?' + params.join('&');
      if(this.clientId){
        this.url = this.url + '#hide_parameters=client_id'
      }
    }
    this.urlSafe = this.sanitizer.bypassSecurityTrustResourceUrl(this.url);
  }
  ngOnInit(): void {
    this.setURL();
  }
  onLoad() {
    this.isLoading = false;
  }

}
