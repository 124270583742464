<div class="container">

  <mat-card>

    <form [formGroup]="createDividendForm" (ngSubmit)="submit()">

      <mat-card-content>

        <div fxLayout="column">

          <mat-form-field (click)="startDatePicker.open()">
            <mat-label>{{"labels.inputs.dividendperiodstartdate" | translate }}</mat-label>
            <input matInput [min]="minDate" [max]="maxDate" [matDatepicker]="startDatePicker" required formControlName="dividendPeriodStartDate">
            <mat-datepicker-toggle matSuffix [for]="startDatePicker"></mat-datepicker-toggle>
            <mat-datepicker #startDatePicker></mat-datepicker>
            <mat-error *ngIf="createDividendForm.controls.dividendPeriodStartDate.hasError('required')">
              {{"labels.inputs.dividendperiodstartdate" | translate }} <strong>{{"labels.commons.is required" | translate}}</strong>
            </mat-error>
          </mat-form-field>

          <mat-form-field (click)="endDatePicker.open()">
            <mat-label>{{"labels.heading.dividendperiodenddate" | translate }}</mat-label>
            <input matInput [min]="minDate" [max]="maxDate" [matDatepicker]="endDatePicker" required formControlName="dividendPeriodEndDate">
            <mat-datepicker-toggle matSuffix [for]="endDatePicker"></mat-datepicker-toggle>
            <mat-datepicker #endDatePicker></mat-datepicker>
            <mat-error *ngIf="createDividendForm.controls.dividendPeriodEndDate.hasError('required')"> {{"labels.inputs.enddate" | translate }} <strong>{{"labels.commons.is required" | translate}}</strong>
            </mat-error>
          </mat-form-field>

          <mat-form-field>
            <mat-label>{{"labels.heading.dividendamount" | translate }}</mat-label>
            <input matInput required formControlName="dividendAmount" decimalInput>
            <mat-error *ngIf="createDividendForm.controls.dividendAmount.hasError('required')">
              {{"labels.inputs.amount" | translate}} <strong>{{"labels.commons.is required" | translate}}</strong>
            </mat-error>
          </mat-form-field>

        </div>

      </mat-card-content>

      <mat-card-actions fxLayout="row" fxLayout.xs="column" fxLayoutAlign="center" fxLayoutGap="5px">
        <button type="button" mat-raised-button [routerLink]="['../']">{{"labels.buttons.cancel" | translate}}</button>
        <button mat-raised-button color="primary" [disabled]="!createDividendForm.valid">{{"labels.buttons.submit" | translate}}</button>
      </mat-card-actions>

    </form>

  </mat-card>

</div>
