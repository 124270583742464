<div class="card">
  <div class="content">
    <div class="toolbar">
      <h4>{{'labels.heading.makercheckertask.waiting.for.approval' | translate}}</h4>
    </div>
    <br>
    <div>
      <h4 class="paddedleft">{{'labels.makerchecker.task.created.description' | translate}}</h4>
      <h4 class="paddedleft">{{'labels.makerchecker.task.created.description.view.details' |
        translate}}&nbsp;&nbsp;&nbsp;&nbsp;


        <button mat-raised-button color="primary"
          [routerLink]="['/checker-inbox-and-tasks/checker-inbox',commandId,'view']">
          {{"labels.heading.details" | translate}}
        </button>
      </h4>
    </div>
  </div>
</div>
