<div class="container">

  <mat-card>

    <mat-card-content>

      <div fxLayout="row wrap" fxLayout.lt-md="column" *ngIf="accountTransferTemplateData">

        <h3 class="mat-h3" fxFlexFill>{{ 'labels.transferringFromDetails' | translate }}</h3>

        <mat-divider [inset]="true"></mat-divider>

        <div fxFlexFill>
          <span fxFlex="40%">{{ 'labels.heading.applicant' | translate }}</span>
          <span fxFlex="60%">{{ accountTransferTemplateData?.fromClient?.displayName }}</span>
        </div>

        <div fxFlexFill>
          <span fxFlex="40%">{{ 'labels.heading.office' | translate }}</span>
          <span fxFlex="60%">{{ accountTransferTemplateData?.fromOffice?.name }}</span>
        </div>

        <div fxFlexFill>
          <span fxFlex="40%">{{ 'labels.heading.fromaccount' | translate }}</span>
          <span
            fxFlex="60%">{{ accountTransferTemplateData?.fromAccount?.productName}}&nbsp;-&nbsp;#{{ accountTransferTemplateData.fromAccount.accountNo }}</span>
        </div>

        <div fxFlexFill>
          <span fxFlex="40%">{{ 'labels.heading.fromaccounttype' | translate }}</span>
          <span fxFlex="60%">{{ accountTransferTemplateData?.fromAccountType?.value }}</span>
        </div>

        <div fxFlexFill>
          <span fxFlex="40%">{{ 'labels.heading.currency' | translate }}</span>
          <span fxFlex="60%">{{ accountTransferTemplateData?.currency?.name }}</span>
        </div>

      </div>


      <form [formGroup]="makeAccountTransferForm">

        <div fxLayout="row wrap" fxLayoutGap="2%" fxLayout.lt-md="column">

      <h3 class="mat-h3" fxFlexFill>{{ 'labels.heading.transferredfrom' | translate }}</h3>

      <mat-divider [inset]="true"></mat-divider>
          <mat-form-field fxFlex="98%">
            <mat-label>{{ 'labels.heading.account' | translate }}</mat-label>
            <mat-select required formControlName="fromAccountId" (selectionChange)="changeAccount()">
              <mat-option *ngFor="let account of savingAccounts" [value]="account.id">
                {{ account.accountNo +' ('+account.accountBalance + ' '+ account.currency.code +')' }}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="makeAccountTransferForm.controls.fromAccountId.hasError('required')">
              {{ 'labels.heading.account' | translate }} <strong>{{"labels.commons.is required" | translate}}</strong>
            </mat-error>
          </mat-form-field>
          <h3 class="mat-h3" fxFlexFill>{{ 'labels.transferredTo' | translate }}</h3>

          <mat-divider [inset]="true"></mat-divider>
          <!-- <mat-form-field fxFlex="98%">
            <mat-label>Office</mat-label>
            <mat-select required formControlName="toOfficeId" (selectionChange)="changeAccount()">
              <mat-option *ngFor="let toOfficeType of toOfficeTypeData" [value]="toOfficeType.id">
                {{ toOfficeType?.name }}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="makeAccountTransferForm.controls.toOfficeId.hasError('required')">
              Office <strong>{{"labels.commons.is required" | translate}}</strong>
            </mat-error>
          </mat-form-field> -->

          <mat-form-field fxFlex="98%">
            <mat-label>{{ 'labels.heading.client' | translate }}</mat-label>
            <input matInput formControlName="toClientId" [matAutocomplete]="clientsAutocomplete">
            <mat-error *ngIf="makeAccountTransferForm.controls.toClientId.hasError('required')">
              {{ 'labels.heading.client' | translate }} <strong>{{"labels.commons.is required" | translate}}</strong>
            </mat-error>
          </mat-form-field>

          <mat-autocomplete autoActiveFirstOption #clientsAutocomplete="matAutocomplete" [displayWith]="displayClient">
            <mat-option *ngFor="let client of clientsData" [value]="client">
              {{ client.id }} - {{ client.displayName }}
            </mat-option>
          </mat-autocomplete>

          <mat-form-field fxFlex="98%">
            <mat-label>{{ 'labels.heading.accounttype' | translate }}</mat-label>
            <mat-select required formControlName="toAccountType" (selectionChange)="changeAccount()">
              <mat-option *ngFor="let toAccountType of toAccountTypeData" [value]="toAccountType.id">
                {{ toAccountType.value }}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="makeAccountTransferForm.controls.toAccountType.hasError('required')">
              {{ 'labels.heading.accounttype' | translate }} <strong>{{"labels.commons.is required" | translate}}</strong>
            </mat-error>
          </mat-form-field>

          <mat-form-field fxFlex="98%">
            <mat-label>{{ 'labels.heading.account' | translate }}</mat-label>
            <mat-select required formControlName="toAccountId" (selectionChange)="changeAccount()">
              <mat-option *ngFor="let toAccount of toAccountData" [value]="toAccount.id">
                {{ toAccount.productName }} - {{ toAccount.accountNo }}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="makeAccountTransferForm.controls.toAccountId.hasError('required')">
              {{ 'labels.heading.account' | translate }} <strong>{{"labels.commons.is required" | translate}}</strong>
            </mat-error>
          </mat-form-field>

          <mat-form-field fxFlex="98%">
            <mat-label>{{ 'labels.heading.amount' | translate }}</mat-label>
            <input decimalInput matInput required formControlName="transferAmount">
            <mat-error *ngIf="makeAccountTransferForm.controls.transferAmount.hasError('required')">
              {{ 'labels.heading.amount' | translate }} <strong>{{"labels.commons.is required" | translate}}</strong>
            </mat-error>
          </mat-form-field>

          <mat-form-field fxFlex="98%"  (click)="transferDatePicker.open()">
            <mat-label>{{ 'labels.heading.transactiondate' | translate }}</mat-label>
            <input matInput [min]="minDate" [max]="maxDate" [matDatepicker]="transferDatePicker" required
              formControlName="transferDate">
            <mat-datepicker-toggle matSuffix [for]="transferDatePicker"></mat-datepicker-toggle>
            <mat-datepicker #transferDatePicker></mat-datepicker>
            <mat-error *ngIf="makeAccountTransferForm.controls.transferDate.hasError('required')">
              {{ 'labels.heading.transactiondate' | translate }} <strong>{{"labels.commons.is required" | translate}}</strong>
            </mat-error>
          </mat-form-field>

          <mat-form-field fxFlex="98%">
            <mat-label>{{ 'labels.heading.description' | translate }}</mat-label>
            <textarea matInput formControlName="transferDescription"></textarea>
            <mat-error *ngIf="makeAccountTransferForm.controls.transferDescription.hasError('required')">
              {{ 'labels.inputs.transfer.description' | translate }} <strong>{{"labels.commons.is required" | translate}}</strong>
            </mat-error>

          </mat-form-field>

        </div>

      </form>

    </mat-card-content>

    <mat-card-actions fxLayout="row" fxLayout.xs="column" fxLayoutAlign="center" fxLayoutGap="5px">
      <button type="button" mat-raised-button [routerLink]="['../']">{{"labels.buttons.cancel" | translate}}</button>
      <button mat-raised-button color="primary" [disabled]="!makeAccountTransferForm.valid" *mifosxHasPermission="'CREATE_ACCOUNTTRANSFER'"
        (click)="submit()">{{"labels.buttons.submit" | translate}}</button>
    </mat-card-actions>

  </mat-card>

</div>
