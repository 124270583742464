<div class="container m-b-20" fxLayout="row" fxLayoutAlign="end" fxLayoutGap="20px">
  <div #buttonCreateUser class="in-block">
    <button mat-raised-button color="primary" [routerLink]="['create']">
      <fa-icon icon="plus"></fa-icon>&nbsp;&nbsp;
      {{'labels.buttons.newuser' | translate}}
    </button>
  </div>
</div>

<div class="container">

  <div #filter fxLayout="row" fxLayoutGap="20px">
    <mat-form-field fxFlex>
      <mat-label>{{'labels.buttons.filter' | translate}}</mat-label>
      <input matInput (keyup)="applyFilter($event.target.value)">
    </mat-form-field>
  </div>

  <div #usersTable class="mat-elevation-z8">

    <table mat-table [dataSource]="dataSource" matSort>

      <ng-container matColumnDef="firstname">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'labels.heading.firstname' | translate}} </th>
        <td mat-cell *matCellDef="let user"> {{ user.firstname }} </td>
      </ng-container>

      <ng-container matColumnDef="lastname">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'labels.heading.lastname' | translate}} </th>
        <td mat-cell *matCellDef="let user"> {{ user.lastname }} </td>
      </ng-container>

      <ng-container matColumnDef="email">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'labels.heading.email' | translate}} </th>
        <td mat-cell *matCellDef="let user"> {{ user.email }} </td>
      </ng-container>

      <ng-container matColumnDef="officeName">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'labels.heading.officeName' | translate}} </th>
        <td mat-cell *matCellDef="let user"> {{ user.officeName }} </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;" [routerLink]="[row.id]" class="select-row"></tr>

    </table>

    <mat-paginator [pageSizeOptions]="[10, 25, 50, 100]" showFirstLastButtons></mat-paginator>

  </div>

</div>

<ng-template #templateButtonCreateUser let-popover="popover">
  <h2>{{'labels.buttons.newuser' | translate}}</h2>
  <p class="mw300">{{'labels.heading.createUserInOrganization' | translate}}</p>
  <div fxLayout="row" fxLayoutAlign="end" fxLayoutGap="2%" fxLayout.lt-md="column">
    <button mat-raised-button color="warn"
      (click)="popover.close();configurationWizardService.closeConfigWizard()">{{'labels.buttons.close' |
      translate}}</button>
    <button mat-raised-button color="primary" (click)="popover.close();previousStep()">{{'labels.buttons.back' |
      translate}}</button>
    <button mat-raised-button color="primary"
      (click)="popover.close();showPopover(templateFilter, filter, 'bottom', true)">{{'labels.buttons.next' |
      translate}}</button>
  </div>
</ng-template>

<ng-template #templateFilter let-popover="popover">
  <h4>{{'labels.heading.searchUsers' | translate}}</h4>
  <div fxLayout="row" fxLayoutAlign="end" fxLayoutGap="2%" fxLayout.lt-md="column">
    <button mat-raised-button color="warn"
      (click)="popover.close();configurationWizardService.closeConfigWizard()">{{'labels.buttons.close' |
      translate}}</button>
    <button mat-raised-button color="primary"
      (click)="popover.close();showPopover(templateButtonCreateUser, buttonCreateUser, 'bottom', true)">{{'labels.buttons.back'
      |
      translate}}</button>
    <button mat-raised-button color="primary"
      (click)="popover.close();showPopover(templateUsersTable, usersTable, 'top', true)">{{'labels.buttons.next' |
      translate}}</button>
  </div>
</ng-template>

<ng-template #templateUsersTable let-popover="popover">
  <h4>{{'labels.heading.listOfUsers' | translate}}</h4>
  <div fxLayout="row" fxLayoutAlign="end" fxLayoutGap="2%" fxLayout.lt-md="column">
    <button mat-raised-button color="warn"
      (click)="popover.close();configurationWizardService.closeConfigWizard()">{{'labels.buttons.close' |
      translate}}</button>
    <button mat-raised-button color="primary"
      (click)="popover.close();showPopover(templateFilter, filter, 'bottom', true)">{{'labels.buttons.back' |
      translate}}</button>
    <button mat-raised-button color="primary" (click)="popover.close();nextStep()">{{'labels.buttons.next' |
      translate}}</button>
  </div>
</ng-template>