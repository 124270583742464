<form [formGroup]="whatsappForm">
  <h1 mat-dialog-title>{{"labels.whatsAppMessage" | translate}}  <img src="https://static.whatsapp.net/rsrc.php/v3/yP/r/rYZqPCBaG70.png" alt="whatsapp" ></h1>
  <div mat-dialog-content>
      <mat-form-field style="width:100%" appearance="fill">
        <mat-label>{{"labels.phoneNumber" | translate}}</mat-label>
        <input matInput formControlName="phone" placeholder="21261234567" required>
        <mat-error *ngIf="whatsappForm.controls.phone.hasError('required')">
          {{"labels.phoneNumber" | translate}} <strong>{{"labels.commons.is required" | translate}}</strong>
        </mat-error>
        <mat-error *ngIf="whatsappForm.controls.phone.hasError('pattern')">
          {{"labels.invalidPhoneNumber" | translate}}
        </mat-error>
      </mat-form-field>
      <mat-form-field style="width:100%" appearance="fill">
        <mat-label>{{"labels.heading.message" | translate}}</mat-label>
        <textarea  matInput formControlName="message" required></textarea>
        <mat-error *ngIf="whatsappForm.controls.message.hasError('required')">
          {{"labels.heading.message" | translate}} <strong>{{"labels.commons.is required" | translate}}</strong>
        </mat-error>
      </mat-form-field>
  </div>
  <div mat-dialog-actions>
    <button mat-button (click)="onCancelClick()">{{"labels.buttons.cancel" | translate}}</button>
    <button mat-button cdkFocusInitial [disabled]="!whatsappForm.valid" (click)="onSendClick()">{{"labels.send" | translate}}</button>
  </div>
</form>
