<h1 mat-dialog-title>{{"labels.viewJournalEntry" | translate}}</h1>

<div mat-dialog-content>

  <table>

    <tr>
      <th>{{"labels.inputs.office" | translate}}</th>
      <td>{{ data.journalEntry.officeName }}</td>
    </tr>

    <tr>
      <th>{{"labels.inputs.entryid" | translate}}</th>
      <td>{{ data.journalEntry.id }}</td>
    </tr>

    <tr>
      <th>{{"labels.inputs.transactionid" | translate}}</th>
      <td>{{ data.journalEntry.transactionId }}</td>
    </tr>

    <tr>
      <th>{{"labels.heading.transactiondate" | translate}}</th>
      <td>{{ data.journalEntry.transactionDate }}</td>
    </tr>

    <tr>
      <th>{{"labels.inputs.type" | translate}}</th>
      <td>{{ data.journalEntry.glAccountType.value }}</td>
    </tr>

    <tr>
      <th>{{"labels.accountCode" | translate}}</th>
      <td>{{ data.journalEntry.glAccountId }}</td>
    </tr>

    <tr>
      <th>{{"labels.inputs.accountname" | translate}}</th>
      <td>{{ data.journalEntry.glAccountName }}</td>
    </tr>

    <tr>
      <th>
        <span *ngIf="data.journalEntry.entryType.value === 'CREDIT'">{{"labels.heading.credit" | translate}}</span>
        <span *ngIf="data.journalEntry.entryType.value === 'DEBIT'">{{"labels.heading.debit" | translate}}</span>
      </th>
      <td>
        {{ (data.journalEntry.currency.displaySymbol || data.journalEntry.currency.code) + ' ' + data.journalEntry.amount }}
      </td>
    </tr>

    <tr *ngIf="data.journalEntry.referenceNumber">
      <th>{{"labels.inputs.referencenumber" | translate}}</th>
      <td>{{ data.journalEntry.referenceNumber }}</td>
    </tr>

    <tr *ngIf="data.journalEntry.comments">
      <th>{{"labels.heading.comments" | translate}}</th>
      <td>{{ data.journalEntry.comments }}</td>
    </tr>

    <tr *ngIf="data.journalEntry.transactionDetails.paymentDetails">
      <th colspan="2" class="header">{{"labels.paymentDetails" | translate}}</th>
    </tr>

    <tr *ngIf="data.journalEntry.transactionDetails.paymentDetails?.paymentType">
      <th>{{"labels.heading.paymenttype" | translate}}</th>
      <td>{{ data.journalEntry.transactionDetails.paymentDetails.paymentType.name }}</td>
    </tr>

    <tr *ngIf="data.journalEntry.transactionDetails.paymentDetails?.accountNumber">
      <th>{{"labels.inputs.accno" | translate}}</th>
      <td>{{ data.journalEntry.transactionDetails.paymentDetails.accountNumber }}</td>
    </tr>

    <tr *ngIf="data.journalEntry.transactionDetails.paymentDetails?.checkNumber">
      <th>{{"labels.chequeNumber" | translate}}</th>
      <td>{{ data.journalEntry.transactionDetails.paymentDetails.checkNumber }}</td>
    </tr>

    <tr *ngIf="data.journalEntry.transactionDetails.paymentDetails?.routingCode">
      <th>{{"labels.inputs.routingcode" | translate}}</th>
      <td>{{ data.journalEntry.transactionDetails.paymentDetails.routingCode }}</td>
    </tr>

    <tr *ngIf="data.journalEntry.transactionDetails.paymentDetails?.receiptNumber">
      <th>{{"labels.receiptNumber" | translate}}</th>
      <td>{{ data.journalEntry.transactionDetails.paymentDetails.receiptNumber }}</td>
    </tr>

    <tr *ngIf="data.journalEntry.transactionDetails.paymentDetails?.bankNumber">
      <th>{{"labels.bankNumber" | translate}}</th>
      <td>{{ data.journalEntry.transactionDetails.paymentDetails.bankNumber }}</td>
    </tr>

    <tr>
      <th>{{"labels.heading.createdby" | translate}}</th>
      <td>{{ data.journalEntry.createdByUserName }}</td>
    </tr>

    <tr>
      <th>{{"labels.inputs.submittedon" | translate}}</th>
      <td>{{ data.journalEntry.submittedOnDate }}</td>
    </tr>

  </table>

</div>

<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>{{"labels.buttons.close" | translate}}</button>
</mat-dialog-actions>
