<div class="tab-container mat-typography" *ngIf="loans.length > 0">

  <div fxLayout="row" fxLayoutAlign="start center">
    <div class="search-box" fxFlex="18%">
      <mat-form-field appearance="fill" fxFlex="100%">
        <input matInput [formControl]="fromPrincipalFilter" type="number" min="0" (keyup)="applyFilters()"
          [placeholder]="'labels.fromAmount'| translate">
      </mat-form-field>
    </div>
    <div class="search-box" fxFlex="18%">
      <mat-form-field appearance="fill" fxFlex="100%">
        <input matInput [formControl]="toPrincipalFilter" type="number" [min]="fromPrincipalFilter.value"
          (keyup)="applyFilters()" [placeholder]="'labels.toAmount'| translate">
      </mat-form-field>
    </div>
    <div fxFlex="59%">
      <div class="export">
        <button mat-raised-button color="success" *mifosxHasPermission="'APPROVE_LOAN'" (click)="approveLoan()">
          <fa-icon icon="check"></fa-icon>&nbsp;&nbsp;{{"labels.anchors.approve" | translate}}
        </button>
        <button mat-raised-button color="success" (click)="exportexcel()">
          <fa-icon icon="file-excel"></fa-icon>&nbsp;&nbsp;{{'labels.buttons.exportexcel' | translate}}
        </button>
      </div>
    </div>
  </div>



  <table mat-table [dataSource]="dataSource">

    <ng-container matColumnDef="select">
      <th mat-header-cell *matHeaderCellDef>
        <mat-checkbox (change)="$event ? masterToggle(filteredLoans) : null"
          [checked]="selection.hasValue() && isAllSelected(filteredLoans)"
          [indeterminate]="selection.hasValue() && !isAllSelected(filteredLoans)"
          [aria-label]="checkboxLabel(filteredLoans)">
        </mat-checkbox>
      </th>
      <td mat-cell *matCellDef="let row">
        <mat-checkbox (click)="$event.stopPropagation()" (change)="$event ? selection.toggle(row) : null"
          [checked]="selection.isSelected(row)" [aria-label]="checkboxLabel(row)">
        </mat-checkbox>
      </td>
    </ng-container>

    <ng-container matColumnDef="clientName">
      <th mat-header-cell *matHeaderCellDef> {{"labels.inputs.client" | translate}}

        <mat-form-field appearance="fill">
          <input matInput [formControl]="clientFilter" (keyup)="applyFilters()" [placeholder]="'labels.buttons.search'| translate">
        </mat-form-field>
      </th>
      <td mat-cell *matCellDef="let loan" class="view-details"
        [routerLink]="['/clients', loan.clientId]">
        {{ loan.clientName }}
      </td>

    </ng-container>

    <ng-container matColumnDef="loanAccount">
      <th mat-header-cell *matHeaderCellDef> {{"labels.inputs.account" | translate}}
        <mat-form-field appearance="fill">
          <input matInput [formControl]="loanAccountFilter" (keyup)="applyFilters()" [placeholder]="'labels.buttons.search'| translate">
        </mat-form-field>
      </th>
      <td mat-cell *matCellDef="let loan" class="view-details"
        [routerLink]="['/clients', loan.clientId, 'loans-accounts', loan.id]">
        {{loan.accountNo}}
      </td>
    </ng-container>

    <ng-container matColumnDef="loanProduct">
      <th mat-header-cell *matHeaderCellDef> {{"labels.inputs.product" | translate}}

        <mat-form-field appearance="fill">
          <mat-select [formControl]="productFilter" (selectionChange)="applyFilters()" [placeholder]="'labels.buttons.search'| translate">
            <mat-option value="">
              {{"labels.menus.All" | translate}}
            </mat-option>
            <mat-option *ngFor="let product of products" [value]="product">
              {{ product }}
            </mat-option>
          </mat-select>
        </mat-form-field>

      </th>
      <td mat-cell *matCellDef="let loan" class="view-details"
        [routerLink]="['/clients', loan.clientId, 'loans-accounts', loan.id]">
        {{ loan.loanProductName}}
      </td>
    </ng-container>

    <ng-container matColumnDef="amount">
      <th mat-header-cell *matHeaderCellDef> {{"labels.heading.amount" | translate}}

        <mat-form-field appearance="fill">
          <input matInput [formControl]="principalFilter" type="number" min="0" (keyup)="applyFilters()"
            [placeholder]="'labels.buttons.search'| translate">
        </mat-form-field>

      </th>
      <td mat-cell *matCellDef="let loan"> {{ loan.principal | number}} </td>
    </ng-container>

    <ng-container matColumnDef="loanAgency">
      <th mat-header-cell *matHeaderCellDef> {{"labels.loanAgency" | translate}}
        <mat-form-field appearance="fill">
          <mat-select [formControl]="agencyFilter" (selectionChange)="applyFilters()" [placeholder]="'labels.buttons.search'| translate">
            <mat-option value="">
              {{"labels.menus.All" | translate}}
            </mat-option>
            <mat-option *ngFor="let agency of agencies" [value]="agency">
              {{ agency }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </th>
      <td mat-cell *matCellDef="let loan"> {{ loan.agency }} </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="selection.toggle(row)">
    </tr>
  </table>
  <mat-paginator [pageSizeOptions]="[10, 20, 30]" showFirstLastButtons aria-label="Select page of periodic elements">
  </mat-paginator>


</div>

<div class="alert" *ngIf="loans.length === 0">

  <div class="message">
    <i class="fa fa-exclamation-circle alert-check"></i>
    {{"labels.noPendingLoanAvailableDisbursal" | translate}}.
  </div>

</div>
