/** Angular Imports */
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SelectionModel } from '@angular/cdk/collections';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialog } from '@angular/material/dialog';

/** Custom Services */
import { TasksService } from '../../tasks.service';
import { SettingsService } from 'app/settings/settings.service';

/** Dialog Components */
import { ConfirmationDialogComponent } from 'app/shared/confirmation-dialog/confirmation-dialog.component';
import { Dates } from 'app/core/utils/dates';

@Component({
  selector: 'mifosx-account-activation',
  templateUrl: './account-activation.component.html',
  styleUrls: ['./account-activation.component.scss']
})
export class AccountActivationComponent implements OnInit{

    /** Loans Data */
    accounts: any;
    /** Batch Requests */
    batchRequests: any[];
    /** Datasource for loans disbursal table */
    dataSource: MatTableDataSource<any>;
    /** Row Selection */
    selection: SelectionModel<any>;
    /** Displayed Columns for loan disbursal data */
    displayedColumns: string[] = ['select', 'client', 'savingsAccountNumber'];

    /**
     * Retrieves the loans data from `resolve`.
     * @param {ActivatedRoute} route Activated Route.
     * @param {Dialog} dialog MatDialog.
     * @param {Dates} dateUtils Date Utils.
     * @param {router} router Router.
     * @param {SettingsService} settingsService Settings Service.
     * @param {TasksService} tasksService Tasks Service.
     */
    constructor(private route: ActivatedRoute,
      private dialog: MatDialog,
      private dateUtils: Dates,
      private settingsService: SettingsService,
      private tasksService: TasksService) {
      this.route.data.subscribe((data: { accountData: any }) => {
        this.accounts = data.accountData.pageItems;
        this.dataSource = new MatTableDataSource(this.accounts);
        this.selection = new SelectionModel(true, []);
      });
    }
    
    ngOnInit() {
      this.dataSource.filterPredicate = function (record,filter) {
        if(typeof record.clientName === 'string')
          return record.clientName.toLowerCase().indexOf(filter)!=-1;
        return false
        } 
      }
  
    /** Whether the number of selected elements matches the total number of rows. */
    isAllSelected() {
      const numSelected = this.selection.selected.length;
      const numRows = this.dataSource.data.length;
      return numSelected === numRows;
    }

    /** Selects all rows if they are not all selected; otherwise clear selection. */
    masterToggle() {
      this.isAllSelected() ?
        this.selection.clear() :
        this.dataSource.data.forEach((row: any) => this.selection.select(row));
    }

    /** The label for the checkbox on the passed row */
    checkboxLabel(row?: any): string {
      if (!row) {
        return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
      }
      return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.position + 1}`;
    }

    activateAccount() {
      const activateAccountDialogRef = this.dialog.open(ConfirmationDialogComponent, {
        data: { heading: 'labels.heading.accountactivation', dialogContext: 'labels.areYouSureYouWantToActivateAccount' }
      });
      activateAccountDialogRef.afterClosed().subscribe((response: { confirm: any }) => {
        if (response.confirm) {
          this.bulkAccountActivation();
        }
      });
    }

    bulkAccountActivation() {
      const dateFormat = 'yyyy-MM-dd';
      const activatedOnDate = this.dateUtils.formatDate(new Date(), dateFormat);
      const locale = this.settingsService.language.code;
      const formData = {
        dateFormat,
        activatedOnDate,
        locale
      };
      const selectedAccounts = this.selection.selected.length;
      const listSelectedAccounts = this.selection.selected;
      let approvedAccounts = 0;
      this.batchRequests = [];
      let reqId = 1;
      listSelectedAccounts.forEach((element: any) => {
        const url = 'savingsaccounts/' + element.id + '?command=activate';
        const bodyData = JSON.stringify(formData);
        const batchData = { requestId: reqId++, relativeUrl: url, method: 'POST', body: bodyData };
        this.batchRequests.push(batchData);
      });
      this.tasksService.submitBatchData(this.batchRequests).subscribe((response: any) => {
        console.log(response);
        response.forEach((responseEle: any) => {
          if (responseEle.statusCode = '200') {
            approvedAccounts++;
            responseEle.body = JSON.parse(responseEle.body);
            if (selectedAccounts === approvedAccounts) {
              this.savingsResource();
            }
          }
        });
      });
    }

    savingsResource() {
      this.tasksService.getAllSavingsAcativate().subscribe((response: any) => {
        this.accounts = response.pageItems;
        this.dataSource = new MatTableDataSource(this.accounts);
        this.selection = new SelectionModel(true, []);
      });
    }

    applyFilter(filterValue: string = '') {
      this.dataSource.filter = filterValue.trim().toLowerCase();
    }

}
