/** Angular Imports */
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

/**
 * Custom Services
 */
import { ClientsService } from 'app/clients/clients.service';
import { Dates } from 'app/core/utils/dates';
import { ProductsService } from 'app/products/products.service';
import { SettingsService } from 'app/settings/settings.service';


@Component({
  selector: 'mifosx-add-client-credit-line',
  templateUrl: './add-client-credit-line.component.html',
  styleUrls: ['./add-client-credit-line.component.scss']
})
export class AddClientCreditLineComponent implements OnInit {

  /** Client CreditLine Form */
  clientCreditLineForm: FormGroup;
  /** Client CreditLine Options */
  clientProductLinesOptions: any;
  /** Client Id */
  clientId: any;
  /** CreditLine Details */
  creditLineDetails: any;
  minDate = new Date();
  clientLCs: any;
  creditLineParents: any = [];

  /**
   * Retirives CreditLine Form from `resolve`
   * @param {FormBuilder} formBuilder Form bUilder.
   * @param {ActivatedRoute} route Activated Route.
   * @param {Router} router Router.
   * @param {ProductsService} productsService Products Service
   */
  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private productsService: ProductsService,
    private clientsService: ClientsService,
    private settingsService: SettingsService,
    private dateUtils:Dates) {
      this.route.data.subscribe((data: { clientActionData: any }) => {
        this.clientProductLinesOptions = data.clientActionData;
      });
      this.clientId = this.route.parent.snapshot.params['clientId'];
    }

  ngOnInit(): void {
    this.createClientCreditLineForm();
    this.buildDependencies();
    this.clientsService.getCreditLinesTemplate(this.clientId).subscribe((data:any)=>{
      this.clientLCs = data;
      this.clientProductLinesOptions = this.clientProductLinesOptions.filter((item:any)=>{
        if (item.unique){
          return !this.clientLCs.find((lc:any)=>(lc.productLine.id===item.id && lc.status !==500 && lc.status !==600));
        }
        return true;
      })
    })
  }

  /**
   * Subscribe to Form controls value changes
   */
  buildDependencies() {

    this.clientCreditLineForm.controls.productLine.valueChanges.subscribe((productLine: any) => {
      let processingEndDate= new Date();
      processingEndDate.setDate(processingEndDate.getDate()+productLine.processingDays)
      this.clientCreditLineForm.patchValue({
        'limitAmount': productLine.limitAmount,
        'processingEndDate': processingEndDate,
      });
      this.creditLineDetails = productLine;

      const parents = this.clientLCs.filter((item:any)=>
      item.productLine.id===productLine.parent
      && item.status===300
      )
      const unique = this.clientProductLinesOptions.find((item:any)=>item.id===productLine.parent)?.unique;
      if(parents.length>0 && unique===false){
        this.clientCreditLineForm.addControl('parentId',this.formBuilder.control(null));
        this.creditLineParents = parents;
      }else{
        this.creditLineParents = [];
        this.clientCreditLineForm.removeControl('parentId');
      }

    });
  }

  /**
   * Creates the Clients CreditLines Form
   */
  createClientCreditLineForm() {
    this.clientCreditLineForm = this.formBuilder.group({
      'limitAmount': [ '', Validators.required ],
      'productLine': [ undefined, Validators.required ],
      'appliedOnDate':[new Date(),Validators.required],
      'processingEndDate':[new Date(),Validators.required],
      'motivation':['',Validators.required],
    });
  }

  /**
   * Submits Client CreditLine
   */
  submit() {
    const dateFormat = this.settingsService.dateFormat;
    var creditLine = {
      limitAmount : this.clientCreditLineForm.value.limitAmount,
      appliedOnDate: this.dateUtils.formatDate(this.clientCreditLineForm.value.appliedOnDate, dateFormat),
      processingEndDate: this.dateUtils.formatDate(this.clientCreditLineForm.value.processingEndDate, dateFormat),
      applyReason: this.clientCreditLineForm.value.motivation,
      productLineId: this.clientCreditLineForm.value.productLine.id,
      locale: this.settingsService.language.code,
      dateFormat: this.settingsService.dateFormat,
      parentId : this.clientCreditLineForm.value.parentId
    }
    this.clientsService.createClientCreditLine(this.clientId,creditLine).subscribe(() => {
      this.router.navigate(['../../'], {relativeTo: this.route});
    });
  }

}
