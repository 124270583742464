<div fxLayout="row" fxLayoutAlign="end" fxLayoutGap="2%" fxLayout.lt-md="column" class="container m-b-20">

  <div #buttonEdit class="in-block">
    <button mat-raised-button color="primary" (click)="editTask(); backupCheckValues();" *mifosxHasPermission="'UPDATE_PERMISSION'">
      <fa-icon icon="edit"></fa-icon>&nbsp;&nbsp;{{"labels.inputs.edit" | translate }}</button>
  </div>
</div>

<div class="container">

  <mat-card>

    <ng-container>

      <div fxLayout="row">

        <div #mcTable fxFlex="30" fxLayout="column">
          <mat-list>
            <mat-list-item [ngClass]="selectedItem == grouping ? 'active' : 'inactive'" *ngFor="let grouping of groupings" (click)="showPermissions(grouping)">
              <span class="groupingName">
                {{ formatName(grouping) }}
              </span>
            </mat-list-item>
          </mat-list>
        </div>

        <mat-divider [vertical]="true"></mat-divider>

        <div fxFlex="70" fxLayout="column" class="listPermission">
          <form [formGroup]="formGroup" (submit)="submit()">

            <div *ngFor="let permission of permissions.permissions" class="displayPermissions">
                <div formArrayName="roster">
                  <div [formGroupName]="permission.id">
                    <mat-checkbox name="cp" id="{{ permission.code }}" formControlName="selected">
                    {{ permissionName(permission.code) }}
                  </mat-checkbox>
                  </div>
                </div>
              </div>

            </form>
        </div>

      </div>
    </ng-container>

    <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="center" fxLayoutGap="5px" *ngIf="!isDisabled">
      <mat-card-actions>
        <button type="button" mat-raised-button (click)="cancel(); restoreCheckboxes();">{{"labels.buttons.cancel" | translate}}</button>
        <button mat-raised-button color="primary" (click)="submit()" *mifosxHasPermission="'UPDATE_PERMISSION'">{{"labels.buttons.submit" | translate}}</button>
      </mat-card-actions>
    </div>

  </mat-card>

</div>

<ng-template #templateButtonEdit let-popover="popover">
  <h2>{{"labels.inputs.edit" | translate }}</h2>
  <p class="mw300">{{"labels.toAddRemoveTaskFromMakerChecker" | translate}}</p>
  <div fxLayout="row" fxLayoutAlign="end" fxLayoutGap="2%" fxLayout.lt-md="column">
    <button mat-raised-button color="warn" (click)="popover.close();configurationWizardService.closeConfigWizard()">{{"labels.buttons.close" | translate}}</button>
    <button mat-raised-button color="primary" (click)="popover.close();previousStep()">{{"labels.buttons.back" | translate}}</button>
    <button mat-raised-button color="primary" (click)="popover.close();showPopover(templateMcTable,mcTable,'top', true);">{{"labels.buttons.next" | translate}}</button>
  </div>
</ng-template>

<ng-template #templateMcTable let-popover="popover">
  <h4 class="mw300">{{"labels.listOfAllMakerCheckerTasks" | translate}} {{"labels.forMoreDetailsClick" | translate}} <a href="" target="_blank">{{"labels.configureMakerCheckerTasks" | translate}}</a></h4>
  <div fxLayout="row" fxLayoutAlign="end" fxLayoutGap="2%" fxLayout.lt-md="column">
    <button mat-raised-button color="warn" (click)="popover.close();configurationWizardService.closeConfigWizard()">{{"labels.buttons.close" | translate}}</button>
    <button mat-raised-button color="primary" (click)="popover.close();showPopover(templateButtonEdit,buttonEdit,'bottom', true);">{{"labels.buttons.back" | translate}}</button>
    <button mat-raised-button color="primary" (click)="popover.close();nextStep()">{{"labels.buttons.next" | translate}}</button>
  </div>
</ng-template>
