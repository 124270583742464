<div class="container m-b-20" fxLayout="row" fxLayoutAlign="end" fxLayoutGap="20px">
    <button mat-raised-button color="primary" [routerLink]="['create']" *mifosxHasPermission="'CREATE_TAXCOMPONENT'">
      <fa-icon icon="plus"></fa-icon>&nbsp;&nbsp;
      {{"labels.heading.createtaxcomponent" | translate}}
    </button>
  </div>

  <div class="container">

    <div fxLayout="row">
      <mat-form-field fxFlex>
        <mat-label>{{"labels.buttons.filter" | translate}}</mat-label>
        <input matInput (keyup)="applyFilter($event.target.value)">
      </mat-form-field>
    </div>

    <div class="mat-elevation-z8">

      <table mat-table [dataSource]="dataSource" matSort>

        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>{{"labels.inputs.name" | translate}}</th>
          <td mat-cell *matCellDef="let taxComponent"> {{ taxComponent.name }} </td>
        </ng-container>

        <ng-container matColumnDef="percentage">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> {{"labels.inputs.percentage" | translate}} </th>
          <td mat-cell *matCellDef="let taxComponent"> {{ taxComponent.percentage }} </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;" [routerLink]="[row.id]" class="select-row"></tr>

      </table>

      <mat-paginator [pageSizeOptions]="[10, 25, 50, 100]" showFirstLastButtons></mat-paginator>

    </div>

  </div>
