/** Angular Imports */
import { Component, OnInit, TemplateRef, ElementRef, ViewChild, AfterViewInit } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MatTreeNestedDataSource } from '@angular/material/tree';
import { NestedTreeControl } from '@angular/cdk/tree';
import { FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

/** rxjs Imports */
import { of } from 'rxjs';

/** Custom Models */

/** Custom Services */

/**
 * Chart of accounts component.
 */


//

interface LcChildren {
  id: number,
  loanProducts: any[],
  name: string,
  limitAmount: number,
  parent: number,
  processingDays: number,
  revolving: Boolean,
  unique: Boolean,
  children: LcChildren[],
  currency: string

}

@Component({
  selector: 'mifosx-product-lines',
  templateUrl: './product-lines.component.html',
  styleUrls: ['./product-lines.component.scss']
})
export class ProductLinesComponent implements AfterViewInit, OnInit {

  productLines: any = [];

  viewGroup = new FormControl('listView');
  glAccountData: any = [];
  displayedColumns: string[] = ['name', 'limitAmount', 'loanProducts', 'parent', 'revolving', 'unique', 'processingDays', 'validityDays'];
  tableDataSource: MatTableDataSource<any>;
  nestedTreeControl: NestedTreeControl<LcChildren>;
  nestedTreeDataSource: MatTreeNestedDataSource<LcChildren>;
  productLine: LcChildren;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  @ViewChild('buttonTreeView') buttonTreeView: ElementRef<any>;
  @ViewChild('templateButtonTreeView') templateButtonTreeView: TemplateRef<any>;
  @ViewChild('accountsTable') accountsTable: ElementRef<any>;
  @ViewChild('templateAccountsTable') templateAccountsTable: TemplateRef<any>;

  constructor(
    private route: ActivatedRoute,
    private router: Router) {
    this.nestedTreeControl = new NestedTreeControl<LcChildren>(this._getChildren);
    this.nestedTreeDataSource = new MatTreeNestedDataSource<LcChildren>();
    this.route.data.subscribe((data: { productLines: any }) => {
      this.productLines = data.productLines;

      this.nestedTreeDataSource.data = this.generateLCTree(this.productLines);
      this.nestedTreeControl.expand(this.nestedTreeDataSource.data[0]);
      this.nestedTreeControl.dataNodes = this.generateLCTree(this.productLines);

      this.productLines = this.productLines.map((item: any) => ({
        ...item,
        parent: this.productLines.find((gl: any) => gl.id === item.parent)
      }))
      this.tableDataSource = new MatTableDataSource(this.productLines);


    });

  }

  generateLCTree(items: any, parent: any = undefined) {
    const result: any[] = [];
    items.forEach((item: any) => {
      if (item.parent === parent) {
        const children = this.generateLCTree(items, item.id)
        if (children.length) {
          item.children = children
        } else {
          item.children = []
        }
        result.push(item)
      }
    });
    return result;
  }
  ngOnInit() {



  }


  ngAfterViewInit() {
    this.tableDataSource.paginator = this.paginator;

    this.tableDataSource.sort = this.sort;
  }


  applyFilter(filterValue: string) {
    this.tableDataSource.filter = filterValue.trim().toLowerCase();
  }


  viewLcChildren(pl: LcChildren) {

      this.productLine = pl;
  }

  hasNestedChild = (_: number, node: LcChildren) => node.children.length;


  private _getChildren = (node: LcChildren) => of(node.children);


  showPopover(template: TemplateRef<any>, target: HTMLElement | ElementRef<any>, position: string, backdrop: boolean): void {
    // setTimeout(() => this.popoverService.open(template, target, position, backdrop, {}), 200);
  }
}

