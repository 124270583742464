<h1 mat-dialog-title>{{"labels.welcomeTo" | translate}} Bank of Africa</h1>

<mat-dialog-content>

<h3 class="mw600">{{"labels.followingGuideTourWillHelp" | translate: {'name' : appName} }}</h3>

<h3>{{"labels.youHaveSuccessfullyCompletedSetupConfigurationW" | translate}}</h3>

<h3>{{"labels.progressBar" | translate}} : 100 %</h3>

<mat-progress-bar mode="determinate" value="100"></mat-progress-bar>

</mat-dialog-content>

<mat-dialog-actions>
    <button mat-raised-button color="warn" mat-dialog-close>{{"labels.closeConfigW" | translate}}</button>
</mat-dialog-actions>
