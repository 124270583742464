<div class="tab-container mat-typography">

  <router-outlet>

    <h3>{{ 'labels.heading.familymembers' | translate }}</h3>

    <div fxLayout="row" fxLayoutAlign="flex-end">
      <button mat-raised-button color="primary" [routerLink]="['./add']">
        <fa-icon icon="plus"></fa-icon>&nbsp;&nbsp;{{ 'labels.buttons.add' | translate }}
      </button>
    </div>

    <mat-accordion>

      <mat-expansion-panel *ngFor="let member of clientFamilyMembers;index as i" class="family-member">

        <mat-expansion-panel-header>
          <mat-panel-title>
            {{member.firstName+' '+member.middleName+' '+member.lastName}}
          </mat-panel-title>
          <mat-panel-description>
            {{member.relationship}}
          </mat-panel-description>
        </mat-expansion-panel-header>

        <mat-divider [inset]="true"></mat-divider>

        <div class="family-member-actions" fxLayout="row" fxLayoutAlign="flex-end">
          <button mat-button color="primary">
            <fa-icon icon="edit" [routerLink]="[member.id,'edit']"></fa-icon>
          </button>
          <button mat-button color="warn" (click)="deleteFamilyMember(member.clientId,member.id,member.firstName,i)">
            <fa-icon icon="trash"></fa-icon>
          </button>
        </div>

        <p>
          {{ 'labels.inputs.firstname' | translate }} : {{member.firstName}}<br />
          {{ 'labels.inputs.middlename' | translate }} : {{member.middleName}}<br />
          {{ 'labels.inputs.lastname' | translate }} : {{member.lastName}}<br />
          {{ 'labels.inputs.qualification' | translate }} : {{member.qualification}}<br />
          {{ 'labels.inputs.mobileNumber' | translate }} : {{member.mobileNumber}}<br />
          {{ 'labels.inputs.age' | translate }} : {{member.age}}<br />
          {{ 'labels.inputs.isDependent' | translate }} : {{member.isDependent}}<br />
          {{ 'labels.inputs.maritalstatus' | translate }} : {{member.maritalStatus}}<br />
          {{ 'labels.inputs.gender' | translate }} : {{member.gender}}<br />
          {{ 'labels.inputs.profession' | translate }} : {{member.profession}}<br />
          {{ 'labels.inputs.dateofbirth' | translate }} : {{member.dateOfBirth  | dateFormat}}<br />
        </p>

      </mat-expansion-panel>

    </mat-accordion>

  </router-outlet>

</div>
