<mat-card class="container">

  <div fxLayout="row" fxLayout.lt-md="column">

    <div fxFlex="65%">
      <div fxLayout="column">
        <mat-form-field>
          <mat-label>{{"labels.nameOrganization" | translate}}</mat-label>
          <input matInput [formControl]="organizationName">
        </mat-form-field>
      </div>
      <div fxLayout="column">
        <mat-form-field id="server-selector">
          <mat-label>MetaBase</mat-label>
          <mat-select [formControl]="metabaseid">
            <mat-option *ngFor="let db of metabses" [value]="db.id">
              {{ db.name}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div fxLayout="column">
        <span fxFlex="10%" fxFlexAlign="center">
          <button mat-raised-button color="primary" (click)="onSubmit()">{{'labels.buttons.submit' |
            translate}}</button>
        </span>
      </div>
    </div>
    <div fxFlex="35%" class="row">
      <div fxLayout="column">

        <span class="header">{{"labels.logo" | translate}}</span>
        <img [src]="currentTenant.logo ? tenantLogo: 'assets/images/empty_picture.png'" />
      </div>
      <div fxLayout="column">
        <span fxFlex="10%" fxFlexAlign="center">
          <button mat-button (click)="uploadLogo()">
            <fa-icon icon="upload" size="lg"></fa-icon>
            &nbsp;&nbsp;
            {{"labels.anchors.uploadImage" | translate}}
          </button>
        </span>
      </div>

    </div>
  </div>




</mat-card>
