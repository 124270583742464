<div class="container m-b-20" fxLayout="row" fxLayoutGap="20px">
    <div #schedulerStatus>
    <h2 class="no-m">{{"labels.heading.schedulerstatus" | translate}}: {{ schedulerActive ? 'Active' : 'Inactive' }}</h2>
    </div>
    <button mat-raised-button class="suspend">
      <fa-icon icon="times-circle"></fa-icon>&nbsp;&nbsp;
      {{"labels.buttons.suspend" | translate}}
    </button>
    <button mat-raised-button class="activate" (click)="activateScheduler()" *ngIf="!schedulerActive">
      <fa-icon icon="times-circle"></fa-icon>&nbsp;&nbsp;
      {{"labels.anchors.activate" | translate}}
    </button>

</div>

<div class="container">

  <div #filter fxLayout="row" fxLayoutGap="20px">
    <mat-form-field fxFlex>
      <mat-label>&nbsp;&nbsp;{{"labels.buttons.filter" | translate }}</mat-label>
      <input matInput (keyup)="applyFilter($event.target.value)">
    </mat-form-field>
  </div>

  <div class="container space-top" fxLayout="row" fxLayoutGap="20px">
    <button mat-raised-button color="primary" (click)="runSelectedJobs()">
      <fa-icon icon="play"></fa-icon>&nbsp;&nbsp;
      {{"labels.buttons.runselectedjobs" | translate}}
    </button>
  </div>

  <div #jobsTable class="mat-elevation-z8 space-top">

    <table mat-table [dataSource]="dataSource" matSort>

      <ng-container matColumnDef="select">
        <th mat-header-cell *matHeaderCellDef>
          <mat-checkbox (change)="$event ? masterToggle() : null" [checked]="selection.hasValue() && isAllSelected()"
            [indeterminate]="selection.hasValue() && !isAllSelected()">
          </mat-checkbox>
        </th>
        <td mat-cell *matCellDef="let row">
          <mat-checkbox (click)="$event.stopPropagation()" (change)="$event ? selection.toggle(row) : null"
            [checked]="selection.isSelected(row)">
          </mat-checkbox>
        </td>
      </ng-container>

      <ng-container matColumnDef="displayName">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>{{"labels.inputs.name" | translate }}</th>
        <td mat-cell *matCellDef="let job"> {{ job.displayName }} </td>
      </ng-container>

      <ng-container matColumnDef="nextRunTime">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{"labels.heading.nextrun" | translate}} </th>
        <td mat-cell *matCellDef="let job"> {{ job.nextRunTime }} </td>
      </ng-container>

      <ng-container matColumnDef="previousRunTime">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{"labels.heading.previousrun" | translate}} </th>
        <td mat-cell *matCellDef="let job"><span *ngIf="job.lastRunHistory">{{ job.lastRunHistory.jobRunStartTime
            }}</span></td>
      </ng-container>

      <ng-container matColumnDef="previousRunStatus">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{"labels.heading.previousrunstatus" | translate}} </th>
        <td mat-cell *matCellDef="let job" class="center">
          <div *ngIf="job.lastRunHistory">
            <fa-icon *ngIf="job.lastRunHistory.status === 'success'" class="success" [matTooltip]="'labels.Success'| translate"
              matTooltipPosition="right" icon="check-circle" size="lg"></fa-icon>
            <fa-icon *ngIf="!(job.lastRunHistory.status == 'success')" class="fail" [matTooltip]="'errors.failed'| translate"
              matTooltipPosition="right" icon="times-circle" size="lg"></fa-icon>
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="currentlyRunning">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{"labels.heading.currentlyrunning" | translate}} </th>
        <td mat-cell *matCellDef="let job" class="center">
          <div [className]="job.currentlyRunning === true ? 'currently-running' : 'not-currently-running'">
            <fa-icon matTooltip="{{ job.currentlyRunning === true ? 'Yes' : 'No' }}" matTooltipPosition="right"
              icon="circle" size="lg"></fa-icon>
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="errorLog">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{"labels.heading.errorlog" | translate}} </th>
        <td mat-cell *matCellDef="let job" class="center">
          <button mat-icon-button class="errorlog"
            *ngIf="!(job.lastRunHistory && job.lastRunHistory.status == 'success')" [matTooltip]="'labels.heading.errorlog'| translate"
            matTooltipPosition="right">
            <fa-icon icon="exclamation-circle" size="lg"></fa-icon>
          </button>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;" [routerLink]="[row.jobId]" class="select-row"></tr>

    </table>

    <mat-paginator [pageSizeOptions]="[50, 100]" showFirstLastButtons></mat-paginator>

  </div>

</div>

<div class="container space-top" fxLayout="row" fxLayoutGap="20px">
  <button mat-raised-button color="primary" (click)="runSelectedJobs()">
    <fa-icon icon="play"></fa-icon>&nbsp;&nbsp;
    {{"labels.buttons.runselectedjobs" | translate}}<ng-template #templateSchedulerJobs let-data let-popover="popover">
  <h2>{{"labels.template" | translate}}</h2>
  <p>{{"labels.thisTemplateIAmTryingToUseIsThatFine" | translate}}</p>
  <button (click)="popover.close();nextStepSchedulerJobs()">{{"labels.buttons.next" | translate}}</button>
</ng-template>
  </button>
  <button mat-raised-button color="primary" (click)="refresh()">
    <fa-icon icon="sync"></fa-icon>&nbsp;&nbsp;
    {{"labels.buttons.refresh" | translate}}
  </button>
</div>

<ng-template #templateSchedulerStatus let-data let-popover="popover">
  <h4>{{"labels.schedulerStatusButtonWillUsedChangeStatus" | translate}}</h4>
  <div fxLayout="row" fxLayoutAlign="end" fxLayoutGap="2%" fxLayout.lt-md="column">
    <button mat-raised-button color="warn" (click)="popover.close();configurationWizardService.closeConfigWizard()">{{"labels.buttons.close" | translate}}</button>
    <button mat-raised-button color="primary" (click)="popover.close();previousStep()">{{"labels.buttons.back" | translate}}</button>
    <button mat-raised-button color="primary" (click)="popover.close();showPopover(templateFilter, filter, 'bottom', true)">{{"labels.buttons.next" | translate}}</button>
  </div>
</ng-template>

<ng-template #templateFilter let-data let-popover="popover">
  <h4>{{"labels.searchBarFilterJobs" | translate}}</h4>
  <div fxLayout="row" fxLayoutAlign="end" fxLayoutGap="2%" fxLayout.lt-md="column">
    <button mat-raised-button color="warn" (click)="popover.close();configurationWizardService.closeConfigWizard()">{{"labels.buttons.close" | translate}}</button>
    <button mat-raised-button color="primary" (click)="popover.close();showPopover(templateSchedulerStatus,schedulerStatus, 'bottom', true)">{{"labels.buttons.back" | translate}}</button>
    <button mat-raised-button color="primary" (click)="popover.close();showPopover(templateJobsTable,jobsTable, 'top', true)">{{"labels.buttons.next" | translate}}</button>
  </div>
</ng-template>

<ng-template #templateJobsTable let-data let-popover="popover">
  <h4>{{"labels.listOfAllScheduledBatchJobs" | translate}} <a href="" target="_blank">{{"labels.schedularJobs" | translate}}</a></h4>
  <div fxLayout="row" fxLayoutAlign="end" fxLayoutGap="2%" fxLayout.lt-md="column">
    <button mat-raised-button color="warn" (click)="popover.close();configurationWizardService.closeConfigWizard()">{{"labels.buttons.close" | translate}}</button>
    <button mat-raised-button color="primary" (click)="popover.close();showPopover(templateFilter, filter, 'bottom', true)">{{"labels.buttons.back" | translate}}</button>
    <button mat-raised-button color="primary" (click)="popover.close();nextStep()">{{"labels.buttons.next" | translate}}</button>
  </div>
</ng-template>
