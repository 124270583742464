<div fxLayout="row" fxLayoutAlign="end" fxLayoutGap="2%" fxLayout.lt-md="column" class="container m-b-20">
  <span *mifosxHasPermission="'CREATE_GLACCOUNT'">
    <button mat-raised-button color="primary" *ngIf="glAccount.usage.value === 'HEADER'" [routerLink]="['/accounting/chart-of-accounts/gl-accounts/create']" [queryParams]="{ parent: glAccount.id, accountType: glAccount.type.id }">
      <fa-icon icon="plus"></fa-icon>&nbsp;&nbsp;
      {{"labels.buttons.addchildaccount" | translate}}
    </button>
  </span>
  <span *mifosxHasPermission="'UPDATE_GLACCOUNT'">
    <button mat-raised-button color="primary" [routerLink]="['edit']">
      <fa-icon icon="edit"></fa-icon>&nbsp;&nbsp;
      {{"labels.inputs.edit" | translate}}
    </button>
  </span>
  <span *mifosxHasPermission="'UPDATE_GLACCOUNT'">
    <button mat-raised-button color="warn" *ngIf="!glAccount.disabled" (click)="changeGlAccountState()">
      <fa-icon icon="lock"></fa-icon>&nbsp;&nbsp;
      {{"labels.buttons.disable" | translate}}
    </button>
  </span>
  <span *mifosxHasPermission="'UPDATE_GLACCOUNT'">
    <button mat-raised-button color="accent" *ngIf="glAccount.disabled" (click)="changeGlAccountState()">
      <fa-icon icon="lock-open"></fa-icon>&nbsp;&nbsp;
      {{"labels.buttons.enable" | translate}}
    </button>
  </span>
  <button mat-raised-button color="warn" (click)="deleteGlAccount()" *mifosxHasPermission="'DELETE_GLACCOUNT'">
    <fa-icon icon="trash"></fa-icon>&nbsp;&nbsp;
    {{"labels.anchors.delete" | translate}}
  </button>
</div>

<div class="container">

  <mat-card>

    <mat-card-content>

      <div fxLayout="row wrap" class="content">

        <div fxFlex="50%" class="header">
          {{"labels.inputs.accounttype" | translate}}
        </div>

        <div fxFlex="50%">
          {{ glAccount.type?.value }}
        </div>

        <div fxFlex="50%" class="header">
          {{"labels.inputs.glcode" | translate}}
        </div>

        <div fxFlex="50%">
          {{ glAccount.glCode }}
        </div>

        <div fxFlex="50%" class="header" *ngIf="glAccount.parent">
          {{"labels.parentAccountName" | translate}}
        </div>

        <div fxFlex="50%" *ngIf="glAccount.parent">
          <a class="tab-link" [routerLink]="['/accounting/chart-of-accounts/gl-accounts/view/' + glAccount.parent.id]">
            ({{ glAccount.parent.glCode }}) {{ glAccount.parent.name }}
          </a>
        </div>

        <div fxFlex="50%" class="header" *ngIf="glAccount.tagId.id">
          {{"labels.inputs.tag" | translate}}
        </div>

        <div fxFlex="50%" *ngIf="glAccount.tagId.id">
          {{ glAccount.tagId.name }}
        </div>

        <div fxFlex="50%" class="header">
          {{"labels.inputs.accountusage" | translate}}
        </div>

        <div fxFlex="50%">
          {{ glAccount.usage?.value }}
        </div>

        <div fxFlex="50%" class="header">
          {{"labels.inputs.manualentriesallowed" | translate}}
        </div>

        <div fxFlex="50%">
          {{ glAccount.manualEntriesAllowed ? 'Yes' : 'No' }}
        </div>

        <div fxFlex="50%" class="header" *ngIf="glAccount.description">
          {{"labels.heading.description" | translate}}
        </div>

        <div fxFlex="50%" *ngIf="glAccount.description">
          {{ glAccount.description }}
        </div>

      </div>

    </mat-card-content>

  </mat-card>

</div>
