<div class="container m-b-20" fxLayout="row" fxLayoutAlign="end" fxLayoutGap="20px">
  <div #buttonAddEdit class="in-block">
    <button mat-raised-button color="primary" [routerLink]="'manage'" *mifosxHasPermission="'UPDATE_CURRENCY'">
      <fa-icon icon="plus"></fa-icon>&nbsp;&nbsp;
      {{'labels.buttons.addedit' | translate}}
    </button>
  </div>
</div>

<div class="container">

  <div #filter fxLayout="row" fxLayoutGap="20px">
    <mat-form-field fxFlex>
      <mat-label>{{'labels.buttons.filter' | translate}}</mat-label>
      <input matInput (keyup)="applyFilter($event.target.value)">
    </mat-form-field>
  </div>

  <div #tableCurrencies class="mat-elevation-z8">

    <table mat-table [dataSource]="dataSource" matSort>

      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'labels.heading.currencyname' | translate}} </th>
        <td mat-cell *matCellDef="let currency"> {{ currency.name }} </td>
      </ng-container>

      <ng-container matColumnDef="code">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'labels.heading.currencycode' | translate}} </th>
        <td mat-cell *matCellDef="let currency"> {{ currency.code }} </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

    </table>

    <mat-paginator [pageSizeOptions]="[10, 25, 50, 100]" showFirstLastButtons></mat-paginator>

  </div>

</div>

<ng-template #templateButtonAddEdit let-popover="popover">
  <h2>{{'labels.buttons.addedit' | translate}}</h2>
  <p class="mw300">{{'labels.heading.listOfAllCurrenciesCurrentlyInUse' | translate}}.</p>
  <div fxLayout="row" fxLayoutAlign="end" fxLayoutGap="2%" fxLayout.lt-md="column">
    <button mat-raised-button color="warn"
      (click)="popover.close();configurationWizardService.closeConfigWizard()">{{'labels.buttons.close' |
      translate}}</button>
    <button mat-raised-button color="primary" (click)="popover.close();previousStep()">{{'labels.buttons.back' |
      translate}}</button>
    <button mat-raised-button color="primary"
      (click)="popover.close();showPopover(templateFilter, filter, 'bottom', true)">{{'labels.buttons.next' |
      translate}}</button>
  </div>
</ng-template>

<ng-template #templateFilter let-popover="popover">
  <h4>{{'labels.heading.searchBarToFilterCurrencies' | translate}}.</h4>
  <div fxLayout="row" fxLayoutAlign="end" fxLayoutGap="2%" fxLayout.lt-md="column">
    <button mat-raised-button color="warn"
      (click)="popover.close();configurationWizardService.closeConfigWizard()">{{'labels.buttons.close' |
      translate}}</button>
    <button mat-raised-button color="primary"
      (click)="popover.close();showPopover(templateButtonAddEdit,buttonAddEdit, 'bottom', true)">{{'labels.buttons.back'
      | translate}}</button>
    <button mat-raised-button color="primary"
      (click)="popover.close();showPopover(templateTableCurrencies, tableCurrencies, 'top', true)">{{'labels.buttons.next'
      | translate}}</button>
  </div>
</ng-template>

<ng-template #templateTableCurrencies let-popover="popover">
  <h4>{{'labels.heading.listOfCurrenciesAvailable' | translate}}.</h4>
  <div fxLayout="row" fxLayoutAlign="end" fxLayoutGap="2%" fxLayout.lt-md="column">
    <button mat-raised-button color="warn"
      (click)="popover.close();configurationWizardService.closeConfigWizard()">{{'labels.buttons.close' |
      translate}}</button>
    <button mat-raised-button color="primary"
      (click)="popover.close();showPopover(templateFilter, filter, 'bottom', true)">{{'labels.buttons.back' |
      translate}}</button>
    <button mat-raised-button color="primary" (click)="popover.close();nextStep()">{{'labels.buttons.next' |
      translate}}</button>
  </div>
</ng-template>