<div class="container">

  <mat-card>

    <form [formGroup]="groupsAddRoleForm" (ngSubmit)="submit()">

      <mat-card-content>

        <div fxLayout="column">

          <mat-form-field>
            <mat-label>{{ 'labels.anchors.client' | translate }}</mat-label>
            <mat-select formControlName="clientId">
              <mat-option *ngFor="let client of clientMemberData" [value]="client.id">
                {{ client.displayName }}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="groupsAddRoleForm.controls.clientId.hasError('required')">
              {{ 'labels.anchors.clientMembers' | translate }} <strong>{{"labels.commons.is required" | translate}}</strong>
            </mat-error>
          </mat-form-field>

          <mat-form-field>
            <mat-label>{{ 'labels.heading.role' | translate }}</mat-label>
            <mat-select formControlName="role">
              <mat-option *ngFor="let role of roleData" [value]="role.id">
                {{ role.name }}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="groupsAddRoleForm.controls.role.hasError('required')">
              {{ 'labels.heading.role' | translate }} <strong>{{"labels.commons.is required" | translate}}</strong>
            </mat-error>
          </mat-form-field>

        </div>

      </mat-card-content>

      <mat-card-actions fxLayout="row" fxLayout.xs="column" fxLayoutAlign="center" fxLayoutGap="5px">
        <button type="button" mat-raised-button [routerLink]="['../']">{{"labels.buttons.cancel" | translate}}</button>
        <button mat-raised-button color="primary" [disabled]="!groupsAddRoleForm.valid"
          *mifosxHasPermission="'ASSIGNROLE_GROUP'">{{"labels.buttons.confirm" | translate}}</button>
      </mat-card-actions>

    </form>

  </mat-card>

</div>
