<div class="container">

  <mat-card>

    <form [formGroup]="editUserForm" (ngSubmit)="submit()">

      <mat-card-content>

        <div fxLayout="row wrap" fxLayoutGap="2%" fxLayout.lt-md="column">

          <mat-form-field fxFlex="48%">
            <mat-label>{{'labels.inputs.username'| translate}}</mat-label>
            <input matInput required formControlName="username"/>
            <mat-error *ngIf="editUserForm.controls.username.hasError('required')">
              {{'labels.inputs.username'| translate}} <strong>{{"labels.commons.is required" | translate}}</strong>
            </mat-error>
          </mat-form-field>

          <mat-form-field fxFlex="48%">
            <mat-label>{{'labels.heading.email'| translate}}</mat-label>
            <input matInput required formControlName="email">
            <mat-error *ngIf="editUserForm.controls.email.hasError('email')">
              {{'errors.inputs.invaliEmail'| translate}}
            </mat-error>
            <mat-error *ngIf="editUserForm.controls.email.hasError('required')">
              {{'labels.heading.email'| translate}} <strong>{{"labels.commons.is required" | translate}}</strong>
            </mat-error>
          </mat-form-field>

          <mat-form-field fxFlex="48%">
            <mat-label>{{'labels.inputs.firstname'| translate}}</mat-label>
            <input matInput required formControlName="firstname"/>
            <mat-error *ngIf="editUserForm.controls.firstname.hasError('pattern')">
              {{'labels.inputs.firstname'| translate}} <strong>{{'errors.inputs.cannotBeginWwithASpecialCharacterOrNumber'| translate}}</strong>
            </mat-error>
            <mat-error *ngIf="editUserForm.controls.firstname.hasError('required')">
              {{'labels.inputs.firstname'| translate}} <strong>{{"labels.commons.is required" | translate}}</strong>
            </mat-error>
          </mat-form-field>

          <mat-form-field fxFlex="48%">
            <mat-label>{{'labels.inputs.lastname'| translate}}</mat-label>
            <input matInput required formControlName="lastname"/>
            <mat-error *ngIf="editUserForm.controls.lastname.hasError('pattern')">
              {{'labels.inputs.lastname'| translate}} <strong>{{'errors.inputs.cannotBeginWwithASpecialCharacterOrNumber'| translate}}</strong>
            </mat-error>
            <mat-error *ngIf="editUserForm.controls.lastname.hasError('required')">
              {{'labels.inputs.lastname'| translate}} <strong>{{"labels.commons.is required" | translate}}</strong>
            </mat-error>
          </mat-form-field>

          <div class="password-never-expires-wrapper" fxFlex="48%">
            <mat-checkbox labelPosition="before" formControlName="passwordNeverExpires" >
             {{'labels.inputs.overridepasswordexpiry'| translate}}
            </mat-checkbox>
          </div>

          <mat-form-field fxFlex="48%">
            <mat-label>{{'labels.inputs.office'| translate}}</mat-label>
            <mat-select required formControlName="officeId" (selectionChange)="officeChanged($event.value)">
              <mat-option *ngFor="let office of officesData" [value]="office.id">
                {{ office.name }}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="editUserForm.controls.officeId.hasError('required')">
              {{'labels.inputs.office'| translate}} <strong>{{"labels.commons.is required" | translate}}</strong>
            </mat-error>
          </mat-form-field>

          <mat-form-field fxFlex="48%">
            <mat-label>{{'labels.inputs.staff'| translate}}</mat-label>
            <mat-select formControlName="staffId">
              <mat-option *ngFor="let staff of staffData" [value]="staff.id">
                {{ staff.displayName }}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field fxFlex="48%">
            <mat-label>{{'labels.inputs.roles'| translate}}</mat-label>
            <mat-select required formControlName="roles" multiple>
              <mat-option *ngFor="let role of rolesData" [value]="role.id">
                {{ role.name }}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="editUserForm.controls.roles.hasError('required')">
              {{'labels.atLeastOneRole'| translate}} <strong>{{'labels.MustBeSelected'| translate}}</strong>
            </mat-error>
          </mat-form-field>

        </div>

      </mat-card-content>

      <mat-card-actions fxLayout="row" fxLayout.xs="column" fxLayoutAlign="center" fxLayoutGap="5px">
        <button type="button" mat-raised-button [routerLink]="['../']">{{"labels.buttons.cancel" | translate}}</button>
        <button mat-raised-button color="primary" [disabled]="!editUserForm.valid">{{"labels.buttons.submit" | translate}}</button>
      </mat-card-actions>

    </form>

  </mat-card>

</div>
