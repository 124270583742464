<div class="container">

  <mat-card>

    <form #accountFormRef [formGroup]="productLineForm" (ngSubmit)="submit()">

      <mat-card-content>

        <div fxLayout="row wrap" fxLayoutGap="2%" fxLayout.lt-md="column">

          <mat-form-field fxFlex="48%">
            <mat-label>{{"labels.inputs.name" | translate}}</mat-label>
            <input matInput required formControlName="name">
            <mat-error *ngIf="productLineForm.controls.name.hasError('required')">
              {{"labels.inputs.name" | translate}} <strong>{{"labels.commons.is required" | translate}}</strong>
            </mat-error>
          </mat-form-field>

          <mat-form-field fxFlex="48%">
            <mat-label>{{"labels.inputs.parent" | translate}}</mat-label>
            <mat-select required formControlName="parentId">
              <mat-option *ngFor="let parent of productLines" [value]="parent">
                {{ parent.name }} ( {{parent.limitAmount}} {{parent.currency.code}} )
              </mat-option>
            </mat-select>
            <mat-error *ngIf="productLineForm.controls.parentId.hasError('required')">
              {{"labels.inputs.parent" | translate}} <strong>{{"labels.commons.is required" | translate}}</strong>
            </mat-error>
          </mat-form-field>

          <mat-form-field fxFlex="48%">
            <mat-label>{{"labels.limitAmount" | translate}}</mat-label>
            <input matInput required formControlName="limitAmount" decimalInput [max]="parent?.limitAmount" [min]="1">
            <mat-error *ngIf="productLineForm.controls.limitAmount.hasError('required')">
              {{"labels.limitAmount" | translate}} <strong>{{"labels.commons.is required" | translate}}</strong>
            </mat-error>
            <mat-error *ngIf="productLineForm.controls.limitAmount.hasError('max')">
              {{"labels.limitAmount" | translate}} <strong>{{"labels.mustBeLessThan" | translate}}</strong> {{parent?.limitAmount}}
            </mat-error>
            <mat-error *ngIf="productLineForm.controls.limitAmount.hasError('min')">
              {{"labels.limitAmount" | translate}} <strong>{{"labels.mustBeGreaterThan" | translate}}</strong> 1
            </mat-error>
            <!-- <mat-error *ngIf="productLineForm.controls.limitAmount.hasError('minPrincipal')">
              {{productLineForm.controls.limitAmount.errors['minPrincipal']}}
            </mat-error> -->
          </mat-form-field>


          <mat-form-field fxFlex="48%">
            <mat-label>{{"labels.inputs.loanproduct" | translate}}</mat-label>
            <mat-select required formControlName="loanProducts" multiple>
              <mat-option *ngFor="let product of loanProductsData" [value]="product.id" [disabled]="productLineForm.value.limitAmount<product.minPrincipal">
                {{ product.name }}
                <span *ngIf="productLineForm.value.limitAmount<product.minPrincipal" class="error-product">
                  {{"labels.limitAmount" | translate}} {{"labels.mustBeGreaterThan" | translate}} {{product.minPrincipal}} ({{"labels.minPrincipalamount" | translate}})
                </span>
              </mat-option>
            </mat-select>
            <mat-error *ngIf="productLineForm.controls.loanProducts.hasError('required')">
              {{"labels.inputs.loanproduct" | translate}} <strong>{{"labels.commons.is required" | translate}}</strong>
            </mat-error>
          </mat-form-field>

          <mat-form-field fxFlex="48%">
            <mat-label>{{"labels.processingDays" | translate}} ({{"labels.inputs.days" | translate}})</mat-label>
            <input matInput required formControlName="processingDays" type="number" min="0">
            <mat-error *ngIf="productLineForm.controls.processingDays.hasError('required')">
              {{"labels.processingDays" | translate}} <strong>{{"labels.commons.is required" | translate}}</strong>
            </mat-error>
          </mat-form-field>

          <mat-form-field fxFlex="48%">
            <mat-label>{{"labels.validityDays" | translate}} ({{"labels.inputs.days" | translate}})</mat-label>
            <input matInput required formControlName="validityDays" type="number" min="0">
            <mat-error *ngIf="productLineForm.controls.validityDays.hasError('required')">
              {{"labels.validityDays" | translate}} <strong>{{"labels.commons.is required" | translate}}</strong>
            </mat-error>
          </mat-form-field>


          <div fxFlex="10%" class="manual-entries-allowed-wrapper">
            <mat-checkbox labelPosition="before" formControlName="revolving" class="checker">
              {{"labels.revolving" | translate}}
            </mat-checkbox>
          </div>

          <div fxFlex="20%" class="manual-entries-allowed-wrapper">
            <mat-checkbox labelPosition="before" formControlName="unique" class="checker">
              {{"labels.isUnique" | translate}}
            </mat-checkbox>
          </div>

        </div>

      </mat-card-content>

      <mat-card-actions fxLayout="row" fxLayout.xs="column" fxLayoutAlign="center" fxLayoutGap="5px">
        <button type="button" mat-raised-button [routerLink]="['../']">{{"labels.buttons.cancel" | translate}}</button>
        <button mat-raised-button color="primary" [disabled]="!productLineForm.valid" >{{"labels.buttons.submit" | translate}}</button>
      </mat-card-actions>

    </form>

  </mat-card>

</div>


