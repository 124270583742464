<div class="container">

  <mat-card>

    <form [formGroup]="floatingRateForm" (ngSubmit)="submit()">

      <mat-card-content>

        <div fxLayout="row wrap" fxLayout.lt-md="column" fxLayoutGap="15%">

          <mat-form-field fxFlex="25%">
            <mat-label>{{"labels.inputs.floatingratename" | translate }}</mat-label>
            <input matInput required formControlName="name">
            <mat-error *ngIf="floatingRateForm.controls.name.hasError('required')">
              {{"labels.inputs.floatingratename" | translate }} <strong>{{"labels.commons.is required" | translate}}</strong>
            </mat-error>
          </mat-form-field>

          <div class="checkbox-wrapper" fxFlex="25%">
            <mat-checkbox formControlName="isBaseLendingRate" class="checkbox" labelPosition="before">
              {{"labels.heading.isbaselendingrate" | translate }}
            </mat-checkbox>
          </div>

          <div class="checkbox-wrapper" fxFlex="15%">
            <mat-checkbox formControlName="isActive" class="checkbox" labelPosition="before">
              {{"labels.inputs.active" | translate}}
            </mat-checkbox>
          </div>

        </div>

        <br>

        <mat-divider [inset]="true"></mat-divider>

        <br>

        <div fxLayout="row wrap" fxLayoutGap="70%" fxLayoutGap.lt-md="0px" class="m-b-10" fxLayout.lt-md="column">

          <p fxFlex="25%" class="mat-title">{{"labels.heading.rateperiods" | translate }}</p>

          <button mat-mini-fab type="button" color="primary" (click)="addFloatingRatePeriod()">
            <fa-icon icon="plus"></fa-icon>
          </button>

        </div>

        <table mat-table [dataSource]="dataSource" matSort>

          <ng-container matColumnDef="fromDate">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>{{"labels.heading.fromdate" | translate }}</th>
            <td mat-cell *matCellDef="let floatingRatePeriod"> {{ floatingRatePeriod.fromDate  | dateFormat : 'yyyy-MM-dd' }} </td>
          </ng-container>

          <ng-container matColumnDef="interestRate">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>{{"labels.heading.interestrate" | translate }}</th>
            <td mat-cell *matCellDef="let floatingRatePeriod"> {{ floatingRatePeriod.interestRate }} </td>
          </ng-container>

          <ng-container matColumnDef="isDifferential">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>{{"labels.isDifferential" | translate }}</th>
            <td mat-cell *matCellDef="let floatingRate">
              <fa-icon *ngIf="floatingRate.isDifferentialToBaseLendingRate" icon="check-circle" size="lg"
                class="is-differential" [matTooltip]="'labels.yes'| translate" matTooltipPosition="right"></fa-icon>
              <fa-icon *ngIf="!floatingRate.isDifferentialToBaseLendingRate" icon="times-circle" size="lg"
                class="not-is-differential" [matTooltip]="'labels.no'| translate" matTooltipPosition="right"></fa-icon>
            </td>
          </ng-container>

          <ng-container matColumnDef="actions">
            <th mat-header-cell *matHeaderCellDef>{{"labels.inputs.actions" | translate}} </th>
            <td mat-cell *matCellDef="let floatingRatePeriod">
              <button type="button" color="primary" mat-icon-button
                (click)="editFloatingRatePeriod(floatingRatePeriod)">
                <fa-icon icon="edit" size="lg"></fa-icon>
              </button>
              <button type="button" color="warn" mat-icon-button
                (click)="deleteFloatingRatePeriod(floatingRatePeriod)">
                <fa-icon icon="trash" size="lg"></fa-icon>
              </button>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

        </table>

        <mat-paginator [pageSizeOptions]="[10, 25, 50, 100]" showFirstLastButtons></mat-paginator>

      </mat-card-content>

      <mat-card-actions fxLayout="row" fxLayout.xs="column" fxLayoutAlign="center" fxLayoutGap="5px">
        <button type="button" mat-raised-button [routerLink]="['../']">{{"labels.buttons.cancel" | translate}}</button>
        <button mat-raised-button type="submit" color="primary" [disabled]="!floatingRateForm.valid || floatingRateForm.pristine && isFloatingRateFormPristine">
          {{"labels.buttons.submit" | translate}}
        </button>
      </mat-card-actions>

    </form>

  </mat-card>

</div>
