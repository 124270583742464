<div fxLayout="row" fxLayoutAlign="space-between center">
  <mat-form-field fxFlex="50%">
    <mat-label>{{"labels.buttons.filter" | translate}}</mat-label>
    <input matInput (keyup)="applyFilter($event.target.value)">
  </mat-form-field>
  <button mat-raised-button color="primary" (click)="toggleClosed()">
    {{ showClosed ? 'View Active' : 'View Closed' }}
  </button>
</div>

<table mat-table [dataSource]="dataSource" matSort>

  <ng-container matColumnDef="accountNo">
    <th mat-header-cell *matHeaderCellDef mat-sort-header> {{"labels.inputs.accno" | translate}} </th>
    <td mat-cell *matCellDef="let loanAccount">{{ loanAccount.accountNo }}</td>
  </ng-container>

  <ng-container matColumnDef="productName">
    <th mat-header-cell *matHeaderCellDef mat-sort-header> {{"labels.heading.loanaccount" | translate}} </th>
    <td mat-cell *matCellDef="let loanAccount">{{ loanAccount.productName }}</td>
  </ng-container>

  <ng-container matColumnDef="Type">
    <th mat-header-cell *matHeaderCellDef> {{"labels.heading.type" | translate}} </th>
    <td mat-cell *matCellDef="let loanAccount">
      <fa-icon matTooltip="{{ loanAccount.loanType.value }}" matTooltipPosition="right" [icon]="(loanAccount.loanType.code === 'accountType.individual') ? 'user' : 'users'" size="lg"></fa-icon>
    </td>
  </ng-container>

  <ng-container matColumnDef="Status">
    <th mat-header-cell *matHeaderCellDef> {{"labels.inputs.status" | translate}} </th>
    <td mat-cell *matCellDef="let loanAccount">
      <span [className]="loanAccount.inArrears?'status-active-overdue' : (loanAccount.status.code | statusLookup)">
        <fa-icon matTooltip="{{ loanAccount.status.value }}" matTooltipPosition="right" icon="circle" size="md"></fa-icon>
      </span>
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

</table>

<mat-paginator [pageSizeOptions]="[10, 25]" showFirstLastButtons></mat-paginator>
