<div class="tab-container mat-typography" *ngIf="loans.length > 0">

  <div fxLayout="row" fxLayoutAlign="start center">
    <div class="search-box" fxFlex="40%">
      <mat-form-field fxFlex="90%">
        <input matInput [placeholder]="'labels.inputs.filterbyname' | translate" (keyup)="applyFilter($event.target.value)">
      </mat-form-field>
    </div>
    <div fxFlex="60%">
      <button mat-raised-button color="success" *mifosxHasPermission="'APPROVE_LOANRESCHEDULE'"
        (click)="approveBulkLoanReschedule()">
        <fa-icon icon="check"></fa-icon>&nbsp;&nbsp;{{'labels.buttons.approve'| translate}}
      </button>
    </div>
  </div>

  <table mat-table [dataSource]="dataSource">

    <ng-container matColumnDef="select">
      <th mat-header-cell *matHeaderCellDef>
        <mat-checkbox (change)="$event ? masterToggle() : null" [checked]="selection.hasValue() && isAllSelected()"
          [indeterminate]="selection.hasValue() && !isAllSelected()" [aria-label]="checkboxLabel()">
        </mat-checkbox>
      </th>
      <td mat-cell *matCellDef="let row">
        <mat-checkbox (click)="$event.stopPropagation()" (change)="$event ? selection.toggle(row) : null"
          [checked]="selection.isSelected(row)" [aria-label]="checkboxLabel(row)">
        </mat-checkbox>
      </td>
    </ng-container>

    <ng-container matColumnDef="client">
      <th mat-header-cell *matHeaderCellDef> {{'labels.heading.client'| translate}} </th>
      <td mat-cell *matCellDef="let loan" class="view-details" [routerLink]="['../../clients', loan.clientId , 'general']"> {{loan.clientName}} </td>
    </ng-container>

    <ng-container matColumnDef="rescheduleRequestNo">
      <th mat-header-cell *matHeaderCellDef> {{'labels.heading.reschulerequest'| translate}} </th>
      <td mat-cell *matCellDef="let loan"> {{loan.id}} </td>
    </ng-container>

    <ng-container matColumnDef="loanAccountNo">
      <th mat-header-cell *matHeaderCellDef> {{'labels.heading.loanaccount'| translate}}# </th>
      <td mat-cell *matCellDef="let loan" class="view-details" [routerLink]="['../../clients', loan.clientId , 'loans', loan.loanId, 'general']"> {{loan.loanAccountNumber}} </td>
    </ng-container>

    <ng-container matColumnDef="rescheduleForm">
      <th mat-header-cell *matHeaderCellDef> {{'labels.rescheduleForm'| translate}} </th>
      <td mat-cell *matCellDef="let loan"> {{loan.rescheduleFromDate  | dateFormat}} </td>
    </ng-container>

    <ng-container matColumnDef="rescheduleReason">
      <th mat-header-cell *matHeaderCellDef> {{'labels.rescheduleReason'| translate}} </th>
      <td mat-cell *matCellDef="let loan"> {{loan.rescheduleReasonCodeValue.name}} </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="selection.toggle(row)">
    </tr>
  </table>

</div>

<div class="alert" *ngIf="loans.length === 0">

  <div class="message">
    <i class="fa fa-exclamation-circle alert-check"></i>
    {{'labels.noPendingRescheduledLoanAvailableAccount'| translate}}
  </div>

</div>