<div class="container m-b-20" fxLayout="row" fxLayoutAlign="end" fxLayoutGap="20px">
  <button mat-raised-button color="primary" [routerLink]="['edit']">
    <fa-icon icon="edit"></fa-icon>&nbsp;&nbsp;{{"labels.inputs.edit" | translate }}</button>
</div>

<div class="container mat-elevation-z8">

  <table mat-table [dataSource]="dataSource" matSort>

    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>{{"labels.inputs.name" | translate }}</th>
      <td mat-cell *matCellDef="let configuration"> {{ configuration.name }} </td>
    </ng-container>

    <ng-container matColumnDef="value">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>{{"labels.inputs.value" | translate}}</th>
      <td mat-cell *matCellDef="let configuration"> {{ configuration.value }} </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

  </table>

</div>
