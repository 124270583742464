<div class="sidnav-container">
  <div class="navigation-panel" fxLayout="column">

    <!-- TODO: Allow customization by organization -->
    <!-- <div class="app-brand" [routerLink]="['/home']">
      <img src="assets/images/logo.png" alt="app-logo" class="app-logo"> -->
      <!-- <span #logo class="app-logo-text text-muted">{{ 'APP_NAME' | translate }}</span> -->
    <!-- </div> -->
    <!-- <div class="app-brand" [routerLink]="['/home']">
      <img src="assets/images/logo.png" alt="app-logo" class="app-logo" width="100%">
      <span #logo class="app-logo-text text-muted">{{ 'APP_NAME' | translate }}</span>
    </div>

    <div class="app-user">
      <div class="app-user-photo">
         TODO: Decision to be taken on providing this feature
    <img src="assets/images/user_placeholder.png" alt="app-user-photo">
  </div>
  <span class="app-user-name mb-05">
    {{ username }}
  </span>
  <div #userPanel class="app-user-controls">
    <button class="xs-button" mat-icon-button matTooltip="{{ 'tooltips.Settings' | translate }}"
      [routerLink]="['/settings']">
      <fa-icon icon="cog"></fa-icon>
    </button>
    <button class="xs-button" mat-icon-button matTooltip="{{ 'tooltips.Sign Out' | translate }}" (click)="logout()">
      <fa-icon icon="sign-out-alt"></fa-icon>
    </button>
  </div>
</div> -->

    <div class="app-sidenav">
      <!-- <div #frequentlyAccessed>
        <div class="mt-1 mb-1 nav-item-sep">
          <mat-divider></mat-divider>
          <span class="text-muted icon-menu-title">{{ 'labels.menus.Frequently Accessed' | translate }}</span>
        </div>
      </div>
      <div class="icon-menu">
        <div class="icon-menu-item" *ngFor="let activity of mappedActivities">
          <button mat-raised-button [matTooltip]="activity.toolTip" [routerLink]="[activity.path]">
            <fa-icon [icon]="activity.icon" size="lg"></fa-icon>
          </button>
        </div>
      </div> -->
      <mat-nav-list>
        <app-menu-list-item *ngFor="let item of navItems" [item]="item"></app-menu-list-item>
        <!-- <mat-list-item [matTooltip]="'labels.anchors.help'| translate">
          <mat-icon matListIcon (click)="help()">
            <fa-icon icon="question-circle" size="sm"></fa-icon>
          </mat-icon>
          <a matLine>{{ 'labels.menus.Help' | translate }}</a>
        </mat-list-item> -->
      </mat-nav-list>
    </div>
  </div>
</div>


<ng-template #templateLogo let-popover="popover">
  <p class="mw300">{{"labels.ThisLogoWillReturnUser" | translate}}</p>
  <div fxLayout="row" fxLayoutAlign="end" fxLayoutGap="2%" fxLayout.lt-md="column">
    <button mat-raised-button color="warn"
      (click)="popover.close();configurationWizardService.closeConfigWizard()">{{"labels.buttons.close" | translate}}</button>
    <button mat-raised-button color="primary" (click)="popover.close();previousStep()">{{"labels.buttons.back" | translate}}</button>
    <button mat-raised-button color="primary"
      (click)="popover.close();showPopover(templateUserPanel,userPanel,'bottom', true);">{{"labels.buttons.next" | translate}}</button>
  </div>
</ng-template>

<ng-template #templateUserPanel let-popover="popover">
  <h2>{{ 'labels.menus.User Panel' | translate }}</h2>
  <p class="mw300">{{"labels.ThisSectionContainsUserName" | translate}}</p>
  <div fxLayout="row" fxLayoutAlign="end" fxLayoutGap="2%" fxLayout.lt-md="column">
    <button mat-raised-button color="warn"
      (click)="popover.close();configurationWizardService.closeConfigWizard()">{{"labels.buttons.close" | translate}}</button>
    <button mat-raised-button color="primary"
      (click)="popover.close();showPopover(templateLogo,logo,'bottom', true);">{{"labels.buttons.back" | translate}}</button>
    <button mat-raised-button color="primary"
      (click)="popover.close();showPopover(templateFrequentlyAccessed,frequentlyAccessed,'bottom', true);">{{"labels.buttons.next" | translate}}</button>
  </div>
</ng-template>

<ng-template #templateFrequentlyAccessed let-popover="popover">
  <h2>{{ 'labels.menus.Frequently Accessed' | translate }}</h2>
  <p class="mw300">{{"labels.thisContainsShortcutFrequently" | translate}}</p>
  <div fxLayout="row" fxLayoutAlign="end" fxLayoutGap="2%" fxLayout.lt-md="column">
    <button mat-raised-button color="warn"
      (click)="popover.close();configurationWizardService.closeConfigWizard()">{{"labels.buttons.close" | translate}}</button>
    <button mat-raised-button color="primary"
      (click)="popover.close();showPopover(templateUserPanel,userPanel,'bottom', true);">{{"labels.buttons.back" | translate}}</button>
    <button mat-raised-button color="primary"
      (click)="popover.close();showPopover(templateDashboard,dashboard,'bottom', true);">{{"labels.buttons.next" | translate}}</button>
  </div>
</ng-template>

<ng-template #templateDashboard let-popover="popover">
  <h2>{{ 'labels.menus.Dashboard' | translate }}</h2>
  <p class="mw300">{{"labels.thisWillNavigateUserToDashboard" | translate}}</p>
  <div fxLayout="row" fxLayoutAlign="end" fxLayoutGap="2%" fxLayout.lt-md="column">
    <button mat-raised-button color="warn"
      (click)="popover.close();configurationWizardService.closeConfigWizard()">{{"labels.buttons.close" | translate}}</button>
    <button mat-raised-button color="primary"
      (click)="popover.close();showPopover(templateFrequentlyAccessed,frequentlyAccessed,'bottom', true);">{{"labels.buttons.back" | translate}}</button>
    <button mat-raised-button color="primary"
      (click)="popover.close();showPopover(templateNavigation,navigation,'bottom', true);">{{"labels.buttons.next" | translate}}</button>
  </div>
</ng-template>

<ng-template #templateNavigation let-popover="popover">
  <h2>{{ 'labels.menus.Navigation' | translate }}</h2>
  <p class="mw300">{{"labels.thisWillNavigateUserToEntity" | translate}}</p>
  <div fxLayout="row" fxLayoutAlign="end" fxLayoutGap="2%" fxLayout.lt-md="column">
    <button mat-raised-button color="warn"
      (click)="popover.close();configurationWizardService.closeConfigWizard()">{{"labels.buttons.close" | translate}}</button>
    <button mat-raised-button color="primary"
      (click)="popover.close();showPopover(templateDashboard,dashboard,'bottom', true);">{{"labels.buttons.back" | translate}}</button>
    <button mat-raised-button color="primary"
      (click)="popover.close();showPopover(templateFrequentPostings,frequentPostings,'bottom', true);">{{"labels.buttons.next" | translate}}</button>
  </div>
</ng-template>

<ng-template #templateFrequentPostings let-popover="popover">
  <h2>{{ 'labels.menus.Frequent Postings' | translate }}</h2>
  <p class="mw300">{{"labels.shortcutJournalEntryScreenWithAccoutingRules" | translate}}</p>
  <div fxLayout="row" fxLayoutAlign="end" fxLayoutGap="2%" fxLayout.lt-md="column">
    <button mat-raised-button color="warn"
      (click)="popover.close();configurationWizardService.closeConfigWizard()">{{"labels.buttons.close" | translate}}</button>
    <button mat-raised-button color="primary"
      (click)="popover.close();showPopover(templateNavigation,navigation,'bottom', true);">{{"labels.buttons.back" | translate}}</button>
    <button mat-raised-button color="primary"
      (click)="popover.close();showPopover(templateCreateJournalEntry,createJournalEntry,'top', true);">{{"labels.buttons.next" | translate}}</button>
  </div>
</ng-template>

<ng-template #templateCreateJournalEntry let-popover="popover">
  <h2>{{ 'labels.menus.Create Journal Entry' | translate }}</h2>
  <p class="mw300">{{"labels.shortcutToCreateJournalEntryScreen" | translate}}</p>
  <div fxLayout="row" fxLayoutAlign="end" fxLayoutGap="2%" fxLayout.lt-md="column">
    <button mat-raised-button color="warn"
      (click)="popover.close();configurationWizardService.closeConfigWizard()">{{"labels.buttons.close" | translate}}</button>
    <button mat-raised-button color="primary"
      (click)="popover.close();showPopover(templateFrequentPostings,frequentPostings,'bottom', true);">{{"labels.buttons.back" | translate}}</button>
    <button mat-raised-button color="primary"
      (click)="popover.close();showPopover(templateChartOfAccounts,chartOfAccounts,'top', true);">{{"labels.buttons.next" | translate}}</button>
  </div>
</ng-template>

<ng-template #templateChartOfAccounts let-popover="popover">
  <h2>{{ 'labels.menus.Chart of Accounts' | translate }}</h2>
  <p class="mw300">{{"labels.shortcutToChartAccountsScreen" | translate}}</p>
  <div fxLayout="row" fxLayoutAlign="end" fxLayoutGap="2%" fxLayout.lt-md="column">
    <button mat-raised-button color="warn"
      (click)="popover.close();configurationWizardService.closeConfigWizard()">{{"labels.buttons.close" | translate}}</button>
    <button mat-raised-button color="primary"
      (click)="popover.close();showPopover(templateCreateJournalEntry,createJournalEntry,'top', true);">{{"labels.buttons.back" | translate}}</button>
    <button mat-raised-button color="primary" (click)="popover.close();nextStep()">{{"labels.buttons.next" | translate}}</button>
  </div>
</ng-template>
