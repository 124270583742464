<div class="tab-container mat-typography">

  <h3>{{datatableName}}</h3>

  <div fxLayout="row" fxLayoutAlign="flex-end">
    <span *mifosxHasPermission="'CREATE_' + datatableName">
      <button mat-raised-button color="primary" (click)="add()" *ngIf="!dataObject.data[0]">
        <fa-icon icon="plus"></fa-icon>&nbsp;&nbsp;{{ 'labels.buttons.add' | translate }}
      </button>
    </span>
    <span *mifosxHasPermission="'UPDATE_' + datatableName">
      <button mat-raised-button color="primary" (click)="edit()" *ngIf="dataObject.data[0]">
        <fa-icon icon="edit"></fa-icon>&nbsp;&nbsp;{{ 'labels.buttons.edit' | translate }}
      </button>
    </span>
    <span *mifosxHasPermission="'DELETE_' + datatableName" class="delete-button">
      <button mat-raised-button color="warn" (click)="delete()" *ngIf="dataObject.data[0]">
        <fa-icon icon="trash"></fa-icon>&nbsp;&nbsp;{{ 'labels.anchors.delete' | translate }}
      </button>
    </span>
  </div>

  <mat-list role="list" *ngIf="dataObject.data[0]">
    <mat-list-item role="listitem" *ngFor="let columnHeader of dataObject.columnHeaders;let i = index">
      {{columnHeader.columnName}} : {{dataObject.data[0].row[i]}}
    </mat-list-item>
  </mat-list>

</div>
