import { ActivatedRoute } from '@angular/router';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'mifosx-view-rate',
  templateUrl: './view-rate.component.html',
  styleUrls: ['./view-rate.component.scss']
})
export class ViewRateComponent implements OnInit {

  rate: any = null;
  constructor(private route: ActivatedRoute) {
    this.route.data.subscribe((data: { rate: any }) => {
      this.rate = data.rate;
    });
  }

  ngOnInit(): void {
  }

}
