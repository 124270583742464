import { SimulatorDialogComponent } from './../../../../../shared/simulator-dialog/simulator-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { AuthenticationService } from 'app/core/authentication/authentication.service';
/** Angular Imports */
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { SettingsService } from 'app/settings/settings.service';
import { Dates } from 'app/core/utils/dates';
import { ClientsService } from 'app/clients/clients.service';
import { MatStepper } from '@angular/material/stepper';

/** Custom Services */

/**
 * Loans Account Details Step
 */
@Component({
  selector: 'mifosx-loans-account-details-step',
  templateUrl: './loans-account-details-step.component.html',
  styleUrls: ['./loans-account-details-step.component.scss']
})
export class LoansAccountDetailsStepComponent implements OnInit {

  /** Loans Account Template */
  @Input() loansAccountTemplate: any;
  @Input() creditLine: any;
  @Input() token:string;
  @Input() loanProducts: any;

  /** Minimum date allowed. */
  minDate = new Date(2000, 0, 1);
  /** Maximum date allowed. */
  maxDate = new Date(2100, 0, 1);
  /** Product Data */
  productData: any;
  /** Loan Officer Data */
  loanOfficerOptions: any;
  /** Loan Purpose Options */
  loanPurposeOptions: any;
  /** Fund Options */
  fundOptions: any;
  /** Account Linking Options */
  accountLinkingOptions: any;
  /** For edit loan accounts form */
  isFieldOfficerPatched = false;
  /** Loans Account Details Form */
  loansAccountDetailsForm: FormGroup;

  loanId: any = null;
  currencyDisplaySymbol = '$';
  termFrequencyTypeData: any;
  simulation: any;
  clientId:any;

  repaymentEvery: number;
  remainAmountCL: any;
  productIdQuery:any;
  nextPeriod :any;


  /** Loans Account Template with product data  */
  loansAccountProduct: any;
  @Output() loansAccountProductTemplate = new EventEmitter();

  /**
   * Sets loans account details form.
   * @param {FormBuilder} formBuilder Form Builder.
   * @param {AuthenticationService} authenticationService Loans Service.
   * @param {SettingsService} settingsService SettingsService
   */
  constructor(private formBuilder: FormBuilder,
    private authenticationService: AuthenticationService,
    private route: ActivatedRoute,
    private dateUtils: Dates,
    private settingsService: SettingsService,
    private dialog: MatDialog,
    private _stepper: MatStepper) {
    this.loanId = this.route.snapshot.params['loanId'];
    this.productIdQuery = this.route.snapshot.queryParams['productId'];
    this.createLoansAccountDetailsForm();
    this.clientId = this.route.snapshot.params['clientId'];

  }

  ngOnInit() {
    this.maxDate = this.settingsService.maxFutureDate;
    this.buildDependencies();
    this.remainAmountCL = this.creditLine?.remainAmount;
    if (this.loansAccountTemplate) {
      this.productData = this.loanProducts;
      if (this.loansAccountTemplate.loanProductId) {
        this.loansAccountDetailsForm.patchValue({
          'productId': this.loansAccountTemplate.loanProductId,
          'submittedOnDate': this.loansAccountTemplate.timeline.submittedOnDate && new Date(this.loansAccountTemplate.timeline.submittedOnDate),
          'loanPurposeId': this.loansAccountTemplate.loanPurposeId,
          'expectedDisbursementDate': this.loansAccountTemplate.timeline.expectedDisbursementDate && new Date(this.loansAccountTemplate.timeline.expectedDisbursementDate),


          'principal': this.loansAccountTemplate.principal,
          'loanTermFrequency': this.loansAccountTemplate.termFrequency,
          'loanTermFrequencyType': this.loansAccountTemplate.termPeriodFrequencyType.id,
          'numberOfRepayments': this.loansAccountTemplate.numberOfRepayments,
          'repaymentEvery': this.loansAccountTemplate.repaymentEvery,
          'repaymentFrequencyType': this.loansAccountTemplate.repaymentFrequencyType.id,

        });
      }
    }
    this.loansAccountDetailsForm.get('repaymentEvery').valueChanges.subscribe(repaymentEvery => {
      const loanTermFrequency = repaymentEvery * this.loansAccountProduct?.product?.minNumberOfRepayments;
      this.loansAccountDetailsForm.get("loanTermFrequency").setValue(loanTermFrequency)
      this.loansAccountDetailsForm.get("numberOfRepayments").setValue(loanTermFrequency / repaymentEvery)

    });
    this.loansAccountDetailsForm.get('loanTermFrequency').valueChanges.subscribe(loanTermFrequency => {
      const repaymentEvery = this.loansAccountDetailsForm.get('repaymentEvery').value

      this.loansAccountDetailsForm.get("numberOfRepayments").setValue(loanTermFrequency / repaymentEvery)

    });

    this.loansAccountDetailsForm.get('repaymentFrequencyType').valueChanges.subscribe(repaymentFrequencyType => {
      this.loansAccountDetailsForm.get("loanTermFrequencyType").setValue(repaymentFrequencyType)
    });
    if(this.productIdQuery){
      this.loansAccountDetailsForm.get("productId").setValue(parseInt(this.productIdQuery));
    }
  }


  onchangeProduct() {
    if (this.loansAccountProduct) {
      this.loansAccountTemplate = this.loansAccountProduct;
      this.currencyDisplaySymbol = this.loansAccountTemplate.currency.displayLabel;
      if (this.loanId != null && this.loansAccountTemplate.accountNo) {
        this.loansAccountTemplate = this.loansAccountTemplate;
      }

      this.loansAccountDetailsForm.patchValue({
        'principal': this.loansAccountTemplate.principal,
        'loanTermFrequency': this.loansAccountTemplate.termFrequency,
        'loanTermFrequencyType': this.loansAccountTemplate.termPeriodFrequencyType.id,
        'numberOfRepayments': this.loansAccountTemplate.numberOfRepayments,
        'repaymentEvery': this.loansAccountTemplate.repaymentEvery,
        'repaymentFrequencyType': this.loansAccountTemplate.repaymentFrequencyType.id,
      });
    }


    const allowAttributeOverrides = this.loansAccountTemplate.product.allowAttributeOverrides;
    if (!allowAttributeOverrides.repaymentEvery) {
      this.loansAccountDetailsForm.controls.repaymentEvery.disable();
      this.loansAccountDetailsForm.controls.repaymentFrequencyType.disable();
    }
    this.setOptions();
  }

  setOptions() {
    if (this.loansAccountProduct) {
    this.termFrequencyTypeData = this.loansAccountProduct?.repaymentFrequencyTypeOptions;
    }
   }
   frequencyTypeCoefficients = [30,4,1];
   get frequencyTypeCoefficient(){
    return  this.frequencyTypeCoefficients[this.loansAccountDetailsForm.value.repaymentFrequencyType];
  }
   get maxDuration(){
    const frequencyType = this.frequencyTypeCoefficient;
    switch(this.loansAccountProduct?.product.id){
      case 1://PLATINIUM
      case 4://Fast cash
        return 3*frequencyType;
      case 2://GOLD
        return 2*frequencyType;
      case 3://SLIVER
        return 1*frequencyType;
      default:
        return 3*frequencyType;
    }
   }
   largestMultiple(maxDuration:number,repaidEvery:number){
    const remainder = maxDuration % repaidEvery;
    return maxDuration - remainder;
   }
   get maxTerms(){
    const max= this.loansAccountDetailsForm.value.repaymentEvery*this.loansAccountProduct?.product.maxNumberOfRepayments;
    return this.largestMultiple(Math.min(this.maxDuration,max),this.loansAccountDetailsForm.value.repaymentEvery);
   }

   get maxRepaidEvery(){
    return Math.floor(this.maxDuration/this.loansAccountProduct?.product.minNumberOfRepayments)
   }

  /**
   * Creates loans account details form.
   */
  createLoansAccountDetailsForm() {
    this.loansAccountDetailsForm = this.formBuilder.group({
      'productId': ['', Validators.required],
      'loanPurposeId': [''],
      'submittedOnDate': [new Date(), Validators.required],
      'expectedDisbursementDate': [new Date(), Validators.required],

      'principal': ['', Validators.required],
      'loanTermFrequency': ['', Validators.required],
      'loanTermFrequencyType': ['', Validators.required],
      'numberOfRepayments': ['', Validators.required],
      'repaymentEvery': ['', Validators.required],
      'repaymentFrequencyType': ['', Validators.required],
      'repaymentFrequencyNthDayType': [''],

    });
  }



  /**
   * Fetches loans account product template on productId value changes
   */
  buildDependencies() {
    // const entityId = (this.loansAccountTemplate.clientId) ? this.loansAccountTemplate.clientId : this.loansAccountTemplate.group.id;
    // const isGroup = (this.loansAccountTemplate.clientId) ? false : true;
    this.loansAccountDetailsForm.get('numberOfRepayments').disable()
    this.loansAccountDetailsForm.get('productId').valueChanges.subscribe((productId: string) => {
      this.authenticationService.getLoansAccountTemplateResource(this.token,this.clientId, false, productId).subscribe((response: any) => {
        this.loansAccountProduct = response;
        this.loansAccountProduct.product.maxPrincipal = Math.min(this.loansAccountProduct.product.maxPrincipal,this.remainAmountCL)
        if(this.remainAmountCL<this.loansAccountProduct.product.minPrincipal){
          this.loansAccountDetailsForm.disable({emitEvent: false});
          this.loansAccountDetailsForm.get('productId').enable({emitEvent: false});
          this.loansAccountDetailsForm.get('productId').setErrors({remainAmountIsNotEnough:true})
        }else{
          this.loansAccountDetailsForm.enable({emitEvent: false});
          this.loansAccountDetailsForm.get('numberOfRepayments').disable({emitEvent: false});
        }
        this.loansAccountProductTemplate.emit(response);
        this.loanOfficerOptions = response.loanOfficerOptions;
        this.loanPurposeOptions = response.loanPurposeOptions;
        this.fundOptions = response.fundOptions;
        this.accountLinkingOptions = response.accountLinkingOptions;
        this.onchangeProduct()
      });
    });
  }

  onSliderChange(value: Number) {
    // console.log("This is emitted as the thumb slides");
    // console.log(value);
    this.loansAccountDetailsForm.get("repaymentEvery").setValue(value);
    // this.loansAccountDetailsForm.get("repaymentEvery").value
  }

  /**
   * Returns loans account details form value.
   */
  get loansAccountDetails() {
    return this.loansAccountDetailsForm.getRawValue();
  }

  goToNextStep() {
    const formData = this.simulationFormData();
    this.authenticationService.simulateLoan(formData,this.token).subscribe((response: any) => {
      this.nextPeriod = {...response?.periods[1], lastdueDate: response?.periods[response?.periods?.length-1]?.dueDate};
      this._stepper.next();
    });
  }

  simulationFormData(){
    const values = {
      ...this.loansAccountDetails,
      repaymentFrequencyNthDayType: undefined
    };

    const locale = this.settingsService.language.code;
    const dateFormat = 'yyyy-MM-dd';
    const formData = {
      ...values,
      interestRatePerPeriod: this.loansAccountProduct.interestRatePerPeriod,
      amortizationType: this.loansAccountProduct.amortizationType?.id,
      interestType: this.loansAccountProduct.interestType?.id,
      transactionProcessingStrategyId: this.loansAccountProduct?.transactionProcessingStrategyId,
      expectedDisbursementDate: this.dateUtils.formatDate(values.expectedDisbursementDate, dateFormat),
      submittedOnDate: this.dateUtils.formatDate(values.submittedOnDate, dateFormat),
      interestCalculationPeriodType: values.loanTermFrequencyType,
      repaymentFrequencyType: values.loanTermFrequencyType,
      locale: locale,
      dateFormat: dateFormat,
      loanType: "individual",
      clientId: undefined,
      loanPurposeId: undefined,
      charges: this.loansAccountProduct?.charges?.map((charge:any)=>({
        chargeId: charge?.chargeId,
        amount: charge?.amount
      }))
    }
    return formData;
  }

  simulate() {
    const simulData = {
      productName:this.productData.find((prod:any)=>prod.id===Number(this.loansAccountDetails.productId))?.name,
      repaymentFrequencyType: this.termFrequencyTypeData.find((type:any)=>type.id===Number(this.loansAccountDetails.repaymentFrequencyType))?.code,
      loanTermFrequency: this.loansAccountDetails?.loanTermFrequency,
    }
    const formData = this.simulationFormData()
    this.authenticationService.simulateLoan(formData,this.token).subscribe((response: any) => {
      this.simulation = {
        totalPrincipalDisbursed: response?.totalPrincipalDisbursed,
        totalDueForPeriod: response?.periods[1]?.totalDueForPeriod,
        totalRepaymentExpected: response?.totalRepaymentExpected,
        charge: response?.totalFeeChargesCharged,
        currency: response?.currency?.code,
        ...simulData,
         }
      const SimulatorDialogComponentRef = this.dialog.open(SimulatorDialogComponent, {
        data: this.simulation
      });
    });
  }

}
