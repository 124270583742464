<div class="container">

  <mat-card>

    <form [formGroup]="productMixForm" (ngSubmit)="submit()">

      <mat-card-content>

        <div fxLayout="column">

          <mat-form-field>
            <mat-label> {{"labels.inputs.product" | translate }} </mat-label>
            <input matInput formControlName="productId">
          </mat-form-field>

          <mat-form-field *ngIf="productData">
            <mat-label> {{"labels.inputs.restrictedproducts" | translate }} </mat-label>
            <mat-select required formControlName="restrictedProducts" multiple>
              <mat-option *ngFor="let product of productData" [value]="product.id">
                {{ product.name }}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="productMixForm.controls.restrictedProducts.hasError('required')"> {{"labels.atLeastOneRestrictedProduct" | translate }} <strong> {{"labels.mustSelected" | translate }} </strong>
            </mat-error>
          </mat-form-field>
        </div>

      </mat-card-content>

      <mat-card-actions fxLayout="row" fxLayout.xs="column" fxLayoutAlign="center" fxLayoutGap="5px">
        <button type="button" mat-raised-button [routerLink]="['../']">{{"labels.buttons.cancel" | translate}}</button>
        <button mat-raised-button color="primary" [disabled]="!productMixForm.valid" *mifosxHasPermission="'UPDATE_PRODUCTMIX'">{{"labels.buttons.submit" | translate}}</button>
      </mat-card-actions>

    </form>

  </mat-card>

</div>
