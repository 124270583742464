<h1 mat-dialog-title>{{"labels.buttons.changepassword" | translate}}</h1>

<div mat-dialog-content>

  <form [formGroup]="changePasswordForm">

    <div fxLayout="column">

      <mat-form-field>
        <mat-label>{{"labels.inputs.Password" | translate }}</mat-label>
        <input matInput formControlName="password" type="password">
        <mat-error *ngIf="changePasswordForm.controls.password.hasError('required')">{{"labels.inputs.Password" | translate }} <strong>{{"labels.commons.is required" | translate }}</strong>
        </mat-error>
        <mat-error *ngIf="changePasswordForm.controls.password.hasError('minlength')">
          {{"labels.passwordShouldBeAtLeast" | translate}} <strong>{{"labels.8characterslong" | translate}}</strong>
        </mat-error>
        <mat-error *ngIf="changePasswordForm.controls.password.hasError('maxlength')">
          {{"labels.passwordShouldNotBeMoreThan" | translate}} <strong>{{"labels.50CharactersLong" | translate}}</strong>
        </mat-error>
        <mat-error *ngIf="changePasswordForm.controls.password.hasError('pattern') && !(changePasswordForm.controls.password.hasError('minlength') || changePasswordForm.controls.password.hasError('maxlength'))">
          {{"labels.passwordShouldIncludeA" | translate}} <strong>{{"labels.numeral" | translate}}</strong> {{"labels.andLeastOneUppercaseLowercaseSpecialCharacter" | translate}}
        </mat-error>
      </mat-form-field>

      <mat-form-field>
        <mat-label>{{"labels.inputs.repeatpassword" | translate}}</mat-label>
        <input matInput formControlName="repeatPassword" type="password">
        <mat-error *ngIf="changePasswordForm.controls.repeatPassword.hasError('required')">{{"labels.inputs.Password" | translate }} <strong>{{"labels.commons.is required" | translate }}</strong>
        </mat-error>
        <mat-error *ngIf="changePasswordForm.controls.repeatPassword.hasError('notequal')">{{"labels.inputs.Password" | translate }}<strong>{{"labels.doNotMatch" | translate }}</strong>
        </mat-error>
      </mat-form-field>

    </div>

  </form>

</div>

<mat-dialog-actions align="center">
  <button mat-raised-button mat-dialog-close>{{"labels.buttons.cancel" | translate}}</button>
  <button mat-raised-button color="warn" [disabled]="!changePasswordForm.valid" [mat-dialog-close]="{password:changePasswordForm.get('password').value,repeatPassword:changePasswordForm.get('repeatPassword').value}">{{"labels.buttons.confirm" | translate}}</button>
</mat-dialog-actions>
