<mifosx-web-views-top-bar title="labels.repaymentHistory"></mifosx-web-views-top-bar>
<div class="container">
  <div class="content">
    <h2>{{loanDetailsData?.loanProductName}}</h2>
    <mat-card *ngFor="let tran of loanDetailsData?.transactions">
      <mat-card-content>
        <h3>{{tran.type.value}}</h3>
        <div class="details">
          {{tran.currency.displaySymbol}} {{tran.amount}}
          <span>{{tran?.date | dateFormat}}</span>
        </div>
      </mat-card-content>
    </mat-card>
  </div>
  <mifosx-web-views-logo></mifosx-web-views-logo>
</div>
