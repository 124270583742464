<form [formGroup]="loanProductAccountingForm">

  <div fxLayout="row wrap" fxLayoutGap="2%" fxLayout.lt-md="column">

    <mat-radio-group fxFlex="98%" fxLayout="row" fxLayoutGap="5%" fxLayout.lt-md="column" formControlName="accountingRule">
      <mat-radio-button *ngFor="let accountingRule of accountingRuleData; let i =  index" [value]="i+1">
        {{ accountingRule }}
      </mat-radio-button>
    </mat-radio-group>

    <mat-divider fxFlex="98%"></mat-divider>

    <div *ngIf="loanProductAccountingForm.value.accountingRule >= 2 && loanProductAccountingForm.value.accountingRule <= 4" fxFlexFill fxLayout="row wrap" fxLayoutGap="2%" fxLayout.lt-md="column">

      <h4 fxFlex="98%" class="mat-h4">{{"labels.assets" | translate}} / {{"labels.liability" | translate}}</h4>

      <mat-form-field fxFlex="48%">
        <mat-label>{{"labels.inputs.fundsource" | translate}}</mat-label>
        <mat-select formControlName="fundSourceAccountId" required>
          <mat-option *ngFor="let assetAndLiabilityAccount of assetAndLiabilityAccountData" [value]="assetAndLiabilityAccount.id">
            ({{ assetAndLiabilityAccount.glCode }}) {{ assetAndLiabilityAccount.name }}
          </mat-option>
        </mat-select>
        <mat-error>
          {{"labels.inputs.fundsource" | translate}} <strong>{{"labels.commons.is required" | translate}}</strong>
        </mat-error>
      </mat-form-field>

      <h4 fxFlex="98%" class="mat-h4">{{"labels.assets" | translate}}</h4>

      <mat-form-field fxFlex="48%">
        <mat-label>{{"labels.inputs.loanportfolio" | translate}}</mat-label>
        <mat-select formControlName="loanPortfolioAccountId" required>
          <mat-option *ngFor="let assetAccount of assetAccountData" [value]="assetAccount.id">
            ({{ assetAccount.glCode }}) {{ assetAccount.name }}
          </mat-option>
        </mat-select>
        <mat-error>
          {{"labels.inputs.loanportfolio" | translate}} <strong>{{"labels.commons.is required" | translate}}</strong>
        </mat-error>
      </mat-form-field>

      <div *ngIf="loanProductAccountingForm.value.accountingRule === 3 || loanProductAccountingForm.value.accountingRule === 4" fxFlexFill fxLayout="row wrap" fxLayoutGap="2%" fxLayout.lt-md="column">

        <mat-form-field fxFlex="48%">
          <mat-label>{{"labels.inputs.receivableinterest" | translate}}</mat-label>
          <mat-select formControlName="receivableInterestAccountId" required>
            <mat-option *ngFor="let assetAccount of assetAccountData" [value]="assetAccount.id">
              ({{ assetAccount.glCode }}) {{ assetAccount.name }}
            </mat-option>
          </mat-select>
          <mat-error>
            {{"labels.inputs.receivableinterest" | translate}} <strong>{{"labels.commons.is required" | translate}}</strong>
          </mat-error>
        </mat-form-field>

        <mat-form-field fxFlex="48%">
          <mat-label>{{"labels.inputs.receivablefees" | translate}}</mat-label>
          <mat-select formControlName="receivableFeeAccountId" required>
            <mat-option *ngFor="let assetAccount of assetAccountData" [value]="assetAccount.id">
              ({{ assetAccount.glCode }}) {{ assetAccount.name }}
            </mat-option>
          </mat-select>
          <mat-error>
            {{"labels.inputs.receivablefees" | translate}} <strong>{{"labels.commons.is required" | translate}}</strong>
          </mat-error>
        </mat-form-field>

        <mat-form-field fxFlex="48%">
          <mat-label>{{"labels.inputs.receivablepenalties" | translate}}</mat-label>
          <mat-select formControlName="receivablePenaltyAccountId" required>
            <mat-option *ngFor="let assetAccount of assetAccountData" [value]="assetAccount.id">
              ({{ assetAccount.glCode }}) {{ assetAccount.name }}
            </mat-option>
          </mat-select>
          <mat-error>
            {{"labels.inputs.receivablepenalties" | translate}} <strong>{{"labels.commons.is required" | translate}}</strong>
          </mat-error>
        </mat-form-field>

      </div>

      <mat-form-field fxFlex="48%">
        <mat-label>{{"labels.inputs.transfersinsuspense" | translate}}</mat-label>
        <mat-select formControlName="transfersInSuspenseAccountId" required>
          <mat-option *ngFor="let assetAccount of assetAccountData" [value]="assetAccount.id">
            ({{ assetAccount.glCode }}) {{ assetAccount.name }}
          </mat-option>
        </mat-select>
        <mat-error>
          {{"labels.inputs.transfersinsuspense" | translate}} <strong>{{"labels.commons.is required" | translate}}</strong>
        </mat-error>
      </mat-form-field>

      <mat-divider fxFlex="98%"></mat-divider>

      <h4 fxFlex="98%" class="mat-h4">{{"labels.heading.income" | translate}} </h4>

      <mat-form-field fxFlex="48%">
        <mat-label>{{"labels.heading.incomefrominterest" | translate}}</mat-label>
        <mat-select formControlName="interestOnLoanAccountId" required>
          <mat-option *ngFor="let incomeAccount of incomeAccountData" [value]="incomeAccount.id">
            ({{ incomeAccount.glCode }}) {{ incomeAccount.name }}
          </mat-option>
        </mat-select>
        <mat-error>
          {{"labels.heading.incomefrominterest" | translate}} <strong>{{"labels.commons.is required" | translate}}</strong>
        </mat-error>
      </mat-form-field>

      <mat-form-field fxFlex="48%">
        <mat-label>{{"labels.heading.incomefromfees" | translate}} </mat-label>
        <mat-select formControlName="incomeFromFeeAccountId" required>
          <mat-option *ngFor="let incomeAccount of incomeAccountData" [value]="incomeAccount.id">
            ({{ incomeAccount.glCode }}) {{ incomeAccount.name }}
          </mat-option>
        </mat-select>
        <mat-error>
          {{"labels.heading.incomefromfees" | translate}} <strong>{{"labels.commons.is required" | translate}}</strong>
        </mat-error>
      </mat-form-field>

      <mat-form-field fxFlex="48%">
        <mat-label>{{"labels.inputs.incomefrompenalties" | translate}} </mat-label>
        <mat-select formControlName="incomeFromPenaltyAccountId" required>
          <mat-option *ngFor="let incomeAccount of incomeAccountData" [value]="incomeAccount.id">
            ({{ incomeAccount.glCode }}) {{ incomeAccount.name }}
          </mat-option>
        </mat-select>
        <mat-error>
          {{"labels.inputs.incomefrompenalties" | translate}} <strong>{{"labels.commons.is required" | translate}}</strong>
        </mat-error>
      </mat-form-field>

      <mat-form-field fxFlex="48%">
        <mat-label>{{"labels.heading.recoverypayments" | translate}}</mat-label>
        <mat-select formControlName="incomeFromRecoveryAccountId" required>
          <mat-option *ngFor="let incomeAccount of incomeAccountData" [value]="incomeAccount.id">
            ({{ incomeAccount.glCode }}) {{ incomeAccount.name }}
          </mat-option>
        </mat-select>
        <mat-error>
          {{"labels.heading.recoverypayments" | translate}} <strong>{{"labels.commons.is required" | translate}}</strong>
        </mat-error>
      </mat-form-field>

      <mat-divider fxFlex="98%"></mat-divider>

      <h4 fxFlex="98%" class="mat-h4">{{"labels.heading.expenses" | translate}} </h4>

      <mat-form-field fxFlex="48%">
        <mat-label>{{"labels.heading.loseswrittenoff" | translate}}</mat-label>
        <mat-select formControlName="writeOffAccountId" required>
          <mat-option *ngFor="let expenseAccount of expenseAccountData" [value]="expenseAccount.id">
            ({{ expenseAccount.glCode }}) {{ expenseAccount.name }}
          </mat-option>
        </mat-select>
        <mat-error>
          {{"labels.heading.loseswrittenoff" | translate}} <strong>{{"labels.commons.is required" | translate}}</strong>
        </mat-error>
      </mat-form-field>

      <mat-form-field fxFlex="48%">
        <mat-label>{{"labels.goodwillCredit" | translate}}</mat-label>
        <mat-select formControlName="goodwillCreditAccountId" required>
          <mat-option *ngFor="let expenseAccount of expenseAccountData" [value]="expenseAccount.id">
            ({{ expenseAccount.glCode }}) {{ expenseAccount.name }}
          </mat-option>
        </mat-select>
        <mat-error>
          {{"labels.goodwillCredit" | translate}} <strong>{{"labels.commons.is required" | translate}}</strong>
        </mat-error>
      </mat-form-field>

      <mat-divider fxFlex="98%"></mat-divider>

      <h4 fxFlex="98%" class="mat-h4">{{"labels.heading.liabilities" | translate}} </h4>

      <mat-form-field fxFlex="48%">
        <mat-label>{{"labels.inputs.overpaymentliability" | translate}}</mat-label>
        <mat-select formControlName="overpaymentLiabilityAccountId" required>
          <mat-option *ngFor="let liabilityAccount of liabilityAccountData" [value]="liabilityAccount.id">
            ({{ liabilityAccount.glCode }}) {{ liabilityAccount.name }}
          </mat-option>
        </mat-select>
        <mat-error>
          {{"labels.inputs.overpaymentliability" | translate}} <strong>{{"labels.commons.is required" | translate}}</strong>
        </mat-error>
      </mat-form-field>

      <mat-divider fxFlex="98%"></mat-divider>

      <h3 fxFlex="23%" class="mat-h3">{{"labels.heading.advancedaccountingrules" | translate}} </h3>

      <mat-checkbox fxFlex="73%" formControlName="advancedAccountingRules"></mat-checkbox>

      <div *ngIf="loanProductAccountingForm.value.advancedAccountingRules" fxFlexFill fxLayout="row wrap" fxLayoutGap="2%" fxLayout.lt-md="column">

        <h4 fxFlex="33%" class="mat-h4">{{"labels.heading.configurefundsourcesforpaymentchannels" | translate}} </h4>

        <div fxFlex="63%">
          <button type="button" mat-raised-button color="primary" (click)="add('PaymentFundSource', paymentChannelToFundSourceMappings)">
            <fa-icon icon="plus"></fa-icon>&nbsp;&nbsp;
            {{"labels.buttons.Add" | translate}}
          </button>
        </div>

        <table fxFlex="98%" class="mat-elevation-z1" mat-table [dataSource]="paymentChannelToFundSourceMappings.value" *ngIf="paymentChannelToFundSourceMappings.value.length !== 0">

          <ng-container matColumnDef="paymentTypeId">
            <th mat-header-cell *matHeaderCellDef>{{"labels.heading.paymenttype" | translate}} </th>
            <td mat-cell *matCellDef="let paymentFundSource">
              {{ paymentFundSource.paymentTypeId | find:paymentTypeData:'id':'name' }}
            </td>
          </ng-container>

          <ng-container matColumnDef="fundSourceAccountId">
            <th mat-header-cell *matHeaderCellDef>{{"labels.heading.fundsource" | translate}} </th>
            <td mat-cell *matCellDef="let paymentFundSource">
              {{ paymentFundSource.fundSourceAccountId | find:assetAccountData:'id':'name' }}
            </td>
          </ng-container>

          <ng-container matColumnDef="actions">
            <th mat-header-cell *matHeaderCellDef>{{"labels.inputs.actions" | translate}} </th>
            <td mat-cell *matCellDef="let paymentFundSource; let i = index">
              <button mat-icon-button color="primary" (click)="edit('PaymentFundSource', paymentChannelToFundSourceMappings, i)">
                <fa-icon icon="edit"></fa-icon>
              </button>
              <button mat-icon-button color="warn" (click)="delete(paymentChannelToFundSourceMappings, i)">
                <fa-icon icon="trash"></fa-icon>
              </button>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="paymentFundSourceDisplayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: paymentFundSourceDisplayedColumns;"></tr>

        </table>

        <h4 fxFlex="33%" class="mat-h4">{{"labels.mapFeesSpecificIncomeAccounts" | translate}} </h4>

        <div fxFlex="63%">
          <button type="button" mat-raised-button color="primary" (click)="add('FeesIncome', feeToIncomeAccountMappings)">
            <fa-icon icon="plus"></fa-icon>&nbsp;&nbsp;
            {{"labels.buttons.Add" | translate}}
          </button>
        </div>

        <table fxFlex="98%" class="mat-elevation-z1" mat-table [dataSource]="feeToIncomeAccountMappings.value" *ngIf="feeToIncomeAccountMappings.value.length !== 0">

          <ng-container matColumnDef="chargeId">
            <th mat-header-cell *matHeaderCellDef>{{"labels.commons.fees" | translate}}:</th>
            <td mat-cell *matCellDef="let feesIncome">
              {{ feesIncome.chargeId | find:chargeData:'id':'name' }}
            </td>
          </ng-container>

          <ng-container matColumnDef="incomeAccountId">
            <th mat-header-cell *matHeaderCellDef>{{"labels.heading.incomeaccount" | translate}} </th>
            <td mat-cell *matCellDef="let feesIncome">
              {{ feesIncome.incomeAccountId | find:incomeAndLiabilityAccountData:'id':'name' }}
            </td>
          </ng-container>

          <ng-container matColumnDef="actions">
            <th mat-header-cell *matHeaderCellDef>{{"labels.inputs.actions" | translate}} </th>
            <td mat-cell *matCellDef="let feesIncome; let i = index">
              <button mat-icon-button color="primary" (click)="edit('FeesIncome', feeToIncomeAccountMappings, i)">
                <fa-icon icon="edit"></fa-icon>
              </button>
              <button mat-icon-button color="warn" (click)="delete(feeToIncomeAccountMappings, i)">
                <fa-icon icon="trash"></fa-icon>
              </button>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="feesPenaltyIncomeDisplayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: feesPenaltyIncomeDisplayedColumns;"></tr>

        </table>

        <h4 fxFlex="33%" class="mat-h4">{{"labels.heading.mappenaltiestospecificincomeaccounts" | translate}}</h4>

        <div fxFlex="63%">
          <button type="button" mat-raised-button color="primary" (click)="add('PenaltyIncome', penaltyToIncomeAccountMappings)">
            <fa-icon icon="plus"></fa-icon>&nbsp;&nbsp;
            {{"labels.buttons.Add" | translate}}
          </button>
        </div>


        <table fxFlex="98%" class="mat-elevation-z1" mat-table [dataSource]="penaltyToIncomeAccountMappings.value" *ngIf="penaltyToIncomeAccountMappings.value.length !== 0">

          <ng-container matColumnDef="chargeId">
            <th mat-header-cell *matHeaderCellDef>{{"labels.commons.penalty" | translate}} </th>
            <td mat-cell *matCellDef="let penaltyIncome">
              {{ penaltyIncome.chargeId | find:penaltyData:'id':'name' }}
            </td>
          </ng-container>

          <ng-container matColumnDef="incomeAccountId">
            <th mat-header-cell *matHeaderCellDef>{{"labels.heading.incomeaccount" | translate}} </th>
            <td mat-cell *matCellDef="let penaltyIncome">
              {{ penaltyIncome.incomeAccountId | find:incomeAccountData:'id':'name' }}
            </td>
          </ng-container>

          <ng-container matColumnDef="actions">
            <th mat-header-cell *matHeaderCellDef>{{"labels.inputs.actions" | translate}} </th>
            <td mat-cell *matCellDef="let penaltyIncome; let i = index">
              <button mat-icon-button color="primary" (click)="edit('PenaltyIncome', penaltyToIncomeAccountMappings, i)">
                <fa-icon icon="edit"></fa-icon>
              </button>
              <button mat-icon-button color="warn" (click)="delete(penaltyToIncomeAccountMappings, i)">
                <fa-icon icon="trash"></fa-icon>
              </button>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="feesPenaltyIncomeDisplayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: feesPenaltyIncomeDisplayedColumns;"></tr>

        </table>

      </div>

    </div>

  </div>

  <div fxLayout="row" class="margin-t" fxLayout.xs="column" fxLayoutAlign="center" fxLayoutGap="2%">
    <button mat-raised-button matStepperPrevious>
      <fa-icon icon="arrow-left"></fa-icon>&nbsp;&nbsp;
      {{"labels.buttons.previous" | translate}}
    </button>
    <button mat-raised-button matStepperNext [disabled]="!loanProductFormValid">
      {{"labels.buttons.next" | translate}}&nbsp;&nbsp;
      <fa-icon icon="arrow-right"></fa-icon>
    </button>
  </div>

</form>
