<mifosx-web-views-top-bar title="labels.buttons.Dashboard" [main]="true"></mifosx-web-views-top-bar>
<div class="container tabs" *ngIf="clientCLData">
  <div class="header">
    <mifosx-card>
      <div class="item-card">
        <span>{{"labels.creditLimit" | translate}}:</span>
        <span class="amount"><strong>{{clientCLData?.limitAmount}}</strong> {{clientCLData?.currency?.code}}</span>
      </div>
      <div class="item-card">
        <span>{{"labels.limitUtilized" | translate}}:</span>
        <span class="amount"><strong>{{clientCLData?.limitAmount - clientCLData?.remainAmount}}</strong>
          {{clientCLData?.currency?.code}}</span>
      </div>
      <div class="item-card">
        <span>{{"labels.limitAvailable" | translate}}:</span>
        <span class="amount limit"><strong>{{clientCLData?.remainAmount}}</strong>
          {{clientCLData?.currency?.code}}</span>
      </div>
    </mifosx-card>
    <br />
  </div>
  <nav>
    <a [routerLink]="['./dashboard']" routerLinkActive #dashboard="routerLinkActive"
      [ngClass]="{'active': dashboard.isActive}">
      {{"labels.anchors.home" | translate}}
    </a>
    <a [routerLink]="['./history']" routerLinkActive #history="routerLinkActive" routerLinkActive="history**"
      [ngClass]="{'active': history.isActive}">
      {{"labels.history" | translate}}
    </a>
    <a [routerLink]="['./products']" routerLinkActive #lproducts="routerLinkActive"
      [ngClass]="{'active': lproducts.isActive}">
      {{"labels.heading.products" | translate}}
    </a>
  </nav>
  <div class="content">

    <router-outlet [data]="clientCLData"></router-outlet>
  </div>
  <mifosx-web-views-logo></mifosx-web-views-logo>

  <button mat-raised-button color="accent" class="request_btn" [routerLink]="['create/']"
    *ngIf="clientCLData?.remainAmount && showLoanRequestBtn">
    {{"labels.loanRequest" | translate}}
  </button>
</div>
<mifosx-web-view-loading *ngIf="clientCLData"></mifosx-web-view-loading>
