import { ClientsService } from 'app/clients/clients.service';
import { AuthenticationInterceptor } from './../../../core/authentication/authentication.interceptor';
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AccountingService } from 'app/accounting/accounting.service';

@Component({
  selector: 'mifosx-web-view-dashboard-loan',
  templateUrl: './web-view-dashboard-loan.component.html',
  styleUrls: ['./web-view-dashboard-loan.component.scss']
})
export class WebViewDashboardLoanComponent implements OnInit {

  openLoansColumns: string[] = ['Account No', 'Original Loan', 'Amount Paid', 'Actions'];

  loanAccounts: any[] = [];
  history: any[] = [];
  token: string;
  clientId: string;
  // clientData: any;
  clientCLData: any;
  loanProducts:any;

  showLoanRequestBtn = false;

  constructor(private route: ActivatedRoute,
    private router:Router,
    private authenticationInterceptor: AuthenticationInterceptor,
    private clientsService: ClientsService,
    private accountingService: AccountingService
  ) {
    this.clientId = this.route.snapshot.params['clientId'];
    this.token = this.route.snapshot.queryParams['token'];
    if(this.token){
      this.authenticationInterceptor.setAuthorizationToken(this.token);
    }
  }

  ngOnInit(): void {
      this.clientsService.getCreditLinesTemplate(this.clientId).subscribe((data: any) => {
        this.clientCLData = data[0];
      });
      this.clientsService.getClientSummary(this.clientId).subscribe((data: any) => {
        this.showLoanRequestBtn = (data.length===0 ||data[0]?.activeLoans===0)
      });

  }



}
