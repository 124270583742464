<div fxLayout="row" fxLayoutAlign="end" fxLayoutGap="2%" fxLayout.lt-md="column" class="container m-b-20">
  <button mat-raised-button color="primary" [routerLink]="['edit']" *mifosxHasPermission="'UPDATE_LOANPRODUCT'">
    <fa-icon icon="edit"></fa-icon>&nbsp;&nbsp;
    {{"labels.buttons.edit" | translate}}
  </button>
</div>

<div class="container">

  <mat-card>

    <mat-card-content>

      <div fxLayout="row wrap" fxLayout.lt-md="column">

        <h2 class="mat-h2" fxFlexFill>{{ loanProduct.name }}</h2>

        <mat-divider [inset]="true"></mat-divider>

        <h3 class="mat-h3" fxFlexFill>{{"labels.buttons.details" | translate}}</h3>

        <mat-divider [inset]="true"></mat-divider>

        <div fxFlexFill>
          <span fxFlex="40%">{{"labels.inputs.product.shortname" | translate}}:</span>
          <span fxFlex="60%">{{ loanProduct.shortName }}</span>
        </div>

        <div fxFlexFill *ngIf="loanProduct.fundId">
          <span fxFlex="40%">{{"labels.heading.fundname" | translate }}:</span>
          <span fxFlex="60%">{{ loanProduct.fundName }}</span>
        </div>

        <div fxFlexFill>
          <span fxFlex="40%">{{"labels.heading.includeborrowercycle" | translate }}:</span>
          <span fxFlex="60%">{{ loanProduct.includeInBorrowerCycle ? 'Yes' : 'No' }}</span>
        </div>

        <div fxFlexFill *ngIf="loanProduct.startDate">
          <span fxFlex="40%">{{"labels.inputs.startdate" | translate }}:</span>
          <span fxFlex="60%">{{ loanProduct.startDate  | dateFormat }}</span>
        </div>

        <div fxFlexFill *ngIf="loanProduct.closeDate">
          <span fxFlex="40%">{{"labels.inputs.closedate" | translate }}:</span>
          <span fxFlex="60%">{{ loanProduct.closeDate  | dateFormat }}</span>
        </div>

        <div fxFlexFill *ngIf="loanProduct.description">
          <span fxFlex="40%">{{"labels.heading.description" | translate}}:</span>
          <span fxFlex="60%">{{ loanProduct.description }}</span>
        </div>

        <h3 class="mat-h3" fxFlexFill>{{"labels.inputs.currency" | translate}}</h3>

        <mat-divider [inset]="true"></mat-divider>

        <div fxFlexFill>
          <span fxFlex="40%">{{"labels.heading.currency" | translate}}:</span>
          <span fxFlex="60%">{{ loanProduct.currency.name }}</span>
        </div>

        <div fxFlexFill>
          <span fxFlex="40%">{{"labels.inputs.decimalplace" | translate}}:</span>
          <span fxFlex="60%">{{ loanProduct.currency.decimalPlaces }}</span>
        </div>

        <div fxFlexFill>
          <span fxFlex="40%">{{"labels.heading.currencyinmultiplesof" | translate}}:</span>
          <span fxFlex="60%">{{ loanProduct.currency.inMultiplesOf }}</span>
        </div>

        <div fxFlexFill>
          <span fxFlex="40%">{{"labels.inputs.installmentinmultiplesof" | translate }}:</span>
          <span fxFlex="60%">{{ loanProduct.installmentAmountInMultiplesOf }}</span>
        </div>

        <h3 class="mat-h3" fxFlexFill>{{"labels.terms" | translate}}</h3>

        <mat-divider [inset]="true"></mat-divider>

        <div fxFlexFill>
          <span fxFlex="40%">{{"labels.inputs.principalf" | translate }}:</span>
          <span fxFlex="60%">{{ loanProduct.principal + ' (Min: ' + (loanProduct.minPrincipal ? loanProduct.minPrincipal : loanProduct.principal) + ', Max: ' + (loanProduct.maxPrincipal ? loanProduct.maxPrincipal : loanProduct.principal) + ')' }}</span>
        </div>

        <div fxFlexFill>
          <span fxFlex="40%">{{"labels.allowApprovedDisbursedAmountsOverApplied" | translate }}:</span>
          <span fxFlex="60%">{{ loanProduct.allowApprovedDisbursedAmountsOverApplied ? 'Yes' : 'No' }}</span>
        </div>

        <div fxFlexFill *ngIf="loanProduct.allowApprovedDisbursedAmountsOverApplied">
          <span fxFlex="40%">{{"labels.overAppliedAmount" | translate}}:</span>
          <span fxFlex="60%" *ngIf="loanProduct.overAppliedCalculationType === 'percentage'">{{ loanProduct.overAppliedNumber }} %</span>
          <span fxFlex="60%" *ngIf="loanProduct.overAppliedCalculationType === 'flat'">{{ loanProduct.overAppliedNumber }} {{ loanProduct.currencyCode }}</span>
        </div>

        <div fxFlexFill>
          <span fxFlex="40%">{{"labels.inputs.numofrepayments" | translate }}:</span>
          <span fxFlex="60%">{{ loanProduct.numberOfRepayments + ' (Min: ' + (loanProduct.minNumberOfRepayments ? loanProduct.minNumberOfRepayments : loanProduct.numberOfRepayments) + ', Max: ' + (loanProduct.maxNumberOfRepayments ? loanProduct.maxNumberOfRepayments : loanProduct.numberOfRepayments) + ')' }}</span>
        </div>

        <div fxFlexFill>
          <span fxFlex="40%">{{"labels.linedtofloatinginterestrates" | translate }}:</span>
          <span fxFlex="60%">{{ loanProduct.isLinkedToFloatingInterestRates ? 'Yes' : 'No' }}</span>
        </div>

        <div fxFlexFill *ngIf="!loanProduct.isLinkedToFloatingInterestRates">
          <span fxFlex="40%">{{"labels.inputs.nominalinterestrate" | translate }}:</span>
          <span fxFlex="60%">{{ loanProduct.interestRatePerPeriod + ' (Min: ' + (loanProduct.minInterestRatePerPeriod ? loanProduct.minInterestRatePerPeriod : loanProduct.interestRatePerPeriod) + ', Max: ' + (loanProduct.maxInterestRatePerPeriod ? loanProduct.maxInterestRatePerPeriod : loanProduct.interestRatePerPeriod) + ')' + ' ' + loanProduct.interestRateFrequencyType.value }}</span>
        </div>

        <div fxFlexFill *ngIf="loanProduct.isLinkedToFloatingInterestRates" fxLayout="row wrap" fxLayout.lt-md="column">
          <span fxFlex="40%">{{"labels.heading.floatinglendingrate" | translate }}:</span>
          <span fxFlex="60%">{{ loanProduct.floatingRateName }}</span>
          <span fxFlex="40%">{{"labels.heading.floatinginterestratedifferential" | translate }}:</span>
          <span fxFlex="60%">{{ loanProduct.interestRateDifferential }}</span>
          <span fxFlex="40%">{{"labels.heading.isFloatingInterestRateCalculationAllowed" | translate }}:</span>
          <span fxFlex="60%">{{ loanProduct.isFloatingInterestRateCalculationAllowed ? 'Yes' : 'No' }}</span>
          <span fxFlex="40%">{{"labels.inputs.floatinginterestrate" | translate }}:</span>
          <span fxFlex="60%">{{ loanProduct.defaultDifferentialLendingRate + ' (Min: ' + (loanProduct.minDifferentialLendingRate ? loanProduct.minDifferentialLendingRate : loanProduct.defaultDifferentialLendingRate) + ', Max: ' + (loanProduct.maxDifferentialLendingRate ? loanProduct.maxDifferentialLendingRate : loanProduct.defaultDifferentialLendingRate) + ')' }}</span>
        </div>

        <div fxFlexFill>
          <span fxFlex="40%">{{"labels.inputs.useborrowerloancounter" | translate }}:</span>
          <span fxFlex="60%">{{ loanProduct.useBorrowerCycle ? 'Yes' : 'No' }}</span>
        </div>

        <div fxFlexFill *ngIf="loanProduct.principalVariationsForBorrowerCycle?.length">

          <h4 class="mat-h4" fxFlexFill>{{"labels.heading.principalCycleVariations" | translate }}:</h4>

          <table fxFlexFill class="mat-elevation-z1" mat-table [dataSource]="loanProduct.principalVariationsForBorrowerCycle">

            <ng-container matColumnDef="valueConditionType">
              <th mat-header-cell *matHeaderCellDef>{{"labels.condition" | translate }}:</th>
              <td mat-cell *matCellDef="let variation">
                {{ variation.valueConditionType.value }}
              </td>
            </ng-container>

            <ng-container matColumnDef="borrowerCycleNumber">
              <th mat-header-cell *matHeaderCellDef>{{"labels.inputs.cyclenumber" | translate }}:</th>
              <td mat-cell *matCellDef="let variation">
                {{ variation.borrowerCycleNumber }}
              </td>
            </ng-container>

            <ng-container matColumnDef="minValue">
              <th mat-header-cell *matHeaderCellDef>{{"labels.inputs.minimum" | translate }}:</th>
              <td mat-cell *matCellDef="let variation">
                {{ variation.minValue  }}
              </td>
            </ng-container>

            <ng-container matColumnDef="defaultValue">
              <th mat-header-cell *matHeaderCellDef>{{"labels.inputs.default" | translate }}:</th>
              <td mat-cell *matCellDef="let variation">
                {{ variation.defaultValue }}
              </td>
            </ng-container>

            <ng-container matColumnDef="maxValue">
              <th mat-header-cell *matHeaderCellDef>{{"labels.inputs.maximum" | translate }}:</th>
              <td mat-cell *matCellDef="let variation">
                {{ variation.maxValue }}
              </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="variationsDisplayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: variationsDisplayedColumns;"></tr>

          </table>

        </div>

        <div fxFlexFill *ngIf="loanProduct.numberOfRepaymentVariationsForBorrowerCycle?.length">

          <h4 class="mat-h4" fxFlexFill>{{"labels.heading.numerOfRepaymentCycleVariations" | translate }}:</h4>

          <table fxFlexFill class="mat-elevation-z1" mat-table [dataSource]="loanProduct.numberOfRepaymentVariationsForBorrowerCycle">

            <ng-container matColumnDef="valueConditionType">
              <th mat-header-cell *matHeaderCellDef>{{"labels.condition" | translate }}:</th>
              <td mat-cell *matCellDef="let variation">
                {{ variation.valueConditionType.value }}
              </td>
            </ng-container>

            <ng-container matColumnDef="borrowerCycleNumber">
              <th mat-header-cell *matHeaderCellDef>{{"labels.inputs.cyclenumber" | translate }}:</th>
              <td mat-cell *matCellDef="let variation">
                {{ variation.borrowerCycleNumber }}
              </td>
            </ng-container>

            <ng-container matColumnDef="minValue">
              <th mat-header-cell *matHeaderCellDef>{{"labels.inputs.minimum" | translate }}:</th>
              <td mat-cell *matCellDef="let variation">
                {{ variation.minValue  }}
              </td>
            </ng-container>

            <ng-container matColumnDef="defaultValue">
              <th mat-header-cell *matHeaderCellDef>{{"labels.inputs.default" | translate }}:</th>
              <td mat-cell *matCellDef="let variation">
                {{ variation.defaultValue }}
              </td>
            </ng-container>

            <ng-container matColumnDef="maxValue">
              <th mat-header-cell *matHeaderCellDef>{{"labels.inputs.maximum" | translate }}:</th>
              <td mat-cell *matCellDef="let variation">
                {{ variation.maxValue }}
              </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="variationsDisplayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: variationsDisplayedColumns;"></tr>

          </table>

        </div>

        <div fxFlexFill *ngIf="loanProduct.interestRateVariationsForBorrowerCycle?.length">

          <h4 class="mat-h4" fxFlexFill>{{"labels.heading.interestRateCycleVariations" | translate }}:</h4>

          <table fxFlexFill class="mat-elevation-z1" mat-table [dataSource]="loanProduct.interestRateVariationsForBorrowerCycle">

            <ng-container matColumnDef="valueConditionType">
              <th mat-header-cell *matHeaderCellDef>{{"labels.condition" | translate }}:</th>
              <td mat-cell *matCellDef="let variation">
                {{ variation.valueConditionType.value }}
              </td>
            </ng-container>

            <ng-container matColumnDef="borrowerCycleNumber">
              <th mat-header-cell *matHeaderCellDef>{{"labels.inputs.cyclenumber" | translate }}:</th>
              <td mat-cell *matCellDef="let variation">
                {{ variation.borrowerCycleNumber }}
              </td>
            </ng-container>

            <ng-container matColumnDef="minValue">
              <th mat-header-cell *matHeaderCellDef>{{"labels.inputs.minimum" | translate }}:</th>
              <td mat-cell *matCellDef="let variation">
                {{ variation.minValue  }}
              </td>
            </ng-container>

            <ng-container matColumnDef="defaultValue">
              <th mat-header-cell *matHeaderCellDef>{{"labels.inputs.default" | translate }}:</th>
              <td mat-cell *matCellDef="let variation">
                {{ variation.defaultValue }}
              </td>
            </ng-container>

            <ng-container matColumnDef="maxValue">
              <th mat-header-cell *matHeaderCellDef>{{"labels.inputs.maximum" | translate }}:</th>
              <td mat-cell *matCellDef="let variation">
                {{ variation.maxValue }}
              </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="variationsDisplayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: variationsDisplayedColumns;"></tr>

          </table>

        </div>

        <div fxFlexFill>
          <span fxFlex="40%">{{"labels.heading.repayevery" | translate }}:</span>
          <span fxFlex="60%">{{ loanProduct.repaymentEvery + ' ' + loanProduct.repaymentFrequencyType.value }}</span>
        </div>

        <div fxFlexFill *ngIf="loanProduct.minimumDaysBetweenDisbursalAndFirstRepayment">
          <span fxFlex="40%">{{"labels.inputs.minimum.days.between.disbursal.and.first.repayment" | translate }}:</span>
          <span fxFlex="60%">{{ loanProduct.minimumDaysBetweenDisbursalAndFirstRepayment }}</span>
        </div>

        <h3 class="mat-h3" fxFlexFill>{{"labels.menus.Settings" | translate}}</h3>

        <mat-divider [inset]="true"></mat-divider>

        <div fxFlexFill>
          <span fxFlex="40%">{{"labels.inputs.amortization" | translate }}:</span>
          <span fxFlex="60%">{{ loanProduct.amortizationType.value }}</span>
        </div>

        <div fxFlexFill>
          <span fxFlex="40%">{{"labels.inputs.isequalamortization" | translate}}:</span>
          <span fxFlex="60%">{{ loanProduct.isEqualAmortization ? 'Yes' : 'No' }}</span>
        </div>

        <div fxFlexFill>
          <span fxFlex="40%">{{"labels.heading.interestmethod" | translate }}:</span>
          <span fxFlex="60%">{{ loanProduct.interestType.value }}</span>
        </div>

        <div fxFlexFill>
          <span fxFlex="40%">{{"labels.heading.interestcalculationperiod" | translate }}:</span>
          <span fxFlex="60%">{{ loanProduct.interestCalculationPeriodType.value }}</span>
        </div>

        <div fxFlexFill *ngIf="loanProduct.interestCalculationPeriodType.id === 1">
          <span fxFlex="40%">{{"labels.heading.allowpartialperiodinterestcalcualtion" | translate }}:</span>
          <span fxFlex="60%">{{ loanProduct.allowPartialPeriodInterestCalcualtion ? 'Yes' : 'No' }}</span>
        </div>

        <div fxFlexFill>
          <span fxFlex="40%">{{"labels.heading.repaymentstrategy" | translate }}:</span>
          <span fxFlex="60%">{{ loanProduct.transactionProcessingStrategyName }}</span>
        </div>

        <div fxFlexFill *ngIf="loanProduct.graceOnPrincipalPayment">
          <span fxFlex="40%">{{"labels.gracePrincipalPayment" | translate }}:</span>
          <span fxFlex="60%">{{ loanProduct.graceOnPrincipalPayment }}</span>
        </div>

        <div fxFlexFill *ngIf="loanProduct.graceOnInterestPayment">
          <span fxFlex="40%">{{"labels.graceInterestPayment" | translate }}:</span>
          <span fxFlex="60%">{{ loanProduct.graceOnInterestPayment }}</span>
        </div>

        <div fxFlexFill *ngIf="loanProduct.graceOnInterestCharged">
          <span fxFlex="40%">{{"labels.inputs.interestfreeperiod" | translate }}:</span>
          <span fxFlex="60%">{{ loanProduct.graceOnInterestCharged }}</span>
        </div>

        <div fxFlexFill *ngIf="loanProduct.inArrearsTolerance">
          <span fxFlex="40%">{{"labels.heading.arearstolerance" | translate }}:</span>
          <span fxFlex="60%">{{ loanProduct.inArrearsTolerance }}</span>
        </div>

        <div fxFlexFill>
          <span fxFlex="40%">{{"labels.heading.daysinyears" | translate}}:</span>
          <span fxFlex="60%">{{ loanProduct.daysInYearType.value }}</span>
        </div>

        <div fxFlexFill>
          <span fxFlex="40%">{{"labels.inputs.daysinmonth" | translate }}:</span>
          <span fxFlex="60%">{{ loanProduct.daysInMonthType.value }}</span>
        </div>

        <div fxFlexFill>
          <span fxFlex="40%">{{"labels.inputs.candefineinstallmentamount" | translate }}:</span>
          <span fxFlex="60%">{{ loanProduct.canDefineInstallmentAmount ? 'Yes' : 'No' }}</span>
        </div>

        <div fxFlexFill *ngIf="loanProduct.graceOnArrearsAgeing">
          <span fxFlex="40%">{{"labels.inputs.no.of.overdue.days.to.move.loan.into.arrears" | translate }}:</span>
          <span fxFlex="60%">{{ loanProduct.graceOnArrearsAgeing }}</span>
        </div>

        <div fxFlexFill *ngIf="loanProduct.overdueDaysForNPA">
          <span fxFlex="40%">{{"labels.inputs.no.of.overdue.days.to.change.loan.into.npa" | translate }}:</span>
          <span fxFlex="60%">{{ loanProduct.overdueDaysForNPA }}</span>
        </div>

        <div fxFlexFill>
          <span fxFlex="40%">{{"labels.inputs.loan.account.moves.out.of.npa.only.on.arrears.completion" | translate }}:</span>
          <span fxFlex="60%">{{ loanProduct.accountMovesOutOfNPAOnlyOnArrearsCompletion ? 'Yes' : 'No' }}</span>
        </div>

        <div fxFlexFill *ngIf="loanProduct.principalThresholdForLastInstallment">
          <span fxFlex="40%">{{"labels.inputs.thresholdforlastinstallment" | translate }}:</span>
          <span fxFlex="60%">{{ loanProduct.principalThresholdForLastInstallment }}</span>
        </div>

        <div fxFlexFill>
          <span fxFlex="40%">{{"labels.inputs.variable.installments.allowed" | translate }}:</span>
          <span fxFlex="60%">{{ loanProduct.allowVariableInstallments ? 'Yes' : 'No' }}</span>
        </div>

        <div fxFlexFill *ngIf="loanProduct.allowVariableInstallments">
          <span fxFlex="40%">{{"labels.inputs.variableinstallments" | translate }}:</span>
          <span fxFlex="60%">{{ '(Min: ' + loanProduct.minimumGap + ', Max: ' + (loanProduct.maximumGap ? loanProduct.maximumGap : loanProduct.minimumGap) + ')' }}</span>
        </div>

        <div fxFlexFill>
          <span fxFlex="40%">{{"labels.inputs.canUseForTopup" | translate }}</span>
          <span fxFlex="60%">{{ loanProduct.canUseForTopup ? 'Yes' : 'No' }}</span>
        </div>

        <h3 class="mat-h3" fxFlexFill>{{"labels.heading.interestRecalculation" | translate }}:</h3>

        <mat-divider [inset]="true"></mat-divider>

        <div fxFlexFill>
          <span fxFlex="40%">{{"labels.inputs.recalculateinterest" | translate }}:</span>
          <span fxFlex="60%">{{ loanProduct.isInterestRecalculationEnabled ? 'Yes' : 'No' }}</span>
        </div>

        <div fxFlexFill *ngIf="loanProduct.isInterestRecalculationEnabled" fxLayout="row wrap" fxLayout.lt-md="column">
          <span fxFlex="40%">{{"labels.inputs.preclose.interest.calculation.strategy" | translate }}:</span>
          <span fxFlex="60%">{{ loanProduct.interestRecalculationData.preClosureInterestCalculationStrategy.value }}</span>
          <span fxFlex="40%">{{"labels.inputs.interest.recalculation.reschdule.strategy" | translate }}:</span>
          <span fxFlex="60%">{{ loanProduct.interestRecalculationData.rescheduleStrategyType.value }}</span>
          <span fxFlex="40%">{{"labels.inputs.interest.recalculation.compounding.method" | translate }}:</span>
          <span fxFlex="60%">{{ loanProduct.interestRecalculationData.interestRecalculationCompoundingType.value }}</span>
          <div fxFlexFill *ngIf="loanProduct.interestRecalculationData.interestRecalculationCompoundingType.id !== 0" fxLayout="row wrap" fxLayout.lt-md="column">
            <span fxFlex="40%">{{"labels.inputs.frequency.for.compounding" | translate }}:</span>
            <span fxFlex="60%">
              {{ loanProduct.interestRecalculationData.recalculationCompoundingFrequencyType.value }}
              <span *ngIf="loanProduct.interestRecalculationData.recalculationCompoundingFrequencyType.id === 4 && loanProduct.interestRecalculationData.recalculationCompoundingFrequencyNthDay">&nbsp;&nbsp;{{ 'on ' + loanProduct.interestRecalculationData.recalculationCompoundingFrequencyNthDay?.value }}</span>
              <span *ngIf="(loanProduct.interestRecalculationData.recalculationCompoundingFrequencyType.id === 4 && !loanProduct.interestRecalculationData.recalculationCompoundingFrequencyOnDay) || loanProduct.interestRecalculationData.recalculationCompoundingFrequencyType.id === 3">&nbsp;&nbsp;{{ loanProduct.interestRecalculationData.recalculationCompoundingFrequencyWeekday?.value }}</span>
              <span *ngIf="loanProduct.interestRecalculationData.recalculationCompoundingFrequencyType.id === 4 && loanProduct.interestRecalculationData.recalculationCompoundingFrequencyOnDay">&nbsp;&nbsp;{{ 'on day  ' + loanProduct.interestRecalculationData.recalculationCompoundingFrequencyOnDay }}</span>
            </span>
            <div fxFlexFill *ngIf="loanProduct.interestRecalculationData.recalculationCompoundingFrequencyType.id !== 1" fxLayout="row wrap" fxLayout.lt-md="column">
              <span fxFlex="40%">{{"labels.inputs.frequenc.interval.for.compounding" | translate }}:</span>
              <span fxFlex="60%">{{ loanProduct.interestRecalculationData.recalculationCompoundingFrequencyInterval }}</span>
            </div>
          </div>
          <div fxFlexFill fxLayout="row wrap" fxLayout.lt-md="column">
            <span fxFlex="40%">{{"labels.inputs.frequency.for.recalculte.outstanding.principal" | translate }}:</span>
            <span fxFlex="60%">
              {{ loanProduct.interestRecalculationData.recalculationRestFrequencyType.value }}
              <span *ngIf="loanProduct.interestRecalculationData.recalculationRestFrequencyType.id === 4 && loanProduct.interestRecalculationData.recalculationRestFrequencyNthDay">&nbsp;&nbsp;{{ 'on ' + loanProduct.interestRecalculationData.recalculationRestFrequencyNthDay?.value }}</span>
              <span *ngIf="(loanProduct.interestRecalculationData.recalculationRestFrequencyType.id === 4 && !loanProduct.interestRecalculationData.recalculationRestFrequencyOnDay) || loanProduct.interestRecalculationData.recalculationRestFrequencyType.id === 3">&nbsp;&nbsp;{{ loanProduct.interestRecalculationData.recalculationRestFrequencyWeekday?.value }}</span>
              <span *ngIf="loanProduct.interestRecalculationData.recalculationRestFrequencyType.id === 4 && loanProduct.interestRecalculationData.recalculationRestFrequencyOnDay">&nbsp;&nbsp;{{ 'on day  ' + loanProduct.interestRecalculationData.recalculationRestFrequencyOnDay }}</span>
            </span>
          </div>
          <div fxFlexFill *ngIf="loanProduct.interestRecalculationData.recalculationRestFrequencyType.id !== 1" fxLayout="row wrap" fxLayout.lt-md="column">
            <span fxFlex="40%">{{"labels.inputs.frequenc.interval.for.recalculte.outstanding.principal" | translate }}:</span>
            <span fxFlex="60%">{{ loanProduct.interestRecalculationData.recalculationRestFrequencyInterval }}</span>
            <div fxFlexFill *ngIf="loanProduct.recalculationRestFrequencyDate">
              <span fxFlex="40%">{{"labels.restFrequencyDate" | translate }}:</span>
              <span fxFlex="60%">{{ loanProduct.interestRecalculationData.recalculationRestFrequencyDate }}</span>
            </div>
          </div>
          <div fxFlexFill>
            <span fxFlex="40%">{{"labels.inputs.arrearsbasedonoriginalschedule" | translate }}:</span>
            <span fxFlex="60%">{{ loanProduct.isArrearsBasedOnOriginalSchedule ? 'Yes' : 'No' }}</span>
          </div>
        </div>

        <h3 class="mat-h3" fxFlexFill>{{"labels.heading.gauranteeRequirements" | translate }}:</h3>

        <mat-divider [inset]="true"></mat-divider>

        <div fxFlexFill>
          <span fxFlex="40%">{{"labels.inputs.holdguaranteefunds" | translate }}</span>
          <span fxFlex="60%">{{ loanProduct.holdGuaranteeFunds ? 'Yes' : 'No' }}</span>
        </div>

        <div fxFlexFill *ngIf="loanProduct.holdGuaranteeFunds" fxLayout="row wrap" fxLayout.lt-md="column">
          <span fxFlex="40%">{{"labels.inputs.mandatory.guarantee" | translate }}</span>
          <span fxFlex="60%">{{ loanProduct.productGuaranteeData.mandatoryGuarantee }}</span>
          <div fxFlexFill *ngIf="loanProduct.productGuaranteeData.minimumGuaranteeFromOwnFunds">
            <span fxFlex="40%">{{"labels.inputs.minimum.guarantee.from.own.funds" | translate }}</span>
            <span fxFlex="60%">{{ loanProduct.productGuaranteeData.minimumGuaranteeFromOwnFunds }}</span>
          </div>
          <div fxFlexFill *ngIf="loanProduct.productGuaranteeData.minimumGuaranteeFromGuarantor">
            <span fxFlex="40%">{{"labels.inputs.minimum.guarantee.from.guarantor" | translate }}</span>
            <span fxFlex="60%">{{ loanProduct.productGuaranteeData.minimumGuaranteeFromGuarantor }}</span>
          </div>
        </div>

        <h3 class="mat-h3" fxFlexFill>{{"labels.loanTrancheDetails" | translate }}</h3>

        <mat-divider [inset]="true"></mat-divider>

        <div fxFlexFill>
          <span fxFlex="40%">{{"labels.inputs.multidisburseloan" | translate }}:</span>
          <span fxFlex="60%">{{ loanProduct.multiDisburseLoan ? 'Yes' : 'No' }}</span>
        </div>

        <div fxFlexFill *ngIf="loanProduct.multiDisburseLoan" fxLayout="row wrap" fxLayout.lt-md="column">
          <span fxFlex="40%">{{"labels.inputs.maxtranchecount" | translate }}:</span>
          <span fxFlex="60%">{{ loanProduct.maxTrancheCount }}</span>
          <div fxFlexFill *ngIf="loanProduct.outstandingLoanBalance">
            <span fxFlex="40%">{{"labels.inputs.outstandingloanbalance" | translate }}:</span>
            <span fxFlex="60%">{{ loanProduct.outstandingLoanBalance }}</span>
          </div>
          <div fxFlexFill>
            <span fxFlex="40%">{{"labels.disallowExpectedDisbursements" | translate }}:</span>
            <span fxFlex="60%">{{ loanProduct.disallowExpectedDisbursements ? 'Yes' : 'No' }}</span>
          </div>
        </div>

        <h3 class="mat-h3" fxFlexFill>{{"labels.heading.configurableAttributes" | translate }}:</h3>

        <mat-divider [inset]="true"></mat-divider>

        <div fxFlexFill>
          <span fxFlex="40%">{{"labels.inputs.isConfigLoanAttributes" | translate }}:</span>
          <span fxFlex="60%">{{ loanProduct.allowAttributeConfiguration ? 'Yes' : 'No' }}</span>
        </div>

        <div fxFlexFill *ngIf="loanProduct.allowAttributeConfiguration" fxLayout="row wrap" fxLayout.lt-md="column">
          <span fxFlex="40%">{{"labels.inputs.amortization" | translate }}:</span>
          <span fxFlex="60%">{{ loanProduct.allowAttributeOverrides.amortizationType ? 'Yes' : 'No' }}</span>
          <span fxFlex="40%">{{"labels.heading.interestmethod" | translate }}:</span>
          <span fxFlex="60%">{{ loanProduct.allowAttributeOverrides.interestType ? 'Yes' : 'No' }}</span>
          <span fxFlex="40%">{{"labels.heading.repaymentstrategy" | translate }}:</span>
          <span fxFlex="60%">{{ loanProduct.allowAttributeOverrides.transactionProcessingStrategyId ? 'Yes' : 'No' }}</span>
          <span fxFlex="40%">{{"labels.heading.interestcalculationperiod" | translate }}:</span>
          <span fxFlex="60%">{{ loanProduct.allowAttributeOverrides.interestCalculationPeriodType ? 'Yes' : 'No' }}</span>
          <span fxFlex="40%">{{"labels.heading.arearstolerance" | translate }}:</span>
          <span fxFlex="60%">{{ loanProduct.allowAttributeOverrides.inArrearsTolerance ? 'Yes' : 'No' }}</span>
          <span fxFlex="40%">{{"labels.inputs.repaidevery" | translate }}:</span>
          <span fxFlex="60%">{{ loanProduct.allowAttributeOverrides.repaymentEvery ? 'Yes' : 'No' }}</span>
          <span fxFlex="40%">{{"labels.inputs.grace" | translate }}:</span>
          <span fxFlex="60%">{{ loanProduct.allowAttributeOverrides.graceOnPrincipalAndInterestPayment ? 'Yes' : 'No' }}</span>
          <span fxFlex="40%">{{"labels.inputs.no.of.overdue.days.to.move.loan.into.arrears" | translate }}:</span>
          <span fxFlex="60%">{{ loanProduct.allowAttributeOverrides.graceOnArrearsAgeing ? 'Yes' : 'No' }}</span>
          <span fxFlex="40%">{{"labels.anchors.delinquencyBuckets" | translate }}:</span>
          <span fxFlex="60%">{{ loanProduct.delinquencyBucket.id ? 'Yes' : 'No' }}</span>
        </div>

        <div fxFlexFill *ngIf="loanProduct.charges.length" fxLayout="row wrap" fxLayout.lt-md="column">

          <h3 class="mat-h3" fxFlexFill>{{"labels.anchors.charges" | translate}}</h3>

          <mat-divider [inset]="true"></mat-divider>

          <table fxFlexFill class="mat-elevation-z1" mat-table [dataSource]="loanProduct.charges | chargesPenaltyFilter:false">

            <ng-container matColumnDef="name">
              <th mat-header-cell *matHeaderCellDef>{{"labels.inputs.name" | translate}}</th>
              <td mat-cell *matCellDef="let charge">
                {{ charge.name + ', ' + charge.currency.displaySymbol }}
              </td>
            </ng-container>

            <ng-container matColumnDef="chargeCalculationType">
              <th mat-header-cell *matHeaderCellDef>{{"labels.inputs.type" | translate}}</th>
              <td mat-cell *matCellDef="let charge">
                {{ charge.chargeCalculationType.value }}
              </td>
            </ng-container>

            <ng-container matColumnDef="amount">
              <th mat-header-cell *matHeaderCellDef>{{"labels.heading.amount" | translate}}</th>
              <td mat-cell *matCellDef="let charge">
                {{ charge.amount  }}
              </td>
            </ng-container>

            <ng-container matColumnDef="chargeTimeType">
              <th mat-header-cell *matHeaderCellDef>{{"labels.heading.collectedon" | translate}}</th>
              <td mat-cell *matCellDef="let charge">
                {{ charge.chargeTimeType.value }}
              </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="chargesDisplayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: chargesDisplayedColumns;"></tr>

          </table>

          <h3 class="mat-h3" fxFlexFill>{{"labels.overdueCharges" | translate }}</h3>

          <mat-divider [inset]="true"></mat-divider>

          <table fxFlexFill class="mat-elevation-z1" mat-table [dataSource]="loanProduct.charges | chargesPenaltyFilter:true">

            <ng-container matColumnDef="name">
              <th mat-header-cell *matHeaderCellDef>{{"labels.inputs.name" | translate}}</th>
              <td mat-cell *matCellDef="let overdueCharge">
                {{ overdueCharge.name + ', ' + overdueCharge.currency.displaySymbol }}
              </td>
            </ng-container>

            <ng-container matColumnDef="chargeCalculationType">
              <th mat-header-cell *matHeaderCellDef>{{"labels.inputs.type" | translate}}</th>
              <td mat-cell *matCellDef="let overdueCharge">
                {{ overdueCharge.chargeCalculationType.value }}
              </td>
            </ng-container>

            <ng-container matColumnDef="amount">
              <th mat-header-cell *matHeaderCellDef>{{"labels.heading.amount" | translate}}</th>
              <td mat-cell *matCellDef="let overdueCharge">
                {{ overdueCharge.amount  }}
              </td>
            </ng-container>

            <ng-container matColumnDef="chargeTimeType">
              <th mat-header-cell *matHeaderCellDef>{{"labels.heading.collectedon" | translate}}</th>
              <td mat-cell *matCellDef="let overdueCharge">
                {{ overdueCharge.chargeTimeType.value }}
              </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="chargesDisplayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: chargesDisplayedColumns;"></tr>

          </table>

        </div>

        <h3 class="mat-h3" fxFlexFill>{{"labels.menus.Accounting" | translate}}</h3>

        <mat-divider [inset]="true"></mat-divider>

        <div fxFlexFill>
          <span fxFlex="40%">{{"labels.inputs.type" | translate}}:</span>
          <span fxFlex="60%">{{ loanProduct.accountingRule.value }}</span>
        </div>

        <div fxFlexFill *ngIf="loanProduct.accountingRule.id >= 2 && loanProduct.accountingRule.id <= 4" fxLayout="row wrap" fxLayout.lt-md="column">

          <h4 fxFlexFill class="mat-h4">{{"labels.assets" | translate }} / {{"labels.heading.liabilities" | translate }}</h4>

          <div fxFlexFill fxLayout="row wrap" fxLayout.lt-md="column">
            <span fxFlex="40%">{{"labels.heading.fundsource" | translate }} </span>
            <span fxFlex="60%">({{loanProduct.accountingMappings.fundSourceAccount.glCode}}) {{ loanProduct.accountingMappings.fundSourceAccount.name }}</span>
          </div>

          <h4 fxFlexFill class="mat-h4">{{"labels.assets" | translate}}</h4>

          <div fxFlexFill fxLayout="row wrap" fxLayout.lt-md="column">
            <span fxFlex="40%">{{"labels.heading.loanportfolio" | translate }} </span>
            <span fxFlex="60%">({{loanProduct.accountingMappings.loanPortfolioAccount.glCode}}) {{ loanProduct.accountingMappings.loanPortfolioAccount.name }}</span>
            <div fxFlexFill *ngIf="loanProduct.accountingRule.id === 3 || loanProduct.accountingRule.id === 4" fxLayout="row wrap" fxLayout.lt-md="column">
              <span fxFlex="40%">{{"labels.inputs.receivableinterest" | translate }} </span>
              <span fxFlex="60%">({{loanProduct.accountingMappings.receivableInterestAccount.glCode}}) {{ loanProduct.accountingMappings.receivableInterestAccount.name }}</span>
              <span fxFlex="40%">{{"labels.inputs.receivablefees" | translate }} </span>
              <span fxFlex="60%">({{loanProduct.accountingMappings.receivableInterestAccount.glCode}}) {{ loanProduct.accountingMappings.receivableInterestAccount.name }}</span>
              <span fxFlex="40%">{{"labels.inputs.receivablepenalties" | translate }} </span>
              <span fxFlex="60%">({{loanProduct.accountingMappings.receivablePenaltyAccount.glCode}}) {{ loanProduct.accountingMappings.receivablePenaltyAccount.name }}</span>
            </div>
            <span fxFlex="40%">{{"labels.inputs.transfersinsuspense" | translate }} </span>
            <span fxFlex="60%">({{loanProduct.accountingMappings.transfersInSuspenseAccount.glCode}}) {{ loanProduct.accountingMappings.transfersInSuspenseAccount.name }}</span>
          </div>

          <h4 fxFlexFill class="mat-h4">{{"labels.heading.income" | translate}} </h4>

          <div fxFlexFill fxLayout="row wrap" fxLayout.lt-md="column">
            <span fxFlex="40%">{{"labels.heading.incomefrominterest" | translate }} </span>
            <span fxFlex="60%">({{loanProduct.accountingMappings.interestOnLoanAccount.glCode}}) {{ loanProduct.accountingMappings.interestOnLoanAccount.name }}</span>
            <span fxFlex="40%">{{"labels.heading.incomefromfees" | translate}}:</span>
            <span fxFlex="60%">({{loanProduct.accountingMappings.incomeFromFeeAccount.glCode}}) {{ loanProduct.accountingMappings.incomeFromFeeAccount.name }}</span>
            <span fxFlex="40%">{{"labels.heading.incomefrompenalties" | translate}}:</span>
            <span fxFlex="60%">({{loanProduct.accountingMappings.incomeFromPenaltyAccount.glCode}}) {{ loanProduct.accountingMappings.incomeFromPenaltyAccount.name }}</span>
            <span fxFlex="40%">{{"labels.heading.recoverypayments" | translate }} </span>
            <span fxFlex="60%">({{loanProduct.accountingMappings.incomeFromRecoveryAccount.glCode}}) {{ loanProduct.accountingMappings.incomeFromRecoveryAccount.name }}</span>
          </div>

          <h4 fxFlexFill class="mat-h4">{{"labels.heading.expenses" | translate}} </h4>

          <div fxFlexFill fxLayout="row wrap" fxLayout.lt-md="column">
            <span fxFlex="40%">{{"labels.heading.loseswrittenoff" | translate }} </span>
            <span fxFlex="60%">({{loanProduct.accountingMappings.writeOffAccount.glCode}}) {{ loanProduct.accountingMappings.writeOffAccount.name }}</span>
            <span fxFlex="40%">{{"labels.goodwillCredit" | translate }} </span>
            <span fxFlex="60%">({{loanProduct.accountingMappings.goodwillCreditAccount.glCode}}) {{ loanProduct.accountingMappings.goodwillCreditAccount.name }}</span>
          </div>

          <h4 fxFlexFill class="mat-h4">{{"labels.heading.liabilities" | translate}} </h4>

          <div fxFlexFill fxLayout="row wrap" fxLayout.lt-md="column">
            <span fxFlex="40%">{{"labels.inputs.overpaymentliability" | translate }} </span>
            <span fxFlex="60%">({{loanProduct.accountingMappings.overpaymentLiabilityAccount.glCode}}) {{ loanProduct.accountingMappings.overpaymentLiabilityAccount.name }}</span>
          </div>

          <div *ngIf="loanProduct.paymentChannelToFundSourceMappings?.length || loanProduct.feeToIncomeAccountMappings?.length || loanProduct.penaltyToIncomeAccountMappings?.length" fxFlexFill fxLayout="row wrap" fxLayout.lt-md="column">

            <h3 class="mat-h3" fxFlexFill>{{"labels.heading.advancedaccountingrules" | translate}} </h3>

            <mat-divider [inset]="true"></mat-divider>

            <div *ngIf="loanProduct.paymentChannelToFundSourceMappings?.length" fxFlexFill fxLayout="row wrap" fxLayout.lt-md="column">

              <h4 class="mat-h4" fxFlexFill>{{"labels.fundsourcesforpaymentchannels" | translate}}:</h4>

              <table fxFlexFill class="mat-elevation-z1" mat-table [dataSource]="loanProduct.paymentChannelToFundSourceMappings">

                <ng-container matColumnDef="paymentTypeId">
                  <th mat-header-cell *matHeaderCellDef>{{"labels.heading.paymenttype" | translate}} </th>
                  <td mat-cell *matCellDef="let paymentFundSource">
                    {{ paymentFundSource.paymentType.name }}
                  </td>
                </ng-container>

                <ng-container matColumnDef="fundSourceAccountId">
                  <th mat-header-cell *matHeaderCellDef>{{"labels.heading.fundsource" | translate}} </th>
                  <td mat-cell *matCellDef="let paymentFundSource">
                    {{ paymentFundSource.fundSourceAccount.name }}
                  </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="paymentFundSourceDisplayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: paymentFundSourceDisplayedColumns;"></tr>

              </table>

            </div>

            <div *ngIf="loanProduct.feeToIncomeAccountMappings?.length" fxFlexFill fxLayout="row wrap" fxLayout.lt-md="column">

              <h4 class="mat-h4" fxFlexFill>{{"labels.mapFeesSpecificIncomeAccounts" | translate}}:</h4>

              <table fxFlexFill class="mat-elevation-z1" mat-table [dataSource]="loanProduct.feeToIncomeAccountMappings">

                <ng-container matColumnDef="chargeId">
                  <th mat-header-cell *matHeaderCellDef>{{"labels.commons.fees" | translate}}:</th>
                  <td mat-cell *matCellDef="let feesIncome">
                    {{ feesIncome.charge.name }}
                  </td>
                </ng-container>

                <ng-container matColumnDef="incomeAccountId">
                  <th mat-header-cell *matHeaderCellDef>{{"labels.heading.incomeaccount" | translate}} </th>
                  <td mat-cell *matCellDef="let feesIncome">
                    {{ feesIncome.incomeAccount.name }}
                  </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="feesPenaltyIncomeDisplayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: feesPenaltyIncomeDisplayedColumns;"></tr>

              </table>

            </div>

            <div *ngIf="loanProduct.penaltyToIncomeAccountMappings?.length" fxFlexFill fxLayout="row wrap" fxLayout.lt-md="column">

              <h4 class="mat-h4" fxFlexFill>{{"labels.heading.mappenaltiestospecificincomeaccounts" | translate}}:</h4>

              <table fxFlexFill class="mat-elevation-z1" mat-table [dataSource]="loanProduct.penaltyToIncomeAccountMappings">

                <ng-container matColumnDef="chargeId">
                  <th mat-header-cell *matHeaderCellDef>{{"labels.commons.penalty" | translate}} </th>
                  <td mat-cell *matCellDef="let penaltyIncome">
                    {{ penaltyIncome.charge.name }}
                  </td>
                </ng-container>

                <ng-container matColumnDef="incomeAccountId">
                  <th mat-header-cell *matHeaderCellDef>{{"labels.heading.incomeaccount" | translate}} </th>
                  <td mat-cell *matCellDef="let penaltyIncome">
                    {{ penaltyIncome.incomeAccount.name }}
                  </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="feesPenaltyIncomeDisplayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: feesPenaltyIncomeDisplayedColumns;"></tr>

              </table>

            </div>

          </div>

        </div>

      </div>

    </mat-card-content>

  </mat-card>

</div>
