<div class="container">

  <form [formGroup]="surveyForm" (ngSubmit)="submit()">

    <div fxLayout="column" fxLayoutGap="4%">

      <mat-card>

        <mat-card-content>

          <div fxLayout="row wrap" fxLayoutGap="2%" fxLayout.lt-md="column">

            <mat-form-field fxFlex="48%">
              <mat-label>{{"labels.heading.key" | translate}}</mat-label>
              <input matInput maxlength="32" required formControlName="key">
              <mat-error *ngIf="surveyForm.controls.key.hasError('required')">{{"labels.heading.key" | translate}} <strong>{{"labels.commons.is required" | translate}}</strong>
              </mat-error>
            </mat-form-field>

            <mat-form-field fxFlex="48%">
              <mat-label>{{"labels.inputs.name" | translate }}</mat-label>
              <input matInput maxlength="255" required formControlName="name">
              <mat-error *ngIf="surveyForm.controls.name.hasError('required')">{{"labels.inputs.name" | translate }} <strong>{{"labels.commons.is required" | translate}}</strong>
              </mat-error>
            </mat-form-field>

            <mat-form-field fxFlex="48%">
              <mat-label>{{"labels.heading.countrycode" | translate}}</mat-label>
              <input matInput maxlength="2" required formControlName="countryCode">
              <mat-error *ngIf="surveyForm.controls.countryCode.hasError('required')">{{"labels.heading.countrycode" | translate}} <strong>{{"labels.commons.is required" | translate}}</strong>
              </mat-error>
              <mat-error *ngIf="surveyForm.controls.countryCode.hasError('pattern')">{{"labels.heading.countrycode" | translate}}<strong>{{"labels.mustConsistOf2AlphabeticCharacters" | translate}}</strong>
              </mat-error>
            </mat-form-field>

            <mat-form-field fxFlex="98%">
              <mat-label>{{"labels.inputs.description" | translate }}</mat-label>
              <textarea matInput formControlName="description"></textarea>
            </mat-form-field>

            <div fxFlexFill fxLayout="row" fxLayout.xs="column" fxLayoutAlign="center" fxLayoutGap="5px" *ngIf="!questionDatas.length">
              <button type="button" mat-raised-button [routerLink]="['../']">{{"labels.buttons.cancel" | translate}}</button>
              <button type="button" mat-raised-button color="primary" [disabled]="!(this.surveyForm.get('key').valid && this.surveyForm.get('name').valid && this.surveyForm.get('countryCode').valid)" (click)="addQuestion()">{{"labels.buttons.proceed" | translate}}</button>
            </div>

          </div>

        </mat-card-content>

      </mat-card>

      <div cdkDropList (cdkDropListDropped)="dropQuestion($event)" fxLayout="column" fxLayoutGap="4%">

        <mat-card cdkDrag cdkDragLockAxis="y" formArrayName="questionDatas" *ngFor="let question of questionDatas.controls; let questionIndex = index; last as isLast">

          <mat-card-content>

            <div fxFlexFill fxLayoutGap="2%" fxLayout.lt-md="column" fxLayout="row wrap" [formGroupName]="questionIndex">

              <div fxFlex="98%" fxLayout="row wrap" fxLayoutGap="2%" fxLayoutAlign="space-between center">
                <h2 class="mat-h2">{{"labels.question" | translate}} {{ questionIndex + 1 }}</h2>
                <button mat-raised-button color="warn" (click)="removeQuestion(questionIndex)" [disabled]="questionDatas.controls.length === 1">
                  <fa-icon icon="trash"></fa-icon>&nbsp;&nbsp;
                  {{"labels.deleteQuestion" | translate}}
                </button>
              </div>

              <mat-form-field fxFlex="48%">
                <mat-label>{{"labels.heading.key" | translate}}</mat-label>
                <input matInput maxlength="32" required formControlName="key">
                <mat-error *ngIf="question.controls.key.hasError('required')">{{"labels.heading.key" | translate}} <strong>{{"labels.commons.is required" | translate}}</strong>
                </mat-error>
              </mat-form-field>

              <mat-form-field fxFlex="48">
                <mat-label>{{"labels.text" | translate}}</mat-label>
                <input matInput maxlength="255" required formControlName="text">
                <mat-error *ngIf="question.controls.text.hasError('required')">{{"labels.text" | translate}} <strong>{{"labels.commons.is required" | translate}}</strong>
                </mat-error>
              </mat-form-field>

              <mat-form-field fxFlex="98%">
                <mat-label>{{"labels.inputs.description" | translate }}</mat-label>
                <textarea matInput formControlName="description"></textarea>
              </mat-form-field>

              <mat-divider [inset]="true"></mat-divider>

              <div fxFlex="98%" fxLayout="row" fxLayoutAlign="space-between center">
                <h4 class="mat-h4">{{"labels.inputs.options" | translate}}</h4>
                <button type="button" mat-raised-button color="primary" (click)="addResponse(questionIndex)">
                  <fa-icon icon="plus"></fa-icon>&nbsp;&nbsp;{{"labels.buttons.addoption" | translate}}</button>
              </div>

              <div cdkDropList (cdkDropListDropped)="dropResponse($event, questionIndex)" fxFlexFill fxLayout="row wrap" fxLayoutGap="2%">

                <div cdkDrag cdkDragLockAxis="y" fxFlexFill fxLayout="row wrap" fxLayoutGap="2%" formArrayName="responseDatas" *ngFor="let response of getResponseDatas(questionIndex).controls; let responseIndex = index;">

                  <div fxFlexFill fxLayout="row wrap" fxLayoutGap="2%" [formGroupName]="responseIndex">

                    <mat-form-field fxFlex="43%">
                      <mat-label>{{"labels.text" | translate}}</mat-label>
                      <input matInput required formControlName="text">
                      <mat-error *ngIf="response.controls.text.hasError('required')">{{"labels.text" | translate}} <strong>{{"labels.commons.is required" | translate}}</strong>
                      </mat-error>
                    </mat-form-field>

                    <mat-form-field fxFlex="43%">
                      <mat-label>{{"labels.inputs.value" | translate}}</mat-label>
                      <input matInput required formControlName="value">
                      <mat-error *ngIf="response.controls.value.hasError('required')">{{"labels.inputs.value" | translate}} <strong>{{"labels.commons.is required" | translate}}</strong>
                      </mat-error>
                      <mat-error *ngIf="response.controls.value.hasError('pattern')">{{"labels.inputs.value" | translate}}<strong>{{"labels.mustBeAnIntegerBetween" | translate}} -9999 {{"labels.and" | translate}} 9999</strong>
                      </mat-error>
                    </mat-form-field>

                    <div fxFlex="8%">
                      <div class="delete-wrapper">
                        <button type="button" color="warn" mat-icon-button [matTooltip]="'labels.anchors.delete'| translate" matTooltipPosition="above" (click)="removeResponse(getResponseDatas(questionIndex), responseIndex)" [disabled]="getResponseDatas(questionIndex).controls.length === 1">
                          <fa-icon icon="trash" size="lg"></fa-icon>
                        </button>
                      </div>
                    </div>

                  </div>

                </div>

              </div>

            </div>

          </mat-card-content>

          <div>

            <mat-card-actions *ngIf="isLast" fxLayout="row" fxLayout.xs="column" fxLayoutAlign="center" fxLayoutGap="5px">
              <button type="button" mat-raised-button (click)="cancelSurvey()">{{"labels.buttons.cancel" | translate}}</button>
              <button type="button" mat-raised-button color="primary"(click)="addQuestion()">
                <fa-icon icon="plus"></fa-icon>&nbsp;&nbsp;
                {{"labels.buttons.addquestion" | translate}}
              </button>
              <button mat-raised-button color="primary" [disabled]="!surveyForm.valid">{{"labels.buttons.submit" | translate}}</button>
            </mat-card-actions>

          </div>

        </mat-card>

      </div>

    </div>

  </form>

</div>
