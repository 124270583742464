<div class="container space">

  <mat-card>

    <form [formGroup]="configurationForm" (ngSubmit)="submit()">

      <mat-card-content>

        <div fxLayout="column">

          <mat-form-field>
            <mat-label>{{"labels.configurationName" | translate}}</mat-label>
            <input matInput required formControlName="name">
          </mat-form-field>

          <mat-form-field>
            <mat-label>{{"labels.configurationValue" | translate}}</mat-label>
            <input matInput required type="number" formControlName="value">
            <mat-error *ngIf="configurationForm.controls.value.hasError('required')">
              {{"labels.configurationValue" | translate}} <strong>{{"labels.commons.is required" | translate}}</strong>
            </mat-error>
          </mat-form-field>

        </div>

      </mat-card-content>

      <mat-card-actions fxLayout="row" fxLayout.xs="column" fxLayoutAlign="center" fxLayoutGap="5px">
        <button type="button" mat-raised-button [routerLink]="['../..']">{{"labels.buttons.cancel" | translate }}</button>
        <button mat-raised-button color="primary" [disabled]="configurationForm.pristine || !configurationForm.valid">{{"labels.buttons.submit" | translate }}</button>
      </mat-card-actions>

    </form>

  </mat-card>

</div>
