import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { SettingsService } from 'app/settings/settings.service';
import { SystemService } from 'app/system/system.service';

@Component({
  selector: 'mifosx-map-credit-bureau-to-loan-product',
  templateUrl: './map-credit-bureau-to-loan-product.component.html',
  styleUrls: ['./map-credit-bureau-to-loan-product.component.scss']
})
export class MapCreditBureauToLoanProductComponent implements OnInit {

  creditBureauForm: FormGroup;
  loanProducts: any = [];
  creditBureauSummary: any = [];


  /**
   * @param {ActivatedRoute} route ActivatedRoute.
   * @param {Router} router Router.
   * @param {ConfigurationWizardService} configurationWizardService ConfigurationWizard Service.
   * @param {PopoverService} popoverService PopoverService.
   */
  constructor(private route: ActivatedRoute,
    private router: Router,
    private systemService: SystemService,
    private formBuilder: FormBuilder,
    private settingsService: SettingsService) {
    this.route.data.subscribe((data: { loanProducts: any, creditBureauSummary: any }) => {
      this.loanProducts = data.loanProducts;
      this.creditBureauSummary = data.creditBureauSummary;
    });
    const local = this.settingsService.language.code;
    this.creditBureauForm = this.formBuilder.group({
      'stalePeriod': ['', Validators.required],
      'skipCreditcheckInFailure': [undefined, Validators.required],
      'isCreditcheckMandatory': [undefined, Validators.required],
      'organisationCreditBureauId': [undefined, Validators.required],
      'loanProductId': [undefined, Validators.required],
      'locale': [local, Validators.required],
    });
  }

  ngOnInit(): void {
  }

  submit() {
    const { organisationCreditBureauId, ...formData } = this.creditBureauForm.value;
    this.systemService.addCreditBureauLoanProductMapping(formData, organisationCreditBureauId).subscribe((data: any) => {
      this.router.navigate(['../'], { relativeTo: this.route });
    })
  }

}
