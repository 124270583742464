<div class="tab-container mat-typography">

  <h3>{{'labels.heading.address' | translate}}</h3>

  <div fxLayout="row" fxLayoutAlign="flex-end">
    <button mat-raised-button color="primary" (click)="addAddress()">
      <fa-icon icon="plus"></fa-icon>&nbsp;&nbsp;{{'labels.buttons.add' | translate}}
    </button>
  </div>

  <mat-accordion>

    <mat-expansion-panel *ngFor="let address of clientAddressData;index as i" class="address">

      <mat-expansion-panel-header>
        <mat-panel-title>
          {{address.addressType}}
        </mat-panel-title>
        <mat-panel-description>
          {{address.relationship}}
        </mat-panel-description>
      </mat-expansion-panel-header>

      <mat-divider [inset]="true"></mat-divider>

      <div class="address-actions" fxLayout="row" fxLayoutAlign="flex-end center">
        <button mat-button color="primary" (click)="editAddress(address,i)">
          <fa-icon icon="edit"></fa-icon>
        </button>
        <mat-slide-toggle [checked]="address.isActive" (change)="toggleAddress(address)"></mat-slide-toggle>
      </div>

      <p>
        <span *ngIf="isFieldEnabled('street')">{{'labels.inputs.street' | translate}} : {{address.street}}<br /></span>
        <span *ngIf="isFieldEnabled('addressLine1')">{{'labels.inputs.addressline1' | translate}} : {{address.addressLine1}}<br /></span>
        <span *ngIf="isFieldEnabled('addressLine2')">{{'labels.inputs.addressline2' | translate}} : {{address.addressLine2}}<br /></span>
        <span *ngIf="isFieldEnabled('addressLine3')">{{'labels.inputs.addressline3' | translate}} : {{address.addressLine3}}<br /></span>
        <span *ngIf="isFieldEnabled('townVillage')">{{'labels.inputs.town_village' | translate}} : {{address.townVillage}}<br /></span>
        <span *ngIf="isFieldEnabled('city')">{{'labels.inputs.city' | translate}} : {{address.city}}<br /></span>
        <span *ngIf="isFieldEnabled('stateProvinceId')">{{'labels.inputs.state_province' | translate}} :
          {{getSelectedValue('stateProvinceIdOptions',address.stateProvinceId)?.name}}<br /></span>
        <span *ngIf="isFieldEnabled('countryId')">{{'labels.inputs.country' | translate}} :
          {{getSelectedValue('countryIdOptions',address.countryId)?.name}}<br /></span>
        <span *ngIf="isFieldEnabled('postalCode')">{{'labels.inputs.postal_code' | translate}} : {{address.postalCode}}<br /></span>
        <span *ngIf="isFieldEnabled('isActive')">{{'labels.inputs.active_status' | translate}} : {{address.isActive}}<br /></span>
      </p>

    </mat-expansion-panel>

  </mat-accordion>

</div>
