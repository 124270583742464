<div class="container">

  <mat-card>

    <form [formGroup]="officeForm" (ngSubmit)="submit()">

      <mat-card-content>

        <div #createOfficeFormRef fxLayout="column">

          <mat-form-field>
            <mat-label>{{ 'labels.inputs.office' | translate }}</mat-label>
            <input required matInput formControlName="name">
            <mat-error *ngIf="officeForm.controls.name.hasError('required')">
              {{ 'labels.inputs.office' | translate }} <strong>{{ 'labels.commons.is required' | translate }}</strong>
            </mat-error>
          </mat-form-field>

          <mat-form-field>
            <mat-label>{{ 'labels.inputs.parentoffice' | translate }}</mat-label>
            <mat-select required formControlName="parentId">
              <mat-option *ngFor="let office of officeData" [value]="office.id">
                {{ office.name }}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="officeForm.controls.parentId.hasError('required')">
              {{ 'labels.inputs.parentoffice' | translate }} <strong>{{ 'labels.commons.is required' | translate
                }}</strong>
            </mat-error>
          </mat-form-field>

          <mat-form-field (click)="openedOnDatePicker.open()">
            <mat-label>{{ 'labels.inputs.openedon' | translate }}</mat-label>
            <input matInput [min]="minDate" [max]="maxDate" formControlName="openingDate" required
              [matDatepicker]="openedOnDatePicker">
            <mat-datepicker-toggle matSuffix [for]="openedOnDatePicker"></mat-datepicker-toggle>
            <mat-datepicker #openedOnDatePicker></mat-datepicker>
            <mat-error *ngIf="officeForm.controls.openingDate.hasError('required')">
              {{ 'labels.inputs.openedon' | translate }} <strong>{{ 'labels.commons.is required' | translate }}</strong>
            </mat-error>
          </mat-form-field>

          <mat-form-field>
            <mat-label>{{ 'labels.inputs.externalid' | translate }}</mat-label>
            <input matInput formControlName="externalId">
          </mat-form-field>

        </div>

      </mat-card-content>

      <mat-card-actions fxLayout="row" fxLayout.xs="column" fxLayoutAlign="center" fxLayoutGap="5px">
        <button type="button" mat-raised-button [routerLink]="['../']">{{ 'labels.buttons.cancel' | translate
          }}</button>
        <button mat-raised-button color="primary" [disabled]="!officeForm.valid"
          *mifosxHasPermission="'CREATE_OFFICE'">{{ 'labels.buttons.submit' | translate }}</button>
      </mat-card-actions>

    </form>

  </mat-card>

</div>

<ng-template #templateCreateOfficeForm let-popover="popover">
  <h2>{{ 'labels.buttons.createoffice' | translate }}</h2>
  <p class="mw400">{{"labels.ClickStartFillingDetails" | translate}}. * {{"labels.markFieldsNeccessary" | translate}}. {{"labels.forMoreDetailsClick" | translate}}: <a href=""
      target="_blank">{{ 'labels.anchors.manageoffices' | translate }}</a></p>
  <div fxLayout="row" fxLayoutAlign="end" fxLayoutGap="2%" fxLayout.lt-md="column">
    <button mat-raised-button color="warn" (click)="popover.close();configurationWizardService.closeConfigWizard()">{{
      'labels.buttons.close' | translate }}</button>
    <button mat-raised-button color="primary" (click)="popover.close()">{{ 'labels.buttons.createoffice' | translate
      }}</button>
    <button mat-raised-button color="primary" (click)="popover.close();previousStep()">{{ 'labels.buttons.back' |
      translate }}</button>
    <button mat-raised-button color="primary" (click)="popover.close();nextStep()">{{ 'labels.buttons.next' | translate
      }}</button>
  </div>
</ng-template>
