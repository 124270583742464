<form [formGroup]="loanProductSettingsForm">

  <div fxLayout="row wrap" fxLayoutGap="2%" fxLayout.lt-md="column" fxLayoutAlign.gt-sm="start center">

    <mat-form-field fxFlex="31%">
      <mat-label>{{"labels.heading.amortization" | translate}}</mat-label>
      <mat-select formControlName="amortizationType" required>
        <mat-option *ngFor="let amortizationType of amortizationTypeData" [value]="amortizationType.id">
          {{ amortizationType.value }}
        </mat-option>
      </mat-select>
      <mat-error>
        {{"labels.heading.amortization" | translate}} <strong>{{"labels.commons.is required" | translate}}</strong>
      </mat-error>
    </mat-form-field>

    <mat-checkbox fxFlex="15%" labelPosition="before" formControlName="isEqualAmortization">
      {{"labels.inputs.isequalamortization" | translate}}?
    </mat-checkbox>

    <mat-form-field fxFlex="48%">
      <mat-label>{{"labels.heading.interestmethod" | translate}}</mat-label>
      <mat-select formControlName="interestType" required>
        <mat-option *ngFor="let interestType of interestTypeData" [value]="interestType.id">
          {{ interestType.value }}
        </mat-option>
      </mat-select>
      <mat-error>
        {{"labels.heading.interestmethod" | translate}} <strong>{{"labels.commons.is required" | translate}}</strong>
      </mat-error>
    </mat-form-field>

    <mat-form-field fxFlex="48%">
      <mat-label>{{"labels.heading.interestcalculationperiod" | translate}}</mat-label>
      <mat-select formControlName="interestCalculationPeriodType" required>
        <mat-option *ngFor="let interestCalculationPeriodType of interestCalculationPeriodTypeData" [value]="interestCalculationPeriodType.id">
          {{ interestCalculationPeriodType.value }}
        </mat-option>
      </mat-select>
      <mat-error>
        {{"labels.heading.interestcalculationperiod" | translate}} <strong>{{"labels.commons.is required" | translate}}</strong>
      </mat-error>
    </mat-form-field>

    <mat-checkbox *ngIf="loanProductSettingsForm.value.interestCalculationPeriodType === 1" fxFlex="48%" labelPosition="before" formControlName="allowPartialPeriodInterestCalcualtion">
      {{"labels.inputs.allowpartialperiodinterestcalcualtion" | translate}}
    </mat-checkbox>

    <mat-form-field fxFlex="48%">
      <mat-label>{{"labels.inputs.repaymentstrategy" | translate}}</mat-label>
      <mat-select formControlName="transactionProcessingStrategyId" required>
        <mat-option *ngFor="let transactionProcessingStrategy of transactionProcessingStrategyData" [value]="transactionProcessingStrategy.id">
          {{ transactionProcessingStrategy.name }}
        </mat-option>
      </mat-select>
      <mat-error>
        {{"labels.inputs.repaymentstrategy" | translate}} <strong>{{"labels.commons.is required" | translate}}</strong>
      </mat-error>
    </mat-form-field>

    <span fxFlex="48%" fxHide.lt-md></span>

    <h4 fxFlex="98%" class="mat-h4">{{"labels.inputs.grace" | translate}}</h4>

    <mat-form-field fxFlex="48%">
      <mat-label>{{"labels.gracePrincipalPayment" | translate}}</mat-label>
      <input type="number" matInput formControlName="graceOnPrincipalPayment">
    </mat-form-field>

    <mat-form-field fxFlex="48%">
      <mat-label>{{"labels.graceInterestPayment" | translate}}</mat-label>
      <input type="number" matInput formControlName="graceOnInterestPayment">
    </mat-form-field>

    <!-- <mat-form-field fxFlex="48%">
      <mat-label>{{"labels.anchors.delinquencyBuckets" | translate}}</mat-label>
      <mat-select formControlName="delinquencyBucketId">
        <mat-option *ngFor="let delinquencyBucket of delinquencyBucketData" [value]="delinquencyBucket.id">
          {{ delinquencyBucket.name }}
        </mat-option>
      </mat-select>
    </mat-form-field> -->

    <mat-divider fxFlex="98%"></mat-divider>

    <mat-form-field fxFlex="48%">
      <mat-label>{{"labels.inputs.interestfreeperiod" | translate}}</mat-label>
      <input type="number" matInput formControlName="graceOnInterestCharged">
    </mat-form-field>

    <mat-form-field fxFlex="48%">
      <mat-label>{{"labels.heading.arearstolerance" | translate}}</mat-label>
      <input type="number" matInput formControlName="inArrearsTolerance">
    </mat-form-field>

    <mat-form-field fxFlex="48%">
      <mat-label>{{"labels.heading.daysinyears" | translate}} </mat-label>
      <mat-select formControlName="daysInYearType" required>
        <mat-option *ngFor="let daysInYearType of daysInYearTypeData" [value]="daysInYearType.id">
          {{ daysInYearType.value }}
        </mat-option>
      </mat-select>
      <mat-error>
        {{"labels.heading.daysinyears" | translate}} <strong>{{"labels.commons.is required" | translate}}</strong>
      </mat-error>
    </mat-form-field>

    <mat-form-field fxFlex="48%">
      <mat-label>{{"labels.inputs.daysinmonth" | translate}}</mat-label>
      <mat-select formControlName="daysInMonthType" required>
        <mat-option *ngFor="let daysInMonthType of daysInMonthTypeData" [value]="daysInMonthType.id">
          {{ daysInMonthType.value }}
        </mat-option>
      </mat-select>
      <mat-error>
        {{"labels.inputs.daysinmonth" | translate}} <strong>{{"labels.commons.is required" | translate}}</strong>
      </mat-error>
    </mat-form-field>

    <mat-checkbox fxFlex="98%" labelPosition="before" formControlName="canDefineInstallmentAmount" class="margin-v">
      {{"labels.inputs.candefineinstallmentamount" | translate}}
    </mat-checkbox>

    <h4 fxFlex="48%" class="mat-h4">{{"labels.inputs.no.of.overdue.days.to.move.loan.into.arrears" | translate}}</h4>

    <mat-form-field fxFlex="48%">
      <mat-label>{{"labels.onArrearsAgeing" | translate}}</mat-label>
      <input type="number" matInput formControlName="graceOnArrearsAgeing">
    </mat-form-field>

    <h4 fxFlex="48%" class="mat-h4">{{"labels.maximumNumberDaysLoanOverdueBefore" | translate}}</h4>

    <mat-form-field fxFlex="48%">
      <mat-label>{{"labels.inputs.overduedaysfornpa" | translate}}</mat-label>
      <input type="number" matInput formControlName="overdueDaysForNPA">
    </mat-form-field>

    <mat-checkbox fxFlex="48%" labelPosition="before" formControlName="accountMovesOutOfNPAOnlyOnArrearsCompletion" class="margin-v">
      {{"labels.inputs.loan.account.moves.out.of.npa.only.on.arrears.completion" | translate}}
    </mat-checkbox>

    <mat-form-field fxFlex="48%">
      <mat-label>{{"labels.inputs.thresholdforlastinstallment" | translate}}</mat-label>
      <input type="number" matInput formControlName="principalThresholdForLastInstallment">
    </mat-form-field>

    <mat-checkbox fxFlex="48%" labelPosition="before" formControlName="allowVariableInstallments" class="margin-v">
      {{"labels.AreVariableInstallmentsAllowed" | translate}}
    </mat-checkbox>

    <div *ngIf="loanProductSettingsForm.value.allowVariableInstallments" fxFlexFill fxLayout="row wrap" fxLayoutGap="2%" fxLayout.lt-md="column">

      <h4 fxFlex="98%" class="mat-h4">{{"labels.inputs.variableinstallments" | translate}}</h4>

      <mat-form-field fxFlex="48%">
        <mat-label>{{"labels.inputs.minimumgap" | translate}}</mat-label>
        <input type="number" matInput formControlName="minimumGap" required>
        <mat-error>
          {{"labels.inputs.minimumgap" | translate}} <strong>{{"labels.commons.is required" | translate}}</strong>
        </mat-error>
      </mat-form-field>

      <mat-form-field fxFlex="48%">
        <mat-label>{{"labels.inputs.maximumgap" | translate}}</mat-label>
        <input type="number" matInput formControlName="maximumGap">
      </mat-form-field>

    </div>

    <mat-checkbox fxFlex="48%" labelPosition="before" formControlName="canUseForTopup" class="margin-v">
      {{"labels.allowedCanUseForTopup" | translate}}
    </mat-checkbox>

    <mat-divider fxFlex="98%"></mat-divider>

    <h3 fxFlex="23%" class="mat-h3">{{"labels.heading.interestRecalculation" | translate }}:</h3>

    <mat-checkbox fxFlex="73%" labelPosition="before" formControlName="isInterestRecalculationEnabled" class="margin-b">
      {{"labels.inputs.recalculateinterest" | translate}}
    </mat-checkbox>

    <div *ngIf="loanProductSettingsForm.value.isInterestRecalculationEnabled" fxFlexFill fxLayout="row wrap" fxLayoutGap="2%" fxLayout.lt-md="column">

      <mat-form-field fxFlex="48%">
        <mat-label>{{"labels.inputs.preclose.interest.calculation.strategy" | translate}}</mat-label>
        <mat-select formControlName="preClosureInterestCalculationStrategy" required>
          <mat-option *ngFor="let preClosureInterestCalculationStrategy of preClosureInterestCalculationStrategyData" [value]="preClosureInterestCalculationStrategy.id">
            {{ preClosureInterestCalculationStrategy.value }}
          </mat-option>
        </mat-select>
        <mat-error>
          {{"labels.inputs.preclose.interest.calculation.strategy" | translate}} <strong>{{"labels.commons.is required" | translate}}</strong>
        </mat-error>
      </mat-form-field>

      <mat-form-field fxFlex="48%">
        <mat-label>{{"labels.inputs.interest.recalculation.reschdule.strategy" | translate}}</mat-label>
        <mat-select formControlName="rescheduleStrategyMethod" required>
          <mat-option *ngFor="let rescheduleStrategyType of rescheduleStrategyTypeData" [value]="rescheduleStrategyType.id">
            {{ rescheduleStrategyType.value }}
          </mat-option>
        </mat-select>
        <mat-error>
          {{"labels.inputs.interest.recalculation.reschdule.strategy" | translate}} <strong>{{"labels.commons.is required" | translate}}</strong>
        </mat-error>
      </mat-form-field>

      <mat-form-field fxFlex="48%">
        <mat-label>{{"labels.inputs.interest.recalculation.compounding.method" | translate}}</mat-label>
        <mat-select formControlName="interestRecalculationCompoundingMethod" required>
          <mat-option *ngFor="let interestRecalculationCompoundingType of interestRecalculationCompoundingTypeData" [value]="interestRecalculationCompoundingType.id">
            {{ interestRecalculationCompoundingType.value }}
          </mat-option>
        </mat-select>
        <mat-error>
          {{"labels.inputs.interest.recalculation.compounding.method" | translate}} <strong>{{"labels.commons.is required" | translate}}</strong>
        </mat-error>
      </mat-form-field>

      <span fxFlex="48%" fxHide.lt-md></span>

      <div fxFlexFill fxLayout="row wrap" fxLayoutGap="2%" fxLayout.lt-md="column">

        <mat-form-field fxFlex="48%" *ngIf="loanProductSettingsForm.value.interestRecalculationCompoundingMethod !== 0 && loanProductSettingsForm.value.recalculationCompoundingFrequencyType !== 1">
          <mat-label>{{"labels.inputs.frequenc.interval.for.compounding" | translate}}</mat-label>
          <input type="number" matInput formControlName="recalculationCompoundingFrequencyInterval" required>
          <mat-error>
            {{"labels.inputs.frequenc.interval.for.compounding" | translate}} <strong>{{"labels.commons.is required" | translate}}</strong>
          </mat-error>
        </mat-form-field>

        <mat-form-field fxFlex="48%" *ngIf="loanProductSettingsForm.value.recalculationRestFrequencyType !== 1">
          <mat-label>{{"labels.inputs.frequenc.interval.for.recalculte.outstanding.principal" | translate}}</mat-label>
          <input type="number" matInput formControlName="recalculationRestFrequencyInterval" required>
          <mat-error>
           {{"labels.inputs.frequenc.interval.for.recalculte.outstanding.principal" | translate}} <strong>{{"labels.commons.is required" | translate}}</strong>
          </mat-error>
        </mat-form-field>

      </div>

      <div *ngIf="loanProductSettingsForm.value.interestRecalculationCompoundingMethod !== 0" fxFlexFill fxLayout="row wrap" fxLayoutGap="2%" fxLayout.lt-md="column">

        <mat-form-field fxFlex="31%">
          <mat-label>{{"labels.inputs.frequency.for.compounding" | translate}}</mat-label>
          <mat-select formControlName="recalculationCompoundingFrequencyType" required>
            <mat-option *ngFor="let interestRecalculationFrequencyType of interestRecalculationFrequencyTypeData" [value]="interestRecalculationFrequencyType.id">
              {{ interestRecalculationFrequencyType.value }}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <div *ngIf="loanProductSettingsForm.value.recalculationCompoundingFrequencyType === 4" fxFlex="31%" fxLayout="row" fxLayout.lt-md="column">

          <mat-form-field fxFlexFill>
            <mat-select formControlName="recalculationCompoundingFrequencyNthDayType">
              <mat-option *ngFor="let interestRecalculationNthDayType of interestRecalculationNthDayTypeData" [value]="interestRecalculationNthDayType.id">
                {{ interestRecalculationNthDayType.value }}
              </mat-option>
            </mat-select>
          </mat-form-field>

        </div>

        <div *ngIf="(loanProductSettingsForm.value.recalculationCompoundingFrequencyType === 4 && loanProductSettingsForm.value.recalculationCompoundingFrequencyNthDayType !== -2) || loanProductSettingsForm.value.recalculationCompoundingFrequencyType === 3" fxFlex="31%" fxLayout="row" fxLayout.lt-md="column">

          <mat-form-field fxFlexFill>
              <mat-select formControlName="recalculationCompoundingFrequencyDayOfWeekType">
                <mat-option *ngFor="let interestRecalculationDayOfWeekType of interestRecalculationDayOfWeekTypeData" [value]="interestRecalculationDayOfWeekType.id">
                  {{ interestRecalculationDayOfWeekType.value }}
                </mat-option>
              </mat-select>
            </mat-form-field>

        </div>

        <div *ngIf="loanProductSettingsForm.value.recalculationCompoundingFrequencyType === 4 && loanProductSettingsForm.value.recalculationCompoundingFrequencyNthDayType === -2" fxFlex="31%" fxLayout="row" fxLayout.lt-md="column">

          <mat-form-field fxFlexFill>
            <mat-select formControlName="recalculationCompoundingFrequencyOnDayType">
              <mat-option *ngFor="let interestRecalculationOnDayType of interestRecalculationOnDayTypeData" [value]="interestRecalculationOnDayType">
                {{ interestRecalculationOnDayType }}
              </mat-option>
            </mat-select>
          </mat-form-field>

        </div>

      </div>

      <mat-form-field fxFlex="31%">
        <mat-label>{{"labels.inputs.frequency.for.recalculte.outstanding.principal" | translate}}</mat-label>
        <mat-select formControlName="recalculationRestFrequencyType" required>
          <mat-option *ngFor="let interestRecalculationFrequencyType of interestRecalculationFrequencyTypeData" [value]="interestRecalculationFrequencyType.id">
            {{ interestRecalculationFrequencyType.value }}
          </mat-option>
        </mat-select>
        <mat-error>
          {{"labels.inputs.frequency.for.recalculte.outstanding.principal" | translate}} <strong>{{"labels.commons.is required" | translate}}</strong>
        </mat-error>
      </mat-form-field>

      <div *ngIf="loanProductSettingsForm.value.recalculationRestFrequencyType === 4" fxFlex="31%" fxLayout="row" fxLayout.lt-md="column">

        <mat-form-field fxFlexFill>
          <mat-select formControlName="recalculationRestFrequencyNthDayType">
            <mat-option *ngFor="let interestRecalculationNthDayType of interestRecalculationNthDayTypeData" [value]="interestRecalculationNthDayType.id">
              {{ interestRecalculationNthDayType.value }}
            </mat-option>
          </mat-select>
        </mat-form-field>

      </div>

      <div *ngIf="(loanProductSettingsForm.value.recalculationRestFrequencyType === 4 && loanProductSettingsForm.value.recalculationRestFrequencyNthDayType !== -2) || loanProductSettingsForm.value.recalculationRestFrequencyType === 3" fxFlex="31%" fxLayout="row" fxLayout.lt-md="column">

        <mat-form-field fxFlexFill>
          <mat-select formControlName="recalculationRestFrequencyDayOfWeekType">
            <mat-option *ngFor="let interestRecalculationDayOfWeekType of interestRecalculationDayOfWeekTypeData" [value]="interestRecalculationDayOfWeekType.id">
              {{ interestRecalculationDayOfWeekType.value }}
            </mat-option>
          </mat-select>
        </mat-form-field>

      </div>

      <div *ngIf="loanProductSettingsForm.value.recalculationRestFrequencyType === 4 && loanProductSettingsForm.value.recalculationRestFrequencyNthDayType === -2" fxFlex="31%" fxLayout="row" fxLayout.lt-md="column">

        <mat-form-field fxFlexFill>
          <mat-select formControlName="recalculationRestFrequencyOnDayType">
            <mat-option *ngFor="let interestRecalculationOnDayType of interestRecalculationOnDayTypeData" [value]="interestRecalculationOnDayType">
              {{ interestRecalculationOnDayType }}
            </mat-option>
          </mat-select>
        </mat-form-field>

      </div>

      <mat-checkbox fxFlex="98%" labelPosition="before" formControlName="isArrearsBasedOnOriginalSchedule" class="margin-v">
        {{"labels.inputs.arrearsbasedonoriginalschedule" | translate}}?
      </mat-checkbox>

    </div>

    <mat-divider fxFlex="98%"></mat-divider>

    <h3 fxFlex="23%" class="mat-h3">{{"labels.heading.gauranteeRequirements" | translate }}:</h3>

    <mat-checkbox fxFlex="73%" labelPosition="before" formControlName="holdGuaranteeFunds" class="margin-b">
      {{"labels.inputs.holdguaranteefunds" | translate}}
    </mat-checkbox>

    <div *ngIf="loanProductSettingsForm.value.holdGuaranteeFunds" fxFlexFill fxLayout="row wrap" fxLayoutGap="2%" fxLayout.lt-md="column">

      <mat-form-field fxFlex="31%">
        <mat-label>{{"labels.inputs.mandatory.guarantee" | translate}}</mat-label>
        <input type="number" matInput formControlName="mandatoryGuarantee" required>
        <mat-error>
          {{"labels.inputs.mandatory.guarantee" | translate}} <strong>{{"labels.commons.is required" | translate}}</strong>
        </mat-error>
      </mat-form-field>

      <mat-form-field fxFlex="31%">
        <mat-label>{{"labels.inputs.minimum.guarantee.from.own.funds" | translate}}</mat-label>
        <input type="number" matInput formControlName="minimumGuaranteeFromOwnFunds">
      </mat-form-field>

      <mat-form-field fxFlex="31%">
        <mat-label>{{"labels.inputs.minimum.guarantee.from.guarantor" | translate}}</mat-label>
        <input type="number" matInput formControlName="minimumGuaranteeFromGuarantor">
      </mat-form-field>

    </div>

    <mat-divider fxFlex="98%"></mat-divider>

    <h3 fxFlex="23%" class="mat-h3">{{"labels.loanTrancheDetails" | translate }}</h3>

    <mat-checkbox fxFlex="73%" labelPosition="before" formControlName="multiDisburseLoan" class="margin-b">
      {{"labels.inputs.multidisburseloan" | translate}}
    </mat-checkbox>

    <div *ngIf="loanProductSettingsForm.value.multiDisburseLoan" fxFlexFill fxLayout="row wrap" fxLayoutGap="2%" fxLayout.lt-md="column">
      <mat-form-field fxFlex="48%">
        <mat-label>{{"labels.inputs.maxtranchecount" | translate}}</mat-label>
        <input type="number" matInput formControlName="maxTrancheCount" required>
        <mat-error>
          {{"labels.inputs.maxtranchecount" | translate}} <strong>{{"labels.commons.is required" | translate}}</strong>
        </mat-error>
      </mat-form-field>

      <mat-form-field fxFlex="48%">
        <mat-label>{{"labels.inputs.outstandingloanbalance" | translate}}</mat-label>
        <input type="number" matInput formControlName="outstandingLoanBalance">
      </mat-form-field>

      <mat-checkbox fxFlex="48%" labelPosition="before" formControlName="disallowExpectedDisbursements" class="margin-v">
        {{"labels.disallowExpectedDisbursements" | translate}}
      </mat-checkbox>
    </div>

    <mat-divider fxFlex="98%"></mat-divider>

    <h3 fxFlex="23%" class="mat-h3">{{"labels.heading.configurableAttributes" | translate }}:</h3>

    <mat-checkbox fxFlex="73%" labelPosition="before" formControlName="allowAttributeConfiguration" class="margin-b">
      {{"labels.inputs.isConfigLoanAttributes" | translate}}
    </mat-checkbox>

    <div *ngIf="loanProductSettingsForm.value.allowAttributeConfiguration" formGroupName="allowAttributeOverrides" fxFlexFill fxLayout="row wrap" fxLayoutGap="2%" fxLayout.lt-md="column">

      <mat-checkbox fxFlex="48%" labelPosition="before" formControlName="amortizationType" class="margin-v">
        {{"labels.inputs.amortization" | translate}}
      </mat-checkbox>

      <mat-checkbox fxFlex="48%" labelPosition="before" formControlName="interestType" class="margin-v">
        {{"labels.inputs.interestmethod" | translate}}
      </mat-checkbox>

      <mat-checkbox fxFlex="48%" labelPosition="before" formControlName="transactionProcessingStrategyId" class="margin-v">
        {{"labels.inputs.repaymentstrategy" | translate}}
      </mat-checkbox>

      <mat-checkbox fxFlex="48%" labelPosition="before" formControlName="interestCalculationPeriodType" class="margin-v">
        {{"labels.inputs.interestcalculationperiod" | translate}}
      </mat-checkbox>

      <mat-checkbox fxFlex="48%" labelPosition="before" formControlName="inArrearsTolerance" class="margin-v">
        {{"labels.inputs.arearstolerance" | translate}}
      </mat-checkbox>

      <mat-checkbox fxFlex="48%" labelPosition="before" formControlName="repaymentEvery" class="margin-v">
        {{"labels.inputs.repaidevery" | translate}}
      </mat-checkbox>

      <mat-checkbox fxFlex="48%" labelPosition="before" formControlName="graceOnPrincipalAndInterestPayment" class="margin-v">
        {{"labels.inputs.grace" | translate}}
      </mat-checkbox>

      <mat-checkbox fxFlex="48%" labelPosition="before" formControlName="graceOnArrearsAgeing" class="margin-v">
        {{"labels.inputs.no.of.overdue.days.to.move.loan.into.arrears" | translate}}
      </mat-checkbox>

    </div>

  </div>

  <div fxLayout="row" class="margin-t" fxLayout.xs="column" fxLayoutAlign="center" fxLayoutGap="2%">
    <button mat-raised-button matStepperPrevious>
      <fa-icon icon="arrow-left"></fa-icon>&nbsp;&nbsp;
      {{"labels.buttons.previous" | translate}}
    </button>
    <button mat-raised-button matStepperNext>
      {{"labels.buttons.next" | translate}}&nbsp;&nbsp;
      <fa-icon icon="arrow-right"></fa-icon>
    </button>
  </div>

</form>
