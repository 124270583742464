import { ActivatedRoute } from '@angular/router';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'mifosx-web-view-history',
  templateUrl: './web-view-history.component.html',
  styleUrls: ['./web-view-history.component.scss']
})
export class WebViewHistoryComponent implements OnInit {
  openLoansColumns: string[] = ['Account No', 'Original Loan', 'Amount Paid'];
  loans:any;
  constructor(private route: ActivatedRoute) {
    this.route.data.subscribe((data: { accounts: any }) => {
      this.loans = data?.accounts?.loanAccounts?.filter((loan:any)=>loan.status.id===600);

    });
  }

  ngOnInit(): void {
  }

}
