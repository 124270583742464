<div class="container">

  <mat-card>

    <form [formGroup]="frequentPostingsForm" (ngSubmit)="submit()">

      <mat-card-content>

        <div fxLayout="row wrap" fxLayoutGap="2%" fxLayout.lt-md="column">

          <mat-form-field fxFlex="48%">
            <mat-label>{{ 'labels.inputs.office' | translate }}</mat-label>
            <mat-select required formControlName="officeId">
              <mat-option *ngFor="let office of officeData" [value]="office.id">
                {{ office.name }}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="frequentPostingsForm.controls.officeId.hasError('required')">
              {{ 'labels.inputs.office' | translate }} <strong>{{'labels.commons.is required' |
                translate}}</strong>
            </mat-error>
          </mat-form-field>

          <mat-form-field fxFlex="48%">
            <mat-label>{{ 'labels.inputs.accountingrules' | translate }}</mat-label>
            <mat-select required formControlName="accountingRule">
              <mat-option *ngFor="let accountingRule of accountingRuleData" [value]="accountingRule">
                {{ accountingRule.name }}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="frequentPostingsForm.controls.accountingRule.hasError('required')">
              {{ 'labels.inputs.accountingrules' | translate }} <strong>{{'labels.commons.is required' |
                translate}}</strong>
            </mat-error>
          </mat-form-field>

          <mat-form-field fxFlex="48%">
            <mat-label>{{ 'labels.inputs.currency' | translate }}</mat-label>
            <mat-select required formControlName="currencyCode">
              <mat-option *ngFor="let currency of currencyData" [value]="currency.code">
                {{ currency.displayLabel }}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="frequentPostingsForm.controls.currencyCode.hasError('required')">
              {{ 'labels.inputs.currency' | translate }} <strong>{{'labels.commons.is required' |
                translate}}</strong>
            </mat-error>
          </mat-form-field>

          <span fxFlex="48%" fxHide.lt-md></span>

          <div fxFlexFill fxLayout="row wrap" fxLayout.lt-md="column" formArrayName="debits"
            *ngFor="let debit of debits.controls; let i = index;">

            <div fxFlexFill fxLayout="row wrap" fxLayoutGap="2%" fxLayout.lt-md="column" [formGroupName]="i">

              <mat-form-field fxFlex="48%">
                <mat-label>{{ 'labels.inputs.affectedglentries' | translate }}</mat-label>
                <mat-select required formControlName="glAccountId">
                  <mat-option *ngFor="let affectedGLEntryDebit of debitAccountData" [value]="affectedGLEntryDebit.id">
                    {{ affectedGLEntryDebit.name }}
                  </mat-option>
                </mat-select>
                <mat-error *ngIf="debits.at(i).controls.glAccountId.hasError('required')">
                  {{ 'labels.inputs.affectedglentries' | translate }} <strong>{{'labels.commons.is required' |
                    translate}}</strong>
                </mat-error>
              </mat-form-field>

              <mat-form-field fxFlex="43%">
                <mat-label>{{ 'labels.inputs.debit' | translate }}</mat-label>
                <input matInput required formControlName="amount" decimalInput>
                <mat-error *ngIf="debits.at(i).controls.amount.hasError('required')">
                  {{ 'labels.inputs.debit' | translate }} <strong>{{'labels.commons.is required' |
                    translate}}</strong>
                </mat-error>
              </mat-form-field>

              <span fxFlex>
                <button *ngIf="i !== 0" type="button" mat-icon-button (click)="removeAffectedGLEntry(debits, i)">
                  <fa-icon icon="minus-circle" size="lg"></fa-icon>
                </button>
                <button *ngIf="i === 0 && allowMultipleDebitEntries" type="button" mat-icon-button color="primary"
                  (click)="addAffectedGLEntry(debits)">
                  <fa-icon icon="plus-circle" size="lg"></fa-icon>
                </button>
              </span>

            </div>

          </div>

          <div fxFlexFill fxLayout="row wrap" fxLayout.lt-md="column" formArrayName="credits"
            *ngFor="let credit of credits.controls; let i = index;">

            <div fxFlexFill fxLayout="row wrap" fxLayoutGap="2%" fxLayout.lt-md="column" [formGroupName]="i">

              <mat-form-field fxFlex="48%">
                <mat-label>{{"labels.affectedGLEntryCredit" | translate}}</mat-label>
                <mat-select required formControlName="glAccountId">
                  <mat-option *ngFor="let affectedGLEntryCredit of creditAccountData"
                    [value]="affectedGLEntryCredit.id">
                    {{ affectedGLEntryCredit.name }}
                  </mat-option>
                </mat-select>
                <mat-error *ngIf="credits.at(i).controls.glAccountId.hasError('required')">
                  {{"labels.affectedGLEntryCredit" | translate}} <strong>{{'labels.commons.is required' |
                    translate}}</strong>
                </mat-error>
              </mat-form-field>

              <mat-form-field fxFlex="43%">
                <mat-label>{{ 'labels.inputs.credit' | translate }}</mat-label>
                <input matInput required formControlName="amount" decimalInput>
                <mat-error *ngIf="credits.at(i).controls.amount.hasError('required')">
                  {{ 'labels.inputs.credit' | translate }} <strong>{{'labels.commons.is required' |
                    translate}}</strong>
                </mat-error>
              </mat-form-field>

              <span fxFlex>
                <button *ngIf="i !== 0" type="button" mat-icon-button (click)="removeAffectedGLEntry(credits, i)">
                  <fa-icon icon="minus-circle" size="lg"></fa-icon>
                </button>
                <button *ngIf="i === 0 && allowMultipleCreditEntries" type="button" mat-icon-button color="primary"
                  (click)="addAffectedGLEntry(credits)">
                  <fa-icon icon="plus-circle" size="lg"></fa-icon>
                </button>
              </span>

            </div>

          </div>

          <mat-form-field fxFlex="48%">
            <mat-label>{{ 'labels.inputs.referencenumber' | translate }}</mat-label>
            <input matInput formControlName="referenceNumber">
          </mat-form-field>

          <mat-form-field fxFlex="48%" (click)="transactionDatePicker.open()">
            <mat-label>{{ 'labels.inputs.transactiondate' | translate }}</mat-label>
            <input matInput [min]="minDate" [max]="maxDate" [matDatepicker]="transactionDatePicker" required
              formControlName="transactionDate">
            <mat-datepicker-toggle matSuffix [for]="transactionDatePicker"></mat-datepicker-toggle>
            <mat-datepicker #transactionDatePicker></mat-datepicker>
            <mat-error *ngIf="frequentPostingsForm.controls.transactionDate.hasError('required')">
              {{ 'labels.inputs.transactiondate' | translate }} <strong>required</strong>
            </mat-error>
          </mat-form-field>

          <mat-form-field fxFlex="48%">
            <mat-label>{{'labels.inputs.paymenttype' | translate}}</mat-label>
            <mat-select formControlName="paymentTypeId">
              <mat-option *ngFor="let paymentType of paymentTypeData" [value]="paymentType.id">
                {{ paymentType.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field fxFlex="48%">
            <mat-label>{{ 'labels.inputs.accnum' | translate}}</mat-label>
            <input matInput formControlName="accountNumber">
          </mat-form-field>

          <mat-form-field fxFlex="48%">
            <mat-label>{{ 'labels.inputs.checknumber' | translate}}</mat-label>
            <input matInput formControlName="checkNumber">
          </mat-form-field>

          <mat-form-field fxFlex="48%">
            <mat-label>{{ 'labels.inputs.routingcode' | translate}}</mat-label>
            <input matInput formControlName="routingCode">
          </mat-form-field>

          <mat-form-field fxFlex="48%">
            <mat-label>{{ 'labels.inputs.receiptnumber' | translate}}</mat-label>
            <input matInput formControlName="receiptNumber">
          </mat-form-field>

          <mat-form-field fxFlex="48%">
            <mat-label>{{ 'labels.inputs.banknumber' | translate}}</mat-label>
            <input matInput formControlName="bankNumber">
          </mat-form-field>

          <mat-form-field fxFlex="98%">
            <mat-label>{{ 'labels.inputs.comments' | translate }}</mat-label>
            <textarea matInput formControlName="comments"></textarea>
          </mat-form-field>

        </div>

      </mat-card-content>

      <mat-card-actions fxLayout="row" fxLayout.xs="column" fxLayoutAlign="center" fxLayoutGap="5px">
        <button type="button" mat-raised-button [routerLink]="['../']">{{ 'labels.buttons.cancel' | translate
          }}</button>
        <button mat-raised-button color="primary" [disabled]="!frequentPostingsForm.valid"
          *mifosxHasPermission="'CREATE_JOURNALENTRY'">{{ 'labels.buttons.submit' | translate }}</button>
      </mat-card-actions>

    </form>

  </mat-card>

</div>
