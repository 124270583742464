<div class="container">

  <mat-card>

    <form [formGroup]="clientCreditLineForm" (ngSubmit)="submit()">

      <mat-card-content>

        <div fxLayout="column">
          <mat-form-field>
            <mat-label>{{ 'labels.productLines' | translate }}</mat-label>
            <mat-select required formControlName="productLine">
              <mat-option *ngFor="let clientCreditLine of clientProductLinesOptions" [value]="clientCreditLine">
                {{ clientCreditLine.name }}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="clientCreditLineForm.controls.productLine.hasError('required')">
              {{ 'labels.productLines' | translate }} <strong>{{ 'labels.commons.is required' | translate }}</strong>
            </mat-error>
          </mat-form-field>

          <mat-form-field *ngIf="creditLineParents.length>0">
            <mat-label>{{ 'labels.creditLineParent' | translate }}</mat-label>
            <mat-select  formControlName="parentId">
              <mat-option></mat-option>
              <mat-option *ngFor="let parent of creditLineParents" [value]="parent.id">
                {{ parent.id}} - {{parent.productLine.name}} ({{parent.limitAmount}} {{parent.currency.code}})
              </mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field>
            <mat-label>{{ 'labels.limitAmount' | translate }}</mat-label>
            <input matInput required formControlName="limitAmount"  decimalInput [max]="creditLineDetails?.limitAmount"
              min="1">
              <mat-error *ngIf="clientCreditLineForm.controls.limitAmount.hasError('required')">
                {{"labels.limitAmount" | translate}} <strong>{{"labels.commons.is required" | translate}}</strong>
              </mat-error>

              <mat-error *ngIf="clientCreditLineForm.controls.limitAmount.hasError('max')">
                {{"labels.limitAmount" | translate}} <strong>{{"labels.mustBeLessThan" | translate}}</strong> {{creditLineDetails?.limitAmount}}
              </mat-error>

              <mat-error *ngIf="clientCreditLineForm.controls.limitAmount.hasError('min')">
                {{"labels.limitAmount" | translate}} <strong>{{"labels.mustBeGreaterThan" | translate}}</strong> 1
              </mat-error>
          </mat-form-field>

          <mat-form-field>
            <mat-label>{{"labels.appliedOnDate" | translate}}</mat-label>
            <input matInput [min]="minDate" [matDatepicker]="appliedOnDatePicker" required
              formControlName="appliedOnDate">
            <mat-datepicker-toggle matSuffix [for]="appliedOnDatePicker"></mat-datepicker-toggle>
            <mat-datepicker #appliedOnDatePicker></mat-datepicker>
            <mat-error *ngIf="clientCreditLineForm.controls.appliedOnDate.hasError('required')">
              {{"labels.appliedOnDate" | translate}} <strong>{{"labels.commons.is required" | translate}}</strong>
            </mat-error>
          </mat-form-field>


          <mat-form-field>
            <mat-label>{{"labels.processingEndDate" | translate}}</mat-label>
            <input matInput [min]="minDate" [matDatepicker]="processingEndDate" required
              formControlName="processingEndDate">
            <mat-datepicker-toggle matSuffix [for]="processingEndDate"></mat-datepicker-toggle>
            <mat-datepicker #processingEndDate></mat-datepicker>
            <mat-error *ngIf="clientCreditLineForm.controls.processingEndDate.hasError('required')">
              {{"labels.processingEndDate" | translate}} <strong>{{"labels.commons.is required" | translate}}</strong>
            </mat-error>
          </mat-form-field>

          <!-- <mat-form-field>
            <mat-label>{{"labels.validityEndDate" | translate}}</mat-label>
            <input matInput [min]="minDate" [matDatepicker]="validityEndDate" required
              formControlName="validityEndDate">
            <mat-datepicker-toggle matSuffix [for]="validityEndDate"></mat-datepicker-toggle>
            <mat-datepicker #validityEndDate></mat-datepicker>
            <mat-error *ngIf="clientCreditLineForm.controls.validityEndDate.hasError('required')">
              {{"labels.validityEndDate" | translate}} <strong>{{"labels.commons.is required" | translate}}</strong>
            </mat-error>
          </mat-form-field> -->

          <mat-form-field>
            <mat-label>{{"labels.motivation" | translate}}</mat-label>
            <textarea matInput required formControlName="motivation" matLine="2"></textarea>
            <mat-error *ngIf="clientCreditLineForm.controls.motivation.hasError('required')">
              {{"labels.motivation" | translate}} <strong>{{"labels.commons.is required" | translate}}</strong>
            </mat-error>
          </mat-form-field>

        </div>

      </mat-card-content>

      <mat-card-actions fxLayoutGap="5px" fxLayout="row" fsLayout.xs="column" fxLayoutAlign="center">
        <button type="button" mat-raised-button [routerLink]="['../../']">{{ 'labels.buttons.cancel' | translate
          }}</button>
        <button mat-raised-button color="primary" [disabled]="!clientCreditLineForm.valid">{{ 'labels.buttons.submit' |
          translate }}</button>
      </mat-card-actions>

    </form>

  </mat-card>

</div>
