<div class="container">

  <mat-card>

    <form [formGroup]="editGroupForm">

      <mat-card-content>

        <div fxLayout="column">

          <mat-form-field>
            <mat-label>{{ 'labels.inputs.name' | translate }}</mat-label>
            <input matInput required formControlName="name">
            <mat-error *ngIf="editGroupForm.controls.name.hasError('required')">
              {{ 'labels.inputs.groupName' | translate }} <strong>{{"labels.commons.is required" | translate}}</strong>
            </mat-error>
            <mat-error *ngIf="editGroupForm.controls.name.hasError('pattern')">
              {{ 'labels.inputs.groupName' | translate }} <strong>{{ 'labels.cannot' | translate }}</strong> {{ 'labels.anchors.cannotbeginwithspecialcharacter' | translate }}
            </mat-error>
          </mat-form-field>

          <mat-form-field>
            <mat-label>{{ 'labels.heading.staff' | translate }}</mat-label>
            <mat-select formControlName="staffId">
              <mat-option *ngFor="let staff of staffData" [value]="staff.id">
              {{ staff.displayName }}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field *ngIf="this.editGroupForm.contains('activationDate')" (click)="activationDatePicker.open()">
            <mat-label>{{ 'labels.heading.activationdate' | translate }}</mat-label>
            <input matInput [min]="minDate" [max]="maxDate" [matDatepicker]="activationDatePicker" required formControlName="activationDate">
            <mat-datepicker-toggle matSuffix [for]="activationDatePicker"></mat-datepicker-toggle>
            <mat-datepicker #activationDatePicker></mat-datepicker>
            <mat-error *ngIf="editGroupForm.controls.activationDate.hasError('required')">
              {{ 'labels.heading.activationdate' | translate }} <strong>{{"labels.commons.is required" | translate}}</strong>
            </mat-error>
          </mat-form-field>

          <mat-form-field>
            <mat-label>{{ 'labels.heading.externalid' | translate }}</mat-label>
            <input matInput formControlName="externalId">
          </mat-form-field>

          <mat-form-field (click)="submittedOnDatePicker.open()">
            <mat-label>{{ 'labels.inputs.submittedon' | translate }}</mat-label>
            <input matInput [min]="minDate" [max]="maxDate" [matDatepicker]="submittedOnDatePicker" required formControlName="submittedOnDate">
            <mat-datepicker-toggle matSuffix [for]="submittedOnDatePicker"></mat-datepicker-toggle>
            <mat-datepicker #submittedOnDatePicker></mat-datepicker>
            <mat-error *ngIf="editGroupForm.controls.submittedOnDate.hasError('required')">
              {{ 'labels.anchors.submissionDate' | translate }} <strong>{{"labels.commons.is required" | translate}}</strong>
            </mat-error>
          </mat-form-field>

        </div>

      </mat-card-content>

      <mat-card-actions fxLayout="row" fxLayout.xs="column" fxLayoutAlign="center" fxLayoutGap="5px">
        <button type="button" mat-raised-button [routerLink]="['../']">{{"labels.buttons.cancel" | translate}}</button>
        <button mat-raised-button color="primary" [disabled]="!editGroupForm.valid" (click)="submit()">{{"labels.buttons.submit" | translate}}</button>
      </mat-card-actions>

    </form>

  </mat-card>

</div>
