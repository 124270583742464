<div class="container">

  <mat-card>

    <div>

      <form [formGroup]="provisioningCriteriaForm">

        <div fxLayout="row" fxLayoutGap="2%">

          <mat-form-field fxFlex>
            <mat-label>{{"labels.anchors.provisioningcriteria" | translate}}</mat-label>
            <input required matInput formControlName="criteriaName">
            <mat-error *ngIf="provisioningCriteriaForm.controls.criteriaName.hasError('required')">
              {{"labels.anchors.provisioningcriteria" | translate}} <strong>{{"labels.commons.is required" | translate}}</strong>
            </mat-error>
          </mat-form-field>

          <mat-form-field fxFlex>
            <mat-label>{{"labels.inputs.selectedproducts" | translate}}</mat-label>
            <mat-select formControlName="loanProducts" [compareWith]="compareOptions" multiple>
              <mat-option *ngFor="let product of loanProducts" [value]="product">
                {{ product.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>

        </div>

      </form>

    </div>

    <div class="mat-elevation-z1">

      <mat-hint *ngIf="!provisioningCriteriaFormValid">{{"labels.pleasefillAllProvisioningCriteriaDefinitions" | translate}}.</mat-hint>

      <table mat-table class="m-t-10" [dataSource]="definitions">

        <ng-container matColumnDef="category">
          <th mat-header-cell *matHeaderCellDef>{{"labels.heading.category" | translate}}</th>
          <td mat-cell *matCellDef="let definition"> {{ definition.categoryName }} </td>
        </ng-container>

        <ng-container matColumnDef="minAge">
          <th mat-header-cell *matHeaderCellDef>{{"labels.heading.minimumage" | translate}}</th>
          <td mat-cell *matCellDef="let definition"> {{ definition.minAge || '0' }} </td>
        </ng-container>

        <ng-container matColumnDef="maxAge">
          <th mat-header-cell *matHeaderCellDef>{{"labels.heading.maximumage" | translate}}</th>
          <td mat-cell *matCellDef="let definition"> {{ definition.maxAge || '0' }} </td>
        </ng-container>

        <ng-container matColumnDef="percentage">
          <th mat-header-cell *matHeaderCellDef>{{"labels.heading.percentage" | translate}}  (%)</th>
          <td mat-cell *matCellDef="let definition"> {{ definition.provisioningPercentage || '0' }} </td>
        </ng-container>

        <ng-container matColumnDef="liabilityAccount">
          <th mat-header-cell *matHeaderCellDef>{{"labels.heading.provisioningliabilityaccount" | translate}} </th>
          <td mat-cell *matCellDef="let definition"> {{ (definition.liabilityAccount | find:loanProvisioningCriteriaAndTemplate.glAccounts:'id':'name') || '-' }} </td>
        </ng-container>

        <ng-container matColumnDef="expenseAccount">
          <th mat-header-cell *matHeaderCellDef>{{"labels.heading.provisioningexpenseaccount" | translate}} </th>
          <td mat-cell *matCellDef="let definition"> {{ (definition.expenseAccount | find:loanProvisioningCriteriaAndTemplate.glAccounts:'id':'name') || '-' }} </td>
        </ng-container>

        <ng-container matColumnDef="edit">
          <th mat-header-cell *matHeaderCellDef>{{"labels.inputs.actions" | translate}} </th>
          <td mat-cell *matCellDef="let definition">
            <button mat-button color="primary" (click)="editDefinition(definition)">
              <fa-icon icon="edit"></fa-icon>&nbsp;&nbsp;
              {{"labels.inputs.edit" | translate}}
            </button>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

      </table>

    </div>

    <div class="m-t-20" fxLayout="row" fxLayoutAlign="center" fxLayoutGap="20px">
      <button type="button" mat-raised-button [routerLink]="['../']">{{"labels.buttons.cancel" | translate}}</button>
      <button mat-raised-button color="primary" [disabled]="!provisioningCriteriaFormValid" (click)="submit()" *mifosxHasPermission="'UPDATE_CRITERIA'">{{"labels.buttons.submit" | translate}}</button>
    </div>

  </mat-card>

</div>
