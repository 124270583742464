<span *ngIf="item.permission">
  <a  (click)="onItemSelected(item)"
    [ngClass]="{'active': item.route ? router.isActive(item.route, true): false, 'expanded': expanded}"
    class="menu-list-item side-nav-item">
    <mat-icon matListIcon *ngIf="item.iconName">
      <fa-icon icon={{item.iconName}} size="sm"></fa-icon>
    </mat-icon>
    <div class="title" >{{item.displayName |
      translate}}
    </div>
    <span fxFlex *ngIf="item.children && item.children.length" style="align-self: flex-end;">
      <span fxFlex></span>
      <mat-icon [@indicatorRotate]="expanded ? 'expanded': 'collapsed'" style="margin-right: 23px;">
       <div class="cert"></div>
      </mat-icon>
    </span>
  </a>
  <div *ngIf="expanded">
    <app-menu-list-item *ngFor="let child of item.children" [item]="child" [depth]="depth+1" [child]="true">
    </app-menu-list-item>
  </div>

</span>
