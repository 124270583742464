<div class="container">

  <mat-card>

    <div #currencyFormRef>

      <form #formRef="ngForm" [formGroup]="currencyForm" fxLayout="row" fxLayoutAlign="start baseline"
        fxLayoutGap="20px" fxFlex="48%" (ngSubmit)="addCurrency()">

        <mat-form-field fxFlex="calc(80%-20px)">
          <mat-label>{{'labels.heading.currency' | translate}}</mat-label>
          <mat-select required formControlName="currency">
            <mat-option *ngFor="let currency of currencyData" [value]="currency">
              {{ currency.name }}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="currencyForm.controls.currency.hasError('required')">
            {{'labels.heading.currency' | translate}} <strong>{{'labels.commons.is required' | translate}}</strong>
          </mat-error>
        </mat-form-field>

        <button mat-raised-button fxFlex color="primary" [disabled]="!currencyForm.valid"
          *mifosxHasPermission="'UPDATE_CURRENCY'">
          <fa-icon icon="plus"></fa-icon>
        </button>

      </form>

    </div>

    <mat-grid-list cols="2" rowHeight="50px">

      <mat-grid-tile *ngFor="let currency of selectedCurrencies; index as i">

        <input matInput [value]="currency.name">

        <div fxLayout="row" fxLayoutAlign="flex-start">
          <button mat-button color="warn" (click)="deleteCurrency(currency.code, i)"
            *mifosxHasPermission="'UPDATE_CURRENCY'">
            <fa-icon icon="trash"></fa-icon>
          </button>
        </div>

      </mat-grid-tile>

    </mat-grid-list>

  </mat-card>

</div>

<ng-template #templateCurrencyFormRef let-popover="popover">
  <h4>{{'labels.heading.addCurrencyForm' | translate}}</h4>
  <p class="mw400">{{'labels.heading.addCurrencyText' | translate}} <a href=""
      target="_blank">{{'labels.anchors.currencyconfig' | translate}}</a></p>
  <div fxLayout="row" fxLayoutAlign="end" fxLayoutGap="2%" fxLayout.lt-md="column">
    <button mat-raised-button color="warn"
      (click)="popover.close();configurationWizardService.closeConfigWizard()">{{'labels.buttons.close' |
      translate}}</button>
    <button mat-raised-button color="primary" (click)="popover.close();">{{'labels.buttons.add' | translate}}</button>
    <button mat-raised-button color="primary" (click)="popover.close();previousStep()">{{'labels.buttons.back' |
      translate}}</button>
    <button mat-raised-button color="primary" (click)="popover.close();nextStep()">{{'labels.buttons.next' |
      translate}}</button>
  </div>
</ng-template>