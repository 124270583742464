<div class="tab-container mat-typography" *ngIf="loans.length > 0">

  <div fxLayout="row" fxLayoutAlign="start center">
    <div class="search-box" fxFlex="18%">
      <mat-form-field fxFlex="100%" appearance="fill">
        <input matInput [max]="endDateFilter.value" [matDatepicker]="submitPicker" [formControl]="startDateFilter"
          (dateChange)="applyFilters()" (keyup)="applyFilters()" [placeholder]="'labels.heading.fromdate'| translate">
        <mat-datepicker-toggle matSuffix [for]="submitPicker"></mat-datepicker-toggle>
        <mat-datepicker #submitPicker></mat-datepicker>
      </mat-form-field>
    </div>
    <div class="search-box" fxFlex="18%">
      <mat-form-field fxFlex="100%" appearance="fill">
        <input matInput [min]="startDateFilter.value" [matDatepicker]="submitPicker1" [formControl]="endDateFilter"
          (dateChange)="applyFilters()" (keyup)="applyFilters()" [placeholder]="'labels.heading.todate'| translate">
        <mat-datepicker-toggle matSuffix [for]="submitPicker1"><button>{{"labels.buttons.clear" | translate}}</button></mat-datepicker-toggle>
        <mat-datepicker #submitPicker1><button>{{"labels.buttons.clear" | translate}}</button></mat-datepicker>
      </mat-form-field>
    </div>
    <div fxFlex="59%">
      <div class="export">
        <button mat-raised-button color="success" *mifosxHasPermission="'APPROVE_LOANRESCHEDULE'"
          (click)="approveBulkLoanReschedule()">
          <fa-icon icon="check"></fa-icon>&nbsp;&nbsp;{{"labels.anchors.approve" | translate}}
        </button>
        <button mat-raised-button color="success" (click)="exportexcel()">
          <fa-icon icon="file-excel"></fa-icon>&nbsp;&nbsp;{{'labels.buttons.exportexcel' | translate}}
        </button>
      </div>
    </div>
  </div>

  <table mat-table [dataSource]="dataSource">

    <ng-container matColumnDef="select">
      <th mat-header-cell *matHeaderCellDef>
        <mat-checkbox (change)="$event ? masterToggle(filteredLoans) : null"
          [checked]="selection.hasValue() && isAllSelected(filteredLoans)"
          [indeterminate]="selection.hasValue() && !isAllSelected(filteredLoans)"
          [aria-label]="checkboxLabel(filteredLoans)">
        </mat-checkbox>
      </th>
      <td mat-cell *matCellDef="let row">
        <mat-checkbox (click)="$event.stopPropagation()" (change)="$event ? selection.toggle(row) : null"
          [checked]="selection.isSelected(row)" [aria-label]="checkboxLabel(row)">
        </mat-checkbox>
      </td>
    </ng-container>

    <ng-container matColumnDef="client">
      <th mat-header-cell *matHeaderCellDef> {{"labels.inputs.client" | translate}}
        <mat-form-field appearance="fill">
          <input matInput [formControl]="clientFilter" (keyup)="applyFilters()" [placeholder]="'labels.buttons.search'| translate">
        </mat-form-field>
      </th>
      <td mat-cell *matCellDef="let loan" class="view-details"
        [routerLink]="['/clients', loan.clientId , 'general']">
        {{loan.clientName}} </td>
    </ng-container>

    <ng-container matColumnDef="rescheduleRequestNo">
      <th mat-header-cell *matHeaderCellDef> {{"labels.heading.reschulerequest" | translate}}
        <mat-form-field appearance="fill">
          <input matInput [formControl]="rescheduleFilter" (keyup)="applyFilters()" [placeholder]="'labels.buttons.search'| translate">
        </mat-form-field>
      </th>
      <td mat-cell *matCellDef="let loan"> {{loan.id}} </td>
    </ng-container>

    <ng-container matColumnDef="loanAccountNo">
      <th mat-header-cell *matHeaderCellDef> {{"labels.heading.loanaccount" | translate}}
        <mat-form-field appearance="fill">
          <input matInput [formControl]="loanAccountFilter" (keyup)="applyFilters()" [placeholder]="'labels.buttons.search'| translate">
        </mat-form-field>
      </th>
      <td mat-cell *matCellDef="let loan" class="view-details"
        [routerLink]="['clients', loan.clientId , 'loans-accounts', loan.loanId, 'general']">
        {{loan.loanAccountNumber}}
      </td>
    </ng-container>

    <ng-container matColumnDef="rescheduleForm">
      <th mat-header-cell *matHeaderCellDef> {{"labels.rescheduleForm" | translate}}
        <mat-form-field appearance="fill">
          <input matInput [matDatepicker]="submitPicker" [formControl]="rescheduleFromDateFilter" [placeholder]="'labels.buttons.search'| translate"
            (dateChange)="applyFilters()" (keyup)="applyFilters()">
          <mat-datepicker-toggle matSuffix [for]="submitPicker"></mat-datepicker-toggle>
          <mat-datepicker #submitPicker></mat-datepicker>
        </mat-form-field>
      </th>
      <td mat-cell *matCellDef="let loan">
        {{loan.rescheduleFromDate | dateFormat}}
      </td>
    </ng-container>

    <ng-container matColumnDef="rescheduleReason">
      <th mat-header-cell *matHeaderCellDef> {{"labels.rescheduleReason" | translate}}
        <mat-form-field appearance="fill">
          <mat-select [formControl]="rescheduleReasonFilter" (selectionChange)="applyFilters()" [placeholder]="'labels.buttons.search'| translate">
            <mat-option value="">
              {{"labels.menus.All" | translate}}
            </mat-option>
            <mat-option *ngFor="let reason of rescheduleReasons" [value]="reason">
              {{ reason }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </th>
      <td mat-cell *matCellDef="let loan">
        {{loan.rescheduleReasonCodeValue.name}}
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="selection.toggle(row)">
    </tr>
  </table>
  <mat-paginator [pageSizeOptions]="[10, 20, 30]" showFirstLastButtons aria-label="Select page of periodic elements">
  </mat-paginator>

</div>

<div class="alert" *ngIf="loans.length === 0">

  <div class="message">
    <i class="fa fa-exclamation-circle alert-check"></i>
    {{"labels.noPendingRescheduledLoanAvailableAccount" | translate}}
  </div>

</div>
