import { Directive, ElementRef, HostListener, OnInit, Input } from '@angular/core';
import { SettingsService } from 'app/settings/settings.service';
import { NgControl } from '@angular/forms';
@Directive({
  selector: '[decimalInput]'
})
export class ValidateDecimalDirective implements OnInit {

  @Input() max: number;
  @Input() min: number;

  local: string;
  constructor(private el: ElementRef,
    private settingsService: SettingsService,
    private ngControl: NgControl) { }

  ngOnInit() {
    this.local = this.settingsService.language.code;
    if (this.local === "fr") {
      const initialVal: string = this.el.nativeElement.value;
      this.el.nativeElement.value = initialVal.replace(".", ",")
      this.updateControlValue(initialVal.replace(".", ","))
    }
  }

  minMaxValidator(val: string) {
    const decimalValue = parseFloat(val.replace(",", "."));
    if (this?.max < decimalValue) {
      this.ngControl.control.setErrors({ max: true });
    }
    if (this?.min > decimalValue) {
      this.ngControl.control.setErrors({ min: true });
    }
  }

  @HostListener('input')
  onInput() {
    const inputValue: string = this.el.nativeElement.value.replace(/[^0-9.,-]/g, '');
    if (this.local === "fr") {
      const newValue: string = inputValue.replace(/\./g, ',');
      const pattern: RegExp = /^\d+\,?\d{0,4}$/;
      if (!pattern.test(newValue)) {
        this.el.nativeElement.value = newValue.slice(0, -1);
        this.updateControlValue(newValue.slice(0, -1));
      } else {
        this.el.nativeElement.value = newValue;
        this.updateControlValue(newValue);
      }
    } else {
      const newValue: string = inputValue.replace(/\,/g, '.');
      const pattern: RegExp = /^\d+\.?\d{0,4}$/;
      if (!pattern.test(newValue)) {
        this.el.nativeElement.value = newValue.slice(0, -1);
        this.updateControlValue(newValue.slice(0, -1));
      } else {
        this.el.nativeElement.value = newValue;
        this.updateControlValue(newValue);
      }
    }
    this.minMaxValidator(this.el.nativeElement.value);

  }

  updateControlValue(value: any) {
    if (this.ngControl) {
      this.ngControl.control.setValue(value);
      this.ngControl.control.updateValueAndValidity();
    }
  }
}
