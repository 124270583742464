<div class="container m-b-20" fxLayout="row" fxLayoutAlign="end" fxLayoutGap="20px">
  <button mat-raised-button color="primary" [routerLink]="['edit']" *mifosxHasPermission="'UPDATE_STAFF'">
    <fa-icon icon="edit"></fa-icon>&nbsp;&nbsp;
    {{"labels.inputs.edit" | translate}}
  </button>
</div>

<div class="container">

  <mat-card>

    <mat-card-content>

      <div fxLayout="row wrap" class="content">

        <div fxFlex="50%" class="mat-body-strong">
          {{"labels.inputs.firstname" | translate}}
        </div>

        <div fxFlex="50%">
          {{ employeeData.firstname }}
        </div>

        <div fxFlex="50%" class="mat-body-strong">
          {{"labels.inputs.lastname" | translate}}
        </div>

        <div fxFlex="50%">
          {{ employeeData.lastname }}
        </div>

        <div fxFlex="50%" class="mat-body-strong">
          {{"labels.inputs.office" | translate}}
        </div>

        <div fxFlex="50%">
          {{ employeeData.officeName }}
        </div>

        <div fxFlex="50%" class="mat-body-strong">
          {{"labels.inputs.isLoanOfficer" | translate}}
        </div>

        <div fxFlex="50%">
          {{ employeeData.isLoanOfficer === true ? 'Yes' : 'No' }}
        </div>

        <div fxFlex="50%" class="mat-body-strong" *ngIf="employeeData.mobileNo">
          {{"labels.inputs.mobileNo" | translate}}
        </div>

        <div fxFlex="50%" *ngIf="employeeData.mobileNo">
          {{ employeeData.mobileNo }}
        </div>

        <div fxFlex="50%" class="mat-body-strong">
          {{"labels.menus.status" | translate}}
        </div>

        <div fxFlex="50%">
          {{ employeeData.isActive === true ? 'Active' : 'Inactive' }}
        </div>

        <div fxFlex="50%" class="mat-body-strong">
          {{"labels.joiningDate" | translate}}
        </div>

        <div fxFlex="50%">
          {{ employeeData.joiningDate  | dateFormat }}
        </div>

      </div>

    </mat-card-content>

  </mat-card>

</div>
