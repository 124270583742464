<div class="container m-b-20" fxLayout="row" fxLayoutAlign="end" fxLayoutGap="20px">

  <button mat-raised-button color="primary" [routerLink]="['create']" *mifosxHasPermission="'CREATE_TELLER'">
    <fa-icon icon="plus"></fa-icon>&nbsp;&nbsp;
    {{'labels.anchors.createTeller' | translate}}
  </button>

</div>

<div class="container">

  <div fxLayout="row" fxLayoutGap="20px">
    <mat-form-field fxFlex>
      <mat-label>{{'labels.buttons.filter' | translate}}</mat-label>
      <input matInput (keyup)="applyFilter($event.target.value)">
    </mat-form-field>
  </div>

  <div class="mat-elevation-z8">

    <table mat-table [dataSource]="dataSource" matSort>

      <ng-container matColumnDef="officeName">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'labels.heading.cashmgmt.officeName' | translate}}
        </th>
        <td mat-cell *matCellDef="let teller"> {{ teller.officeName }} </td>
      </ng-container>

      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'labels.heading.cashmgmt.tellerName' | translate}}
        </th>
        <td mat-cell *matCellDef="let teller"> {{ teller.name }} </td>
      </ng-container>

      <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'labels.heading.cashmgmt.tellerStatus' | translate}}
        </th>
        <td mat-cell *matCellDef="let teller">
          <div [className]="teller.status === 'ACTIVE' ? 'true' : 'false'">
            <fa-icon matTooltip="{{ teller.status === 'ACTIVE' ? 'Active' : 'Inactive' }}" matTooltipPosition="right"
              icon="circle" size="lg"></fa-icon>
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="startDate">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'labels.heading.cashmgmt.tellerStartDate' | translate}}
        </th>
        <td mat-cell *matCellDef="let teller"> {{ teller.startDate | dateFormat }} </td>
      </ng-container>

      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef> {{'labels.heading.actions' | translate}} </th>
        <td mat-cell *matCellDef="let teller" (click)="$event.stopPropagation()">
          <button mat-button color="primary" [routerLink]="[teller.id,'cashiers']">
            <fa-icon icon="eye"></fa-icon>&nbsp;&nbsp;{{'labels.buttons.viewCashiers' | translate}}
          </button>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;" [routerLink]="[row.id]" class="select-row"></tr>

    </table>

    <mat-paginator [pageSizeOptions]="[10, 25, 50, 100]" showFirstLastButtons></mat-paginator>

  </div>

</div>