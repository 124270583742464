<div fxLayout="row wrap" fxLayout.lt-md="column">

  <h2 class="mat-h2" fxFlexFill>{{ fixedDepositProduct.name }}</h2>

  <mat-divider fxFlexFill></mat-divider>

  <h3 class="mat-h3" fxFlexFill>{{"labels.buttons.details" | translate}}</h3>

  <mat-divider fxFlexFill></mat-divider>

  <div fxFlexFill>
    <span fxFlex="40%">{{"labels.inputs.product.shortname" | translate}}:</span>
    <span fxFlex="60%">{{ fixedDepositProduct.shortName }}</span>
  </div>

  <div fxFlexFill *ngIf="fixedDepositProduct.description">
    <span fxFlex="40%">{{"labels.heading.description" | translate}}:</span>
    <span fxFlex="60%">{{ fixedDepositProduct.description }}</span>
  </div>

  <h3 class="mat-h3" fxFlexFill>{{"labels.inputs.currency" | translate}}</h3>

  <mat-divider fxFlexFill></mat-divider>

  <div fxFlexFill>
    <span fxFlex="40%">{{"labels.heading.currency" | translate}}:</span>
    <span fxFlex="60%">{{ fixedDepositProduct.currencyCode | find:fixedDepositProductsTemplate.currencyOptions:'code':'name' }}</span>
  </div>

  <div fxFlexFill>
    <span fxFlex="40%">{{"labels.inputs.decimalplace" | translate}}:</span>
    <span fxFlex="60%">{{ fixedDepositProduct.digitsAfterDecimal }}</span>
  </div>

  <div fxFlexFill>
    <span fxFlex="40%">{{"labels.heading.currencyinmultiplesof" | translate}}:</span>
    <span fxFlex="60%">{{ fixedDepositProduct.inMultiplesOf }}</span>
  </div>

  <h3 class="mat-h3" fxFlexFill>{{"labels.terms" | translate}}</h3>

  <mat-divider fxFlexFill></mat-divider>

  <div fxFlexFill>
    <span fxFlex="40%">{{"labels.inputs.depositamount" | translate}}:</span>
    <span fxFlex="60%">{{ fixedDepositProduct.depositAmount + ' (Min: ' + (fixedDepositProduct.minDepositAmount ? fixedDepositProduct.minDepositAmount : fixedDepositProduct.depositAmount) + ', Max: ' + (fixedDepositProduct.maxDepositAmount ? fixedDepositProduct.maxDepositAmount : fixedDepositProduct.depositAmount) + ')' }}</span>
  </div>

  <div fxFlexFill>
    <span fxFlex="40%">{{"labels.heading.interestcompoundingperiod" | translate}}:</span>
    <span fxFlex="60%">{{ fixedDepositProduct.interestCompoundingPeriodType | find:fixedDepositProductsTemplate.interestCompoundingPeriodTypeOptions:'id':'value' }}</span>
  </div>

  <div fxFlexFill>
    <span fxFlex="40%">{{"labels.heading.interestpostingperiod" | translate}}:</span>
    <span fxFlex="60%">{{ fixedDepositProduct.interestPostingPeriodType | find:fixedDepositProductsTemplate.interestPostingPeriodTypeOptions:'id':'value' }}</span>
  </div>

  <div fxFlexFill>
    <span fxFlex="40%">{{"labels.heading.interestcalculatedusing" | translate}}:</span>
    <span fxFlex="60%">{{ fixedDepositProduct.interestCalculationType | find:fixedDepositProductsTemplate.interestCalculationTypeOptions:'id':'value' }}</span>
  </div>

  <div fxFlexFill>
    <span fxFlex="40%">{{"labels.heading.daysinyears" | translate}}:</span>
    <span fxFlex="60%">{{ fixedDepositProduct.interestCalculationDaysInYearType | find:fixedDepositProductsTemplate.interestCalculationDaysInYearTypeOptions:'id':'value' }}</span>
  </div>

  <h3 class="mat-h3" fxFlexFill>{{"labels.menus.Settings" | translate}}</h3>

  <mat-divider fxFlexFill></mat-divider>

  <div fxFlexFill *ngIf="fixedDepositProduct.lockinPeriodFrequency">
    <span fxFlex="40%">{{"labels.heading.lockinPeriodFrequency" | translate}}:</span>
    <span fxFlex="60%">{{ fixedDepositProduct.lockinPeriodFrequency + ' ' + (fixedDepositProduct.lockinPeriodFrequencyType | find:fixedDepositProductsTemplate.lockinPeriodFrequencyTypeOptions:'id':'value') }}</span>
  </div>

  <div fxFlexFill>
    <span fxFlex="40%">{{"labels.heading.minimumdepositterm" | translate}}:</span>
    <span fxFlex="60%">{{ fixedDepositProduct.minDepositTerm + ' ' + (fixedDepositProduct.minDepositTermTypeId | find:fixedDepositProductsTemplate.periodFrequencyTypeOptions:'id':'value') }}</span>
  </div>

  <div fxFlexFill *ngIf="fixedDepositProduct.inMultiplesOfDepositTerm">
    <span fxFlex="40%">{{"labels.inputs.inmultiplesof" | translate}}:</span>
    <span fxFlex="60%">{{ fixedDepositProduct.inMultiplesOfDepositTerm + ' ' + (fixedDepositProduct.inMultiplesOfDepositTermTypeId | find:fixedDepositProductsTemplate.periodFrequencyTypeOptions:'id':'value') }}</span>
  </div>

  <div fxFlexFill *ngIf="fixedDepositProduct.maxDepositTerm">
    <span fxFlex="40%">{{"labels.inputs.maximumdepositterm" | translate}}:</span>
    <span fxFlex="60%">{{ fixedDepositProduct.maxDepositTerm + ' ' + (fixedDepositProduct.maxDepositTermTypeId | find:fixedDepositProductsTemplate.periodFrequencyTypeOptions:'id':'value') }}</span>
  </div>
  <!-- For Pre-mature closure -->
  <div fxFlexFill>
    <span fxFlex="40%">{{"labels.heading.preclosurepenalapplicable" | translate}}:</span>
    <span fxFlex="60%">{{ fixedDepositProduct.preClosurePenalApplicable ? 'Yes' : 'No' }}</span>
  </div>

  <div fxFlexFill *ngIf="fixedDepositProduct.preClosurePenalApplicable">
    <span fxFlex="40%">{{"labels.penalInterest" | translate}} (%):</span>
    <span fxFlex="60%">{{ fixedDepositProduct.preClosurePenalInterest + '% (' + (fixedDepositProduct.preClosurePenalInterestOnTypeId | find:fixedDepositProductsTemplate.preClosurePenalInterestOnTypeOptions:'id':'value') + ')' }}</span>
  </div>
  <!--  -->
  <div fxFlexFill>
    <span fxFlex="40%">{{"labels.withholdTaxApplicable" | translate}}):</span>
    <span fxFlex="60%">{{ fixedDepositProduct.withHoldTax ? 'Yes' : 'No' }}</span>
  </div>

  <div fxFlexFill *ngIf="fixedDepositProduct.withHoldTax">
    <span fxFlex="40%">{{"labels.inputs.taxgroup" | translate}}):</span>
    <span fxFlex="60%">{{ fixedDepositProduct.taxGroupId | find:fixedDepositProductsTemplate.taxGroupOptions:'id':'name' }}</span>
  </div>

  <div fxFlexFill fxLayout="row wrap" fxLayout.lt-md="column" *ngIf="fixedDepositProduct.charts.length !== 0">

    <h3 class="mat-h3" fxFlexFill>{{"labels.heading.interestchart" | translate}} </h3>

    <mat-divider fxFlexFill></mat-divider>

    <div fxFlexFill *ngFor="let chart of fixedDepositProduct.charts; let chartIndex = index" fxLayout="row wrap" fxLayout.lt-md="column">

      <div fxFlexFill *ngIf="chart.name">
        <span fxFlex="40%">{{"labels.heading.name" | translate}}):</span>
        <span fxFlex="60%">{{ chart.name }}</span>
      </div>

      <div fxFlexFill>
        <span fxFlex="40%">{{"labels.heading.interestratechart.valid.from" | translate}}):</span>
        <span fxFlex="60%">{{ chart.fromDate  | dateFormat }}</span>
      </div>

      <div fxFlexFill *ngIf="chart.endDate">
        <span fxFlex="40%">{{"labels.heading.interestratechart.valid.to" | translate}}):</span>
        <span fxFlex="60%">{{ chart.endDate  | dateFormat }}</span>
      </div>

      <div fxFlexFill *ngIf="chart.description">
        <span fxFlex="40%">{{"labels.heading.description" | translate}}:</span>
        <span fxFlex="60%">{{ chart.description }}</span>
      </div>

      <div fxFlexFill>
        <span fxFlex="40%">{{"labels.primaryGroupingAmount" | translate}}):</span>
        <span fxFlex="60%">{{ fixedDepositProduct.isPrimaryGroupingByAmount ? 'Yes' : 'No' }}</span>
      </div>

      <table fxFlexFill class="mat-elevation-z1" mat-table [dataSource]="chart.chartSlabs" *ngIf="chart.chartSlabs.length !== 0" multiTemplateDataRows>

        <ng-container matColumnDef="period">
          <th mat-header-cell *matHeaderCellDef>{{"labels.Period" | translate}} </th>
          <td mat-cell *matCellDef="let chartSlab">
            {{ chartSlab.fromPeriod + ' - ' + chartSlab.toPeriod + ' ' + (chartSlab.periodType | find:fixedDepositProductsTemplate.chartTemplate.periodTypes:'id':'value') }}
          </td>
        </ng-container>

        <ng-container matColumnDef="amountRange">
          <th mat-header-cell *matHeaderCellDef>{{"labels.heading.interestratedetails.amount.range" | translate}} </th>
          <td mat-cell *matCellDef="let chartSlab">
            {{ chartSlab.amountRangeFrom + ' - ' + chartSlab.amountRangeTo }}
          </td>
        </ng-container>

        <ng-container matColumnDef="annualInterestRate">
          <th mat-header-cell *matHeaderCellDef>{{"labels.heading.interest" | translate}} </th>
          <td mat-cell *matCellDef="let chartSlab">
            {{ chartSlab.annualInterestRate }}
          </td>
        </ng-container>

        <ng-container matColumnDef="description">
          <th mat-header-cell *matHeaderCellDef>{{"labels.heading.description" | translate}} </th>
          <td mat-cell *matCellDef="let chartSlab">
            {{ chartSlab.description }}
          </td>
        </ng-container>

        <ng-container matColumnDef="actions">
          <th mat-header-cell *matHeaderCellDef>{{"labels.inputs.actions" | translate}} </th>
          <td mat-cell *matCellDef="let chartSlab; let chartSlabIndex = dataIndex">
            <button mat-button color="primary" (click)="expandChartSlabIndex[chartIndex] = expandChartSlabIndex[chartIndex] === chartSlabIndex ? null : chartSlabIndex" [disabled]="chartSlab.incentives.length === 0">
              <span *ngIf="expandChartSlabIndex[chartIndex] !== chartSlabIndex">
                <fa-icon icon="eye"></fa-icon>&nbsp;&nbsp;
                {{"labels.viewIncentives" | translate}}
              </span>
              <span *ngIf="expandChartSlabIndex[chartIndex] === chartSlabIndex">
                <fa-icon icon="eye-slash"></fa-icon>&nbsp;&nbsp;
                {{"labels.hideIncentives" | translate}}
              </span>
            </button>
          </td>
        </ng-container>

        <ng-container matColumnDef="incentives">
          <td mat-cell *matCellDef="let chartSlab; let chartSlabIndex = dataIndex" [attr.colspan]="chartSlabsDisplayedColumns[chartIndex].length">
            <div fxLayout="row wrap" fxFlexFill class="incentives" [@expandChartSlab]="chartSlabIndex === expandChartSlabIndex[chartIndex] ? 'expanded' : 'collapsed'">
              <mat-card fxLayout="row wrap" fxFlexFill>

                <h4 class="mat-h4" fxFlex="23%">
                  {{"labels.incentives" | translate}}
                </h4>

                <table fxFlexFill class="mat-elevation-z1" mat-table [dataSource]="chartSlab.incentives">

                  <ng-container matColumnDef="entityType">
                    <th mat-header-cell *matHeaderCellDef>{{"labels.entityType" | translate}} </th>
                    <td mat-cell *matCellDef="let incentive">
                      {{ incentive.entityType | find:fixedDepositProductsTemplate.chartTemplate.entityTypeOptions:'id':'value' }}
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="attributeName">
                    <th mat-header-cell *matHeaderCellDef>{{"labels.attributeName" | translate}} </th>
                    <td mat-cell *matCellDef="let incentive">
                      {{ incentive.attributeName | find:fixedDepositProductsTemplate.chartTemplate.attributeNameOptions:'id':'value' }}
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="conditionType">
                    <th mat-header-cell *matHeaderCellDef>{{"labels.conditionType" | translate}} </th>
                    <td mat-cell *matCellDef="let incentive">
                      {{ incentive.conditionType | find:fixedDepositProductsTemplate.chartTemplate.conditionTypeOptions:'id':'value' }}
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="attributeValue">
                    <th mat-header-cell *matHeaderCellDef>{{"labels.attributeName" | translate}} </th>
                    <td mat-cell *matCellDef="let incentive" [ngSwitch]="incentive.attributeName">
                      <span *ngSwitchCase="2">{{ incentive.attributeValue | find:fixedDepositProductsTemplate.chartTemplate.genderOptions:'id':'name' }}</span>
                      <span *ngSwitchCase="3">{{ incentive.attributeValue }}</span>
                      <span *ngSwitchCase="4">{{ incentive.attributeValue | find:fixedDepositProductsTemplate.chartTemplate.clientTypeOptions:'id':'name' }}</span>
                      <span *ngSwitchCase="5">{{ incentive.attributeValue | find:fixedDepositProductsTemplate.chartTemplate.clientClassificationOptions:'id':'name' }}</span>
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="incentiveType">
                    <th mat-header-cell *matHeaderCellDef>{{"labels.incentiveType" | translate}} </th>
                    <td mat-cell *matCellDef="let incentive">
                      {{ incentive.incentiveType | find:fixedDepositProductsTemplate.chartTemplate.incentiveTypeOptions:'id':'value'}}
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="amount">
                    <th mat-header-cell *matHeaderCellDef>{{"labels.heading.amount" | translate}}</th>
                    <td mat-cell *matCellDef="let incentive">
                      {{ incentive.amount }}
                    </td>
                  </ng-container>

                  <tr mat-header-row *matHeaderRowDef="incentivesDisplayedColumns"></tr>
                  <tr mat-row *matRowDef="let row; columns: incentivesDisplayedColumns;"></tr>

                </table>

              </mat-card>
            </div>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="chartSlabsDisplayedColumns[chartIndex]"></tr>
        <tr mat-row *matRowDef="let row; columns: chartSlabsDisplayedColumns[chartIndex];"></tr>
        <tr mat-row *matRowDef="let row; columns: chartSlabsIncentivesDisplayedColumns;" class="incentives-row"></tr>

      </table>

      <mat-divider fxFlexFill></mat-divider>

    </div>

  </div>

  <div fxFlexFill *ngIf="fixedDepositProduct.charges.length" fxLayout="row wrap" fxLayout.lt-md="column">

    <h3 class="mat-h3" fxFlexFill>{{"labels.anchors.charges" | translate}}</h3>

    <mat-divider fxFlexFill></mat-divider>

    <table fxFlexFill class="mat-elevation-z1" mat-table [dataSource]="fixedDepositProduct.charges">

      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef>{{"labels.inputs.name" | translate}}</th>
        <td mat-cell *matCellDef="let charge">
          {{ charge.name + ', ' + charge.currency.displaySymbol }}
        </td>
      </ng-container>

      <ng-container matColumnDef="chargeCalculationType">
        <th mat-header-cell *matHeaderCellDef>{{"labels.inputs.type" | translate}}</th>
        <td mat-cell *matCellDef="let charge">
          {{ charge.chargeCalculationType.value }}
        </td>
      </ng-container>

      <ng-container matColumnDef="amount">
        <th mat-header-cell *matHeaderCellDef>{{"labels.heading.amount" | translate}}</th>
        <td mat-cell *matCellDef="let charge">
          {{ charge.amount  }}
        </td>
      </ng-container>

      <ng-container matColumnDef="chargeTimeType">
        <th mat-header-cell *matHeaderCellDef>{{"labels.heading.collectedon" | translate}}</th>
        <td mat-cell *matCellDef="let charge">
          {{ charge.chargeTimeType.value }}
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="chargesDisplayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: chargesDisplayedColumns;"></tr>

    </table>

  </div>

  <h3 class="mat-h3" fxFlexFill>{{"labels.menus.Accounting" | translate}}</h3>

  <mat-divider fxFlexFill></mat-divider>

  <div fxFlexFill>
    <span fxFlex="40%">{{"labels.inputs.type" | translate}}:</span>
    <span fxFlex="60%">{{ accountingRuleData[fixedDepositProduct.accountingRule-1] }}</span>
  </div>

  <div fxFlexFill *ngIf="fixedDepositProduct.accountingRule === 2" fxLayout="row wrap" fxLayout.lt-md="column">

    <h4 fxFlexFill class="mat-h4">{{"labels.assets" | translate}}</h4>

    <div fxFlexFill>
      <span fxFlex="40%">{{"labels.inputs.savingreference" | translate}}:</span>
      <span fxFlex="60%">{{ fixedDepositProduct.savingsReferenceAccountId | find:fixedDepositProductsTemplate.accountingMappingOptions.assetAccountOptions:'id':'name' }}</span>
    </div>

    <h4 fxFlexFill class="mat-h4">{{"labels.heading.liabilities" | translate}} </h4>

    <div fxFlexFill fxLayout="row wrap" fxLayout.lt-md="column">
      <span fxFlex="40%">{{"labels.inputs.savingcontrol" | translate}}:</span>
      <span fxFlex="60%">{{ fixedDepositProduct.savingsControlAccountId | find:fixedDepositProductsTemplate.accountingMappingOptions.liabilityAccountOptions:'id':'name' }}</span>
      <span fxFlex="40%">{{"labels.inputs.savingstransfersinsuspenpense" | translate}}:</span>
      <span fxFlex="60%">{{ fixedDepositProduct.transfersInSuspenseAccountId | find:fixedDepositProductsTemplate.accountingMappingOptions.liabilityAccountOptions:'id':'name' }}</span>
    </div>

    <h4 fxFlexFill class="mat-h4">{{"labels.heading.expenses" | translate}} </h4>

    <div fxFlexFill>
      <span fxFlex="40%">{{"labels.inputs.interestonsavings" | translate}}:</span>
      <span fxFlex="60%">{{ fixedDepositProduct.interestOnSavingsAccountId | find:fixedDepositProductsTemplate.accountingMappingOptions.expenseAccountOptions:'id':'name' }}</span>
    </div>

    <h4 fxFlexFill class="mat-h4">{{"labels.heading.income" | translate}} </h4>

    <div fxFlexFill fxLayout="row wrap" fxLayout.lt-md="column">
      <span fxFlex="40%">{{"labels.heading.incomefromfees" | translate}}:</span>
      <span fxFlex="60%">{{ fixedDepositProduct.incomeFromFeeAccountId | find:fixedDepositProductsTemplate.accountingMappingOptions.incomeAccountOptions:'id':'name' }}</span>
      <span fxFlex="40%">{{"labels.heading.incomefrompenalties" | translate}}:</span>
      <span fxFlex="60%">{{ fixedDepositProduct.incomeFromPenaltyAccountId | find:fixedDepositProductsTemplate.accountingMappingOptions.incomeAccountOptions:'id':'name' }}</span>
    </div>

    <div *ngIf="fixedDepositProduct.advancedAccountingRules && (fixedDepositProduct.paymentChannelToFundSourceMappings?.length || fixedDepositProduct.feeToIncomeAccountMappings?.length || fixedDepositProduct.penaltyToIncomeAccountMappings?.length)" fxFlexFill fxLayout="row wrap" fxLayout.lt-md="column">

      <h3 class="mat-h3" fxFlexFill>{{"labels.heading.advancedaccountingrules" | translate}} </h3>

      <mat-divider fxFlexFill></mat-divider>

      <div *ngIf="fixedDepositProduct.paymentChannelToFundSourceMappings?.length" fxFlexFill fxLayout="row wrap" fxLayout.lt-md="column">

        <h4 class="mat-h4" fxFlexFill>{{"labels.fundsourcesforpaymentchannels" | translate}}:</h4>

        <table fxFlexFill class="mat-elevation-z1" mat-table [dataSource]="fixedDepositProduct.paymentChannelToFundSourceMappings">

          <ng-container matColumnDef="paymentTypeId">
            <th mat-header-cell *matHeaderCellDef>{{"labels.heading.paymenttype" | translate}} </th>
            <td mat-cell *matCellDef="let paymentFundSource">
              {{ paymentFundSource.paymentTypeId | find:fixedDepositProductsTemplate.paymentTypeOptions:'id':'name' }}
            </td>
          </ng-container>

          <ng-container matColumnDef="fundSourceAccountId">
            <th mat-header-cell *matHeaderCellDef>{{"labels.heading.fundsource" | translate}} </th>
            <td mat-cell *matCellDef="let paymentFundSource">
              {{ paymentFundSource.fundSourceAccountId | find:fixedDepositProductsTemplate.accountingMappingOptions.assetAccountOptions:'id':'name' }}
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="paymentFundSourceDisplayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: paymentFundSourceDisplayedColumns;"></tr>

        </table>

      </div>

      <div *ngIf="fixedDepositProduct.feeToIncomeAccountMappings?.length" fxFlexFill fxLayout="row wrap" fxLayout.lt-md="column">

        <h4 class="mat-h4" fxFlexFill>{{"labels.mapFeesSpecificIncomeAccounts" | translate}}:</h4>

        <table fxFlexFill class="mat-elevation-z1" mat-table [dataSource]="fixedDepositProduct.feeToIncomeAccountMappings">

          <ng-container matColumnDef="chargeId">
            <th mat-header-cell *matHeaderCellDef>{{"labels.commons.fees" | translate}}:</th>
            <td mat-cell *matCellDef="let feesIncome">
              {{ feesIncome.chargeId | find:fixedDepositProductsTemplate.chargeOptions:'id':'name' }}
            </td>
          </ng-container>

          <ng-container matColumnDef="incomeAccountId">
            <th mat-header-cell *matHeaderCellDef>{{"labels.heading.incomeaccount" | translate}} </th>
            <td mat-cell *matCellDef="let feesIncome">
              {{ feesIncome.incomeAccountId | find:fixedDepositProductsTemplate.accountingMappingOptions.incomeAccountOptions:'id':'name' }}
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="feesPenaltyIncomeDisplayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: feesPenaltyIncomeDisplayedColumns;"></tr>

        </table>

      </div>

      <div *ngIf="fixedDepositProduct.penaltyToIncomeAccountMappings?.length" fxFlexFill fxLayout="row wrap" fxLayout.lt-md="column">

        <h4 class="mat-h4" fxFlexFill>{{"labels.heading.mappenaltiestospecificincomeaccounts" | translate}}:</h4>

        <table fxFlexFill class="mat-elevation-z1" mat-table [dataSource]="fixedDepositProduct.penaltyToIncomeAccountMappings">

          <ng-container matColumnDef="chargeId">
            <th mat-header-cell *matHeaderCellDef>{{"labels.commons.penalty" | translate}} </th>
            <td mat-cell *matCellDef="let penaltyIncome">
              {{ penaltyIncome.chargeId  | find:fixedDepositProductsTemplate.penaltyOptions:'id':'name' }}
            </td>
          </ng-container>

          <ng-container matColumnDef="incomeAccountId">
            <th mat-header-cell *matHeaderCellDef>{{"labels.heading.incomeaccount" | translate}} </th>
            <td mat-cell *matCellDef="let penaltyIncome">
              {{ penaltyIncome.incomeAccountId | find:fixedDepositProductsTemplate.accountingMappingOptions.incomeAccountOptions:'id':'name' }}
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="feesPenaltyIncomeDisplayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: feesPenaltyIncomeDisplayedColumns;"></tr>

        </table>

      </div>

    </div>

  </div>

</div>

<div fxLayout="row" class="margin-t" fxLayout.xs="column" fxLayoutAlign="center" fxLayoutGap="2%">
  <button mat-raised-button matStepperPrevious>
    <fa-icon icon="arrow-left"></fa-icon>&nbsp;&nbsp;
    {{"labels.heading.prev" | translate}}
  </button>
  <button mat-raised-button [routerLink]="['../']">
    {{"labels.buttons.cancel" | translate}}
  </button>
  <button mat-raised-button color="primary" (click)="submit.emit()">
    {{"labels.buttons.submit" | translate}}
  </button>
</div>
