<div class="container m-b-20" fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="end" fxLayoutGap="2%">
  <button mat-raised-button color="primary" [routerLink]="['edit']">
    <fa-icon icon="edit"></fa-icon>&nbsp;&nbsp;
    {{'labels.inputs.edit'| translate}}
  </button>
  <button mat-raised-button color="warn" (click)="delete()">
    <fa-icon icon="trash"></fa-icon>&nbsp;&nbsp;
    {{'labels.anchors.delete'| translate}}
  </button>
  <button mat-raised-button color="primary" (click)="changeUserPassword()">
    <fa-icon icon="cog"></fa-icon>&nbsp;&nbsp;
    {{'labels.buttons.changepassword'| translate}}
  </button>
</div>

<div class="container">

  <mat-card>

    <mat-card-content>

      <div fxLayout="row wrap" class="content">

        <div fxFlex="50%" class="mat-body-strong">
          {{'labels.inputs.loginName'| translate}}
        </div>

        <div fxFlex="50%">
          {{ userData.username }}
        </div>

        <div fxFlex="50%" class="mat-body-strong">
          {{'labels.inputs.firstname'| translate}}
        </div>

        <div fxFlex="50%">
          {{ userData.firstname }}
        </div>

        <div fxFlex="50%" class="mat-body-strong">
          {{'labels.inputs.lastname'| translate}}
        </div>

        <div fxFlex="50%">
          {{ userData.lastname }}
        </div>


        <div fxFlex="50%" class="mat-body-strong" *ngIf="userData.email">
          {{'labels.heading.email'| translate}}
        </div>

        <div fxFlex="50%" *ngIf="userData.email">
          {{ userData.email }}
        </div>

        <div fxFlex="50%" class="mat-body-strong">
          {{'labels.inputs.office'| translate}}
        </div>

        <div fxFlex="50%">
          {{ userData.officeName }}
        </div>

        <div fxFlex="50%" class="mat-body-strong">
          {{'labels.inputs.roles'| translate}}
        </div>

        <div fxFlex="50%">
          <span *ngFor="let role of userData.selectedRoles">
            {{ role.name }}
          </span>
        </div>

      </div>

    </mat-card-content>

  </mat-card>

</div>
