/** Angular Imports */
import { AuthenticationService } from '../../core/authentication/authentication.service';
import { Injectable } from '@angular/core';

@Injectable()
export class HasPermission {

  /** User Permissions */
  private userPermissions: any[];

  constructor(private authenticationService: AuthenticationService) {
    const savedCredentials = this.authenticationService.getCredentials();
    this.userPermissions = savedCredentials.permissions;
  }

  hasPermission(permission: string) {
    permission = permission.trim();
    if (this.userPermissions.includes('ALL_FUNCTIONS')) {
      return true;
    } else if (permission !== '') {
        if (permission.substring(0, 5) === 'READ_' && this.userPermissions.includes('ALL_FUNCTIONS_READ')) {
          return true;
        } else if (this.userPermissions.includes(permission)) {
          return true;
        } else {
          return false;
        }
    } else {
      return false;
    }
  }

  hasOneOfThosePermissions = function(permissions:string[]){
    let result = false;
    permissions.forEach((item:string)=>{
      result = result || this.hasPermission(item);
    });
    return result;
  }


}
