<div class="container">

    <mat-card>

        <form [formGroup]="collateralForm" (ngSubmit)="submit()">

            <mat-card-content>

                <div fxLayout="row wrap" fxLayoutGap="2%" fxLayout.lt-md="column">

                    <mat-form-field fxFlex="48%">
                        <mat-label>{{"labels.heading.name" | translate}}</mat-label>
                        <input matInput required formControlName="name">
                        <mat-error *ngIf="collateralForm.controls.name.hasError('required')">
                          {{"labels.inputs.name" | translate}} <strong>{{"labels.commons.is required" | translate}}</strong>
                        </mat-error>
                    </mat-form-field>
                    <mat-form-field fxFlex="48%">
                        <mat-label>{{"labels.heading.typeQuality" | translate}}</mat-label>
                        <input matInput required formControlName="quality">
                        <mat-error *ngIf="collateralForm.controls.quality.hasError('required')">
                            {{"labels.quality" | translate}} <strong>{{"labels.commons.is required" | translate}}</strong>
                        </mat-error>
                    </mat-form-field>
                    <mat-form-field fxFlex="48%">
                        <mat-label>{{"labels.inputs.unittype" | translate}}</mat-label>
                        <input matInput required formControlName="unitType">
                        <mat-error *ngIf="collateralForm.controls.unitType.hasError('required')">
                          {{"labels.inputs.unittype" | translate}} <strong>{{"labels.commons.is required" | translate}}</strong>
                        </mat-error>
                    </mat-form-field>
                    <mat-form-field fxFlex="48%">
                        <mat-label>{{"labels.inputs.baseprice" | translate}}</mat-label>
                        <input matInput required formControlName="basePrice">
                        <mat-error *ngIf="collateralForm.controls.basePrice.hasError('required')">
                          {{"labels.inputs.baseprice" | translate}} <strong>{{"labels.commons.is required" | translate}}</strong>
                        </mat-error>
                    </mat-form-field>
                    <mat-form-field fxFlex="48%">
                        <mat-label>{{"labels.inputs.pcttobase" | translate}}</mat-label>
                        <input matInput required formControlName="pctToBase">
                        <mat-error *ngIf="collateralForm.controls.pctToBase.hasError('required')">
                          {{"labels.inputs.pcttobase" | translate}} <strong>{{"labels.commons.is required" | translate}}</strong>
                        </mat-error>
                    </mat-form-field>
                    <mat-form-field fxFlex="48%">
                        <mat-label>{{"labels.inputs.currency" | translate}}</mat-label>
                        <mat-select required formControlName="currency">
                            <mat-option *ngFor="let currency of collateralTemplateData" [value]="currency.code">
                            {{ currency.name }}
                            </mat-option>
                        </mat-select>
                        <mat-error *ngIf="collateralForm.controls.currency.hasError('required')">
                          {{"labels.inputs.currency" | translate}} <strong>{{"labels.commons.is required" | translate}}</strong>
                        </mat-error>
                    </mat-form-field>

                </div>

            </mat-card-content>

            <mat-card-actions fxLayout="row" fxLayout.xs="column" fxLayoutAlign="center" fxLayoutGap="5px">
                <button type="button" mat-raised-button [routerLink]="['../']"> {{"labels.buttons.cancel" | translate}}</button>
                <button mat-raised-button color="primary" [disabled]="!collateralForm.valid" *mifosxHasPermission="'CREATE_CHARGE'">{{"labels.buttons.submit" | translate}}</button>
            </mat-card-actions>

        </form>

    </mat-card>

</div>
