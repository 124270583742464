/** Angular Imports */
import { Component, Input, Output, EventEmitter } from '@angular/core';
import { FalseLiteral } from 'typescript';

/**
 * Custom file upload component based on angular material.
 */
@Component({
  selector: 'mifosx-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss']
})
export class FileUploadComponent {
  /** send File data to parent component */
  @Output() uploadFileEvent = new EventEmitter();
  /** Form field flex dimension */
  @Input() flex: any;
  /** Selected file name */
  fileName: File;
  fileSizeError = false;

  constructor() { }

  /**
   * Sets the file name.
   * @param {any} event File input change event.
   */
  onFileSelect($event: any) {
    const size  = $event.target.files[0].size
    if((size/1024/1024) <5){
      this.fileName = $event.target.files[0].name;
      this.fileSizeError = false;
      this.uploadFileEvent.emit($event.target.files[0]);

    }else{
        this.fileSizeError = true;

      }
  }

}
