<div class="container m-b-20" fxLayout="row" fxLayoutAlign="end" fxLayoutGap="20px">
  <div #buttonTreeView>
    <mat-button-toggle-group [formControl]="viewGroup">
      <mat-button-toggle value="listView">
        <fa-icon icon="list"></fa-icon>
      </mat-button-toggle>
      <mat-button-toggle value="treeView">
        <fa-icon icon="sitemap"></fa-icon>
      </mat-button-toggle>
    </mat-button-toggle-group>
  </div>
  <div #buttonAddAccount>
    <button mat-raised-button color="primary" [routerLink]="['create']" *mifosxHasPermission="'CREATE_PRODUCT_LINE'">
      <fa-icon icon="plus"></fa-icon>&nbsp;&nbsp;
      {{"labels.addProductLine" | translate}}
    </button>
  </div>
</div>

<!-- List View -->

<div class="container" [hidden]="viewGroup.value !== 'listView'">

  <div #filter fxLayout="row" fxLayoutGap="20px">
    <mat-form-field fxFlex>
      <mat-label>{{"labels.buttons.filter" | translate}}</mat-label>
      <input matInput (keyup)="applyFilter($event.target.value)">
    </mat-form-field>
  </div>

  <div #accountsTable class="mat-elevation-z8">

    <table mat-table [dataSource]="tableDataSource" matSort>

      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{"labels.inputs.name" | translate}} </th>
        <td mat-cell *matCellDef="let productLine"> {{ productLine.name }} </td>
      </ng-container>

      <ng-container matColumnDef="limitAmount">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{"labels.limitAmount" | translate}} </th>
        <td mat-cell *matCellDef="let productLine"> {{ productLine.limitAmount }} </td>
      </ng-container>

      <ng-container matColumnDef="parent">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{"labels.inputs.parent" | translate}} </th>
        <td mat-cell *matCellDef="let productLine"> {{ productLine?.parent?.name }} </td>
      </ng-container>

      <ng-container matColumnDef="loanProducts">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{"labels.anchors.loanproducts" | translate}} </th>
        <td mat-cell *matCellDef="let productLine">
          <p *ngFor="let product of productLine?.loanProducts">{{product.name}}</p>
        </td>
      </ng-container>

      <ng-container matColumnDef="revolving">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'labels.revolving'|translate}} </th>
        <td mat-cell *matCellDef="let productLine">
          {{ productLine?.revolving ? ('labels.inputs.true' | translate) :'labels.inputs.false' | translate }}
        </td>
      </ng-container>

      <ng-container matColumnDef="unique">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'labels.isUnique'|translate}} </th>
        <td mat-cell *matCellDef="let productLine">
          {{ productLine?.unique ? ('labels.inputs.true' | translate) :'labels.inputs.false' | translate }}
        </td>
      </ng-container>

      <ng-container matColumnDef="processingDays">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{"labels.processingDays" | translate}} </th>
        <td mat-cell *matCellDef="let productLine">
          {{productLine?.processingDays}}
        </td>
      </ng-container>

      <ng-container matColumnDef="validityDays">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{"labels.validityDays" | translate}} </th>
        <td mat-cell *matCellDef="let productLine">
          {{productLine?.validityDays}}
        </td>
      </ng-container>


      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;" [routerLink]="['view', row.id]" class="select-row">
      </tr>

    </table>

    <mat-paginator [pageSizeOptions]="[10, 25, 50, 100]" showFirstLastButtons></mat-paginator>

  </div>

</div>


<!-- Tree View -->

<div class="container" [hidden]="viewGroup.value !== 'treeView'">

  <div class="m-b-20" fxLayout="row" fxLayoutAlign="end" fxLayoutGap="20px">
    <button mat-raised-button (click)="nestedTreeControl.expandAll()">
      {{"labels.buttons.expand.all" | translate}}
    </button>
    <button mat-raised-button (click)="nestedTreeControl.collapseAll()">
      {{"labels.buttons.collapse.all" | translate}}
    </button>
  </div>

  <div fxLayout="row" fxLayoutGap="2%" fxLayout.lt-md="column">
    <div class="mat-elevation-z8" fxFlex>

      <mat-tree [dataSource]="nestedTreeDataSource" [treeControl]="nestedTreeControl" class="pl-account-tree">

        <mat-tree-node *matTreeNodeDef="let node" matTreeNodeToggle>
          <li class="mat-tree-node">
            <button mat-icon-button disabled></button>
            <span (click)="viewLcChildren(node)" [ngClass]="productLine?.id===node?.id && 'pl-selected'">
              {{ node.name }} ({{node.limitAmount}} {{node.currency.code}})
            </span>
          </li>
        </mat-tree-node>

        <mat-nested-tree-node *matTreeNodeDef="let node; when: hasNestedChild">
          <li>
            <div class="mat-tree-node">
              <button mat-icon-button matTreeNodeToggle [attr.aria-label]="'toggle ' + node.name">
                <fa-icon class="mat-icon-rtl-mirror"
                  icon="{{ nestedTreeControl.isExpanded(node) ? 'chevron-down' : 'chevron-right' }}"></fa-icon>
              </button>
              <span (click)="viewLcChildren(node)" [ngClass]="productLine?.id===node?.id && 'pl-selected'">
                {{ node.name }} ({{node.limitAmount}} {{node.currency.code}})
              </span>
            </div>
            <ul [class.pl-account-tree-invisible]="!nestedTreeControl.isExpanded(node)">
              <ng-container matTreeNodeOutlet></ng-container>
            </ul>
          </li>
        </mat-nested-tree-node>

      </mat-tree>

    </div>
    <div fxFlex="30%" *ngIf="productLine">

      <mat-card>

        <mat-card-content>

          <div fxLayout="row wrap" class="content">

            <div fxFlex="50%" class="header">
              {{"labels.inputs.name" | translate}}
            </div>

            <div fxFlex="50%">
              {{ productLine?.name }}
            </div>

            <div fxFlex="50%" class="header">
              {{"labels.inputs.parent" | translate}}
            </div>

            <div fxFlex="50%">
              {{ productLine?.parentName }}
            </div>

            <div fxFlex="50%" class="header" >
              {{"labels.limitAmount" | translate}}
            </div>

            <div fxFlex="50%">
              {{ productLine?.limitAmount }} {{ productLine?.limitAmount && productLine?.currency?.code }}
            </div>

            <div fxFlex="50%" class="header">
              {{"labels.inputs.loanproduct" | translate}}
            </div>

            <div fxFlex="50%" >
              <ul>
                <li *ngFor="let product of productLine?.loanProducts">{{product.name}}</li>
              </ul>
            </div>

            <div fxFlex="50%" class="header">
              {{"labels.validityDays" | translate}}
            </div>

            <div fxFlex="50%">
              {{ productLine?.validityDays }} {{productLine?.validityDays && "labels.heading.days" | translate}}
            </div>
            <div fxFlex="50%" class="header">
              {{"labels.processingDays" | translate}}
            </div>

            <div fxFlex="50%">
              {{ productLine?.processingDays}}  {{productLine?.processingDays && "labels.heading.days" | translate}}
            </div>

            <div fxFlex="50%" class="header">
              {{"labels.revolving" | translate}}
            </div>

            <div fxFlex="50%">
              {{ productLine?.revolving ? ('labels.inputs.true' | translate) :'labels.inputs.false' | translate }}
            </div>

            <div fxFlex="50%" class="header">
              {{"labels.isUnique" | translate}}
            </div>

            <div fxFlex="50%">
              {{ productLine?.unique ? ('labels.inputs.true' | translate) :'labels.inputs.false' | translate }}
            </div>

          <ng-container *ngIf="productLine?.approvalDeadline">
            <div fxFlex="50%" class="header">
              {{"labels.approvalDays" | translate}}
            </div>

            <div fxFlex="50%">
              {{ productLine?.approvalDays}} {{"labels.commons.days" | translate}}
            </div>
          </ng-container>

          </div>

        </mat-card-content>

      </mat-card>

    </div>
  </div>

</div>
