<div class="container">

  <mat-card>

    <form [formGroup]="rejectClientForm" (ngSubmit)="submit()">

      <mat-card-content>

        <div fxLayout="column">

          <mat-form-field (click)="rejectionDatePicker.open()">
            <mat-label> {{ 'labels.inputs.rejectiondate' | translate }}</mat-label>
            <input matInput [min]="minDate" [max]="maxDate" [matDatepicker]="rejectionDatePicker" required formControlName="rejectionDate">
            <mat-datepicker-toggle matSuffix [for]="rejectionDatePicker"></mat-datepicker-toggle>
            <mat-datepicker #rejectionDatePicker></mat-datepicker>
            <mat-error *ngIf="rejectClientForm.controls.rejectionDate.hasError('required')">
               {{ 'labels.inputs.rejectiondate' | translate }} <strong>{{ 'labels.commons.is required' | translate }}</strong>
            </mat-error>
          </mat-form-field>

          <mat-form-field fxFlex="48%">
            <mat-label>{{ 'labels.inputs.rejectionreason' | translate }}</mat-label>
            <mat-select formControlName="rejectionReasonId">
              <mat-option *ngFor="let reason of rejectionData" [value]="reason.id">
                {{ reason.name }}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="rejectClientForm.controls.rejectionReasonId.hasError('required')">
              {{ 'labels.inputs.rejectionreason' | translate }} <strong>{{ 'labels.commons.is required' | translate }}</strong>
            </mat-error>
          </mat-form-field>

        </div>

      </mat-card-content>

      <mat-card-actions fxLayout="row" fxLayout.xs="column" fxLayoutAlign="center" fxLayoutGap="5px">
        <button type="button" mat-raised-button [routerLink]="['../../']">{{ 'labels.buttons.cancel' | translate }}</button>
        <button mat-raised-button color="warn" [disabled]="!rejectClientForm.valid">{{ 'labels.buttons.confirm' | translate }}</button>
      </mat-card-actions>

    </form>

  </mat-card>

</div>
