import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';


@Injectable({
  providedIn: 'root'
})
export class TenantService {

  constructor(private http: HttpClient) { }
  getTenants(): Observable<any> {
    const httpParams = new HttpParams()
    return this.http.get('/tenant', { params: httpParams });
  }

  getMetaBaseDBs(): Observable<any> {
    const httpParams = new HttpParams()
    return this.http.get('/dashboards/database', { params: httpParams });
  }

  updateTenant(tenant:any,tenantName:any) {
    return this.http.put(`/tenant/${tenantName}`,tenant);
  }
  uploadTenantLogo(tenantName: string, image: File) {
    const formData = new FormData();
    formData.append('file', image);
    formData.append('filename', 'file');
    return this.http.post(`/tenant/${tenantName}/images`, formData);
  }
}
