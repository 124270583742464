<div class="container mat-typography" style="padding-bottom: 20px;">

  <div class="mat-elevation-z2 surveyCont">

    <div class="surveySelect" fxLayout="row">

      <mat-label class="nameLabel">{{ 'labels.anchors.surveyName' | translate }}</mat-label>
      <mat-select class="surveyOption" (selectionChange)="onSurveyChange($event)">
        <mat-option *ngFor="let surveyData of allSurveyData" [value]="surveyData">
          {{surveyData.name}}
        </mat-option>
      </mat-select>

    </div>

    <hr>

    <div>

      <ng-container *ngFor="let component of componentGroups">

        <h2 *ngIf="component[0].componentKey"> component[0].componentKey </h2>

        <div fxLayout="column" class="displayQuestions">

          <ng-container *ngFor="let question of component">

            <div fxLayout="row" class="question">

              <mat-label fxFlex="40" class="questionText">{{question.text}}</mat-label>

              <mat-radio-group fxFlex="60" [(ngModel)]="question.answer">

                <mat-radio-button fxLayout="column" class="radio-button" *ngFor="let options of question.responseDatas"
                  style="margin-bottom: 5px; align-items: normal; white-space: normal;" [value]="options">
                  <span class="wrap-mat-radio-label">
                    {{options.text}}
                  </span>
                </mat-radio-button>

              </mat-radio-group>

            </div>

            <div *ngIf="question.answer">{{ 'labels.anchors.yourAnswerIs' | translate }} : {{question.answer.text}}</div>

          </ng-container>

        </div>

      </ng-container>

    </div>

    <button mat-raised-button class="button-cancel" color="danger" (click)="goBack()" >{{ 'labels.buttons.cancel' | translate }}</button>
    <!-- <button mat-raised-button class="button-cancel" color="danger"  [routerLink]="['../']">{{ 'labels.buttons.cancel' | translate }}</button> -->
    <button mat-raised-button class="button-submit" color="primary" (click)=submit()>{{ 'labels.buttons.submit' | translate }}</button>

  </div>

</div>
