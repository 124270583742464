<h1 mat-dialog-title>{{"labels.welcomeTo" | translate}} Bank of Africa</h1>

<mat-dialog-content>

<h3 class="mw600">{{"labels.followingGuideTourWillHelp" | translate: {'name' : appName} }}</h3>


<mat-grid-list cols="3" rowHeight="50px">

      <mat-grid-tile>
		   <button mat-raised-button color="primary" [mat-dialog-close]="{ show: 1 }">{{"labels.homeScreenTour" | translate}}</button>
	  </mat-grid-tile>
	  <mat-grid-tile>
		   <button mat-raised-button color="primary" [mat-dialog-close]="{ show: 2 }">{{"labels.setupOrganization" | translate}}</button>
	  </mat-grid-tile>
	  <mat-grid-tile>
		   <button mat-raised-button color="primary" [mat-dialog-close]="{ show: 3 }">{{"labels.setupSystem" | translate}}</button>
	  </mat-grid-tile>
	  <mat-grid-tile>
		   <button mat-raised-button color="primary" [mat-dialog-close]="{ show: 4 }">{{"labels.setupAccounting" | translate}}</button>
	  </mat-grid-tile>
	  <mat-grid-tile>
		   <button mat-raised-button color="primary" [mat-dialog-close]="{ show: 5 }">{{"labels.setupProducts" | translate}}</button>
	  </mat-grid-tile>
	  <mat-grid-tile>
		   <button mat-raised-button color="primary" [mat-dialog-close]="{ show: 6 }">{{"labels.setupFundsReports" | translate}}</button>
	  </mat-grid-tile>

</mat-grid-list>

<h3>{{"labels.progressBar" | translate}} : 0%</h3>

<mat-progress-bar mode="determinate" value="0"></mat-progress-bar>

</mat-dialog-content>

<mat-dialog-actions>
    <button mat-raised-button color="warn" [mat-dialog-close]="{ show: 0 }">{{"labels.closeConfigW" | translate}}</button>
</mat-dialog-actions>
