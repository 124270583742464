<div class="container">

  <mat-card>

    <form [formGroup]="activateCenterForm" (ngSubmit)="submit()">

      <mat-card-content>

          <mat-form-field fxFlex (click)="activationDatePicker.open()">
            <mat-label>{{"labels.activatedOnDate" | translate}}</mat-label>
            <input matInput [min]="minDate" [max]="maxDate" [matDatepicker]="activationDatePicker" required formControlName="activationDate">
            <mat-datepicker-toggle matSuffix [for]="activationDatePicker"></mat-datepicker-toggle>
            <mat-datepicker #activationDatePicker></mat-datepicker>
            <mat-error *ngIf="activateCenterForm.controls.activationDate.hasError('required')">
              {{"labels.activatedOnDate" | translate}} <strong>{{"labels.commons.is required" | translate}}</strong>
            </mat-error>
          </mat-form-field>

      </mat-card-content>

      <mat-card-actions fxLayout="row" fxLayout.xs="column" fxLayoutAlign="center" fxLayoutGap="5px">
        <button type="button" mat-raised-button [routerLink]="['../../']">{{"labels.buttons.cancel" | translate}}</button>
        <button mat-raised-button color="accent" [disabled]="!activateCenterForm.valid">{{"labels.buttons.confirm" | translate}}</button>
      </mat-card-actions>

    </form>

  </mat-card>

</div>
