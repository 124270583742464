<div fxLayout="row" fxLayoutAlign="end" fxLayoutGap="2%" fxLayout.lt-md="column" class="container m-b-20">
  <button mat-raised-button color="primary" [routerLink]="['edit']" *mifosxHasPermission="'UPDATE_SHAREPRODUCT'">
    <fa-icon icon="edit"></fa-icon>&nbsp;&nbsp;
    {{"labels.buttons.edit" | translate}}
  </button>
  <button mat-raised-button color="primary" [routerLink]="['dividends']" *mifosxHasPermission="'UPDATE_SHAREPRODUCT'">
    <fa-icon icon="hand-holding-usd"></fa-icon>&nbsp;&nbsp;
    {{"labels.heading.dividends" | translate}}
  </button>
</div>

<div class="container">

  <mat-card>

    <mat-card-content>

      <div fxLayout="row wrap" fxLayout.lt-md="column">

        <h2 class="mat-h2" fxFlexFill>{{ shareProduct.name }}</h2>

        <mat-divider [inset]="true"></mat-divider>

        <h3 class="mat-h3" fxFlexFill>{{"labels.buttons.details" | translate}}</h3>

        <mat-divider [inset]="true"></mat-divider>

        <div fxFlexFill>
          <span fxFlex="40%">{{"labels.inputs.product.shortname" | translate}}:</span>
          <span fxFlex="60%">{{ shareProduct.shortName }}</span>
        </div>

        <div fxFlexFill *ngIf="shareProduct.description">
          <span fxFlex="40%">{{"labels.heading.description" | translate}}:</span>
          <span fxFlex="60%">{{ shareProduct.description }}</span>
        </div>

        <h3 class="mat-h3" fxFlexFill>{{"labels.inputs.currency" | translate}}</h3>

        <mat-divider [inset]="true"></mat-divider>

        <div fxFlexFill>
          <span fxFlex="40%">{{"labels.heading.currency" | translate}}:</span>
          <span fxFlex="60%">{{ shareProduct.currency.name }}</span>
        </div>

        <div fxFlexFill>
          <span fxFlex="40%">{{"labels.inputs.decimalplace" | translate}}:</span>
          <span fxFlex="60%">{{ shareProduct.currency.decimalPlaces }}</span>
        </div>

        <div fxFlexFill>
          <span fxFlex="40%">{{"labels.heading.currencyinmultiplesof" | translate}}:</span>
          <span fxFlex="60%">{{ shareProduct.currency.inMultiplesOf }}</span>
        </div>

        <h3 class="mat-h3" fxFlexFill>{{"labels.terms" | translate}}</h3>

        <mat-divider [inset]="true"></mat-divider>

        <div fxFlexFill>
          <span fxFlex="40%">{{"labels.inputs.totalnumberofshares" | translate }}</span>
          <span fxFlex="60%">{{ shareProduct.totalShares }}</span>
        </div>

        <div fxFlexFill>
          <span fxFlex="40%">{{"labels.inputs.totalsharestobeissue" | translate }}</span>
          <span fxFlex="60%">{{ shareProduct.totalSharesIssued }}</span>
        </div>

        <div fxFlexFill>
          <span fxFlex="40%">{{"labels.heading.sharenominalprice" | translate }}</span>
          <span fxFlex="60%">{{ shareProduct.unitPrice }}</span>
        </div>

        <div fxFlexFill>
          <span fxFlex="40%">{{"labels.inputs.capitalvalue" | translate }}:</span>
          <span fxFlex="60%">{{ shareProduct.shareCapital }}</span>
        </div>

        <h3 class="mat-h3" fxFlexFill>{{"labels.menus.Settings" | translate}}</h3>

        <mat-divider [inset]="true"></mat-divider>

        <div fxFlexFill>
          <span fxFlex="40%">{{"labels.inputs.sharesperclient" | translate }}:</span>
          <span fxFlex="60%">{{ shareProduct.nominalShares + ' (Min: ' + (shareProduct.minimumShares ? shareProduct.minimumShares : shareProduct.nominalShares) + ', Max: ' + (shareProduct.maximumShares ? shareProduct.maximumShares : shareProduct.nominalShares) + ')' }}</span>
        </div>

        <div fxFlexFill *ngIf="shareProduct.minimumActivePeriod">
          <span fxFlex="40%">{{"labels.inputs.minimumactiveperiodt" | translate }}:</span>
          <span fxFlex="60%">{{ shareProduct.minimumActivePeriod + ' ' + shareProduct.minimumActivePeriodForDividendsTypeEnum.value }}</span>
        </div>

        <div fxFlexFill *ngIf="shareProduct.lockinPeriod">
          <span fxFlex="40%">{{"labels.heading.lockinPeriodFrequency" | translate}}:</span>
          <span fxFlex="60%">{{ shareProduct.lockinPeriod + ' ' + shareProduct.lockPeriodTypeEnum.value }}</span>
        </div>

        <div fxFlexFill>
          <span fxFlex="40%">{{"labels.inputs.allowdividendsforinactiveclients" | translate }}:</span>
          <span fxFlex="60%">{{ shareProduct.allowDividendCalculationForInactiveClients ? 'Yes' : 'No' }}</span>
        </div>

        <div fxFlexFill *ngIf="shareProduct.marketPrice?.length" fxLayout="row wrap" fxLayout.lt-md="column">

          <h3 class="mat-h3" fxFlexFill>{{"labels.inputs.marketpriceperiods" | translate }}:</h3>

          <mat-divider [inset]="true"></mat-divider>

          <table fxFlexFill class="mat-elevation-z1" mat-table [dataSource]="shareProduct.marketPrice">

            <ng-container matColumnDef="fromDate">
              <th mat-header-cell *matHeaderCellDef>{{"labels.heading.fromdate" | translate }}</th>
              <td mat-cell *matCellDef="let marketPrice">
                {{ marketPrice.fromDate  | dateFormat }}
              </td>
            </ng-container>

            <ng-container matColumnDef="shareValue">
              <th mat-header-cell *matHeaderCellDef>{{"labels.heading.sharenominalprice" | translate }}</th>
              <td mat-cell *matCellDef="let marketPrice">
                {{ marketPrice.shareValue }}
              </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="marketPriceDisplayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: marketPriceDisplayedColumns;"></tr>

          </table>

        </div>

        <div fxFlexFill *ngIf="shareProduct.charges.length" fxLayout="row wrap" fxLayout.lt-md="column">

          <h3 class="mat-h3" fxFlexFill>{{"labels.anchors.charges" | translate}}</h3>

          <mat-divider [inset]="true"></mat-divider>

          <table fxFlexFill class="mat-elevation-z1" mat-table [dataSource]="shareProduct.charges">

            <ng-container matColumnDef="name">
              <th mat-header-cell *matHeaderCellDef>{{"labels.inputs.name" | translate}}</th>
              <td mat-cell *matCellDef="let charge">
                {{ charge.name + ', ' + charge.currency.displaySymbol }}
              </td>
            </ng-container>

            <ng-container matColumnDef="chargeCalculationType">
              <th mat-header-cell *matHeaderCellDef>{{"labels.inputs.type" | translate}}</th>
              <td mat-cell *matCellDef="let charge">
                {{ charge.chargeCalculationType.value }}
              </td>
            </ng-container>

            <ng-container matColumnDef="amount">
              <th mat-header-cell *matHeaderCellDef>{{"labels.heading.amount" | translate}}</th>
              <td mat-cell *matCellDef="let charge">
                {{ charge.amount  }}
              </td>
            </ng-container>

            <ng-container matColumnDef="chargeTimeType">
              <th mat-header-cell *matHeaderCellDef>{{"labels.heading.collectedon" | translate}}</th>
              <td mat-cell *matCellDef="let charge">
                {{ charge.chargeTimeType.value }}
              </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="chargesDisplayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: chargesDisplayedColumns;"></tr>

          </table>

        </div>

        <h3 class="mat-h3" fxFlexFill>{{"labels.menus.Accounting" | translate}}</h3>

        <mat-divider [inset]="true"></mat-divider>

        <div fxFlexFill>
          <span fxFlex="40%">{{"labels.inputs.type" | translate}}:</span>
          <span fxFlex="60%">{{ shareProduct.accountingRule.value }}</span>
        </div>

        <div fxFlexFill *ngIf="shareProduct.accountingRule.id === 2" fxLayout="row wrap" fxLayout.lt-md="column">

          <h4 fxFlexFill class="mat-h4">{{"labels.assets" | translate}}</h4>

          <div fxFlexFill>
            <span fxFlex="40%">{{"labels.inputs.sharereference" | translate }}:</span>
            <span fxFlex="60%">{{ shareProduct.accountingMappings.shareReferenceId.name }}</span>
          </div>

          <h4 fxFlexFill class="mat-h4">{{"labels.heading.liabilities" | translate}} </h4>

          <div fxFlexFill>
            <span fxFlex="40%">{{"labels.inputs.sharecontrol" | translate }}:</span>
            <span fxFlex="60%">{{ shareProduct.accountingMappings.shareSuspenseId.name }}</span>
          </div>

          <h4 fxFlexFill class="mat-h4">{{"labels.heading.shareequity" | translate }}</h4>

          <div fxFlexFill>
            <span fxFlex="40%">{{"labels.inputs.equity" | translate }}:</span>
            <span fxFlex="60%">{{ shareProduct.accountingMappings.shareEquityId.name }}</span>
          </div>

          <h4 fxFlexFill class="mat-h4">{{"labels.heading.income" | translate}} </h4>

          <div fxFlexFill>
            <span fxFlex="40%">{{"labels.heading.incomefromfees" | translate}}:</span>
            <span fxFlex="60%">{{ shareProduct.accountingMappings.incomeFromFeeAccountId.name }}</span>
          </div>

        </div>

      </div>

    </mat-card-content>

  </mat-card>

</div>
