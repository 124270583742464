<h1 mat-dialog-title>{{ 'labels.heading.editNote' | translate }}</h1>
<div>
  <form #formRef="ngForm" [formGroup]="noteForm">
    <mat-form-field>
      <textarea formControlName="note" matInput [placeholder]="('labels.inputs.writeANote'| translate)+'...'"></textarea>
    </mat-form-field>
    <mat-dialog-actions align="end">
      <button mat-raised-button mat-dialog-close>{{"labels.buttons.cancel" | translate}}</button>
      <button mat-raised-button color="primary" [disabled]="noteForm.pristine"
        [mat-dialog-close]="{ editForm: noteForm }">{{ 'labels.buttons.confirm' | translate }}</button>
    </mat-dialog-actions>
  </form>
</div>
