export const appTableData: { displayValue: string, value: string }[] = [
    { displayValue: 'Client', value: 'm_client' },
    { displayValue: 'Group', value: 'm_group' },
    { displayValue: 'Loan', value: 'm_loan' },
    { displayValue: 'Center', value: 'm_center' },
    { displayValue: 'Office', value: 'm_office' },
    { displayValue: 'App user', value: 'm_appuser' },
    { displayValue: 'Saving Account', value: 'm_savings_account' },
    { displayValue: 'Product Loan', value: 'm_product_loan' },
    { displayValue: 'Savings Product', value: 'm_savings_product' }
  ];

export const entitySubTypeData: { displayValue: string, value: string }[] = [
    { displayValue: 'Person', value: 'Person' },
    { displayValue: 'Entity', value: 'Entity' },
  ];
