<form [formGroup]="smsCampaignDetailsForm">

  <div fxLayout="row wrap" fxLayoutGap="2%" fxLayout.lt-md="column" fxLayoutAlign.gt-sm="start center">

    <mat-form-field fxFlex="48%">
      <mat-label>{{"labels.inputs.campaignName" | translate}}</mat-label>
      <input matInput formControlName="campaignName" required>
      <mat-error>
        {{"labels.inputs.campaignName" | translate}} <strong>{{"labels.commons.is required" | translate}}</strong>
      </mat-error>
    </mat-form-field>

    <mat-form-field fxFlex="48%"
      *ngIf="!smsCampaignDetailsForm.controls.isNotification.value">
      <mat-label>{{"labels.inputs.smsprovider" | translate}}</mat-label>
      <mat-select formControlName="providerId">
        <mat-option *ngFor="let provider of smsProviders" [value]="provider.id">
          {{ provider.value ? provider.value : provider.providerName }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field fxFlex="48%">
      <mat-label>{{"labels.heading.triggertype" | translate}}</mat-label>
      <mat-select formControlName="triggerType" required>
        <mat-option *ngFor="let triggerType of triggerTypes" [value]="triggerType.id">
          {{ triggerType.value }}
        </mat-option>
      </mat-select>
      <mat-error>
      {{"labels.heading.triggertype" | translate}} <strong>{{"labels.commons.is required" | translate}}</strong>
      </mat-error>
    </mat-form-field>

    <mat-checkbox labelPosition="before" formControlName="isNotification" fxFlex="48%">
      {{"labels.inputs.isnotification" | translate}}?
    </mat-checkbox>

    <mat-form-field fxFlex="48%"
      *ngIf="smsCampaignDetailsForm.contains('recurrenceStartDate')" (click)="recurrenceStartDatePicker.open()">
      <mat-label>{{"labels.inputs.scheduledate" | translate}}</mat-label>
      <input matInput [min]="minDate" [max]="maxDate" [matDatepicker]="recurrenceStartDatePicker"
        formControlName="recurrenceStartDate" required>
      <mat-datepicker-toggle matSuffix [for]="recurrenceStartDatePicker"></mat-datepicker-toggle>
      <mat-datepicker #recurrenceStartDatePicker></mat-datepicker>
      <mat-error>
        {{"labels.inputs.scheduledate" | translate}} <strong>{{"labels.commons.is required" | translate}}</strong>
      </mat-error>
    </mat-form-field>

    <mat-form-field fxFlex="48%"
      *ngIf="smsCampaignDetailsForm.contains('frequency')">
      <mat-label>{{"labels.menus.repeats" | translate}}</mat-label>
      <mat-select formControlName="frequency" required>
        <mat-option [value]="1">{{"labels.daily" | translate}}</mat-option>
        <mat-option [value]="2">{{"labels.weekly" | translate}}</mat-option>
        <mat-option [value]="3">{{"labels.monthly" | translate}}</mat-option>
        <mat-option [value]="4">{{"labels.yearly" | translate}}</mat-option>
      </mat-select>
      <mat-error>
        {{"labels.repetitionFrequency" | translate}} <strong>{{"labels.commons.is required" | translate}}</strong>
      </mat-error>
    </mat-form-field>

    <mat-form-field fxFlex="48%"
      *ngIf="smsCampaignDetailsForm.contains('interval')">
      <mat-label>{{"labels.repetitionInterval" | translate}}</mat-label>
      <mat-select formControlName="interval" required>
        <mat-option *ngFor="let interval of repetitionIntervals" [value]="interval">
          {{ interval }}
        </mat-option>
      </mat-select>
      <mat-error>
        {{"labels.repetitionInterval" | translate}} <strong>{{"labels.commons.is required" | translate}}</strong>
      </mat-error>
    </mat-form-field>

    <mat-form-field fxFlex="48%"
      *ngIf="smsCampaignDetailsForm.contains('repeatsOnDay')">
      <mat-label>{{"labels.repeatsDay" | translate}}</mat-label>
      <mat-select formControlName="repeatsOnDay" required>
        <mat-option value="1">{{"labels.monday" | translate}}</mat-option>
        <mat-option value="2">{{"labels.tuesday" | translate}}</mat-option>
        <mat-option value="3">{{"labels.wednesday" | translate}}</mat-option>
        <mat-option value="4">{{"labels.thursday" | translate}}</mat-option>
        <mat-option value="5">{{"labels.friday" | translate}}</mat-option>
        <mat-option value="6">{{"labels.saturday" | translate}}</mat-option>
        <mat-option value="7">{{"labels.sunday" | translate}}</mat-option>
      </mat-select>
      <mat-error>
        {{"labels.atleast" | translate}} <strong>{{"labels.one" | translate}}</strong> {{"labels.dayMustBeSelected" | translate}}
      </mat-error>
    </mat-form-field>

    <mat-form-field fxFlex="48%">
      <mat-label>{{"labels.inputs.businessRule" | translate}}</mat-label>
      <mat-select formControlName="runReportId" required>
        <mat-option *ngFor="let rule of businessRules" [value]="rule.reportId">
          {{ rule.reportName }}
        </mat-option>
      </mat-select>
      <mat-error>
        {{"labels.inputs.businessRule" | translate}} <strong>{{"labels.commons.is required" | translate}}</strong>
      </mat-error>
    </mat-form-field>

  </div>

  <div fxLayout="row" class="margin-t" fxLayout.xs="column" fxLayoutAlign="center" fxLayoutGap="2%"
    *ngIf="!smsCampaignDetailsForm.controls.runReportId.value">
    <button mat-raised-button matStepperPrevious disabled>
      <fa-icon icon="arrow-left"></fa-icon>&nbsp;&nbsp;
      {{"labels.heading.prev" | translate}}
    </button>
    <button mat-raised-button matStepperNext>
      {{"labels.heading.next" | translate}}&nbsp;&nbsp;
      <fa-icon icon="arrow-right"></fa-icon>
    </button>
  </div>

  <mifosx-business-rule-parameters
    *ngIf="smsCampaignDetailsForm.controls.runReportId.value"
    [paramData]="paramData"
    (templateParameters)="passParameters($event)"
  >
  </mifosx-business-rule-parameters>

</form>
