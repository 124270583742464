<mat-card-header fxLayout="row" fxLayoutGap="5%" class="header">
  <fa-icon class="main-icon" icon="user" size="3x"></fa-icon>
  <mat-card-title-group>
    <div class="mat-typography">
      <mat-card-title>
        <h2>
          {{ employeeData.displayName }}
          <span [className]="employeeData.isActive === true ? 'true' : 'false'">
            <fa-icon matTooltip="{{ employeeData.isActive === true ? 'Active' : 'Inactive' }}" matTooltipPosition="right" icon="circle" size="lg"></fa-icon>
          </span>
        </h2>
      </mat-card-title>
      <mat-card-subtitle>
        <p>
          {{"labels.associatedOfficer" | translate}}: {{ employeeData.officeName }}
        </p>
      </mat-card-subtitle>
    </div>
  </mat-card-title-group>
</mat-card-header>

<mat-card-content>
  <div fxLayout="row wrap" class="content">

    <div fxFlex="50%" class="mat-body-strong">
      {{"labels.inputs.joinedon" | translate}}
    </div>

    <div fxFlex="50%">
      {{ employeeData.joiningDate  | dateFormat }}
    </div>

    <div fxFlex="50%" class="mat-body-strong">
      {{"labels.heading.loanofficer" | translate}}
    </div>

    <div fxFlex="50%">
      {{ employeeData.isLoanOfficer ? 'Yes' : 'No' }}
    </div>

    <div fxFlex="50%" class="mat-body-strong" *ngIf="employeeData.mobileNo">
      {{"labels.inputs.mobileNumber" | translate}}
    </div>

    <div fxFlex="50%" *ngIf="employeeData.mobileNo">
      {{ employeeData.mobileNo }}
    </div>

    <div fxFlex="50%" class="mat-body-strong">
      {{"labels.numberCenters" | translate}}
    </div>

    <div fxFlex="50%">
      {{ centerData ? centerData.length : '' }}
    </div>

  </div>
</mat-card-content>
