<mat-card-header fxLayout="row" fxLayoutGap="5%" class="header">
  <fa-icon class="main-icon" icon="building" size="3x"></fa-icon>
  <mat-card-title-group>
    <div class="mat-typography">
      <mat-card-title>
        <h2>
          {{ officeData.name }}
        </h2>
      </mat-card-title>
      <mat-card-subtitle>
        <p>
          {{ officeData.externalId ? 'External ID: ' + officeData.externalId : '' }}
        </p>
      </mat-card-subtitle>
    </div>
  </mat-card-title-group>
</mat-card-header>

<mat-card-content>
  <div fxLayout="row wrap" class="content">

    <div fxFlex="50%" class="mat-body-strong">
      {{"labels.inputs.openedon" | translate}}
    </div>

    <div fxFlex="50%">
      {{ officeData.openingDate  | dateFormat }}
    </div>

    <div fxFlex="50%" class="mat-body-strong" *ngIf="officeData.parentName">
      {{"labels.parentofficeData" | translate}}
    </div>

    <div fxFlex="50%" *ngIf="officeData.parentName">
      {{ officeData.parentName }}
    </div>

    <div fxFlex="50%" class="mat-body-strong">
      {{"labels.numberStaff" | translate}}
    </div>

    <div fxFlex="50%">
      {{ employeeData ? employeeData.length : '' }}
    </div>

  </div>
</mat-card-content>
