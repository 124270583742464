<mifosx-card *ngFor="let product of loanProducts" [routerLink]="['../create']" [queryParams]="{productId:product.id}">
  <!-- <div [routerLink]="['../create']" [queryParams]="{productId:product.id}"> -->
    <h2>{{product.name}}</h2>
    <table class="product">
      <tr>
        <td>{{"labels.maxPrincipal" | translate}}:</td>
        <td>{{product?.maxPrincipal}} {{product?.currency?.code}}</td>
      </tr>
      <tr>
        <td>{{"labels.minPrincipal" | translate}}:</td>
        <td>{{product?.minPrincipal}} {{product?.currency?.code}}</td>
      </tr>
      <tr>
        <td>{{"labels.maxNumberOfRepayments" | translate}}:</td>
        <td>{{product?.maxNumberOfRepayments}}</td>
      </tr>
      <tr>
        <td>{{"labels.minNumberOfRepayments" | translate}}:</td>
        <td>{{product?.minNumberOfRepayments}}</td>
      </tr>
    </table>
  <!-- </div> -->
</mifosx-card>
