<div class="tab-container mat-typography">
  <mat-card class="center-card">
    <mat-card-header fxLayout="column" class="mat-toolbar mat-primary header">
      <mat-card-title-group class="header-title-group">
        <div class="profile-image-container">
          <div>
            <img mat-card-md-image class="profile-image" src='assets/images/center_placeholder.png'>
          </div>
        </div>
        <div class="mat-typography center-card-title">
          <mat-card-title>
            <h3>
              <i class="fa fa-stop" matTooltip="{{ centerViewData.status.value }}"
                [ngClass]="centerViewData.status.code |statusLookup"></i>
              {{"labels.centerName" | translate}} : {{centerViewData.name}}
            </h3>
          </mat-card-title>
          <mat-card-subtitle>
            <div fxLayout="row" fxLayout.lt-md="column">
              <div fxFlex="50%">
                <p>
                  {{"labels.inputs.account" | translate}} #:{{centerViewData.accountNo}} <br />
                  {{"labels.menus.office" | translate}}: {{centerViewData.officeName}} <br />
                  <span *ngIf="centerViewData.externalId">
                    {{"labels.heading.externalid" | translate}}: {{centerViewData.externalId}} <br />
                  </span>
                  <span *ngIf="centerViewData.staffName">
                    {{"labels.inputs.staff" | translate}}: {{centerViewData.staffName}} <br />
                  </span>
                  {{"labels.inputs.activationdate" | translate}} :
                  {{(centerViewData.activationDate)?(centerViewData.activationDate | dateFormat) :'Not Activated'}}<br />
                </p>
              </div>
            </div>
          </mat-card-subtitle>
        </div>

        <div class="center-meeting" fxLayoutAlign="start start">
          <div *ngIf="centerViewData.collectionMeetingCalendar; else unassigned">
            <p>
              {{"labels.heading.nextmeetingon" | translate}}: {{centerViewData.collectionMeetingCalendar?.nextTenRecurringDates[0] | dateFormat}}
              <span *ngIf="editMeeting">
                <i class="fa fa-edit" (click)="doAction('Edit Meeting')"
                  *mifosxHasPermission="'UPDATE_MEETING'"></i><br />
              </span>
              {{"labels.heading.meetingfrequency" | translate}}: {{centerViewData.collectionMeetingCalendar?.frequency.value | lowercase}}
            </p>
          </div>
          <ng-template #unassigned>
            <div>
              <p>
                {{"labels.heading.nextmeetingon" | translate}}: {{"labels.heading.unassigned" | translate}}
                <i class="fa fa-calendar"></i><br />
                {{"labels.heading.meetingfrequency" | translate}}: N/A
              </p>
            </div>
          </ng-template>
        </div>

      </mat-card-title-group>
    </mat-card-header>
  </mat-card>
  <div *ngIf="centerViewData.status.value=='Closed'">
    <h3 class="closedCenter">{{"labels.heading.centerclosed" | translate}}</h3>
  </div>
  <h3>{{"labels.summaryDetails" | translate}}</h3>
  <div fxLayout="row" fxLayoutGap="32px" class="summary-details-container">
    <p>
      {{"labels.activeClients" | translate}}: {{centerSummaryData.activeClients}}<br />
      {{"labels.activeGroupLoans" | translate}}: {{centerSummaryData.activeGroupLoans}}<br />
      {{"labels.activeClientLoans" | translate}}: {{centerSummaryData.activeClientLoans}}<br />
      {{"labels.activeOverdueGroupLoans" | translate}}: {{centerSummaryData.overdueGroupLoans}}<br />
    </p>
    <p>
      {{"labels.activeGroupBorrowers" | translate}}: {{centerSummaryData.activeGroupBorrowers}}<br />
      {{"labels.activeClientBorrowers" | translate}}: {{centerSummaryData.activeClientBorrowers}}<br />
      {{"labels.activeOverdueClientLoans" | translate}}: {{centerSummaryData.overdueClientLoans}}<br />
    </p>
  </div>

  <!-- Groups -->
  <ng-container *ngIf="groupResourceData.length > 0">

    <h3>{{"labels.heading.groups" | translate}}</h3>

    <table mat-table [dataSource]="groupResourceData" matSort>

      <ng-container matColumnDef="Account No">
        <th mat-header-cell *matHeaderCellDef> {{"labels.inputs.accno" | translate}} </th>
        <td mat-cell *matCellDef="let element"> <i class="fa fa-stop" matTooltip="{{ element.status.value }}"
            [ngClass]="element.status.code|statusLookup"></i>
          {{element.accountNo}} </td>
      </ng-container>

      <ng-container matColumnDef="Group Name">
        <th mat-header-cell *matHeaderCellDef> {{"labels.inputs.groupName" | translate}} </th>
        <td mat-cell *matCellDef="let element"> {{element.name}} </td>
      </ng-container>

      <ng-container matColumnDef="Office Name">
        <th mat-header-cell *matHeaderCellDef> {{"labels.heading.officeName" | translate}} </th>
        <td mat-cell *matCellDef="let element"> {{element.officeName}} </td>
      </ng-container>

      <ng-container matColumnDef="Submitted On">
        <th mat-header-cell *matHeaderCellDef> {{"labels.inputs.submittedOnDate" | translate}} </th>
        <td mat-cell *matCellDef="let element"> {{element.timeline.submittedOnDate  | dateFormat}} </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="groupsColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: groupsColumns;" [routerLink]="['/groups', row.id, 'general']"></tr>

    </table>

  </ng-container>

  <!-- savings overview table -->
  <ng-container *ngIf="!(savingsAccountData === undefined)">

    <ng-container *ngIf="savingsAccountData.length > 0">

      <h3>{{"labels.heading.savingsaccoverview" | translate}}</h3>

      <table mat-table [dataSource]="savingsAccountData" matSort>

        <ng-container matColumnDef="Account No">
          <th mat-header-cell *matHeaderCellDef> {{"labels.accountNo" | translate}} </th>
          <td mat-cell *matCellDef="let element"> <i class="fa fa-stop" matTooltip="{{ element.status.value }}"
              [ngClass]="element.status.code|statusLookup"></i>
            {{element.accountNo}} </td>
        </ng-container>

        <ng-container matColumnDef="Products">
          <th mat-header-cell *matHeaderCellDef> {{"labels.menus.Products" | translate}} </th>
          <td mat-cell *matCellDef="let element"> {{element.productName}} </td>
        </ng-container>

        <ng-container matColumnDef="Balance">
          <th mat-header-cell *matHeaderCellDef> {{"labels.heading.balance" | translate}}</th>
          <td mat-cell *matCellDef="let element"> {{element.accountBalance}} </td>
        </ng-container>

        <ng-container matColumnDef="Actions">
          <th mat-header-cell *matHeaderCellDef> {{"labels.heading.actions" | translate}} </th>
          <td mat-cell *matCellDef="let element">
            <ng-container *ngIf="element.status.active">
              <button class="account-action-button" mat-raised-button color="primary" *ngIf="element.depositType.id == '100'"
                (click)="routeEdit($event)" [routerLink]="['../','savings-accounts', element.id, 'actions', 'Deposit']">
                <i class="fa fa-arrow-right" [matTooltip]="'labels.buttons.deposit'| translate"></i>
              </button>
              <button class="account-action-button" mat-raised-button color="primary" *ngIf="element.depositType.id == '300'"
                (click)="routeEdit($event)" [routerLink]="['../','savings-accounts', element.id, 'actions', 'Deposit']">
                <i class="fa fa-arrow-right" [matTooltip]="'labels.buttons.deposit'| translate"></i>
              </button>
              <button class="account-action-button" mat-raised-button color="primary" *ngIf="element.depositType.id == '100'"
                (click)="routeEdit($event)" [routerLink]="['../','savings-accounts', element.id, 'actions', 'Withdrawal']">
                <i class="fa fa-arrow-left" [matTooltip]="'labels.anchors.withdraw'| translate"></i>
              </button>
              <button class="account-action-button" mat-raised-button color="primary" *ngIf="element.depositType.id == '300'"
                (click)="routeEdit($event)" [routerLink]="['../','savings-accounts', element.id, 'actions', 'Withdrawal']">
                <i class="fa fa-arrow-left" [matTooltip]="'labels.anchors.withdraw'| translate"></i>
              </button>
            </ng-container>
            <ng-container *ngIf="element.status.submittedAndPendingApproval">
              <button class="account-action-button" mat-raised-button color="primary"
                (click)="routeEdit($event)" [routerLink]="['../','savings-accounts', element.id, 'actions', 'Approve']">
                <i class="fa fa-check" [matTooltip]="'labels.anchors.approve'| translate"></i>
              </button>
            </ng-container>
            <ng-container *ngIf="!element.status.submittedAndPendingApproval && !element.status.active">
              <button class="account-action-button" mat-raised-button color="primary"
                (click)="routeEdit($event)" [routerLink]="['../','savings-accounts', element.id, 'actions', 'Undo Approval']">
                <i class="fa fa-undo" [matTooltip]="'labels.anchors.undoapproval'| translate"></i>
              </button>
              <button class="account-action-button" mat-raised-button color="primary"
                (click)="routeEdit($event)" [routerLink]="['../','savings-accounts', element.id, 'actions', 'Activate']">
                <i class="fa fa-power-off" [matTooltip]="'labels.anchors.activate'| translate"></i>
              </button>
            </ng-container>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="savingsAccountColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: savingsAccountColumns;" [routerLink]="['../', 'savings-accounts', row.id, 'transactions']"></tr>
      </table>

    </ng-container>

  </ng-container>

</div>
