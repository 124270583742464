<h1 mat-dialog-title>{{'labels.heading.errorlog' | translate}}</h1>

<div mat-dialog-content>
    <p *ngIf="!showAsCode"> {{ data }}</p>
    <span *ngIf="showAsCode" [innerHTML]="data"></span>
</div>

<mat-dialog-actions align="left">
    <button mat-raised-button mat-dialog-close>{{'labels.buttons.cancel' | translate}}</button>
</mat-dialog-actions>
