<div class="container">

  <mat-card>

    <form [formGroup]="passwordPreferencesForm" (ngSubmit)="submit()">

      <mat-card-content>

        <mat-radio-group fxLayout="column" formControlName="validationPolicyId" fxLayoutGap="10px">
          <mat-radio-button *ngFor="let passwordPreference of passwordPreferencesData" [value]="passwordPreference.id">
            <span class="description-wrap">{{ passwordPreference.description }}</span>
          </mat-radio-button>
        </mat-radio-group>

      </mat-card-content>

      <mat-card-actions fxLayout="row" fxLayout.xs="column" fxLayoutAlign="center" fxLayoutGap="5px">
        <button type="button" mat-raised-button [routerLink]="['../']">{{'labels.buttons.cancel' | translate}}</button>
        <button mat-raised-button color="primary" [disabled]="passwordPreferencesForm.pristine"
          *mifosxHasPermission="'UPDATE_PASSWORD_VALIDATION_POLICY'">{{'labels.buttons.submit' | translate}}</button>
      </mat-card-actions>

    </form>

  </mat-card>

</div>