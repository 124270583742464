<div class="container">

  <mat-card>

    <form [formGroup]="acceptClientTransferForm" (ngSubmit)="submit()">

      <mat-card-content fxLayout="column">

        <mat-form-field (click)="transferDatePicker.open()">
          <mat-label>{{ 'labels.inputs.transfer.date' | translate }}</mat-label>
          <input matInput [matDatepicker]="transferDatePicker" required formControlName="transferDate">
          <mat-datepicker-toggle matSuffix [for]="transferDatePicker"></mat-datepicker-toggle>
          <mat-datepicker #transferDatePicker></mat-datepicker>
        </mat-form-field>

        <mat-form-field fxFlex>
          <mat-label>{{ 'labels.inputs.note' | translate }}</mat-label>
          <textarea matInput formControlName="note"></textarea>
        </mat-form-field>

      </mat-card-content>

      <mat-card-actions fxLayout="row" fxLayout.xs="column" fxLayoutAlign="center" fxLayoutGap="5px">
        <button type="button" mat-raised-button [routerLink]="['../../']">{{ 'labels.buttons.cancel' | translate }}</button>
        <button mat-raised-button color="primary" [disabled]="!acceptClientTransferForm.valid">{{ 'labels.buttons.confirm' | translate }}</button>
      </mat-card-actions>

    </form>

  </mat-card>

</div>
