<form [formGroup]="loanProductDetailsForm">

  <div fxLayout="row wrap" fxLayoutGap="2%" fxLayout.lt-md="column" fxLayoutAlign.gt-sm="start center">

    <mat-form-field fxFlex="48%">
      <mat-label>{{"labels.inputs.productname" | translate}}</mat-label>
      <input matInput formControlName="name" required>
      <mat-error>
        {{"labels.inputs.productname" | translate}} <strong>{{"labels.commons.is required" | translate}}</strong>
      </mat-error>
    </mat-form-field>

    <mat-form-field fxFlex="48%">
      <mat-label>{{"labels.inputs.product.shortname" | translate}}</mat-label>
      <input matInput formControlName="shortName" maxlength="4" required>
      <mat-error>
        {{"labels.inputs.product.shortname" | translate}} <strong>{{"labels.commons.is required" | translate}}</strong>
      </mat-error>
    </mat-form-field>

    <mat-form-field fxFlex="48%">
      <mat-label>{{"labels.inputs.fund" | translate}}</mat-label>
      <mat-select formControlName="fundId">
        <mat-option *ngFor="let fund of fundData" [value]="fund.id">
          {{ fund.name }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-checkbox labelPosition="before" formControlName="includeInBorrowerCycle" fxFlex="48%">
      {{"labels.inputs.includeinborrowerloancounter" | translate}}
    </mat-checkbox>

    <mat-form-field fxFlex="48%" (click)="startDatePicker.open()">
      <mat-label>{{"labels.inputs.startdate" | translate }}</mat-label>
      <input matInput [min]="minDate" [max]="maxDate" [matDatepicker]="startDatePicker" formControlName="startDate">
      <mat-datepicker-toggle matSuffix [for]="startDatePicker"></mat-datepicker-toggle>
      <mat-datepicker #startDatePicker></mat-datepicker>
    </mat-form-field>

    <mat-form-field fxFlex="48%" (click)="closeDatePicker.open()">
      <mat-label>{{"labels.inputs.closedate" | translate}}</mat-label>
      <input matInput [min]="minDate" [max]="maxDate" [matDatepicker]="closeDatePicker" formControlName="closeDate">
      <mat-datepicker-toggle matSuffix [for]="closeDatePicker"></mat-datepicker-toggle>
      <mat-datepicker #closeDatePicker></mat-datepicker>
    </mat-form-field>

    <mat-form-field fxFlex="98%">
      <mat-label>{{"labels.heading.description" | translate}}</mat-label>
      <textarea matInput formControlName="description"></textarea>
    </mat-form-field>

  </div>

  <div fxLayout="row" class="margin-t" fxLayout.xs="column" fxLayoutAlign="center" fxLayoutGap="2%">
    <button mat-raised-button matStepperPrevious disabled>
      <fa-icon icon="arrow-left"></fa-icon>&nbsp;&nbsp;
      {{"labels.buttons.previous" | translate}}
    </button>
    <button mat-raised-button matStepperNext>
      {{"labels.buttons.next" | translate}}&nbsp;&nbsp;
      <fa-icon icon="arrow-right"></fa-icon>
    </button>
  </div>

</form>
