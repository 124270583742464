<h1 mat-dialog-title>{{"labels.welcomeTo" | translate}} Bank of Africa</h1>

<mat-dialog-content>

<h3 class="mw600">{{"labels.followingGuideTourWillHelp" | translate: {'name' : appName} }}</h3>

<h3>{{"labels.youHaveSuccessfullyCompletedSetupOf" | translate}} {{ previousStepName }}</h3>

<h3>{{"labels.progressBar" | translate}} : {{stepPercentage}}%</h3>

<mat-progress-bar mode="determinate" [value]="stepPercentage"></mat-progress-bar>

</mat-dialog-content>

<mat-dialog-actions>
    <button mat-raised-button color="warn" [mat-dialog-close]="{ nextStep: false }">{{"labels.closeConfigurationW" | translate}}</button>
    <button mat-raised-button color="primary" [mat-dialog-close]="{ nextStep: true }"> {{ nextStepName }} </button>
</mat-dialog-actions>
