<div *ngIf="!data.reverted; else reverted">

  <h1 mat-dialog-title>{{"labels.revertTransaction" | translate}}</h1>
  <div mat-dialog-content>
    <p>{{"labels.SureYouWantRevertTransaction" | translate}}</p>
    <mat-form-field fxFlexFill>
      <mat-label>{{"labels.inputs.comments" | translate}}</mat-label>
      <textarea matInput [formControl]="comments"></textarea>
    </mat-form-field>
  </div>
  <mat-dialog-actions align="end">
    <button mat-button mat-dialog-close>{{"labels.no" | translate}}</button>
    <button mat-button [mat-dialog-close]="{ revert: true, comments: comments.value }">{{"labels.yes" | translate}}</button>
  </mat-dialog-actions>

</div>

<ng-template #reverted>

    <h1 mat-dialog-title>{{"labels.transactionReverted" | translate}}</h1>
    <div mat-dialog-content>
      <h4>{{"labels.Success" | translate}}!</h4>
      <p>{{"labels.heading.transactionreverse" | translate}}:</p>
      <p>{{"labels.inputs.transactionid" | translate}}: {{ data.transactionId }}</p>
    </div>
    <mat-dialog-actions align="end">
      <button mat-button mat-dialog-close>{{"labels.buttons.close" | translate}}</button>
      <button mat-button [mat-dialog-close]="{ redirect: true }">{{"labels.buttons.redirecttonewtrx" | translate}}</button>
    </mat-dialog-actions>

</ng-template>
