<form [formGroup]="shareProductSettingsForm">

  <div fxLayout="row wrap" fxLayoutGap="2%" fxLayout.lt-md="column">

    <h4 fxFlex="98%" class="mat-h4">{{"labels.heading.shareperclient" | translate}}</h4>

    <mat-form-field fxFlex="31%">
      <mat-label>{{"labels.inputs.minimum" | translate}} </mat-label>
      <input type="number" matInput formControlName="minimumShares">
    </mat-form-field>

    <mat-form-field fxFlex="31%">
      <mat-label>{{"labels.inputs.default" | translate}} </mat-label>
      <input type="number" matInput formControlName="nominalShares" required>
      <mat-error>
        {{"labels.inputs.default" | translate}} {{"labels.heading.shareperclient" | translate}} <strong>{{"labels.commons.is required" | translate}}</strong>
      </mat-error>
    </mat-form-field>

    <mat-form-field fxFlex="31%">
      <mat-label>{{"labels.inputs.maximum" | translate}} </mat-label>
      <input type="number" matInput formControlName="maximumShares">
    </mat-form-field>

    <h4 fxFlex="98%" class="mat-h4">{{"labels.heading.minimumactiveperiod" | translate}}</h4>

    <mat-form-field fxFlex="48%">
      <mat-label>{{"labels.Frequency" | translate}}</mat-label>
      <input type="number" matInput formControlName="minimumActivePeriodForDividends">
    </mat-form-field>

    <mat-form-field fxFlex="48%">
      <mat-label>{{"labels.inputs.type" | translate}}</mat-label>
      <mat-select formControlName="minimumactiveperiodFrequencyType">
        <mat-option *ngFor="let minimumActivePeriodFrequencyType of minimumActivePeriodFrequencyTypeData" [value]="minimumActivePeriodFrequencyType.id">
          {{ minimumActivePeriodFrequencyType.value }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <h4 fxFlex="98%" class="mat-h4">
    {{"labels.inputs.lockinPeriodFrequency" | translate}}
  </h4>

    <mat-form-field fxFlex="48%">
      <mat-label>{{"labels.Frequency" | translate}}</mat-label>
      <input type="number" matInput formControlName="lockinPeriodFrequency">
    </mat-form-field>

    <mat-form-field fxFlex="48%">
      <mat-label>{{"labels.inputs.type" | translate}}</mat-label>
      <mat-select formControlName="lockinPeriodFrequencyType">
        <mat-option *ngFor="let lockinPeriodFrequencyType of lockinPeriodFrequencyTypeData" [value]="lockinPeriodFrequencyType.id">
          {{ lockinPeriodFrequencyType.value }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-checkbox fxFlex="98%" class="margin-v" labelPosition="before" formControlName="allowDividendCalculationForInactiveClients">
      {{"labels.heading.allowdividendsforinactiveclients" | translate}}
    </mat-checkbox>

  </div>

  <div fxLayout="row" class="margin-t" fxLayout.xs="column" fxLayoutAlign="center" fxLayoutGap="2%">
    <button mat-raised-button matStepperPrevious>
      <fa-icon icon="arrow-left"></fa-icon>&nbsp;&nbsp;
      {{"labels.buttons.previous" | translate}}
    </button>
    <button mat-raised-button matStepperNext>
      {{"labels.buttons.next" | translate}}&nbsp;&nbsp;
      <fa-icon icon="arrow-right"></fa-icon>
    </button>
  </div>

</form>
