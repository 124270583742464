<div class="container">
  <h3>{{rate?.name}}
    <button mat-raised-button color="primary" [routerLink]="['edit']" *mifosxHasPermission="'UPDATE_RATE'">
      <fa-icon icon="edit"></fa-icon>&nbsp;&nbsp;
      {{"labels.buttons.edit" | translate}}
    </button>
  </h3>
  <hr />
  <div class="sub-container">
    <table>
      <tbody>
        <tr>
          <td class="ng-binding">{{"labels.heading.percentage" | translate}}</td>
          <td class="ng-binding">{{rate?.percentage}}</td>
        </tr>
        <tr>
          <td class="ng-binding">{{"labels.heading.active" | translate}}</td>
          <td class="ng-binding">{{(rate?.active ? "labels.yes" : "labels.no" ) | translate}}</td>
        </tr>
        <tr>
          <td class="ng-binding">{{"labels.rateappliesto" | translate}}</td>
          <td class="ng-binding">{{rate?.productApply}}</td>
        </tr>

      </tbody>
    </table>
  </div>
</div>
