<form [formGroup]="shareProductTermsForm">

  <div fxLayout="row wrap" fxLayoutGap="2%" fxLayout.lt-md="column">

    <mat-form-field fxFlex="48%">
      <mat-label>{{"labels.inputs.totalnumberofshares" | translate}}</mat-label>
      <input type="number" matInput formControlName="totalShares" required>
      <mat-error>
        {{"labels.inputs.totalnumberofshares" | translate}} <strong>{{"labels.commons.is required" | translate}}</strong>
      </mat-error>
    </mat-form-field>

    <mat-form-field fxFlex="48%">
      <mat-label>{{"labels.inputs.totalsharestobeissue" | translate}}</mat-label>
      <input type="number" matInput formControlName="sharesIssued" required>
      <mat-error>
        {{"labels.inputs.totalsharestobeissue" | translate}} <strong>{{"labels.commons.is required" | translate}}</strong>
      </mat-error>
    </mat-form-field>

    <mat-form-field fxFlex="48%">
      <mat-label>{{"labels.heading.sharenominalprice" | translate}}</mat-label>
      <input type="number" matInput formControlName="unitPrice" required>
      <mat-error>
        {{"labels.inputs.nominalprice" | translate}} <strong>{{"labels.commons.is required" | translate}}</strong>
      </mat-error>
    </mat-form-field>

    <mat-form-field fxFlex="48%">
      <mat-label>{{"labels.inputs.capitalvalue" | translate}}</mat-label>
      <input type="number" matInput formControlName="shareCapital" readonly>
      <mat-hint>
        {{"labels.inputs.totalsharestobeissue" | translate}} * {{"labels.inputs.nominalprice" | translate}} ({{"labels.autoCalculated" | translate}})
      </mat-hint>
    </mat-form-field>

  </div>

  <div fxLayout="row" class="margin-t" fxLayout.xs="column" fxLayoutAlign="center" fxLayoutGap="2%">
    <button mat-raised-button matStepperPrevious>
      <fa-icon icon="arrow-left"></fa-icon>&nbsp;&nbsp;
      {{"labels.buttons.previous" | translate}}
    </button>
    <button mat-raised-button matStepperNext>
      {{"labels.buttons.next" | translate}}&nbsp;&nbsp;
      <fa-icon icon="arrow-right"></fa-icon>
    </button>
  </div>

</form>
