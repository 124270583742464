<mat-card class="client-card">

  <mat-card-header fxLayout="column" class="mat-toolbar mat-primary header">
    <div class="toolbar">
      <h3 class="client-title">
        <i class="fa fa-circle  statusactive" uib-tooltip="Active" uib-tooltip-placement="top"></i>
        <!-- ngIf: client.subStatus.description -->
        <strong class="ng-binding">{{clientViewData.displayName}} </strong>
        <small class="ng-binding">
            {{"labels.heading.clientno" | translate}}: {{clientViewData.accountNo}} |
          <ng-container *ngIf="clientViewData.externalId">
            {{"labels.inputs.externalid" | translate}}: {{clientViewData.externalId}} |
          </ng-container>
          <ng-container *ngIf="clientViewData.staffName">
            {{"labels.inputs.staff" | translate}}: {{clientViewData.staffName}}
          </ng-container>
        </small>
      </h3>
    </div>
    <mat-card-actions class="client-actions">
      <button mat-raised-button [matMenuTriggerFor]="ManageClient">
        {{'labels.anchors.manageClient' | translate}}
        &nbsp;&nbsp;<i class="fa fa-caret-down drop-down-icon ng-scope"></i>
      </button>
      <mat-menu #ManageClient="matMenu" yPosition="below">
        <span *ngIf="clientViewData.status.value === 'Pending'" has-permission='ACTIVATE_CLIENT'><button mat-menu-item
            (click)="doAction('Activate')">
            {{'labels.buttons.activate' | translate}}
          </button>
        </span>
        <span *ngIf="clientViewData.status.value === 'Pending'">
          <button mat-menu-item (click)="doAction('Withdraw')"> {{'labels.buttons.withdraw' | translate}}</button>
        </span>
        <span *ngIf="clientViewData.status.value === 'Pending'">
          <button mat-menu-item (click)="doAction('Reject')">{{'labels.buttons.reject' | translate}}</button>
        </span>
        <span *ngIf="clientViewData.status.value === 'Pending'">
          <button mat-menu-item *mifosxHasPermission="'DELETE_CLIENT'" (click)="doAction('Delete')">
            {{'labels.buttons.delete' | translate}}
          </button>
        </span>
        <span *ngIf="clientViewData.status.value === 'Closed'">
          <button mat-menu-item (click)="doAction('Reactivate')">{{'labels.buttons.reactivate' | translate}}</button>
        </span>
        <span *ngIf="clientViewData.status.value === 'Rejected'">
          <button mat-menu-item (click)="doAction('Undo Rejection')">{{'labels.buttons.undoReject' |
            translate}}</button>
        </span>


        <button mat-menu-item [routerLink]="['edit']" has-permission='UPDATE_CLIENT'>
          {{'labels.buttons.edit' | translate}}
        </button>
        <button mat-menu-item (click)="doAction('Update Default Savings')"
          *mifosxHasPermission="'UPDATESAVINGSACCOUNT_CLIENT'"> {{'labels.buttons.updatedefaultsavings' | translate}}
        </button>
        <span *ngIf="clientViewData.staffId">
          <button mat-menu-item *mifosxHasPermission="'UNASSIGNSTAFF_CLIENT'" (click)="doAction('Unassign Staff')">
            {{'labels.buttons.unassignstaff' | translate}}
          </button>
        </span>
        <span *ngIf="!clientViewData.staffId">
          <button mat-menu-item (click)="doAction('Assign Staff')">{{'labels.buttons.assignstaff' |
            translate}}</button>
        </span>
        <button mat-menu-item (click)="doAction('Upload Signature')" *mifosxHasPermission="'CREATE_CLIENTIMAGE'">
          {{'labels.buttons.uploadsig' | translate}}
        </button>
        <button mat-menu-item (click)="doAction('Delete Signature')" *mifosxHasPermission="'DELETE_CLIENTIMAGE'">
          {{'labels.buttons.deletesig' | translate}}
        </button>
        <button mat-menu-item (click)="doAction('Survey')">{{'labels.buttons.survey' | translate}}</button>
        <button mat-menu-item (click)="doAction('Transfer Client')">{{'labels.buttons.transferclient' |
          translate}}</button>
        <button mat-menu-item (click)="doAction('Close')">{{'labels.buttons.close' | translate}}</button>
        <button mat-menu-item (click)="doAction('Create Collateral')">
          {{'labels.buttons.createcollateral' | translate}}
        </button>
        <button mat-menu-item (click)="doAction('Create Credit Line')" *mifosxHasPermission="'CREATE_CREDIT_LINE'">
          {{'labels.createCreditLine' | translate}}
        </button>
        <button mat-menu-item (click)="doAction('Client Screen Reports')"> {{'labels.anchors.clientscreenreport' |
          translate}} </button>

        <!--
        <button mat-menu-item (click)="doAction('Upload Signature')" *mifosxHasPermission="'CREATE_CLIENTIMAGE'">
          {{'labels.buttons.uploadsig' | translate}} </button>
        <button mat-menu-item (click)="doAction('Delete Signature')" *mifosxHasPermission="'DELETE_CLIENTIMAGE'">
          {{'labels.buttons.deletesig' | translate}} </button>
        <button mat-menu-item (click)="doAction('Survey')">{{'labels.buttons.survey' | translate}}</button>
        <button mat-menu-item (click)="doAction('Transfer Client')"
          has-permission='PROPOSETRANSFER_CLIENT'>{{'labels.buttons.transferclient' |
          translate}}</button>
        <button mat-menu-item (click)="doAction('Close')" has-permission='CLOSE_CLIENT'>
          {{'labels.buttons.close' | translate}}</button>
        <button mat-menu-item (click)="doAction('Create Collateral')"
          has-permission='CREATE_COLLATERAL'>{{'labels.buttons.createcollateral' |
          translate}}</button> -->
      </mat-menu>





      <button mat-raised-button [matMenuTriggerFor]="SellToClient">
        {{'labels.anchors.sellToClient' | translate}}
        &nbsp;&nbsp;<i class="fa fa-caret-down drop-down-icon ng-scope"></i>

      </button>
      <mat-menu #SellToClient="matMenu" yPosition="below">
        <button mat-menu-item [routerLink]="['loans-accounts', 'create']">{{'labels.buttons.newloan' |
          translate}}</button>
        <button mat-menu-item
          [routerLink]="['savings-accounts', 'create']">{{'labels.buttons.newsaving'|translate}}</button>
        <button mat-menu-item *mifosxHasPermission="'CREATE_RECURRINGDEPOSITACCOUNT'"
          [routerLink]="['recurringdeposits', 'create-recurring-deposits-account']">
          {{'labels.buttons.newrecurringdepositaccount' | translate}}
        </button>
        <button mat-menu-item *mifosxHasPermission="'CREATE_SHAREACCOUNT'"
          [routerLink]="['shares-accounts', 'create']">{{ 'labels.buttons.newshareaccount' | translate }}</button>
        <button mat-menu-item *mifosxHasPermission="'CREATE_FIXEDDEPOSITACCOUNT'"
          [routerLink]="['fixed-deposits-accounts', 'create']">
          {{'labels.buttons.newfixeddepositaccount' |
          translate}}</button>

        <button mat-menu-item (click)="doAction('Create Self Service User')">
          {{'labels.buttons.createselfserviceuser' |
          translate}}</button>

      </mat-menu>



      <button mat-raised-button [matMenuTriggerFor]="Transaction">
        {{'labels.anchors.transactionClient' | translate}}
        &nbsp;&nbsp;<i class="fa fa-caret-down drop-down-icon ng-scope"></i>

      </button>
      <mat-menu #Transaction="matMenu" yPosition="below">
        <span *ngIf="clientViewData.status.value!='Transfer on hold'">
          <button mat-menu-item (click)="doAction('View Standing Instructions')"
            *mifosxHasPermission="'CREATE_STANDINGINSTRUCTION'">{{'labels.buttons.viewstandinginstructions' |
            translate}}</button>
        </span>
        <button mat-menu-item (click)="doAction('Create Standing Instructions')"
          *mifosxHasPermission="'READ_STANDINGINSTRUCTION'">{{'labels.buttons.createstandinginstruction' |
          translate}}</button>


        <button mat-menu-item [routerLink]="['charges', 'overview']"
          *mifosxHasPermission="'READ_CLIENTCHARGE'">{{'labels.buttons.chargesoverview' | translate}}</button>


        <button mat-menu-item [routerLink]="['make-client-account-transfer']">
          {{'labels.buttons.transferFunds' | translate}}
        </button>
        <span *ngIf="clientViewData.status.value === 'Transfer in progress'">
          <button mat-menu-item (click)="doAction('Undo Transfer')"> {{'labels.buttons.undotransfer' |
            translate}}</button>
        </span>
        <span *ngIf="clientViewData.status.value === 'Transfer in progress'">
          <button mat-menu-item (click)="doAction('Accept Transfer')">{{'labels.buttons.accepttransfer' |
            translate}}</button>
        </span>
        <span *ngIf="clientViewData.status.value === 'Transfer in progress'">
          <button mat-menu-item (click)="doAction('Reject Transfer')">{{'labels.buttons.rejecttransfer' |
            translate}}</button>
        </span>
        <button mat-menu-item (click)="doAction('Add Charge')" *mifosxHasPermission="'CREATE_CLIENTCHARGE'">
          {{'labels.buttons.newcharge' | translate}}
        </button>


      </mat-menu>



      <!-- <span *mifosxHasPermission="'UPDATE_CLIENT'">
        <button mat-raised-button [routerLink]="['edit']">
          <i class="fa fa-edit"></i>Edit</button>
      </span>

      <button mat-raised-button [matMenuTriggerFor]="Applications">
        <i class="fa fa-file"></i>Applications</button>
      <mat-menu #Applications="matMenu">
        <button mat-menu-item [routerLink]="['loans-accounts', 'create']">New Loan Account</button>
        <button mat-menu-item [routerLink]="['savings-accounts', 'create']">New Savings Account</button>
        <button mat-menu-item *mifosxHasPermission="'CREATE_SHAREACCOUNT'" [routerLink]="['shares-accounts', 'create']">New Share Account</button>
        <button mat-menu-item *mifosxHasPermission="'CREATE_RECURRINGDEPOSITACCOUNT'" [routerLink]="['recurringdeposits', 'create-recurring-deposits-account']">New Recurring Deposit Account</button>
        <button mat-menu-item *mifosxHasPermission="'CREATE_FIXEDDEPOSITACCOUNT'" [routerLink]="['fixed-deposits-accounts', 'create']">New Fixed Deposits Account</button>
      </mat-menu>

      <button mat-raised-button [matMenuTriggerFor]="Actions">
        <i class="fa fa-tasks"></i>Actions</button>
      <mat-menu #Actions="matMenu">
        <button mat-menu-item (click)="doAction('Close')">{{"labels.buttons.close" | translate}}</button>
        <button mat-menu-item (click)="doAction('Transfer Client')">Transfer Client</button>
        <span *ngIf="clientViewData.status.value === 'Pending'"><button mat-menu-item (click)="doAction('Activate')">Activate</button></span>
        <span *ngIf="clientViewData.status.value === 'Pending'"><button mat-menu-item (click)="doAction('Withdraw')">Withdraw</button></span>
        <span *ngIf="clientViewData.status.value === 'Pending'"><button mat-menu-item (click)="doAction('Reject')">Reject</button></span>
        <span *ngIf="clientViewData.status.value === 'Pending'"><button mat-menu-item *mifosxHasPermission="'DELETE_CLIENT'" (click)="doAction('Delete')">{{ 'labels.buttons.delete' | translate }}</button></span>
        <span *ngIf="clientViewData.status.value === 'Closed'"><button mat-menu-item (click)="doAction('Reactivate')">Reactivate</button></span>
        <span *ngIf="clientViewData.status.value === 'Rejected'"><button mat-menu-item (click)="doAction('Undo Rejection')">Undo Rejection</button></span>
        <span *ngIf="clientViewData.status.value === 'Transfer in progress'"><button mat-menu-item (click)="doAction('Undo Transfer')">Undo Transfer</button></span>
        <span *ngIf="clientViewData.status.value === 'Transfer in progress'"><button mat-menu-item (click)="doAction('Accept Transfer')">Accept Transfer</button></span>
        <span *ngIf="clientViewData.status.value === 'Transfer in progress'"><button mat-menu-item (click)="doAction('Reject Transfer')">Reject transfer</button></span>
      </mat-menu>

      <span *ngIf="!clientViewData.staffId">
        <button mat-raised-button (click)="doAction('Assign Staff')">
          <i class="fa fa-user"></i>Assign Staff</button>
      </span>
      <span *ngIf="clientViewData.staffId">
        <button mat-raised-button *mifosxHasPermission="'UNASSIGNSTAFF_CLIENT'" (click)="doAction('Unassign Staff')">
          <i class="fa fa-user"></i>Unassign Staff</button>
      </span>

      <button mat-raised-button [matMenuTriggerFor]="More">{{'labels.buttons.more' | translate}}</button>
      <mat-menu #More="matMenu">
        <button mat-menu-item (click)="doAction('Add Charge')" *mifosxHasPermission="'CREATE_CLIENTCHARGE'"> Add Charge </button>
        <button mat-menu-item (click)="doAction('Create Collateral')">Create Collateral</button>
        <button mat-menu-item (click)="doAction('Survey')">Survey</button>
        <button mat-menu-item (click)="doAction('Update Default Savings')" *mifosxHasPermission="'UPDATESAVINGSACCOUNT_CLIENT'"> Update Default Savings </button>
        <button mat-menu-item (click)="doAction('Upload Signature')" *mifosxHasPermission="'CREATE_CLIENTIMAGE'"> Upload Signature </button>
        <button mat-menu-item (click)="doAction('Delete Signature')" *mifosxHasPermission="'DELETE_CLIENTIMAGE'"> Delete Signature </button>
        <button mat-menu-item (click)="doAction('Client Screen Reports')"> Client Screen Reports </button>
        <button mat-menu-item (click)="doAction('Create Standing Instructions')" *mifosxHasPermission="'READ_STANDINGINSTRUCTION'">Create Standing Instructions</button>
        <span *ngIf="clientViewData.status.value!='Transfer on hold'">
          <button mat-menu-item (click)="doAction('View Standing Instructions')" *mifosxHasPermission="'CREATE_STANDINGINSTRUCTION'">View Standing Instructions</button>
        </span>
        <button mat-menu-item (click)="doAction('Create Self Service User')">Create Self Service User</button>
      </mat-menu> -->

    </mat-card-actions>

  </mat-card-header>

  <mat-card-content>
    <nav class="navigation-tabs">
      <a [routerLink]="['.']" routerLinkActive #general="routerLinkActive"
        [ngClass]="general.isActive && this.router.url.split('/').length ===3 ? 'selected_tab': ''">
        {{'labels.heading.general' | translate}}
      </a>
      <a [routerLink]="['./address']" routerLinkActive #address="routerLinkActive"
        [ngClass]="address.isActive ? 'selected_tab': ''">
        {{'labels.heading.address' | translate}}
      </a>
      <a [routerLink]="['./family-members']" routerLinkActive #familyMembers="routerLinkActive"
        [ngClass]="familyMembers.isActive ? 'selected_tab': ''">
        {{'labels.heading.familymembers' | translate}}
      </a>
      <a [routerLink]="['./identities']" routerLinkActive #identities="routerLinkActive"
        [ngClass]="identities.isActive ? 'selected_tab': ''" *mifosxHasPermission="'READ_CLIENTIDENTIFIER'">
        {{'labels.heading.identities' | translate}}
      </a>
      <a [routerLink]="['./documents']" routerLinkActive #documents="routerLinkActive"
        [ngClass]="documents.isActive ? 'selected_tab': ''" *mifosxHasPermission="'READ_DOCUMENT'">
        {{'labels.heading.documents' | translate}}
      </a>
      <a [routerLink]="['./notes']" routerLinkActive #notes="routerLinkActive"
        [ngClass]="notes.isActive ? 'selected_tab': ''" *mifosxHasPermission="'READ_CLIENTNOTE'">
        {{ 'labels.heading.notes' | translate }}
      </a>
      <ng-container *ngFor="let clientDatatable of clientDatatables">
        <a [routerLink]="['./datatables',clientDatatable.registeredTableName]" routerLinkActive
          #datatable="routerLinkActive" [ngClass]="datatable.isActive ? 'selected_tab': ''"
          *mifosxHasPermission="'READ_' + clientDatatable.registeredTableName">
          {{clientDatatable.registeredTableName}}
        </a>
      </ng-container>
    </nav>

    <div class="content">
      <router-outlet></router-outlet>
    </div>
  </mat-card-content>

</mat-card>
