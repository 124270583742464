<form [formGroup]="loansAccountDetailsForm">

  <div fxLayout="row wrap" fxLayoutGap="2%" fxLayout.lt-md="column">

    <div class="input-container" fxFlex="48%">
      <label>{{'labels.inputs.productname' | translate}}</label>
      <select formControlName="productId" required>
        <option *ngFor="let product of productData" [value]="product.id">
          {{ product.name }}
        </option>
      </select>
      <i class="dropdown-icon fa fa-chevron-down" aria-hidden="true"></i>
      <mat-error *ngIf="loansAccountDetailsForm.controls.productId.hasError('required') && loansAccountDetailsForm.controls.productId.touched">
        {{'labels.inputs.productname' | translate}} <strong>{{"labels.commons.is required" | translate}}</strong>
      </mat-error>
      <mat-error *ngIf="loansAccountDetailsForm.controls.productId.hasError('remainAmountIsNotEnough')  && loansAccountDetailsForm.controls.productId.touched">
        {{"labels.theRemainingAmountOfTheCreditLineIsNotEnough" | translate}}
      </mat-error>
    </div>


    <div class="input-container" fxFlex="48%">
      <mat-label>{{'labels.inputs.loanpurpose' | translate}}</mat-label>
      <select formControlName="loanPurposeId">
        <option *ngFor="let loanPurpose of loanPurposeOptions" [value]="loanPurpose.id">
          {{ loanPurpose.name }}
        </option>
      </select>
      <i class="dropdown-icon fa fa-chevron-down" aria-hidden="true"></i>
    </div>

    <!-- <div class="input-container" fxFlex="48%" (click)="submitPicker.open()">
      <mat-label>{{'labels.inputs.submittedon' | translate}}</mat-label>
      <input  [min]="minDate" [max]="maxDate" disabled [matDatepicker]="submitPicker" required
        formControlName="submittedOnDate">
      <mat-datepicker-toggle matSuffix [for]="submitPicker"></mat-datepicker-toggle>
      <mat-datepicker #submitPicker></mat-datepicker>
      <mat-error *ngIf="loansAccountDetailsForm.controls.submittedOnDate?.hasError('required')">
        {{'labels.inputs.submittedon' | translate}} <strong>{{"labels.commons.is required" | translate}}</strong>
      </mat-error>
    </div> -->

    <div class="input-container" fxFlex="48%" (click)="disbursementPicker.open()">
      <label>{{'labels.inputs.disbursementon' | translate}}</label>
      <input  [min]="loansAccountDetailsForm.value.submittedOnDate" [max]="maxDate"
        [matDatepicker]="disbursementPicker" required formControlName="expectedDisbursementDate">
      <mat-datepicker-toggle matSuffix [for]="disbursementPicker"></mat-datepicker-toggle>
      <mat-datepicker #disbursementPicker></mat-datepicker>
      <mat-error *ngIf="loansAccountDetailsForm.controls.submittedOnDate?.hasError('required')  && loansAccountDetailsForm.controls.submittedOnDate.touched ">
        {{'labels.inputs.disbursementon' | translate}} <strong>{{"labels.commons.is required" | translate}}</strong>
      </mat-error>
    </div>

    <div class="input-container" fxFlex="48%">
      <mat-label>{{'labels.heading.principal' | translate}} {{currencyDisplaySymbol}}</mat-label>
      <input decimalInput  formControlName="principal" [min]="loansAccountProduct?.product?.minPrincipal"
        [max]="loansAccountProduct?.product?.maxPrincipal">
      <mat-error *ngIf="loansAccountDetailsForm.controls.principal.hasError('required')   && loansAccountDetailsForm.controls.principal.touched "> {{ 'labels.heading.principal'|
        translate }} <strong>{{"labels.commons.is required" | translate}}</strong>
      </mat-error>
      <mat-error *ngIf="loansAccountDetailsForm.controls.principal.hasError('max') && loansAccountDetailsForm.controls.principal.touched "> {{ 'labels.heading.principal'|
        translate }} <strong>{{"labels.mustBeLessThan" | translate}}</strong>
        {{loansAccountProduct?.product?.maxPrincipal}}
      </mat-error>
      <mat-error *ngIf="loansAccountDetailsForm.controls.principal.hasError('min') && loansAccountDetailsForm.controls.principal.touched "> {{ 'labels.heading.principal'|
        translate }} <strong>{{"labels.mustBeGreaterThan" | translate}}</strong>
        {{loansAccountProduct?.product?.minPrincipal}}
      </mat-error>
    </div>


    <div fxLayout="row wrap" fxFlex="48%">

      <div class="input-container" fxFlex="30%">
        <mat-label>{{'labels.inputs.repaidevery' | translate}}</mat-label>
        <input  required type="number" formControlName="repaymentEvery" min="1" [max]="maxRepaidEvery"
          [value]="loansAccountDetailsForm.value.repaymentEvery">
        <mat-error *ngIf="loansAccountDetailsForm.controls.repaymentEvery.hasError('required') && loansAccountDetailsForm.controls.repaymentEvery.touched ">
          {{'labels.inputs.repaidevery' | translate}} <strong>{{"labels.commons.is required" | translate}}</strong>
        </mat-error>
        <mat-error *ngIf="loansAccountDetailsForm.controls.repaymentEvery.hasError('max') && loansAccountDetailsForm.controls.repaymentEvery.touched">
          {{ 'labels.inputs.repaidevery'| translate }} <strong>{{"labels.mustBeLessThan" | translate}}</strong>
          {{maxRepaidEvery}}
        </mat-error>
        <mat-error *ngIf="loansAccountDetailsForm.controls.repaymentEvery.hasError('min') && loansAccountDetailsForm.controls.repaymentEvery.touched">
          {{ 'labels.inputs.repaidevery'| translate }} <strong>{{"labels.mustBeGreaterThan" | translate}}</strong>
          1
        </mat-error>
      </div>


      <mat-slider fxFlex="70%" #mySlider formControlName="repaymentEvery" min="1" [max]="maxRepaidEvery"
        [value]="loansAccountDetailsForm.value.repaymentEvery">
        <input matSliderThumb>
      </mat-slider>
    </div>
    <div class="input-container" fxFlex="48%">
      <mat-label>{{'labels.Frequency' | translate}}</mat-label>
      <select formControlName="repaymentFrequencyType" required>
        <option *ngFor="let repaymentFrequencyType of termFrequencyTypeData" [value]="repaymentFrequencyType.id">
          {{ repaymentFrequencyType.code | translate}}
        </option>
      </select>
      <i class="dropdown-icon fa fa-chevron-down" aria-hidden="true"></i>
    </div>

    <div fxLayout="row wrap" fxFlex="48%">
      <div class="input-container" fxFlex="30%">
        <mat-label>{{'labels.inputs.loanterm' | translate}}</mat-label>
        <input  type="number"
          [min]="loansAccountDetailsForm.value.repaymentEvery*loansAccountProduct?.product.minNumberOfRepayments"
          [max]="maxTerms" [step]="loansAccountDetailsForm.value.repaymentEvery" required
          formControlName="loanTermFrequency" [value]="loansAccountDetailsForm.value.loanTermFrequency">
        <mat-error *ngIf="loansAccountDetailsForm.controls.loanTermFrequency.hasError('required') && loansAccountDetailsForm.controls.loanTermFrequency.touched">
          {{'labels.inputs.loanterm' | translate}} <strong>{{"labels.commons.is required" | translate}}</strong>
        </mat-error>
        <mat-error *ngIf="loansAccountDetailsForm.controls.loanTermFrequency.hasError('max') && loansAccountDetailsForm.controls.loanTermFrequency.touched">
          {{ 'labels.inputs.loanterm'| translate }} <strong>{{"labels.mustBeLessThan" | translate}}</strong>
          {{maxTerms}}
        </mat-error>
        <mat-error *ngIf="loansAccountDetailsForm.controls.loanTermFrequency.hasError('min') && loansAccountDetailsForm.controls.loanTermFrequency.touched">
          {{ 'labels.inputs.loanterm'| translate }} <strong>{{"labels.mustBeGreaterThan" | translate}}</strong>
          {{loansAccountDetailsForm.value.repaymentEvery*loansAccountProduct?.product.minNumberOfRepayments}}
        </mat-error>
      </div>
      <mat-slider fxFlex="70%" #mySlider formControlName="loanTermFrequency"
        [step]="loansAccountDetailsForm.value.repaymentEvery" [value]="loansAccountDetailsForm.value.loanTermFrequency"
        [min]="loansAccountDetailsForm.value.repaymentEvery*loansAccountProduct?.product.minNumberOfRepayments"
        [max]="maxTerms" [step]="loansAccountDetailsForm.value.repaymentEvery">
        <input matSliderThumb>
      </mat-slider>
    </div>




    <div class="input-container" fxFlex="48%">
      <mat-label>{{'labels.heading.numberOfRepayments' | translate}}</mat-label>
      <input type="number"  formControlName="numberOfRepayments"
        [value]="loansAccountDetailsForm.value.numberOfRepayments"
        [min]="loansAccountProduct?.product?.minNumberOfRepayments"
        [max]="loansAccountProduct?.product?.maxNumberOfRepayments" [attr.disabled]="true">
      <mat-error *ngIf="loansAccountDetailsForm.controls.numberOfRepayments.hasError('required') && loansAccountDetailsForm.controls.numberOfRepayments.touched">
        {{'labels.heading.numofrepayments' | translate}} <strong>{{"labels.commons.is required" | translate}}</strong>
      </mat-error>
    </div>
  </div>
  <button mat-raised-button color="primary" (click)="simulate()">
    {{'labels.simulate' | translate}}
  </button>
  <div fxLayout="row" class="margin-t" fxLayoutAlign="center" fxLayoutGap="2%">
    <button mat-raised-button matStepperPrevious disabled>
      <fa-icon class="hidden-mobile" icon="arrow-left"></fa-icon>&nbsp;&nbsp;
      {{'labels.buttons.previous' | translate}}
    </button>
    <button mat-raised-button [routerLink]="['../']">
      {{ 'labels.buttons.cancel'| translate }}
    </button>
    <button mat-raised-button (click)="goToNextStep()">
      {{'labels.buttons.next' | translate}}&nbsp;&nbsp;
      <fa-icon class="hidden-mobile" icon="arrow-right"></fa-icon>
    </button>
  </div>

</form>
