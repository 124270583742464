import { FormGroup, FormBuilder } from '@angular/forms';
import { async } from '@angular/core/testing';
import { HomeService } from './../home.service';
import { AuthenticationService } from 'app/core/authentication/authentication.service';
/** Angular Imports */
import { Component, OnInit } from '@angular/core';

/**
 * Dashboard component.
 */
@Component({
  selector: 'mifosx-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {

  /** Array of all user activities */
  userActivity: string[];
  /** Array of most recent user activities */
  recentActivities: string[];
  /** Array of most frequent user activities */
  frequentActivities: string[];
  dashData: any;
  dashboards : any[];
  filtersForm: FormGroup;


  constructor(
    private formBuilder: FormBuilder,
    private authenticationService: AuthenticationService,
    private homeService: HomeService, ) {
    this.filtersForm = this.formBuilder.group({
      dashboard: []
    });

    this.userActivity = JSON.parse(localStorage.getItem('mifosXLocation'));
    // const credentials = this.authenticationService.getCredentials();
    // const dashboards = credentials?.dashboards;
    // if(dashboards){
      this.homeService.getDashboards().subscribe((data:any)=>{
        this.dashboards = data;
        this.dashData = this.dashboards[0];
        this.filtersForm.get('dashboard').setValue(this.dashData);
      })
  }

  selectDahsboard(dashData:any){
    this.dashData = dashData;
  }

  ngOnInit() {
    this.recentActivities = this.getRecentActivities();
    this.frequentActivities = this.getFrequentActivities();
  }

  /**
   * Returns top eight recent activities.
   */
  getRecentActivities() {
    const reverseActivities = this.userActivity.reverse();
    const uniqueActivities: string[] = [];
    reverseActivities.forEach((activity: string) => {
      if (!uniqueActivities.includes(activity)) {
        uniqueActivities.push(activity);
      }
    });
    const topEightRecentActivities =
      uniqueActivities
        .filter((activity: string) => !['/', '/login', '/home', '/dashboard'].includes(activity))
        .slice(0, 8);
    return topEightRecentActivities;
  }

  /**
   * Returns top eight frequent activities.
   */
  getFrequentActivities() {
    const frequencyCounts: any  = {};
    let index  = this.userActivity.length;
    while (index) {
      frequencyCounts[this.userActivity[--index]] = (frequencyCounts[this.userActivity[index]] || 0) + 1;
    }
    const frequencyCountsArray = Object.entries(frequencyCounts);
    const topEigthFrequentActivities =
      frequencyCountsArray
        .sort((a: any, b: any) => b[1] - a[1])
        .map((entry: any[]) => entry[0])
        .filter((activity: string) => !['/', '/login', '/home', '/dashboard'].includes(activity))
        .slice(0, 8);
    return topEigthFrequentActivities;
  }

}
