<div class="container">

  <mat-card>

    <form [formGroup]="taxComponentForm" (ngSubmit)="submit()">

      <mat-card-content>

        <div fxLayout="column">

          <mat-form-field>
            <mat-label>{{"labels.heading.name" | translate}}</mat-label>
            <input matInput required formControlName="name">
            <mat-error *ngIf="taxComponentForm.controls.name.hasError('required')">
              {{"labels.inputs.name" | translate}} <strong>{{"labels.commons.is required" | translate}}</strong>
            </mat-error>
          </mat-form-field>

          <mat-form-field>
            <mat-label> {{"labels.inputs.percentage" | translate}} </mat-label>
            <input type="number" matInput required formControlName="percentage">
            <mat-error *ngIf="taxComponentForm.controls.percentage.hasError('required')"> {{"labels.inputs.percentage" |
              translate}} <strong>{{"labels.commons.is required" | translate}}</strong>
            </mat-error>
            <mat-error *ngIf="taxComponentForm.controls.percentage.hasError('pattern') ||
            taxComponentForm.controls.percentage.hasError('max')"> {{"labels.inputs.percentage" | translate}}
              <strong>{{"labels.should" | translate}}</strong> {{"labels.beLargerThan0Most100" | translate}}
            </mat-error>
          </mat-form-field>

          <mat-form-field>
            <mat-label>{{"labels.inputs.debitaccounttype" | translate}}</mat-label>
            <mat-select formControlName="debitAccountType">
              <mat-option *ngFor="let debitAccountType of debitAccountTypeData" [value]="debitAccountType.id">
                {{ debitAccountType.value }}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field *ngIf="taxComponentForm.controls.debitAccountType.value">
            <mat-label> {{"labels.inputs.debitaccount" | translate}} </mat-label>
            <mat-select required formControlName="debitAcountId">
              <mat-option *ngFor="let debitAccount of debitAccountData" [value]="debitAccount.id">
                {{ debitAccount.name }}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="taxComponentForm.controls.debitAcountId.hasError('required')">
              {{"labels.inputs.debitaccount" | translate}} <strong>{{"labels.commons.is required" | translate}}</strong>
            </mat-error>
          </mat-form-field>

          <mat-form-field>
            <mat-label> {{"labels.inputs.creditaccounttype" | translate}} </mat-label>
            <mat-select formControlName="creditAccountType">
              <mat-option *ngFor="let creditAccountType of creditAccountTypeData" [value]="creditAccountType.id">
                {{ creditAccountType.value }}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field *ngIf="taxComponentForm.controls.creditAccountType.value">
            <mat-label> {{"labels.inputs.creditaccount" | translate}} </mat-label>
            <mat-select required formControlName="creditAcountId">
              <mat-option *ngFor="let creditAccount of creditAccountData" [value]="creditAccount.id">
                {{ creditAccount.name }}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="taxComponentForm.controls.creditAcountId.hasError('required')">
              {{"labels.inputs.creditaccount" | translate}} <strong>{{"labels.commons.is required" |
                translate}}</strong>
            </mat-error>
          </mat-form-field>

          <mat-form-field (click)="startDatePicker.open()">
            <mat-label>{{"labels.inputs.startdate" | translate }}</mat-label>
            <input matInput [min]="minDate" [max]="maxDate" [matDatepicker]="startDatePicker" required
              formControlName="startDate">
            <mat-datepicker-toggle matSuffix [for]="startDatePicker"></mat-datepicker-toggle>
            <mat-datepicker #startDatePicker></mat-datepicker>
            <mat-error *ngIf="taxComponentForm.controls.startDate.hasError('required')"> {{"labels.inputs.startdate" |
              translate }} <strong>{{"labels.commons.is required" | translate}}</strong>
            </mat-error>
          </mat-form-field>

        </div>

      </mat-card-content>

      <mat-card-actions fxLayout="row" fxLayout.xs="column" fxLayoutAlign="center" fxLayoutGap="5px">
        <button type="button" mat-raised-button [routerLink]="['../']">{{"labels.buttons.cancel" | translate}}</button>
        <button mat-raised-button color="primary" [disabled]="!taxComponentForm.valid"
          *mifosxHasPermission="'CREATE_TAXCOMPONENT'">{{"labels.buttons.submit" | translate}}</button>
      </mat-card-actions>

    </form>

  </mat-card>

</div>
