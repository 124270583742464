import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'mifosx-web-views-top-bar',
  templateUrl: './web-views-top-bar.component.html',
  styleUrls: ['./web-views-top-bar.component.scss']
})
export class WebViewsTopBarComponent implements OnInit {

  @Input() title:string;
  @Input() main : Boolean = false;
  constructor() { }

  ngOnInit(): void {
  }

}
