<div class="container">

  <mat-card>

    <form [formGroup]="editEmployeeForm" (ngSubmit)="submit()">

      <mat-card-content>

        <div fxLayout="column">

          <mat-form-field>
            <mat-label>{{"labels.inputs.office" | translate}}</mat-label>
            <mat-select required formControlName="officeId">
              <mat-option *ngFor="let office of officeData" [value]="office.id">
                {{ office.name }}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="editEmployeeForm.controls.officeId.hasError('required')">
              {{"labels.inputs.office" | translate}} <strong>{{"labels.commons.is required" | translate}}</strong>
            </mat-error>
          </mat-form-field>

          <mat-form-field>
            <mat-label>{{"labels.inputs.firstname" | translate}}</mat-label>
            <input matInput required formControlName="firstname">
            <mat-error *ngIf="editEmployeeForm.controls.firstname.hasError('required')">
              {{"labels.inputs.firstname" | translate}} <strong>{{"labels.commons.is required" | translate}}</strong>
            </mat-error>
            <mat-error *ngIf="editEmployeeForm.controls.firstname.hasError('pattern')">
              {{"labels.inputs.firstname" | translate}} <strong>{{"labels.cannot" | translate}}</strong> {{"labels.anchors.cannotbeginwithspecialcharacter" | translate}}
            </mat-error>
          </mat-form-field>

          <mat-form-field>
            <mat-label>{{"labels.inputs.lastname" | translate}}</mat-label>
            <input matInput required formControlName="lastname">
            <mat-error *ngIf="editEmployeeForm.controls.lastname.hasError('required')">
              {{"labels.inputs.lastname" | translate}} <strong>{{"labels.commons.is required" | translate}}</strong>
            </mat-error>
            <mat-error *ngIf="editEmployeeForm.controls.lastname.hasError('pattern')">
              {{"labels.inputs.lastname" | translate}}<strong>{{"labels.cannot" | translate}}</strong> {{"labels.anchors.cannotbeginwithspecialcharacter" | translate}}
            </mat-error>
          </mat-form-field>

          <mat-checkbox labelPosition="before" formControlName="isLoanOfficer" class="m-b-10">
            {{"labels.inputs.isLoanOfficer" | translate}}
          </mat-checkbox>

          <mat-form-field>
            <mat-label>{{"labels.inputs.mobileNo" | translate}}</mat-label>
            <input matInput formControlName="mobileNo">
          </mat-form-field>

          <mat-checkbox labelPosition="before" formControlName="isActive">
            {{"labels.heading.active" | translate}}
          </mat-checkbox>

          <mat-form-field (click)="joiningDatePicker.open()">
            <mat-label>{{"labels.joiningDate" | translate}}</mat-label>
            <input matInput [min]="minDate" [max]="maxDate" [matDatepicker]="joiningDatePicker" required formControlName="joiningDate">
            <mat-datepicker-toggle matSuffix [for]="joiningDatePicker"></mat-datepicker-toggle>
            <mat-datepicker #joiningDatePicker></mat-datepicker>
            <mat-error *ngIf="editEmployeeForm.controls.joiningDate.hasError('required')">
              {{"labels.joiningDate" | translate}} <strong>{{"labels.commons.is required" | translate}}</strong>
            </mat-error>
          </mat-form-field>

        </div>

      </mat-card-content>

      <mat-card-actions fxLayout="row" fxLayout.xs="column" fxLayoutAlign="center" fxLayoutGap="5px">
        <button type="button" mat-raised-button [routerLink]="['../']">{{"labels.buttons.cancel" | translate}}</button>
        <button mat-raised-button color="primary" [disabled]="!editEmployeeForm.valid" *mifosxHasPermission="'UPDATE_STAFF'">{{"labels.buttons.submit" | translate}}</button>
      </mat-card-actions>

    </form>

  </mat-card>

</div>
