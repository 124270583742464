<div class="container m-b-20" fxLayout="row" fxLayoutAlign="end" fxLayoutGap="20px">

  <button mat-raised-button color="primary" [routerLink]="['edit']" *mifosxHasPermission="'UPDATE_REPORT'">
    <fa-icon icon="edit"></fa-icon>&nbsp;&nbsp;{{"labels.inputs.edit" | translate }}</button>

  <span *ngIf="!reportData.coreReport">
    <button mat-raised-button color="warn" (click)="delete()" *mifosxHasPermission="'DELETE_REPORT'">
      <fa-icon icon="trash"></fa-icon>&nbsp;&nbsp;{{"labels.anchors.delete" | translate }}</button>
  </span>

</div>

<div class="container">

  <mat-card>

    <mat-card-title>{{ reportData.reportName }}</mat-card-title>

    <mat-card-content>

      <div fxLayout="row wrap" class="content">

        <div fxFlex="50%" class="mat-body-strong">
          {{"labels.inputs.reporttype" | translate}}:
        </div>

        <div fxFlex="50%">
          {{ reportData.reportType }}
        </div>

        <div fxFlex="50%" class="mat-body-strong">
          {{"labels.inputs.reportsubtype" | translate}}:
        </div>

        <div fxFlex="50%">
          {{ reportData.reportSubType }}
        </div>

        <div fxFlex="50%" class="mat-body-strong">
          {{"labels.inputs.reportcategory" | translate}}:
        </div>

        <div fxFlex="50%">
          {{ reportData.reportCategory }}
        </div>

        <div fxFlex="50%" class="mat-body-strong">
          {{"labels.inputs.corereport" | translate}}:
        </div>

        <div fxFlex="50%">
          {{ reportData.coreReport ? 'Yes' : 'No' }}
        </div>

        <div fxFlex="50%" class="mat-body-strong">
          {{"labels.userReport" | translate}}:
        </div>

        <div fxFlex="50%">
          {{ reportData.useReport ? 'Yes' : 'No' }}
        </div>

      </div>

    </mat-card-content>

  </mat-card>
