<!-- <h1 mat-dialog-title>{{"labels.heading.loansimulator" | translate}}</h1>
<div mat-dialog-content>
  <div class="content" fxLayout="row wrap" fxLayoutGap="2%" fxLayout.lt-md="column">
    <div fxFlex="48%">
      {{"labels.totalDueForPeriod" | translate}} :
      </div>
      <div fxFlex="48%">
      {{data?.totalDueForPeriod}} {{data?.currency}}
    </div>

    <div fxFlex="48%">
      {{"labels.anchors.charges" | translate}} :
    </div>
    <div fxFlex="48%"> {{data?.charge}} {{data?.currency}}
    </div>
  </div>
</div>
<mat-dialog-actions align="end">
  <button mat-raised-button mat-dialog-close>{{"labels.close" | translate}}</button>
</mat-dialog-actions> -->



<mat-card class="custom-card">
  <h2>Récapitulatif de votre simulation</h2>

  <div class="card-content">
    <div class="card-item">
      <div class="card-item-right">
        <img src='assets/images/svg/bag.svg' />
        <h2>Besoin</h2>
      </div>
      <div class="card-item-left">
        <h2>{{data?.productName}}</h2>
      </div>
    </div>
    <div class="card-item">
      <div class="card-item-right">
        <img src='assets/images/svg/money.svg' />
        <h2>Montant du prêt</h2>
      </div>
      <div class="card-item-left">
        <h2>{{data?.totalPrincipalDisbursed}} {{data?.currency}}</h2>
      </div>
    </div>
    <div class="card-item">
      <div class="card-item-right">
        <img src='assets/images/svg/money.svg' />
        <h2>Coût total du prêt
        </h2>
      </div>
      <div class="card-item-left">
        <h2>{{data?.totalRepaymentExpected}} {{data?.currency}}</h2>
      </div>
    </div>
    <div class="card-item">
      <div class="card-item-right">
        <img src='assets/images/svg/watch.svg' />
        <h2>Durée du prêt
        </h2>
      </div>
      <div class="card-item-left">
        <h2>{{data?.loanTermFrequency}} {{data?.repaymentFrequencyType | translate}}</h2>
      </div>
    </div>
    <div class="card-item">
      <div class="card-item-right">
        <img src='assets/images/svg/money.svg' />
        <h2>{{"labels.totalDueForPeriod" | translate}}</h2>
      </div>
      <div class="card-item-left">
        <h2>{{data?.totalDueForPeriod}} {{data?.currency}}</h2>
      </div>
    </div>
  </div>
  <br />

  <button (click)="close()" class="simulate-button">Nouvelle simulation</button>
  <br />
  <br />
  <!-- <p>Offre non contractuelle, simulation effectuée sur la base des données déclaratives fournies, à confirmer par une
    étude complète des justificatifs.</p> -->

</mat-card>
