<div class="container">

  <mat-card>

    <form [formGroup]="adhocQueryForm" (ngSubmit)="submit()">

      <mat-card-content>

        <div fxLayout="column">

          <mat-form-field>
            <mat-label>{{"labels.inputs.name" | translate}}</mat-label>
            <input matInput required formControlName="name">
            <mat-error *ngIf="adhocQueryForm.controls.name.hasError('required')">
              {{"labels.inputs.name" | translate}} <strong>{{"labels.commons.is required" | translate}}</strong>
            </mat-error>
          </mat-form-field>

          <mat-form-field>
            <mat-label>{{"labels.inputs.query" | translate}}</mat-label>
            <textarea matInput required formControlName="query"></textarea>
            <mat-error *ngIf="adhocQueryForm.controls.query.hasError('required')">
              {{"labels.inputs.query" | translate}} <strong>{{"labels.commons.is required" | translate}}</strong>
            </mat-error>
          </mat-form-field>

          <mat-form-field>
            <mat-label>{{"labels.inputs.tablename" | translate}}</mat-label>
            <input matInput required formControlName="tableName">
            <mat-error *ngIf="adhocQueryForm.controls.tableName.hasError('required')">
              {{"labels.inputs.tablename" | translate}} <strong>{{"labels.commons.is required" | translate}}</strong>
            </mat-error>
          </mat-form-field>

          <mat-form-field>
            <mat-label>{{"labels.inputs.tablefields" | translate}}</mat-label>
            <textarea matInput required formControlName="tableFields"></textarea>
            <mat-error *ngIf="adhocQueryForm.controls.tableFields.hasError('required')">
              {{"labels.inputs.tablefields" | translate}} <strong>{{"labels.areRequired" | translate}}</strong>
            </mat-error>
          </mat-form-field>

          <mat-form-field>
            <mat-label>{{"labels.heading.email" | translate}}</mat-label>
            <input matInput formControlName="email">
            <mat-error *ngIf="adhocQueryForm.controls.email.hasError('email')">
              {{"labels.heading.email" | translate}} <strong>{{"labels.isInvalid" | translate}}</strong>
            </mat-error>
          </mat-form-field>

          <mat-form-field>
            <mat-label>{{"labels.inputs.reportrunfrequncy" | translate}}</mat-label>
            <mat-select formControlName="reportRunFrequency">
              <mat-option *ngFor="let reportRunFrequency of reportRunFrequencyData" [value]="reportRunFrequency.id">
                {{ reportRunFrequency.value }}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field *ngIf="adhocQueryForm.controls.reportRunFrequency.value === 5">
            <mat-label>{{"labels.customReportRunFrequency" | translate}} ({{"labels.commons.days" | translate}})</mat-label>
            <input type="number" matInput required formControlName="reportRunEvery">
            <mat-error *ngIf="adhocQueryForm.controls.reportRunEvery.hasError('required')">
              {{"labels.customReportRunFrequency" | translate}} <strong>{{"labels.commons.is required" | translate}}</strong>
            </mat-error>
            <mat-error *ngIf="adhocQueryForm.controls.reportRunEvery.hasError('min')">
              {{"labels.customReportRunFrequency" | translate}} <strong>{{"labels.mustBeLargerThan" | translate}} 0</strong>
            </mat-error>
          </mat-form-field>

          <mat-checkbox labelPosition="before" formControlName="isActive">
            {{"labels.inputs.active" | translate}}
          </mat-checkbox>

        </div>

      </mat-card-content>

      <mat-card-actions fxLayout="row" fxLayout.xs="column" fxLayoutAlign="center" fxLayoutGap="5px">
        <button type="button" mat-raised-button [routerLink]="['../']">{{"labels.buttons.cancel" | translate}}</button>
        <button mat-raised-button color="primary" [disabled]="!adhocQueryForm.valid" *mifosxHasPermission="'CREATE_ADHOC'">{{"labels.buttons.submit" | translate}}</button>
      </mat-card-actions>

    </form>

  </mat-card>

</div>
