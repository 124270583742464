<div fxLayout="row" fxLayoutAlign="end" fxLayoutGap="2%" fxLayout.lt-md="column" class="container m-b-20">
  <button mat-raised-button color="primary" [routerLink]="['edit']" *mifosxHasPermission="'UPDATE_TELLER'">
    <fa-icon icon="edit"></fa-icon>&nbsp;&nbsp;
    {{"labels.inputs.edit" | translate}}
  </button>
  <button mat-raised-button color="warn" (click)="deleteTeller()" *mifosxHasPermission="'DELETE_TELLER'">
    <fa-icon icon="trash"></fa-icon>&nbsp;&nbsp;
    {{"labels.anchors.delete" | translate}}
  </button>
</div>

<div class="container">

  <mat-card>

    <mat-card-content>

      <div fxLayout="row wrap" class="content">

        <div fxFlex="50%" class="mat-body-strong">
          {{"labels.inputs.teller.name" | translate}}
        </div>

        <div fxFlex="50%">
          {{ tellerData.name }}
        </div>

        <div fxFlex="50%" class="mat-body-strong">
          {{"labels.inputs.teller.office" | translate}}
        </div>

        <div fxFlex="50%">
          {{ tellerData.officeName }}
        </div>

        <div fxFlex="50%" class="mat-body-strong" *ngIf="tellerData.description">
          {{"labels.input.teller.description" | translate}}
        </div>

        <div fxFlex="50%" *ngIf="tellerData.description">
          {{ tellerData.description }}
        </div>

        <div fxFlex="50%" class="mat-body-strong">
          {{"labels.inputs.teller.startDate" | translate}}
        </div>

        <div fxFlex="50%">
          {{ tellerData.startDate  | dateFormat }}
        </div>

        <div fxFlex="50%" class="mat-body-strong" *ngIf="tellerData.endDate">
          {{"labels.inputs.teller.endDate" | translate}}
        </div>

        <div fxFlex="50%" *ngIf="tellerData.endDate">
          {{ tellerData.endDate  | dateFormat }}
        </div>

        <div fxFlex="50%" class="mat-body-strong">
          {{"labels.inputs.teller.status" | translate}}
        </div>

        <div fxFlex="50%">
          {{ tellerData.status | titlecase }}
        </div>

      </div>

    </mat-card-content>

  </mat-card>

</div>
