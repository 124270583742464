import { TranslateService } from '@ngx-translate/core';
import { ExportExcel } from './exportExcel.model';
import { Injectable } from '@angular/core';
import * as XLSX from 'xlsx';


@Injectable({
  providedIn: 'root'
})
export class ExportExcelService {

  constructor(private translateService: TranslateService) {
  }


  exportExcelFile(param: ExportExcel) {
    const header = param.header.map((item: string) => this.translateService.instant(item))
    const worksheet = XLSX.utils.json_to_sheet(param.data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet);
    XLSX.utils.sheet_add_aoa(worksheet, [header], { origin: "A1" });
    XLSX.writeFile(workbook, param.fileName);
  }

}
