<div class="tab-container mat-typography">
  <mat-card class="group-card">

    <mat-card-header fxLayout="column" class="mat-toolbar mat-primary header">

      <mat-card-title-group class="header-title-group">

        <div class="profile-image-container">
          <div>
            <img mat-card-md-image class="profile-image" src="assets/images/group_placeholder.png">
          </div>
        </div>

        <div class="mat-typography group-card-title">
          <mat-card-title>
            <h3>
              <i class="fa fa-stop" [ngClass]="groupViewData.status.code|statusLookup"
                [matTooltip]="groupViewData.status.value"></i>
                {{ 'labels.inputs.groupName' | translate }} : {{groupViewData.name}}
            </h3>
          </mat-card-title>
          <mat-card-subtitle>
            <p>
              {{ 'labels.inputs.group' | translate }} #: {{groupViewData.id}} | {{ 'labels.centerName' | translate }}: {{groupViewData.centerName}} | {{ 'labels.inputs.staff' | translate }}: {{groupViewData.staffName
              || ('labels.heading.unassigned' | translate )}}<br />
              {{ 'labels.heading.officeName' | translate }}: {{groupViewData.officeName}}<br />
               {{ 'labels.heading.activationdate' | translate }} : {{(groupViewData.activationDate)?(groupViewData.activationDate | dateFormat) :('labels.heading.notActivated' | translate)}}<br />
              <span *ngIf="!groupViewData.active">
                {{ 'labels.heading.closuredate' | translate }}: {{groupViewData.timeline.closedOnDate | dateFormat}}
              </span>
            </p>
          </mat-card-subtitle>
        </div>

        <div class="group-meeting" fxLayoutAlign="start start">
          <div *ngIf="groupViewData.collectionMeetingCalendar; else unassigned">
            <p>
              {{ 'labels.heading.nextmeetingon' | translate }}: {{groupViewData.collectionMeetingCalendar?.nextTenRecurringDates[0] | dateFormat}}
              <i class="fa fa-edit" *ngIf="editMeeting" (click)="doAction('Edit Meeting')"></i><br />
              {{ 'labels.heading.meetingfrequency' | translate }}: {{groupViewData.collectionMeetingCalendar?.frequency.value | lowercase}}
            </p>
          </div>
          <ng-template #unassigned>
            <div>
              <p>
                {{ 'labels.heading.nextmeetingon' | translate }}: {{ 'labels.heading.unassigned' | translate }}
                <i class="fa fa-calendar"></i><br />
                {{ 'labels.heading.meetingfrequency' | translate }}: N/A
              </p>
            </div>
          </ng-template>
        </div>

      </mat-card-title-group>
      </mat-card-header>
      </mat-card>
  <h3>{{ 'labels.heading.groupdetails' | translate }}</h3>

  <div fxLayout="row" fxLayoutGap="32px" class="group-details-container m-b-30">
    <p>
      {{ 'labels.activeClientLoans' | translate }}: {{groupSummary?.activeClientLoans}}<br/>
      {{ 'labels.activeClientBorrowers' | translate }}: {{groupSummary?.activeClientBorrowers}}<br/>
      {{ 'labels.activeOverdueClientLoans' | translate }}: {{groupSummary?.overdueClientLoans}}<br/>
    </p>
    <p>
      {{ 'labels.activeGroupLoans' | translate }}: {{groupSummary?.activeGroupLoans}}<br/>
      {{ 'labels.activeGroupBorrowers' | translate }}: {{groupSummary?.activeGroupBorrowers}}<br/>
      {{ 'labels.activeOverdueGroupLoans' | translate }}: {{groupSummary?.overdueGroupLoans}}<br/>
    </p>
    <p>
      {{ 'labels.activeClients' | translate }}: {{groupSummary?.activeClients}}<br/>
    </p>
  </div>

  <!-- Client Members Table -->
  <div *ngIf="groupClientMembers">

    <h3>{{ 'labels.anchors.clientMembers' | translate }}</h3>

    <table mat-table [dataSource]="groupClientMembers"
      class="mat-elevation-z1 m-b-30">

      <ng-container matColumnDef="Name">
        <th mat-header-cell *matHeaderCellDef> {{ 'labels.heading.name' | translate }} </th>
        <td mat-cell *matCellDef="let element">
          <i class="fa fa-stop" [ngClass]="element.status.code|statusLookup" [matTooltip]="element.status.value"></i>
          {{element.displayName}}
        </td>
      </ng-container>

      <ng-container matColumnDef="Account No">
        <th mat-header-cell *matHeaderCellDef> {{ 'labels.accountNo' | translate }} </th>
        <td mat-cell *matCellDef="let element"> {{element.accountNo}} </td>
      </ng-container>

      <ng-container matColumnDef="Office">
        <th mat-header-cell *matHeaderCellDef> {{ 'labels.inputs.office' | translate }} </th>
        <td mat-cell *matCellDef="let element"> {{element.officeName}} </td>
      </ng-container>

      <ng-container matColumnDef="JLG Loan Application">
        <th mat-header-cell *matHeaderCellDef> {{ 'labels.buttons.jlgloanapplication' | translate }} </th>
        <td mat-cell *matCellDef="let element">
          <div class="m-l-30" *ngIf="element.status.code !== 'clientStatusType.closed'">
            <button class="account-action-button" mat-raised-button color="primary">
              <i class="fa fa-plus" [matTooltip]="'labels.heading.newloanapplication' | translate"></i>
            </button>
          </div>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="clientMemberColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: clientMemberColumns;" [routerLink]="['/clients', row.id, 'loans-accounts', 'create']" class="select-row"></tr>

    </table>

  </div>

  <div *ngIf="loanAccounts">

    <div fxLayout="row" fxLayoutAlign="start" class="table-header">
      <div class="m-b-10">
        <h3>{{ 'labels.buttons.loanaccounts' | translate }}</h3>
      </div>
      <div *ngIf="(loanAccounts|accountsFilter:'loan':'closed').length" class="action-button m-b-10">
        <button mat-raised-button class="f-right" color="primary"
      (click)="toggleLoanAccountsOverview()">{{(showClosedLoanAccounts?'labels.buttons.viewActiveAccounts':'labels.buttons.viewClosedAccounts') | translate}}</button>
      </div>
    </div>

    <!-- Open Loan Accounts Table -->
    <table *ngIf="!showClosedLoanAccounts" mat-table [dataSource]="loanAccounts|accountsFilter:'loan'"
      class="mat-elevation-z1 m-b-30">

      <ng-container matColumnDef="Account No">
        <th mat-header-cell *matHeaderCellDef> {{ 'labels.accountNo' | translate }} </th>
        <td mat-cell *matCellDef="let element"> <i class="fa fa-stop"
            [ngClass]="element.inArrears?'status-active-overdue':(element.status.code|statusLookup)"
            [matTooltip]="element.inArrears?('labels.heading.active' | translate)+' '+('labels.heading.overdue' | translate):element.status.value"></i>
          {{element.accountNo}}
        </td>
      </ng-container>

      <ng-container matColumnDef="Loan Account">
        <th mat-header-cell *matHeaderCellDef> {{ 'labels.buttons.loanaccount' | translate }} </th>
        <td mat-cell *matCellDef="let element"> {{element.productName}} </td>
      </ng-container>

      <ng-container matColumnDef="Original Loan">
        <th mat-header-cell *matHeaderCellDef> {{ 'labels.heading.originalLoan' | translate }} </th>
        <td mat-cell *matCellDef="let element"> {{element.originalLoan}} </td>
      </ng-container>

      <ng-container matColumnDef="Loan Balance">
        <th mat-header-cell *matHeaderCellDef> {{ 'labels.heading.loanBalance' | translate }} </th>
        <td mat-cell *matCellDef="let element"> {{element.loanBalance}} </td>
      </ng-container>

      <ng-container matColumnDef="Amount Paid">
        <th mat-header-cell *matHeaderCellDef> {{ 'labels.heading.amountPaid' | translate }} </th>
        <td mat-cell *matCellDef="let element"> {{element.amountPaid}} </td>
      </ng-container>

      <ng-container matColumnDef="Type">
        <th mat-header-cell *matHeaderCellDef> {{ 'labels.inputs.type' | translate }}</th>
        <td mat-cell *matCellDef="let element">
          <i class="fa fa-large" [ngClass]="(element.loanType.value==='Individual')?'fa-user':'fa-group'"
          [matTooltip]="((element.loanType.value==='Individual')?'labels.heading.individual':'labels.heading.group') | translate"></i>
        </td>
      </ng-container>

      <ng-container matColumnDef="Actions">
        <th mat-header-cell *matHeaderCellDef> {{ 'labels.heading.actions' | translate }}</th>
        <td mat-cell *matCellDef="let element">
          <button *ngIf="element.status.active" class="account-action-button" mat-raised-button color="primary">
            <i class="fa fa-dollar" [matTooltip]="'labels.buttons.makerepayment' | translate"></i>
          </button>
          <button *ngIf="element.status.pendingApproval" class="account-action-button" mat-raised-button color="primary">
            <i class="fa fa-check" [matTooltip]="'labels.anchors.approve'| translate"></i>
          </button>
          <button *ngIf="!element.status.pendingApproval && !element.status.active && !element.status.overpaid"
            class="account-action-button" mat-raised-button color="primary">
            <i class="fa fa-flag" [matTooltip]="'labels.anchors.disburse'| translate"></i>
          </button>
          <button *ngIf="!element.status.pendingApproval && !element.status.active && element.status.overpaid"
            class="account-action-button" mat-raised-button color="primary">
            <i class="fa fa-exchange" [matTooltip]="'labels.buttons.transferFunds'| translate"></i>
          </button>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="openLoansColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: openLoansColumns;" [routerLink]="['loans-accounts', row.id, 'general']"></tr>

    </table>

    <!-- Closed Loan Accounts Table-->
    <table *ngIf="showClosedLoanAccounts" mat-table [dataSource]="loanAccounts|accountsFilter:'loan':'closed'"
      class="mat-elevation-z1 m-b-30">

      <ng-container matColumnDef="Account No">
        <th mat-header-cell *matHeaderCellDef> {{ 'labels.accountNo' | translate }} </th>
        <td mat-cell *matCellDef="let element"> <i class="fa fa-stop"
            [ngClass]="element.inArrears?'status-active-overdue':(element.status.code|statusLookup)"
            [matTooltip]="element.inArrears?('labels.heading.active' | translate)+' '+('labels.heading.overdue' | translate):element.status.value"></i>
          {{element.accountNo}}
        </td>
      </ng-container>

      <ng-container matColumnDef="Loan Account">
        <th mat-header-cell *matHeaderCellDef> {{ 'labels.buttons.loanaccount' | translate }} </th>
        <td mat-cell *matCellDef="let element"> {{element.productName}} </td>
      </ng-container>

      <ng-container matColumnDef="Original Loan">
        <th mat-header-cell *matHeaderCellDef> {{ 'labels.heading.originalLoan' | translate }} </th>
        <td mat-cell *matCellDef="let element"> {{element.lastActiveTransactionDate  | dateFormat}} </td>
      </ng-container>

      <ng-container matColumnDef="Loan Balance">
        <th mat-header-cell *matHeaderCellDef> {{ 'labels.heading.loanBalance' | translate }} </th>
        <td mat-cell *matCellDef="let element"> {{element.loanBalance}} </td>
      </ng-container>

      <ng-container matColumnDef="Amount Paid">
        <th mat-header-cell *matHeaderCellDef> {{ 'labels.heading.amountPaid' | translate }} </th>
        <td mat-cell *matCellDef="let element"> {{element.accountBalance}} </td>
      </ng-container>

      <ng-container matColumnDef="Type">
        <th mat-header-cell *matHeaderCellDef> {{ 'labels.inputs.type' | translate }}</th>
        <td mat-cell *matCellDef="let element">
          <i class="fa fa-large" [ngClass]="(element.loanType.value=== 'Individual')?'fa-user':'fa-group'"
          [matTooltip]="((element.loanType.value==='Individual')?'labels.heading.individual':'labels.heading.group') | translate"></i>
        </td>
      </ng-container>

      <ng-container matColumnDef="Closed Date">
        <th mat-header-cell *matHeaderCellDef> {{ 'labels.heading.closedate' | translate }} </th>
        <td mat-cell *matCellDef="let element">{{element.timeline.closedOnDate  | dateFormat}}</td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="closedLoansColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: closedLoansColumns;" [routerLink]="['../', 'loans-accounts', row.id, 'general']"></tr>

    </table>

  </div>

  <div *ngIf="gsimAccounts">

    <div fxLayout="row" fxLayoutAlign="start" class="table-header">
      <div class="m-b-10">
        <h3>{{ 'labels.heading.gsimaccountoverview' | translate }}</h3>
      </div>
    </div>

    <!-- GSIM Accounts Table -->
    <table mat-table [dataSource]="gsimAccounts"
      class="mat-elevation-z1 m-b-30">

      <ng-container matColumnDef="GSIM Id">
        <th mat-header-cell *matHeaderCellDef> {{ 'labels.heading.gsimId' | translate }} </th>
        <td mat-cell *matCellDef="let element">
          {{element.gsimId}} </td>
      </ng-container>

      <ng-container matColumnDef="Account Number">
        <th mat-header-cell *matHeaderCellDef> {{ 'labels.inputs.accno' | translate }}</th>
        <td mat-cell *matCellDef="let element"> {{element.accountNumber}} </td>
      </ng-container>

      <ng-container matColumnDef="Product">
        <th mat-header-cell *matHeaderCellDef> {{ 'labels.inputs.product' | translate }}</th>
        <td mat-cell *matCellDef="let element"> {{element.childGSIMAccounts[0].productName}}</td>
      </ng-container>

      <ng-container matColumnDef="Balance">
        <th mat-header-cell *matHeaderCellDef> {{ 'labels.heading.balance' | translate }}</th>
        <td mat-cell *matCellDef="let element"> {{element.parentBalance}} </td>
      </ng-container>

      <ng-container matColumnDef="Status">
        <th mat-header-cell *matHeaderCellDef> {{ 'labels.inputs.status' | translate }}</th>
        <td mat-cell *matCellDef="let element"> {{element.savingsStatus}} </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="gsimAccountsColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: gsimAccountsColumns;" [routerLink]="['savings-accounts', 'gsim-account', row.accountNumber]"></tr>

    </table>

  </div>

  <div *ngIf="glimAccounts">

    <div fxLayout="row" fxLayoutAlign="start" class="table-header">
      <div class="m-b-10">
        <h3>{{ 'labels.heading.glimloanaccoverview' | translate }}</h3>
      </div>
    </div>

    <!-- GLIM Loans Accounts Table -->
    <table mat-table [dataSource]="glimAccounts"
      class="mat-elevation-z1 m-b-30">

      <ng-container matColumnDef="GLIM Id">
        <th mat-header-cell *matHeaderCellDef> {{ 'labels.heading.glimId' | translate }} </th>
        <td mat-cell *matCellDef="let element">
          {{element.glimId}} </td>
      </ng-container>

      <ng-container matColumnDef="Account Number">
        <th mat-header-cell *matHeaderCellDef> {{ 'labels.inputs.accno' | translate }}</th>
        <td mat-cell *matCellDef="let element"> {{element.accountNumber}} </td>
      </ng-container>

      <ng-container matColumnDef="Product">
        <th mat-header-cell *matHeaderCellDef> {{ 'labels.inputs.product' | translate }}</th>
        <td mat-cell *matCellDef="let element"> {{element.childGLIMAccounts[0].productName}} </td>
      </ng-container>

      <ng-container matColumnDef="Original Loan">
        <th mat-header-cell *matHeaderCellDef> {{ 'labels.heading.originalLoan' | translate }} </th>
        <td mat-cell *matCellDef="let element"> {{element.parentPrincipalAmount}} </td>
      </ng-container>

      <ng-container matColumnDef="Status">
        <th mat-header-cell *matHeaderCellDef> {{ 'labels.inputs.status' | translate }}</th>
        <td mat-cell *matCellDef="let element">{{element.loanStatus}} </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="glimAccountsColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: glimAccountsColumns;" [routerLink]="['loans-accounts', 'glim-account', row.glimId, row.accountNumber]"></tr>

    </table>

  </div>

  <div *ngIf="savingAccounts">

    <div fxLayout="row" fxLayoutAlign="start" class="table-header">
      <div class="m-b-10">
        <h3>{{ 'labels.buttons.savingaccounts' | translate }}</h3>
      </div>
      <div class="action-button m-b-10">
        <button *ngIf="(savingAccounts|accountsFilter:'saving':'closed').length" mat-raised-button class="f-right" color="primary"
        (click)="toggleSavingAccountsOverview()">{{(showClosedSavingAccounts?'labels.buttons.viewActiveAccounts':'labels.buttons.viewClosedAccounts') | translate}}</button>
      </div>
    </div>

    <!-- Open Savings Accounts Table -->
    <table *ngIf="!showClosedSavingAccounts" mat-table [dataSource]="savingAccounts|accountsFilter:'saving'"
      class="mat-elevation-z1 m-b-30">

      <ng-container matColumnDef="Account No">
        <th mat-header-cell *matHeaderCellDef> {{ 'labels.accountNo' | translate }} </th>
        <td mat-cell *matCellDef="let element"> <i class="fa fa-stop" [ngClass]="element.status.code|statusLookup" [matTooltip]="element.status.value"></i>
          {{element.accountNo}} </td>
      </ng-container>

      <ng-container matColumnDef="Saving Account">
        <th mat-header-cell *matHeaderCellDef> {{ 'labels.buttons.savingaccount' | translate }}</th>
        <td mat-cell *matCellDef="let element"> {{element.productName}} </td>
      </ng-container>

      <ng-container matColumnDef="Last Active">
        <th mat-header-cell *matHeaderCellDef> {{ 'labels.heading.lastActive' | translate }} </th>
        <td mat-cell *matCellDef="let element"> {{element.lastActiveTransactionDate  | dateFormat}} </td>
      </ng-container>

      <ng-container matColumnDef="Balance">
        <th mat-header-cell *matHeaderCellDef> {{ 'labels.heading.balance' | translate }}</th>
        <td mat-cell *matCellDef="let element"> {{element.accountBalance}} </td>
      </ng-container>

      <ng-container matColumnDef="Actions">
        <th mat-header-cell *matHeaderCellDef> {{ 'labels.heading.actions' | translate }}</th>
        <td mat-cell *matCellDef="let element">
          <button *ngIf="element.status.active" class="account-action-button" mat-raised-button color="primary"
            (click)="routeEdit($event)" [routerLink]="['../','savings-accounts', element.id, 'actions', 'Deposit']">
            <i class="fa fa-arrow-up" [matTooltip]="'labels.buttons.deposit' | translate"></i>
          </button>
          <button *ngIf="element.status.active" class="account-action-button" mat-raised-button color="primary"
            (click)="routeEdit($event)" [routerLink]="['../','savings-accounts', element.id, 'actions', 'Withdrawal']">
            <i class="fa fa-arrow-down" [matTooltip]="'labels.anchors.withdraw' | translate"></i>
          </button>
          <button *ngIf="element.status.submittedAndPendingApproval" class="account-action-button" mat-raised-button color="primary"
            (click)="routeEdit($event)" [routerLink]="['../','savings-accounts', element.id, 'actions', 'Approve']">
            <i class="fa fa-check" [matTooltip]="'labels.anchors.approve' | translate"></i>
          </button>
          <button *ngIf="!element.status.submittedAndPendingApproval && !element.status.active"
            class="account-action-button" mat-raised-button color="primary"
            (click)="routeEdit($event)" [routerLink]="['../','savings-accounts', element.id, 'actions', 'Undo Approval']">
            <i class="fa fa-undo" [matTooltip]="'labels.anchors.undoapproval' | translate"></i>
          </button>
          <button *ngIf="!element.status.submittedAndPendingApproval && !element.status.active"
            class="account-action-button" mat-raised-button color="primary"
            (click)="routeEdit($event)" [routerLink]="['../','savings-accounts', element.id, 'actions', 'Activate']">
            <i class="fa fa-check-circle" [matTooltip]="'labels.anchors.activate' | translate"></i>
          </button>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="openSavingsColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: openSavingsColumns;" [routerLink]="['savings-accounts', row.id, 'transactions']"></tr>

    </table>

    <!-- Closed Saving Accounts Table -->
    <table *ngIf="showClosedSavingAccounts" mat-table [dataSource]="savingAccounts|accountsFilter:'saving':'closed'"
      class="mat-elevation-z1 m-b-30">

      <ng-container matColumnDef="Account No">
        <th mat-header-cell *matHeaderCellDef> {{ 'labels.accountNo' | translate }} </th>
        <td mat-cell *matCellDef="let element"> <i class="fa fa-stop" [ngClass]="element.status.code|statusLookup" [matTooltip]="element.status.value"></i>
          {{element.accountNo}} </td>
      </ng-container>

      <ng-container matColumnDef="Saving Account">
        <th mat-header-cell *matHeaderCellDef> {{ 'labels.buttons.savingaccount' | translate }}</th>
        <td mat-cell *matCellDef="let element"> {{element.productName}} </td>
      </ng-container>

      <ng-container matColumnDef="Closed Date">
        <th mat-header-cell *matHeaderCellDef> {{ 'labels.heading.closedate' | translate }} </th>
        <td mat-cell *matCellDef="let element">{{element.timeline.closedOnDate  | dateFormat}}</td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="closedSavingsColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: closedSavingsColumns;" [routerLink]="['savings-accounts', row.id, 'transactions']"></tr>

    </table>

  </div>

</div>
