/** Angular Imports  */
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { SystemService } from '../../system.service';
import * as _ from 'lodash';

/** Custom Components */
import { DeleteDialogComponent } from '../../../shared/delete-dialog/delete-dialog.component';
import { DisableDialogComponent } from '../../../shared/disable-dialog/disable-dialog.component';
import { EnableDialogComponent } from '../../../shared/enable-dialog/enable-dialog.component';
import getPermissions from './getPermissions';

/**
 * View Role and Permissions Component
 */
@Component({
  selector: 'mifosx-view-role-fields-permissions',
  templateUrl: './view-role-fields-permissions.component.html',
  styleUrls: ['./view-role-fields-permissions.component.scss']
})
export class ViewRoleFieldsPermissionsComponent implements OnInit {

  /** Role Permissions Data */
  rolePermissionService: any;
  /** Stores the current grouping */
  currentGrouping: string;
  /** Stores the previous grouping */
  previousGrouping = '';
  /** Stores Grouping Data */
  groupings: string[] = [];
  /** Stores the selected role */
  selectedItem = '';
  /** Checks if its disabled */
  isDisabled: Boolean = true;
  /** Checks if there is any change in data */
  checkboxesChanged: Boolean = false;
  /** Stores backup values */
  bValuesOnly: string[] = [];
  /** Role ID */
  roleId: any;
  /** Creates permission form  */
  formGroup: FormGroup;
  /** Creates Backup form */
  backupform: FormGroup;
  /** Temporarily stores Permission data */
  tempPermissionUIData: {};

  tempFieldsUIData: any = {};
  /** Stores permissions */
  permissions: {
    permissions: { code: string, id: number }[]
  };

  /**
   * Retrieves the roledetails data from `resolve`.
   * @param {ActivatedRoute} route Activated Route.
   * @param {SystemService} systemService System Service.
   * @param {Router} router Router for navigation.
   * @param {FormBuilder} formBuilder Form Builder.
   * @param {MatDialog} dialog Shared Dialog Boxes.
   */
  constructor(private route: ActivatedRoute,
    private systemService: SystemService,
    private router: Router,
    private formBuilder: FormBuilder,
    public dialog: MatDialog) {
    this.route.data.subscribe((data: { roledetails: any }) => {
      this.rolePermissionService = this.getPermissionsAsList({
      ...data.roledetails,
      permissionFieldUsageData: data.roledetails.permissions,
    });
    delete this.rolePermissionService.permissions;
    });

    // this.rolePermissionService = this.getPermissionsAsList({
    //   ...getPermissions,
    //   permissionFieldUsageData: getPermissions.permissions,
    // });
    // delete this.rolePermissionService.permissions;

  }

  getPermissionsAsList(permissions:any){
    const data = {
      ...permissions,
      permissionFieldUsageData: [],
    }
    // TODO : if you have two different slected you will have to update this function

    permissions.permissionFieldUsageData.forEach((permission:any) => {
      permission.fieldsData.forEach((field:any) => {
        data.permissionFieldUsageData.push({
          ...permission,
          ...field,
          fieldsData: undefined
        })
      });
    });
    return data;
  }

  formatToSubmit(fields:any[]){

    let data = {};

    fields.forEach((field:any)=>{

      data[field.code] = data[field.code] ?? {}
      data[field.code] = {
        ...data[field.code],
        [field.name]:field.selected
      }
    })
    return {permissions:data};
  }

  /**
   * Groups all the data on init
   */
  ngOnInit() {
    this.permissions = {
      permissions: []
    };
    this.createForm();
    this.groupRules();
    this.selectedItem = Object.entries(this.tempPermissionUIData)[0][0];
    this.showPermissions(this.selectedItem);
    this.route.params.subscribe((routeParams: any) => {
      this.roleId = routeParams.id;
    });
  }

  /**
   * creates the form to display and edit permissions
   */
  createForm() {
    this.formGroup = this.formBuilder.group({
      roster: this.formBuilder.array(this.rolePermissionService.permissionFieldUsageData.map((elem: any) => this.createMemberGroup(elem)))
    });

  }

  createMemberGroup(permission: any): FormGroup {
    return this.formBuilder.group({
      ...permission,
      ...{
        code: [permission.code, Validators.required],
        selected: [{ value: permission.selected, disabled: true }, Validators.required]
      }
    });
  }

  /**
   * Groups the permissions based on rules
   */
  groupRules() {
    this.tempPermissionUIData = {};
    for (const i in this.rolePermissionService.permissionFieldUsageData) {
      if (this.rolePermissionService.permissionFieldUsageData[i]) {
        if (this.groupings.indexOf(this.rolePermissionService.permissionFieldUsageData[i].grouping)===-1) {
          this.currentGrouping = this.rolePermissionService.permissionFieldUsageData[i].grouping;
          this.groupings.push(this.currentGrouping);
          this.tempPermissionUIData[this.currentGrouping] = { permissions: [] };
        }
        const temp = { ...this.rolePermissionService.permissionFieldUsageData[i], id: i };
        // this.tempPermissionUIData[this.currentGrouping].permissions.push(temp);
        if (temp.name==='ALL_FIELD') {
          this.tempPermissionUIData[temp.grouping].permissions.push(temp);
          // this.tempFieldsUIData[temp.code] = { fields: [] }
        }
        this.tempFieldsUIData[temp.code] = this.tempFieldsUIData[temp.code] ?? { fields: [] };
        this.tempFieldsUIData[temp.code].fields.push({ ...this.rolePermissionService.permissionFieldUsageData[i], id: i });
      }
    }
  }

  /**
   * Displays the permission for selected role
   * @param grouping Selected Role
   */
  showPermissions(grouping: string) {
    this.permissions = this.tempPermissionUIData[grouping];
    this.selectedItem = grouping;
    this.previousGrouping = grouping;
  }

  /**
   * Formats the Role Name
   * @param string String
   */
  formatName(string: any) {
    if (string.indexOf('portfolio_') > -1) {
      string = string.replace('portfolio_', '');
    }
    if (string.indexOf('transaction_') > -1) {
      const temp = string.split('_');
      string = temp[1] + ' ' + temp[0].charAt(0).toUpperCase() + temp[0].slice(1) + 's';
    }
    string = string.charAt(0).toUpperCase() + string.slice(1);
    return string;
  }

  /**
   * Formats the permission from permission code
   * @param name String
   */
  permissionName(name: any) {
    name = name || '';
    // replace '_' with ' '
    name = name.replace(/_/g, ' ');
    // for reorts replace read with view
    if (this.previousGrouping === 'report') {
      name = name.replace(/READ/g, 'View');
    }
    return name;
  }

  /**
   * Backups the valued
   */
  backupCheckValues() {
    this.backupform = _.cloneDeep(this.formGroup) as FormGroup;
  }

  /**
   * Restores the checkboxes to previous data on clicking cancel
   */
  restoreCheckboxes() {
    this.formGroup = _.cloneDeep(this.backupform) as FormGroup;
  }

  isRoleEnable(value: any) {
    return value;
  }

  editRoles() {
    this.isDisabled = false;
    this.formGroup.controls.roster.enable();
  }

  /**
   * Cancel the changes
   */
  cancel() {
    this.isDisabled = true;
    this.formGroup.controls.roster.disable();
  }

  /**
   * Submits the modified permissions
   */
  checkAllFields(checked: boolean, code: string) {
    this.formGroup.get('roster').value.forEach((element: any, index: number) => {
      if (element.code === code) {
        this.formGroup.get('roster').get(index.toString()).setValue({
          ...element,
          selected: checked
        })
      }
    });
  }

  checkifAllSelected(code: string) {
    const allselected = !this.formGroup.get('roster').value
    .filter((item:any)=>item.code===code && item.name !== 'ALL_FIELD')
    .some((element: any) => element.selected === false);

    const index = this.formGroup.get('roster').value.findIndex((item:any)=>item.code === code && item.name==='ALL_FIELD');
    this.formGroup.get('roster').get(index.toString()).setValue({
      ...this.formGroup.get('roster').get(index.toString()).value,
      selected: allselected
    })

  }
  submit() {
    const value = this.formGroup.get('roster').value;
    const permissionData = this.formatToSubmit(value);
    this.formGroup.controls.roster.disable();
    this.checkboxesChanged = false;
    this.isDisabled = true;
    this.systemService.updateRolePermissionsFields(this.roleId, permissionData).subscribe((response: any) => {
      console.log('response: ', response);
    });
  }

  /**
   * Selects all the permission of a particular role
   */
  selectAll() {
    for (let i = 0; i < this.permissions.permissions.length; i++) {
      this.formGroup.controls.roster['controls'][this.permissions.permissions[i].id].patchValue({
        selected: true
      });
    }
  }

  /**
   * Deselects all the permissions of a particular role
   */
  deselectAll() {
    for (let i = 0; i < this.permissions.permissions.length; i++) {
      this.formGroup.controls.roster['controls'][this.permissions.permissions[i].id].patchValue({
        selected: false
      });
    }
  }

  /**
   * Deletes the Role and redirects to Roles and Permissions.
   */
  deleteRole() {
    const deleteRoleDialogRef = this.dialog.open(DeleteDialogComponent, {
      data: { deleteContext: `labels.inputs.role`,code: ` ${this.roleId}` }
    });
    deleteRoleDialogRef.afterClosed().subscribe((response: any) => {
      if (response.delete) {
        this.systemService.deleteRole(this.roleId)
          .subscribe(() => {
            this.router.navigate(['/system/roles-and-permissions']);
          });
      } else {

      }
    });
  }

  /**
   * Enables the Role and redirects to Roles and Permissions.
   */
  enableRolesConfirmation() {
    const enableRoleDialogRef = this.dialog.open(EnableDialogComponent, {
      data: { enableContext: `labels.inputs.role` ,code:` ${this.roleId}` }
    });
    enableRoleDialogRef.afterClosed().subscribe((response: any) => {
      if (response.enable) {
        this.systemService.enableRole(this.roleId)
          .subscribe(() => {
            this.router.navigate(['/system/roles-and-permissions']);
          });
      } else {

      }
    });
  }

  /**
   * Disables the Role and redirects to Roles and Permissions.
   */
  disableRolesConfirmation() {
    const deleteRoleDialogRef = this.dialog.open(DisableDialogComponent, {
      data: { disableContext: `labels.inputs.role` ,code:` ${this.roleId}` }
    });
    deleteRoleDialogRef.afterClosed().subscribe((response: any) => {
      if (response.disable) {
        this.systemService.disableRole(this.roleId)
          .subscribe(() => {
            this.router.navigate(['/system/roles-and-permissions']);
          });
      } else {

      }
    });
  }

}
