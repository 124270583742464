<div class="add-family-member-container">

  <form [formGroup]="addFamilyMemberForm" (ngSubmit)="submit()">

    <div fxLayout="row wrap" fxLayout.lt-md="column" fxLayoutAlign="start center">

      <mat-form-field fxFlex="calc(50% - 25px)">
        <mat-label>{{ 'labels.inputs.firstname' | translate }}</mat-label>
        <input formControlName="firstName" required matInput />
        <mat-error *ngIf="addFamilyMemberForm.controls.firstName.hasError('required')">
          {{ 'labels.inputs.firstname' | translate }} <strong>{{'labels.commons.is required' | translate}}</strong>
        </mat-error>
      </mat-form-field>

      <mat-form-field fxFlex="calc(50% - 25px)">
        <mat-label>{{ 'labels.inputs.middlename' | translate }}</mat-label>
        <input formControlName="middleName" matInput />
      </mat-form-field>

      <mat-form-field fxFlex="calc(50% - 25px)">
        <mat-label>{{ 'labels.inputs.lastname' | translate }}</mat-label>
        <input formControlName="lastName" required matInput />
        <mat-error *ngIf="addFamilyMemberForm.controls.lastName.hasError('required')">
          {{ 'labels.inputs.lastname' | translate }} <strong>{{'labels.commons.is required' | translate}}</strong>
        </mat-error>
      </mat-form-field>

      <mat-form-field fxFlex="calc(50% - 25px)">
        <mat-label>{{ 'labels.inputs.qualification' | translate }}</mat-label>
        <input formControlName="qualification" matInput />
      </mat-form-field>

      <mat-form-field fxFlex="calc(50% - 25px)">
        <mat-label>{{ 'labels.inputs.age' | translate }}</mat-label>
        <input type="number" formControlName="age" required matInput />
        <mat-error *ngIf="addFamilyMemberForm.controls.age.hasError('required')">
          {{ 'labels.inputs.age' | translate }} <strong>{{'labels.commons.is required' | translate}}</strong>
        </mat-error>
      </mat-form-field>

      <mat-checkbox formControlName="isDependent" labelPosition="before" fxFlex="calc(50% - 25px)">{{
        'labels.inputs.isDependent' | translate }}?
      </mat-checkbox>

      <mat-form-field fxFlex="calc(50% - 25px)">
        <mat-label>{{ 'labels.inputs.relationship' | translate }}</mat-label>
        <mat-select formControlName="relationshipId" required>
          <mat-option *ngFor="let relation of addFamilyMemberTemplate.relationshipIdOptions" [value]="relation.id">
            {{ relation.name }}
          </mat-option>
        </mat-select>
        <mat-error *ngIf="addFamilyMemberForm.controls.relationshipId.hasError('required')">
          {{ 'labels.inputs.relationship' | translate }} <strong>{{'labels.commons.is required' | translate}}</strong>
        </mat-error>
      </mat-form-field>

      <mat-form-field fxFlex="calc(50% - 25px)">
        <mat-label>{{ 'labels.inputs.gender' | translate }}</mat-label>
        <mat-select formControlName="genderId" required>
          <mat-option *ngFor="let gender of addFamilyMemberTemplate.genderIdOptions" [value]="gender.id">
            {{ gender.name }}
          </mat-option>
        </mat-select>
        <mat-error *ngIf="addFamilyMemberForm.controls.genderId.hasError('required')">
          {{ 'labels.inputs.gender' | translate }} <strong>{{'labels.commons.is required' | translate}}</strong>
        </mat-error>
      </mat-form-field>

      <mat-form-field fxFlex="calc(50% - 25px)">
        <mat-label>{{ 'labels.inputs.profession' | translate }}</mat-label>
        <mat-select formControlName="professionId">
          <mat-option *ngFor="let profession of addFamilyMemberTemplate.professionIdOptions" [value]="profession.id">
            {{ profession.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field fxFlex="calc(50% - 25px)">
        <mat-label>{{ 'labels.inputs.maritalstatus' | translate }}</mat-label>
        <mat-select formControlName="maritalStatusId">
          <mat-option *ngFor="let maritalStatus of addFamilyMemberTemplate.maritalStatusIdOptions"
            [value]="maritalStatus.id">
            {{ maritalStatus.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>


      <mat-form-field fxFlex (click)="dueDatePicker.open()">
        <mat-label>{{ 'labels.inputs.dateofbirth' | translate }}</mat-label>
        <input formControlName="dateOfBirth" matInput [min]="minDate" [max]="maxDate" [matDatepicker]="dueDatePicker"
          required>
        <mat-datepicker-toggle matSuffix [for]="dueDatePicker"></mat-datepicker-toggle>
        <mat-datepicker #dueDatePicker></mat-datepicker>
        <mat-error *ngIf="addFamilyMemberForm.controls.dateOfBirth.hasError('required')">
          {{ 'labels.inputs.dateofbirth' | translate }} <strong>{{'labels.commons.is required' | translate}}</strong>
        </mat-error>
      </mat-form-field>

    </div>

    <div fxLayout="row" fxLayoutGap="32px" fxLayoutAlign="center">
      <button type="button" mat-raised-button [routerLink]="['../']">{{ 'labels.buttons.cancel' | translate }}</button>
      <button type="submit" mat-raised-button color="primary" [disabled]="!addFamilyMemberForm.valid">{{
        'labels.buttons.submit' | translate }}</button>
    </div>

  </form>

</div>