<mat-card-header fxLayout="row" fxLayoutGap="5%">
  <fa-icon class="main-icon" icon="users" size="3x"></fa-icon>
  <mat-card-title-group>
    <div class="mat-typography">
      <mat-card-title>
        <h2>
          {{ groupData.name }}
          <span [className]="groupData.status.code | statusLookup">
            <fa-icon matTooltip="{{ groupData.status.value }}" matTooltipPosition="right" icon="circle" size="lg"></fa-icon>
          </span>
        </h2>
      </mat-card-title>
      <mat-card-subtitle>
        <p>
          {{"labels.accountNo" | translate}}: {{ groupData.accountNo }} {{ groupData.externalId ? '| External ID: ' + groupData.externalId : '' }}
        </p>
      </mat-card-subtitle>
    </div>
  </mat-card-title-group>
</mat-card-header>

<mat-card-content>
    <mat-tab-group>
      <mat-tab [label]="'labels.buttons.details' | translate">
        <div fxLayout="row wrap" class="content">

          <div fxFlex="50%" class="mat-body-strong">
            {{"labels.inputs.activationdate" | translate}}
          </div>

          <div fxFlex="50%">
            {{ groupData.activationDate  | dateFormat }}
          </div>

          <div fxFlex="50%" class="mat-body-strong">
            {{"labels.associatedOfficer" | translate}}
          </div>

          <div fxFlex="50%">
            {{ groupData.staffName }}
          </div>

          <div fxFlex="50%" class="mat-body-strong" *ngIf="groupData.centerName">
            {{"labels.associatedCenter" | translate}}
          </div>

          <div fxFlex="50%" *ngIf="groupData.centerName">
            {{ groupData.centerName }}
          </div>

          <div fxFlex="50%" class="mat-body-strong" *ngIf="groupData.collectionMeetingCalendar && groupData.collectionMeetingCalendar.nextTenRecurringDates[0]">
            {{"labels.nextMeetingDate" | translate}}
          </div>

          <div fxFlex="50%" *ngIf="groupData.collectionMeetingCalendar && groupData.collectionMeetingCalendar.nextTenRecurringDates[0]">
            {{ groupData.collectionMeetingCalendar.nextTenRecurringDates[0]  | dateFormat }}
          </div>

          <div fxFlex="50%" class="mat-body-strong" *ngIf="groupData.collectionMeetingCalendar && groupData.collectionMeetingCalendar.humanReadable">
            {{"labels.heading.meetingfrequency" | translate}}
          </div>

          <div fxFlex="50%" *ngIf="groupData.collectionMeetingCalendar && groupData.collectionMeetingCalendar.humanReadable">
            {{ groupData.collectionMeetingCalendar.humanReadable }}
          </div>

          <div fxFlex="50%" class="mat-body-strong">
            {{"labels.numberClients" | translate}}
          </div>

          <div fxFlex="50%">
            {{ clientData ? clientData.length : '' }}
          </div>

        </div>
      </mat-tab>

      <mat-tab [label]="'labels.buttons.loanaccounts' | translate" *ngIf="groupAccountsData && groupAccountsData.loanAccounts">
        <mifosx-loan-account-table [loanAccountData]="groupAccountsData.loanAccounts"></mifosx-loan-account-table>
      </mat-tab>

      <mat-tab [label]="'labels.buttons.savingaccounts' | translate" *ngIf="groupAccountsData && groupAccountsData.savingsAccounts">
        <mifosx-savings-account-table [savingsAccountData]="groupAccountsData.savingsAccounts"></mifosx-savings-account-table>
      </mat-tab>

    </mat-tab-group>
</mat-card-content>
