/** Angular Imports */
import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

/** Custom Services */
import { LoansService } from 'app/loans/loans.service';
import { SettingsService } from 'app/settings/settings.service';
import { Dates } from 'app/core/utils/dates';
import { AccountTransfersService } from 'app/account-transfers/account-transfers.service';
import { ClientsService } from 'app/clients/clients.service';

@Component({
  selector: 'mifosx-repayment',
  templateUrl: './repayment.component.html',
  styleUrls: ['./repayment.component.scss']
})
export class RepaymentComponent implements OnInit {

  @Input() dataObject: any;
  /** Loan Id */
  loanId: string;
  /** Payment Type Options */
  paymentTypes: any;
  /** Show payment details */
  showPaymentDetails = false;
  /** Minimum Date allowed. */
  minDate = new Date(2000, 0, 1);
  /** Maximum Date allowed. */
  maxDate = new Date();
  /** Repayment Loan Form */
  repaymentLoanForm: FormGroup;
  /** From Account Data */
  accountData: any;
  /** From Clients Data */
  clientsData: any;

  /**
   * @param {FormBuilder} formBuilder Form Builder.
   * @param {LoansService} loanService Loan Service.
   * @param {ActivatedRoute} route Activated Route.
   * @param {Router} router Router for navigation.
   * @param {SettingsService} settingsService Settings Service
   */
  constructor(private formBuilder: FormBuilder,
    private loanService: LoansService,
    private route: ActivatedRoute,
    private router: Router,
    private dateUtils: Dates,
    private settingsService: SettingsService,
    private accountTransfersService: AccountTransfersService,
    private clientsService: ClientsService,
    private loansService: LoansService) {
      this.loanId = this.route.parent.snapshot.params['loanId'];
    }

  /**
   * Creates the repayment loan form
   * and initialize with the required values
   */
  ngOnInit() {
    this.maxDate = this.settingsService.businessDate;
    this.createRepaymentLoanForm();
  }

  /**
   * Subscribes to Clients search filter:
   */
   ngAfterViewInit() {
    this.repaymentLoanForm.controls.fromClientId.valueChanges.subscribe((value: string) => {
      if (value.length >= 2) {
        this.clientsService.getFilteredClients('displayName', 'ASC', false, value)
          .subscribe((data: any) => {
            console.log(data);
            this.clientsData = data.pageItems;
          });
      }
    });
  }

  /**
   * Creates the create close form.
   */
  createRepaymentLoanForm() {
    this.repaymentLoanForm = this.formBuilder.group({
      'fromClientId': ['', Validators.required],
      'fromAccountId': ['', Validators.required],
      'transactionDate': [new Date(), Validators.required],
      'transactionAmount': ['', Validators.required],
      'paymentTypeId': '',
      'note': ''
    });
  }

  /**
   * Add payment detail fields to the UI.
   */
  addPaymentDetails() {
    this.showPaymentDetails = !this.showPaymentDetails;
    if (this.showPaymentDetails) {
      this.repaymentLoanForm.addControl('accountNumber', new FormControl(''));
      this.repaymentLoanForm.addControl('checkNumber', new FormControl(''));
      this.repaymentLoanForm.addControl('routingCode', new FormControl(''));
      this.repaymentLoanForm.addControl('receiptNumber', new FormControl(''));
      this.repaymentLoanForm.addControl('bankNumber', new FormControl(''));
    } else {
      this.repaymentLoanForm.removeControl('accountNumber');
      this.repaymentLoanForm.removeControl('checkNumber');
      this.repaymentLoanForm.removeControl('routingCode');
      this.repaymentLoanForm.removeControl('receiptNumber');
      this.repaymentLoanForm.removeControl('bankNumber');
    }
  }

  /**
   * Displays Client name in form control input.
   * @param {any} client Client data.
   * @returns {string} Client name if valid otherwise undefined.
   */
   displayClient(client: any): string | undefined {
    return client ? client.displayName : undefined;
  }

  accountChangeEvent() {
    this.loanId = this.repaymentLoanForm.value.fromAccountId;
    this.loansService.getLoanActionTemplate(this.loanId, 'payoutRefund').subscribe((response: any) => {
      this.paymentTypes = response.paymentTypeOptions;
    });
  }

  clientChange(event:any){
    const client=event.option.value
    this.accountTransfersService.savingsAccountTransfer(client.id, 1).subscribe((response: any) => {
      this.accountData = response.fromAccountOptions;
    });
  }

  /** Submits the repayment form */
  submit() {
    const repaymentLoanFormData = this.repaymentLoanForm.value;
    const locale = this.settingsService.language.code;
    const dateFormat = 'yyyy-MM-dd';
    const prevTransactionDate: Date = this.repaymentLoanForm.value.transactionDate;
    const urlRedirection = '/clients/'+repaymentLoanFormData.fromClientId.id+'/loans-accounts/'+this.loanId+'/transactions';
    delete repaymentLoanFormData.fromClientId;
    delete repaymentLoanFormData.fromAccountId;
    if (repaymentLoanFormData.transactionDate instanceof Date) {
      repaymentLoanFormData.transactionDate = this.dateUtils.formatDate(prevTransactionDate, dateFormat);
    }
    const data = {
      ...repaymentLoanFormData,
      dateFormat,
      locale
    };
    const command = 'payoutRefund';
    this.loanService.submitLoanActionButton(this.loanId, data, command)
      .subscribe((response: any) => {
        this.router.navigate(['../../..'+urlRedirection], { relativeTo: this.route });
    });
  }

}
