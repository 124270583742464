<div class="container">

  <mat-card>

    <div fxLayout="row" fxLayout.lt-md="column">

      <div fxFlex="50%">

        <mat-nav-list>

          <div #office>
            <mat-list-item [routerLink]="['offices']" *mifosxHasPermission="'READ_OFFICE'">
              <mat-icon matListIcon>
                <fa-icon icon="building" size="sm"></fa-icon>
              </mat-icon>
              <h4 matLine>{{'labels.anchors.manageoffices' | translate}}</h4>
              <p matLine>{{'labels.addnewofficeormodifyordeactivateofficeormodifyofficeheirarchy'
                | translate}}</p>
            </mat-list-item>
          </div>

          <div #holidays>
            <mat-list-item [routerLink]="['holidays']" *mifosxHasPermission="'READ_HOLIDAY'">
              <mat-icon matListIcon>
                <fa-icon icon="calendar-alt" size="sm"></fa-icon>
              </mat-icon>
              <h4 matLine>{{'labels.anchors.manageholidays'| translate}}</h4>
              <p matLine>{{'labels.defineholidaysforoffices' | translate}}</p>
            </mat-list-item>
          </div>

          <div #employee>
            <mat-list-item [routerLink]="['employees']" *mifosxHasPermission="'READ_STAFF'">
              <mat-icon matListIcon>
                <fa-icon icon="user" size="sm"></fa-icon>
              </mat-icon>
              <h4 matLine>{{'labels.anchors.manageemployees'| translate}}</h4>
              <p matLine>{{'labels.aemployeerepresentsloanofficerswithnoaccesstosystem' |
                translate}}</p>
            </mat-list-item>
          </div>

          <mat-list-item [routerLink]="['standing-instructions-history']"
            *mifosxHasPermission="'READ_STANDINGINSTRUCTION'">
            <mat-icon matListIcon>
              <fa-icon icon="book" size="sm"></fa-icon>
            </mat-icon>
            <h4 matLine>{{'labels.anchors.standinginstruction.history' | translate}}</h4>
            <p matLine>{{'labels.view.standinginstructions.history.logging' |
              translate}}</p>
          </mat-list-item>

          <mat-list-item [routerLink]="['fund-mapping']" *mifosxHasPermission="'READ_OFFICE'">
            <mat-icon matListIcon>
              <fa-icon icon="money-bill-alt" size="sm"></fa-icon>
            </mat-icon>
            <h4 matLine>{{'labels.anchors.fund.mapping' | translate}}</h4>
            <p matLine>{{'labels.view.advance.search.fund.mapping' |
              translate}}</p>
          </mat-list-item>

          <mat-list-item [routerLink]="['password-preferences']"
            *mifosxHasPermission="'READ_PASSWORD_VALIDATION_POLICY'">
            <mat-icon matListIcon>
              <fa-icon icon="lock" size="sm"></fa-icon>
            </mat-icon>
            <h4 matLine> {{'labels.anchors.passwordPreferences' | translate}}</h4>
            <p matLine>{{'labels.view.passwordPreferences' |
              translate}}</p>
          </mat-list-item>

          <mat-list-item [routerLink]="['provisioning-criteria']" *mifosxHasPermission="'VIEW_PROVISIONS'">
            <mat-icon matListIcon>
              <fa-icon icon="edit" size="sm"></fa-icon>
            </mat-icon>
            <h4 matLine>{{'labels.anchors.createprovisioningcriteria' | translate}}</h4>
            <p matLine>{{'labels.view.createprovisioningcriteria' | translate}}</p>
          </mat-list-item>

          <mat-list-item [routerLink]="['entity-data-table-checks']"
            *mifosxHasPermission="'READ_ENTITY_DATATABLE_CHECK'">
            <mat-icon matListIcon>
              <fa-icon icon="check" size="sm"></fa-icon>
            </mat-icon>
            <h4 matLine>{{'labels.anchors.entitydatatablechecks' | translate}} </h4>
            <p matLine>{{'labels.view.entitydatatablechecks' | translate}}</p>
          </mat-list-item>

        </mat-nav-list>

      </div>

      <div fxFlex="50%">

        <mat-nav-list>

          <div #addEditCurrency>
            <mat-list-item [routerLink]="['currencies']" *mifosxHasPermission="'READ_CURRENCY'">
              <mat-icon matListIcon>
                <fa-icon icon="cogs" size="sm"></fa-icon>
              </mat-icon>
              <h4 matLine>{{'labels.anchors.currencyconfig' | translate}}</h4>
              <p matLine>{{'labels.currenciesavailableacrossorganizationfordifferentproducts' |
                translate}}</p>
            </mat-list-item>
          </div>

          <div #manageFunds>
            <mat-list-item [routerLink]="['manage-funds']" *mifosxHasPermission="'READ_FUND'">
              <mat-icon matListIcon>
                <fa-icon icon="money-bill-alt" size="sm"></fa-icon>
              </mat-icon>
              <h4 matLine>{{'labels.anchors.managefunds' | translate}}</h4>
              <p matLine>{{'labels.fundsareassociatedwithloans' | translate}}</p>
            </mat-list-item>
          </div>

          <mat-list-item [routerLink]="['bulkloan']" *mifosxHasPermission="'BULKREASSIGN_LOAN'">
            <mat-icon matListIcon>
              <fa-icon icon="money-bill-alt" size="sm"></fa-icon>
            </mat-icon>
            <h4 matLine>{{'labels.anchors.bulkloanreassignment' | translate}}</h4>
            <p matLine>{{'labels.easywaytoreassignalltheloanfromonelotoanotherlo' |
              translate}}</p>
          </mat-list-item>

          <mat-list-item [routerLink]="['tellers']" *mifosxHasPermission="'READ_TELLER'">
            <mat-icon matListIcon>
              <fa-icon icon="money-bill-alt" size="sm"></fa-icon>
            </mat-icon>
            <h4 matLine>{{'labels.anchors.tellercashmanagement' | translate}}</h4>
            <p matLine>{{'labels.view.tellercashmanagement' |
              translate}}</p>
          </mat-list-item>

          <div #workingDays>
            <mat-list-item [routerLink]="['working-days']" *mifosxHasPermission="'READ_WORKINGDAYS'">
              <mat-icon matListIcon>
                <fa-icon icon="calendar-alt" size="sm"></fa-icon>
              </mat-icon>
              <h4 matLine>{{'labels.anchors.workingDays' | translate}}</h4>
              <p matLine>{{'labels.view.workingDays' |
                translate}}</p>
            </mat-list-item>
          </div>

          <mat-list-item [routerLink]="['payment-types']" *mifosxHasPermission="'READ_PAYMENTTYPE'">
            <mat-icon matListIcon>
              <fa-icon icon="dollar-sign" size="sm"></fa-icon>
            </mat-icon>
            <h4 matLine>{{'labels.anchors.paymentTypes' | translate}}</h4>
            <p matLine>{{'labels.view.paymentTypes' |
              translate}}</p>
          </mat-list-item>

          <mat-list-item [routerLink]="['sms-campaigns']" *mifosxHasPermission="'VIEW_SMSCAMPAIGNS'">
            <mat-icon matListIcon>
              <fa-icon icon="envelope" size="sm"></fa-icon>
            </mat-icon>
            <h4 matLine>{{'labels.anchors.smscampaings' | translate}}</h4>
            <p matLine>{{'labels.view.smscampaigns' | translate}}</p>
          </mat-list-item>

          <mat-list-item [routerLink]="['adhoc-query']" *mifosxHasPermission="'VIEW_ADHOC'">
            <mat-icon matListIcon>
              <fa-icon icon="chevron-right" size="sm"></fa-icon>
            </mat-icon>
            <h4 matLine>{{'labels.anchors.adhoc' | translate}}</h4>
            <p matLine>{{'labels.view.adhocs' | translate}}</p>
          </mat-list-item>

          <mat-list-item [routerLink]="['bulk-import']" *mifosxHasPermission="'VIEW_BULKIMPORT'">
            <mat-icon matListIcon>
              <fa-icon icon="upload" size="sm"></fa-icon>
            </mat-icon>
            <h4 matLine>{{'labels.anchors.bulkimport' | translate}}</h4>
            <p matLine>{{'labels.view.bulkimport' | translate}}</p>
          </mat-list-item>

        </mat-nav-list>

      </div>

    </div>

  </mat-card>

</div>

<ng-template #templateOffice let-popover="popover">
  <h2>{{'labels.anchors.manageoffices' | translate}}</h2>
  <p class="mw300">{{"labels.theManageOfficesPageAllowsCreateEditAddOfficesOrganization" | translate}}</p>
  <div fxLayout="row" fxLayoutAlign="end" fxLayoutGap="2%" fxLayout.lt-md="column">
    <button mat-raised-button color="warn"
      (click)="popover.close();configurationWizardService.closeConfigWizard()">{{'labels.buttons.close' |
      translate}}</button>
    <button mat-raised-button color="primary" (click)="popover.close();previousStepOffice()">{{'labels.buttons.back' |
      translate}}</button>
    <button mat-raised-button color="primary" (click)="popover.close();nextStepOffice()">{{'labels.buttons.next' |
      translate}}</button>
  </div>
</ng-template>

<ng-template #templateAddEditCurrency let-popover="popover">
  <h2>{{'labels.anchors.currencyconfig' | translate}}</h2>
  <p class="mw300">{{"labels.thisSectionAllowsOrganizationDefineCurrenciesUsed" | translate: {'name' : appName} }}</p>
  <div fxLayout="row" fxLayoutAlign="end" fxLayoutGap="2%" fxLayout.lt-md="column">
    <button mat-raised-button color="warn"
      (click)="popover.close();configurationWizardService.closeConfigWizard()">{{'labels.buttons.close' |
      translate}}</button>
    <button mat-raised-button color="primary"
      (click)="popover.close();previousStepAddEditCurrency()">{{'labels.buttons.back' | translate}}</button>
    <button mat-raised-button color="primary"
      (click)="popover.close();nextStepAddEditCurrency()">{{'labels.buttons.next' | translate}}</button>
  </div>
</ng-template>

<ng-template #templateHolidays let-popover="popover">
  <h2>{{'labels.anchors.manageholidays'| translate}}</h2>
  <p class="mw300">{{"labels.theAbilityManageHolidaysIndividualOffices" | translate}}</p>
  <div fxLayout="row" fxLayoutAlign="end" fxLayoutGap="2%" fxLayout.lt-md="column">
    <button mat-raised-button color="warn"
      (click)="popover.close();configurationWizardService.closeConfigWizard()">{{'labels.buttons.close' |
      translate}}</button>
    <button mat-raised-button color="primary" (click)="popover.close();previousStepHolidays()">{{'labels.buttons.back' |
      translate}}</button>
    <button mat-raised-button color="primary" (click)="popover.close();nextStepHolidays()">{{'labels.buttons.next' |
      translate}}</button>
  </div>
</ng-template>

<ng-template #templateEmployee let-popover="popover">
  <h2>{{'labels.anchors.manageemployees'| translate}}</h2>
  <p class="mw300">{{"labels.withOptionMayViewEditDeleteOrganizationEmployees" | translate}}</p>
  <div fxLayout="row" fxLayoutAlign="end" fxLayoutGap="2%" fxLayout.lt-md="column">
    <button mat-raised-button color="warn"
      (click)="popover.close();configurationWizardService.closeConfigWizard()">{{'labels.buttons.close' |
      translate}}</button>
    <button mat-raised-button color="primary" (click)="popover.close();previousStepEmployee()">{{'labels.buttons.back' |
      translate}}</button>
    <button mat-raised-button color="primary" (click)="popover.close();nextStepEmployee()">{{'labels.buttons.next' |
      translate}}</button>
  </div>
</ng-template>

<ng-template #templateWorkingDays let-popover="popover">
  <h2>{{'labels.anchors.workingDays' | translate}}</h2>
  <p class="mw300">{{"labels.workingDaysPageAllowsDefine" | translate}}</p>
  <div fxLayout="row" fxLayoutAlign="end" fxLayoutGap="2%" fxLayout.lt-md="column">
    <button mat-raised-button color="warn"
      (click)="popover.close();configurationWizardService.closeConfigWizard()">{{'labels.buttons.close' |
      translate}}</button>
    <button mat-raised-button color="primary"
      (click)="popover.close();previousStepWorkingDays()">{{'labels.buttons.back' | translate}}</button>
    <button mat-raised-button color="primary" (click)="popover.close();nextStepWorkingDays()">{{'labels.buttons.next' |
      translate}}</button>
  </div>
</ng-template>

<ng-template #templateManageFunds let-popover="popover">
  <h2>{{'labels.anchors.managefunds' | translate}}</h2>
  <p class="mw300">{{"labels.thisAllowsYouToManageFundsAssociated" | translate}}.</p>
  <div fxLayout="row" fxLayoutAlign="end" fxLayoutGap="2%" fxLayout.lt-md="column">
    <button mat-raised-button color="warn"
      (click)="popover.close();configurationWizardService.closeConfigWizard()">{{'labels.buttons.close' |
      translate}}</button>
    <button mat-raised-button color="primary"
      (click)="popover.close();previousStepManageFunds()">{{'labels.buttons.back' | translate}}</button>
    <button mat-raised-button color="primary" (click)="popover.close();nextStepManageFunds()">{{'labels.buttons.next' |
      translate}}</button>
  </div>
</ng-template>
