<div class="tab-container mat-typography">

  <mat-list>

    <mat-list-item>
      {{"labels.inputs.parentoffice" | translate}} : {{ officeData.parentName ? officeData.parentName : 'N/A' }}
    </mat-list-item>

    <mat-list-item>
      {{"labels.heading.openedon" | translate}} : {{ officeData.openingDate ? (officeData.openingDate  | dateFormat) : 'Unassigned' }}
    </mat-list-item>

    <mat-list-item>
      {{"labels.heading.namedecorated" | translate}} : {{ officeData.nameDecorated ? officeData.nameDecorated : 'Unassigned' }}
    </mat-list-item>

    <mat-list-item>
      {{"labels.heading.externalid" | translate}} : {{ officeData.externalId ? officeData.externalId : 'Unassigned' }}
    </mat-list-item>

  </mat-list>

</div>
