import { Injectable } from '@angular/core';
import { extract } from 'app/core/i18n/i18n.service';

@Injectable({
  providedIn: 'root'
})
export class CreditLineActionsButtons {

  public buttons = [
    {
      icon: 'fa fa-check',
      action: 'accept',
      name: 'labels.buttons.approve',
      color: 'primary',
      permission: 'ACCEPT_CREDIT_LINE'
    },
    {
      icon: 'fa fa-edit',
      action: 'supplement',
      name: 'labels.toComplete',
      color: 'accent',
      permission: 'SUPPLEMENT_CREDIT_LINE'
    },
    {
      icon: 'fa fa-times',
      action: 'refuse',
      name: 'labels.anchors.reject',
      color: 'warn',
      permission: 'REFUSE_CREDIT_LINE'
    },
    {
      icon: 'fa fa-check-circle',
      action: 'confirm',
      name: 'labels.buttons.activate',
      color: 'primary',
      permission: 'CONFIRM_CREDIT_LINE'
    },
    {
      icon: 'fa fa-undo',
      action: 'undoaccept',
      name: 'labels.buttons.undoapproval',
      color: 'primary',
      permission: 'UNDOACCEPT_CREDIT_LINE'
    },
    {
      icon: 'fa fa-undo',
      action: 'undosupplement',
      name: 'labels.undotoComplete',
      color: 'primary',
      permission: 'UNDOSUPPLEMENT_CREDIT_LINE'
    },
    {
      icon: 'fa fa-undo',
      action: 'undorefuse',
      name: 'labels.buttons.undoReject',
      color: 'primary',
      permission: 'UNDOREFUSE_CREDIT_LINE'
    }
  ];

  public actionCreditLine(action: String, code: Number) {
    switch (code) {
      case 100:
        return action === 'accept' || action === 'refuse' || action === 'supplement'
      case 200:
        return action === 'confirm' || action == "undoaccept"
      case 400:
        return action === "accept" || action === "refuse" || action === "undosupplement";
      case 500:
        return action == "undorefuse"
      default:
        return false
    }
  }

  public actionMapping(name: string){
    switch(name){
      case 'confirm':
        return extract("labels.buttons.activate");
      case 'accept':
        return extract("labels.anchors.approve");
      case 'refuse':
        return extract("labels.anchors.reject");
      case 'supplement':
        return extract("labels.toComplete");
      case 'undosupplement':
        return extract("labels.undotoComplete");
      case 'undoaccept':
        return extract("labels.buttons.undoapproval");
      case 'undorefuse':
        return extract("labels.buttons.undoReject");
    }
  }
}
