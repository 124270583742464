<div fxLayout="row" fxLayoutAlign="end" fxLayoutGap="2%" fxLayout.lt-md="column" class="container m-b-20">
  <button mat-raised-button color="primary" [routerLink]="['edit']" *mifosxHasPermission="'UPDATE_ACCOUNTINGRULE'">
    <fa-icon icon="edit"></fa-icon>&nbsp;&nbsp;
    {{"labels.inputs.edit" | translate}}
  </button>
  <button mat-raised-button color="warn" (click)="deleteAccountingRule()" *mifosxHasPermission="'DELETE_ACCOUNTINGRULE'">
    <fa-icon icon="trash"></fa-icon>&nbsp;&nbsp;
    {{"labels.anchors.delete" | translate}}
  </button>
</div>

<div class="container" fxLayout="row" fxLayoutGap="2%" fxLayout.lt-md="column">

  <div fxFlex="48%">

    <mat-card>

      <mat-card-content>

        <div fxLayout="row wrap" class="content">

          <div fxFlex="50%" class="header">
            {{"labels.anchors.office" | translate}}
          </div>

          <div fxFlex="50%">
            {{ accountingRule.officeName }}
          </div>

          <div fxFlex="50%" class="header" *ngIf="accountingRule.description">
            {{"labels.inputs.description" | translate}}
          </div>

          <div fxFlex="50%" *ngIf="accountingRule.description">
            {{ accountingRule.description }}
          </div>

          <div fxFlex="50%" class="header">
            {{"labels.multipleDebitEntriesAllowed" | translate}}
          </div>

          <div fxFlex="50%">
            {{ accountingRule.allowMultipleDebitEntries }}
          </div>

          <div fxFlex="50%" class="header">
            {{"labels.multipleCreditEntriesAllowed" | translate}}
          </div>

          <div fxFlex="50%">
            {{ accountingRule.allowMultipleCreditEntries }}
          </div>

        </div>

      </mat-card-content>

    </mat-card>

  </div>

  <div fxFlex>

    <mat-card>

      <mat-card-content>

        <div fxLayout="row" class="content">

          <div fxFlex="50%" fxLayout="column" *ngIf="accountingRule.debitTags">
            <div class="header">
              {{"labels.heading.debittags" | translate}}
            </div>
            <div *ngFor="let debitTag of accountingRule.debitTags">
              {{ debitTag.tag.name }}
            </div>
          </div>

          <div fxFlex="50%" fxLayout="column" *ngIf="accountingRule.debitAccounts">
            <div class="header">
              {{"labels.debitAccountName" | translate}}
            </div>
            <div *ngFor="let debitAccount of accountingRule.debitAccounts">
              {{ debitAccount.name + ' (' + debitAccount.glCode + ')' }}
            </div>
          </div>

          <div fxFlex="50%" fxLayout="column" *ngIf="accountingRule.creditTags">
            <div class="header">
              {{"labels.heading.credittags" | translate}}
            </div>
            <div *ngFor="let creditTag of accountingRule.creditTags">
              {{ creditTag.tag.name }}
            </div>
          </div>

          <div fxFlex="50%" fxLayout="column" *ngIf="accountingRule.creditAccounts">
            <div class="header">
              {{"labels.creditAccountName" | translate}}
            </div>
            <div *ngFor="let creditAccount of accountingRule.creditAccounts">
              {{ creditAccount.name + ' (' + creditAccount.glCode + ')' }}
            </div>
          </div>

        </div>

      </mat-card-content>

    </mat-card>

  </div>

</div>
