<div class="container">

  <mat-card>

    <form [formGroup]="emailConfigurationForm" (ngSubmit)="submit()">

      <mat-card-content>

        <div fxLayout="column">

          <mat-form-field>
            <mat-label>{{"labels.inputs.Username" | translate}}</mat-label>
            <input matInput required formControlName="username">
            <mat-error *ngIf="emailConfigurationForm.controls.username.hasError('required')">{{"labels.inputs.Username" | translate}} <strong>{{"labels.commons.is required" | translate}}</strong>
            </mat-error>
          </mat-form-field>

          <mat-form-field>
            <mat-label>{{"labels.inputs.Password" | translate}}</mat-label>
            <input matInput required formControlName="password">
            <mat-error *ngIf="emailConfigurationForm.controls.password.hasError('required')">{{"labels.inputs.Password" | translate}} <strong>{{"labels.commons.is required" | translate}}</strong>
            </mat-error>
          </mat-form-field>

          <mat-form-field>
            <mat-label>{{"labels.host" | translate}}</mat-label>
            <input matInput required formControlName="host">
            <mat-error *ngIf="emailConfigurationForm.controls.host.hasError('required')">{{"labels.host" | translate}} <strong>{{"labels.commons.is required" | translate}}</strong>
            </mat-error>
          </mat-form-field>

          <mat-form-field>
            <mat-label>{{"labels.port" | translate}}</mat-label>
            <input matInput required formControlName="port">
            <mat-error *ngIf="emailConfigurationForm.controls.port.hasError('required')">{{"labels.port" | translate}} <strong>{{"labels.commons.is required" | translate}}</strong>
            </mat-error>
          </mat-form-field>

          <mat-form-field>
            <mat-label>{{"labels.useTLS" | translate}}</mat-label>
            <input matInput required formControlName="useTLS">
            <mat-error *ngIf="emailConfigurationForm.controls.useTLS.hasError('required')">{{"labels.useTLS" | translate}} <strong>{{"labels.commons.is required" | translate}}</strong>
            </mat-error>
          </mat-form-field>

        </div>

      </mat-card-content>

      <mat-card-actions fxLayout="row" fxLayout.xs="column" fxLayoutAlign="center" fxLayoutGap="5px">
        <button type="button" mat-raised-button [routerLink]="['../']">{{"labels.buttons.cancel" | translate}}</button>
        <button mat-raised-button color="primary" [disabled]="!emailConfigurationForm.valid || emailConfigurationForm.pristine">{{"labels.buttons.submit" | translate}}</button>
      </mat-card-actions>

    </form>

  </mat-card>

</div>
