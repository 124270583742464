<div class="container m-b-20" fxLayout="row" fxLayoutAlign="end" fxLayoutGap="20px">
  <div #buttonCreateReport class="in-block">
    <button mat-raised-button color="primary" [routerLink]="['create']" *mifosxHasPermission="'CREATE_REPORT'">
        <fa-icon icon="plus"></fa-icon>&nbsp;&nbsp;
        {{"labels.anchors.createreport" | translate}}
    </button>
  </div>
</div>

<div class="container">

    <div #filter fxLayout="row">
        <mat-form-field fxFlex>
            <mat-label>&nbsp;&nbsp;{{"labels.buttons.filter" | translate }}</mat-label>
            <input matInput (keyup)="applyFilter($event.target.value)">
        </mat-form-field>
    </div>

    <div #reportsTable class="mat-elevation-z8">

        <table mat-table [dataSource]="dataSource" matSort>

            <ng-container matColumnDef="reportName">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>{{"labels.inputs.reportname" | translate}}</th>
                <td mat-cell *matCellDef="let report"> {{ report.reportName }} </td>
            </ng-container>

            <ng-container matColumnDef="reportType">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>{{"labels.inputs.reporttype" | translate}}</th>
                <td mat-cell *matCellDef="let report"> {{ report.reportType }} </td>
            </ng-container>

            <ng-container matColumnDef="reportSubType">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>{{"labels.inputs.reportsubtype" | translate}}</th>
                <td mat-cell *matCellDef="let report"> {{ report.reportSubType }} </td>
            </ng-container>

            <ng-container matColumnDef="reportCategory">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>{{"labels.inputs.reportcategory" | translate}}</th>
                <td mat-cell *matCellDef="let report"> {{ report.reportCategory }} </td>
            </ng-container>

            <ng-container matColumnDef="coreReport">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> {{"labels.inputs.corereport" | translate}} </th>
                <td mat-cell *matCellDef="let report">
                    <fa-icon *ngIf="report.coreReport" icon="check-circle" size="lg" class="true" [matTooltip]="'labels.yes'| translate"
                        matTooltipPosition="right"></fa-icon>
                    <fa-icon *ngIf="!report.coreReport" icon="times-circle" size="lg" class="false" [matTooltip]="'labels.no'| translate"
                        matTooltipPosition="right"></fa-icon>
                </td>
            </ng-container>

            <ng-container matColumnDef="userReport">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> {{"labels.userReport" | translate}} </th>
                <td mat-cell *matCellDef="let report">
                    <fa-icon *ngIf="report.useReport" icon="check-circle" size="lg" class="true" [matTooltip]="'labels.yes'| translate"
                        matTooltipPosition="right"></fa-icon>
                    <fa-icon *ngIf="!report.useReport" icon="times-circle" size="lg" class="false" [matTooltip]="'labels.no'| translate"
                        matTooltipPosition="right"></fa-icon>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;" [routerLink]="[row.id]"
                class="select-row"></tr>
        </table>

        <mat-paginator [pageSizeOptions]="[10, 25, 50, 100]" showFirstLastButtons></mat-paginator>

    </div>
</div>

<ng-template #templateButtonCreateReport let-popover="popover">
  <h2>{{"labels.anchors.createreport" | translate}}</h2>
  <p class="mw300">{{"labels.thisOptionAllowsYouCreateReport" | translate}}</p>
  <div fxLayout="row" fxLayoutAlign="end" fxLayoutGap="2%" fxLayout.lt-md="column">
    <button mat-raised-button color="warn" (click)="popover.close();configurationWizardService.closeConfigWizard()">{{"labels.buttons.close" | translate}}</button>
    <button mat-raised-button color="primary" (click)="popover.close();previousStep()">{{"labels.buttons.back" | translate}}</button>
    <button mat-raised-button color="primary" (click)="popover.close();showPopover(templateFilter,filter, 'bottom', true)">{{"labels.buttons.next" | translate}}</button>
  </div>
</ng-template>

<ng-template #templateFilter let-popover="popover">
  <h4>{{"labels.searchBarFilterReportsName" | translate}}</h4>
  <div fxLayout="row" fxLayoutAlign="end" fxLayoutGap="2%" fxLayout.lt-md="column">
    <button mat-raised-button color="warn" (click)="popover.close();configurationWizardService.closeConfigWizard()">{{"labels.buttons.close" | translate}}</button>
    <button mat-raised-button color="primary" (click)="popover.close();showPopover(templateButtonCreateReport, buttonCreateReport, 'bottom', true)">{{"labels.buttons.back" | translate}}</button>
    <button mat-raised-button color="primary" (click)="popover.close();showPopover(templateReportsTable,reportsTable, 'top', true)">{{"labels.buttons.next" | translate}}</button>
  </div>
</ng-template>

<ng-template #templateReportsTable let-popover="popover">
  <h4 class="mw300">{{"labels.listAllCurrentlyAvailableReports" | translate}}. {{"labels.forMoreDetailsClick" | translate}} <a href="" target="_blank">{{"labels.anchors.managereports" | translate}}</a></h4>
  <div fxLayout="row" fxLayoutAlign="end" fxLayoutGap="2%" fxLayout.lt-md="column">
    <button mat-raised-button color="warn" (click)="popover.close();configurationWizardService.closeConfigWizard()">{{"labels.buttons.close" | translate}}</button>
    <button mat-raised-button color="primary" (click)="popover.close();showPopover(templateFilter,filter, 'bottom', true)">{{"labels.buttons.back" | translate}}</button>
    <button mat-raised-button color="primary" (click)="popover.close();nextStep()">{{"labels.buttons.next" | translate}}</button>
  </div>
</ng-template>
