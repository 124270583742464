<mat-card-header fxLayout="row" fxLayoutGap="5%" class="header">
  <fa-icon class="main-icon" icon="building" size="3x"></fa-icon>
  <mat-card-title-group>
    <div class="mat-typography">
      <mat-card-title>
        <h2>
          {{ centerData.name }}
          <span [className]="centerData.status.code | statusLookup">
            <fa-icon matTooltip="{{ centerData.status.value }}" matTooltipPosition="right" icon="circle" size="lg"></fa-icon>
          </span>
        </h2>
      </mat-card-title>
      <mat-card-subtitle>
        <p>
          {{"labels.accountNo" | translate}}: {{ centerData.accountNo }} | {{ centerData.externalId ? '| External ID: ' + centerData.externalId : '' }}
        </p>
      </mat-card-subtitle>
    </div>
  </mat-card-title-group>
</mat-card-header>

<mat-card-content>

  <mat-tab-group>
    <mat-tab [label]="'labels.buttons.details' | translate">
      <div fxLayout="row wrap" class="content">

        <div fxFlex="50%" class="mat-body-strong">
          {{"labels.inputs.activationdate" | translate}}
        </div>

        <div fxFlex="50%">
          {{ centerData.activationDate  | dateFormat }}
        </div>

        <div fxFlex="50%" class="mat-body-strong">
          {{"labels.associatedOfficer" | translate}}
        </div>

        <div fxFlex="50%">
          {{ centerData.staffName }}
        </div>

        <div fxFlex="50%" class="mat-body-strong" *ngIf="centerSummaryData">
          {{"labels.numberActiveClients" | translate}}
        </div>

        <div fxFlex="50%" *ngIf="centerSummaryData">
          {{ centerSummaryData.activeClients }}
        </div>

        <div fxFlex="50%" class="mat-body-strong" *ngIf="centerSummaryData">
          {{"labels.numberActiveClientLoans" | translate}}
        </div>

        <div fxFlex="50%" *ngIf="centerSummaryData">
          {{ centerSummaryData.activeClientLoans }}
        </div>

        <div fxFlex="50%" class="mat-body-strong" *ngIf="centerSummaryData">
          {{"labels.numberActiveClientBorrowers" | translate}}
        </div>

        <div fxFlex="50%" *ngIf="centerSummaryData">
          {{ centerSummaryData.activeClientBorrowers }}
        </div>

        <div fxFlex="50%" class="mat-body-strong" *ngIf="centerSummaryData">
          {{"labels.numberActiveOverdueGroupLoans" | translate}}
        </div>

        <div fxFlex="50%" *ngIf="centerSummaryData">
          {{ centerSummaryData.overdueGroupLoans }}
        </div>

        <div fxFlex="50%" class="mat-body-strong" *ngIf="centerSummaryData">
          {{"labels.numberActiveOverdueClientLoans" | translate}}
        </div>

        <div fxFlex="50%" *ngIf="centerSummaryData">
          {{ centerSummaryData.overdueClientLoans }}
        </div>

        <div fxFlex="50%" class="mat-body-strong" *ngIf="centerData.collectionMeetingCalendar && centerData.collectionMeetingCalendar.nextTenRecurringDates[0]">
          {{"labels.nextMeetingDate" | translate}}
        </div>

        <div fxFlex="50%" *ngIf="centerData.collectionMeetingCalendar && centerData.collectionMeetingCalendar.nextTenRecurringDates[0]">
          {{ centerSummaryData.collectionMeetingCalendar.nextTenRecurringDates[0]  | dateFormat }}
        </div>

        <div fxFlex="50%" class="mat-body-strong" *ngIf="centerData.collectionMeetingCalendar && centerData.collectionMeetingCalendar.humanReadable">
          {{"labels.heading.meetingfrequency" | translate}}
        </div>

        <div fxFlex="50%" *ngIf="centerData.collectionMeetingCalendar && centerData.collectionMeetingCalendar.humanReadable">
          {{ centerSummaryData.collectionMeetingCalendar.humanReadable }}
        </div>

        <div fxFlex="50%" class="mat-body-strong">
          {{"labels.numberGroups" | translate}}
        </div>

        <div fxFlex="50%">
          {{ groupData ? groupData.length : '' }}
        </div>

      </div>
    </mat-tab>

    <mat-tab [label]="'labels.buttons.loanaccounts' | translate" *ngIf="centerAccountsData && centerAccountsData.loanAccounts">
      <mifosx-loan-account-table [loanAccountData]="centerAccountsData.loanAccounts"></mifosx-loan-account-table>
    </mat-tab>

    <mat-tab [label]="'labels.buttons.savingaccounts' | translate" *ngIf="centerAccountsData && centerAccountsData.savingsAccounts">
      <mifosx-savings-account-table [savingsAccountData]="centerAccountsData.savingsAccounts"></mifosx-savings-account-table>
    </mat-tab>

  </mat-tab-group>

</mat-card-content>
