<mifosx-web-views-top-bar title="labels.heading.loandetails"></mifosx-web-views-top-bar>

<div class="container">
  <div class="content">
  <mifosx-card>

  <ng-container *ngIf="loanDetailsData.status.id!==600">
    <!-- <mifosx-card>
      <div>
        <span>{{"labels.outstandingBalance" | translate}}:</span>
        <span class="amount"><strong>{{clientCLData?.limitAmount}}</strong> {{clientCLData?.currency?.code}}</span>
      </div>
      <div>
        <span>{{"labels.limitUtilized" | translate}}:</span>
        <span class="amount"><strong>{{clientCLData?.limitAmount - clientCLData?.remainAmount}}</strong>
          {{clientCLData?.currency?.code}}</span>
      </div>
      <div>
        <span>{{"labels.limitAvailable" | translate}}:</span>
        <span class="amount limit"><strong>{{clientCLData?.remainAmount}}</strong>
          {{clientCLData?.currency?.code}}</span>
      </div>
    </mifosx-card> -->
      <div>
    <span>
    {{"labels.outstandingBalance" | translate}} :
    </span>
    <span class="amount">
      <strong>{{loanDetailsData.summary?.totalOutstanding}}</strong>
      {{loanDetailsData.currency.code ?? loanDetailsData.currency.displaySymbol}}
    </span>
  </div>
  <div>
    <span>{{"labels.nextInstallement" | translate}} :</span>
    <span class="amount">{{nextInstallement ?? "NA"}}</span>
  </div>
  <div>
    <span>
    {{"labels.inputs.duedate" | translate}} :
    </span>
    <span class="amount"> {{loanDetailsData?.summary?.overdueSinceDate ?? "NA" | dateFormat}}</span>
  </div>
  </ng-container>
  <div>
    <span>
    {{"labels.loanId" | translate}} :
    </span>
    <span class="amount">{{loanDetailsData.accountNo}}</span>
  </div>
  <div>
    <span>
    {{"labels.heading.type" | translate}} :
    </span>
    <span class="amount">{{loanDetailsData.loanType.value}}</span>
  </div>
  <div>
    <span>
    {{"labels.inputs.currency" | translate}} :
  </span>
  <span class="amount">
    {{loanDetailsData.currency.code ?? loanDetailsData.currency.displaySymbol}}
    </span>
  </div>
  </mifosx-card>
  <br/>
  <div class="payment" *ngIf="loanDetailsData.status.id!==600">
    <button button mat-flat-button color="primary" [routerLink]="['make_repayment/']" [queryParams]="{total: true}">
      {{"labels.totalPayment" | translate}}
    </button>
    <button button mat-flat-button color="primary" [routerLink]="['make_repayment/']">
      {{"labels.partialPayment" | translate}}
    </button>
  </div>
  <div class="divider">
    <mat-divider fxFlexFill></mat-divider>
  </div>
  <h4>{{"labels.monitor" | translate}}</h4>
  <div class="monitor" fxLayout="column" fxLayoutAlign="center" fxLayoutGap="5px">
    <button mat-button color="primary" [routerLink]="['summary/']">
      <mat-icon>description</mat-icon>
      {{"labels.heading.loanSummary" | translate}}
    </button>
    <button mat-button color="primary" [routerLink]="['charges/']">
      <mat-icon>payments</mat-icon>
      {{"labels.loanCharges" | translate}}
    </button>
    <button mat-button color="primary" [routerLink]="['repayment_schedule/']">
      <mat-icon>payments</mat-icon>
      {{"labels.repaymentSchedule" | translate}}
    </button>
    <button mat-button color="primary" [routerLink]="['transactions/']">
      <mat-icon>sync_alt</mat-icon>
      {{"labels.repaymentHistory" | translate}}
    </button>
  </div>
  </div>
  <mifosx-web-views-logo></mifosx-web-views-logo>
</div>
