import { MatDialog } from '@angular/material/dialog';
import { ConfirmationDialogComponent } from 'app/shared/confirmation-dialog/confirmation-dialog.component';
/** Angular Imports */
import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

/** Custom Services */
import { Dates } from 'app/core/utils/dates';
import { LoansService } from 'app/loans/loans.service';
import { SettingsService } from 'app/settings/settings.service';

/**
 * Loan Make Repayment Component
 */
@Component({
  selector: 'mifosx-web-view-make-repayment',
  templateUrl: './web-view-make-repayment.component.html',
  styleUrls: ['./web-view-make-repayment.component.scss']
})
export class WebViewMakeRepaymentComponent implements OnInit {

  dataObject: any;
  /** Loan Id */
  loanId: string;
  /** Payment Type Options */
  paymentTypes: any;
  /** Show payment details */
  showPaymentDetails = false;
  /** Minimum Date allowed. */
  minDate = new Date(2000, 0, 1);
  /** Maximum Date allowed. */
  maxDate = new Date();
  /** Repayment Loan Form */
  repaymentLoanForm: FormGroup;
  paymentTypeId= 3;
  totalPayment:Boolean;
  totalOutstanding:any;

  /**
   * @param {FormBuilder} formBuilder Form Builder.
   * @param {LoansService} loanService Loan Service.
   * @param {ActivatedRoute} route Activated Route.
   * @param {Router} router Router for navigation.
   * @param {SettingsService} settingsService Settings Service
   */
  constructor(private formBuilder: FormBuilder,
    private loanService: LoansService,
    private route: ActivatedRoute,
    private router: Router,
    private dateUtils: Dates,
    private settingsService: SettingsService,
    public dialog: MatDialog) {
    this.loanId = this.route.snapshot.params['loanId'];
    this.totalPayment = this.route.snapshot.queryParams['total'];
  }

  /**
   * Creates the repayment loan form
   * and initialize with the required values
   */
  ngOnInit() {
    this.maxDate = this.settingsService.businessDate;
    this.createRepaymentLoanForm();
    this.loanService.getLoanActionTemplate(this.loanId, 'repayment').subscribe(data=>{
      this.dataObject = data;
      this.setRepaymentLoanDetails();
    });
    this.route.data.subscribe((data: { loanDetailsData: any }) => {
      this.totalOutstanding = data?.loanDetailsData?.summary?.totalOutstanding;
      if(this.totalPayment){
        this.repaymentLoanForm.patchValue({
          transactionAmount: this.totalOutstanding
        });
      }
    })

  }

  /**
   * Creates the create close form.
   */
  createRepaymentLoanForm() {
    this.repaymentLoanForm = this.formBuilder.group({
      'transactionDate': [new Date(), Validators.required],
      'transactionAmount': ['', Validators.required],
      'paymentTypeId': 3,
      'note': ''
    });
  }

  setRepaymentLoanDetails() {
    this.paymentTypes = this.dataObject.paymentTypeOptions;
    this.paymentTypeId = this.dataObject.paymentTypeOptions.filter((item:any)=>item.name==="Cash IN")?.id ?? 3;
    if(!this.totalPayment){
    this.repaymentLoanForm.patchValue({
      transactionAmount: this.dataObject.amount
    });
  }
  }

  /** Submits the repayment form */
  submit() {
    const unAssignStaffDialogRef = this.dialog.open(ConfirmationDialogComponent, {
      data: { heading: 'labels.buttons.makerepayment', dialogContext: 'labels.areYouSureYouWantToMakeThisRepayment' }
    });
    unAssignStaffDialogRef.afterClosed().subscribe((response: { confirm: any }) => {
      if (response.confirm) {
        const repaymentLoanFormData = this.repaymentLoanForm.value;
        const locale = this.settingsService.language.code;
        const dateFormat = 'yyyy-MM-dd';
        repaymentLoanFormData.transactionDate = this.dateUtils.formatDate(new Date(), dateFormat);

        const data = {
          ...repaymentLoanFormData,
          paymentTypeId:this.paymentTypeId,
          dateFormat,
          locale
        };
        const command = this.dataObject.type.code.split('.')[1];
        this.loanService.loanWebviewRepayment(this.loanId, data)
          .subscribe((response: any) => {
            this.router.navigate(['../../'], { relativeTo: this.route });
          });
      }
    });
  }

}
