<div class="container">
  <mat-card>
    <div fxLayout="row" fxLayout.lt-md="column">
      <div fxFlex="50%">
        <mat-nav-list>
          <div #viewaccounts>
            <mat-list-item [routerLink]="['viewaccounts']">
              <mat-icon matListIcon>
                <fa-icon icon="table" size="sm"></fa-icon>
              </mat-icon>
              <h4 matLine>{{"labels.heading.viewaccounts" | translate}}</h4>
              <p matLine>{{"labels.heading.viewaccounts" | translate}}</p>
            </mat-list-item>
          </div>
        </mat-nav-list>
      </div>

      <div fxFlex="50%">
        <mat-nav-list>
          <div #accountsTracking>
            <mat-list-item [routerLink]="['followaccount/approval']">
              <mat-icon matListIcon>
                <fa-icon icon="list-ul" size="sm"></fa-icon>
              </mat-icon>
              <h4 matLine>{{"labels.anchors.accountsTracking" | translate}}</h4>
              <p matLine>{{"labels.anchors.accountsTracking" | translate}}</p>
            </mat-list-item>
          </div>
        </mat-nav-list>
      </div>
    </div>
  </mat-card>
</div>
