<div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="2%">

  <mat-form-field [fxFlex]="flex || '83%'">
    <input matInput [readonly]="true" [value]="fileName || ''" [placeholder]="'labels.noFileSelected'| translate">
  </mat-form-field>
  <span fxFlex="10%" fxFlexAlign="center">
    <button mat-button (click)="uploadFile.click()">
      <fa-icon icon="folder-open" size="lg"></fa-icon>
      &nbsp;&nbsp;
      {{"labels.browse" | translate}}
    </button>
  </span>

</div>
<div class="error-size" fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="2%" *ngIf="fileSizeError">
  {{"labels.fileSizeMustBeMaximum" | translate: {size: 5} }}
</div>

<input type="file" #uploadFile (change)="onFileSelect($event)" [style.display]="'none'">
