<div class="container">

  <mat-card>

    <form [formGroup]="codeForm" (ngSubmit)="submit()">

      <mat-card-content>

          <div #codeFormRef fxLayout="column">

            <mat-form-field>
              <mat-label>{{"labels.inputs.codename" | translate }}</mat-label>
              <input matInput required formControlName="name">
              <mat-error *ngIf="codeForm.controls.name.hasError('required')">{{"labels.inputs.codename" | translate }} <strong>{{"labels.commons.is required" | translate}}</strong>
              </mat-error>
            </mat-form-field>

          </div>

      </mat-card-content>

      <mat-card-actions fxLayout="row" fxLayout.xs="column" fxLayoutAlign="center" fxLayoutGap="5px">
        <button type="button" mat-raised-button [routerLink]="['../']">{{"labels.buttons.cancel" | translate}}</button>
        <button mat-raised-button color="primary" [disabled]="!codeForm.valid" *mifosxHasPermission="'CREATE_CODE'">{{"labels.buttons.submit" | translate}}</button>
      </mat-card-actions>

    </form>

  </mat-card>

</div>

<ng-template #templateCodeFormRef let-data let-popover="popover">
  <h2>{{"labels.createCode" | translate }}</h2>
  <p class="mw400">{{"labels.toCreateCodeClickCreateCodeEnterCodeName" | translate}} <br>{{"labels.forMoreDetailsClick" | translate }}<a href="" target="_blank">{{"labels.anchors.managecodes" | translate}}</a></p>
  <div fxLayout="row" fxLayoutAlign="end" fxLayoutGap="2%" fxLayout.lt-md="column">
    <button mat-raised-button color="warn" (click)="popover.close();configurationWizardService.closeConfigWizard()">{{"labels.buttons.close" | translate}}</button>
    <button mat-raised-button color="primary" (click)="popover.close()">{{"labels.createCode" | translate }}</button>
    <button mat-raised-button color="primary" (click)="popover.close();previousStep()">{{"labels.buttons.back" | translate}}</button>
    <button mat-raised-button color="primary" (click)="popover.close();nextStep()">{{"labels.buttons.next" | translate}}</button>
  </div>
</ng-template>
