<div class="container">

  <mat-card>

    <form [formGroup]="employeeForm" (ngSubmit)="submit()">

      <mat-card-content>

        <div #createEmployeeFormRef fxLayout="column">

          <mat-form-field>
            <mat-label>{{"labels.inputs.office" | translate}}</mat-label>
            <mat-select required formControlName="officeId">
              <mat-option *ngFor="let office of officeData" [value]="office.id">
                {{ office.name }}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="employeeForm.controls.officeId.hasError('required')">
              {{"labels.inputs.office" | translate}} <strong>{{"labels.commons.is required" | translate}}</strong>
            </mat-error>
          </mat-form-field>

          <mat-form-field>
            <mat-label>{{"labels.inputs.firstname" | translate}}</mat-label>
            <input matInput required formControlName="firstname">
            <mat-error *ngIf="employeeForm.controls.firstname.hasError('required')">
              {{"labels.inputs.firstname" | translate}} <strong>{{"labels.commons.is required" | translate}}</strong>
            </mat-error>
            <mat-error *ngIf="employeeForm.controls.firstname.hasError('pattern')">
              {{"labels.inputs.firstname" | translate}} <strong>{{"labels.cannot" | translate}}</strong> {{"labels.anchors.cannotbeginwithspecialcharacter" | translate}}
            </mat-error>
          </mat-form-field>

          <mat-form-field>
            <mat-label>{{"labels.inputs.lastname" | translate}}</mat-label>
            <input matInput required formControlName="lastname">
            <mat-error *ngIf="employeeForm.controls.lastname.hasError('required')">
              {{"labels.inputs.lastname" | translate}} <strong>{{"labels.commons.is required" | translate}}</strong>
            </mat-error>
            <mat-error *ngIf="employeeForm.controls.lastname.hasError('pattern')">
              {{"labels.inputs.lastname" | translate}}<strong>{{"labels.cannot" | translate}}</strong> {{"labels.anchors.cannotbeginwithspecialcharacter" | translate}}
            </mat-error>
          </mat-form-field>

          <mat-checkbox labelPosition="before" formControlName="isLoanOfficer" class="loan-officer">
            {{"labels.inputs.isLoanOfficer" | translate}}
          </mat-checkbox>

          <mat-form-field>
            <mat-label>{{"labels.inputs.mobileNo" | translate}}</mat-label>
            <input matInput formControlName="mobileNo">
          </mat-form-field>

          <mat-form-field (click)="joiningDatePicker.open()">
            <mat-label>{{"labels.joiningDate" | translate}}</mat-label>
            <input matInput [min]="minDate" [max]="maxDate" [matDatepicker]="joiningDatePicker" required formControlName="joiningDate">
            <mat-datepicker-toggle matSuffix [for]="joiningDatePicker"></mat-datepicker-toggle>
            <mat-datepicker #joiningDatePicker></mat-datepicker>
            <mat-error *ngIf="employeeForm.controls.joiningDate.hasError('required')">
              {{"labels.joiningDate" | translate}} <strong>{{"labels.commons.is required" | translate}}</strong>
            </mat-error>
          </mat-form-field>

        </div>

      </mat-card-content>

      <mat-card-actions fxLayout="row" fxLayout.xs="column" fxLayoutAlign="center" fxLayoutGap="5px">
        <button type="button" mat-raised-button [routerLink]="['../']">{{"labels.buttons.cancel" | translate}}</button>
        <button mat-raised-button color="primary" [disabled]="!employeeForm.valid" *mifosxHasPermission="'CREATE_STAFF'">{{"labels.buttons.submit" | translate}}</button>
      </mat-card-actions>

    </form>

  </mat-card>

</div>

<ng-template #templateCreateEmployeeForm let-popover="popover">
  <h2>{{"labels.buttons.createemployee" | translate}}</h2>
  <p class="mw400">{{"labels.ClickStartFillingDetails" | translate}}. * {{"labels.markFieldsNeccessary" | translate}}. <br> {{"labels.forMoreDetailsClick" | translate}}: <a href="" target="_blank">{{"labels.anchors.manageemployees" | translate}}</a></p>
  <div fxLayout="row" fxLayoutAlign="end" fxLayoutGap="2%" fxLayout.lt-md="column">
    <button mat-raised-button color="warn" (click)="popover.close();configurationWizardService.closeConfigWizard()">{{"labels.buttons.close" | translate}}</button>
    <button mat-raised-button color="primary" (click)="popover.close()">{{"labels.anchors.createemployee" | translate}}</button>
    <button mat-raised-button color="primary" (click)="popover.close();previousStep()">{{"labels.buttons.back" | translate}}</button>
    <button mat-raised-button color="primary" (click)="popover.close();nextStep()">{{"labels.buttons.next" | translate}}</button>
  </div>
</ng-template>
