<mat-card class="container" *ngIf="!isCollapsed">

  <form [formGroup]="fundMappingForm" (ngSubmit)="submit()">

    <div fxLayout="row wrap" fxLayoutGap="2%" fxLayout.lt-md="column" fxLayoutAlign.gt-sm="start center">

      <mat-form-field fxFlex="48%">
        <mat-label>{{"labels.inputs.adhoc.search.loanstatus" | translate}}</mat-label>
        <mat-select formControlName="loanStatus" multiple>
          <mat-option value="all">{{"labels.menus.All" | translate}}</mat-option>
          <mat-option value="active">{{"labels.heading.active" | translate}}</mat-option>
          <mat-option value="closed">{{"loanStatusType.overpaid" | translate}}</mat-option>
          <mat-option value="overpaid">{{"loanStatusType.closed.obligations.met" | translate}}</mat-option>
          <mat-option value="writeoff">{{"loanStatusType.closed.written.off" | translate}}</mat-option>
        </mat-select>
      </mat-form-field>

      <span fxFlex="48%" fxHide.lt-md></span>

      <mat-form-field fxFlex="48%">
        <mat-label>{{"labels.inputs.product" | translate}}</mat-label>
        <mat-select formControlName="loanProducts" multiple>
          <mat-option *ngFor="let product of advanceSearchTemplate.loanProducts" [value]="product.id">
            {{ product.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field fxFlex="48%">
        <mat-label>{{"labels.inputs.office" | translate}}</mat-label>
        <mat-select formControlName="offices" multiple>
          <mat-option *ngFor="let office of advanceSearchTemplate.offices" [value]="office.id">
            {{ office.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field fxFlex="31%">
        <mat-label>{{"labels.dateType" | translate}}</mat-label>
        <mat-select required formControlName="loanDateOption">
          <mat-option value="approvalDate">{{"labels.inputs.loan.approvaldate" | translate}}</mat-option>
          <mat-option value="createdDate">{{"labels.inputs.loan.createdondate" | translate}}</mat-option>
          <mat-option value="disbursalDate">{{"labels.heading.disbursementdate" | translate}}</mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field fxFlex="31%" (click)="fromDatePicker.open()">
        <mat-label>{{"labels.heading.fromdate" | translate}}</mat-label>
        <input matInput [min]="minDate" [max]="maxDate" [matDatepicker]="fromDatePicker"
          required formControlName="loanFromDate" required>
        <mat-datepicker-toggle matSuffix [for]="fromDatePicker"></mat-datepicker-toggle>
        <mat-datepicker #fromDatePicker></mat-datepicker>
        <mat-error *ngIf="fundMappingForm.controls.loanFromDate.hasError('required')">
          {{"labels.heading.fromdate" | translate}} <strong>{{"labels.commons.is required" | translate}}</strong>
        </mat-error>
      </mat-form-field>

      <mat-form-field fxFlex="31%" (click)="toDatePicker.open()">
        <mat-label>{{"labels.inputs.todate" | translate}}</mat-label>
        <input matInput [min]="minDate" [max]="maxDate" [matDatepicker]="toDatePicker"
          required formControlName="loanToDate" required>
        <mat-datepicker-toggle matSuffix [for]="toDatePicker"></mat-datepicker-toggle>
        <mat-datepicker #toDatePicker></mat-datepicker>
        <mat-error *ngIf="fundMappingForm.controls.loanToDate.hasError('required')">
          {{"labels.inputs.todate" | translate}} <strong>{{"labels.commons.is required" | translate}}</strong>
        </mat-error>
      </mat-form-field>

      <mat-checkbox fxFlex="73%" labelPosition="before" formControlName="includeOutStandingAmountPercentage" class="margin-b">
        {{"labels.inputs.adhoc.search.loanoutstanding.percentage" | translate}}
      </mat-checkbox>

      <div *ngIf="fundMappingForm.value.includeOutStandingAmountPercentage" fxFlexFill fxLayout="row wrap" fxLayoutGap="2%" fxLayout.lt-md="column">

        <mat-form-field *ngIf="fundMappingForm.contains('outStandingAmountPercentageCondition')" fxFlex="31%">
          <mat-label>{{"labels.heading.comparisonCondition" | translate}}</mat-label>
          <mat-select required formControlName="outStandingAmountPercentageCondition">
            <mat-option value="between">{{"labels.inputs.between" | translate}}</mat-option>
            <mat-option value="<="><=</mat-option>
            <mat-option value=">=">>=</mat-option>
            <mat-option value="<"><</mat-option>
            <mat-option value=">">></mat-option>
            <mat-option value="=">=</mat-option>
          </mat-select>
          <mat-error *ngIf="fundMappingForm.controls.outStandingAmountPercentageCondition.hasError('required')">
            {{"labels.heading.comparisonCondition" | translate}} <strong>{{"labels.commons.is required" | translate}}</strong>
          </mat-error>
        </mat-form-field>

        <mat-form-field *ngIf="fundMappingForm.contains('minOutStandingAmountPercentage')" fxFlex="31%">
          <mat-label>{{"labels.minimumValue" | translate}}</mat-label>
          <input type="number" matInput required formControlName="minOutStandingAmountPercentage">
          <mat-error *ngIf="fundMappingForm.controls.minOutStandingAmountPercentage.hasError('required')">
            {{"labels.minimumValue" | translate}} <strong>{{"labels.commons.is required" | translate}}</strong>
          </mat-error>
        </mat-form-field>

        <mat-form-field *ngIf="fundMappingForm.contains('outStandingAmountPercentage')" fxFlex="31%">
          <mat-label>{{"labels.comparisonValue" | translate}}</mat-label>
          <input type="number" matInput required formControlName="outStandingAmountPercentage">
          <mat-error *ngIf="fundMappingForm.controls.outStandingAmountPercentage.hasError('required')">
            {{"labels.comparisonValue" | translate}} <strong>{{"labels.commons.is required" | translate}}</strong>
          </mat-error>
        </mat-form-field>

        <mat-form-field *ngIf="fundMappingForm.contains('maxOutStandingAmountPercentage')" fxFlex="31%">
          <mat-label>{{"labels.maximumValue" | translate}}</mat-label>
          <input type="number" matInput required formControlName="maxOutStandingAmountPercentage">
          <mat-error *ngIf="fundMappingForm.controls.maxOutStandingAmountPercentage.hasError('required')">
            {{"labels.maximumValue" | translate}} <strong>{{"labels.commons.is required" | translate}}</strong>
          </mat-error>
        </mat-form-field>

      </div>

      <mat-checkbox fxFlex="73%" labelPosition="before" formControlName="includeOutstandingAmount" class="margin-b">
        {{"labels.loanOutstandingAmount" | translate}}
      </mat-checkbox>

      <div *ngIf="fundMappingForm.value.includeOutstandingAmount" fxFlexFill fxLayout="row wrap" fxLayoutGap="2%" fxLayout.lt-md="column">

        <mat-form-field *ngIf="fundMappingForm.contains('outstandingAmountCondition')" fxFlex="31%">
          <mat-label>{{"labels.heading.comparisonCondition" | translate}}</mat-label>
          <mat-select required formControlName="outstandingAmountCondition">
            <mat-option value="between">{{"labels.inputs.between" | translate}}</mat-option>
            <mat-option value="<="><=</mat-option>
            <mat-option value=">=">>=</mat-option>
            <mat-option value="<"><</mat-option>
            <mat-option value=">">></mat-option>
            <mat-option value="=">=</mat-option>
          </mat-select>
          <mat-error *ngIf="fundMappingForm.controls.outstandingAmountCondition.hasError('required')">
            {{"labels.heading.comparisonCondition" | translate}} <strong>{{"labels.commons.is required" | translate}}</strong>
          </mat-error>
        </mat-form-field>

        <mat-form-field *ngIf="fundMappingForm.contains('minOutstandingAmount')" fxFlex="31%">
          <mat-label>{{"labels.minimumValue" | translate}}</mat-label>
          <input type="number" matInput required formControlName="minOutstandingAmount">
          <mat-error *ngIf="fundMappingForm.controls.minOutstandingAmount.hasError('required')">
            {{"labels.minimumValue" | translate}} <strong>{{"labels.commons.is required" | translate}}</strong>
          </mat-error>
        </mat-form-field>

        <mat-form-field *ngIf="fundMappingForm.contains('outstandingAmount')" fxFlex="31%">
          <mat-label>{{"labels.comparisonValue" | translate}}</mat-label>
          <input type="number" matInput required formControlName="outstandingAmount">
          <mat-error *ngIf="fundMappingForm.controls.outstandingAmount.hasError('required')">
            {{"labels.comparisonValue" | translate}} <strong>{{"labels.commons.is required" | translate}}</strong>
          </mat-error>
        </mat-form-field>

        <mat-form-field *ngIf="fundMappingForm.contains('maxOutstandingAmount')" fxFlex="31%">
          <mat-label>{{"labels.maximumValue" | translate}}</mat-label>
          <input type="number" matInput required formControlName="maxOutstandingAmount">
          <mat-error *ngIf="fundMappingForm.controls.maxOutstandingAmount.hasError('required')">
            {{"labels.maximumValue" | translate}} <strong>{{"labels.commons.is required" | translate}}</strong>
          </mat-error>
        </mat-form-field>

      </div>

    </div>

    <mat-card-actions fxLayout="row" fxLayout.xs="column" fxLayoutAlign="center" fxLayoutGap="5px">
      <button type="button" mat-raised-button [routerLink]="['../']">{{"labels.buttons.cancel" | translate}}</button>
      <button mat-raised-button color="primary" [disabled]="!fundMappingForm.valid">
        <fa-icon icon="search"></fa-icon>&nbsp; {{"labels.buttons.summary" | translate}}
      </button>
    </mat-card-actions>

  </form>

</mat-card>


<div class="container" *ngIf="isCollapsed">

  <mat-card>

    <div class="m-b-20">
      <button mat-raised-button color="primary" (click)="isCollapsed = false">
        {{"labels.buttons.parameters" | translate}}
      </button>
    </div>

    <table class="mat-elevation-z1" mat-table [dataSource]="dataSource" matSort>

      <ng-container matColumnDef="officeName">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{"labels.heading.officeName" | translate}} </th>
        <td mat-cell *matCellDef="let loan"> {{ loan.officeName }}</td>
      </ng-container>

      <ng-container matColumnDef="productName">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{"labels.heading.productname" | translate}} </th>
        <td mat-cell *matCellDef="let loan"> {{ loan.loanProductName }}</td>
      </ng-container>

      <ng-container matColumnDef="count">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{"labels.heading.count" | translate}} </th>
        <td mat-cell *matCellDef="let loan"> {{ loan.count }} </td>
      </ng-container>

      <ng-container matColumnDef="outstanding">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{"labels.heading.outstanding" | translate}} </th>
        <td mat-cell *matCellDef="let loan"> {{ loan.loanOutStanding }} </td>
      </ng-container>

      <ng-container matColumnDef="percentage">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{"labels.heading.percentage" | translate}} </th>
        <td mat-cell *matCellDef="let loan"> {{ loan.percentage }} </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

    </table>

    <mat-paginator [pageSizeOptions]="[10, 25, 50, 100]" showFirstLastButtons></mat-paginator>

  </mat-card>

</div>
