<div fxLayout="row" fxLayoutAlign="end" fxLayoutGap="2%" fxLayout.lt-md="column" class="container m-b-20">
  <button mat-raised-button color="primary" [routerLink]="['edit']" *mifosxHasPermission="'UPDATE_CASHIER'">
    <fa-icon icon="edit"></fa-icon>&nbsp;&nbsp;
    {{"labels.inputs.edit" | translate}}
  </button>
  <button mat-raised-button color="warn" (click)="deleteCashier()" *mifosxHasPermission="'DELETECASHIER_TELLER'">
    <fa-icon icon="trash"></fa-icon>&nbsp;&nbsp;
    {{"labels.anchors.delete" | translate}}
  </button>
</div>


<div class="container">

  <mat-card>

    <mat-card-content>

      <div fxLayout="row wrap" class="content">

        <div fxFlex="50%" class="header">
          {{"labels.inputs.name" | translate}}
        </div>

        <div fxFlex="50%">
          {{ cashierData.staffName}}
        </div>

        <div fxFlex="50%" class="header">
          {{"labels.heading.cashmgmt.tellerName" | translate}}
        </div>

        <div fxFlex="50%">
          {{ cashierData.tellerName }}
        </div>

        <div fxFlex="50%" class="header" *ngIf="cashierData.description !== ''">
          {{"labels.heading.description" | translate}}
        </div>

        <div fxFlex="50%" *ngIf="cashierData.description !== ''">
          {{ cashierData.description }}
        </div>

        <div fxFlex="50%" class="header">
          {{"labels.inputs.startdate" | translate}}
        </div>

        <div fxFlex="50%">
          {{ cashierData.startDate  | dateFormat }}
        </div>

        <div fxFlex="50%" class="header">
          {{"labels.inputs.enddate" | translate}}
        </div>

        <div fxFlex="50%">
          {{ cashierData.endDate  | dateFormat }}
        </div>

        <div fxFlex="50%" class="header">
          {{"labels.inputs.teller.cashier.fullDay" | translate}}
        </div>

        <div fxFlex="50%">
          {{ cashierData.isFullDay }}
        </div>

      </div>

    </mat-card-content>

  </mat-card>

</div>
