<div fxLayout="row wrap" fxLayout.lt-md="column">

  <h3 class="mat-h3" fxFlexFill>{{'labels.heading.general' | translate}}</h3>

  <mat-divider fxFlexFill></mat-divider>

  <div fxFlexFill>
    <span fxFlex="40%">{{'labels.inputs.name' | translate}}</span>
    <span fxFlex="60%" *ngIf="client.legalFormId === 2">{{ client.fullname }}</span>
    <span fxFlex="60%" *ngIf="client.legalFormId === 1">{{ client.firstname }} {{ client.middlename ? client.middlename
      + ' ' + client.lastname : client.lastname }}</span>
  </div>

  <div fxFlexFill>
    <span fxFlex="40%">{{'labels.inputs.office' | translate}}</span>
    <span fxFlex="60%">{{ client.officeId | find:clientTemplate.officeOptions:'id':'name' }}</span>
  </div>

  <div fxFlexFill>
    <span fxFlex="40%">{{'labels.inputs.legalForm' | translate}}</span>
    <span fxFlex="60%">{{ client.legalFormId | find:clientTemplate.clientLegalFormOptions:'id':'value' }}</span>
  </div>

  <div fxFlexFill *ngIf="client.staffId">
    <span fxFlex="40%">{{'labels.inputs.staff' | translate}}</span>
    <span fxFlex="60%">{{ client.staffId | find:clientTemplate.staffOptions:'id':'displayName' }}</span>
  </div>

  <div fxFlexFill *ngIf="client.dateOfBirth">
    <span fxFlex="40%">{{ (client.legalFormId === 1 ? 'labels.inputs.dateofbirth' : 'labels.inputs.incorporationdate') | translate }}</span>
    <span fxFlex="60%">{{ client.dateOfBirth | dateFormat }}</span>
  </div>

  <div fxFlexFill *ngIf="client.externalId">
    <span fxFlex="40%">{{'labels.inputs.externalid' | translate}}</span>
    <span fxFlex="60%">{{ client.externalId }}</span>
  </div>

  <div fxFlexFill *ngIf="client.mobileNo">
    <span fxFlex="40%">{{'labels.inputs.mobileNumber' | translate}}</span>
    <span fxFlex="60%">{{ client.mobileNo }}</span>
  </div>

  <div fxFlexFill *ngIf="client.emailAddress">
    <span fxFlex="40%">{{'labels.inputs.email' | translate}}</span>
    <span fxFlex="60%">{{ client.emailAddress }}</span>
  </div>

  <div fxFlexFill *ngIf="client.clientTypeId">
    <span fxFlex="40%">{{'labels.inputs.clienttype' | translate}}</span>
    <span fxFlex="60%">{{ client.clientTypeId | find:clientTemplate.clientTypeOptions:'id':'name' }}</span>
  </div>

  <div fxFlexFill *ngIf="client.clientClassificationId">
    <span fxFlex="40%">{{'labels.inputs.clientclassification' | translate}}</span>
    <span fxFlex="60%">{{ client.clientClassificationId | find:clientTemplate.clientClassificationOptions:'id':'name'
      }}</span>
  </div>

  <div fxFlexFill *ngIf="client.savingsProductId">
    <span fxFlex="40%">{{'labels.anchors.savingproducts' | translate}}</span>
    <span fxFlex="60%">{{ client.savingsProductId | find:clientTemplate.savingProductOptions:'id':'name' }}</span>
  </div>

  <div fxFlexFill *ngIf="client.submittedOnDate">
    <span fxFlex="40%">{{'labels.inputs.submittedOnDate' | translate}}</span>
    <span fxFlex="60%">{{ client.submittedOnDate | dateFormat }}</span>
  </div>

  <div fxFlexFill>
    <span fxFlex="40%">{{'labels.heading.is_active' | translate}}</span>
    <span fxFlex="60%">{{ client.active ? 'Yes' : 'No' }}</span>
  </div>

  <div fxFlexFill *ngIf="client.activationDate">
    <span fxFlex="40%">{{'labels.inputs.activationdate' | translate}}</span>
    <span fxFlex="60%">{{ client.activationDate | dateFormat }}</span>
  </div>

  <ng-container *ngIf="client.legalFormId === 1">

    <div fxFlexFill *ngIf="client.genderId">
      <span fxFlex="40%">{{'labels.inputs.gender' | translate}}</span>
      <span fxFlex="60%">{{ client.genderId | find:clientTemplate.genderOptions:'id':'name' }}</span>
    </div>

    <div fxFlexFill>
      <span fxFlex="40%">{{'labels.inputs.isStaff' | translate}}</span>
      <span fxFlex="60%">{{ client.isStaff ? 'Yes' : 'No' }}</span>
    </div>

  </ng-container>

  <ng-container *ngIf="client.legalFormId === 2">

    <div fxFlexFill *ngIf="client.clientNonPersonDetails.incorpValidityTillDate">
      <span fxFlex="40%">{{'labels.inputs.incorpValidityTillDate' | translate}}</span>
      <span fxFlex="60%">{{ client.clientNonPersonDetails.incorpValidityTillDate | dateFormat }}</span>
    </div>

    <div fxFlexFill>
      <span fxFlex="40%">{{'labels.inputs.incorpNumber' | translate}}</span>
      <span fxFlex="60%">{{ client.clientNonPersonDetails.incorpNumber || 'Not Provided' }}</span>
    </div>

    <div fxFlexFill *ngIf="client.clientNonPersonDetails.mainBusinessLineId">
      <span fxFlex="40%">{{'labels.inputs.mainBusinessLine' | translate}}</span>
      <span fxFlex="60%">{{ client.clientNonPersonDetails.mainBusinessLineId |
        find:clientTemplate.clientNonPersonMainBusinessLineOptions:'id':'name' }}</span>
    </div>

    <div fxFlexFill *ngIf="client.clientNonPersonDetails.constitutionId">
      <span fxFlex="40%">{{'labels.inputs.constitution' | translate}}</span>
      <span fxFlex="60%">{{ client.clientNonPersonDetails.constitutionId |
        find:clientTemplate.clientNonPersonConstitutionOptions:'id':'name'}}</span>
    </div>

    <div fxFlexFill>
      <span fxFlex="40%">{{'labels.inputs.remarks' | translate}}</span>
      <span fxFlex="60%">{{ client.clientNonPersonDetails.remarks || 'N/A' }}</span>
    </div>

  </ng-container>

  <div fxFlexFill *ngIf="client?.familyMembers?.length" fxLayout="row wrap" fxLayout.lt-md="column" class="m-b-20">

    <h3 class="mat-h3" fxFlexFill>{{'labels.heading.familymembers' | translate}}</h3>

    <mat-divider fxFlexFill></mat-divider>

    <mat-accordion fxFlexFill class="m-t-5">

      <mat-expansion-panel *ngFor="let member of client.familyMembers" class="family-member">

        <mat-expansion-panel-header>

          <mat-panel-title>
            {{ member.firstName }} {{ member.middleName ? member.middleName + ' ' + member.lastName : member.lastName }}
          </mat-panel-title>

          <mat-panel-description>
            {{member.relationship}}
          </mat-panel-description>

        </mat-expansion-panel-header>

        <mat-divider></mat-divider>

        <p>
          {{ 'labels.inputs.firstname' | translate }} : {{ member.firstName }}<br />
          {{ 'labels.inputs.middlename' | translate }} : {{ member.middleName }}<br />
          {{ 'labels.inputs.lastname' | translate }} : {{ member.lastName }}<br />
          {{ 'labels.inputs.qualification' | translate }} : {{ member.qualification }}<br />
          {{ 'labels.inputs.relationship' | translate }} : {{ member.relationshipId |
          find:clientTemplate.familyMemberOptions.relationshipIdOptions:'id':'name' }}<br />
          {{ 'labels.inputs.age' | translate }} : {{ member.age }}<br />
          {{ 'labels.inputs.isDependent' | translate }} : {{ member.isDependent ? 'Yes' : 'No' }}<br />
          {{ 'labels.inputs.maritalstatus' | translate }} : {{ member.maritalStatusId |
          find:clientTemplate.familyMemberOptions.maritalStatusIdOptions:'id':'name' }}<br />
          {{ 'labels.inputs.gender' | translate }} : {{ member.genderId |
          find:clientTemplate.familyMemberOptions.genderIdOptions:'id':'name' }}<br />
          {{ 'labels.inputs.profession' | translate }} : {{ member.professionId |
          find:clientTemplate.familyMemberOptions.professionIdOptions:'id':'name' }}<br />
          {{ 'labels.inputs.dateofbirth' | translate }} : {{ member.dateOfBirth | dateFormat }}<br />
        </p>

      </mat-expansion-panel>

    </mat-accordion>

  </div>

  <div fxFlexFill *ngIf="clientTemplate.isAddressEnabled && client.address.length" fxLayout="row wrap"
    fxLayout.lt-md="column">

    <h3 class="mat-h3" fxFlexFill>{{ 'labels.heading.address' | translate }}</h3>

    <mat-divider fxFlexFill></mat-divider>

    <mat-accordion fxFlexFill class="m-t-5">

      <mat-expansion-panel *ngFor="let address of client.address" class="address">

        <mat-expansion-panel-header>

          <mat-panel-title>
            {{ getSelectedValue('addressTypeIdOptions',address.addressTypeId)?.name }}
          </mat-panel-title>

          <mat-panel-description>
            {{address.relationship}}
          </mat-panel-description>

        </mat-expansion-panel-header>

        <mat-divider></mat-divider>

        <p>
          <span *ngIf="isFieldEnabled('street')">{{ 'labels.inputs.street' | translate }} :
            {{address.street}}<br /></span>
          <span *ngIf="isFieldEnabled('addressLine1')">{{ 'labels.inputs.addressline1' | translate }} :
            {{address.addressLine1}}<br /></span>
          <span *ngIf="isFieldEnabled('addressLine2')">{{ 'labels.inputs.addressline2' | translate }} :
            {{address.addressLine2}}<br /></span>
          <span *ngIf="isFieldEnabled('addressLine3')">{{ 'labels.inputs.addressline3' | translate }} :
            {{address.addressLine3}}<br /></span>
          <span *ngIf="isFieldEnabled('townVillage')">{{ 'labels.inputs.town_village' | translate }} :
            {{address.townVillage}}<br /></span>
          <span *ngIf="isFieldEnabled('city')">{{ 'labels.inputs.city' | translate }} : {{address.city}}<br /></span>
          <span *ngIf="isFieldEnabled('stateProvinceId')">{{ 'labels.inputs.state_province' | translate }} :
            {{getSelectedValue('stateProvinceIdOptions',address.stateProvinceId)?.name}}<br /></span>
          <span *ngIf="isFieldEnabled('countryId')">{{ 'labels.inputs.country' | translate }} :
            {{getSelectedValue('countryIdOptions',address.countryId)?.name}}<br /></span>
          <span *ngIf="isFieldEnabled('postalCode')">{{ 'labels.inputs.postal_code' | translate }} :
            {{address.postalCode}}<br /></span>
          <span *ngIf="isFieldEnabled('isActive')">{{'labels.inputs.is_active' | translate}} :
            {{address.isActive}}<br /></span>
        </p>

      </mat-expansion-panel>

    </mat-accordion>

  </div>

</div>

<div fxLayout="row" class="margin-t" fxLayout.xs="column" fxLayoutAlign="center" fxLayoutGap="2%">
  <button mat-raised-button matStepperPrevious>
    <fa-icon icon="arrow-left"></fa-icon>&nbsp;&nbsp;
    {{'labels.buttons.previous' | translate}}
  </button>
  <button mat-raised-button [routerLink]="['../']">
    {{'labels.buttons.cancel' | translate}}
  </button>
  <button mat-raised-button color="primary" (click)="submit.emit()">
    {{'labels.buttons.submit' | translate}}
  </button>
</div>
