<h2 mat-dialog-title>{{"labels.menus.Configuration Wizard" | translate}}</h2>

<mat-dialog-content>

<h3 class="mw600"> {{"labels.youHaveCreated" | translate}} {{stepName}} {{"labels.successfully" | translate}}. {{"labels.PleaseSelectFromOptions" | translate}}</h3>

</mat-dialog-content>

<mat-dialog-actions>
    <button mat-raised-button color="warn"  [mat-dialog-close]="{ step: 1 }">{{"labels.closeConfigurationW" | translate}}</button>
    <button mat-raised-button color="primary" [mat-dialog-close]="{ step: 2 }">{{"labels.createMore" | translate}} {{stepName}}</button>
    <button mat-raised-button color="primary" [mat-dialog-close]="{ step: 3 }">{{"labels.goNextStep" | translate}}</button>
</mat-dialog-actions>
