<div class="container m-b-20" fxLayout="row" fxLayoutAlign="end">
  <button mat-raised-button color="primary" (click)="editSchedule()">
    <fa-icon icon="edit"></fa-icon>&nbsp;&nbsp;
    {{ 'labels.editSchedule' | translate }}
  </button>
</div>

<div class="container">

  <mat-card>

    <form [formGroup]="groupEditMeetingForm">

      <mat-card-content>

        <div fxLayout="column">

          <mat-form-field>
            <mat-label>{{ 'labels.repetitionFrequency' | translate }}</mat-label>
            <mat-select formControlName="frequency">
              <mat-option *ngFor="let option of frequencyOptions" [value]="option.id">
                {{ option.value }}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="groupEditMeetingForm.controls.frequency.hasError('repeatsOnDay')">
              {{ 'labels.repetitionFrequency' | translate }} <strong>{{"labels.commons.is required" | translate}}</strong>
            </mat-error>
          </mat-form-field>

          <mat-form-field>
            <mat-label>{{ 'labels.repetitionInterval' | translate }}</mat-label>
            <mat-select formControlName="interval">
              <mat-option *ngFor="let interval of repetitionIntervals" [value]="interval">
                {{ interval }}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="groupEditMeetingForm.controls.interval.hasError('repeatsOnDay')">
              {{ 'labels.repetitionInterval' | translate }} <strong>{{"labels.commons.is required" | translate}}</strong>
            </mat-error>
          </mat-form-field>

          <mat-form-field *ngIf="groupEditMeetingForm.contains('repeatsOnDay')">
          <mat-label>{{ 'labels.repeatsDay' | translate }}</mat-label>
          <mat-select formControlName="repeatsOnDay" required>
            <mat-option *ngFor="let day of repeatsOnDays" [value]="day.id">
              {{ day.value }}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="groupEditMeetingForm.controls.repeatsOnDay.hasError('repeatsOnDay')">
            {{ 'labels.atleast' | translate }} <strong>{{ 'labels.one' | translate }}</strong> {{ 'labels.dayMustBeSelected' | translate }}
          </mat-error>
        </mat-form-field>

        <mat-form-field class="m-b-5" (click)="startDatePicker.open()">
          <mat-label>{{ 'labels.inputs.calendar.changes.effective.from' | translate }}</mat-label>
          <input matInput [min]="minDate" [max]="maxDate" [matDatepicker]="startDatePicker" required formControlName="startDate">
          <mat-datepicker-toggle matSuffix [for]="startDatePicker"></mat-datepicker-toggle>
          <mat-datepicker #startDatePicker></mat-datepicker>
          <mat-error *ngIf="groupEditMeetingForm.controls.startDate.hasError('required')">
            {{ 'labels.changesAffectionDate' | translate }} <strong>{{"labels.commons.is required" | translate}}</strong>
          </mat-error>
          <mat-hint>
            {{ 'labels.noteEditCenterMeeting1' | translate }} <br>
            {{ 'labels.noteEditCenterMeeting2' | translate }}
          </mat-hint>
        </mat-form-field>

        </div>

      </mat-card-content>

      <mat-card-actions fxLayout="row" fxLayout.xs="column" fxLayoutAlign="center" fxLayoutGap="5px">
        <button type="button" mat-raised-button [routerLink]="['../../']">{{"labels.buttons.cancel" | translate}}</button>
        <button mat-raised-button color="primary" [disabled]="!groupEditMeetingForm.valid" (click)="submit()"
        *mifosxHasPermission="'CREATE_MEETING'">{{"labels.buttons.submit" | translate}}</button>
      </mat-card-actions>

    </form>

  </mat-card>

</div>
