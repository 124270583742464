<mat-card class="center-card">
  <mat-card-header fxLayout="column" class="mat-toolbar mat-primary header">

    <mat-card-actions class="center-actions" *ngIf="!(centerViewData.status.value==='Closed')">



      <button mat-raised-button [matMenuTriggerFor]="ManageClient">
        {{"labels.manage" | translate}}
        &nbsp;&nbsp;<i class="fa fa-caret-down drop-down-icon ng-scope"></i>
      </button>
      <mat-menu #ManageClient="matMenu" yPosition="below">
        <span *ngIf="!(centerViewData.status.value=='Active')">
          <button mat-menu-item *mifosxHasPermission="'ACTIVATE_CENTER'" (click)="doAction('Activate')">
            {{"labels.buttons.activate" | translate}}
          </button>
        </span>
        <button mat-menu-item *mifosxHasPermission="'UPDATE_CENTER'" (click)="doAction('Edit')">
          {{"labels.inputs.edit" | translate}}
        </button>
        <button mat-menu-item *mifosxHasPermission="'CREATE_GROUP'">
          {{"labels.heading.addgroup" | translate}}
        </button>
        <button mat-menu-item *mifosxHasPermission="'ASSOCIATEGROUPS_CENTER'" (click)="doAction('Manage Groups')">
          {{"labels.heading.managegroups" | translate}}
        </button>
        <span *ngIf="centerViewData.collectionMeetingCalendar">
          <button mat-menu-item *mifosxHasPermission="'SAVEORUPDATEATTENDANCE_MEETING'"
            (click)="doAction('Attendance')"> {{"labels.buttons.attendance" | translate}} </button>
        </span>
        <span *ngIf="!(centerViewData.staffId)">
          <button mat-menu-item *mifosxHasPermission="'ASSIGNSTAFF_GROUP'" (click)="doAction('Assign Staff')">
            {{"labels.inputs.assignstaff" | translate}}
          </button>
        </span>
        <span *ngIf="centerViewData.staffId">
          <button mat-menu-item *mifosxHasPermission="'UNASSIGNSTAFF_GROUP'" (click)="doAction('Unassign Staff')">
            {{"labels.heading.unassignstaff" | translate}}
          </button>
        </span>
        <span *ngIf="centerViewData.status.value==='Pending'">
          <button mat-menu-item *mifosxHasPermission="'DELETE_CENTER'" (click)="doAction('Delete')">
            {{"labels.anchors.delete" | translate}}
          </button>
        </span>
        <button mat-menu-item *mifosxHasPermission="'CLOSE_CENTER'" (click)="doAction('Close')">
          {{"labels.anchors.close" | translate}}
        </button>
        <span *ngIf="centerViewData.status.value==='Active' && !(centerViewData.collectionMeetingCalendar)">
          <button mat-menu-item *mifosxHasPermission="'CREATE_MEETING'" (click)="doAction('Attach Meeting')">
            {{"labels.anchors.attachmeeting" | translate}}
          </button>
        </span>

        <button mat-menu-item *mifosxHasPermission="'READ_Staff Assignment History'"
          (click)="doAction('Staff Assignment History')"> {{"labels.buttons.staffassignmenthistory" | translate}} </button>

      </mat-menu>




      <button mat-raised-button [matMenuTriggerFor]="Serve">
        {{"labels.serve" | translate}}
        &nbsp;&nbsp;<i class="fa fa-caret-down drop-down-icon ng-scope"></i>
      </button>
      <mat-menu #Serve="matMenu" yPosition="below">
        <span *ngIf="centerViewData.active">
          <button mat-menu-item *mifosxHasPermission="'CREATE_SAVINGSACCOUNT'"
            [routerLink]="['savings-accounts', 'create']">
            {{"labels.centersSavingApplication" | translate}}
          </button>
        </span>

      </mat-menu>












      <!-- <span *ngIf="!(centerViewData.status.value=='Active')">
        <button mat-raised-button *mifosxHasPermission="'ACTIVATE_CENTER'" (click)="doAction('Activate')"><i class="fa fa-check-sign"></i> Activate </button>
      </span>
      <button mat-raised-button *mifosxHasPermission="'UPDATE_CENTER'" (click)="doAction('Edit')"><i class="fa fa-edit"></i> Edit </button>
      <button mat-raised-button *mifosxHasPermission="'CREATE_GROUP'"><i class="fa fa-plus"></i> Add Group </button>
      <button mat-raised-button *mifosxHasPermission="'ASSOCIATEGROUPS_CENTER'" (click)="doAction('Manage Groups')"> <i class="fa fa-edit"></i> Manage Groups </button>
      <span  *ngIf="centerViewData.active">
      <button mat-raised-button *mifosxHasPermission="'CREATE_SAVINGSACCOUNT'" [routerLink]="['savings-accounts', 'create']">
        <i class="fa fa-file"></i> Centers Saving Application
      </button>
      </span>
      <button mat-raised-button [matMenuTriggerFor]="More"> More </button>
      <mat-menu #More="matMenu">
        <span *ngIf="centerViewData.collectionMeetingCalendar">
          <button mat-menu-item *mifosxHasPermission="'SAVEORUPDATEATTENDANCE_MEETING'" (click)="doAction('Attendance')"> Attendance </button>
        </span>
        <span *ngIf="!(centerViewData.staffId)">
          <button mat-menu-item *mifosxHasPermission="'ASSIGNSTAFF_GROUP'" (click)="doAction('Assign Staff')"> Assign Staff </button>
        </span>
        <span *ngIf="centerViewData.staffId">
          <button mat-menu-item *mifosxHasPermission="'UNASSIGNSTAFF_GROUP'" (click)="doAction('Unassign Staff')"> Unassign Staff </button>
        </span>
        <span *ngIf="centerViewData.status.value==='Active'">
          <button mat-menu-item *mifosxHasPermission="'DELETE_CENTER'" (click)="doAction('Delete')"> Delete </button>
        </span>
        <button mat-menu-item *mifosxHasPermission="'CLOSE_CENTER'" (click)="doAction('Close')"> Close </button>
        <span *ngIf="centerViewData.status.value==='Active' && !(centerViewData.collectionMeetingCalendar)">
          <button mat-menu-item *mifosxHasPermission="'CREATE_MEETING'" (click)="doAction('Attach Meeting')"> Attach Meeting </button>
        </span>
        <button mat-menu-item *mifosxHasPermission="'READ_Staff Assignment History'" (click)="doAction('Staff Assignment History')"> Staff Assignment History </button>
      </mat-menu> -->
    </mat-card-actions>
  </mat-card-header>
  <mat-card-content>
    <nav class="navigation-tabs">
      <a [routerLink]="['.']" routerLinkActive #general="routerLinkActive" 
      [ngClass]="general.isActive && this.router.url.split('/').length ===3 ? 'selected_tab': ''">
      {{"labels.heading.general" | translate}}
      </a>
      <a *mifosxHasPermission="'READ_GROUPNOTE'" [routerLink]="['./notes']" routerLinkActive #notes="routerLinkActive"
        [ngClass]="notes.isActive ? 'selected_tab': ''">
        {{"labels.heading.notes" | translate}}
      </a>
      <span *ngFor="let centerDatatable of centerDatatables">
        <a *mifosxHasPermission="'READ_' + centerDatatable.registeredTableName"
          [routerLink]="['./datatables',centerDatatable.registeredTableName]" routerLinkActive
          #datatable="routerLinkActive" [ngClass]="datatable.isActive ? 'selected_tab': ''">
          {{centerDatatable.registeredTableName}}
        </a>
      </span>
    </nav>
    <div class="content">
      <router-outlet></router-outlet>
    </div>
  </mat-card-content>
</mat-card>
