<div class="m-b-20" fxLayout="row" fxLayoutAlign="end" fxLayoutGap="20px">
  <div class="cbTitle">
    {{"labels.anchors.cbconfigured" | translate}}
    <button mat-raised-button color="primary" [routerLink]="['configuration']" >
      {{"labels.anchors.creditbureauconfiguration" | translate}}
    </button>
  </div>
</div>

<div class="container">
  <div class="container m-b-20" fxLayout="row" fxLayoutAlign="end" fxLayoutGap="5px" class="action">
    <button mat-raised-button color="primary" [routerLink]="['addcb']">
      {{"labels.addCreditBureau" | translate }}
    </button>
  </div>
  <div #loanProductsTable class="mat-elevation-z8">

    <table mat-table [dataSource]="dataSource" matSort>

      <ng-container matColumnDef="creditbureauId">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>{{"labels.inputs.creditbureauId" | translate}}</th>
        <td mat-cell *matCellDef="let cb"> {{ cb.index }} </td>
      </ng-container>

      <ng-container matColumnDef="alias">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>{{"labels.heading.alias" | translate}}</th>
        <td mat-cell *matCellDef="let cb"> {{ cb.alias }} </td>
      </ng-container>

      <ng-container matColumnDef="cbName">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{"labels.heading.cbName" | translate}} </th>
        <td mat-cell *matCellDef="let cb"> {{ cb.creditBureauName}} </td>
      </ng-container>

      <ng-container matColumnDef="cbproduct">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{"labels.heading.cbproduct" | translate}} </th>
        <td mat-cell *matCellDef="let cb"> {{ cb.creditBureauProduct}} </td>
      </ng-container>

      <ng-container matColumnDef="country">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{"labels.heading.country" | translate}} </th>
        <td mat-cell *matCellDef="let cb"> {{ cb.creditBureauCountry}} </td>
      </ng-container>

      <ng-container matColumnDef="is_active">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{"labels.heading.is_active" | translate}} </th>
        <td mat-cell *matCellDef="let cb">
          <i class="fa fa-check-circle" style="color:green" *ngIf="cb.isActive"></i>
          <i class="fa fa-times-circle" style="color:red" *ngIf="!cb.isActive"></i>
        </td>
      </ng-container>

      <ng-container matColumnDef="active/inactive">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{"labels.inputs.active" |
          translate}}/{{"labels.heading.inactive" | translate}} </th>
        <td mat-cell *matCellDef="let cb">

          <button (click)="changeCbStatus(cb)" mat-raised-button color="primary">
            {{ (cb.isActive ? "labels.buttons.disable" : "labels.buttons.enable") | translate}}
          </button>

        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="creditBureauColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: creditBureauColumns;" class="select-row"></tr>

    </table>

  </div>

</div>

<div class="m-b-20" fxLayout="row" fxLayoutAlign="end" fxLayoutGap="20px">
  <div class="cbTitle">
    {{"labels.anchors.cblpmapping" | translate}}
  </div>
</div>
<div class="container">
  <div class="container m-b-20" fxLayout="row" fxLayoutAlign="end" fxLayoutGap="5px" class="action">
    <button mat-raised-button color="primary" [routerLink]="['mapcblp']">
      {{"labels.anchors.mapcblp" | translate }}
    </button>
  </div>
  <div #loanProductsTable class="mat-elevation-z8">

    <table mat-table [dataSource]="dataSourceMapping" matSort>

      <ng-container matColumnDef="id">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>{{"labels.heading.id" | translate}}</th>
        <td mat-cell *matCellDef="let cb"> {{ cb.index }} </td>
      </ng-container>

      <ng-container matColumnDef="alias">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>{{"labels.heading.alias" | translate}}</th>
        <td mat-cell *matCellDef="let cb"> {{ cb.alias }} </td>
      </ng-container>

      <ng-container matColumnDef="cbsummary">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{"label.heading.cbsummary" | translate}} </th>
        <td mat-cell *matCellDef="let cb"> {{ cb.creditbureauSummary}} </td>
      </ng-container>

      <ng-container matColumnDef="loanproduct">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{"label.heading.loanproduct" | translate}} </th>
        <td mat-cell *matCellDef="let cb"> {{ cb.loanProductName}} </td>
      </ng-container>

      <ng-container matColumnDef="is_creditcheck_mandatory">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{"labels.heading.is_creditcheck_mandatory" | translate}} </th>
        <td mat-cell *matCellDef="let cb"> {{ cb.isCreditCheckMandatory}} </td>
      </ng-container>

      <ng-container matColumnDef="skip_credit_check_in_failure">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{"labels.heading.skip_credit_check_in_failure" | translate}} </th>
        <td mat-cell *matCellDef="let cb">
          {{cb?.skipCrediCheckInFailure}}
        </td>
      </ng-container>

      <ng-container matColumnDef="stale_period">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{"labels.heading.stale_period" | translate}} </th>
        <td mat-cell *matCellDef="let cb">
          {{cb.stalePeriod}}
        </td>
      </ng-container>

      <ng-container matColumnDef="is_active">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{"labels.inputs.is_active" |translate}}</th>
        <td mat-cell *matCellDef="let cb">

            <i class="fa fa-check-circle" style="color:green" *ngIf="cb.isActive"></i>
            <i class="fa fa-times-circle" style="color:red" *ngIf="!cb.isActive"></i>

        </td>
      </ng-container>
      <ng-container matColumnDef="active/inactive">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{"labels.inputs.active" |
          translate}}/{{"labels.heading.inactive" | translate}} </th>
        <td mat-cell *matCellDef="let cb">

          <button (click)="changeCbMappingStatus(cb)" mat-raised-button color="primary">
            {{ (cb.isActive ? "labels.buttons.disable" : "labels.buttons.enable") | translate}}
          </button>

        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="cbMappingsColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: cbMappingsColumns;" class="select-row"></tr>

    </table>

  </div>

</div>
