<form [formGroup]="recurringDepositProductTermsForm">

  <div fxLayout="row wrap" fxLayoutGap="2%" fxLayout.lt-md="column">

    <h4 fxFlex="98%" class="mat-h4">{{"labels.inputs.depositamount" | translate}} </h4>

    <mat-form-field fxFlex="31%">
      <mat-label>{{"labels.inputs.minimum" | translate}} </mat-label>
      <input decimalInput matInput formControlName="minDepositAmount">
    </mat-form-field>

    <mat-form-field fxFlex="31%">
      <mat-label>{{"labels.inputs.default" | translate}} </mat-label>
      <input decimalInput matInput formControlName="depositAmount" required>
      <mat-error>
        >{{"labels.inputs.default.deposit.amount" | translate}} <strong>{{"labels.commons.is required" | translate}}</strong>
      </mat-error>
    </mat-form-field>

    <mat-form-field fxFlex="31%">
      <mat-label>{{"labels.inputs.maximum" | translate}} </mat-label>
      <input decimalInput matInput formControlName="maxDepositAmount">
    </mat-form-field>

    <mat-divider fxFlex="98%"></mat-divider>

    <mat-form-field fxFlex="48%">
      <mat-label>{{"labels.heading.interestcompoundingperiod" | translate}} </mat-label>
      <mat-select formControlName="interestCompoundingPeriodType" required>
        <mat-option *ngFor="let interestCompoundingPeriodType of interestCompoundingPeriodTypeData" [value]="interestCompoundingPeriodType.id">
          {{ interestCompoundingPeriodType.value }}
        </mat-option>
      </mat-select>
      <mat-error>
       {{"labels.heading.interestcompoundingperiod" | translate}} <strong>{{"labels.commons.is required" | translate}}</strong>
      </mat-error>
    </mat-form-field>

    <mat-form-field fxFlex="48%">
      <mat-label>  {{"labels.heading.interestpostingperiod" | translate}} </mat-label>
      <mat-select formControlName="interestPostingPeriodType" required>
        <mat-option *ngFor="let interestPostingPeriodType of interestPostingPeriodTypeData" [value]="interestPostingPeriodType.id">
          {{ interestPostingPeriodType.value }}
        </mat-option>
      </mat-select>
      <mat-error>
       {{"labels.heading.interestpostingperiod" | translate}} <strong>{{"labels.commons.is required" | translate}}</strong>
      </mat-error>
    </mat-form-field>

    <mat-form-field fxFlex="48%">
      <mat-label>  {{"labels.heading.interestcalculatedusing" | translate}} </mat-label>
      <mat-select formControlName="interestCalculationType" required>
        <mat-option *ngFor="let interestCalculationType of interestCalculationTypeData" [value]="interestCalculationType.id">
          {{ interestCalculationType.value }}
        </mat-option>
      </mat-select>
      <mat-error>
       {{"labels.heading.interestcalculatedusing" | translate}} <strong>{{"labels.commons.is required" | translate}}</strong>
      </mat-error>
    </mat-form-field>

    <mat-form-field fxFlex="48%">
      <mat-label>{{"labels.heading.daysinyears" | translate}} </mat-label>
      <mat-select formControlName="interestCalculationDaysInYearType" required>
        <mat-option *ngFor="let interestCalculationDaysInYearType of interestCalculationDaysInYearTypeData" [value]="interestCalculationDaysInYearType.id">
          {{ interestCalculationDaysInYearType.value }}
        </mat-option>
      </mat-select>
      <mat-error>
        {{"labels.heading.daysinyears" | translate}} <strong>{{"labels.commons.is required" | translate}}</strong>
      </mat-error>
    </mat-form-field>

  </div>

  <div fxLayout="row" class="margin-t" fxLayout.xs="column" fxLayoutAlign="center" fxLayoutGap="2%">
    <button mat-raised-button matStepperPrevious>
      <fa-icon icon="arrow-left"></fa-icon>&nbsp;&nbsp;
      {{"labels.buttons.previous" | translate}}
    </button>
    <button mat-raised-button matStepperNext>
      {{"labels.buttons.next" | translate}}&nbsp;&nbsp;
      <fa-icon icon="arrow-right"></fa-icon>
    </button>
  </div>

</form>
