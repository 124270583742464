import { ActivatedRoute } from '@angular/router';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'mifosx-web-views-loan-transactions',
  templateUrl: './web-views-loan-transactions.component.html',
  styleUrls: ['./web-views-loan-transactions.component.scss']
})
export class WebViewsLoanTransactionsComponent implements OnInit {

  loanDetailsData: any;
  constructor(private route: ActivatedRoute) {
    this.route.data.subscribe((data: { loanDetailsData: any }) => {
      this.loanDetailsData = data.loanDetailsData;
    })

  }


  ngOnInit(): void {
  }

}
