<h1 mat-dialog-title>{{ data.columnName ? "Edit" : "Add" }} Column</h1>

<mat-dialog-content>

  <form [formGroup]="columnForm">

    <div fxLayout="column">

      <mat-form-field>
        <mat-label>{{"labels.inputs.columnname" | translate}}:</mat-label>
        <input matInput required formControlName="name">
        <mat-error *ngIf="columnForm.controls.name.hasError('required')">{{"labels.inputs.columnname" | translate}}:<strong>{{"labels.commons.is required" | translate}}</strong>
        </mat-error>
      </mat-form-field>

      <mat-form-field>
        <mat-label>{{"labels.columnType" | translate}}</mat-label>
        <mat-select required formControlName="type">
          <mat-option *ngFor="let columnType of columnTypeData" [value]="columnType.value">
            {{ columnType.displayValue }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <div>
        <mat-checkbox labelPosition="before" formControlName="mandatory">{{"labels.mandatory" | translate}}</mat-checkbox>
      </div>

      <mat-form-field>
        <mat-label>{{"labels.columnLength" | translate}}</mat-label>
        <input matInput required type="number" formControlName="length">
      </mat-form-field>

      <mat-form-field>
        <mat-label>{{"labels.columnCode" | translate}}</mat-label>
        <mat-select required formControlName="code">
          <mat-option *ngFor="let columnCode of data.columnCodes" [value]="columnCode.name">
            {{ columnCode.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>

    </div>

  </form>

</mat-dialog-content>

<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>{{"labels.buttons.cancel" | translate}}</button>
  <button mat-raised-button color="primary" [disabled]="!columnForm.valid || columnForm.pristine"
    (click)="submit()">{{"labels.buttons.submit" | translate}}</button>
</mat-dialog-actions>
