import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
/** Angular Imports */
import { Component, Inject } from '@angular/core';


@Component({
  selector: 'mifosx-whatsapp-dialog',
  templateUrl: './whatsapp-dialog.component.html',
  styleUrls: ['./whatsapp-dialog.component.scss']
})
export class WhatsappDialogComponent {

  whatsappForm: FormGroup;
  phoneReg = /^[1-9][0-9]{3,}/;

  constructor(
    private formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<WhatsappDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { phoneNumber: string },
  ) {
    this.whatsappForm = this.formBuilder.group({
      'phone': [data.phoneNumber, Validators.pattern(this.phoneReg)],
      'message': ['', Validators.required]
    });
    if (!this.phoneReg.test(data.phoneNumber) && data.phoneNumber) {
      this.whatsappForm.controls['phone'].setErrors({ 'pattern': true });
      this.whatsappForm.controls['phone'].markAsTouched();
    }
  }

  onCancelClick(): void {
    this.dialogRef.close();
  }


  onSendClick = function () {
    const { phone, message } = this.whatsappForm.value;
    const top = (window.innerHeight - 450) / 2;
    const left = (window.innerWidth - 900) / 2;
    let params = "scrollbars=no,resizable=yes,status=no,location=no,toolbar=no,menubar=no,height=450, width=900, top=" + top + ", left= " + left;
    let url = "https://web.whatsapp.com/send?text=" + message + "&phone=" + phone;
    open(url, 'whatsapp', params);
    this.dialogRef.close();
    this.whatsappForm.con
  }
}
