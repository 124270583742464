<div fxLayout="row wrap" fxLayoutGap="2%" fxLayout.lt-md="column">

  <h4 fxFlex="23%" class="mat-h4">{{"labels.inputs.marketpriceperiods" | translate}}</h4>

  <div fxFlex="73%">
    <button type="button" mat-raised-button color="primary" (click)="addMarketPricePeriod()">
      <fa-icon icon="plus"></fa-icon>&nbsp;&nbsp;
      {{"labels.buttons.Add" | translate}}
    </button>
  </div>

  <table fxFlex="98%" class="mat-elevation-z1" mat-table [dataSource]="marketPricePeriods.value" *ngIf="marketPricePeriods.value.length">

    <ng-container matColumnDef="fromDate">
      <th mat-header-cell *matHeaderCellDef>{{"labels.heading.fromdate" | translate }}</th>
      <td mat-cell *matCellDef="let marketPricePeriod">
        {{ marketPricePeriod.fromDate  | dateFormat }}
      </td>
    </ng-container>

    <ng-container matColumnDef="shareValue">
      <th mat-header-cell *matHeaderCellDef>{{"labels.heading.sharenominalprice" | translate }}</th>
      <td mat-cell *matCellDef="let marketPricePeriod">
        {{ marketPricePeriod.shareValue }}
      </td>
    </ng-container>

    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef>{{"labels.inputs.actions" | translate}} </th>
      <td mat-cell *matCellDef="let variation; let i = index">
        <button mat-icon-button color="primary" (click)="editMarketPricePeriod(i)">
          <fa-icon icon="edit"></fa-icon>
        </button>
        <button mat-icon-button color="warn" (click)="deleteMarketPricePeriod(i)">
          <fa-icon icon="trash"></fa-icon>
        </button>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

  </table>

</div>

<div fxLayout="row" class="margin-t" fxLayout.xs="column" fxLayoutAlign="center" fxLayoutGap="2%">
  <button mat-raised-button matStepperPrevious>
    <fa-icon icon="arrow-left"></fa-icon>&nbsp;&nbsp;
    {{"labels.heading.prev" | translate}}
  </button>
  <button mat-raised-button matStepperNext>
    {{"labels.buttons.next" | translate}}&nbsp;&nbsp;
    <fa-icon icon="arrow-right"></fa-icon>
  </button>
</div>
