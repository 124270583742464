<div fxLayout="row" fxLayoutAlign="end" fxLayoutGap="2%" fxLayout.lt-md="column" class="container m-b-20">
  <button mat-raised-button color="primary" (click)="editRoles(); backupCheckValues();"
    [disabled]="rolePermissionService.name === 'Super user'">
    <fa-icon icon="edit"></fa-icon>&nbsp;&nbsp;
    {{"labels.anchors.editrole" | translate}}
  </button>
</div>

<div class="container m-b-20">

  <mat-card class="mat-elevation-z8">

    <mat-card-content>

      <div fxLayout="row wrap" class="content">

        <div fxFlex="40%" fxFlex.lt-md="50%" class="header">
          <b>{{"labels.inputs.name" | translate}}:</b>
        </div>

        <div fxFlex="60%" fxFlex.lt-md="50%">
          {{ rolePermissionService.name }}
        </div>

        <div fxFlex="40%" fxFlex.lt-md="50%" class="header">
          <b>{{"labels.inputs.description" | translate}}:</b>
        </div>

        <div fxFlex="60%" fxFlex.lt-md="50%">
          {{ rolePermissionService.description }}
        </div>

      </div>

    </mat-card-content>

  </mat-card>

</div>

<div class="mat-elevation-z8 container">

  <mat-card>

    <ng-container>

      <div fxLayout="row" class="permissionSelected">
        <div fxFlex="75">
          <h3>{{"labels.permissions" | translate}}:<strong>{{ formatName(previousGrouping) }}</strong>
          </h3>
        </div>

        <div fxFlex="25" fxLayout="row" fxLayout.xs="column" fxLayoutAlign="end" fxLayoutGap="5px" *ngIf="!isDisabled"
          class="selectDeselect">
          <button mat-raised-button color="primary" (click)="selectAll()">{{"labels.buttons.selectAll" | translate}}:</button>
          <button mat-raised-button color="default" (click)="deselectAll()">{{"labels.buttons.deselectAll" | translate}}:</button>
        </div>

      </div>

      <div fxLayout="row">

        <div fxFlex="30" fxLayout="column">
          <mat-list>
            <mat-list-item [ngClass]="selectedItem == grouping ? 'active' : 'inactive'"
              *ngFor="let grouping of groupings" (click)="showPermissions(grouping)">
              <span class="groupingName">
                {{ formatName(grouping) }}
              </span>
            </mat-list-item>
          </mat-list>

        </div>

        <mat-divider [vertical]="true"></mat-divider>

        <div fxFlex="70" fxLayout="column" class="listPermission">

          <form [formGroup]="formGroup" (submit)="submit()">

            <div *ngFor="let permission of permissions.permissions" class="displayPermissions">
              <div formArrayName="roster">
                <div>
                    {{ permissionName(permission.code) }}
                </div>
                <div *ngFor="let field of  tempFieldsUIData[permission.code].fields" class="displayFields">
                    <div [formGroupName]="field.id" *ngIf="field.name!=='ALL_FIELD'">
                      <mat-checkbox name="cp" id="{{ field.name }}" formControlName="selected" (change)="checkifAllSelected(field.code)">
                        {{field.name}}
                      </mat-checkbox>
                  </div>
                </div>
              </div>
            </div>

          </form>
        </div>
      </div>
    </ng-container>

    <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="center" fxLayoutGap="5px" *ngIf="!isDisabled">
      <mat-card-actions>
        <button type="button" mat-raised-button (click)="cancel(); restoreCheckboxes();">{{"labels.buttons.cancel" | translate}}</button>
        <button mat-raised-button color="primary" (click)="submit()">{{"labels.buttons.submit" | translate}}</button>
      </mat-card-actions>
    </div>

  </mat-card>

</div>
