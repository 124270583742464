<div class="container m-b-20" fxLayout="row" fxLayoutAlign="end" fxLayoutGap="20px">

  <button mat-raised-button color="primary" [routerLink]="['create']"
    *mifosxHasPermission="'CREATE_ENTITY_DATATABLE_CHECK'">
    <fa-icon icon="plus"></fa-icon>&nbsp;&nbsp;
    {{'labels.buttons.create' | translate}}
  </button>

</div>

<div class="container">

  <div fxLayout="row" fxLayoutGap="20px">
    <mat-form-field fxFlex>
      <mat-label>{{'labels.buttons.filter' | translate}}</mat-label>
      <input matInput (keyup)="applyFilter($event.target.value)">
    </mat-form-field>
  </div>

  <div class="mat-elevation-z8">

    <table mat-table [dataSource]="dataSource" matSort>

      <ng-container matColumnDef="entity">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'labels.heading.entity' | translate}} </th>
        <td mat-cell *matCellDef="let entityDataTableCheck"> {{ entityDataTableCheck.entity }} </td>
      </ng-container>

      <ng-container matColumnDef="productName">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'labels.heading.product' | translate}} </th>
        <td mat-cell *matCellDef="let entityDataTableCheck"> {{ entityDataTableCheck.productName }} </td>
      </ng-container>

      <ng-container matColumnDef="datatableName">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'labels.heading.datatable' | translate}} </th>
        <td mat-cell *matCellDef="let entityDataTableCheck"> {{ entityDataTableCheck.datatableName }} </td>
      </ng-container>

      <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'labels.heading.status' | translate}} </th>
        <td mat-cell *matCellDef="let entityDataTableCheck"> {{ entityDataTableCheck.status.value }} </td>
      </ng-container>

      <ng-container matColumnDef="systemDefined">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'labels.heading.systemdefined' | translate}} </th>
        <td mat-cell *matCellDef="let entityDataTableCheck">
          <div [className]="entityDataTableCheck.systemDefined === true ? 'true' : 'false'">
            <fa-icon *ngIf="entityDataTableCheck.systemDefined" icon="check-circle" size="lg" [matTooltip]="'labels.yes'| translate"
              matTooltipPosition="right"></fa-icon>
            <fa-icon *ngIf="!entityDataTableCheck.systemDefined" icon="times-circle" size="lg" [matTooltip]="'labels.no'| translate"
              matTooltipPosition="right"></fa-icon>
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef> {{'labels.heading.actions' | translate}} </th>
        <td mat-cell *matCellDef="let entityDataTableCheck">
          <button mat-button (click)="deleteEntityDataTableCheck(entityDataTableCheck.id)" color="warn"
            *mifosxHasPermission="'DELETE_ENTITY_DATATABLE_CHECK'">
            <fa-icon icon="trash"></fa-icon>&nbsp;&nbsp;{{'labels.buttons.delete' | translate}}
          </button>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

    </table>

    <mat-paginator [pageSizeOptions]="[10, 25, 50, 100]" showFirstLastButtons></mat-paginator>

  </div>

</div>