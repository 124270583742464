import { ActivatedRouteSnapshot } from '@angular/router';
/** Angular Imports */
import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';

/** rxjs Imports */
import { Observable } from 'rxjs';

/** Custom Services */
import { ProductsService } from '../products.service';

/**
 * Credit Lignes data resolver.
 */
@Injectable()
export class ProductLineResolver implements Resolve<Object> {

  /**
   *
   * @param {ProductsService} productsService Products service.
   */
  constructor(private productsService: ProductsService) {}

  /**
   * Returns the credit lignes data.
   * @returns {Observable<any>}
   */
  resolve(route: ActivatedRouteSnapshot): Observable<any> {
    const productLineId = route.paramMap.get('id');
    return this.productsService.getProductLigne(productLineId);
  }

}
