import { ConfirmationDialogComponent } from 'app/shared/confirmation-dialog/confirmation-dialog.component';
import { MatDialog } from '@angular/material/dialog';
/** Angular Imports */
import { Component, OnInit, AfterViewInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

/** Custom Services */
import { AccountTransfersService } from '../../../account-transfers/account-transfers.service';
import { SettingsService } from 'app/settings/settings.service';
import { ClientsService } from 'app/clients/clients.service';
import { Dates } from 'app/core/utils/dates';


/**
 * Create account transfers
 */
@Component({
  selector: 'mifosx-savings-transfer',
  templateUrl: './savings-transfer.component.html',
  styleUrls: ['./savings-transfer.component.scss']
})
export class SavingsTransferComponent implements OnInit, AfterViewInit {

  /** Standing Instructions Data */
  accountTransferTemplateData: any;
  /** Minimum date allowed. */
  minDate = new Date(2000, 0, 1);
  /** Maximum date allowed. */
  maxDate = new Date(2100, 0, 1);
  /** Edit Standing Instructions form. */
  makeAccountTransferForm: FormGroup;
  /** To Office Type Data */
  toOfficeTypeData: any;
  /** To Client Type Data */
  toClientTypeData: any;
  /** To Account Type Data */
  toAccountTypeData: any;
  /** To Account Data */
  toAccountData: any;
  /** From Account Data */
  fromAccountData: any;
  /** Account Type Id */
  accountTypeId: any;
  /** Account Type */
  accountType: any;
  /** Savings Id or Loans Id */
  id: any;
  /** Clients Data */
  clientsData: any;
  /** From Clients Data */
  clientsFromData: any;

  /**
   * Retrieves the standing instructions template from `resolve`.
   * @param {ActivatedRoute} route Activated Route.
   * @param {FormBuilder} formBuilder Form Builder
   * @param {Router} router Router
   * @param {AccountTransfersService} accountTransfersService Account Transfers Service
   * @param {Dates} dateUtils Date Utils
   * @param {SettingsService} settingsService Settings Service
   * @param {ClientsService} clientsService Clients Service
   */
  constructor(private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private accountTransfersService: AccountTransfersService,
    private dateUtils: Dates,
    private settingsService: SettingsService,
    private clientsService: ClientsService,
    public dialog: MatDialog,) {

  }
  /** Sets the value from the URL */
  setParams() {
    this.accountType = this.route.snapshot.queryParams['accountType'];
    switch (this.accountType) {
      case 'fromloans':
        this.accountTypeId = '1';
        this.id = this.route.snapshot.queryParams['loanId'];
        break;
      case 'fromsavings':
        this.accountTypeId = '2';
        this.id = this.route.snapshot.queryParams['savingsId'];
        break;
      default:
        this.accountTypeId = '0';
    }
  }

  /**
   * Creates and sets the create standing instructions form.
   */
  ngOnInit() {
    this.createMakeAccountTransferForm();
    this.disableTransferTo();
  }

  disableTransferTo() {
    this.makeAccountTransferForm.controls.toClientId.disable();
    this.makeAccountTransferForm.controls.toOfficeId.disable();
    this.makeAccountTransferForm.controls.toAccountType.disable();
    this.makeAccountTransferForm.controls.toAccountId.disable();
  }

  enableTransferTo() {
    this.makeAccountTransferForm.controls.toClientId.enable();
    this.makeAccountTransferForm.controls.toOfficeId.enable();
    this.makeAccountTransferForm.controls.toAccountType.enable();
    this.makeAccountTransferForm.controls.toAccountId.enable();
  }

  /**
   * Creates the standing instruction form.
   */
  createMakeAccountTransferForm() {
    this.makeAccountTransferForm = this.formBuilder.group({
      'toOfficeId': ['', Validators.required],
      'fromClientId': ['', Validators.required],
      'toClientId': ['', Validators.required],
      'toAccountType': ['', Validators.required],
      'toAccountId': ['', Validators.required],
      'fromAccountId': ['', Validators.required],
      'transferAmount': ['', Validators.required],
      'transferDate': ['', Validators.required],
      'transferDescription': ['', Validators.required],
    });
  }

  /** Sets options value */
  setOptions() {
    this.toOfficeTypeData = this.accountTransferTemplateData.toOfficeOptions;
    this.toAccountTypeData = this.accountTransferTemplateData.toAccountTypeOptions;
    this.toAccountData = this.accountTransferTemplateData.toAccountOptions;
  }

  /** Executes on change of various select options */
  changeEvent() {
    const formValue = this.refineObject(this.makeAccountTransferForm.value);
    this.accountTransfersService.newAccountTranferResource(this.id, this.accountTypeId, formValue).subscribe((response: any) => {
      this.accountTransferTemplateData = response;
      this.toClientTypeData = response.toClientOptions;
      this.setOptions();
    });
  }

  accountChangeEvent() {
    this.accountTypeId = 2;
    this.id = this.makeAccountTransferForm.value.fromAccountId;
    this.enableTransferTo();
  }

  /** Refine Object
   * Removes the object param with null or '' values
   */
  refineObject(dataObj: { [x: string]: any; transferAmount: any; transferDate: any; transferDescription: any; }) {

    delete dataObj.transferAmount;
    delete dataObj.transferDate;
    delete dataObj.transferDescription;
    if (dataObj.toClientId) {
      dataObj.toClientId = dataObj.toClientId.id;
    }
    if (dataObj.fromClientId) {
      dataObj.fromClientId = dataObj.fromClientId.id;
    }
    const propNames = Object.getOwnPropertyNames(dataObj);
    for (let i = 0; i < propNames.length; i++) {
      const propName = propNames[i];
      if (dataObj[propName] === null || dataObj[propName] === undefined || dataObj[propName] === '') {
        delete dataObj[propName];
      }
    }
    return dataObj;
  }

  refineObjectFromClient(dataObj: { [x: string]: any; transferAmount: any; transferDate: any; transferDescription: any; }) {

    delete dataObj.transferAmount;
    delete dataObj.transferDate;
    delete dataObj.transferDescription;
    delete dataObj.toClientId;
    if (dataObj.fromClientId.id) {
      dataObj.fromClientId = dataObj.fromClientId.id;
      dataObj.fromAccountType = 2;
    } else {
      delete dataObj.fromClientId;
    }
    const propNames = Object.getOwnPropertyNames(dataObj);
    for (let i = 0; i < propNames.length; i++) {
      const propName = propNames[i];
      if (dataObj[propName] === null || dataObj[propName] === undefined || dataObj[propName] === '') {
        delete dataObj[propName];
      }
    }
    return dataObj;
  }

  /**
   * Subscribes to Clients search filter:
   */
  ngAfterViewInit() {
    this.makeAccountTransferForm.controls.toClientId.valueChanges.subscribe((value: string) => {
      if (value.length >= 2) {
        this.clientsService.getFilteredClients('displayName', 'ASC', false, value)
          .subscribe((data: any) => {
            console.log(data);
            this.clientsData = data.pageItems;
          });
        this.changeEvent();
      }
    });

    this.makeAccountTransferForm.controls.fromClientId.valueChanges.subscribe((value: string) => {
      if (value.length >= 2) {
        this.clientsService.getFilteredClients('displayName', 'ASC', false, value)
          .subscribe((data: any) => {
            console.log(data);
            this.clientsFromData = data.pageItems;
          });
      }
    });
  }

  /**
   * Displays Client name in form control input.
   * @param {any} client Client data.
   * @returns {string} Client name if valid otherwise undefined.
   */
  displayClient(client: any): string | undefined {
    return client ? client.displayName : undefined;
  }

  updateMySelection(event: any) {
    const client = event.option.value
    this.accountTransfersService.savingsAccountTransfer(client.id, 2).subscribe((response: any) => {
      this.fromAccountData = response.fromAccountOptions;
      this.toOfficeTypeData = response.toOfficeOptions;
    });
  }

  /**
   * Submits the standing instructions form
   */
  submit() {
    const unAssignStaffDialogRef = this.dialog.open(ConfirmationDialogComponent, {
      data: { heading: 'labels.anchors.accounttransfers', dialogContext: 'transferConfirmation' }
    });
    unAssignStaffDialogRef.afterClosed().subscribe((response: { confirm: any }) => {
      if (response.confirm) {
        const dateFormat = 'yyyy-MM-dd';
        const locale = this.settingsService.language.code;
        const urlRedirection = '/clients/' + this.makeAccountTransferForm.value.fromClientId.id + '/savings-accounts/' + this.id + '/transactions';
        const makeAccountTransferData = {
          ... this.makeAccountTransferForm.value,
          transferDate: this.dateUtils.formatDate(this.makeAccountTransferForm.value.transferDate, dateFormat),
          dateFormat,
          locale,
          toClientId: this.makeAccountTransferForm.controls.toClientId.value.id,
          fromAccountId: this.id,
          fromAccountType: this.accountTypeId,
          fromClientId: this.accountTransferTemplateData.fromClient.id,
          fromOfficeId: this.accountTransferTemplateData.fromClient.officeId
        };

        this.accountTransfersService.createAccountTransfer(makeAccountTransferData).subscribe(() => {
          this.router.navigate(['../..' + urlRedirection], { relativeTo: this.route });
        });
      }
    });
  }

}
