import { TranslateService } from '@ngx-translate/core';
import { ExportExcelService } from './../../../core/exportExcel/ExportExcel.service';
import { FormControl } from '@angular/forms';
/** Angular Imports */
import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SelectionModel } from '@angular/cdk/collections';
import * as _ from 'lodash';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialog } from '@angular/material/dialog';


/** Custom Services */

import { SettingsService } from 'app/settings/settings.service';
import { Dates } from 'app/core/utils/dates';
import { TasksService } from 'app/tasks/tasks.service';
import * as moment from 'moment';
import { MatPaginator } from '@angular/material/paginator';
@Component({
  selector: 'mifosx-viewaccounts',
  templateUrl: './viewaccounts.component.html',
  styleUrls: ['../../operations.module.scss']
})
export class ViewaccountsComponent implements OnInit {

  /** Offices Data */
  offices: any;
  /** accounts Data */
  accounts: any;
  filteredAccounts: any;
  showSearch = false;

  statusList: any = [];
  agencies: any = [];
  savingsproducts: any = [];

  /** Checks whether to show data or not */
  showData = false;
  /** Data source for accounts approval table. */
  dataSource: MatTableDataSource<any>;
  /** Row Selection Data */
  selection: SelectionModel<any>;
  /** Map data */
  idToNodeMap = {};
  /** Grouped Office Data */
  officesArray: any[];
  /** List of Requests */
  batchRequests: any[];
  /** Displayed Columns */
  displayedColumns: string[] = ['status', 'accountNo', 'clientName', 'savingsProductName', 'accountBalance', 'availableBalance', 'lastActive', 'officeName'];

  maxDate = new Date();
  accountNoFilter = new FormControl();
  clientNameFilter = new FormControl();
  savingsProductNameFilter = new FormControl();
  accountBalanceFilter = new FormControl();
  availableBalanceFilter = new FormControl();
  lastActiveFilter = new FormControl();
  officeNameFilter = new FormControl();
  statusFilter = new FormControl();
  fromPrincipalFilter = new FormControl();
  toPrincipalFilter = new FormControl();
  endDateFilter = new FormControl();
  startDateFilter = new FormControl();

  advencedSearch = () => {
    this.showSearch = !this.showSearch;
  }
  applyFilters = () => {
    this.filteredAccounts = this.accounts.filter((account: any) => {
      const date = account?.lastActiveTransactionDate;
      const lastActiveDate = date ? new Date(date[0], date[1] - 1, date[2]) : undefined;

      const accountNo = this.accountNoFilter.value ? account?.accountNo?.toLowerCase().includes(this.accountNoFilter.value?.toLowerCase()) : true;
      const clientName = this.clientNameFilter.value ? account?.clientName?.toLowerCase().includes(this.clientNameFilter.value?.toLowerCase()) : true;
      const savingsProductName = this.savingsProductNameFilter.value ? account?.savingsProductName?.toLowerCase().includes(this.savingsProductNameFilter.value?.toLowerCase()) : true
      const accountBalance = this.accountBalanceFilter.value ? account?.summary?.accountBalance.toString() === (this.accountBalanceFilter.value.toString()) : true
      const availableBalance = this.availableBalanceFilter.value ? account?.summary?.availableBalance.toString()=== (this.availableBalanceFilter.value.toString()) : true;
      const lastActive = this.lastActiveFilter.value ? moment(lastActiveDate).isSame(this.lastActiveFilter.value) : true;

      const officeName = this.officeNameFilter.value ? account?.officeName.toLowerCase().includes(this.officeNameFilter.value?.toLowerCase()) : true;
      const status = this.statusFilter.value ? (account?.status?.code.toLowerCase() === this.statusFilter.value?.status?.toLowerCase() && account?.subStatus.value?.toLowerCase() === this.statusFilter.value?.substatus?.toLowerCase()) : true;


      const fromPrincipal = this.fromPrincipalFilter.value || this.fromPrincipalFilter.value === 0 ? account?.summary?.accountBalance >= this.fromPrincipalFilter.value : true;
      const toPrincipal = this.toPrincipalFilter.value || this.toPrincipalFilter.value === 0 ? account?.summary?.accountBalance <= this.toPrincipalFilter.value : true;

      const fromDate = this.startDateFilter.value ? moment(lastActiveDate).isSameOrAfter(this.startDateFilter.value) : true;
      const toDate = this.endDateFilter.value ? moment(lastActiveDate).isSameOrBefore(this.endDateFilter.value) : true;

      return status && accountNo && clientName && savingsProductName && accountBalance && availableBalance && lastActive && officeName && fromDate && toDate && fromPrincipal && toPrincipal;
    });
    this.dataSource.data = this.filteredAccounts;
  }
  exportexcel(): void {
    const dateFormat = 'yyyy-MM-dd';
    const data = this.filteredAccounts.map((account: any) => ({
      Status: this.translateService.instant(account.status.code),
      Substatus: this.translateService.instant(account.subStatus.value),
      SavingAccount: account.accountNo,
      Client: account.clientName,
      Type: account.savingsProductName,
      Balance: account.summary?.accountBalance,
      AvailableBalance: account.summary?.availableBalance,
      LastActive:this.dateUtils.formatDate(account.lastActiveTransactionDate,dateFormat),
      Office: account.officeName,
    }))

    const header = ['labels.inputs.status', 'labels.Substatus','labels.heading.savingaccount', 'labels.heading.client', 'labels.heading.accounttype', 'labels.heading.balance', 'labels.heading.availablebalance', 'labels.heading.lastActive', 'labels.heading.officeName'];
    this.exportExcelService.exportExcelFile({data:data,header:header,fileName:'view_accounts.xlsx'})
  }

  /**
    * Retrieves the offices and accounts data from `resolve`.
    * @param {ActivatedRoute} route Activated Route.
    * @param {Dialog} dialog MatDialog.
    * @param {Dates} dateUtils Date Utils.
    * @param {router} router Router.
    * @param {SettingsService} settingsService Settings Service.
    * @param {TasksService} tasksService Tasks Service.
    */
  constructor(private route: ActivatedRoute,
    private dialog: MatDialog,
    private dateUtils: Dates,
    private router: Router,
    private settingsService: SettingsService,
    private tasksService: TasksService,
    private exportExcelService: ExportExcelService,
    private translateService: TranslateService) {
    this.route.data.subscribe((data: { viewaccountsTemplate: any }) => {
      this.accounts = data.viewaccountsTemplate?.pageItems;
      this.filteredAccounts = [...this.accounts];
      this.dataSource = new MatTableDataSource(this.filteredAccounts);
      this.selection = new SelectionModel(true, []);
      if (this.accounts.lenght !== 0) {
        this.showData = true
      }

      this.accounts.forEach((account: any) => {
        if (!this.statusList.some((status: any) => (status.status === account.status.code && status.substatus === account.subStatus.value))) {
          this.statusList.push({ status: account.status.code, substatus: account.subStatus.value })
        }
        if (this.agencies.indexOf(account.officeName) === -1) {
          this.agencies.push(account?.officeName)
        }
        if (this.savingsproducts.indexOf(account.savingsProductName) === -1) {
          this.savingsproducts.push(account.savingsProductName);
        }
      });
    });
  }
  @ViewChild(MatPaginator) paginator: MatPaginator;

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
  }

  applyFilter(filterValue: string = '') {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  reload() {
    const url: string = this.router.url;
    this.router.navigateByUrl(`/checker-inbox-and-tasks`, { skipLocationChange: true })
      .then(() => this.router.navigate([url]));
  }
  ngOnInit(): void {
  }

}
