<div class="tab-container mat-typography" *ngIf="accounts.length > 0">
  <div fxLayout="row" fxLayoutAlign="start center">
    <div class="search-box" fxFlex="40%">
      <mat-form-field fxFlex="90%">
        <input
          matInput
          [placeholder]="'labels.inputs.filterbyname' | translate"
          (keyup)="applyFilter($event.target.value)"
        />
      </mat-form-field>
    </div>
    <div fxFlex="60%">
      <button
        mat-raised-button
        color="success"
        *mifosxHasPermission="'ACTIVATE_SAVINGSACCOUNT'"
        (click)="activateAccount()"
      >
        <fa-icon icon="check"></fa-icon>&nbsp;&nbsp;{{ "labels.anchors.activate" | translate }}
      </button>
    </div>
  </div>

  <table mat-table [dataSource]="dataSource">
    <ng-container matColumnDef="select">
      <th mat-header-cell *matHeaderCellDef>
        <mat-checkbox
          (change)="$event ? masterToggle() : null"
          [checked]="selection.hasValue() && isAllSelected()"
          [indeterminate]="selection.hasValue() && !isAllSelected()"
          [aria-label]="checkboxLabel()"
        >
        </mat-checkbox>
      </th>
      <td mat-cell *matCellDef="let row">
        <mat-checkbox
          (click)="$event.stopPropagation()"
          (change)="$event ? selection.toggle(row) : null"
          [checked]="selection.isSelected(row)"
          [aria-label]="checkboxLabel(row)"
        >
        </mat-checkbox>
      </td>
    </ng-container>

    <ng-container matColumnDef="client">
      <th mat-header-cell *matHeaderCellDef>{{ "labels.inputs.clientName" | translate }}</th>
      <td
        mat-cell
        *matCellDef="let savings"
        class="view-details"
        [routerLink]="['../../clients', savings.clientId, 'general']"
      >
        {{ savings.clientName }}
      </td>
    </ng-container>

    <ng-container matColumnDef="savingsAccountNumber">
      <th mat-header-cell *matHeaderCellDef>{{ "labels.heading.savingsaccountno" | translate }}</th>
      <td
        mat-cell
        *matCellDef="let savings"
        class="view-details"
        [routerLink]="['../../clients', savings.clientId, 'savings-accounts', savings.id]"
      >
        {{ savings.accountNo }}
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns" (click)="selection.toggle(row)"></tr>
  </table>
</div>

<div class="alert" *ngIf="accounts.length === 0">
  <div class="message">
    <i class="fa fa-exclamation-circle alert-check"></i>
    {{ "labels.noPendingAccountAvailable" | translate }}
  </div>
</div>
