<div class="container m-b-20" fxLayout="row" fxLayoutAlign="end" fxLayoutGap="20px">

  <div #buttonImportEmployees>
    <button mat-raised-button color="primary" *mifosxHasPermission="'CREATE_STAFF'"
      [routerLink]="['/', 'organization', 'bulk-import', 'Employees']">
      <fa-icon icon="upload"></fa-icon>&nbsp;&nbsp;
      {{'labels.buttons.importEmployees' | translate }}
    </button>
  </div>
  <div #buttonCreateEmployee>
    <button mat-raised-button color="primary" [routerLink]="['create']" *mifosxHasPermission="'CREATE_STAFF'">
      <fa-icon icon="plus"></fa-icon>&nbsp;&nbsp;
      {{'labels.buttons.createemployee' | translate }}
    </button>
  </div>

</div>

<div class="container">

  <div #filter fxLayout="row" fxLayoutGap="20px">
    <mat-form-field fxFlex>
      <mat-label>{{'labels.buttons.filter' | translate }}</mat-label>
      <input matInput (keyup)="applyFilter($event.target.value)">
    </mat-form-field>
  </div>

  <div #tableEmployees class="mat-elevation-z8">

    <table mat-table [dataSource]="dataSource" matSort>

      <ng-container matColumnDef="displayName">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'labels.heading.name' | translate }} </th>
        <td mat-cell *matCellDef="let employee"> {{ employee.displayName }} </td>
      </ng-container>

      <ng-container matColumnDef="isLoanOfficer">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'labels.heading.isLoanOfficer' | translate }} </th>
        <td mat-cell *matCellDef="let employee">
          <div [className]="employee.isLoanOfficer === true ? 'true' : 'false'">
            <fa-icon *ngIf="employee.isLoanOfficer" icon="check-circle" size="lg" [matTooltip]="'labels.yes'| translate"
              matTooltipPosition="right"></fa-icon>
            <fa-icon *ngIf="!employee.isLoanOfficer" icon="times-circle" size="lg" [matTooltip]="'labels.no'| translate"
              matTooltipPosition="right"></fa-icon>
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="officeName">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'labels.heading.office' | translate }} </th>
        <td mat-cell *matCellDef="let employees"> {{ employees.officeName }} </td>
      </ng-container>

      <ng-container matColumnDef="isActive">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'labels.heading.status' | translate }} </th>
        <td mat-cell *matCellDef="let employee">
          <div [className]="employee.isActive === true ? 'true' : 'false'">
            <fa-icon matTooltip="{{ employee.isActive === true ? 'Active' : 'Inactive' }}" matTooltipPosition="right"
              icon="circle" size="lg"></fa-icon>
          </div>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;" [routerLink]="[row.id]" class="select-row"></tr>

    </table>

    <mat-paginator [pageSizeOptions]="[10, 25, 50, 100]" showFirstLastButtons></mat-paginator>

  </div>

</div>

<ng-template #templateButtonImportEmployees let-data let-popover="popover">
  <h2>{{'labels.buttons.importEmployees' | translate }}</h2>
  <p class="mw300">{{"labels.thisButtonUsedImportEmployeeDetails" | translate}}.</p>
  <div fxLayout="row" fxLayoutAlign="end" fxLayoutGap="2%" fxLayout.lt-md="column">
    <button mat-raised-button color="warn"
      (click)="popover.close();configurationWizardService.closeConfigWizard()">{{'labels.buttons.close' | translate
      }}</button>
    <button mat-raised-button color="primary" (click)="popover.close();previousStep()">{{'labels.buttons.back' |
      translate }}</button>
    <button mat-raised-button color="primary"
      (click)="popover.close();showPopover(templateButtonCreateEmployee,buttonCreateEmployee,'bottom', true)">{{'labels.buttons.next'
      | translate }}</button>
  </div>
</ng-template>

<ng-template #templateButtonCreateEmployee let-popover="popover">
  <h2>{{'labels.buttons.createemployee' | translate }}</h2>
  <p class="mw300">{{'labels.heading.createEmployeeText' | translate }}.</p>
  <div fxLayout="row" fxLayoutAlign="end" fxLayoutGap="2%" fxLayout.lt-md="column">
    <button mat-raised-button color="warn"
      (click)="popover.close();configurationWizardService.closeConfigWizard()">{{'labels.buttons.close' | translate
      }}</button>
    <button mat-raised-button color="primary"
      (click)="popover.close();showPopover(templateButtonImportEmployees,buttonImportEmployees,'bottom', true)">{{'labels.buttons.back'
      | translate }}</button>
    <button mat-raised-button color="primary"
      (click)="popover.close();showPopover(templateFilter,filter,'bottom', true)">{{'labels.buttons.next' | translate
      }}</button>
  </div>
</ng-template>

<ng-template #templateFilter let-popover="popover">
  <h4>{{'labels.heading.searchBarToFilterEmployees' | translate }}.</h4>
  <div fxLayout="row" fxLayoutAlign="end" fxLayoutGap="2%" fxLayout.lt-md="column">
    <button mat-raised-button color="warn"
      (click)="popover.close();configurationWizardService.closeConfigWizard()">{{'labels.buttons.close' | translate
      }}</button>
    <button mat-raised-button color="primary"
      (click)="popover.close();showPopover(templateButtonCreateEmployee,buttonCreateEmployee,'bottom', true)">{{'labels.buttons.back'
      | translate }}</button>
    <button mat-raised-button color="primary"
      (click)="popover.close();showPopover(templateTableEmployees,tableEmployees,'top', true)">{{'labels.buttons.next' |
      translate }}</button>
  </div>
</ng-template>

<ng-template #templateTableEmployees let-popover="popover">
  <h4>{{'labels.heading.listOfEmployeesInTheOrganization' | translate }}.</h4>
  <div fxLayout="row" fxLayoutAlign="end" fxLayoutGap="2%" fxLayout.lt-md="column">
    <button mat-raised-button color="warn"
      (click)="popover.close();configurationWizardService.closeConfigWizard()">{{'labels.buttons.close' | translate
      }}</button>
    <button mat-raised-button color="primary"
      (click)="popover.close();showPopover(templateFilter,filter,'bottom', true)">{{'labels.buttons.back' | translate
      }}</button>
    <button mat-raised-button color="primary" (click)="popover.close();nextStep()">{{'labels.buttons.next' | translate
      }}</button>
  </div>
</ng-template>
