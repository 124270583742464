<div fxLayout="row" fxLayoutAlign="end" fxLayoutGap="2%" fxLayout.lt-md="column" class="container m-b-20">
  <button mat-raised-button color="primary" [routerLink]="['edit']" *mifosxHasPermission="'UPDATE_CHARGE'">
    <fa-icon icon="edit"></fa-icon>&nbsp;&nbsp;
    {{"labels.buttons.edit" | translate}}
  </button>
  <button mat-raised-button color="warn" (click)="deleteCharge()" *mifosxHasPermission="'DELETE_CHARGE'">
    <fa-icon icon="trash"></fa-icon>&nbsp;&nbsp;
    {{"labels.buttons.delete" | translate}}
  </button>
</div>

<div class="container">

  <mat-card>

    <mat-card-content>

      <div fxLayout="row wrap" class="content">

        <div fxFlex="50%" class="mat-body-strong">
          {{"labels.chargeName" | translate}}
        </div>

        <div fxFlex="50%">
          {{ chargeData.name }}
        </div>

        <div fxFlex="50%" class="mat-body-strong">
          {{"labels.heading.chargetype" | translate}}
        </div>

        <div fxFlex="50%">
          {{ chargeData.penalty === true ? 'Penalty' : 'Charge' }}
        </div>

        <div fxFlex="50%" class="mat-body-strong">
          {{"labels.inputs.currency" | translate}}
        </div>

        <div fxFlex="50%">
          {{ chargeData.currency.name }}
        </div>

        <div fxFlex="50%" class="mat-body-strong">
          {{"labels.heading.amount" | translate}}
        </div>

        <div fxFlex="50%">
          {{ chargeData.amount }}
        </div>

        <div fxFlex="50%" class="mat-body-strong">
          {{"labels.heading.chargetimetype" | translate}}
        </div>

        <div fxFlex="50%">
          {{ chargeData.chargeTimeType.value }}
        </div>

        <div fxFlex="50%" class="mat-body-strong">
          {{"labels.heading.chargeappliesto" | translate}}
        </div>

        <div fxFlex="50%">
          {{ chargeData.chargeAppliesTo.value }}
        </div>

        <div fxFlex="50%" class="mat-body-strong">
          {{"labels.heading.chargecalculationtype" | translate}}
        </div>

        <div fxFlex="50%">
          {{ chargeData.chargeCalculationType.value }}
        </div>

        <div fxFlex="50%" class="mat-body-strong">
          {{"labels.heading.chargepaymentmode" | translate}}
        </div>

        <div fxFlex="50%">
          {{ chargeData.chargePaymentMode.value }}
        </div>

        <div fxFlex="50%" class="mat-body-strong">
          {{"labels.inputs.active" | translate}}
        </div>

        <div fxFlex="50%">
          {{ chargeData.active === true ? 'Yes' : 'No' }}
        </div>

        <div fxFlex="50%" class="mat-body-strong" *ngIf="chargeData.chargeTimeType.id == 9 && chargeData.feeFrequency">
          {{"labels.feeFrequency" | translate}}
        </div>

        <div fxFlex="50%" *ngIf="chargeData.chargeTimeType.id == 9 && chargeData.feeFrequency && chargeData.feeFrequency">
          {{ chargeData.feeFrequency.value }}
        </div>

        <div fxFlex="50%" class="mat-body-strong" *ngIf="chargeData.chargeTimeType.id == 9 && chargeData.feeInterval">
          {{"labels.inputs.frequencyInterval" | translate}}
        </div>

        <div fxFlex="50%" *ngIf="chargeData.chargeTimeType.id == 9 && chargeData.feeInterval">
          {{ chargeData.feeInterval }}
        </div>

        <div fxFlex="50%" class="mat-body-strong" *ngIf="chargeData.incomeOrLiabilityAccount">
          {{"labels.incomeFromCharges" | translate}}
        </div>

        <div fxFlex="50%" *ngIf="chargeData.incomeOrLiabilityAccount">
          {{ chargeData.incomeOrLiabilityAccount.name + ' (' + chargeData.incomeOrLiabilityAccount.glCode + ')' }}
        </div>

        <div fxFlex="50%" class="mat-body-strong" *ngIf="chargeData.taxGroup">
          {{"labels.inputs.taxgroup" | translate}}
        </div>

        <div fxFlex="50%" *ngIf="chargeData.taxGroup">
          {{ chargeData.taxGroup.name }}
        </div>

      </div>

    </mat-card-content>

  </mat-card>

</div>
