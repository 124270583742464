<div class="container">

  <mat-card>

    <form #dataTableFormRef [formGroup]="dataTableForm" (ngSubmit)="submit()">

      <mat-card-content>

        <div fxLayout="row wrap" fxLayoutGap="2%" fxLayout.lt-md="column">

          <mat-form-field fxFlex="40%">
            <mat-label>{{"labels.inputs.datatablename" | translate}}</mat-label>
            <input matInput required formControlName="datatableName">
            <mat-error *ngIf="dataTableForm.controls.datatableName.hasError('required')">
              {{"labels.inputs.datatablename" | translate}} <strong>{{"labels.commons.is required" | translate}}</strong>
            </mat-error>
          </mat-form-field>

          <mat-form-field fxFlex="20%">
            <mat-label>{{"labels.entityType" | translate}}</mat-label>
            <mat-select required formControlName="apptableName">
              <mat-option *ngFor="let appTable of appTableData" [value]="appTable.value">
                {{ appTable.displayValue }}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="dataTableForm.controls.apptableName.hasError('required')">
              {{"labels.entityType" | translate}} <strong>{{"labels.commons.is required" | translate}}</strong>
            </mat-error>
          </mat-form-field>

          <mat-form-field fxFlex="20%" *ngIf="showEntitySubType">
            <mat-label>{{"labels.entitySubType" | translate}}</mat-label>
            <mat-select formControlName="entitySubType">
              <mat-option *ngFor="let entitySubType of entitySubTypeData" [value]="entitySubType.value">
                {{ entitySubType.displayValue }}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <div fxFlex="14%" class="multi-row-wrapper">
            <mat-checkbox labelPosition="before" formControlName="multiRow" class="multi-row">Multi Row</mat-checkbox>
          </div>

        </div>
        <br>

        <div fxLayout="row wrap" fxLayoutGap="60%" fxLayout.lt-md="column">

          <p fxFlex="20%" class="mat-title">{{"labels.heading.columns" | translate}}</p>

          <button mat-raised-button fxFlex="20%" type="button" color="primary" (click)="addColumn()">
            <fa-icon icon="plus"></fa-icon>&nbsp;&nbsp;
            {{"labels.inputs.addcolumns" | translate}}
          </button>

        </div>

        <br>

        <table mat-table [dataSource]="dataSource" matSort>

          <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>{{"labels.inputs.name" | translate }}</th>
            <td mat-cell *matCellDef="let column"> {{ column.columnName }}
              <fa-icon *ngIf="column.isColumnPrimaryKey" icon="check-circle" size="lg" class="column-mandatory"
              [matTooltip]="'labels.primaryKey'| translate" matTooltipPosition="right"></fa-icon>
            </td>
          </ng-container>

          <ng-container matColumnDef="type">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> {{"labels.heading.type" | translate}} </th>
            <td mat-cell *matCellDef="let column"> {{ column.columnDisplayType }} </td>
          </ng-container>

          <ng-container matColumnDef="mandatory">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> {{"labels.mandatory" | translate}} </th>
            <td mat-cell *matCellDef="let column" class="left">
              <fa-icon *ngIf="!column.isColumnNullable" icon="check-circle" size="lg" class="column-mandatory"
                [matTooltip]="'labels.yes'| translate" matTooltipPosition="right"></fa-icon>
              <fa-icon *ngIf="column.isColumnNullable" icon="times-circle" size="lg" class="not-column-mandatory"
                [matTooltip]="'labels.no'| translate" matTooltipPosition="right"></fa-icon>
            </td>
          </ng-container>

          <ng-container matColumnDef="length">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> {{"labels.heading.length" | translate}} </th>
            <td mat-cell *matCellDef="let column"> {{ column.columnLength }} </td>
          </ng-container>

          <ng-container matColumnDef="code">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> {{"labels.heading.code" | translate}} </th>
            <td mat-cell *matCellDef="let column"> {{ column.columnCode }} </td>
          </ng-container>

          <ng-container matColumnDef="actions">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> {{"labels.heading.actions" | translate}} </th>
            <td mat-cell *matCellDef="let column" fxLayoutGap="15%">
              <button type="button" color="primary" mat-icon-button (click)="editColumn(column)">
                <fa-icon icon="edit" size="lg"></fa-icon>
              </button>
              <button type="button" color="warn" mat-icon-button (click)="deleteColumn(column)">
                <fa-icon icon="trash" size="lg"></fa-icon>
              </button>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

        </table>

        <mat-paginator [pageSizeOptions]="[10, 25, 50, 100]" showFirstLastButtons></mat-paginator>

      </mat-card-content>

      <mat-card-actions fxLayout="row" fxLayout.xs="column" fxLayoutAlign="center" fxLayoutGap="5px">
        <button type="button" mat-raised-button [routerLink]="['../']">{{"labels.buttons.cancel" | translate}}</button>
        <button mat-raised-button color="primary"
          [disabled]="!dataTableForm.valid || columnData.length === 0">{{"labels.buttons.submit" | translate}}</button>
      </mat-card-actions>

    </form>

  </mat-card>

</div>

<ng-template #templateDataTableFormRef let-popover="popover">
  <h2>{{"labels.createDatatable" | translate}}</h2>
  <p class="mw400">{{"labels.clickToStartFillingDetailsMarkFields" | translate}}. {{"labels.forMoreDetailsClick" | translate}} <a href="" target="_blank">{{"labels.manageDatatables" | translate}}</a></p>
  <div fxLayout="row" fxLayoutAlign="end" fxLayoutGap="2%" fxLayout.lt-md="column">
    <button mat-raised-button color="warn" (click)="popover.close();configurationWizardService.closeConfigWizard()">{{"labels.buttons.close" | translate}}</button>
    <button mat-raised-button color="primary" (click)="popover.close();">{{"labels.createDatatable" | translate}}</button>
    <button mat-raised-button color="primary" (click)="popover.close();previousStep()">{{"labels.buttons.back" | translate}}</button>
    <button mat-raised-button color="primary" (click)="popover.close();nextStep()">{{"labels.buttons.next" | translate}}</button>
  </div>
</ng-template>
