// `.env.ts` is generated by the `npm run env` command
import env from './.env';

export const environment = {
  production: true,
  version: env.mifos_x.version,
  hash: env.mifos_x.hash,
  // For connecting to server running elsewhere update the tenant identifier
  fineractPlatformTenantId: window['env']['fineractPlatformTenantId'] || 'default',
  // For connecting to others servers running elsewhere update the base API URL
  baseApiUrls: window['env']['fineractApiUrls'] ||
    'http://188.214.132.172:8440,https://188.214.132.172:8444',
  metabaseURL: 'http://188.214.132.172:3000/public/dashboard/',
  // For connecting to server running elsewhere set the base API URL
  baseApiUrl: window['env']['fineractApiUrl'] || 'http://188.214.132.172:8440',
  allowServerSwitch: env.allow_switching_backend_instance,
  apiProvider: window['env']['apiProvider'] || '/fineract-provider/api',
  apiVersion: window['env']['apiVersion'] || '/v1',
  serverUrl: '',
  oauth: {
    enabled: window["env"]["oauth"],  // For connecting toBank of Africa using OAuth2 Authentication change the value to true
    serverUrl: ''
  },
  client_id: window['env']['client_id'] || 'community-app',
  // grant_type: window['env']['grant_type'] || 'password',
  client_secret: window['env']['client_secret'] || 'Ca2FSphqZtAWm9KRJfGswEFHKvwiKbt6',
  baseAuthentificationUrl: window['env']['authentificationUrl'] || 'http://188.214.132.172:9001',
  realmName: window['env']['realmName'] || 'koby',

  defaultLanguage: window['env']['defaultLanguage'] || 'fr-FR',
  supportedLanguages: window['env']['supportedLanguages'] || 'en-US,fr-FR',
  // logo: window['env']['logo']
};

// Server URL
environment.serverUrl = `${environment.baseApiUrl}${environment.apiProvider}${environment.apiVersion}`;
environment.oauth.serverUrl = `${environment.baseApiUrl}${environment.apiProvider}`;
