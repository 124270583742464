<h1 mat-dialog-title>{{ 'labels.buttons.uploadpic' | translate }}</h1>

<div>

  <mifosx-file-upload flex="60%" (change)="onFileSelect($event)"></mifosx-file-upload>

  <mat-dialog-actions align="end">
      <button mat-raised-button mat-dialog-close>{{"labels.buttons.cancel" | translate}}</button>
      <button mat-raised-button color="primary" [disabled]="!image" [mat-dialog-close]="image">{{ 'labels.buttons.confirm' | translate }}</button>
  </mat-dialog-actions>

</div>
