<div class="container">

  <mat-card>

    <div fxLayout="row" fxLayout.lt-md="column">

      <div fxFlex="50%">

        <mat-nav-list>

          <mat-list-item [routerLink]="['tax-components']" *mifosxHasPermission="'READ_TAXCOMPONENT'">
            <mat-icon matListIcon>
              <fa-icon icon="building" size="sm"></fa-icon>
            </mat-icon>
            <h4 matLine> {{"labels.anchors.managetaxcomponents" | translate }} </h4>
            <p matLine> {{"labels.view.taxcomponent" | translate }} </p>
          </mat-list-item>

        </mat-nav-list>

      </div>

      <div fxFlex="50%">

        <mat-nav-list>

          <mat-list-item [routerLink]="['tax-groups']" *mifosxHasPermission="'READ_TAXGROUP'">
            <mat-icon matListIcon>
              <fa-icon icon="building" size="sm"></fa-icon>
            </mat-icon>
            <h4 matLine>{{"labels.anchors.managetaxgroup" | translate}}</h4>
            <p matLine> {{"labels.view.taxgroup" | translate }} </p>
          </mat-list-item>

        </mat-nav-list>

      </div>

    </div>

  </mat-card>

</div>
