<form [formGroup]="shareProductAccountingForm">

  <div fxLayout="row wrap" fxLayoutGap="2%" fxLayout.lt-md="column">

    <mat-radio-group fxFlex="98%" fxLayout="row" fxLayoutGap="5%" fxLayout.lt-md="column" formControlName="accountingRule">
      <mat-radio-button *ngFor="let accountingRule of accountingRuleData; let i =  index" [value]="i+1">
        {{ accountingRule }}
      </mat-radio-button>
    </mat-radio-group>

    <mat-divider fxFlex="98%"></mat-divider>

    <div *ngIf="shareProductAccountingForm.value.accountingRule === 2" fxFlexFill fxLayout="row wrap" fxLayoutGap="2%" fxLayout.lt-md="column">

      <h4 fxFlex="98%" class="mat-h4">{{"labels.assets" | translate}}</h4>

      <mat-form-field fxFlex="48%">
        <mat-label>{{"labels.inputs.sharereference" | translate }}</mat-label>
        <mat-select formControlName="shareReferenceId" required>
          <mat-option *ngFor="let assetAccount of assetAccountData" [value]="assetAccount.id">
            {{ assetAccount.name }}
          </mat-option>
        </mat-select>
        <mat-error>{{"labels.inputs.sharereference" | translate }} <strong>{{"labels.commons.is required" | translate}}</strong>
        </mat-error>
      </mat-form-field>

      <mat-divider fxFlex="98%"></mat-divider>

      <h4 fxFlex="98%" class="mat-h4">{{"labels.heading.liabilities" | translate}} </h4>

      <mat-form-field fxFlex="48%">
        <mat-label>{{"labels.inputs.sharecontrol" | translate }}</mat-label>
        <mat-select formControlName="shareSuspenseId" required>
          <mat-option *ngFor="let liabilityAccount of liabilityAccountData" [value]="liabilityAccount.id">
            {{ liabilityAccount.name }}
          </mat-option>
        </mat-select>
        <mat-error>{{"labels.inputs.sharecontrol" | translate }} <strong>{{"labels.commons.is required" | translate}}</strong>
        </mat-error>
      </mat-form-field>

      <mat-divider fxFlex="98%"></mat-divider>

      <h4 fxFlex="98%" class="mat-h4">{{"labels.heading.shareequity" | translate }}</h4>

      <mat-form-field fxFlex="48%">
        <mat-label>{{"labels.heading.equity" | translate }}</mat-label>
        <mat-select formControlName="shareEquityId" required>
          <mat-option *ngFor="let equityAccount of equityAccountData" [value]="equityAccount.id">
            {{ equityAccount.name }}
          </mat-option>
        </mat-select>
        <mat-error>{{"labels.heading.equity" | translate }} <strong>{{"labels.commons.is required" | translate}}</strong>
        </mat-error>
      </mat-form-field>

      <mat-divider fxFlex="98%"></mat-divider>

      <h4 fxFlex="98%" class="mat-h4">{{"labels.heading.income" | translate}} </h4>

      <mat-form-field fxFlex="48%">
        <mat-label>{{"labels.heading.incomefromfees" | translate}} </mat-label>
        <mat-select formControlName="incomeFromFeeAccountId" required>
          <mat-option *ngFor="let incomeAccount of incomeAccountData" [value]="incomeAccount.id">
            {{ incomeAccount.name }}
          </mat-option>
        </mat-select>
        <mat-error>
          {{"labels.heading.incomefromfees" | translate}} <strong>{{"labels.commons.is required" | translate}}</strong>
        </mat-error>
      </mat-form-field>

    </div>

  </div>

  <div fxLayout="row" class="margin-t" fxLayout.xs="column" fxLayoutAlign="center" fxLayoutGap="2%">
    <button mat-raised-button matStepperPrevious>
      <fa-icon icon="arrow-left"></fa-icon>&nbsp;&nbsp;
      {{"labels.buttons.previous" | translate}}
    </button>
    <button mat-raised-button matStepperNext [disabled]="!shareProductFormValid">
      {{"labels.buttons.next" | translate}}&nbsp;&nbsp;
      <fa-icon icon="arrow-right"></fa-icon>
    </button>
  </div>

</form>
